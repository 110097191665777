import API from '../axios/API';

export const getCategoryList = (outletId) => {
    return async (getState, dispatch) => {
        let response;

        try {
            response = await API.get(`/restaurants/:restaurantId/outlets/${outletId}/categories`);
            // https://api.staging.fab.delivery/api/v2/restaurants/1/outlets/3/categories
        } catch (error) {
            response = error;
        }

        return response;
    };
};
