import {
    EuiFlexGroup,
    EuiFlexItem,
    EuiPanel,
    EuiStat,
    EuiButton,
    EuiButtonIcon,
    EuiFieldText,
    EuiFieldNumber,
    EuiFormRow,
} from '@elastic/eui';
import { isEmpty } from 'lodash';
import React, { useRef } from 'react';
import { Controller, useFieldArray } from 'react-hook-form';
const PercentageSplitTab = (props) => {
    const { watch, setValue, errors, control } = props;

    const numberRef = useRef(null);

    const { fields, append, remove } = useFieldArray({
        control,
        name: 'choices',
    });

    const percentageCalculateField = (index) => {
        let record = watch();

        let value = 0;
        if (!isEmpty(record) && record.choices) {
            record.choices.map((choice, i) => {
                if (index == i && parseFloat(choice.number) >= 0 && parseFloat(choice.number) <= 100) {
                    value = (parseFloat(props.total) * parseFloat(choice.number)) / 100;
                }
            });
        }

        return parseFloat(value).toFixed(2);
    };

    const remainingPercentageField = () => {
        let percentage = 100;
        let record = watch();
        if (!isEmpty(record)) {
            record.choices.map((choice, i) => {
                percentage -= parseFloat(choice.number);
            });
        }

        if (percentage < 0 || percentage > 100) {
            percentage = 0;
        }

        return percentage;
    };

    const onWheel = () => {
        numberRef && numberRef.current && numberRef.current.blur();
    };

    return (
        <EuiFlexGroup direction={'column'}>
            <EuiFlexItem>
                <EuiFlexItem
                    style={{ width: '25%', marginTop: '10px', justifyContent: 'flex-end', flexDirection: 'row' }}
                >
                    <EuiButton
                        style={{ width: '10%' }}
                        onClick={() =>
                            append({ number: remainingPercentageField(), name: `Person ${fields.length + 1}` })
                        }
                    >
                        Add Person
                    </EuiButton>
                </EuiFlexItem>
                <EuiPanel style={{ width: '25%', marginTop: '10px' }}>
                    <EuiStat title={props.total} description='Total' textAlign='center' titleSize='l' />
                </EuiPanel>
            </EuiFlexItem>

            <EuiFlexItem style={{ width: '98%', flexDirection: 'row', flexWrap: 'wrap' }}>
                {fields.map((field, index) => {
                    return (
                        <EuiPanel
                            key={field.id}
                            style={{
                                width: '20%',
                                marginTop: '10px',
                                marginRight: '10px',
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >
                            <EuiButtonIcon
                                iconType={'minusInCircle'}
                                disabled={fields.length === 2}
                                onClick={() => {
                                    remove(index);
                                }}
                            />
                            <EuiFlexItem style={{ alignItems: 'center' }}>
                                <EuiFormRow
                                    fullWidth={true}
                                    error={
                                        errors[`choices[${index}].name`] ? errors[`choices[${index}].name`].message : ''
                                    }
                                    isInvalid={errors[`choices[${index}].name`]}
                                    style={{ width: '150px' }}
                                >
                                    <Controller
                                        render={({ field }) => (
                                            <EuiFieldText value={field.value} onChange={field.onChange} />
                                        )}
                                        min={0}
                                        max={100}
                                        name={`choices[${index}].name`}
                                        placeholder='Customer name'
                                        control={control}
                                        fullWidth={true}
                                        isInvalid={
                                            errors[`choices[${index}].name`] && errors[`choices[${index}].name`].message
                                        }
                                        defaultValue={field[`name`]}
                                    />
                                </EuiFormRow>
                                <EuiStat title={percentageCalculateField(index)} textAlign='center' titleSize='l' />
                            </EuiFlexItem>
                            <EuiFlexItem style={{ flexDirection: 'row', justifyContent: 'flex-end' }}>
                                <div style={{ width: '80px' }}>
                                    <EuiFormRow
                                        fullWidth={true}
                                        error={
                                            errors[`choices[${index}].number`]
                                                ? errors[`choices[${index}].number`].message
                                                : ''
                                        }
                                        isInvalid={errors[`choices[${index}].number`]}
                                    >
                                        <Controller
                                            render={({ field }) => (
                                                <EuiFieldNumber value={field.value} onChange={field.onChange} />
                                            )}
                                            min={0}
                                            max={100}
                                            name={`choices[${index}].number`}
                                            placeholder='Customer percentage'
                                            control={control}
                                            fullWidth={true}
                                            inputRef={numberRef}
                                            onWheel={onWheel}
                                            isInvalid={
                                                errors[`choices[${index}].number`] &&
                                                errors[`choices[${index}].number`].message
                                            }
                                            rules={{
                                                validate: (value) =>
                                                    (0 > parseInt(value) || value > 100) &&
                                                    'Please enter value between 0 - 100',
                                            }}
                                            defaultValue={field[`number`]}
                                        />
                                    </EuiFormRow>
                                </div>
                            </EuiFlexItem>
                        </EuiPanel>
                    );
                })}
            </EuiFlexItem>
        </EuiFlexGroup>
    );
};

export default PercentageSplitTab;
