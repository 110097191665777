import API from './axios/API';

export const generateOTP = (country_code, mobile_number) => {
    return async (dispatch, getState) => {
        let response;
        let { language } = getState();

        try {
            response = API.post(`${language.language}/restaurants/:restaurantId/customers/login`, {
                mobile_number: mobile_number,
                country_code: country_code,
            });
        } catch (error) {
            response = error;
        }

        return response;
    };
};
