import React, { useState, useEffect, useRef } from 'react';
import {
    EuiFlexGroup,
    EuiFlexItem,
    EuiDragDropContext,
    EuiButton,
    EuiDroppable,
    euiDragDropMove,
    euiDragDropReorder,
    htmlIdGenerator,
} from '@elastic/eui';
import { useFieldArray } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import OrderItemSplitDroppable from './orderItemSplitDroppable';
import OrderTotalDraggable from './orderTotalDraggable';
import * as types from '../../reduxStore/types/billing';
import OrderSplitInvoice from '../print/orderSplitInvoice';
import ReactToPrint from 'react-to-print';
import API from '../../api/axios/API';

const ItemSplitTab = (props) => {
    const { watch, setValue, errors, control, outletId, fields, remove, append } = props;
    const selectedDraggableItems = useSelector((state) => state.billing.selectedDraggableItems);

    const [list, setList] = useState([]);

    const [dummyState, setDummyState] = useState(false);
    const [selectedIdList, setSelectedIdList] = useState([]);
    const dispatch = useDispatch();
    const billingItem = useSelector((state) => state.billing.billingItem);
    const previousOrders = useSelector((state) => state.billing.previousOrders);

    const mode = useSelector((state) => state.billing.mode);
    const languageId = useSelector((state) => state.language.languageId);
    const invoicePrinterRef = useRef(null);
    let restaurantList = useSelector((state) => state.auth.restaurantList);
    let selectedRestuarantId = useSelector((state) => state.auth.selectedRestaurantId);

    useEffect(() => {
        let innerArray = [];
        for (let key in billingItem) {
            if (parseInt(billingItem[key].quantity) > 0) {
                for (let i = 0; i < parseInt(billingItem[key].quantity); i++) {
                    innerArray.push({
                        content: billingItem[key].title,
                        id: htmlIdGenerator()(),
                        item: billingItem[key],
                    });
                }
            } else {
                innerArray.push({
                    content: billingItem[key].title,
                    id: htmlIdGenerator()(),
                    item: billingItem[key],
                });
            }
        }

        previousOrders.map((order) => {
            order.cart.cart_items.map((item) => {
                if (parseInt(item.quantity) > 0) {
                    for (let i = 0; i < parseInt(item.quantity); i++) {
                        innerArray.push({
                            content: item.title,
                            id: htmlIdGenerator()(),
                            item: item,
                        });
                    }
                } else {
                    innerArray.push({
                        content: item.title,
                        id: htmlIdGenerator()(),
                        item: item,
                    });
                }
            });
        });

        setList(innerArray);
    }, []);

    const updateList = (items, key, index) => {
        fields[index].data = items;
        setDummyState(!dummyState);
    };

    const draggableListAvailability = (source, lists) => {
        let boolCheck = false;
        let list = lists[source.droppableId];

        let itemId = list[source.index];

        selectedDraggableItems.map((item) => {
            if (item.id == itemId.id) {
                boolCheck = true;
            }
        });

        return boolCheck;
    };

    const onDragEnd = async ({ source, destination }) => {
        let lists = {};
        let actions = {};
        let fieldIndex = {};

        fields.map((field, index) => {
            fieldIndex[`DROPPABLE_AREA_TYPE_${index + 2}`] = index;
            lists[`DROPPABLE_AREA_TYPE_${index + 2}`] = field.data;
            actions[`DROPPABLE_AREA_TYPE_${index + 2}`] = updateList;
        });

        lists['DROPPABLE_AREA_TYPE_1'] = list;
        actions['DROPPABLE_AREA_TYPE_1'] = setList;

        if (source && destination) {
            if (source.droppableId === destination.droppableId) {
                if (selectedDraggableItems.length > 0 && draggableListAvailability(source, lists)) {
                    let items = [...list];
                    selectedDraggableItems.map((item) => {
                        let innerIndex;
                        items.map((innerItem, index) => {
                            if (innerItem.id == item.id) {
                                innerIndex = index;
                            }
                        });

                        items = euiDragDropReorder(items, innerIndex, destination.index);
                    });
                    actions[destination.droppableId](items, destination.droppableId);
                } else {
                    const items = euiDragDropReorder(lists[destination.droppableId], source.index, destination.index);

                    actions[destination.droppableId](items, destination.droppableId);
                }
            } else {
                const sourceId = source.droppableId;
                const destinationId = destination.droppableId;

                if (selectedDraggableItems.length > 0 && draggableListAvailability(source, lists)) {
                    let sourceItems = [...lists[sourceId]];
                    let destinationItems = [...lists[destinationId]];
                    selectedDraggableItems.map((item) => {
                        let innerIndex;
                        sourceItems.map((innerItem, index) => {
                            if (innerItem.id == item.id) {
                                innerIndex = index;
                            }
                        });

                        const result = euiDragDropMove(
                            sourceItems,
                            destinationItems,
                            { ...source, index: innerIndex },
                            destination
                        );

                        sourceItems = result[sourceId];
                        destinationItems = result[destinationId];
                    });

                    if (destinationId === 'DROPPABLE_AREA_TYPE_1') {
                        actions[sourceId](sourceItems, sourceId, fieldIndex[sourceId]);
                        setList(destinationItems);
                    } else {
                        actions[sourceId](sourceItems, sourceId, fieldIndex[sourceId]);
                        actions[destinationId](destinationItems, destinationId, fieldIndex[destinationId]);
                    }

                    dispatch({
                        type: types.SET_SELECTED_DRAGGABLE_ITEMS,
                        payload: {
                            selectedDraggableItems: [],
                        },
                    });
                } else {
                    const result = euiDragDropMove(lists[sourceId], lists[destinationId], source, destination);

                    if (sourceId !== 'DROPPABLE_AREA_TYPE_1' && destinationId !== 'DROPPABLE_AREA_TYPE_1') {
                        actions[sourceId](result[sourceId], sourceId, fieldIndex[sourceId]);
                        actions[destinationId](result[destinationId], destinationId, fieldIndex[destinationId]);
                    } else if (destinationId === 'DROPPABLE_AREA_TYPE_1') {
                        actions[sourceId](result[sourceId], sourceId, fieldIndex[sourceId]);
                        setList(result[destinationId]);
                    } else {
                        actions[sourceId](result[sourceId], sourceId, fieldIndex[sourceId]);
                        actions[destinationId](result[destinationId], destinationId, fieldIndex[destinationId]);
                    }
                }
            }
        }
    };

    const DraggableMethod = () => {
        let draggableArray = [];

        fields.map((field, index) => {
            draggableArray.push(
                <OrderItemSplitDroppable
                    field={field}
                    index={index}
                    errors={errors}
                    remove={remove}
                    control={control}
                    list={list}
                    setList={setList}
                    watch={watch}
                />
            );
        });

        return draggableArray;
    };

    return (
        <EuiFlexGroup direction='column'>
            <div style={{ display: 'none' }}>
                <OrderSplitInvoice
                    outletId={outletId}
                    fields={fields}
                    watch={watch}
                    billingItem={billingItem}
                    languageId={languageId}
                    ref={invoicePrinterRef}
                    restaurantList={restaurantList}
                    selectedRestuarantId={selectedRestuarantId}
                    mode={mode}
                />
            </div>
            <EuiFlexItem>
                <EuiDragDropContext onDragEnd={onDragEnd}>
                    <EuiFlexGroup style={{ width: '100%', flexWrap: 'wrap', flexDirection: 'column' }}>
                        {/* <EuiFlexItem> */}
                        <EuiFlexItem style={{ width: '25%' }}>
                            <EuiFlexItem
                                style={{ margin: '10px 0px', justifyContent: 'flex-end', flexDirection: 'row' }}
                            >
                                <EuiButton
                                    onClick={() => {
                                        append({ name: `Person ${fields.length + 1}`, mobile_number: '', data: [] });
                                    }}
                                >
                                    Add Person
                                </EuiButton>
                            </EuiFlexItem>

                            <EuiDroppable
                                droppableId='DROPPABLE_AREA_TYPE_1'
                                type='TYPE_ONE'
                                spacing='m'
                                withPanel
                                grow={false}
                            >
                                {list.map(({ content, id }, idx) => (
                                    <OrderTotalDraggable content={content} id={id} idx={idx} />
                                ))}
                            </EuiDroppable>
                        </EuiFlexItem>

                        <EuiFlexItem
                            style={{
                                width: '98%',
                                flexDirection: 'row',
                                flexWrap: 'wrap',
                                display: 'flex',
                                marginTop: '10px',
                            }}
                        >
                            {DraggableMethod()}
                        </EuiFlexItem>
                    </EuiFlexGroup>
                </EuiDragDropContext>
            </EuiFlexItem>

            <EuiFlexItem style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                <EuiButton type='submit' disabled={list.length !== 0}>
                    Print
                </EuiButton>

                <EuiFlexItem style={{ display: 'none' }}>
                    <ReactToPrint
                        copyStyles={false}
                        trigger={() => (
                            <EuiButton
                                id='buttonClickForPrint'
                                style={{ width: '100px', marginLeft: '5px' }}
                                // disabled={list.length !== 0}
                            >
                                Print
                            </EuiButton>
                        )}
                        content={() => invoicePrinterRef.current}
                    />
                </EuiFlexItem>
            </EuiFlexItem>
        </EuiFlexGroup>
    );
};

export default ItemSplitTab;
