import React, { useEffect, useState } from 'react';
import { EuiButton, EuiButtonEmpty } from '@elastic/eui';
import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import * as types from '../../reduxStore/types/billing';

const FormStepsFooter = (props) => {
    const {
        currentStep,
        firstStep,
        goToStep,
        lastStep,
        nextStep,
        previousStep,
        totalSteps,
        step,
        watch,
        errors,
        setButtonClicked,
        reset,
        setSelectedOption,
    } = props;

    const [showError, setShowError] = useState(false);
    const [showTableError, setShowTableError] = useState(false);
    const [stepFormPosition, setStepFormPosition] = useState('');

    const mode = useSelector((state) => state.billing.mode);
    const customerInformation = useSelector((state) => state.billing.customerInformation);
    const tableSelectedList = useSelector((state) => state.billing.tableSelectedList);
    const addNew = useSelector((state) => state.billing.addNew);

    const dispatch = useDispatch();

    useEffect(() => {
        if (!isEmpty(customerInformation)) {
            nextStep();
        }
    }, [customerInformation]);

    useEffect(() => {
        firstStep();
        if (tableSelectedList.length && step == 1 && mode === 'dine_in') {
            nextStep();
        }

        if (mode === 'delivery' && step == 1 && !isEmpty(customerInformation)) {
            reset({
                mobile_number: `${customerInformation.country_code}${customerInformation.mobile_number}`,
                name: customerInformation.name,
                cover_count: customerInformation.cover_count,
            });

            lastStep();
        }

        dispatch({
            type: types.SET_ADD_NEW,
            payload: {
                addNew: false,
            },
        });
    }, [mode, tableSelectedList]);

    useEffect(() => {
        if (addNew) {
            nextStep();
        } else {
            firstStep();
        }
    }, [addNew]);

    const continueButton = () => {
        let hasError = false;
        if (mode === 'dine_in') {
            if (step === 1) {
                if (tableSelectedList && tableSelectedList.length < 1) {
                    setShowTableError(true);
                    hasError = true;
                }
            } else {
                if (!watch('mobile_number') || !watch('name') || !watch('cover_count') || errors.mobile_number) {
                    setShowError(true);
                    hasError = true;
                }
            }
        } else if (mode === 'delivery') {
            if (step === 2) {
                if (!watch('mobile_number') || !watch('name') || errors.mobile_number) {
                    setShowError(true);
                    hasError = true;
                }
            }
        } else if (mode === 'takeaway') {
            if (step === 2) {
                if (!watch('mobile_number') || !watch('name') || errors.mobile_number) {
                    setShowError(true);
                    hasError = true;
                }
            }
        }

        if (!hasError) {
            nextStep();
        }
    };

    const goBackButton = () => {
        if (step === 2) {
            reset({
                number_combobox: '',
                mobile_number: '',
                name: '',
                cover_count: '',
                block_number: '',
                land_mark: '',
                email: '',
                anniversary_date: '',
                date_of_birth: '',
                reference_mode: '',
            });

            if (setSelectedOption) {
                setSelectedOption([]);
            }
        }

        if (mode === 'dine_in' && step === 2) {
            dispatch({
                type: types.SET_TABLE_SELECTED_LIST,
                payload: {
                    tableSelectedList: [],
                },
            });
        }

        previousStep();

        dispatch({
            type: types.SET_ADD_NEW,
            payload: {
                addNew: false,
            },
        });
    };

    const onFinish = () => {
        setButtonClicked('');
        nextStep();
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'flex-start' }}>
                {(mode === 'takeaway' || mode === 'delivery') && step === 1 ? (
                    <>
                        <EuiButton onClick={nextStep} fullWidth>
                            New Order
                        </EuiButton>
                    </>
                ) : step < totalSteps ? (
                    <>
                        {!(mode === 'dine_in' && step === 1) ? (
                            <EuiButton
                                fill
                                type='submit'
                                style={{ width: '100px', backgroundColor: '#285af6' }}
                                onClick={() => {
                                    setButtonClicked('continue');
                                    continueButton();
                                }}
                            >
                                Continue
                            </EuiButton>
                        ) : (
                            ''
                        )}
                    </>
                ) : (
                    <EuiButton
                        type='submit'
                        fill
                        style={{ width: '100px', backgroundColor: '#285af6' }}
                        onClick={onFinish}
                    >
                        Finish
                    </EuiButton>
                )}

                {step > 1 && (
                    <EuiButtonEmpty style={{ width: '100px', marginRight: '10px' }} onClick={goBackButton}>
                        Go Back
                    </EuiButtonEmpty>
                )}
            </div>
        </div>
    );
};

export default FormStepsFooter;
