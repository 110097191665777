import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { EuiBasicTable, EuiFlexGroup, EuiFlexItem, EuiText, EuiButton } from '@elastic/eui';
import { fetchOrderingModes, updateOrderingMode } from '../../../components/settings/RestaurantSettings/Utils/utils';
import OrderingModeSwitch from '../../../components/settings/RestaurantSettings/OrderingModeSwitch';
import OrderingModeModal from '../../../components/settings/RestaurantSettings/OrderingModeModal';
import { fetchUserOutletInfo } from '../../../reduxStore/actions/fetchUserOutletInfo';
import { useDispatch } from 'react-redux';

const OrderingModes = () => {
    const [orderingModes, setOrderingModes] = useState([]);
    const [updatedModes, setUpdatedModes] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const dispatch = useDispatch();

    const closeModal = useCallback(() => setIsModalVisible(false), []);
    const showModal = useCallback(() => setIsModalVisible(true), []);

    const updateOrderingModes = useCallback(() => {
        fetchOrderingModes()
            .then((resposne) => {
                if (resposne.success) {
                    setOrderingModes(resposne.ordering_modes);
                }
            })
            .catch((error) => {
                //todo: handle error
            });
    }, []);

    useEffect(() => {
        updateOrderingModes();
    }, [updateOrderingModes]);

    const handleChange = useCallback(
        async (event, id, setIsActive) => {
            setIsActive(event.target.checked);
            let modeChanges = {
                id,
                status: event.target.checked,
            };
            setUpdatedModes([...updatedModes, modeChanges]);
        },
        [updatedModes]
    );

    const handleSave = useCallback(() => {
        updateOrderingMode(updatedModes).then((resposne) => {
            if (resposne.success) {
                dispatch(fetchUserOutletInfo());
                setUpdatedModes([]);
            }
        });
    }, [updatedModes, dispatch]);

    let columns = useMemo(() => {
        return [
            {
                field: 'name',
                name: 'Name',
                render: (name) => (
                    <EuiText size='s' href='#' target='_blank'>
                        {name}
                    </EuiText>
                ),
            },
            {
                field: 'order_type',
                name: 'Order Type',
                render: (type) => {
                    return <EuiText size='s'>{type}</EuiText>;
                },
            },
            {
                field: 'order_tab',
                name: 'Order Tab',
                render: (tab) => {
                    return <EuiText size='s'>{tab}</EuiText>;
                },
            },
            {
                field: 'offer_actions',
                name: 'Actions',
                render: (mode) => {
                    return <OrderingModeSwitch mode={mode} handleChange={handleChange} />;
                },
            },
        ];
    }, [handleChange]);

    const items = useMemo(() => {
        return orderingModes?.map((mode) => {
            return {
                order_type: mode.type,
                order_tab: mode.tab,
                offer_actions: mode,
                name: mode.display_name,
                id: mode.id,
            };
        });
    }, [orderingModes]);

    return (
        <>
            <OrderingModeModal
                closeModal={closeModal}
                isModalVisible={isModalVisible}
                updateOrderingModes={updateOrderingModes}
            />
            <EuiFlexGroup gutterSize='s' direction='column' alignItems='flexEnd'>
                <EuiFlexItem grow={false}>
                    <EuiButton onClick={showModal}>Create an ordering mode</EuiButton>
                </EuiFlexItem>
                <EuiFlexItem>
                    <EuiBasicTable
                        tableCaption='Demo of EuiBasicTable'
                        items={items}
                        rowHeader='firstName'
                        columns={columns}
                        responsive
                    />
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                    <EuiButton color='primary' fill isDisabled={!updatedModes.length} onClick={handleSave}>
                        Save
                    </EuiButton>
                </EuiFlexItem>
            </EuiFlexGroup>
        </>
    );
};

export default React.memo(OrderingModes);
