import API from '../axios/API';

export const getOnlyItems = (id) => {
    return async (dispatch, getState) => {
        let response;
        let { outlet } = getState();
        try {
            response = await API.get(
                `restaurants/:restaurantId/outlets/${outlet.selectedOutletId}/categories/${id}/items`
            );
        } catch (error) {
            response = error;
        }

        return response;
    };
};
