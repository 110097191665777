import React, { useCallback, useEffect, useState } from 'react';
import { EuiFlexItem, EuiText, EuiFlexGroup, EuiPanel, EuiSpacer } from '@elastic/eui';
import { useForm } from 'react-hook-form';
import FormGroupDescription from '../../../../components/formGroupDescription';
import { useDispatch, useSelector } from 'react-redux';
import * as types from '../../../../reduxStore/types/outlet';
import { userHasPermission } from '../../../../helper/auth';
import { fetchOutletOrderingModes } from '../../../../api/outlet/fetchOutletOrderingModes';
import { fetchOrderingModes } from '../../../../components/settings/RestaurantSettings/Utils/utils';
import OutletOrderingModeSwitch from '../../../../components/settings/RestaurantSettings/OutletOrderingModeSwitch';
import { updateOutletOrderingModes } from '../../../../components/settings/outlet-form/utils';
import CheckBoxField from '../../../../components/Form/CheckBoxField';
import LoadingOverlay from 'react-loading-overlay';

const OrderingMode = () => {
    const {
        handleSubmit,
        control,
        watch,
        reset,
        setValue,

        formState: { isDirty },
    } = useForm();
    const dispatch = useDispatch();
    const permissions = useSelector((state) => state.permission.permission);
    const restaurantDescriptions = useSelector((state) => state.auth.restaurantDescriptions);
    const outletId = useSelector((state) => state.outlet.selectedOutletId);
    const [orderingModes, setOrderingModes] = useState([]);
    const availableLanguages = useSelector((state) => state.language.availableLanguages);
    const outletOrderingModes = useSelector((state) => state?.outlet?.outletOrderingModes?.ordering_modes);
    const languageId = useSelector((state) => state.language.languageId);
    const [isProcessing, setIsProcessing] = useState(false);
    const [list, setList] = useState([]);
    const doesDineInTabExist = list?.filter((mode) => mode.tab === 'dine_in' && mode.status);

    useEffect(() => {
        if (orderingModes?.length) {
            setList([...orderingModes]);
        }
    }, [orderingModes]);

    const resetChanges = useCallback(() => {
        let defaultValue = {};
        defaultValue['ordering_modes'] = orderingModes;
        for (const mode of orderingModes) {
            for (const outletMode of outletOrderingModes) {
                if (outletMode.restaurant_ordering_mode_id === mode.id) {
                    defaultValue[`${mode.type}_${mode.id}`] = outletMode.is_enabled;
                    defaultValue[`is_processing_${mode.type}_${mode.id}`] = outletMode.is_processing;
                    if (outletMode.translations.display_name[languageId] === 'In Store') {
                        defaultValue['in_store_for_dine_in'] = true;
                    }
                }
            }
        }

        reset({
            ...defaultValue,
        });
    }, [languageId, orderingModes, outletOrderingModes, reset]);

    useEffect(() => {
        if (orderingModes?.length && outletOrderingModes?.length) {
            resetChanges();
        }
    }, [resetChanges, orderingModes, outletOrderingModes]);

    useEffect(() => {
        if (!userHasPermission(permissions, '/outlet-settings', 'write')) return;

        if (isDirty) {
            dispatch({
                type: 'set-portal-active',
                payload: {
                    portalActive: {
                        resetChanges: resetChanges,
                        submit: handleSubmit(onSubmit),
                    },
                },
            });
        } else {
            dispatch({
                type: 'set-portal-active',
                payload: {
                    portalActive: {},
                },
            });
            dispatch({
                type: 'set-portal-active-tab-switched',
                payload: {
                    tabSwitched: false,
                },
            });
        }
    }, [isDirty]);

    const onSubmit = (data) => {
        let structuredModes = [];
        data.ordering_modes.forEach((orderingMode, index) => {
            let languageObj = {};
            for (const language of availableLanguages) {
                if (orderingMode.tab === 'dine_in') {
                    if (data.in_store_for_dine_in) {
                        languageObj[language.language_id] = 'In Store';
                    } else {
                        languageObj[language.language_id] = 'Dine In';
                    }
                } else {
                    languageObj[language.language_id] = orderingMode.display_name;
                }
            }

            let obj = {
                restaurant_ordering_mode_id: orderingMode.id,
                display_rank: index + 1,
                is_enabled: watch(`${orderingMode.type}_${orderingMode.id}`)
                    ? watch(`${orderingMode.type}_${orderingMode.id}`)
                    : false,
                translations: {
                    display_name: languageObj,
                },
                show_on_home_screen: true,
            };

            structuredModes.push(obj);
        });

        updateOutletOrderingModes(outletId, { ordering_modes: structuredModes }, 'ordering_mode').then((response) => {
            if (response.success) {
                dispatch({
                    type: types.SET_PORTAL_ACTIVE,
                    payload: {
                        portalActive: {},
                    },
                });
                let isProcessing = false;
                for (const orderingMode of response?.outlet?.ordering_modes) {
                    if (orderingMode.is_processing) {
                        isProcessing = true;
                    }
                }
                setIsProcessing(isProcessing);
                dispatch({
                    type: types.SET_PORTAL_ACTIVE_TAB_SWITCHED,
                    payload: {
                        tabSwitched: false,
                    },
                });
                getOrderingModes();
            }
        });
    };

    const getOrderingModes = useCallback(async () => {
        dispatch(fetchOutletOrderingModes(outletId)).then((response) => {
            let isProcessing = false;
            for (const orderingMode of response?.outlet?.ordering_modes) {
                if (orderingMode.is_processing) {
                    isProcessing = true;
                }
            }
            setIsProcessing(isProcessing);
        });
        fetchOrderingModes()
            .then((resposne) => {
                if (resposne.success) {
                    setOrderingModes(resposne.ordering_modes);
                }
            })
            .catch((error) => {
                //todo: handle error
            });
    }, [dispatch, outletId]);

    useEffect(() => {
        getOrderingModes();
    }, [getOrderingModes]);

    const handleChange = useCallback(
        async (event, id, setIsActive, selectedMode) => {
            const list = watch('ordering_modes');
            const isChecked = event.target.checked;
            for (let index = 0; index < list.length; index++) {
                const element = list[index];

                if (selectedMode.type === 'self_serve') {
                    if (element.type === 'qsr' || element.type === 'fine_dine') {
                        setValue(`${element.type}_${element.id}`, false, { shouldDirty: true });
                        setValue(`ordering_modes[${index}].status`, false, { shouldDirty: true });
                    } else if (element.id === id) {
                        setValue(`${element.type}_${element.id}`, isChecked, { shouldDirty: true });
                        setValue(`ordering_modes[${index}].status`, isChecked, { shouldDirty: true });
                    }
                } else if (selectedMode.type === 'qsr' || selectedMode.type === 'fine_dine') {
                    if (element.type === 'self_serve') {
                        setValue(`${element.type}_${element.id}`, false, { shouldDirty: true });
                        setValue(`ordering_modes[${index}].status`, false, { shouldDirty: true });
                    } else if (element.id === id) {
                        setValue(`${element.type}_${element.id}`, isChecked, { shouldDirty: true });
                        setValue(`ordering_modes[${index}].status`, isChecked, { shouldDirty: true });
                    }
                } else {
                    if (element.id === id) {
                        setValue(`${element.type}_${element.id}`, isChecked, { shouldDirty: true });
                        setValue(`ordering_modes[${index}].status`, isChecked, { shouldDirty: true });
                    }
                }
            }

            setIsActive(isChecked);
        },
        [setValue, watch]
    );

    return (
        <>
            <FormGroupDescription
                title={restaurantDescriptions?.ordering_mode?.display_name}
                description={restaurantDescriptions?.ordering_mode?.description}
            >
                {/* <LoadingOverlay active={isProcessing} spinner text='Processing...'> */}
                {list
                    .filter((orderingMode) => orderingMode.status)
                    .map((mode, idx) => {
                        return (
                            <React.Fragment key={mode.id.toString()}>
                                <EuiSpacer size={`${idx !== 0 ? 's' : null}`} />
                                <EuiPanel paddingSize='s'>
                                    <EuiFlexGroup justifyContent='spaceBetween' alignItems='center' gutterSize='s'>
                                        <EuiFlexItem>
                                            <EuiFlexGroup
                                                justifyContent='spaceBetween'
                                                alignItems='center'
                                                gutterSize='s'
                                            >
                                                <EuiFlexItem grow={false}></EuiFlexItem>
                                                <EuiFlexItem>
                                                    <EuiText style={{ textTransform: 'capitalize' }}>
                                                        {mode.display_name?.includes('_')
                                                            ? mode.display_name.replace('_', ' ')
                                                            : mode.display_name}
                                                    </EuiText>
                                                </EuiFlexItem>
                                            </EuiFlexGroup>
                                        </EuiFlexItem>

                                        <EuiFlexItem grow={false}>
                                            <OutletOrderingModeSwitch
                                                watch={watch}
                                                mode={mode}
                                                control={control}
                                                handleChange={handleChange}
                                            />
                                        </EuiFlexItem>
                                    </EuiFlexGroup>
                                </EuiPanel>
                            </React.Fragment>
                        );
                    })}
                {/* </LoadingOverlay> */}
            </FormGroupDescription>
            {doesDineInTabExist?.length ? (
                <CheckBoxField
                    control={control}
                    name='in_store_for_dine_in'
                    setValue={setValue}
                    label='Use In Store Instead of Dine In'
                />
            ) : null}
        </>
    );
};

export default React.memo(OrderingMode);
