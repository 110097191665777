import { EuiFlexGroup, EuiFlexItem, EuiTextColor } from '@elastic/eui';
import { isArray } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { fetchOutletOrderingModes } from '../../../api/outlet/fetchOutletOrderingModes';
import { userHasPermission } from '../../../helper/auth';
import SelectField from '../../../components/Form/SelectField';
import TimeSlot from '../../../components/Form/TimeSlot';
import FormGroupDescription from '../../../components/formGroupDescription';
import { formatedData } from '../../../components/settings/formatedData';
import OutletOrderingTimings from '../../../components/settings/outlet-form/OutletOrderingTimings';
import { multipleOutletModeTimings, updateOutletOrderingModes } from '../../../components/settings/outlet-form/utils';
import * as types from '../../../reduxStore/types/outlet';

const OutletTimings = ({ fetchOutletById }) => {
    const dispatch = useDispatch();
    const outletOrderingModes = useSelector((state) => state?.outlet?.outletOrderingModes?.ordering_modes);
    const outletData = useSelector((state) => state?.outlet?.outletOrderingModes);
    const outletId = useSelector((state) => state.outlet.selectedOutletId);

    const {
        handleSubmit,
        control,
        watch,
        reset,
        setValue,

        formState: { isDirty, errors },
    } = useForm();
    const [timeSlotError, setTimeSlotError] = useState(false);
    const permissions = useSelector((state) => state.permission.permission);

    const restaurantDescriptions = useSelector((state) => state.auth.restaurantDescriptions);

    const getOrderingModes = useCallback(async () => {
        dispatch(fetchOutletOrderingModes(outletId));
    }, [dispatch, outletId]);

    const resetChanges = useCallback(() => {
        let defaultValue = {};
        //set outlet time
        defaultValue.time_slot =
            outletData.settings.time_slot_setting === 'same_time_slot_for_all_days'
                ? 'outlet_same_day'
                : outletData.settings.time_slot_setting === 'different_time_slots_for_different_days'
                ? 'outlet_differant_day'
                : 'outlet_same_day';
        if (outletData.settings.time_slot_setting === 'same_time_slot_for_all_days') {
            let formatedTimeSlotInfo = formatedData(outletData.settings.schedules);
            let fieldData = [];
            formatedTimeSlotInfo &&
                formatedTimeSlotInfo[1] &&
                formatedTimeSlotInfo[1].map((item) =>
                    fieldData.push({
                        // id: uuid(),
                        start_time: item.start_time,
                        end_time: item.end_time,
                    })
                );
            defaultValue.time = fieldData;
        }
        defaultValue.week = {
            sunday: [],
            monday: [],
            tuesday: [],
            wednesday: [],
            thursday: [],
            friday: [],
            saturday: [],
        };
        if (outletData.settings.time_slot_setting === 'different_time_slots_for_different_days') {
            let formatedTimeSlotInfo = formatedData(outletData.settings.schedules);
            for (const key in formatedTimeSlotInfo) {
                if (key.toString() === '7') {
                    formatedTimeSlotInfo[7].map((item) =>
                        defaultValue.week.sunday.push({
                            // id: uuidv4(),
                            start_time: item.start_time,
                            end_time: item.end_time,
                        })
                    );
                }
                if (key.toString() === '1') {
                    formatedTimeSlotInfo[1].forEach((item) =>
                        defaultValue.week.monday.push({
                            // id: uuidv4(),
                            start_time: item.start_time,
                            end_time: item.end_time,
                        })
                    );
                }

                if (key.toString() === '2') {
                    formatedTimeSlotInfo[2].map((item) =>
                        defaultValue.week.tuesday.push({
                            // id: uuidv4(),
                            start_time: item.start_time,
                            end_time: item.end_time,
                        })
                    );
                }

                if (key.toString() === '3') {
                    formatedTimeSlotInfo[3].map((item) =>
                        defaultValue.week.wednesday.push({
                            // id: uuidv4(),
                            start_time: item.start_time,
                            end_time: item.end_time,
                        })
                    );
                }

                if (key.toString() === '4') {
                    formatedTimeSlotInfo[4].map((item) =>
                        defaultValue.week.thursday.push({
                            // id: uuidv4(),
                            start_time: item.start_time,
                            end_time: item.end_time,
                        })
                    );
                }

                if (key.toString() === '5') {
                    formatedTimeSlotInfo[5].map((item) =>
                        defaultValue.week.friday.push({
                            // id: uuidv4(),
                            start_time: item.start_time,
                            end_time: item.end_time,
                        })
                    );
                }

                if (key.toString() === '6') {
                    formatedTimeSlotInfo[6].map((item) =>
                        defaultValue.week.saturday.push({
                            // id: uuidv4(),
                            start_time: item.start_time,
                            end_time: item.end_time,
                        })
                    );
                }
            }
        }

        // timeslots for multiple ordering modes

        if (outletOrderingModes?.length) {
            for (const element of outletOrderingModes) {
                let diffFormatedScheduleMap = {};
                if (element.time_slot_setting !== 'no_time_slots' && isArray(element.timeslots)) {
                    for (let scheduleElement of element.timeslots) {
                        if (diffFormatedScheduleMap.hasOwnProperty(scheduleElement.day_of_week)) {
                            diffFormatedScheduleMap[scheduleElement.day_of_week].push({
                                start_time: scheduleElement.start_time,
                                end_time: scheduleElement.end_time,
                            });
                        } else {
                            diffFormatedScheduleMap[scheduleElement.day_of_week] = [
                                {
                                    start_time: scheduleElement.start_time,
                                    end_time: scheduleElement.end_time,
                                },
                            ];
                        }
                    }
                }

                if (element.time_slot_setting === 'same_time_slot_for_all_days') {
                    defaultValue[`time_${element.restaurant_ordering_mode_id}`] = diffFormatedScheduleMap['1'];
                    defaultValue[
                        `timer_for_item_${element.restaurant_ordering_mode_id}`
                    ] = `same_time_for_all_days_${element.restaurant_ordering_mode_id}`;
                } else if (element.time_slot_setting === 'different_time_slots_for_different_days') {
                    defaultValue.week = {
                        sunday: [],
                        monday: [],
                        tuesday: [],
                        wednesday: [],
                        thursday: [],
                        friday: [],
                        saturday: [],
                    };
                    for (const key in diffFormatedScheduleMap) {
                        if (key == 7) {
                            defaultValue.week[`sunday_${element.restaurant_ordering_mode_id}`] =
                                diffFormatedScheduleMap[key];
                        }
                        if (key == 1) {
                            defaultValue.week[`monday_${element.restaurant_ordering_mode_id}`] =
                                diffFormatedScheduleMap[key];
                        }
                        if (key == 2) {
                            defaultValue.week[`tuesday_${element.restaurant_ordering_mode_id}`] =
                                diffFormatedScheduleMap[key];
                        }
                        if (key == 3) {
                            defaultValue.week[`wednesday_${element.restaurant_ordering_mode_id}`] =
                                diffFormatedScheduleMap[key];
                        }
                        if (key == 4) {
                            defaultValue.week[`thursday_${element.restaurant_ordering_mode_id}`] =
                                diffFormatedScheduleMap[key];
                        }
                        if (key == 5) {
                            defaultValue.week[`friday_${element.restaurant_ordering_mode_id}`] =
                                diffFormatedScheduleMap[key];
                        }
                        if (key == 6) {
                            defaultValue.week[`saturday_${element.restaurant_ordering_mode_id}`] =
                                diffFormatedScheduleMap[key];
                        }
                    }
                    defaultValue[
                        `timer_for_item_${element.restaurant_ordering_mode_id}`
                    ] = `different_time_for_different_days_${element.restaurant_ordering_mode_id}`;
                } else {
                    defaultValue[
                        `timer_for_item_${element.restaurant_ordering_mode_id}`
                    ] = `same_day_of_the_week_${element.restaurant_ordering_mode_id}`;
                }
            }
        }

        // RESET FOR ORDERING MODES

        reset({ ...defaultValue, ordering_modes: outletOrderingModes });
    }, [outletData.settings.schedules, outletData.settings.time_slot_setting, outletOrderingModes, reset]);

    useEffect(() => {
        getOrderingModes();
    }, [getOrderingModes]);

    useEffect(() => {
        resetChanges();
    }, [resetChanges, outletOrderingModes]);

    useEffect(() => {
        if (!userHasPermission(permissions, '/outlet-settings', 'write')) return;

        if (isDirty) {
            dispatch({
                type: 'set-portal-active',
                payload: {
                    portalActive: {
                        resetChanges: resetChanges,
                        submit: handleSubmit(onSubmit),
                    },
                },
            });
        } else {
            dispatch({
                type: 'set-portal-active',
                payload: {
                    portalActive: {},
                },
            });
        }
    }, [isDirty]);

    const onSubmit = useCallback(
        async (data) => {
            // set outlet time
            let schedules;

            if (data.time_slot === 'outlet_same_day') {
                data.time_slot_setting = 'same_time_slot_for_all_days';
                schedules = [];

                let singleTimeSlot =
                    data.time &&
                    data.time.length > 0 &&
                    data.time.filter((item) => {
                        if (item.start_time !== undefined && item.end_time !== undefined) {
                            return item;
                        }
                        return null;
                    });
                singleTimeSlot &&
                    singleTimeSlot.length > 0 &&
                    schedules.push({
                        days_of_week: [1, 2, 3, 4, 5, 6, 7],
                        time_slots: singleTimeSlot,
                    });

                data.schedules = schedules.length > 0 ? schedules : null;
                if (!data.schedules) {
                    setTimeSlotError(true);
                    return false;
                }
            } else if (data.time_slot === 'outlet_differant_day') {
                schedules = [];
                data.time_slot_setting = 'different_time_slots_for_different_days';

                let sunday =
                    data.weak &&
                    data.weak.sunday &&
                    data.weak.sunday.filter((item) => {
                        if (item.start_time !== undefined && item.end_time !== undefined) {
                            return item;
                        }
                        return null;
                    });

                sunday &&
                    sunday.length > 0 &&
                    schedules.push({
                        days_of_week: [7],
                        time_slots: sunday,
                    });
                let monday =
                    data.weak &&
                    data.weak.monday &&
                    data.weak.monday.filter((item) => {
                        if (item.start_time !== undefined && item.end_time !== undefined) {
                            return item;
                        }
                        return null;
                    });
                monday &&
                    monday.length > 0 &&
                    schedules.push({
                        days_of_week: [1],
                        time_slots: monday,
                    });
                let tuesday =
                    data.weak &&
                    data.weak.tuesday &&
                    data.weak.tuesday.filter((item) => {
                        if (item.start_time !== undefined && item.end_time !== undefined) {
                            return item;
                        }
                        return null;
                    });
                tuesday &&
                    tuesday.length > 0 &&
                    schedules.push({
                        days_of_week: [2],
                        time_slots: tuesday,
                    });

                let wednesday =
                    data.weak &&
                    data.weak.wednesday &&
                    data.weak.wednesday.filter((item) => {
                        if (item.start_time !== undefined && item.end_time !== undefined) {
                            return item;
                        }
                        return null;
                    });
                wednesday &&
                    wednesday.length > 0 &&
                    schedules.push({
                        days_of_week: [3],
                        time_slots: wednesday,
                    });

                let thursday =
                    data.weak &&
                    data.weak.thursday &&
                    data.weak.thursday.filter((item) => {
                        if (item.start_time !== undefined && item.end_time !== undefined) {
                            return item;
                        }
                        return null;
                    });
                thursday &&
                    thursday.length > 0 &&
                    schedules.push({
                        days_of_week: [4],
                        time_slots: thursday,
                    });

                let friday =
                    data.weak &&
                    data.weak.friday &&
                    data.weak.friday.filter((item) => {
                        if (item.start_time !== undefined && item.end_time !== undefined) {
                            return item;
                        }
                        return null;
                    });
                friday &&
                    friday.length > 0 &&
                    schedules.push({
                        days_of_week: [5],
                        time_slots: friday,
                    });

                let saturday =
                    data.weak &&
                    data.weak.saturday &&
                    data.weak.saturday.filter((item) => {
                        if (item.start_time !== undefined && item.end_time !== undefined) {
                            return item;
                        }
                        return null;
                    });
                saturday &&
                    saturday.length > 0 &&
                    schedules.push({
                        days_of_week: [6],
                        time_slots: saturday,
                    });

                data.schedules = schedules.length > 0 ? schedules : null;
                if (!data.schedules) {
                    data.time_slot_setting = 'no_time_slots';
                }
            }

            if (outletOrderingModes?.length) {
                for (const orderMode of data?.ordering_modes) {
                    multipleOutletModeTimings(orderMode, data);
                }
            }
            setTimeSlotError(false);
            updateOutletOrderingModes(
                outletId,
                { ordering_modes: data.ordering_modes, schedules: data.schedules },
                'timing'
            ).then((response) => {
                if (response.success) {
                    dispatch({
                        type: types.SET_PORTAL_ACTIVE,
                        payload: {
                            portalActive: {},
                        },
                    });
                    dispatch({
                        type: types.SET_PORTAL_ACTIVE_TAB_SWITCHED,
                        payload: {
                            tabSwitched: false,
                        },
                    });
                    getOrderingModes();
                }
            });
        },
        [dispatch, getOrderingModes, outletId, outletOrderingModes?.length]
    );

    return (
        <>
            <FormGroupDescription
                title={restaurantDescriptions?.timing?.display_name}
                description={restaurantDescriptions?.timing?.description}
            >
                <EuiFlexGroup alignItems='center' style={{ marginTop: '10px' }}>
                    <SelectField
                        label={'Decide Outlet Timing'}
                        name='time_slot'
                        options={timeSlot}
                        valueOfSelected={
                            watch('time_slot') === 'outlet_differant_day' ? 'outlet_differant_day' : 'outlet_same_day'
                        }
                        onChange={(value) =>
                            setValue(
                                'time_slot',
                                value === 'outlet_differant_day' ? 'outlet_differant_day' : 'outlet_same_day',
                                { shouldDirty: true }
                            )
                        }
                        errors={errors}
                        control={control}
                    />
                </EuiFlexGroup>

                <EuiFlexGroup style={{ width: '90%' }}>
                    <div>
                        {watch('time_slot') === 'outlet_same_day' && (
                            <div style={{ marginTop: '30px', marginBottom: '30px' }}>
                                <TimeSlot
                                    reset={reset}
                                    control={control}
                                    setValue={setValue}
                                    name='time'
                                    watch={watch}
                                />
                            </div>
                        )}
                        {watch('time_slot') === 'outlet_differant_day' && (
                            <>
                                {weekDays.map((day, idx) => {
                                    return (
                                        <EuiFlexGroup
                                            style={{
                                                marginBottom: '30px',
                                                paddingBottom: '40px',
                                                borderBottom: '1px solid black',
                                            }}
                                            key={idx}
                                        >
                                            <EuiFlexGroup
                                                style={{
                                                    flexDirection: 'column',
                                                    textAlign: 'center',
                                                    paddingTop: '50px',
                                                }}
                                            >
                                                <EuiFlexItem
                                                    style={{
                                                        textAlign: 'left',
                                                        marginLeft: '3%',
                                                    }}
                                                >
                                                    <strong style={{ textTransform: 'capitalize' }}> {day} </strong>{' '}
                                                </EuiFlexItem>
                                                <TimeSlot
                                                    reset={reset}
                                                    control={control}
                                                    setValue={setValue}
                                                    name={`{week.${day}}`}
                                                    watch={watch}
                                                />
                                            </EuiFlexGroup>
                                        </EuiFlexGroup>
                                    );
                                })}
                            </>
                        )}
                    </div>
                    {timeSlotError && (
                        <EuiTextColor style={{ display: 'block' }} color='danger'>
                            please enter time slot
                        </EuiTextColor>
                    )}
                </EuiFlexGroup>
            </FormGroupDescription>
            <OutletOrderingTimings
                stateUpdate={timeSlotError}
                control={control}
                errors={errors}
                setValue={setValue}
                watch={watch}
            />
        </>
    );
};

export default React.memo(OutletTimings);

const timeSlot = [
    {
        value: 'outlet_same_day',
        inputDisplay: 'Same time for all days',
    },
    {
        value: 'outlet_differant_day',
        inputDisplay: 'Different time for different days',
    },
];

const weekDays = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
