import { combineReducers } from 'redux';
import auth from './auth';
import theme from './theme';
import orders from './orders';
import language from './language';
import notification from './notification';
import outlet from './outlet';
import rider from './rider';
import menu from './menu';
import offersReducer from './OffersReducer';
import category from './category';
import banner from './banner';
import item from './item';
import addon from './addon';
import permission from './permission';
import loading from './loading';
import user from './user';
import customer from './customer';
import billing from './billing';
import table from './table';
import tableBooking from './tableBooking';
import tableManagement from './tableManagement';
import petPoojaLink from './petPoojaLink';
import creditAlerts from './CreditAlerts';
import menuReducer from '../../components/menuComponentsRefactorCopy/Store/MenuReducer';
import petPoojaReducer from './PetPoojaReducer';
import itemAvailabilityReducer from './ItemAvailabilityReducer';
import toastReducer from './ToastReducer';
import restaurantReducer from './RestaurantReducer';

export default combineReducers({
    auth,
    theme,
    orders,
    language,
    notification,
    outlet,
    rider,
    menu,
    offersReducer,
    category,
    banner,
    item,
    addon,
    permission,
    loading,
    user,
    customer,
    billing,
    table,
    tableBooking,
    tableManagement,
    petPoojaLink,
    creditAlerts,
    menuReducer,
    petPoojaReducer,
    itemAvailabilityReducer,
    toastReducer,
    restaurantReducer,
});
