import { htmlIdGenerator } from '@elastic/eui';
import * as types from '../types/tableBooking';

const initialState = {
    tableBookingApprovedList: [],
    tableBookingPendingList: { loading: true, error: false, bookingList: undefined },
    approvedListUpdate: htmlIdGenerator()(),
    pendingListUpdate: htmlIdGenerator()(),
};

export default (state = initialState, action) => {
    switch (action.type) {
        case types.SET_TABLE_BOOKING_APROVED_LIST:
            return {
                ...state,
                tableBookingApprovedList: action.payload.tableBookingApprovedList,
            };
        case types.SET_TABLE_BOOKING_PENDING_LIST:
            return {
                ...state,
                tableBookingPendingList: action.payload.tableBookingPendingList,
            };
        case types.UPDATE_APPROVED_LIST:
            return {
                ...state,
                approvedListUpdate: action.payload.approvedListUpdate,
            };
        case types.UPDATE_PENDING_LIST:
            return {
                ...state,
                pendingListUpdate: action.payload.pendingListUpdate,
            };
        default:
            return state;
    }
};
