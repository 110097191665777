import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import TextField from '../../../components/Form/TextField';
import NumberField from '../../../components/Form/NumberField';
import TextAreaField from '../../../components/Form/TextAreaField';
import SelectField from '../../../components/Form/SelectField';
import CheckBoxField from '../../../components/Form/CheckBoxField';
import { allCountry } from '../../../components/settings/allCountry';
import API from '../../../api/axios/API';
import {
    EuiSpacer,
    EuiFormRow,
    EuiFieldText,
    EuiForm,
    EuiButton,
    EuiFlexGroup,
    EuiFlexItem,
    EuiText,
    EuiHorizontalRule,
    EuiButtonEmpty,
} from '@elastic/eui';
import SwitchField from '../../../components/Form/SwitchField';
import FormGroupDescription from '../../../components/formGroupDescription';
import LoaderButton from '../../../components/Form/loaderButton';
import { useDispatch } from 'react-redux';
import { outletSettingsAPI } from '../../../api/outlet/outletSettingsAPI';
import * as types from '../../../reduxStore/types/outlet';
import { userHasPermission } from '../../../helper/auth';

const AddressDetails = ({ getOutletData, fetchOutletById }) => {
    const languages = useSelector((state) => state.language.availableLanguages);
    const permissions = useSelector((state) => state.permission.permission);
    const restaurantDescriptions = useSelector((state) => state.auth.restaurantDescriptions);
    let countryCode = [];
    for (const [key, value] of Object.entries(allCountry)) {
        countryCode.push({
            value: key,
            inputDisplay: value,
        });
    }
    const {
        handleSubmit,
        control,
        watch,
        reset,
        setValue,

        formState: { isDirty, errors },
    } = useForm();
    let defaultValue = {};
    const [loader, setLoader] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!userHasPermission(permissions, '/outlet-settings', 'write')) return;

        if (isDirty) {
            dispatch({
                type: 'set-portal-active',
                payload: {
                    portalActive: {
                        resetChanges: resetChanges,
                        submit: handleSubmit(onSubmit),
                    },
                },
            });
        } else {
            dispatch({
                type: 'set-portal-active',
                payload: {
                    portalActive: {},
                },
            });
        }
    }, [isDirty]);

    const resetChanges = () => {
        const outletData = getOutletData.outlet;

        let translationObject = {
            city: {},
            state: {},
            address_line_1: {},
            address_line_2: {},
        };

        for (const languageId in outletData.outlet_address.translations.city) {
            translationObject.city[languageId] = outletData.outlet_address.translations.city[languageId];
        }
        for (const languageId in outletData.outlet_address.translations.state) {
            translationObject.state[languageId] = outletData.outlet_address.translations.state[languageId];
        }
        for (const languageId in outletData.outlet_address.translations.address_line_1) {
            translationObject.address_line_1[languageId] =
                outletData.outlet_address.translations.address_line_1[languageId];
        }
        for (const languageId in outletData.outlet_address.translations.address_line_2) {
            translationObject.address_line_2[languageId] =
                outletData.outlet_address.translations.address_line_2[languageId];
        }

        defaultValue.translations = translationObject;
        defaultValue.city = outletData.outlet_address.city;
        defaultValue.state = outletData.outlet_address.state;
        defaultValue.iso_country_code = outletData.outlet_address.iso_country_code;

        reset({
            ...defaultValue,
        });
    };

    useEffect(() => {
        if (getOutletData && getOutletData.outlet) {
            resetChanges();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getOutletData]);

    const onSubmit = async (data) => {
        const city = {};
        const state = {};
        const address_line_1 = {};
        const address_line_2 = {};
        data.id = null;
        if (getOutletData) {
            data.id = getOutletData.outlet.outlet_id;
        }

        for (const property in data.translations.city) {
            city[parseInt(property)] = data.translations.city[property];
        }
        for (const property in data.translations.state) {
            state[parseInt(property)] = data.translations.state[property];
        }
        for (const property in data.translations.address_line_1) {
            address_line_1[parseInt(property)] = data.translations.address_line_1[property];
        }
        for (const property in data.translations.address_line_2) {
            address_line_2[parseInt(property)] = data.translations.address_line_2[property];
        }

        data.translations.city = city;
        data.translations.state = state;
        data.translations.address_line_1 = address_line_1;
        data.translations.address_line_2 = address_line_2;

        data.address = {
            iso_country_code: data.iso_country_code,
            translations: {
                city: data.translations.city,
                state: data.translations.state,
                address_line_1: data.translations.address_line_1,
                address_line_2: data.translations.address_line_2,
            },
        };
        data.settings = {
            enable_auto_accept_order: data.enable_auto_accept_order,
        };
        delete data.translations.city;
        delete data.translations.state;
        delete data.translations.address_line_1;
        delete data.translations.address_line_2;

        // update api call fro basic info
        setLoader(true);
        dispatch(outletSettingsAPI('address', data)).then((res) => {
            if (res.success) {
                dispatch({
                    type: types.SET_PORTAL_ACTIVE,
                    payload: {
                        portalActive: {},
                    },
                });
                dispatch({
                    type: types.SET_PORTAL_ACTIVE_TAB_SWITCHED,
                    payload: {
                        tabSwitched: false,
                    },
                });
                fetchOutletById();
            } else {
                alert(res.message);
            }
        });
        // try {
        //     const updateBasicInfo = await API.put(
        //         `restaurants/:restaurantId/outlets/${data.id}?section=basic_info`,
        //         data
        //     );
        //     if (updateBasicInfo.success) {
        //         fetchOutletById();
        //         alert('Edit outlet successfully..');
        //     }
        // } catch (e) {
        //     alert('Please try later');
        // }
        setLoader(false);
    };

    return (
        <EuiForm component='form' onSubmit={handleSubmit(onSubmit)} style={{ marginTop: 20 }}>
            <FormGroupDescription
                title={restaurantDescriptions?.outlet_address?.display_name}
                description={restaurantDescriptions?.outlet_address?.description}
            >
                {languages &&
                    languages.map((item, index) => (
                        <TextAreaField
                            errors={errors}
                            isInvalid={
                                errors.translations &&
                                errors.translations.address_line_1 &&
                                errors.translations.address_line_1.hasOwnProperty(item.language_id)
                            }
                            error={
                                errors.translations &&
                                errors.translations.address_line_1 &&
                                errors.translations.address_line_1.hasOwnProperty(item.language_id) &&
                                errors.translations.address_line_1[item.language_id]
                                    ? errors.translations.address_line_1[item.language_id].message
                                    : ''
                            }
                            label={index === 0 ? <strong>Address Line 1</strong> : ''}
                            name={`translations.address_line_1.${item.language_id}`}
                            placeholder='Write address line 1'
                            rules={{ required: 'Please write address line 1' }}
                            control={control}
                            rows={4}
                        />
                    ))}

                <EuiSpacer margin='m' />

                {languages &&
                    languages.map((item, index) => (
                        <TextAreaField
                            errors={errors}
                            isInvalid={
                                errors.translations &&
                                errors.translations.address_line_2 &&
                                errors.translations.address_line_2.hasOwnProperty(item.language_id)
                            }
                            error={
                                errors.translations &&
                                errors.translations.address_line_2 &&
                                errors.translations.address_line_2.hasOwnProperty(item.language_id) &&
                                errors.translations.address_line_2[item.language_id]
                                    ? errors.translations.address_line_2[item.language_id].message
                                    : ''
                            }
                            label={index === 0 ? <strong>Address Line 2</strong> : ''}
                            name={`translations.address_line_2.${item.language_id}`}
                            placeholder='Write address line 1'
                            rules={{ required: 'Please write address line 1' }}
                            control={control}
                            rows={4}
                        />
                    ))}

                <EuiSpacer margin='m' />

                {languages &&
                    languages.map((item, index) => (
                        <>
                            <TextField
                                errors={errors}
                                isInvalid={
                                    errors.translations &&
                                    errors.translations.city &&
                                    errors.translations.city.hasOwnProperty(item.language_id)
                                }
                                error={
                                    errors.translations &&
                                    errors.translations.city &&
                                    errors.translations.city.hasOwnProperty(item.language_id) &&
                                    errors.translations.city[item.language_id]
                                        ? errors.translations.city[item.language_id].message
                                        : ''
                                }
                                label={index === 0 ? <strong>City</strong> : ''}
                                name={`translations.city.${item.language_id}`}
                                placeholder='Please Enter city'
                                rules={{ required: 'Please enter city' }}
                                control={control}
                            />
                            <EuiSpacer />
                        </>
                    ))}

                <EuiSpacer size='m' />

                {languages &&
                    languages.map((item, index) => (
                        <>
                            <TextField
                                errors={errors}
                                isInvalid={
                                    errors.translations &&
                                    errors.translations.state &&
                                    errors.translations.state.hasOwnProperty(item.language_id)
                                }
                                error={
                                    errors.translations &&
                                    errors.translations.state &&
                                    errors.translations.state.hasOwnProperty(item.language_id) &&
                                    errors.translations.state[item.language_id]
                                        ? errors.translations.state[item.language_id].message
                                        : ''
                                }
                                label={index === 0 ? <strong>State</strong> : ''}
                                name={`translations.state.${item.language_id}`}
                                placeholder='Please Enter state'
                                rules={{ required: 'Please enter state' }}
                                control={control}
                            />
                            <EuiSpacer />
                        </>
                    ))}
                <SelectField
                    errors={errors}
                    label={<b>Country</b>}
                    name='iso_country_code'
                    options={countryCode}
                    valueOfSelected={watch('iso_country_code') ? watch('iso_country_code') : 'IN'}
                    onChange={(value) => setValue('iso_country_code', value)}
                    placeholder='Please enter your country'
                    rules={{ required: 'Please Enter your country' }}
                    control={control}
                    defaultValue={watch('iso_country_code') ? watch('iso_country_code') : 'IN'}
                />
                <EuiSpacer />

                {/* {
                        isDirty ? (
                            <EuiFormRow>
                                <EuiFlexGroup alignItems="center">
                                    <LoaderButton isLoading={loader}/>
                                    <EuiFlexItem>
                                        <EuiButtonEmpty onClick={resetChanges}>Discard changes</EuiButtonEmpty>
                                    </EuiFlexItem>
                                </EuiFlexGroup>
                            </EuiFormRow>
                        ): ''
                    } */}
            </FormGroupDescription>
        </EuiForm>
    );
};

export default AddressDetails;
