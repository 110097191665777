import React, { useState, useEffect, useCallback } from 'react';
import { EuiComboBox } from '@elastic/eui';
import { useDispatch, useSelector } from 'react-redux';
import * as type from '../../../reduxStore/types/menu';
import {
    fetchActiveItemDetails,
    fetchCategoryItem,
    fetchSearchedCategoryItem,
} from '../catalogue/Api/FetchCatalogueInfo';
import _, { debounce } from 'lodash';
import { SET_ACTIVE_CATEGORY_ID, SET_ACTIVE_ITEM_DETAILS, SET_ACTIVE_ITEM_ID } from '../Store/MenuTypes';

const MenuSearch = () => {
    const [selectedSuggestionOption, setSelectedSuggestionOption] = useState([]);
    const [options, setOptions] = useState([]);
    const languageId = useSelector((state) => state.language.languageId);
    const dispatch = useDispatch();
    const [scrollername, setScrollerName] = useState('');
    const categories = useSelector((state) => state.menuReducer.restaurantCategories);

    useEffect(() => {
        if (scrollername) {
            let scroller = document.getElementById(scrollername);
            scroller && scroller.scrollIntoView();
        }
    }, [scrollername]);

    const fetchSelectedItem = useCallback(
        async (id) => {
            dispatch({
                type: SET_ACTIVE_ITEM_ID,
                payload: id,
            });
            dispatch({
                type: type.SET_ACTIVE_ITEM_ID,
                payload: {
                    activeItemId: id,
                },
            });
            dispatch({
                type: SET_ACTIVE_ITEM_DETAILS,
                payload: { isLoading: true },
            });
            fetchActiveItemDetails(id).then((response) => {
                if (response.success) {
                    const item = response.item;
                    dispatch({
                        type: SET_ACTIVE_ITEM_DETAILS,
                        payload: { details: item, isLoading: false },
                    });
                    dispatch({
                        type: type.EDIT_ITEM,
                        payload: {
                            editItem: item,
                        },
                    });
                    dispatch({
                        type: type.SET_ADDON_ITEM_EDIT_SELECTED_LIST,
                        payload: {
                            selectedItemEditAddOnList: item.addon_groups?.length ? item.addon_groups : [],
                        },
                    });
                    dispatch({
                        type: type.SET_UPSELLING_ITEM_EDIT_SELECTED_LIST,
                        payload: {
                            selectedItemEditUpsellingList: item.upselling_items?.length ? item.upselling_items : [],
                        },
                    });
                }
            });
        },
        [dispatch]
    );

    const handleCategoryClick = useCallback(
        (selectedCategory) => {
            if (selectedCategory?.id) {
                if (selectedCategory?.category_id) {
                    fetchCategoryItem(selectedCategory?.category_id, languageId).then((response) => {
                        if (response.success) {
                            if (response?.items?.length) {
                                fetchSelectedItem(response?.items?.[0]?.item_id);
                            }
                        }
                    });

                    dispatch({
                        type: SET_ACTIVE_CATEGORY_ID,
                        payload: selectedCategory.category_id,
                    });
                }

                let scroller = document.getElementById(selectedCategory.internal_name);
                setScrollerName(selectedCategory.internal_name);
                // if (scroller) {
                //     scroller.scrollIntoView();
                // }
            }
        },
        [languageId, dispatch, fetchSelectedItem]
    );

    const onChange = useCallback(
        (selectedOptions) => {
            setSelectedSuggestionOption(selectedOptions);

            if (selectedOptions?.length) {
                let subCategories = [];
                if (!selectedOptions?.[0]?.items?.length && !selectedOptions?.[0]?.item_id) {
                    // check if the selected option has a sub category
                    for (let category of categories) {
                        if (category.parent_id === selectedOptions[0].category_id) {
                            category.id = category.category_id;
                            subCategories.push(category);
                        }
                    }

                    if (subCategories?.length) {
                        handleCategoryClick(subCategories[0]);
                    }
                }

                if (!subCategories?.length) {
                    if (selectedOptions?.[0]?.item_id) {
                        fetchSelectedItem(selectedOptions?.[0]?.item_id);
                    } else if (selectedOptions?.[0]?.category_id) {
                        dispatch({
                            type: SET_ACTIVE_CATEGORY_ID,
                            payload: selectedOptions?.[0]?.category_id,
                        });

                        handleCategoryClick(selectedOptions?.[0]);
                    }
                }
                let scroller = document.getElementById(selectedOptions[0].internal_name);
                setScrollerName(selectedOptions[0].internal_name);
                // if (scroller) {
                //     scroller.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
                // }
            } else {
                setScrollerName('');
            }
        },
        [categories, dispatch, fetchSelectedItem, handleCategoryClick]
    );

    const debouncedSearch = debounce(async (text, languageId) => {
        await fetchSearchedCategoryItem(text, languageId).then((response) => {
            if (response?.categories?.length) {
                let data = [];
                for (const matched_data of response.categories) {
                    let matchedObj = {
                        id: matched_data.category_id,
                        category_id: matched_data.category_id,
                        items: matched_data.items,
                        label: matched_data.translations.title[languageId] + ' - ' + matched_data.internal_name,
                        internal_name: matched_data.internal_name,
                    };

                    if (matched_data?.items?.length) {
                        for (const item of matched_data.items) {
                            let itemsObj = {
                                id: item.item_id,
                                item_id: item.item_id,
                                category_id: item.category_id,
                                label: item.translations.title[languageId] + ' - ' + item.internal_name,
                                internal_name: item.internal_name,
                            };
                            data.push(itemsObj);
                        }
                    }

                    data.push(matchedObj);
                }
                setOptions(data);
            }
        });
    }, 1000);

    useEffect(() => {
        return function cleanup() {
            debouncedSearch.cancel();
        };
    }, [debouncedSearch]);

    const onSearchChange = useCallback(
        (text) => {
            // handles search functionality
            if (!_.isEmpty(text)) {
                debouncedSearch(text, languageId);
            }
        },
        [debouncedSearch, languageId]
    );

    return (
        <EuiComboBox
            options={options}
            singleSelection={{ asPlainText: true }}
            selectedOptions={selectedSuggestionOption}
            onChange={onChange}
            onSearchChange={onSearchChange}
            placeholder='Search category or an item'
        />
    );
};

export default React.memo(MenuSearch);
