import React from 'react';
import {
    EuiFlexGroup,
    EuiFlexItem,
    EuiDragDropContext,
    EuiButton,
    EuiDroppable,
    EuiPanel,
    euiDragDropMove,
    euiDragDropReorder,
    EuiDraggable,
    htmlIdGenerator,
    EuiFormRow,
    EuiFieldText,
    EuiButtonIcon,
    EuiIcon,
    EuiCheckbox,
} from '@elastic/eui';
import { Controller, useFieldArray } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import * as types from '../../reduxStore/types/billing';
const OrderItemSplitDraggable = (props) => {
    const { field, item, idx, type } = props;
    const selectedDraggableItems = useSelector((state) => state.billing.selectedDraggableItems);
    const dispatch = useDispatch();
    const matchedCheck = () => {
        let check = false;
        selectedDraggableItems.map((innerItem) => {
            if (innerItem.id == item.id) {
                check = true;
            }
        });
        return check;
    };

    const onChange = (e) => {
        // setSelect(e.target.checked);

        let listLength = selectedDraggableItems.filter((item) => item.type != type);

        console.log('list length ', listLength);
        let innerArray = !listLength.length ? [...selectedDraggableItems] : [];

        if (e.target.checked) {
            innerArray.push({ type: type, id: item.id });
        } else {
            let indexValue;
            innerArray.map((item, index) => {
                if (item.id == item.id) {
                    indexValue = index;
                }
            });
            innerArray.splice(indexValue, 1);
        }

        dispatch({
            type: types.SET_SELECTED_DRAGGABLE_ITEMS,
            payload: {
                selectedDraggableItems: innerArray,
            },
        });
    };

    return (
        <div style={{ width: '100%', height: '100%', marginRight: '15px' }} key={field.id}>
            <EuiDraggable key={item.id} index={idx} draggableId={item.id} spacing='m'>
                {(provided, state) => (
                    <EuiPanel style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <div {...provided.dragHandleProps} style={{ marginRight: '10px' }}>
                            <EuiIcon type='grab' />
                        </div>
                        <div>
                            {item.content}
                            {state.isDragging && ' ✨'}
                        </div>
                        <div>
                            <EuiCheckbox checked={matchedCheck()} onChange={(e) => onChange(e)} />
                        </div>
                    </EuiPanel>
                )}
            </EuiDraggable>
        </div>
    );
};

export default OrderItemSplitDraggable;
