import API from '../axios/API';
import {
    LOW_INFRASTRUCTURE_CREDIT_ALERT_AMOUNT,
    LOW_SERVICE_CREDIT_ALERT_AMOUNT,
} from '../../reduxStore/types/CreditAlert';

export const saveCreditAlert = (amount, typeOfAlert) => async (dispatch, getState) => {
    let res;

    const outlet_id = getState().outlet.selectedOutletId;

    if (typeOfAlert === 'service') {
        try {
            res = await API.put(
                `${process.env.REACT_APP_BASE_URL_V3}/restaurants/:restaurantId/outlets/${outlet_id}?section=credit_settings`,
                {
                    low_service_credit_alert_amount: amount,
                }
            );
        } catch (error) {
            console.log('PutCredit', error);
        }
    }

    if (typeOfAlert === 'infrastructure') {
        try {
            res = await API.put(
                `${process.env.REACT_APP_BASE_URL_V3}/restaurants/:restaurantId?section=credit_settings`,
                {
                    low_infrastructure_credit_alert_amount: amount,
                }
            );
        } catch (error) {
            console.log('PutCredit', error);
        }
    }

    return res;
};
