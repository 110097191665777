import { EuiFieldSearch } from '@elastic/eui';
import React, { useCallback, useState } from 'react';

const FilterSearchedItems = ({ currentTab, handleSearchedItems, isLoading }) => {
    const [searchedQuery, setSearchedQuery] = useState('');

    const handleSearchChange = useCallback(
        (event) => {
            setSearchedQuery(event.target.value);
            handleSearchedItems(event.target.value);
        },
        [handleSearchedItems]
    );

    return (
        <EuiFieldSearch
            placeholder={`${currentTab === 'item_availability' ? 'Search Category Items' : 'Search Addons'} `}
            value={searchedQuery}
            isClearable={true}
            onChange={handleSearchChange}
            disabled={isLoading}
        />
    );
};

export default React.memo(FilterSearchedItems);
