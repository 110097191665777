import React, { useCallback, useEffect, useState } from 'react';
import { EuiText, EuiButtonEmpty, EuiDraggable, EuiIcon, EuiFlexItem, EuiAccordion, EuiSpacer } from '@elastic/eui';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { userHasPermission } from '../../../helper/auth';
import * as type from '../../../reduxStore/types/menu';
import ItemList from './itemList';
import { fetchCategoryItem, fetchOutletCategoryItem, getSelectedCategoryDetails } from './Api/FetchCatalogueInfo';
import { SET_ACTIVE_CATEGORY_ID, UPDATE_ITEM_LIST } from '../Store/MenuTypes';
import { isEmpty } from 'lodash';
import CategoryChildRow from './CategoryChildRow';

const CategoryRow = (props) => {
    const { category, isOutletMenu, index } = props;
    const dispatch = useDispatch();
    const history = useHistory();
    const shouldUpdateItem = useSelector((state) => state.menuReducer.shouldUpdateItem);
    const permissions = useSelector((state) => state.permission.permission);
    const languageId = useSelector((state) => state.language.languageId);
    const categories = useSelector((state) => state.menuReducer.restaurantCategories);
    const selectedOutletId = useSelector((state) => state.outlet.selectedOutletId);

    const [listDetails, setListDetails] = useState({
        items: [],
        childCategories: categories.filter((caetgoryItem) => caetgoryItem.parent_id === category.category_id),
        isLoading: true,
    });

    const handleCategoryClick = useCallback(() => {
        if (!listDetails.childCategories.length) {
            if (!isOutletMenu) {
                fetchCategoryItem(category.category_id, languageId).then((response) => {
                    setListDetails((prevState) => ({ ...prevState, isLoading: true }));
                    if (response.success) {
                        setListDetails((prevState) => ({ ...prevState, items: [...response.items], isLoading: false }));
                    }
                });
            } else {
                fetchOutletCategoryItem(category.category_id, languageId, selectedOutletId).then((response) => {
                    setListDetails((prevState) => ({ ...prevState, isLoading: true }));
                    if (response.success) {
                        setListDetails((prevState) => ({ ...prevState, items: [...response.items], isLoading: false }));
                    }
                });
            }
        }

        dispatch({
            type: SET_ACTIVE_CATEGORY_ID,
            payload: category.category_id,
        });
    }, [
        listDetails.childCategories.length,
        dispatch,
        category.category_id,
        isOutletMenu,
        languageId,
        selectedOutletId,
    ]);

    const addSubCategory = useCallback(() => {
        dispatch({
            type: type.SET_ADD_CATEGORY_ID,
            payload: {
                addCategory: category.category_id,
            },
        });
        history.push('/category?category=add');
    }, [dispatch, history]);

    const defaultValueSet = useCallback((defaultObject, item) => {
        for (let key in item.title) {
            defaultObject[`title${key}`] = item.title[key];
        }

        for (let key in item.description) {
            defaultObject[`description${key}`] = item.description[key];
        }

        for (let key in item.size) {
            defaultObject[`size${key}`] = item.size[key];
        }

        for (let key in item.serves) {
            defaultObject[`serves${key}`] = item.serves[key];
        }
    }, []);

    const button = useCallback(
        (provided) => {
            return (
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                    id={category.internal_name}
                >
                    <div style={{ display: 'flex', flexDirection: 'row', width: '80%' }}>
                        {userHasPermission(permissions, history.location.pathname, 'write') && (
                            <div
                                style={{
                                    justifyContent: 'center',
                                    display: 'flex',
                                    width: '12%',
                                    alignItems: 'center',
                                }}
                            >
                                <div {...provided.dragHandleProps}>
                                    <EuiIcon type='grab' />
                                </div>
                            </div>
                        )}

                        <div style={{ minWidth: '175px', marginLeft: '12px' }}>
                            <EuiText size='m' style={{ fontWeight: '500' }}>
                                {isOutletMenu ? category.translations.title[languageId] : category.internal_name}
                            </EuiText>
                        </div>
                    </div>
                </div>
            );
        },
        [category.internal_name, category.translations.title, history, isOutletMenu, languageId, permissions]
    );

    const action = useCallback(() => {
        return (
            <>
                {userHasPermission(permissions, history.location.pathname, 'write') && !isOutletMenu && (
                    <EuiButtonEmpty
                        style={{
                            position: 'absolute',
                            right: '5%',
                            top: '12px',
                        }}
                        onClick={() => editCategory(category.category_id)}
                    >
                        Edit
                    </EuiButtonEmpty>
                )}
            </>
        );
    }, [category.category_id, history.location.pathname, isOutletMenu, permissions]);

    const editCategory = useCallback(
        async (id) => {
            let defaultValueObject = {};
            const category = await dispatch(getSelectedCategoryDetails(id));
            if (!isEmpty(category)) {
                defaultValueSet(defaultValueObject, category.translations);
                defaultValueObject['id'] = category.category_id;
                defaultValueObject['category_image_url'] = category.category_image_url;
                defaultValueObject['category_menu_image_url'] = category.category_menu_image_url;
                defaultValueObject['internal_name'] = category.internal_name;
                defaultValueObject['hidden'] = category.hidden;
                defaultValueObject['hide_category_tile_details'] = category.hide_category_tile_details ? true : false;
                defaultValueObject['apply_mask'] = category.apply_mask;
                defaultValueObject['delivery'] = category.delivery;
                defaultValueObject['dine_in'] = category.dine_in;
                defaultValueObject['takeaway'] = category.takeaway;
                defaultValueObject['ordering_modes'] = category.ordering_modes;
                defaultValueObject['theme'] = category?.theme ? category.theme : 'square_image';
                defaultValueObject['tile_details_position'] = category?.tile_details_position
                    ? category.tile_details_position
                    : '';
                defaultValueObject['gradient_position'] = category?.gradient_position ? category.gradient_position : '';

                for (const element of category?.ordering_modes) {
                    defaultValueObject[`mode_${element.restaurant_ordering_mode_id}`] = true;
                }

                if (category.parent_id) {
                    defaultValueObject['parent_id'] = category.parent_id;
                }
                if (category.addon_groups !== undefined && category.addon_groups.length > 0) {
                    dispatch({
                        type: type.SET_ADDON_SELECTED_LIST,
                        payload: {
                            selectedAddOnList: category.addon_groups,
                        },
                    });
                } else {
                    dispatch({
                        type: type.SET_ADDON_SELECTED_LIST,
                        payload: {
                            selectedAddOnList: [],
                        },
                    });
                }
            }

            dispatch({
                type: type.EDIT_CATEGORY,
                payload: {
                    editCategory: defaultValueObject,
                },
            });

            history.push('/category?category=edit');
        },
        [defaultValueSet, dispatch, history]
    );

    const loadingItems = useCallback(() => {
        return listDetails.isLoading && !listDetails.childCategories.length;
    }, [listDetails]);

    const hasChildCategories = useCallback(() => {
        return listDetails.childCategories.length;
    }, [listDetails]);

    const fetchUpdatedItemList = useCallback(() => {
        handleCategoryClick();

        dispatch({
            type: UPDATE_ITEM_LIST,
            payload: { update: false, details: {} },
        });
    }, [dispatch, handleCategoryClick]);

    useEffect(() => {
        if (shouldUpdateItem?.update && shouldUpdateItem?.details?.category_id === category.category_id) {
            fetchUpdatedItemList();
        }

        return function cleanup() {
            dispatch({
                type: UPDATE_ITEM_LIST,
                payload: { update: false, details: {} },
            });
        };
    }, [
        category?.category_id,
        dispatch,
        fetchUpdatedItemList,
        shouldUpdateItem?.details?.category_id,
        shouldUpdateItem?.update,
    ]);

    return (
        <>
            <EuiDraggable
                spacing='m'
                key={category.category_id.toString()}
                index={index}
                draggableId={category.category_id.toString()}
                customDragHandle={true}
                style={{
                    position: 'relative',
                }}
            >
                {(provided) => (
                    <EuiAccordion
                        id={category.category_id.toString()}
                        onToggle={(isOpen) => {
                            if (isOpen && !listDetails.items.length) {
                                handleCategoryClick();
                            }
                        }}
                        buttonContent={button(provided)}
                        extraAction={action()}
                        style={{ padding: '16px 0' }}
                    >
                        {loadingItems() ? (
                            <EuiFlexItem grow={false}>
                                <EuiButtonEmpty isLoading={true}>Loading</EuiButtonEmpty>
                            </EuiFlexItem>
                        ) : (
                            <>
                                {hasChildCategories() ? (
                                    <CategoryChildRow
                                        key={category.category_id}
                                        isOutletMenu={isOutletMenu}
                                        index={index}
                                        category={category}
                                    />
                                ) : (
                                    <>
                                        <ItemList
                                            key={category.category_id}
                                            itemList={listDetails.items}
                                            setListDetails={setListDetails}
                                            isOutletMenu={isOutletMenu}
                                            category={category}
                                        />
                                        {isOutletMenu ? <EuiSpacer /> : null}
                                    </>
                                )}
                            </>
                        )}

                        {userHasPermission(permissions, history.location.pathname, 'write') && !isOutletMenu && (
                            <EuiButtonEmpty style={{ marginBottom: '8px' }} onClick={() => addSubCategory()}>
                                Add sub category
                            </EuiButtonEmpty>
                        )}
                    </EuiAccordion>
                )}
            </EuiDraggable>
        </>
    );
};

export default React.memo(CategoryRow);
