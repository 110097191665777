import React, { useEffect, useState, useCallback, memo } from 'react';
import { withGoogleMap, GoogleMap, withScriptjs, InfoWindow, Marker } from 'react-google-maps';
import Geocode from 'react-geocode';
import { EuiComboBox, EuiFlexItem, EuiText, EuiFormRow, EuiFieldText, EuiFlexGroup, EuiButtonIcon } from '@elastic/eui';
import _ from 'lodash';
import API from '../../api/axios/API';
import { Controller } from 'react-hook-form';
import GoogleMapReact from 'google-map-react';
import ComboBoxField from '../Form/ComboBoxField';
import FormStepsFooter from '../billingCopy/formStepsFooter';
import { useSelector, useDispatch } from 'react-redux';
import * as types from '../../reduxStore/types/billing';
import { ReactComponent as MapMarker } from '../../assets/img/marker.svg';
import './style.css';

Geocode.setApiKey('AIzaSyBLcauGM860cMMRQ4pU4hf2PvMagDISslg');
Geocode.enableDebug();

const MapComponent = (props) => {
    const { errors, control, setValue, defaultLatLong, watch, setButtonClicked, reset } = props;
    const [position, setPosition] = useState(defaultLatLong);
    const [options, setOptions] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const customerFirstOrder = useSelector((state) => state.billing.customerFirstOrder);
    const dispatch = useDispatch();

    const MarkerComponent = ({ text }) => (
        // <>
        //     <div style={{marginBottom: '17px'}}>
        //         <MapMarker />
        //     </div>
        // </>
        // <div style={{
        //   color: 'white',
        //   background: 'grey',
        //   padding: '15px 10px',
        //   display: 'inline-flex',
        //   textAlign: 'center',
        //   alignItems: 'center',
        //   justifyContent: 'center',
        //   borderRadius: '100%',
        //   transform: 'translate(-50%, -50%)'
        // }}>
        //   {/* {text} */}
        //   <MapMarker />
        // </div>
        <div style={{ marginBottom: '20px' }}>
            <div class='pin'></div>
            <div class='pulse'></div>
        </div>
    );

    const makeDirty = () => {
        if (!customerFirstOrder) {
            dispatch({
                type: types.SET_IS_DIRTY,
                payload: {
                    isDirty: true,
                },
            });
        }
    };

    const getLocations = async (query) => {
        if (query.trim()) {
            let locationResponse;
            setLoading(true);
            try {
                locationResponse = await API.get(`en/restaurants/:restaurantId/google/search?keyword=${query}`);
            } catch (error) {
                locationResponse = error;
            }

            if (locationResponse.success) {
                let innerArray = [];
                locationResponse.address &&
                    locationResponse.address.predictions &&
                    locationResponse.address.predictions.map((prediction) => {
                        innerArray.push({
                            label: `${prediction.description} - ${query}`,
                            prediction_value: prediction,
                        });
                    });
                setOptions(innerArray);
            } else {
                alert('Error in location searching');
            }
            setLoading(false);
        }
    };

    const getLocationLatLng = async (place_id) => {
        let locationResponse;

        try {
            locationResponse = await API.get(`en/restaurants/:restaurantId/google/place/details?place_id=${place_id}`);
        } catch (error) {
            locationResponse = error;
        }

        console.log('location response ', locationResponse);
        if (locationResponse.success) {
            setPosition({ ...position, ...locationResponse.address.geometry });

            updateGeoLocation(locationResponse.address.geometry);
        } else {
            alert('Error in location searching');
        }
    };

    const delayedQuery = useCallback(
        _.debounce((q) => getLocations(q), 500),
        []
    );

    const updateGeoLocation = (position) => {
        Geocode.fromLatLng(position.lat, position.lng).then(
            (response) => {
                let address = {
                    address: response.results[0].formatted_address,
                    addressArray: response.results[0].address_components,
                    city: getCity(response.results[0].address_components),
                    state: getArea(response.results[0].address_components),
                    area: getState(response.results[0].address_components),
                };

                setValue('city', address.city);
                setValue('state', address.state);
                setValue('region', address.area);
                setValue('location_name', address.address);

                setPosition({ ...position, ...address });
            },
            (error) => {
                console.error(error);
            }
        );
        setValue('latitude', position.lat);
        setValue('longitude', position.lng);
    };

    useEffect(() => {
        updateGeoLocation(position);
    }, []);

    const getCity = (addressArray) => {
        let city = '';

        for (let i = 0; i < addressArray.length; i++) {
            if (addressArray[i].types[0] && 'administrative_area_level_2' === addressArray[i].types[0]) {
                city = addressArray[i].long_name;
                return city;
            }
        }
    };

    const getState = (addressArray) => {
        let state = '';
        for (let i = 0; i < addressArray.length; i++) {
            for (let i = 0; i < addressArray.length; i++) {
                if (addressArray[i].types[0] && 'administrative_area_level_1' === addressArray[i].types[0]) {
                    state = addressArray[i].long_name;
                    return state;
                }
            }
        }
    };

    const getArea = (addressArray) => {
        let area = '';
        for (let i = 0; i < addressArray.length; i++) {
            if (addressArray[i].types[0]) {
                for (let j = 0; j < addressArray[i].types.length; j++) {
                    if ('sublocality_level_1' === addressArray[i].types[j] || 'locality' === addressArray[i].types[j]) {
                        area = addressArray[i].long_name;
                        return area;
                    }
                }
            }
        }
    };

    const onMarkerDragEnd = (event) => {
        let newLat = event.latLng.lat(),
            newLng = event.latLng.lng(),
            location_name,
            addressArray = [];

        updateGeoLocation({ lat: newLat, lng: newLng });
    };

    const onInfoWindowClose = (event) => {};

    const Map = withScriptjs(
        withGoogleMap((props) => (
            <GoogleMap
                defaultZoom={15}
                defaultCenter={{ lat: position.lat, lng: position.lng }}
                onMouseMove={(e) => console.log('google map moved', e)}
            >
                <Marker
                    name={'Dolores park'}
                    draggable={true}
                    onDragEnd={onMarkerDragEnd}
                    position={{ lat: position.lat, lng: position.lng }}
                />
                <Marker />

                <InfoWindow onClose={onInfoWindowClose} position={{ lat: position.lat + 0.0018, lng: position.lng }}>
                    <div>
                        <span style={{ padding: 0, margin: 0 }}>{position.address}</span>
                    </div>
                </InfoWindow>
            </GoogleMap>
        ))
    );

    const locationChange = (searchValue, hasMatchingOptions) => {
        delayedQuery(searchValue);
    };

    const onChangeLocation = (selectedOptions) => {
        console.log('inside on change location', selectedOptions);
        setSelectedOptions(selectedOptions);

        if (selectedOptions.length) {
            getLocationLatLng(selectedOptions[0].prediction_value.place_id);
            setValue('location', selectedOptions);
        } else {
            setValue('location', '');
        }
    };

    const onMapMove = (e) => {
        setPosition({ lat: e.lat + 0.000007, lng: e.lng + 0.000003 });
        // setValue('latitude', e.lat)
        // setValue('longitude', e.lng)
        updateGeoLocation({ lat: e.lat + 0.000007, lng: e.lng + 0.000003 });
    };

    console.log('positions ', position, 'selection ', selectedOptions);
    return (
        <div
            style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
            className='customerForm'
        >
            <div style={{ marginBottom: '10px' }}>
                {/* <EuiComboBox 
                            placeholder="Enter Location"
                            options={options}
                            selectedOptions={selectedOptions}
                            onSearchChange={locationChange}
                            isLoading={loading}
                            fullWidth={true}
                            isClearable={true}
                            singleSelection={{ asPlainText: true }}
                            onChange={onChange}
                        /> */}
                {/* label */}
                <ComboBoxField
                    name='location'
                    placeholder='Enter Location'
                    options={options}
                    selectedOptions={selectedOptions}
                    onSearchChange={locationChange}
                    isLoading={loading}
                    fullWidth={true}
                    isClearable={true}
                    singleSelection={{ asPlainText: true }}
                    onChange={onChangeLocation}
                    control={control}
                    errors={errors}
                    onKeyDown={makeDirty}
                    map
                    // rules={{
                    //     required: 'Please enter location',
                    // }}
                    label='Location'
                />
            </div>
            <div style={{ display: 'none' }}>
                <EuiFormRow
                    label={'latitude'}
                    fullWidth={true}
                    error={errors['latitude'] ? errors['latitude'].message : ''}
                    isInvalid={errors['latitude']}
                >
                    <Controller
                        render={({ field }) => <EuiFieldText value={field.value} onChange={field.onChange} />}
                        name={'latitude'}
                        placeholder='latitude'
                        control={control}
                        fullWidth={true}
                        rules={{
                            required: 'Please enter latitude',
                        }}
                        isInvalid={errors['latitude']}
                    />
                </EuiFormRow>
                <EuiFormRow
                    label={'longitude'}
                    fullWidth={true}
                    error={errors['longitude'] ? errors['longitude'].message : ''}
                    isInvalid={errors['longitude']}
                >
                    <Controller
                        render={({ field }) => <EuiFieldText value={field.value} onChange={field.onChange} />}
                        name={'longitude'}
                        placeholder='Cover count'
                        control={control}
                        fullWidth={true}
                        rules={{
                            required: 'Please enter longitude',
                        }}
                        isInvalid={errors['longitude']}
                    />
                </EuiFormRow>
                <EuiFormRow
                    label={'location_name'}
                    fullWidth={true}
                    error={errors['location_name'] ? errors['location_name'].message : ''}
                    isInvalid={errors['location_name']}
                >
                    <Controller
                        render={({ field }) => <EuiFieldText value={field.value} onChange={field.onChange} />}
                        name={'location_name'}
                        placeholder='Cover count'
                        control={control}
                        fullWidth={true}
                        rules={{
                            required: 'Please enter location_name',
                        }}
                        isInvalid={errors['location_name']}
                    />
                </EuiFormRow>
                <EuiFormRow
                    label={'city'}
                    fullWidth={true}
                    error={errors['city'] ? errors['city'].message : ''}
                    isInvalid={errors['city']}
                >
                    <Controller
                        render={({ field }) => <EuiFieldText value={field.value} onChange={field.onChange} />}
                        name={'city'}
                        placeholder='city'
                        control={control}
                        fullWidth={true}
                        rules={{
                            required: 'Please enter city',
                        }}
                        isInvalid={errors['city']}
                    />
                </EuiFormRow>
                <EuiFormRow
                    label={'state'}
                    fullWidth={true}
                    error={errors['state'] ? errors['state'].message : ''}
                    isInvalid={errors['state']}
                >
                    <Controller
                        render={({ field }) => <EuiFieldText value={field.value} onChange={field.onChange} />}
                        name={'state'}
                        placeholder='state'
                        control={control}
                        fullWidth={true}
                        rules={{
                            required: 'Please enter state',
                        }}
                        isInvalid={errors['state']}
                    />
                </EuiFormRow>
                <EuiFormRow
                    label={'region'}
                    fullWidth={true}
                    error={errors['region'] ? errors['region'].message : ''}
                    isInvalid={errors['region']}
                >
                    <Controller
                        render={({ field }) => <EuiFieldText value={field.value} onChange={field.onChange} />}
                        name={'region'}
                        placeholder='region'
                        control={control}
                        fullWidth={true}
                        rules={{
                            required: 'Please enter region',
                        }}
                        isInvalid={errors['region']}
                    />
                </EuiFormRow>
            </div>
            {/* https://maps.googleapis.com/maps/api/js?key=AIzaSyBLcauGM860cMMRQ4pU4hf2PvMagDISslg&&libraries=places */}
            <div style={{ height: '400px', width: '98%', margin: '10px' }}>
                {/* {
                        <Map 
                            googleMapURL='https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=places'
                            loadingElement={<div style={{ height: `100%` }} />}
                            containerElement={<div style={{ height: '350px' }} />}
                            mapElement={<div style={{ height: `100%` }} />}
                        />
                    } */}
                <GoogleMapReact
                    bootstrapURLKeys={{ key: 'AIzaSyBLcauGM860cMMRQ4pU4hf2PvMagDISslg' }}
                    center={position}
                    defaultZoom={15}
                    onClick={(e) => onMapMove(e)}
                    // yesIWantToUseGoogleMapApiInternals
                >
                    <MarkerComponent lat={position.lat} lng={position.lng} text='M' />
                    {/* <Marker
                                name={'Dolores park'}
                                    draggable={true}
                                    onDragEnd={onMarkerDragEnd}
                                        position={{ lat: position.lat, lng: position.lng }}
                                />
                            <Marker />

                            <InfoWindow
                                onClose={onInfoWindowClose}
                                position={{ lat: ( position.lat + 0.0018 ), lng: position.lng }}
                                >
                                <div>
                                    <span style={{ padding: 0, margin: 0 }}>{ position.address }</span>
                                </div>
                            </InfoWindow> */}
                </GoogleMapReact>
            </div>
            <FormStepsFooter
                reset={reset}
                errors={errors}
                watch={watch}
                step={4}
                {...props}
                setButtonClicked={setButtonClicked}
            />
        </div>
    );
};

export default memo(MapComponent);
