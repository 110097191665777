import * as types from '../types/billing';

const initialState = {
    categories: {},
    activeCategoryId: '',
    activeItemId: '',
    billingItem: {},
    itemList: {},
    addonModalVisible: false,
    selectedItemAddonList: [],
    selectedItem: {},
    mode: 'dine_in',
    holdOrder: [],
    holdOrderModal: false,
    splitOrderModal: false,
    discount: 'select_discount',
    itemFreeList: [],
    customerInformation: {},
    finishCustomerInfo: false,
    customerFirstOrder: true,
    isDirty: false,
    tableSelectedList: [],
    cartId: null,
    dineInStepsStatus: {},
    orderId: null,
    billingItemCartStructure: {},
    previousOrders: [],
    itemCommentModalState: false,
    selectedDraggableItems: [],
    tableData: {},
    tables: [],
    sequence: 0,
    addNew: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case types.SET_BILLING_CATEGORIES:
            return {
                ...state,
                categories: action.payload.categories,
            };
        case types.SET_ACTIVE_CATEGORY_ID:
            return {
                ...state,
                activeCategoryId: action.payload.activeCategoryId,
            };
        case types.SET_ACTIVE_ITEM_ID:
            return {
                ...state,
                activeItemId: action.payload.activeItemId,
            };
        case types.SET_BILLING_ITEM:
            return {
                ...state,
                billingItem: action.payload.billingItem,
            };
        case types.SET_ADDON_MODAL_VISIBLE:
            return {
                ...state,
                addonModalVisible: action.payload.addonModalVisible,
            };
        case types.SET_SELECTED_ITEM_ADDON_LIST:
            return {
                ...state,
                selectedItemAddonList: action.payload.selectedItemAddonList,
            };
        case types.SET_SELECTED_ITEM:
            return {
                ...state,
                selectedItem: action.payload.selectedItem,
            };
        case types.SET_MODE:
            return {
                ...state,
                mode: action.payload.mode,
            };
        case types.SET_HOLD_ORDER:
            return {
                ...state,
                holdOrder: action.payload.holdOrder,
            };
        case types.SET_HOLD_ORDER_MODAL:
            return {
                ...state,
                holdOrderModal: action.payload.holdOrderModal,
            };
        case types.SET_SPLIT_ORDER_MODAL:
            return {
                ...state,
                splitOrderModal: action.payload.splitOrderModal,
            };
        case types.SET_ITEM_LIST:
            return {
                ...state,
                itemList: action.payload.itemList,
            };
        case types.SET_DISCOUNT:
            return {
                ...state,
                discount: action.payload.discount,
            };
        case types.SET_ITEM_FREE_LIST:
            return {
                ...state,
                itemFreeList: action.payload.itemFreeList,
            };
        case types.SET_CUSTOMER_INFORMATION:
            return {
                ...state,
                customerInformation: action.payload.customerInformation,
            };
        case types.SET_CUSTOMER_INFORMATION_FINISH:
            return {
                ...state,
                finishCustomerInfo: action.payload.finishCustomerInfo,
            };
        case types.SET_CUSTOMER_FIRST_ORDER:
            return {
                ...state,
                customerFirstOrder: action.payload.customerFirstOrder,
            };
        case types.SET_IS_DIRTY:
            return {
                ...state,
                isDirty: action.payload.isDirty,
            };
        case types.SET_TABLE_SELECTED_LIST:
            return {
                ...state,
                tableSelectedList: action.payload.tableSelectedList,
            };
        case types.SET_CART_ID:
            return {
                ...state,
                cartId: action.payload.cartId,
            };
        case types.SET_DINE_IN_STEPS_STATUS:
            return {
                ...state,
                dineInStepsStatus: action.payload.dineInStepsStatus,
            };
        case types.SET_ORDER_ID:
            return {
                ...state,
                orderId: action.payload.orderId,
            };
        case types.SET_BILLING_ITEM_CART_STRUCTURE:
            return {
                ...state,
                billingItemCartStructure: action.payload.billingItemCartStructure,
            };
        case types.SET_PREVIOUS_ORDERS:
            return {
                ...state,
                previousOrders: action.payload.previousOrders,
            };
        case types.SET_ITEM_COMMENT_MODAL:
            return {
                ...state,
                itemCommentModalState: action.payload.itemCommentModalState,
            };
        // selectedDraggableItems
        case types.SET_SELECTED_DRAGGABLE_ITEMS:
            return {
                ...state,
                selectedDraggableItems: action.payload.selectedDraggableItems,
            };
        case types.SET_TABLE_DATA:
            return {
                ...state,
                tableData: action.payload.tableData,
            };
        case types.SET_TABLES:
            return {
                ...state,
                tables: action.payload.tables,
            };

        case types.SET_SEQUENCE_COUNT_ADD:
            return {
                ...state,
                sequence: state.sequence + 1,
            };
        case types.SET_SEQUENCE_COUNT_MINUS:
            return {
                ...state,
                sequence: state.sequence - 1,
            };
        case types.SET_RESET_STATE:
            return {
                ...initialState,
                mode: state.mode,
                tables: state.tables,
                holdOrder: state.holdOrder,
                holdOrderModal: state.holdOrderModal,
            };
        case types.SET_ADD_NEW:
            return {
                ...state,
                addNew: action.payload.addNew,
            };
        default:
            return state;
    }
};
