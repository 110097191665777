import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import TextField from '../../Form/TextField';
import { EuiCopy, EuiText } from '@elastic/eui';
import FormGroupDescription from '../../formGroupDescription';
import ButtonGroup from '../../Form/ButtonGroup';
import { useDispatch, useSelector } from 'react-redux';

const EltRider = () => {
    const {
        formState: { errors },
        control,
        watch,
        setValue,
    } = useFormContext();
    const [isFlyoutVisible, setIsFlyoutVisible] = useState(true);
    const restaurantDescriptions = useSelector((state) => state.auth.restaurantDescriptions);

    let flyout;

    if (isFlyoutVisible) {
        flyout = (
            <>
                <ButtonGroup
                    idSelected={watch('elt_integration_available') ? '1' : '0'}
                    color='primary'
                    control={control}
                    setValue={setValue}
                    name='elt_integration_available'
                    watch={watch}
                    label={'Elt integration'}
                />
                {watch('elt_integration_available') ? (
                    <>
                        <TextField
                            errors={errors}
                            label='Api Key'
                            name='elt_api_key'
                            placeholder='Enter ELT api key'
                            rules={watch('elt_integration_available') ? { required: 'Please Enter ELT api key' } : {}}
                            control={control}
                        />
                        <TextField
                            errors={errors}
                            label='Primary address id'
                            name='elt_primary_address_id'
                            placeholder='Enter primary address id'
                            rules={
                                watch('elt_integration_available')
                                    ? { required: 'Please enter primary address id' }
                                    : {}
                            }
                            control={control}
                        />
                        <TextField
                            errors={errors}
                            label='Url'
                            name='elt_call_back_url'
                            placeholder='Enter url'
                            control={control}
                            disabled={true}
                            defaultValue={watch('elt_call_back_url')}
                            append={
                                <EuiCopy textToCopy={watch('elt_call_back_url') ? watch('elt_call_back_url') : ''}>
                                    {(copy) => <EuiText onClick={copy}>Copy</EuiText>}
                                </EuiCopy>
                            }
                        />
                    </>
                ) : (
                    ''
                )}
            </>
        );
    }

    return (
        <div style={{ maxWidth: '650px' }}>
            <FormGroupDescription
                title={restaurantDescriptions?.elt_riders?.display_name}
                description={restaurantDescriptions?.elt_riders?.description}
            >
                {flyout}
            </FormGroupDescription>
        </div>
    );
};

export default EltRider;
