import { EuiComboBox, EuiFormRow } from '@elastic/eui';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

const ComboboxWithSearchField = ({
    options,
    rules,
    onChange,
    onSearchChange,
    isLoading,
    selectedOptions,
    required,
    placeholder,
    name,
    fullWidth = true,
    errroMessage,
    isInvalid,
    label,
    renderOption,
}) => {
    const { control } = useFormContext();
    return (
        <EuiFormRow label={label} isInvalid={isInvalid} error={errroMessage} fullWidth={fullWidth}>
            <Controller
                render={({ field }) => (
                    <EuiComboBox
                        async
                        options={options}
                        selectedOptions={field.value}
                        onChange={field.onChange}
                        placeholder={placeholder}
                        onSearchChange={onSearchChange}
                        isLoading={isLoading}
                        renderOption={renderOption}
                    />
                )}
                rules={{ ...rules }}
                required={required}
                name={name}
                control={control}
            />
        </EuiFormRow>
    );
};

export default React.memo(ComboboxWithSearchField);
