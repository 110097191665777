import React from 'react';
import { EuiButton, EuiConfirmModal, EuiFormRow, EuiFieldText, EuiOverlayMask } from '@elastic/eui';
import { useDispatch } from 'react-redux';
import * as types from '../../reduxStore/types/billing';

const StepWizardChangeModal = (props) => {
    const { setOpenStepWizard, stepValue, onStepClicked } = props;
    const dispatch = useDispatch();

    const closeModal = () => {
        setOpenStepWizard(false);
    };

    const onConfirm = () => {
        dispatch({
            type: types.SET_BILLING_ITEM,
            payload: {
                billingItem: {},
            },
        });

        dispatch({
            type: types.SET_BILLING_ITEM_CART_STRUCTURE,
            payload: {
                billingItemCartStructure: {},
            },
        });
        onStepClicked(stepValue);
        closeModal();
    };

    return (
        <>
            <EuiOverlayMask>
                <EuiConfirmModal
                    title='Please reset KOT before switch'
                    onCancel={closeModal}
                    onConfirm={() => {
                        onConfirm();
                    }}
                    confirmButtonText='Reset KOT'
                    cancelButtonText='Cancel'
                    buttonColor='danger'
                    initialFocus='[name=delete]'
                    // confirmButtonDisabled={value.toLowerCase() !== 'delete'}
                    // isLoading={isLoading}
                >
                    <span>Are you sure you want to switch?</span>
                </EuiConfirmModal>
            </EuiOverlayMask>
        </>
    );
};

export default StepWizardChangeModal;
