import {
    EuiFlexGroup,
    EuiFlexItem,
    EuiText,
    EuiLoadingContent,
    EuiButton,
    EuiLink,
    EuiButtonGroup,
} from '@elastic/eui';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getBillingCategories } from '../../api/getBillingCategories';
import { isEmpty } from 'lodash';
import * as types from '../../reduxStore/types/billing';
import { capitalize } from '../../helper/capitalize';

const CategoryList = (props) => {
    const { outletId } = props;

    const dispatch = useDispatch();
    const categories = useSelector((state) => state.billing.categories);
    const activeCategoryId = useSelector((state) => state.billing.activeCategoryId);
    const mode = useSelector((state) => state.billing.mode);
    const language = useSelector((state) => state.language.language);

    useEffect(() => {
        dispatch(getBillingCategories(outletId));
    }, [outletId, mode, language]);

    const categoryList = () => {
        let categoryList = [];

        categories.outlet_categories.map((category) => {
            categoryList.push(
                <EuiFlexItem id={category.internal_name}>
                    <EuiButton
                        size='s'
                        fill={activeCategoryId === category.category_id ? true : false}
                        style={{
                            color: activeCategoryId === category.category_id ? '#f5f5f5' : '#000000',
                            border: 'none',
                            boxShadow: 'none',
                        }}
                        onClick={() => {
                            dispatch({
                                type: types.SET_ITEM_LIST,
                                payload: {
                                    itemList: [],
                                },
                            });
                            dispatch({
                                type: types.SET_ACTIVE_CATEGORY_ID,
                                payload: {
                                    activeCategoryId: category.category_id,
                                },
                            });
                        }}
                    >
                        <span>{capitalize(category.title)}</span>
                    </EuiButton>
                </EuiFlexItem>
            );
        });

        return categoryList;
    };

    const renderList = () => {
        if (isEmpty(categories)) {
            return <EuiLoadingContent lines={3} />;
        }

        if (
            !isEmpty(categories) &&
            categories.success &&
            categories.outlet_categories &&
            categories.outlet_categories.length > 0
        ) {
            return (
                <EuiFlexItem style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <EuiFlexItem
                        id='style-3'
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            overflowY: 'auto',
                            height: '42px',
                            backgroundColor: 'white',
                            border: '1px solid #d8dadc',
                            backgroundColor: '#f5f5f5',
                            padding: '3px 3px',
                            width: '100%',
                        }}
                    >
                        {categoryList()}
                    </EuiFlexItem>
                </EuiFlexItem>
            );
        }

        if (!isEmpty(categories) && categories.outlet_categories && categories.outlet_categories.length === 0) {
            return <EuiText>No categories found</EuiText>;
        }

        if (!isEmpty(categories) && categories.error) {
            return <EuiText>Error in loading categories</EuiText>;
        }
    };

    return (
        <>
            <div>{renderList()}</div>
        </>
    );
};

export default CategoryList;
