import {
    EuiFormRow,
    EuiFlexItem,
    EuiFlexGroup,
    EuiForm,
    EuiEmptyPrompt,
    EuiSpacer,
    EuiButtonGroup,
} from '@elastic/eui';
import React, { useState, useEffect, useCallback } from 'react';
import { Controller, useForm } from 'react-hook-form';
import SelectField from '../../../components/Form/SelectField';
import FormGroupDescription from '../../../components/formGroupDescription';
import { useDispatch, useSelector } from 'react-redux';
import { outletSettingsAPI } from '../../../api/outlet/outletSettingsAPI';
import * as types from '../../../reduxStore/types/outlet';
import ComboBoxField from '../../../components/Form/ComboBoxField';
import { userHasPermission } from '../../../helper/auth';
import { fetchOutletOrderingModes } from '../../../api/outlet/fetchOutletOrderingModes';
import { updateOutletOrderingModes } from '../../../components/settings/outlet-form/utils';

const PaymentMethods = () => {
    const dispatch = useDispatch();
    const permissions = useSelector((state) => state.permission.permission);
    const restaurantDescriptions = useSelector((state) => state.auth.restaurantDescriptions);
    const outletId = useSelector((state) => state.outlet.selectedOutletId);
    const outletData = useSelector((state) => state.outlet.outletOrderingModes);

    const getOrderingModes = useCallback(async () => {
        dispatch(fetchOutletOrderingModes(outletId));
    }, [dispatch, outletId]);

    useEffect(() => {
        getOrderingModes();
    }, [getOrderingModes]);

    const payment_provider_options = [
        {
            label: 'paytm',
        },
        {
            label: 'stripe',
        },
    ];

    const [credit, setCredit] = useState(null);

    let paymentProvider = [];
    let cardProvider = [];
    const optionsPaytm = [
        {
            value: 'paytm',
            inputDisplay: 'Paytm',
        },
    ];

    let defaultValue = {};
    const resetChanges = () => {
        defaultValue.gst_applicable = outletData.settings.gst_applicable ? 'get_yes' : 'get_no';
        defaultValue.delivery_charges_gst_slab = outletData.settings.delivery_charges_gst_slab.toString();
        defaultValue.gst_number = outletData.settings.gst_number;

        for (const cashOrderingMode of outletData.payment_availabilities_ordering_mode_wise) {
            if (cashOrderingMode.payment_method === 'CASH_ON_DELIVERY') {
                defaultValue[`${cashOrderingMode.id}_${cashOrderingMode.ordering_mode}`] = cashOrderingMode.is_enabled;
            }
        }

        //set payment provider
        if (outletData.payment_settings.paytm) {
            paymentProvider.push({
                label: 'paytm',
            });
        }

        if (outletData.payment_settings.stripe) {
            paymentProvider.push({
                label: 'stripe',
            });
        }

        defaultValue.payment_provider = paymentProvider;

        if (outletData.payment_settings) {
            if (outletData.payment_settings.paytm) {
                for (const [key, value] of Object.entries(outletData.payment_settings.paytm)) {
                    if (key === 'PAYTM_MERCHANT_KEY') {
                        defaultValue.paytm_merchent_key = value;
                    }
                    if (key === 'PAYTM_MERCHANT_ID') {
                        defaultValue.paytm_merchent_id = value;
                    }
                    if (key === 'PAYTM_INDUSTRY_TYPE_ID') {
                        defaultValue.paytm_industry_type_id = value;
                    }
                    if (key === 'PAYTM_WEBSITE') {
                        defaultValue.paytm_website = value;
                    }
                    if (key === 'PAYTM_BASE_API_URL') {
                        defaultValue.paytm_base_api_url = value;
                    }
                }
            }
            if (outletData.payment_settings.stripe) {
                for (const [key, value] of Object.entries(outletData.payment_settings.stripe)) {
                    if (key === 'STRIPE_KEY') {
                        defaultValue.stripe_key = value;
                    }
                    if (key === 'STRIPE_SECRET') {
                        defaultValue.stripe_secret = value;
                    }
                    if (key === 'STRIPE_WEBHOOK') {
                        defaultValue.stripe_webhook = value;
                    }
                    if (key === 'STRIPE_WEBHOOK_SECRET') {
                        defaultValue.stripe_webhook_secret = value;
                    }
                }
            }
        }

        if (outletData.payment_availabilities['cash-on-delivery']) {
            if (outletData.payment_availabilities['cash-on-delivery'][0] === 1) {
                defaultValue['cash-on-delivery'] = 1;
            } else {
                defaultValue['cash-on-delivery'] = 0;
            }
        } else {
            defaultValue['cash-on-delivery'] = 0;
        }

        if (paymentProvider.length > 0) {
            // eslint-disable-next-line
            paymentProvider.map((item) => {
                if (item.label === 'paytm') {
                    cardProvider.push({
                        value: 'paytm',
                        inputDisplay: 'Paytm',
                    });
                } else {
                    cardProvider.push({
                        value: 'stripe',
                        inputDisplay: 'Stripe',
                    });
                }
                return null;
            });
        }
        defaultValue.cardProvider = cardProvider;
        defaultValue.credit_card = cardProvider.length === 1 ? cardProvider[0].value : null;
        if (outletData.payment_availabilities) {
            if (outletData.payment_availabilities.paytm) {
                // eslint-disable-next-line
                outletData.payment_availabilities.paytm.map((item, index) => {
                    if (item === 'CREDIT_CARD/DEBIT_CARD') {
                        defaultValue.credit_card = 'paytm';
                    }

                    return null;
                });
            }
            if (outletData.payment_availabilities.stripe) {
                // eslint-disable-next-line
                outletData.payment_availabilities.stripe.map((item, index) => {
                    if (item === 'CREDIT_CARD/DEBIT_CARD') {
                        defaultValue.credit_card = 'stripe';
                    }

                    return null;
                });
            }
        }
        defaultValue.net_banking = optionsPaytm[0].value;
        defaultValue.upi = optionsPaytm[0].value;
        defaultValue.upi_intent = optionsPaytm[0].value;
        if (!defaultValue.balance) {
            defaultValue.balance = optionsPaytm[0].value;
        }

        reset({
            ...defaultValue,
        });
    };

    useEffect(() => {
        if (outletData) {
            resetChanges();
        }
    }, [outletData]);

    const onSubmit = async (data) => {
        console.log('ZZZdata are', data);
        let isThePaymentModeSame = true;
        let payment_methods = [];
        let paytmData = [];
        data.payment_provider.find((item) => {
            if (item.label === 'paytm') {
                paytmData.push('NET_BANKING', 'BALANCE', 'UPI', 'UPI_INTENT');
            }
            return null;
        });
        let stripeData = [];
        if (data.credit_card === 'stripe') {
            stripeData.push('CREDIT_CARD/DEBIT_CARD');
        } else {
            paytmData.push('CREDIT_CARD/DEBIT_CARD');
        }

        data.payment_availabilities = {
            paytm: paytmData,
            stripe: stripeData,
            'cash-on-delivery': [data['cash-on-delivery'] ? 1 : 0],
        };

        for (const cashOrderingMode of outletData.payment_availabilities_ordering_mode_wise) {
            if (cashOrderingMode.payment_method === 'CASH_ON_DELIVERY') {
                payment_methods.push({
                    payment_method_id: cashOrderingMode.id,
                    is_enabled: Boolean(data[`${cashOrderingMode.id}_${cashOrderingMode.ordering_mode}`]),
                });
                if (
                    Boolean(cashOrderingMode.is_enabled) !==
                    Boolean(data[`${cashOrderingMode.id}_${cashOrderingMode.ordering_mode}`])
                ) {
                    isThePaymentModeSame = false;
                }
            }
        }

        // Object.entries(data).forEach(([key, value]) => {
        //     if (key !== 'payment_availabilities') {
        //         delete data[key];
        //     }
        // });

        !isThePaymentModeSame &&
            (await updateOutletOrderingModes(
                outletId,
                { payment_methods: payment_methods },
                'payment_method_visibility'
            ).then((res) => {
                if (res.success) {
                    dispatch({
                        type: types.SET_PORTAL_ACTIVE,
                        payload: {
                            portalActive: {},
                        },
                    });
                    dispatch({
                        type: types.SET_PORTAL_ACTIVE_TAB_SWITCHED,
                        payload: {
                            tabSwitched: false,
                        },
                    });
                    getOrderingModes();
                } else {
                    alert(res.message);
                }
            }));

        await dispatch(outletSettingsAPI('payment_method', data)).then((res) => {
            if (res.success) {
                dispatch({
                    type: types.SET_PORTAL_ACTIVE,
                    payload: {
                        portalActive: {},
                    },
                });
                dispatch({
                    type: types.SET_PORTAL_ACTIVE_TAB_SWITCHED,
                    payload: {
                        tabSwitched: false,
                    },
                });
                getOrderingModes();
            } else {
                alert(res.message);
            }
        });
    };

    const {
        handleSubmit,
        control,
        watch,
        reset,
        setValue,

        formState: { isDirty, errors },
    } = useForm({
        defaultValues: defaultValue,
    });

    useEffect(() => {
        if (isDirty) {
            dispatch({
                type: 'set-portal-active',
                payload: {
                    portalActive: {
                        resetChanges: resetChanges,
                        submit: handleSubmit(onSubmit),
                    },
                },
            });
        } else {
            dispatch({
                type: 'set-portal-active',
                payload: {
                    portalActive: {},
                },
            });
        }
    }, [isDirty]);

    useEffect(() => {
        if (!userHasPermission(permissions, '/outlet-settings', 'write')) return;
    }, [isDirty]);

    return (
        <>
            <EuiForm component='form' onSubmit={handleSubmit(onSubmit)}>
                <EuiFlexItem style={{ display: 'none' }}>
                    <ComboBoxField
                        options={payment_provider_options}
                        selectedOptions={
                            watch('payment_provider') ? watch('payment_provider') : payment_provider_options
                        }
                        onChange={() => {}}
                        errors={errors}
                        label='Select payment provider'
                        name='payment_provider'
                        placeholder='Select one or more payment provider'
                        rules={{
                            validate: (value) =>
                                (watch('payment_provider') && watch('payment_provider').length > 0) ||
                                'Please select atleast one payment provider',
                        }}
                        control={control}
                    />
                </EuiFlexItem>
                <FormGroupDescription
                    title={restaurantDescriptions?.payment_methods?.display_name}
                    description={restaurantDescriptions?.payment_methods?.description}
                >
                    {watch('payment_provider') && watch('payment_provider').length > 0 ? (
                        <EuiFlexGroup direction='column'>
                            {watch('payment_provider') && watch('payment_provider').length > 0 && (
                                <EuiFlexItem>
                                    <SelectField
                                        errors={errors}
                                        label='Credit card / Dabit card'
                                        name='credit_card'
                                        options={credit ? credit : watch('cardProvider') ? watch('cardProvider') : []}
                                        valueOfSelected={
                                            watch('credit_card') ? watch('credit_card') : watch('cardProvider')
                                        }
                                        onChange={(value) => setValue('credit_card', value)}
                                        placeholder='Please select credit card / dabit card'
                                        rules={{ required: 'Please select credit card / dabit card' }}
                                        control={control}
                                        defaultValue={
                                            watch('credit_card') ? watch('credit_card') : watch('cardProvider')
                                        }
                                    />
                                </EuiFlexItem>
                            )}

                            {/* eslint-disable-next-line */}
                            {watch('payment_provider') &&
                                watch('payment_provider').map((item, index) => {
                                    if (item.label === 'paytm') {
                                        return (
                                            <EuiFlexItem key={index}>
                                                <SelectField
                                                    errors={errors}
                                                    label='Net banking'
                                                    name='net_banking'
                                                    options={optionsPaytm}
                                                    valueOfSelected={optionsPaytm[0].value}
                                                    placeholder='Please select net banking'
                                                    rules={{ required: 'Please select net banking' }}
                                                    control={control}
                                                    defaultValue={optionsPaytm[0].value}
                                                />
                                                <SelectField
                                                    errors={errors}
                                                    label='Balance'
                                                    name='balance'
                                                    options={optionsPaytm}
                                                    valueOfSelected={optionsPaytm[0].value}
                                                    placeholder='Please select balance'
                                                    rules={{ required: 'Please select balance' }}
                                                    control={control}
                                                    defaultValue={optionsPaytm[0].value}
                                                />

                                                <SelectField
                                                    errors={errors}
                                                    label='UPI'
                                                    name='upi'
                                                    options={optionsPaytm}
                                                    valueOfSelected={optionsPaytm[0].value}
                                                    placeholder='Please select upi'
                                                    rules={{ required: 'Please select upi' }}
                                                    control={control}
                                                    defaultValue={optionsPaytm[0].value}
                                                />

                                                <SelectField
                                                    errors={errors}
                                                    label='Upi intent'
                                                    name='upi_intent'
                                                    options={optionsPaytm}
                                                    valueOfSelected={optionsPaytm[0].value}
                                                    placeholder='Please select upi intent'
                                                    rules={{ required: 'Please select upi intent' }}
                                                    control={control}
                                                    defaultValue={optionsPaytm[0].value}
                                                />
                                            </EuiFlexItem>
                                        );
                                    }
                                    return null;
                                })}
                            <EuiSpacer />
                        </EuiFlexGroup>
                    ) : (
                        <EuiEmptyPrompt
                            title={<h2>No releveant choice selected</h2>}
                            body={
                                <>
                                    <p>Select choice from outlet settings/payment settings</p>
                                </>
                            }
                        />
                    )}

                    {outletData?.payment_availabilities_ordering_mode_wise?.map((orderingMode) => {
                        return (
                            <React.Fragment key={orderingMode.id.toString()}>
                                {orderingMode.payment_method === 'CASH_ON_DELIVERY' ? (
                                    <EuiFormRow label={`Cash for ${orderingMode.restaurant_ordering_mode_type}`}>
                                        <Controller
                                            render={({ field }) => {
                                                return (
                                                    <EuiFormRow label={''}>
                                                        <EuiButtonGroup
                                                            options={[
                                                                {
                                                                    id: 1,
                                                                    label: 'Enable',
                                                                    iconType: 'check',
                                                                },
                                                                {
                                                                    id: 0,
                                                                    label: 'Disable',
                                                                    iconType: 'cross',
                                                                },
                                                            ]}
                                                            idSelected={field.value}
                                                            onChange={field.onChange}
                                                            color='primary'
                                                        />
                                                    </EuiFormRow>
                                                );
                                            }}
                                            name={`${orderingMode.id.toString()}_${orderingMode.ordering_mode}`}
                                            control={control}
                                        />
                                    </EuiFormRow>
                                ) : null}
                            </React.Fragment>
                        );
                    })}
                </FormGroupDescription>
            </EuiForm>
        </>
    );
};

export default React.memo(PaymentMethods);
