import React from 'react';
import { withGoogleMap, GoogleMap, withScriptjs, InfoWindow, Marker } from 'react-google-maps';
import Autocomplete from 'react-google-autocomplete';
import Geocode from 'react-geocode';
import { EuiFormRow, EuiFieldText } from '@elastic/eui';

Geocode.setApiKey('AIzaSyBLcauGM860cMMRQ4pU4hf2PvMagDISslg');
Geocode.enableDebug();
class Map extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            address: '',
            city: '',
            area: '',
            state: '',
            mapPosition: {
                lat: this.props.center.lat,
                lng: this.props.center.lng,
            },
            markerPosition: {
                lat: this.props.center.lat,
                lng: this.props.center.lng,
            },
        };
    }
    /**
     * Get the current address from the default map position and set those values in the state
     */
    componentDidMount() {
        let location_name, city_name, state_name, region_name;
        const { setValue } = this.props;
        Geocode.fromLatLng(this.state.mapPosition.lat, this.state.mapPosition.lng).then(
            (response) => {
                const address = response.results[0].formatted_address,
                    addressArray = response.results[0].address_components,
                    city = this.getCity(addressArray),
                    area = this.getArea(addressArray),
                    state = this.getState(addressArray);

                location_name = response.results[0].formatted_address;
                city_name = this.getCity(addressArray);
                state_name = this.getState(addressArray);
                region_name = this.getArea(addressArray);

                console.log('city', city, area, state);
                console.log('city 1', city_name, region_name, state_name);

                this.setState({
                    address: address ? address : '',
                    area: area ? area : '',
                    city: city ? city : '',
                    state: state ? state : '',
                });
                setValue('location_name', location_name);
                setValue('city', city_name);
                setValue('state', state_name);
                setValue('region', region_name);
            },
            (error) => {
                console.error(error);
            }
        );
        this.props.setValue('latitude', this.props.center.lat);
        this.props.setValue('longitude', this.props.center.lng);
    }
    /**
     * Component should only update ( meaning re-render ), when the user selects the address, or drags the pin
     *
     * @param nextProps
     * @param nextState
     * @return {boolean}
     */
    shouldComponentUpdate(nextProps, nextState) {
        if (
            this.state.markerPosition.lat !== this.props.center.lat ||
            this.state.address !== nextState.address ||
            this.state.city !== nextState.city ||
            this.state.area !== nextState.area ||
            this.state.state !== nextState.state
        ) {
            console.log('inside if');
            return true;
        } else if (this.props.center.lat === nextProps.center.lat) {
            console.log('inside else');
            return false;
        }
    }
    /**
     * Get the city and set the city input value to the one selected
     *
     * @param addressArray
     * @return {string}
     */
    getCity = (addressArray) => {
        let city = '';
        for (let i = 0; i < addressArray.length; i++) {
            if (addressArray[i].types[0] && 'administrative_area_level_2' === addressArray[i].types[0]) {
                city = addressArray[i].long_name;
                return city;
            }
        }
    };
    /**
     * Get the area and set the area input value to the one selected
     *
     * @param addressArray
     * @return {string}
     */
    getArea = (addressArray) => {
        let area = '';
        for (let i = 0; i < addressArray.length; i++) {
            if (addressArray[i].types[0]) {
                for (let j = 0; j < addressArray[i].types.length; j++) {
                    if ('sublocality_level_1' === addressArray[i].types[j] || 'locality' === addressArray[i].types[j]) {
                        area = addressArray[i].long_name;
                        return area;
                    }
                }
            }
        }
    };
    /**
     * Get the address and set the address input value to the one selected
     *
     * @param addressArray
     * @return {string}
     */
    getState = (addressArray) => {
        let state = '';
        for (let i = 0; i < addressArray.length; i++) {
            for (let i = 0; i < addressArray.length; i++) {
                if (addressArray[i].types[0] && 'administrative_area_level_1' === addressArray[i].types[0]) {
                    state = addressArray[i].long_name;
                    return state;
                }
            }
        }
    };
    /**
     * And function for city,state and address input
     * @param event
     */
    onChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    };
    /**
     * This Event triggers when the marker window is closed
     *
     * @param event
     */
    onInfoWindowClose = (event) => {};
    /**
     * When the user types an address in the search box
     * @param place
     */
    onPlaceSelected = (place) => {
        console.log('place - ', place);
        if (place.formatted_address && place.address_components) {
            const address = place.formatted_address,
                addressArray = place.address_components,
                city = this.getCity(addressArray),
                area = this.getArea(addressArray),
                state = this.getState(addressArray),
                latValue = place.geometry.location.lat(),
                lngValue = place.geometry.location.lng();
            // Set these values in the state.
            this.setState({
                address: address ? address : '',
                area: area ? area : '',
                city: city ? city : '',
                state: state ? state : '',
                markerPosition: {
                    lat: latValue,
                    lng: lngValue,
                },
                mapPosition: {
                    lat: latValue,
                    lng: lngValue,
                },
            });
            this.props.setValue('latitude', latValue);
            this.props.setValue('longitude', lngValue);
            this.props.setValue('location_name', address);

            this.props.setValue('city', city);
            this.props.setValue('state', state);
            this.props.setValue('region', area);
        } else {
            alert('Select address from drop down');
        }
    };
    /**
     * When the marker is dragged you get the lat and long using the functions available from event object.
     * Use geocode to get the address, city, area and state from the lat and lng positions.
     * And then set those values in the state.
     *
     * @param event
     */
    onMarkerDragEnd = (event) => {
        console.log('event', event);
        let newLat = event.latLng.lat(),
            newLng = event.latLng.lng(),
            location_name,
            addressArray = [];
        const { setValue } = this.props;
        Geocode.fromLatLng(newLat, newLng).then(
            (response) => {
                const address = response.results[0].formatted_address,
                    addressArray = response.results[0].address_components,
                    city = this.getCity(addressArray),
                    area = this.getArea(addressArray),
                    state = this.getState(addressArray);
                location_name = response.results[0].formatted_address;
                this.setState({
                    address: address ? address : '',
                    area: area ? area : '',
                    city: city ? city : '',
                    state: state ? state : '',
                    markerPosition: {
                        lat: newLat,
                        lng: newLng,
                    },
                    mapPosition: {
                        lat: newLat,
                        lng: newLng,
                    },
                });
                setValue('city', city);
                setValue('state', state);
                setValue('region', area);
                setValue('location_name', address);
            },
            (error) => {
                console.error(error);
            }
        );
        this.props.setValue('latitude', newLat);
        this.props.setValue('longitude', newLng);
    };
    render() {
        const { errors, Controller, control } = this.props;
        const AsyncMap = withScriptjs(
            withGoogleMap((props) => (
                <GoogleMap
                    defaultZoom={this.props.zoom}
                    defaultCenter={{ lat: this.state.mapPosition.lat, lng: this.state.mapPosition.lng }}
                >
                    {/* For Auto complete Search Box */}
                    <Autocomplete
                        style={{
                            width: '100%',
                            height: '40px',
                            paddingLeft: '16px',
                            marginTop: '2px',
                            marginBottom: '100px',
                        }}
                        onPlaceSelected={this.onPlaceSelected}
                        types={['address']}
                    />

                    <Marker
                        name={'Dolores park'}
                        draggable={true}
                        onDragEnd={this.onMarkerDragEnd}
                        position={{ lat: this.state.markerPosition.lat, lng: this.state.markerPosition.lng }}
                    />
                    <Marker />

                    <InfoWindow
                        onClose={this.onInfoWindowClose}
                        position={{ lat: this.state.markerPosition.lat + 0.0018, lng: this.state.markerPosition.lng }}
                    >
                        <div>
                            <span style={{ padding: 0, margin: 0 }}>{this.state.address}</span>
                        </div>
                    </InfoWindow>
                </GoogleMap>
            ))
        );

        console.log('render');
        let map;
        if (this.props.center.lat !== undefined) {
            map = (
                <div>
                    <div style={{ display: 'none' }}>
                        <EuiFormRow
                            label={'latitude'}
                            fullWidth={true}
                            error={errors['latitude'] ? errors['latitude'].message : ''}
                            isInvalid={errors['latitude']}
                        >
                            <Controller
                                render={({ field }) => <EuiFieldText value={field.value} onChange={field.onChange} />}
                                name={'latitude'}
                                placeholder='latitude'
                                control={control}
                                fullWidth={true}
                                rules={{
                                    required: 'Please enter latitude',
                                }}
                                isInvalid={errors['latitude']}
                            />
                        </EuiFormRow>
                        <EuiFormRow
                            label={'longitude'}
                            fullWidth={true}
                            error={errors['longitude'] ? errors['longitude'].message : ''}
                            isInvalid={errors['longitude']}
                        >
                            <Controller
                                render={({ field }) => <EuiFieldText onChange={field.onChange} value={field.value} />}
                                name={'longitude'}
                                placeholder='Cover count'
                                control={control}
                                fullWidth={true}
                                rules={{
                                    required: 'Please enter longitude',
                                }}
                                isInvalid={errors['longitude']}
                            />
                        </EuiFormRow>
                        <EuiFormRow
                            label={'location_name'}
                            fullWidth={true}
                            error={errors['location_name'] ? errors['location_name'].message : ''}
                            isInvalid={errors['location_name']}
                        >
                            <Controller
                                render={({ field }) => <EuiFieldText value={field.value} onChange={field.onChange} />}
                                name={'location_name'}
                                placeholder='Cover count'
                                control={control}
                                fullWidth={true}
                                rules={{
                                    required: 'Please enter location_name',
                                }}
                                isInvalid={errors['location_name']}
                            />
                        </EuiFormRow>
                        <EuiFormRow
                            label={'city'}
                            fullWidth={true}
                            error={errors['city'] ? errors['city'].message : ''}
                            isInvalid={errors['city']}
                        >
                            <Controller
                                render={({ field }) => <EuiFieldText value={field.value} onChange={field.onChange} />}
                                name={'city'}
                                placeholder='city'
                                control={control}
                                fullWidth={true}
                                rules={{
                                    required: 'Please enter city',
                                }}
                                isInvalid={errors['city']}
                            />
                        </EuiFormRow>
                        <EuiFormRow
                            label={'state'}
                            fullWidth={true}
                            error={errors['state'] ? errors['state'].message : ''}
                            isInvalid={errors['state']}
                        >
                            <Controller
                                render={({ field }) => <EuiFieldText value={field.value} onChange={field.onChange} />}
                                name={'state'}
                                placeholder='state'
                                control={control}
                                fullWidth={true}
                                rules={{
                                    required: 'Please enter state',
                                }}
                                isInvalid={errors['state']}
                            />
                        </EuiFormRow>
                        <EuiFormRow
                            label={'region'}
                            fullWidth={true}
                            error={errors['region'] ? errors['region'].message : ''}
                            isInvalid={errors['region']}
                        >
                            <Controller
                                render={({ field }) => <EuiFieldText onChange={field.onChange} value={field.value} />}
                                name={'region'}
                                placeholder='region'
                                control={control}
                                fullWidth={true}
                                rules={{
                                    required: 'Please enter region',
                                }}
                                isInvalid={errors['region']}
                            />
                        </EuiFormRow>
                    </div>
                    {/* /{languageCode}/restaurants/{restaurantId}/google/search */}
                    {/* https://api.staging.fab.delivery/api/v2/en/restaurants/1/google/search?keyword='levaral' */}
                    {/* https://api.staging.fab.delivery/api/v2/restaurants/1/get/map */}
                    {/* https://maps.googleapis.com/maps/api/js?key=AIzaSyBLcauGM860cMMRQ4pU4hf2PvMagDISslg&libraries=places */}
                    <AsyncMap
                        googleMapURL='https://maps.googleapis.com/maps/api/js?key=AIzaSyBLcauGM860cMMRQ4pU4hf2PvMagDISslg&libraries=places'
                        loadingElement={<div style={{ height: `100%` }} />}
                        containerElement={<div style={{ height: this.props.height }} />}
                        mapElement={<div style={{ height: `100%` }} />}
                    />
                </div>
            );
        } else {
            map = <div style={{ height: this.props.height }} />;
        }
        return map;
    }
}
export default Map;
