import { EuiPanel, EuiSpacer, EuiText, EuiFlexItem } from '@elastic/eui';
import React from 'react';
import ComboBoxField from '../Form/ComboBoxField';
import TextField from '../Form/TextField';
import { useFormContext } from 'react-hook-form';

const ItemListForCategorySelected = (props) => {
    const { categoryList } = props;
    const {
        watch,
        formState: { errors },
        control,
    } = useFormContext();

    const items = watch()?.items?.length > 0 ? watch().items : [];

    return (
        <>
            {items.map((field, index) => {
                return (
                    <React.Fragment key={field?.module_id?.toString() + index.toString()}>
                        {field.module_id || field.module_name ? (
                            <div
                                style={{
                                    marginLeft: field.hasVaraint > 0 || field.module_name === 'items' ? '' : '20px',
                                }}
                            >
                                <EuiPanel
                                    style={{ width: field.hasOwnProperty('hasVaraint') ? '50%' : '49%' }}
                                    hasShadow={true}
                                >
                                    <EuiFlexItem
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            width: '100%',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <EuiText>{field.name}</EuiText>
                                        <EuiFlexItem style={{ display: 'none' }}>
                                            <TextField
                                                label={''}
                                                errors={errors}
                                                isInvalid={errors[`items[${index}].module_id`]}
                                                name={`items[${index}].module_id`}
                                                control={control}
                                                defaultValue={field.module_id ? field.module_id : ''}
                                            />
                                            <TextField
                                                label={''}
                                                errors={errors}
                                                isInvalid={errors[`items[${index}].module_name`]}
                                                name={`items[${index}].module_name`}
                                                control={control}
                                                defaultValue={field.module_name ? field.module_name : ''}
                                            />
                                            <TextField
                                                label={''}
                                                errors={errors}
                                                isInvalid={errors[`items[${index}].name`]}
                                                name={`items[${index}].name`}
                                                control={control}
                                                defaultValue={field.name ? field.name : ''}
                                            />
                                        </EuiFlexItem>
                                        {!Boolean(field.hasVaraint) ? (
                                            <>
                                                <ComboBoxField
                                                    style={{ width: '250px' }}
                                                    options={categoryList}
                                                    selectedOptions={
                                                        watch(`items[${index}].secondary_category_id`)?.length
                                                            ? watch(`items[${index}].secondary_category_id`)
                                                            : []
                                                    }
                                                    singleSelection={true}
                                                    errors={errors}
                                                    label=''
                                                    name={`items[${index}].secondary_category_id`}
                                                    placeholder='Select a category'
                                                    // rules={{
                                                    // required: 'Please select category',
                                                    // validate: (value) =>
                                                    //     (watch(`items.${index}.secondary_category_id`) && watch(`items.${index}.secondary_category_id`).length > 0) ||
                                                    //     'Please select one category',
                                                    // }}
                                                    control={control}
                                                    defaultValue={watch(`items[${index}].secondary_category_id`)}
                                                />
                                            </>
                                        ) : null}
                                    </EuiFlexItem>
                                </EuiPanel>

                                <EuiSpacer siz='s' />
                            </div>
                        ) : null}
                    </React.Fragment>
                );
            })}
        </>
    );
};

export default ItemListForCategorySelected;
