import {
    EuiCheckbox,
    EuiFlexGroup,
    EuiFlexItem,
    EuiRadio,
    EuiSpacer,
    EuiText,
    EuiTextColor,
    htmlIdGenerator,
} from '@elastic/eui';
import React from 'react';

const ManualTurnOnAvailability = ({ radioIdSelected, checkedInvisible, onChange, setCheckedInvisible, state }) => {
    return (
        <React.Fragment>
            <EuiFlexGroup>
                <EuiFlexItem style={{ padding: '6px 24px 16px 24px', marginBottom: '0' }}>
                    <EuiText style={{ marginTop: '10px', fontWeight: '500' }}>Manually turn on</EuiText>
                </EuiFlexItem>
            </EuiFlexGroup>
            <EuiFlexGroup direction='column'>
                <EuiFlexItem style={{ padding: '4px 24px 0 24px' }}>
                    <EuiRadio
                        id={'category_item_availability_0'}
                        label='I will turn it on myself'
                        checked={radioIdSelected === 'category_item_availability_0'}
                        onChange={(e) => onChange(e.target.id)}
                    />
                </EuiFlexItem>
                <EuiFlexItem style={{ padding: '0 24px 0 24px' }}>
                    <EuiTextColor color='subdued'>
                        <EuiText size='xs' style={{ margin: '-18px 0 20px 24px' }}>
                            The item will be visible as OUT OF STOCK <br /> to the customers until you manually turn it
                            back on.
                        </EuiText>
                    </EuiTextColor>
                </EuiFlexItem>
            </EuiFlexGroup>
            {!state?.current_tab_addon ? (
                <EuiFlexItem style={{ marginLeft: '24px' }}>
                    <EuiCheckbox
                        id={htmlIdGenerator()()}
                        label='Would you like to make this invisible as well?'
                        value={checkedInvisible}
                        checked={checkedInvisible}
                        onChange={(event) => {
                            setCheckedInvisible(Boolean(event.target.value));
                        }}
                    />
                    <EuiSpacer />
                </EuiFlexItem>
            ) : null}
        </React.Fragment>
    );
};

export default React.memo(ManualTurnOnAvailability);
