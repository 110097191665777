import React, { useState, useCallback } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import NumberField from '../../../components/Form/NumberField';
import {
    EuiFlexItem,
    EuiFormRow,
    EuiFieldNumber,
    EuiFlexGrid,
    EuiFlexGroup,
    EuiText,
    EuiSpacer,
    EuiButton,
} from '@elastic/eui';
import {
    BUY_ONE_GET_ONE_FREE,
    BUY_X_GET_X,
    COMBO_ITEM_PRICE_DEAL,
    FLAT_DISCOUNT,
    FREE_ITEMS,
    MENU_DISCOUNT,
    PERCENTAGE_DISCOUNT,
} from '../OfferTypes.js/OfferTypes';
import RadioGroupField from '../../../components/Form/RadioGroupField';
import ComboboxWithSearchField from '../../../components/Form/ComboboxWithSearchField';
import { fetchSearchedItems } from '../Api/fetchSearchedItems';
import { debounce } from 'lodash';
import { fetchSearchedCategories } from '../Api/fetchSearchedCategories';

const Configure = ({ selectedOfferType, allCategories }) => {
    const languageId = useSelector((state) => state.language.languageId);
    const {
        formState: { errors },
        control,
        setValue,
        watch,
    } = useFormContext();

    // free items
    const deleteSpecificItem = useCallback(
        (id) => {
            const selectedItems = watch('free_items').filter((item) => item.item_id !== id);
            setValue('free_items', selectedItems, { shouldDirty: true });
        },
        [setValue, watch]
    );

    // delete specific categories
    const deleteSpecificCategories = useCallback(
        (id) => {
            const selectedCategories = watch('category.categories').filter(
                (item) => item.required_item_category_id !== id
            );

            setValue('category.categories', selectedCategories, { shouldDirty: true });
        },
        [setValue, watch]
    );

    // delete required_items
    const deleteSpecificRequiredItems = useCallback(
        (id) => {
            const selectedRequiredItems = watch('required_items').filter((item) => item.item_id !== id);
            setValue('required_items', selectedRequiredItems, { shouldDirty: true });
        },
        [setValue, watch]
    );

    const [isLoading, setIsLoading] = useState({
        items: false,
        categories: false,
    });
    const [items, setItems] = useState([]);

    const [categories, setCategories] = useState([]);

    const renderOption = useCallback((option) => {
        const { label, isSubCategory } = option;
        return (
            <EuiFlexItem style={{ display: 'flex', flexDirection: 'row' }}>
                <EuiText>{label}</EuiText>
                <span style={{ color: 'grey', font: '8px', paddingLeft: '5px' }}>
                    {isSubCategory ? 'sub category' : 'category'}
                </span>
            </EuiFlexItem>
        );
    }, []);

    const delayedCategoriesSearchQuery = debounce(async (keyword) => {
        await fetchSearchedCategories(keyword, languageId)
            .then((response) => {
                setIsLoading((prevState) => ({
                    ...prevState,
                    categories: false,
                }));
                let categoryList = [];
                for (const category of response?.categories) {
                    categoryList.push({
                        label: category?.translations?.title?.[languageId]
                            ? `${category.translations.title[languageId]} | ${category.internal_name}`
                            : '',
                        item_id: category.item_id,
                        quantity: 1,
                        isSubCategory: !!category.parent_id,
                        required_item_category_id: category.category_id,
                    });
                }
                setCategories(categoryList);
            })
            .catch(() =>
                setIsLoading((prevState) => ({
                    ...prevState,
                    categories: false,
                }))
            );
    }, 1000);

    const delayedItemsSearchQuery = debounce(async (query) => {
        await fetchSearchedItems(query)
            .then((response) => {
                setIsLoading((prevState) => ({
                    ...prevState,
                    items: false,
                }));

                let itemList = [];
                for (const item of response?.item) {
                    itemList.push({
                        label: item.translations.title[languageId]
                            ? `${item.translations.title[languageId]} | ${item.internal_name}`
                            : '',
                        item_id: item.item_id,
                        quantity: 1,
                    });
                }
                setItems(itemList);
            })
            .catch(() =>
                setIsLoading((prevState) => ({
                    ...prevState,
                    items: false,
                }))
            );
    }, 1000);

    const handleItemsOnSearch = useCallback(
        async (keyword) => {
            if (!keyword) return;
            setItems([]);
            setIsLoading((prevState) => ({
                ...prevState,
                items: true,
            }));
            await delayedItemsSearchQuery(keyword);
        },
        [delayedItemsSearchQuery]
    );

    const handleCategoriesOnSearch = useCallback(
        async (keyword) => {
            if (!keyword) return;
            setCategories([]);
            setIsLoading((prevState) => ({
                ...prevState,
                categories: true,
            }));
            await delayedCategoriesSearchQuery(keyword);
        },
        [delayedCategoriesSearchQuery]
    );

    const handleCategoryItemRadioChange = useCallback(
        (id) => {
            if (id === 'all_categories') {
                delete errors?.category;
                delete errors?.required_items;
            } else if (id === 'specific_categories') {
                delete errors?.required_items;
            } else if (id === 'specific_items') {
                delete errors?.category;
            }
            setValue(
                'catogaries',
                id === 'all_categories'
                    ? 'all_categories'
                    : id === 'specific_categories'
                    ? 'specific_categories'
                    : id === 'specific_items'
                    ? 'specific_items'
                    : 'all_categories',
                { shouldDirty: true }
            );
        },
        [setValue, errors]
    );

    return (
        <>
            <EuiFlexGrid columns={1}>
                {selectedOfferType === COMBO_ITEM_PRICE_DEAL && (
                    <EuiFlexItem>
                        <NumberField
                            label='Combo Index Number'
                            isInvalid={errors.combo_deal_item_index}
                            errors={errors}
                            name='combo_deal_item_index'
                            rules={{ required: 'Please enter index' }}
                            placeholder='Please add combo deal index'
                            control={control}
                        />
                    </EuiFlexItem>
                )}

                {selectedOfferType === FLAT_DISCOUNT && (
                    <EuiFlexItem>
                        <NumberField
                            label='Discount amount'
                            isInvalid={errors.discount_amount}
                            errors={errors}
                            name='discount_amount'
                            placeholder='Please enter discount amount'
                            rules={{
                                required: 'Please enter discount amount',
                                validate: (value) => value > 0 || 'Please enter value greater than 0',
                            }}
                            step='any'
                            min={1}
                            control={control}
                        />
                    </EuiFlexItem>
                )}

                <EuiFlexItem>
                    <NumberField
                        label='Allow to use offer after'
                        errors={errors}
                        name='repeat_usage_time'
                        placeholder='Please Enter usage time'
                        control={control}
                        append='Minutes'
                    />
                </EuiFlexItem>

                {selectedOfferType === BUY_ONE_GET_ONE_FREE && (
                    <>
                        <EuiFlexItem>
                            <EuiFormRow fullWidth={true}>
                                <RadioGroupField
                                    options={[
                                        {
                                            id: 'not_apply_addon_price',
                                            label: 'No',
                                        },
                                        {
                                            id: 'apply_addon_price',
                                            label: 'Yes',
                                        },
                                    ]}
                                    idSelected={
                                        watch('apply_on_addon') == true ? 'apply_addon_price' : 'not_apply_addon_price'
                                    }
                                    onChange={(id) =>
                                        setValue('apply_on_addon', id === 'apply_addon_price' ? true : false)
                                    }
                                    name='apply_on_addon'
                                    legend={'Do you want to include addons in the offer?'}
                                />
                            </EuiFormRow>
                        </EuiFlexItem>
                    </>
                )}

                {(selectedOfferType === BUY_X_GET_X || selectedOfferType === PERCENTAGE_DISCOUNT) && (
                    <EuiFlexItem>
                        <EuiFlexGroup>
                            <EuiFlexItem grow={false}>
                                <EuiFormRow fullWidth={true}>
                                    <RadioGroupField
                                        idSelected={
                                            watch('max_discount_amount')
                                                ? 'max_discount_amount_yes'
                                                : 'max_discount_amount_no'
                                        }
                                        onChange={(id) =>
                                            setValue(
                                                'max_discount_amount',
                                                id === 'max_discount_amount_yes' ? true : false
                                            )
                                        }
                                        legend=' Do you want to set a maximum discount amount to apply this
                                                offer?'
                                        name='max_discount_amount'
                                        options={[
                                            {
                                                id: 'max_discount_amount_no',
                                                label: 'No',
                                            },
                                            {
                                                id: 'max_discount_amount_yes',
                                                label: 'Yes',
                                            },
                                        ]}
                                        defaultValue={true}
                                    />
                                </EuiFormRow>
                            </EuiFlexItem>
                        </EuiFlexGroup>
                        {watch('max_discount_amount') && (
                            <NumberField
                                label='Maximum discount amount'
                                isInvalid={errors.max_discount_amount_value}
                                errors={errors}
                                name='max_discount_amount_value'
                                placeholder='Please maximum discount amount'
                                rules={{
                                    required: 'Please enter maximum discount amount',
                                    validate: (value) => value > 0 || 'Please enter value greater than 0',
                                }}
                                step='any'
                                min={1}
                                control={control}
                            />
                        )}
                    </EuiFlexItem>
                )}

                {(selectedOfferType === PERCENTAGE_DISCOUNT ||
                    selectedOfferType === MENU_DISCOUNT ||
                    selectedOfferType === COMBO_ITEM_PRICE_DEAL) && (
                    <EuiFlexItem>
                        <NumberField
                            label='Percentage'
                            isInvalid={errors.percentage}
                            errors={errors}
                            name='percentage'
                            placeholder='Please enter percentage'
                            rules={{
                                required: 'Please enter percentage',
                                validate: (value) =>
                                    (value > 0 && value <= 100) || 'Please enter value between 0 to 100',
                            }}
                            step='any'
                            min={1}
                            max={100}
                            control={control}
                        />
                    </EuiFlexItem>
                )}

                {selectedOfferType === FREE_ITEMS && (
                    <EuiFlexItem>
                        <EuiFlexItem>
                            <ComboboxWithSearchField
                                label='Select free Item'
                                isInvalid={errors?.free_items?.message}
                                errroMessage={errors?.free_items?.message}
                                name='free_items'
                                options={items}
                                onSearchChange={handleItemsOnSearch}
                                isLoading={isLoading.items}
                                placeholder='Search to select free item'
                                rules={{
                                    validate: (value) =>
                                        (watch('free_items') && watch('free_items').length > 0) ||
                                        'Please select atleast one item',
                                }}
                            />
                        </EuiFlexItem>
                    </EuiFlexItem>
                )}

                {selectedOfferType === FREE_ITEMS &&
                    watch('free_items')?.map((item, index) => (
                        <EuiFlexItem style={{ marginLeft: '8%' }} key={index.toString()}>
                            <EuiFlexGroup alignItems='flexEnd'>
                                <EuiFlexItem grow={false}>
                                    <EuiFormRow
                                        label={item.label}
                                        isInvalid={errors?.free_items?.[index]?.quantity}
                                        error={
                                            errors?.free_items?.[index]?.quantity
                                                ? errors.free_items[index].quantity.message
                                                : ''
                                        }
                                    >
                                        <Controller
                                            render={({ field }) => (
                                                <EuiFieldNumber value={field.value} onChange={field.onChange} />
                                            )}
                                            name={`free_items[${index}].quantity`}
                                            control={control}
                                            rules={{
                                                required: 'Please enter quantity of free item',
                                                validate: (value) => value > 0 || 'Please enter  value greater than 0',
                                            }}
                                            fullWidth={true}
                                            min={1}
                                            placeholder='Please enter quantity of free item'
                                            required={errors?.free_items?.[index]?.quantity}
                                        />
                                    </EuiFormRow>
                                </EuiFlexItem>
                                <EuiFlexItem grow={false}>
                                    <EuiButton
                                        iconType='minusInCircle'
                                        style={{ textAlign: 'left' }}
                                        onClick={() => deleteSpecificItem(item.item_id, index)}
                                    >
                                        Remove
                                    </EuiButton>
                                </EuiFlexItem>
                            </EuiFlexGroup>
                        </EuiFlexItem>
                    ))}

                <EuiFlexItem>
                    {selectedOfferType !== MENU_DISCOUNT && (
                        <EuiFlexGroup alignItems='center'>
                            <EuiFlexItem grow={false}>
                                <EuiFormRow fullWidth={true}>
                                    <RadioGroupField
                                        options={[
                                            {
                                                id: 'min_cart_amount_no',
                                                label: 'No',
                                            },
                                            {
                                                id: 'min_cart_amount_yes',
                                                label: 'Yes',
                                            },
                                        ]}
                                        idSelected={
                                            watch('min_cart_amount') ? 'min_cart_amount_yes' : 'min_cart_amount_no'
                                        }
                                        onChange={(id) =>
                                            setValue('min_cart_amount', id === 'min_cart_amount_yes' ? true : false)
                                        }
                                        legend='Do you want to set a minimum cart value to apply this offer?'
                                        name='min_cart_amount'
                                        defaultValue={true}
                                    />
                                </EuiFormRow>
                            </EuiFlexItem>
                        </EuiFlexGroup>
                    )}
                    <EuiSpacer size='s' />
                    {watch('min_cart_amount') && (
                        <NumberField
                            label='Minimum cart amount'
                            isInvalid={errors.minimum_cart_amount_value}
                            errors={errors}
                            name='minimum_cart_amount_value'
                            placeholder='Please enter minimum cart amount'
                            rules={{
                                required: 'Please enter minimum cart amount',
                                validate: (value) => value > 0 || 'Please enter value greater than 1',
                            }}
                            step='any'
                            min={1}
                            control={control}
                        />
                    )}
                </EuiFlexItem>
            </EuiFlexGrid>

            <EuiFlexGrid columns={1}>
                <EuiFlexItem grow={false}>
                    <RadioGroupField
                        idSelected={
                            watch('catogaries') === 'all_categories'
                                ? 'all_categories'
                                : watch('catogaries') === 'specific_categories'
                                ? 'specific_categories'
                                : watch('catogaries') === 'specific_items'
                                ? 'specific_items'
                                : 'all_categories'
                        }
                        options={[
                            {
                                id: 'all_categories',
                                label: 'This offer is applicable on all categories.',
                            },
                            {
                                id: 'specific_categories',
                                label: 'This offer is only applicable on specific categories.',
                            },
                            ...(selectedOfferType === 'menu_discount'
                                ? []
                                : [
                                      {
                                          id: 'specific_items',
                                          label: 'This offer is only applicable on specific items.',
                                      },
                                  ]),
                        ]}
                        name='catogaries'
                        onChange={handleCategoryItemRadioChange}
                        legend={'Decide this offer is apply on which categories or items'}
                    />
                </EuiFlexItem>

                {watch('catogaries') === 'specific_categories' && (
                    <EuiFlexItem>
                        <ComboboxWithSearchField
                            options={categories}
                            name='category.categories'
                            label='Category'
                            isInvalid={errors?.category?.categories?.message}
                            errroMessage={errors?.category?.categories?.message}
                            onSearchChange={handleCategoriesOnSearch}
                            isLoading={isLoading.categories}
                            placeholder='Search to select free category'
                            rules={{
                                validate: (value) =>
                                    (watch('category.categories') && watch('category.categories').length > 0) ||
                                    'Please select atleast one category',
                            }}
                            renderOption={renderOption}
                        />
                    </EuiFlexItem>
                )}

                {watch('catogaries') === 'specific_items' && (
                    <EuiFlexItem>
                        <ComboboxWithSearchField
                            label={'Item'}
                            isInvalid={errors?.required_items?.message}
                            errroMessage={errors?.required_items?.message}
                            name='required_items'
                            options={items}
                            onSearchChange={handleItemsOnSearch}
                            isLoading={isLoading.items}
                            placeholder='Search to select free item'
                            rules={{
                                validate: (value) =>
                                    (watch('required_items') && watch('required_items')?.length > 0) ||
                                    'Please select atleast one item',
                            }}
                        />
                    </EuiFlexItem>
                )}
            </EuiFlexGrid>

            {watch('catogaries') === 'specific_categories' &&
                watch('category.categories')?.map((item, index) => {
                    return (
                        <>
                            <EuiSpacer size='s' />
                            <EuiFlexItem style={{ marginLeft: '8%' }} key={index}>
                                <EuiFlexGroup alignItems='flexEnd'>
                                    <EuiFlexItem grow={false}>
                                        <EuiFormRow
                                            label={item.label}
                                            isInvalid={errors?.category?.categories?.[index]?.quantity}
                                            error={
                                                errors?.category?.categories?.[index]?.quantity
                                                    ? errors?.category?.categories?.[index]?.quantity?.message
                                                    : null
                                            }
                                        >
                                            <Controller
                                                render={({ field }) => {
                                                    return (
                                                        <EuiFieldNumber
                                                            value={field.value}
                                                            onChange={field.onChange}
                                                            placeholder='Please enter quantity of free item'
                                                        />
                                                    );
                                                }}
                                                name={`category.categories[${index}].quantity`}
                                                control={control}
                                                rules={{
                                                    required: 'Please enter quantity of free item',
                                                    validate: (value) =>
                                                        value >= 0 || 'Please enter  value greater than 0',
                                                }}
                                                fullWidth={true}
                                                min={1}
                                                required={errors?.category?.categories?.[
                                                    index
                                                ]?.quantity?.hasOwnProperty(index)}
                                            />
                                        </EuiFormRow>
                                    </EuiFlexItem>
                                    <EuiFlexItem grow={false}>
                                        <EuiButton
                                            iconType='minusInCircle'
                                            style={{ textAlign: 'left' }}
                                            onClick={() =>
                                                deleteSpecificCategories(item.required_item_category_id, index)
                                            }
                                        >
                                            Remove
                                        </EuiButton>
                                    </EuiFlexItem>
                                </EuiFlexGroup>
                            </EuiFlexItem>
                        </>
                    );
                })}

            {watch('catogaries') === 'specific_items' && watch('required_items')?.length
                ? watch('required_items').map((item, index) => {
                      return (
                          <React.Fragment key={index.toString()}>
                              <EuiSpacer size='s' />
                              <EuiFlexItem style={{ marginLeft: '8%' }} key={index}>
                                  <EuiFlexGroup alignItems='flexEnd'>
                                      <EuiFlexItem grow={false}>
                                          <EuiFormRow
                                              label={item.label}
                                              isInvalid={errors?.required_items?.[index]?.quantity}
                                              error={
                                                  errors?.required_items?.[index]?.quantity
                                                      ? errors.required_items[index].quantity.message
                                                      : ''
                                              }
                                          >
                                              <Controller
                                                  render={({ field }) => (
                                                      <EuiFieldNumber
                                                          placeholder='Please enter quantity of free item'
                                                          value={field.value}
                                                          onChange={field.onChange}
                                                      />
                                                  )}
                                                  name={`required_items[${index}].quantity`}
                                                  control={control}
                                                  rules={{
                                                      required: 'Please enter quantity of free item',
                                                      validate: (value) =>
                                                          value >= 0 || 'Please enter  value greater than 0',
                                                  }}
                                                  fullWidth={true}
                                                  min={1}
                                                  required={errors?.required_items?.quantity?.hasOwnProperty(index)}
                                              />
                                          </EuiFormRow>
                                      </EuiFlexItem>
                                      <EuiFlexItem grow={false}>
                                          <EuiButton
                                              iconType='minusInCircle'
                                              style={{ textAlign: 'left' }}
                                              onClick={() => deleteSpecificRequiredItems(item.item_id, index)}
                                          >
                                              Remove
                                          </EuiButton>
                                      </EuiFlexItem>
                                  </EuiFlexGroup>
                              </EuiFlexItem>
                          </React.Fragment>
                      );
                  })
                : null}

            {selectedOfferType === BUY_X_GET_X && (
                <EuiFlexGroup alignItems='center' style={{ marginTop: 10, marginLeft: 0 }}>
                    <EuiFlexItem grow={false}>
                        <EuiText>Buy </EuiText>
                    </EuiFlexItem>
                    <EuiFlexItem grow={false}>
                        <NumberField
                            label=''
                            isInvalid={errors.buy_x1}
                            errors={errors}
                            name='buy_x1'
                            placeholder='buy quantity'
                            rules={{
                                required: 'Please enter buy quantity',
                                validate: (value) => value >= 1 || 'Please enter value greater than 1',
                            }}
                            step='any'
                            min={1}
                            control={control}
                        />
                    </EuiFlexItem>
                    <EuiFlexItem grow={false}>
                        <EuiText> Get </EuiText>
                    </EuiFlexItem>
                    <EuiFlexItem grow={false}>
                        <NumberField
                            label=''
                            isInvalid={errors.get_y1}
                            errors={errors}
                            name='get_y1'
                            placeholder='get quantity'
                            rules={{
                                required: 'Please enter get quantity',
                                validate: (value) => value >= 1 || 'Please enter value greater than 1',
                            }}
                            step='any'
                            min={1}
                            control={control}
                        />
                    </EuiFlexItem>
                </EuiFlexGroup>
            )}
        </>
    );
};

export default React.memo(Configure);
