import React, { useState } from 'react';
import { EuiButton, EuiFlexItem } from '@elastic/eui';
import './style.css';
import TableAssignModal from './tableAssignModal';
import { isEmpty } from 'lodash';

const EventComponent = (props) => {
    const { event, title } = props;
    const [selectedEvent, setSelectedEvent] = useState({});

    const onSelect = () => {
        if (!event.booked_tables.length) {
            setSelectedEvent(event);
        }
    };

    const onUpdate = () => {
        setSelectedEvent(event);
    };

    const onOccupyTable = () => {
        event.checkTime(event);
    };

    return (
        <>
            {!isEmpty(selectedEvent) && (
                <TableAssignModal
                    fetchList={event.fetchList}
                    selectedEvent={selectedEvent}
                    setSelectedEvent={setSelectedEvent}
                    outletId={event.outletId}
                />
            )}
            <div
                style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}
            >
                <h1>{title}</h1>
                <EuiFlexItem style={{ display: 'flex', flexDirection: 'row' }}>
                    {event.booked_tables.length ? (
                        <EuiButton onClick={onOccupyTable} style={{ height: '25px' }} fill>
                            {' '}
                            Occupy table{' '}
                        </EuiButton>
                    ) : (
                        <EuiButton
                            disabled={event.booked_tables.length}
                            onClick={onSelect}
                            style={{ height: '25px' }}
                            fill
                        >
                            {' '}
                            {event.booked_tables.length ? 'Assigned' : 'Assign table'}
                        </EuiButton>
                    )}

                    {event.booked_tables.length ? (
                        <EuiButton onClick={onUpdate} style={{ height: '25px', marginLeft: '20px' }} fill>
                            Update table
                        </EuiButton>
                    ) : (
                        ''
                    )}
                </EuiFlexItem>
            </div>
        </>
    );
};

export default EventComponent;
