import React, { useCallback, useRef, useState } from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import './style.css';
import { EuiFlexItem, EuiButtonEmpty, EuiText, EuiSpacer } from '@elastic/eui';
import ReactToPrint from 'react-to-print';
import KotPrint from '../print/KotPrint';
import InvoicePrint from '../print/InvoicePrint';
import { isEmpty } from 'lodash';
import { UserInfoModal } from '../userInfoModal';
import { isElectron } from '../../utils/utils';

const LiveOrderCustomerInformation = (props) => {
    const { selectedOrder } = props;
    const languageId = useSelector((state) => state.language.languageId);
    const kotPrinterRef = useRef(null);
    const invoicePrinterRef = useRef(null);
    const [state, setState] = useState({ userModalOpen: false });

    const getPaymentMethod = useCallback((mode) => {
        if (mode) {
            if (mode === 'CASH_ON_DELIVERY') {
                return 'Cash on delivery';
            } else {
                return 'Online';
            }
        }
    }, []);

    const openUserInfoModal = () => {
        setState({
            ...state,
            userModalOpen: true,
        });
    };

    const closeUserInfoModal = () => {
        setState({
            ...state,
            userModalOpen: false,
        });
    };

    let restaurantList = useSelector((state) => state.auth.restaurantList);
    let selectedRestuarantId = useSelector((state) => state.auth.selectedRestaurantId);

    if (isEmpty(selectedOrder)) return null;
    return (
        <>
            {!isEmpty(selectedOrder.customer) || !isEmpty(selectedOrder.customer_address) ? (
                <UserInfoModal
                    userModalOpen={state.userModalOpen}
                    closeUserInfoModal={closeUserInfoModal}
                    userInfo={selectedOrder.customer_address}
                    userName={selectedOrder.customer.name}
                    inputAddess={
                        selectedOrder.customer_address &&
                        selectedOrder.customer_address.block_number + ', ' + selectedOrder.customer_address.land_mark
                    }
                    locationPin={
                        selectedOrder.customer_address &&
                        selectedOrder.customer_address.latitude + ', ' + selectedOrder.customer_address.longitude
                    }
                    phoneNumber={selectedOrder.customer.mobile_number}
                    email={selectedOrder.customer.email}
                    countryCode={selectedOrder.customer.country_code}
                    customerOrderCount={selectedOrder.order_count}
                    lifeTimeValue={selectedOrder.life_time_value}
                />
            ) : (
                ''
            )}
            {!props.isAbandoned ? (
                <div style={{ display: 'none' }}>
                    <KotPrint order={selectedOrder} languageId={languageId} ref={kotPrinterRef} />
                    <InvoicePrint
                        order={selectedOrder}
                        languageId={languageId}
                        ref={invoicePrinterRef}
                        restaurantList={restaurantList}
                        selectedRestuarantId={selectedRestuarantId}
                    />
                </div>
            ) : (
                ''
            )}
            <div
                style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}
            >
                <EuiText>
                    <h2>
                        <b>
                            Order{' '}
                            {selectedOrder?.last_four_character_order_label
                                ? `#${selectedOrder?.last_four_character_order_label?.toUpperCase()}`
                                : ''}
                        </b>
                    </h2>
                </EuiText>
                {props.isFromTableManagement ? (
                    <EuiText>{getPaymentMethod(selectedOrder?.payment_method?.payment_method)}</EuiText>
                ) : null}
            </div>

            <div style={{ display: 'flex', flexDirection: 'row' }}>
                <EuiFlexItem grow={5}>
                    <EuiText>
                        <h4>{selectedOrder?.customer?.name}</h4>
                    </EuiText>
                    <div>
                        {selectedOrder?.customer_address ? (
                            <>
                                {/* <EuiText size="s">{selectedOrder?.customer_address?.region}, {selectedOrder?.customer_address?.block_number}</EuiText> */}
                                <EuiText size='s'>
                                    {selectedOrder?.customer_address?.block_number},{' '}
                                    {selectedOrder?.customer_address?.land_mark},{' '}
                                    {selectedOrder?.customer_address?.city}, {selectedOrder?.customer_address?.state}
                                </EuiText>
                            </>
                        ) : (
                            ''
                        )}
                        <EuiSpacer size='xs' />
                        <EuiText size='xs'>
                            {selectedOrder?.customer?.country_code} {selectedOrder?.customer?.mobile_number}
                        </EuiText>

                        {selectedOrder?.customer?.email ? (
                            <EuiText size='xs'>{selectedOrder?.customer?.email}</EuiText>
                        ) : (
                            ''
                        )}
                        {selectedOrder?.table ? <EuiText size='s'>Table: {selectedOrder.table?.code}</EuiText> : null}

                        {!isEmpty(selectedOrder.customer) || !isEmpty(selectedOrder.customer_address) ? (
                            <EuiButtonEmpty
                                onClick={openUserInfoModal}
                                style={{ fontSize: '12px', marginLeft: '-6px' }}
                                size='s'
                            >
                                {' '}
                                Show more{' '}
                            </EuiButtonEmpty>
                        ) : (
                            ''
                        )}
                    </div>
                </EuiFlexItem>

                <EuiFlexItem
                    grow={5}
                    style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', paddingTop: '7px' }}
                >
                    <EuiText size='xs'>
                        {selectedOrder?.status?.placed_at
                            ? moment(selectedOrder.status.placed_at).format('DD MMM YYYY, hh:mm a')
                            : ''}
                    </EuiText>
                    {!props.isFromTableManagement ? (
                        <EuiText size='xs'>{selectedOrder?.outlet?.translations.name[languageId]}</EuiText>
                    ) : null}
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <EuiText size='s'>
                            Customer's {selectedOrder.order_count}
                            <span style={{ fontSize: '70%', verticalAlign: 'super' }}>
                                {orderSuperSet(selectedOrder.order_count)}&nbsp;
                            </span>
                        </EuiText>

                        <EuiText style={{ marginTop: '3px' }} size='s'>
                            {' '}
                            order
                        </EuiText>
                    </div>

                    {!props.isAbandoned ? (
                        <div style={{ textAlign: 'right' }}>
                            {isElectron() ? (
                                <>
                                    <EuiButtonEmpty
                                        style={{ fontSize: '12px', marginRight: '-8px' }}
                                        size='s'
                                        onClick={() => {
                                            if (window?.api?.printBill) {
                                                window.api.printKot('toMainPrint', selectedOrder.order_id);
                                            }
                                        }}
                                    >
                                        PRINT KOT
                                    </EuiButtonEmpty>

                                    {!props.isFromTableManagement ? (
                                        <EuiButtonEmpty
                                            style={{ fontSize: '12px', marginRight: '-8px' }}
                                            id='printInvoice'
                                            size='s'
                                            onClick={() => {
                                                if (window?.api?.printBill) {
                                                    let restaurantId = parseInt(
                                                        localStorage.getItem('selectedRestaurant')
                                                    );
                                                    let restaurant = restaurantList.restaurants.filter(
                                                        (restaurant) => restaurant.id == restaurantId
                                                    );
                                                    window.api.printBill('toMainPrint', {
                                                        orderId: selectedOrder.order_id,
                                                        restaurantName: restaurant[0].name,
                                                    });
                                                }
                                            }}
                                        >
                                            PRINT BILL
                                        </EuiButtonEmpty>
                                    ) : null}
                                </>
                            ) : (
                                <>
                                    <ReactToPrint
                                        copyStyles={false}
                                        trigger={() => (
                                            // <EuiButtonIcon iconType='apmTrace' aria-label='Next' />
                                            <EuiButtonEmpty
                                                style={{
                                                    fontSize: '12px',
                                                    marginRight: `${props.isFromTableManagement ? 0 : '-8px'}`,
                                                }}
                                                size='s'
                                            >
                                                {' '}
                                                PRINT KOT{' '}
                                            </EuiButtonEmpty>
                                        )}
                                        content={() => kotPrinterRef.current}
                                    />
                                    {!props.isFromTableManagement ? (
                                        <ReactToPrint
                                            // print={(e) => checkClick(e)}
                                            copyStyles={false}
                                            trigger={() => (
                                                <EuiButtonEmpty
                                                    style={{ fontSize: '12px', marginRight: '-8px' }}
                                                    id='printInvoice'
                                                    size='s'
                                                >
                                                    {' '}
                                                    PRINT BILL{' '}
                                                </EuiButtonEmpty>
                                                // <EuiButtonIcon  id="printInvoice" iconType='documents' aria-label='Next' />
                                            )}
                                            content={() => invoicePrinterRef.current}
                                        />
                                    ) : null}
                                </>
                            )}
                        </div>
                    ) : (
                        ''
                    )}
                </EuiFlexItem>
            </div>
        </>
    );
};

export const orderSuperSet = (value) => {
    let modules1 = value % 10,
        modules2 = value % 100;
    if (modules1.toString() === '1' && modules2.toString() !== '11') {
        return 'st';
    }
    if (modules1.toString() === '2' && modules2.toString() !== '12') {
        return 'nd';
    }
    if (modules1.toString() === '3' && modules2.toString() !== '13') {
        return 'rd';
    }
    return 'th';
};

export default LiveOrderCustomerInformation;
