import React, { useCallback, useState, useEffect } from 'react';
import {
    EuiFieldSearch,
    EuiFlexGroup,
    EuiFlexItem,
    EuiButtonEmpty,
    EuiSuperSelect,
    EuiIcon,
    EuiLoadingContent,
} from '@elastic/eui';
import API from '../../api/axios/API';
import _ from 'lodash';
import CustomerDataGrid from './customerDataGrid';
import CustomerCsvDownload from './customerCsvDownload';

const CustomerList = () => {
    const [activePage, setActivePage] = useState(0);
    const [rowSize, setRowSize] = useState(10);
    const [csvState, setCsvState] = useState(false);

    const filterOption = [
        { value: 'name', inputDisplay: 'Name' },
        { value: 'order_count', inputDisplay: 'Total order count' },
        { value: 'first_order', inputDisplay: 'First order date' },
        { value: 'last_order', inputDisplay: 'Last order date' },
        { value: 'join_date', inputDisplay: 'Join date' },
        { value: 'life_time_value', inputDisplay: 'Life time value' },
        { value: 'order_ratings', inputDisplay: 'Order ratings' },
        { value: 'delivery_ratings', inputDisplay: 'Delivery Ratings' },
    ];

    const [selectedFilterColumn, setSelectedFilterColumn] = useState(filterOption[3].value);

    const filterDirection = [
        {
            value: 'asc',
            inputDisplay: (
                <div>
                    <span>Ascending</span> <EuiIcon type='sortUp' />
                </div>
            ),
        },
        {
            value: 'desc',
            inputDisplay: (
                <div>
                    <span>Descending</span> <EuiIcon type='sortDown' />
                </div>
            ),
        },
    ];

    const [selectedFilterDirection, setSelectedFilterDirection] = useState(filterDirection[1].value);

    const activePageCallback = useCallback((value) => {
        setActivePage(value);
    }, []);

    const rowSizeCallback = useCallback((value) => {
        setRowSize(value);
    }, []);

    const csvStateCallback = useCallback((value) => {
        setCsvState(value);
    }, []);

    const [getAllCustomer, setAllCustomer] = useState(null);
    const [isLaoding, setIsLoading] = useState(true);
    const fetchCustomerList = async () => {
        let response;

        try {
            response = await API.get(
                `restaurants/:restaurantId/customers?page=${
                    activePage + 1
                }&per_page=${rowSize}&filter[column]=${selectedFilterColumn}&filter[direction]=${selectedFilterDirection}`
            );
        } catch (error) {
            response = error;
        }

        if (response.success) {
            setAllCustomer(response);
        } else {
            alert('Error in customer fetch');
        }
        setIsLoading(false);
    };
    useEffect(() => {
        fetchCustomerList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [searchText, setSearchText] = useState('');

    const onActivePageChange = async () => {
        let customerResponse;
        try {
            customerResponse = await API.get(
                `restaurants/:restaurantId/customers?search_query=${searchText}&page=${
                    activePage + 1
                }&per_page=${rowSize}&filter[column]=${selectedFilterColumn}&filter[direction]=${selectedFilterDirection}`
            );
        } catch (error) {
            customerResponse = error;
        }

        setAllCustomer(customerResponse);
    };

    useEffect(() => {
        onActivePageChange();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rowSize, activePage]);

    const getFilteredCustomer = async (q, filterColumn = 'name', filterDirection = 'asc', from) => {
        let customerResponse;
        try {
            customerResponse = await API.get(
                `restaurants/:restaurantId/customers?search_query=${q}&page=1&per_page=${rowSize}&filter[column]=${filterColumn}&filter[direction]=${filterDirection}`
            );
        } catch (error) {
            customerResponse = error;
        }
        setActivePage(0);
        setAllCustomer(customerResponse);
    };

    const delayedQuery = useCallback(
        _.debounce((q, columnFilter, directionFilter) => getFilteredCustomer(q, columnFilter, directionFilter), 500),
        []
    );

    if (isLaoding) {
        return <EuiLoadingContent lines={10} />;
    }

    const onChangeSerachText = (value) => {
        setSearchText(value);
        delayedQuery(value, selectedFilterColumn, selectedFilterDirection);
    };

    const onColumnFilterChange = (value) => {
        setSelectedFilterColumn(value);
        getFilteredCustomer(searchText, value, selectedFilterDirection, 'column filter');
    };

    const onDirectionFilterChange = (value) => {
        setSelectedFilterDirection(value);
        getFilteredCustomer(searchText, selectedFilterColumn, value, 'direction filter');
    };

    return (
        <>
            <EuiFlexItem
                style={{
                    paddingBottom: '10px',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: '90%',
                    marginBottom: '10px',
                }}
            >
                <div style={{ width: '25%' }}>
                    <b style={{ fontSize: '12px' }}>Search by</b>
                    <EuiFieldSearch
                        placeholder='Search by user or number'
                        value={searchText}
                        onChange={(e) => onChangeSerachText(e.target.value)}
                        isClearable={true}
                        aria-label='search user'
                    />
                </div>
                <div style={{ width: '25%' }}>
                    <b style={{ fontSize: '12px' }}>Sort by</b>
                    <EuiSuperSelect
                        options={filterOption}
                        valueOfSelected={selectedFilterColumn}
                        onChange={(value) => onColumnFilterChange(value)}
                        fullWidth={true}
                    />
                </div>
                <div style={{ width: '25%' }}>
                    <b style={{ fontSize: '12px' }}>Sort by</b>
                    <EuiSuperSelect
                        options={filterDirection}
                        valueOfSelected={selectedFilterDirection}
                        onChange={(value) => onDirectionFilterChange(value)}
                        fullWidth={true}
                    />
                </div>
                <EuiButtonEmpty onClick={() => setCsvState(true)}>Download CSV</EuiButtonEmpty>
                {csvState && (
                    <CustomerCsvDownload
                        // setCsvState ={setCsvState}
                        csvStateCallback={csvStateCallback}
                        searchText={searchText}
                        getAllCustomer={getAllCustomer}
                    />
                )}
            </EuiFlexItem>
            <EuiFlexItem>
                <CustomerDataGrid
                    getAllCustomer={getAllCustomer}
                    activePage={activePage}
                    activePageCallback={activePageCallback}
                    rowSize={rowSize}
                    rowSizeCallback={rowSizeCallback}
                />
            </EuiFlexItem>
        </>
    );
};

export default CustomerList;
