import React, { useEffect, useState } from 'react';
import { EuiPage, EuiCard, EuiFlexGroup, EuiFlexItem, EuiListGroup, EuiListGroupItem } from '@elastic/eui';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import API from '../../api/axios/API.js';
import { initializeRestaurant } from '../../reduxStore/actions/initilizationActionCreator';

const RestaurantSelectionPage = () => {
    const dispatch = useDispatch();
    let history = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState({});

    // This is used to check if we need to show loading when selecting restaurant from localstorage.
    let [loadingRestaurant, setLoadingRestaurant] = useState(true);

    const fetchRestaurant = async () => {
        setIsLoading(true);
        await API.get('/users/restaurants').then((res) => {
            setData(res);
            setIsLoading(false);
        });
    };

    useEffect(() => {
        fetchRestaurant();
    }, []);

    const selectedRestaurantId = localStorage.getItem('selectedRestaurant')
        ? localStorage.getItem('selectedRestaurant')
        : null;

    useEffect(() => {
        // If we already have a selected restaurant id in localstorage let's use that.
        if (selectedRestaurantId) {
            handleRestaurantSelection(selectedRestaurantId);
        } else {
            setLoadingRestaurant(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedRestaurantId]);

    const handleRestaurantSelection = async (restaurantId) => {
        localStorage.setItem('selectedRestaurant', restaurantId);
        dispatch(initializeRestaurant(restaurantId, history));
    };

    if (isLoading || loadingRestaurant) {
        return '';
    }

    return (
        <EuiPage style={{ height: '100vh' }}>
            <EuiFlexGroup direction='row' alignItems='flexStart' justifyContent='center' style={{ width: '100%' }}>
                <EuiFlexItem style={{ maxHeight: '95vh', maxWidth: '780px', position: 'relative' }}>
                    <EuiCard
                        layout='horizontal'
                        title={'Select Restaurant'}
                        description='Select restaurant you want to login'
                    >
                        <EuiListGroup style={{ maxWidth: '100%', maxHeight: '85vh' }} className='scrollbar'>
                            {data.restaurants.map((item) => (
                                <EuiListGroupItem
                                    key={item.id}
                                    onClick={() => handleRestaurantSelection(item.id)}
                                    label={item.name + ' >'}
                                    color='subdued'
                                    size='l'
                                />
                            ))}
                        </EuiListGroup>
                    </EuiCard>
                </EuiFlexItem>
            </EuiFlexGroup>
        </EuiPage>
    );
};
export default RestaurantSelectionPage;
