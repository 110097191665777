import API from '../axios/API';

export const getTableDetails = (tableId) => {
    return async (dispatch, getState) => {
        let response;
        let { outlet } = getState();

        try {
            response = await API.get(`restaurants/:restaurantId/outlets/${outlet.selectedOutletId}/tables/${tableId}`);
        } catch (error) {
            response = error;
        }

        return response;
    };
};
