import React, { useCallback } from 'react';
import { EuiGlobalToastList } from '@elastic/eui';
import { useDispatch, useSelector } from 'react-redux';
import { REMOVE_TOAST } from '../../reduxStore/types/Global/Global';

const ToastContainer = () => {
    const dispatch = useDispatch();
    const toasts = useSelector((state) => state.toastReducer.toasts);

    const removeToast = useCallback(
        (removedToast) => {
            dispatch({
                type: REMOVE_TOAST,
                payload: removedToast,
            });
        },
        [dispatch]
    );

    return (
        <>
            {toasts.length ? (
                <EuiGlobalToastList toasts={toasts} dismissToast={removeToast} toastLifeTimeMs={5000} />
            ) : null}
        </>
    );
};

export default React.memo(ToastContainer);
