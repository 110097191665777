import React from 'react';
import {
    EuiOverlayMask,
    EuiModal,
    EuiModalHeader,
    EuiModalHeaderTitle,
    EuiModalBody,
    EuiModalFooter,
    EuiButtonEmpty,
    EuiButton,
} from '@elastic/eui';
import API from '../../api/axios/API';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import * as types from '../../reduxStore/types/billing';

const RunningOrderSettingsModal = (props) => {
    const { setSettingsModal, order, superIndex, setEditOrder, setEditPreviousOrders } = props;
    const languageCode = useSelector((state) => state.language.language);
    const customerInformation = useSelector((state) => state.billing.customerInformation);

    const previousOrders = useSelector((state) => state.billing.previousOrders);
    const tableSelectedList = useSelector((state) => state.billing.tableSelectedList);

    const dispatch = useDispatch();

    const convertAddonStructure = (customizations) => {
        let addonStructure = {};

        customizations.map((addon) => {
            let innerArray = addonStructure[addon.addon_group_id] ? addonStructure[addon.addon_group_id] : [];

            innerArray.push(addon);

            addonStructure[addon.addon_group_id] = innerArray;
        });

        return addonStructure;
    };

    const closeModal = () => {
        setSettingsModal(false);
    };

    const duplicatieBilling = async () => {
        let response;
        try {
            response = await API.post();
        } catch (error) {
            response = error;
        }

        if (response.success) {
        } else {
            alert('Error in updating');
        }
    };

    const orderBillingStructureNew = () => {
        let itemObj = {};

        console.log('super index ', superIndex);
        previousOrders[superIndex].cart.cart_items.map((value) => {
            let innerItemObj = {};
            if (!itemObj[value.item_id]) {
                if (value.customizations && value.customizations.length > 0) {
                    // innerItemObj['customizations'] = value.customizations
                    innerItemObj['customizations'] = convertAddonStructure(value.customizations);
                    innerItemObj['addon_information'] = { quantity: value.quantity };
                    innerItemObj['quantity'] = 0;
                } else {
                    innerItemObj['quantity'] = value.quantity;
                }

                if (value.special_instruction) {
                    innerItemObj['special_instruction'] = value.special_instruction;
                }
            } else {
                if (value.customizations && value.customizations.length > 0) {
                    // innerItemObj['customizations'] = value.customizations
                    innerItemObj['customizations'] = convertAddonStructure(value.customizations);
                    innerItemObj['addon_information'] = {
                        quantity: parseInt(itemObj[value.item_id]['quantity']) + parseInt(value.quantity),
                    };
                    innerItemObj['quantity'] = 0;
                } else {
                    innerItemObj['quantity'] = parseInt(itemObj[value.item_id]['quantity']) + parseInt(value.quantity);
                }

                if (value.special_instruction) {
                    innerItemObj['special_instruction'] = value.special_instruction;
                }
            }

            itemObj[value.item_id] = innerItemObj;
        });

        console.log('order billing structure new ', itemObj);

        return itemObj;
    };

    const kotVoid = async () => {
        let itemStructure = orderBillingStructureNew();
        let innerObj = {};

        innerObj['void_order'] = 1;

        innerObj['items'] = itemStructure;

        innerObj.customer = {
            ...customerInformation,
            mobile_number: customerInformation.mobile_number,
            date_of_birth: customerInformation.date_of_birth
                ? moment(customerInformation.date_of_birth).format('YYYY-MM-DD')
                : '',
            anniversary_date: customerInformation.anniversary_date
                ? moment(customerInformation.anniversary_date).format('YYYY-MM-DD')
                : '',
            // date_of_birth: data.date_of_birth ? moment(data.date_of_birth).format('YYYY-MM-DD') : '',
            // anniversary_date: data.anniversary_date ? moment(data.anniversary_date).format('YYYY-MM-DD') : '',
        };

        console.log('edit order hson string ', JSON.stringify(innerObj));

        let response;

        try {
            response = await API.put(
                `${languageCode}/pos/restaurants/:restaurantId/outlets/${order.outlet_id}/orders/${order.order_id}`,
                innerObj
            );
        } catch (error) {
            response = error;
        }

        if (response.success) {
            alert('Success kot void');
            fetchPreviousOrders();
        } else {
            alert('Error in kot void');
        }

        closeModal();
    };

    const fetchPreviousOrders = async () => {
        let response;

        try {
            response = await API.get(
                `restaurants/:restaurantId/outlets/${order.outlet_id}/tables/${tableSelectedList[0]}`
            );
        } catch (error) {
            response = error;
        }

        console.log('response order setting modal ', response);
        if (response.success) {
            dispatch({
                type: types.SET_PREVIOUS_ORDERS,
                payload: {
                    previousOrders: response.orders.orders,
                },
            });
        } else {
            alert('Error in fetching table orders');
        }
    };

    const editOrder = () => {
        setEditOrder(true);
        setSettingsModal(false);
        let orders = JSON.parse(JSON.stringify(previousOrders));
        setEditPreviousOrders(orders);
    };

    return (
        <>
            <EuiOverlayMask>
                <EuiModal onClose={closeModal} initialFocus='[name=popswitch]'>
                    <EuiModalHeader>
                        <EuiModalHeaderTitle>Kot Settings</EuiModalHeaderTitle>
                    </EuiModalHeader>

                    <EuiModalBody>
                        <EuiButton onClick={kotVoid} color='danger' fill>
                            Kot void
                        </EuiButton>
                        <EuiButton onClick={duplicatieBilling} style={{ marginLeft: '15px' }} color='secondary' fill>
                            Kot re-print
                        </EuiButton>
                        <EuiButton onClick={editOrder} style={{ marginLeft: '15px' }} color='text' fill>
                            Edit
                        </EuiButton>
                    </EuiModalBody>

                    <EuiModalFooter>
                        <EuiButtonEmpty onClick={closeModal}>Close</EuiButtonEmpty>
                    </EuiModalFooter>
                </EuiModal>
            </EuiOverlayMask>
        </>
    );
};

export default RunningOrderSettingsModal;
