import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { EuiForm } from '@elastic/eui';
import FormGroupDescription from '../../../components/formGroupDescription';
import TextField from '../../../components/Form/TextField';
import { useDispatch, useSelector } from 'react-redux';
import { restaurantSettingsAPI } from '../../../api/restaurantSettings/restaurantSettingsAPI';
import * as types from '../../../reduxStore/types/outlet';
import { userHasPermission } from '../../../helper/auth';

const GoogleMapsRestaurantSettings = (props) => {
    const { restaurantResponse, fetchRestaurant } = props;
    const {
        handleSubmit,
        control,
        watch,
        reset,
        setValue,

        formState: { isDirty, errors },
    } = useForm();
    const dispatch = useDispatch();
    const permissions = useSelector((state) => state.permission.permission);
    const restaurantDescriptions = useSelector((state) => state.auth.restaurantDescriptions);

    useEffect(() => {
        if (!userHasPermission(permissions, '/restaurant-settings', 'write')) return;
        if (isDirty) {
            dispatch({
                type: 'set-portal-active',
                payload: {
                    portalActive: {
                        resetChanges: resetChanges,
                        submit: handleSubmit(onSubmit),
                    },
                },
            });
        } else {
            dispatch({
                type: 'set-portal-active',
                payload: {
                    portalActive: {},
                },
            });
        }
    }, [isDirty]);

    useEffect(() => {
        if (restaurantResponse && restaurantResponse.restaurant) {
            resetChanges();
        }
    }, [restaurantResponse]);

    const resetChanges = () => {
        let defaultValue = {};
        let restaurantData = restaurantResponse.restaurant;
        defaultValue.google_maps_api_key = restaurantData.settings && restaurantData.settings.google_maps_api_key;
        if (!defaultValue.google_maps_api_key) {
            defaultValue.google_maps_api_key = '';
        }
        reset({
            ...defaultValue,
        });
    };

    const onSubmit = (data) => {
        dispatch(restaurantSettingsAPI('google_maps_settings', data)).then((res) => {
            if (res.success) {
                dispatch({
                    type: types.SET_PORTAL_ACTIVE,
                    payload: {
                        portalActive: {},
                    },
                });
                dispatch({
                    type: types.SET_PORTAL_ACTIVE_TAB_SWITCHED,
                    payload: {
                        tabSwitched: false,
                    },
                });
                fetchRestaurant();
            } else {
                alert(res.message);
            }
        });
    };

    return (
        <>
            <EuiForm component='form' onSubmit={handleSubmit(onSubmit)}>
                <FormGroupDescription
                    title={restaurantDescriptions?.google_maps?.display_name}
                    description={restaurantDescriptions?.google_maps?.description}
                >
                    <TextField
                        errors={errors}
                        label='Google API key'
                        name='google_maps_api_key'
                        placeholder='Enter Google maps API key'
                        // rules={{ required: 'Please Enter Google api key' }}
                        control={control}
                        watch={watch}
                    />
                </FormGroupDescription>
            </EuiForm>
        </>
    );
};

export default GoogleMapsRestaurantSettings;
