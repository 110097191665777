import React, { useCallback, useEffect, useState } from 'react';
import { useForm, Controller, useWatch, FormProvider } from 'react-hook-form';
import { useSelector } from 'react-redux';
import moment from 'moment';
import API from '../../api/axios/API';
import ComboBoxField from '../Form/ComboBoxField';
import TextField from '../Form/TextField';
import SelectField from '../Form/SelectField';
import TextAreaField from '../Form/TextAreaField';
import { EmojiPicker } from '../emojiPicker';
import {
    EuiButton,
    EuiButtonEmpty,
    EuiFlexGroup,
    EuiFlexItem,
    EuiFlyout,
    EuiFlyoutBody,
    EuiFlyoutFooter,
    EuiFlyoutHeader,
    EuiTitle,
    EuiDatePicker,
    EuiHorizontalRule,
    EuiForm,
} from '@elastic/eui';
import { isEmpty } from 'lodash';
import { fetchRestaurantCatalogue } from '../menuComponentsRefactorCopy/catalogue/Api/FetchCatalogueInfo';
import ImageSelectionZone from '../../modules/Banners/Components/ImageSelectionZone';

export const AddNotificationFlyout = ({ setRefetch, allScreen, allCategories, isOpen, closeFly }) => {
    let defaultValue = {};

    const methods = useForm({
        defaultValues: {
            ...defaultValue,
        },
    });

    // useForm initialize
    const {
        handleSubmit,
        control,
        watch,
        reset,
        setValue,

        formState: { errors },
    } = methods;

    // all state
    const availableLanguages = useSelector((state) => state.language.availableLanguages);
    const allNotification = useSelector((state) => state.notification.allNotification);
    const resetID = useSelector((state) => state.notification.resendID);
    const editID = useSelector((state) => state.notification.editID);
    const allLangiuages = useSelector((state) => state.language.availableLanguages);
    const [identifyState, setItentifyState] = useState(false);
    const [state, setState] = useState({
        sendLoading: false,
    });
    const languageId = useSelector((state) => state.language.languageId);
    const [categoryList, setCategoryList] = useState([]);
    const [languages, setLanguages] = useState([]);
    const [imageDeleteState, setImageDeleteState] = useState({});

    // hook for set default value
    useEffect(() => {
        if (resetID || editID) {
            const editNotificationData = allNotification.filter((item, index) => {
                if (item.id === resetID || item.id === editID) {
                    return item;
                }
                return null;
            })[0];

            let language = [];
            for (const key in editNotificationData.translations.title) {
                language.push({ id: key });
            }

            let setLanguages = [];
            var filterLanguage = allLangiuages.filter(function (o1) {
                return language.some(function (o2) {
                    return o1.language_id.toString() === o2.id.toString();
                });
            });
            filterLanguage.map((item) =>
                setLanguages.push({
                    id: item.language_id,
                    label: item.language_label,
                })
            );
            let translationObject = {
                title: {},
                description: {},
            };
            defaultValue.all_languages = setLanguages;
            for (const languageId in editNotificationData.translations.title) {
                translationObject.title[languageId] = editNotificationData.translations.title[languageId];
            }
            for (const languageId in editNotificationData.translations.description) {
                translationObject.description[languageId] = editNotificationData.translations.description[languageId];
            }
            let image = {};
            for (const property in editNotificationData.translations.image) {
                image[parseInt(property)] = {
                    ...editNotificationData.translations.image[property],
                    src:
                        editNotificationData.translations.image[property] &&
                        editNotificationData.translations.image[property].image_url &&
                        editNotificationData.translations.image[property].image_url,
                };
            }
            setImageDeleteState(image);
            defaultValue.translations = translationObject;
            defaultValue.image = image;
            defaultValue.screen = editNotificationData.screen;
            defaultValue.param = parseInt(editNotificationData.param);

            if (resetID) {
                defaultValue.time_to_send = null;
            } else {
                defaultValue.time_to_send = moment(editNotificationData.time_to_send);
            }
            reset({ ...defaultValue });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resetID, editID]);

    // method for horizontal line
    const renderHorizontalLine = () =>
        watch('all_languages') && watch('all_languages').length !== 0 ? <EuiHorizontalRule /> : '';

    const imageChecker = async (data) => {
        for (var key in data.all_languages) {
            if (data.image[data.all_languages[key].id]) {
                if (
                    imageDeleteState[data.all_languages[key].id] &&
                    data.image[data.all_languages[key].id].id !== imageDeleteState[data.all_languages[key].id].id
                ) {
                    try {
                        API.delete(
                            `/restaurants/:restaurantId/uploads/${imageDeleteState[data.all_languages[key].id].id}`
                        );
                    } catch (error) {
                        console.log('error in image delete ', error);
                    }
                }
            } else {
                if (!isEmpty(imageDeleteState[data.all_languages[key].id])) {
                    try {
                        API.delete(
                            `/restaurants/:restaurantId/uploads/${imageDeleteState[data.all_languages[key].id].id}`
                        );
                    } catch (error) {
                        console.log('error in image delete ', error);
                    }
                }
            }
        }
    };

    // call on  submit form
    const onSubmit = async (data) => {
        imageChecker(data);
        let image = {};
        let title = {};
        let description = {};
        for (const property in data.image) {
            image[parseInt(property)] =
                data.image[property] && data.image[property].id ? data.image[property].id : null;
        }
        for (const languageId in data.translations.title) {
            title[languageId] = data.translations.title[languageId];
        }
        for (const languageId in data.translations.description) {
            description[languageId] = data.translations.description[languageId];
        }
        data.translations.title = title;
        data.translations.description = description;
        function isEmpty(obj) {
            for (var prop in obj) {
                if (obj.hasOwnProperty(prop)) return false;
            }
            return true;
        }
        if (isEmpty(image)) {
            data.translations.image = image;
        }
        for (var propName in image) {
            if (image[propName] === null || image[propName] === undefined) {
                delete image[propName];
            }
        }
        data.translations.image = image;
        data.param = data.screen === 'category-detail-screen' ? data?.param : null;
        data.time_to_send = data.time_to_send ? moment(data.time_to_send).format('YYYY-MM-DD HH:MM') : null;

        setState({ ...state, sendLoading: true });
        try {
            if (identifyState) {
                data.time_to_send = null;
                data.id = editID;
                try {
                    const addNotification = await API.put(`restaurants/:restaurantId/notifications/${data.id}`, data);
                    if (addNotification.success) {
                        alert('Notification edit succssfully ');
                    }
                    setRefetch((preState) => !preState);
                } catch (e) {
                    alert('Please fill all the field');
                }
                setState({ ...state, sendLoading: false });
            } else if (editID) {
                data.id = editID;
                try {
                    const editNotification = await API.put(`restaurants/:restaurantId/notifications/${data.id}`, data);
                    if (editNotification.success) {
                        alert('Notification edit successfully');
                    }
                    setRefetch((preState) => !preState);
                } catch (e) {
                    alert('Please try later');
                }
                setState({ ...state, sendLoading: false });
            } else {
                try {
                    const addNotification = await API.post(`restaurants/:restaurantId/notifications`, data);
                    if (addNotification.success) {
                        alert('Notification add succssfully ');
                    }
                    setRefetch((preState) => !preState);
                } catch (e) {
                    alert('Please fill all the field');
                }
                setState({ ...state, sendLoading: false });
            }
            closeFly();
        } catch (e) {
            alert('Please try later');
            setState({ ...state, sendLoading: false });
        }
    };

    // Set screen for dropdown
    let screenList = [];
    allScreen &&
        allScreen.screens.map((item) =>
            screenList.push({
                value: item,
                inputDisplay: item,
            })
        );

    // Set language for dropdown
    let allLanguages = [];
    availableLanguages &&
        availableLanguages.map((item) =>
            allLanguages.push({
                id: item.language_id,
                label: item.language_label,
            })
        );

    const addEmoji = (name, emoji) => {
        let value = watch(name) ? watch(name) : '';
        value += emoji;
        setValue(name, value);
    };

    const fetchCategories = useCallback(async () => {
        await fetchRestaurantCatalogue(languageId).then((response) => {
            if (response?.categories?.length) {
                const list = [];
                for (const category of response.categories) {
                    list.push({
                        value: category.category_id.toString(),
                        inputDisplay: `${category.translations.title[languageId]} - ${category.internal_name} `,
                    });
                }
                setCategoryList(list);
            }
        });
    }, [languageId]);

    const selectedScreen = useWatch({
        control,
        name: 'screen',
    });
    const selectedLanguages = useWatch({
        control,
        name: 'all_languages',
    });

    useEffect(() => {
        if (selectedLanguages?.length) {
            const languages = availableLanguages.filter((availableLanguage) =>
                selectedLanguages.some((selectedLanguage) => availableLanguage.language_id === selectedLanguage.id)
            );
            setLanguages(languages);
        } else {
            setLanguages([]);
        }
    }, [availableLanguages, selectedLanguages]);

    useEffect(() => {
        if (selectedScreen === 'category-detail-screen' && !categoryList?.length) {
            fetchCategories();
        }
    }, [fetchCategories, watch, selectedScreen, categoryList?.length]);

    return (
        <>
            {isOpen && (
                <FormProvider {...methods}>
                    <EuiForm component='form'>
                        <EuiFlyout onClose={() => closeFly()} aria-labelledby='flyoutComplicatedTitle'>
                            <EuiFlyoutHeader hasBorder>
                                <EuiTitle size='m'>
                                    <h2 id='flyoutComplicatedTitle'>Notification</h2>
                                </EuiTitle>
                            </EuiFlyoutHeader>
                            <EuiFlyoutBody>
                                <ComboBoxField
                                    options={allLanguages}
                                    selectedOptions={watch('all_languages') ? watch('all_languages') : []}
                                    errors={errors}
                                    label='All language'
                                    name='all_languages'
                                    placeholder='Select Languages'
                                    rules={{
                                        validate: (value) =>
                                            (watch('all_languages') && watch('all_languages').length > 0) ||
                                            'Please select language',
                                    }}
                                    control={control}
                                />
                                {renderHorizontalLine()}
                                {watch('all_languages') &&
                                    watch('all_languages').length > 0 &&
                                    watch('all_languages').map((item) => (
                                        <EuiFlexGroup key={item.id} direction='row' style={{ alignItems: 'flex-end' }}>
                                            <EuiFlexItem>
                                                <TextField
                                                    key={item.id}
                                                    errors={errors}
                                                    isInvalid={
                                                        errors.translations &&
                                                        errors.translations.title &&
                                                        errors.translations.title.hasOwnProperty(item.id)
                                                    }
                                                    error={
                                                        errors.translations &&
                                                        errors.translations.title &&
                                                        errors.translations.title.hasOwnProperty(item.id) &&
                                                        errors.translations.title[item.id]
                                                            ? errors.translations.title[item.id].message
                                                            : ''
                                                    }
                                                    label={`${item.label} : Title`}
                                                    name={`translations.title.${item.id}`}
                                                    placeholder={`Enter the title of the outlet in ${item.label}`}
                                                    rules={{ required: 'Please enter title' }}
                                                    control={control}
                                                />
                                            </EuiFlexItem>
                                            <EuiFlexItem style={{ paddingBottom: '10px' }}>
                                                <EmojiPicker
                                                    onSelectEmoji={(emoji) =>
                                                        addEmoji(`translations.title.${item.id}`, emoji.native)
                                                    }
                                                />
                                            </EuiFlexItem>
                                        </EuiFlexGroup>
                                    ))}
                                {renderHorizontalLine()}

                                {watch('all_languages') &&
                                    watch('all_languages').length > 0 &&
                                    watch('all_languages').map((item) => (
                                        <EuiFlexGroup key={item.id} direction='row' style={{ alignItems: 'flex-end' }}>
                                            <EuiFlexItem>
                                                <TextAreaField
                                                    key={item.id}
                                                    errors={errors}
                                                    isInvalid={
                                                        errors.translations &&
                                                        errors.translations.description &&
                                                        errors.translations.description.hasOwnProperty(item.id)
                                                    }
                                                    error={
                                                        errors.translations &&
                                                        errors.translations.description &&
                                                        errors.translations.description.hasOwnProperty(item.id) &&
                                                        errors.translations.description[item.id]
                                                            ? errors.translations.description[item.id].message
                                                            : ''
                                                    }
                                                    label={`${item.label} : Description`}
                                                    name={`translations.description.${item.id}`}
                                                    placeholder='Write Description'
                                                    rules={{ required: 'Please write Description' }}
                                                    control={control}
                                                    rows={4}
                                                />
                                            </EuiFlexItem>
                                            <EuiFlexItem style={{ paddingBottom: '10px' }}>
                                                <EmojiPicker
                                                    onSelectEmoji={(emoji) =>
                                                        addEmoji(`translations.description.${item.id}`, emoji.native)
                                                    }
                                                />
                                            </EuiFlexItem>
                                        </EuiFlexGroup>
                                    ))}
                                {renderHorizontalLine()}
                                {watch('all_languages') && (
                                    <SelectField
                                        errors={errors}
                                        label='Screen'
                                        name='screen'
                                        options={screenList}
                                        valueOfSelected={watch('screen') ? watch('screen') : null}
                                        onChange={(value) => setValue('screen', value)}
                                        placeholder='Please enter your screen'
                                        control={control}
                                        defaultValue={watch('screen') ? watch('screen') : null}
                                    />
                                )}
                                {watch('screen') === 'category-detail-screen' && (
                                    <SelectField
                                        errors={errors}
                                        label='Select categories '
                                        name='param'
                                        placeholder='Please select categories'
                                        options={categoryList}
                                        valueOfSelected={watch('param') ? watch('param') : null}
                                        onChange={(value) => setValue('param', value)}
                                        control={control}
                                    />
                                )}
                                {renderHorizontalLine()}
                                {languages?.map((item, index) => (
                                    <React.Fragment key={index.toString()}>
                                        <ImageSelectionZone
                                            language={item}
                                            aspectRatio={{
                                                width: 16,
                                                height: 9,
                                                imageType: 'notifications',
                                            }}
                                            buttonLabel={'Add an image'}
                                            selectedImgButtonLabel='Change notification image'
                                        />
                                    </React.Fragment>
                                ))}
                                {renderHorizontalLine()}
                            </EuiFlyoutBody>
                            <EuiFlyoutFooter>
                                <EuiFlexGroup justifyContent='spaceBetween'>
                                    <EuiFlexItem grow={false}>
                                        <EuiFlexGroup>
                                            <EuiFlexItem grow={false}>
                                                <EuiButton
                                                    isLoading={state.sendLoading}
                                                    fill
                                                    onClick={handleSubmit(onSubmit)}
                                                    type='submit'
                                                >
                                                    {resetID && watch('time_to_send')
                                                        ? 'Reschedule'
                                                        : resetID
                                                        ? 'Resend'
                                                        : editID
                                                        ? 'Edit'
                                                        : watch('time_to_send')
                                                        ? 'Schedule'
                                                        : 'Send'}
                                                </EuiButton>
                                            </EuiFlexItem>
                                            <EuiFlexItem grow={false}>
                                                <div
                                                    className={watch('time_to_send') ? 'calendar-icon' : ''}
                                                    style={{ width: watch('time_to_send') ? 230 : 52 }}
                                                >
                                                    <Controller
                                                        render={({ field }) => (
                                                            <EuiDatePicker
                                                                name='time_to_send'
                                                                showTimeSelect
                                                                selected={
                                                                    watch('time_to_send') ? watch('time_to_send') : null
                                                                }
                                                                onClear={() => setValue('time_to_send', null)}
                                                                onChange={(date) => setValue('time_to_send', date)}
                                                            />
                                                        )}
                                                        name='time_to_send'
                                                        control={control}
                                                        defaultValue={
                                                            watch('time_to_send') ? watch('time_to_send') : null
                                                        }
                                                        placeholder='select starting date ane time '
                                                        isInvalid={errors.time_to_send}
                                                        fullWidth={true}
                                                    />
                                                </div>
                                            </EuiFlexItem>
                                            {editID && (
                                                <EuiFlexItem>
                                                    <EuiButton
                                                        onClick={() => setItentifyState(true)}
                                                        isLoading={state.sendLoading}
                                                        fill
                                                        type='submit'
                                                    >
                                                        Send Now
                                                    </EuiButton>
                                                </EuiFlexItem>
                                            )}
                                        </EuiFlexGroup>
                                    </EuiFlexItem>
                                    <EuiFlexItem grow={false} onClick={() => closeFly()}>
                                        <EuiButtonEmpty iconType='cross' flush='left'>
                                            Close
                                        </EuiButtonEmpty>
                                    </EuiFlexItem>
                                </EuiFlexGroup>
                            </EuiFlyoutFooter>
                        </EuiFlyout>
                    </EuiForm>
                </FormProvider>
            )}
        </>
    );
};
