import React, { useState, useCallback, useEffect } from 'react';
import TableBookingPendingList from './tableBookingPendingList';
import { EuiFlexItem, EuiLoadingContent } from '@elastic/eui';
import { getTableBookingList } from '../../api/tableBooking/getTableBookingList';
import { useDispatch, useSelector } from 'react-redux';
import * as types from '../../reduxStore/types/tableBooking';

const TableBookingPendingComponent = (props) => {
    const { outletId } = props;

    const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 });
    const [activePage, setActivePage] = useState(0);
    const [rowSize, setRowSize] = useState(10);

    const dispatch = useDispatch();

    const tableBookingPendingList = useSelector((state) => state.tableBooking.tableBookingPendingList);
    const pendingListUpdate = useSelector((state) => state.tableBooking.pendingListUpdate);

    const fetchTableBookingList = () => {
        dispatch(getTableBookingList({ outletId, status: 'pending', activePage: activePage + 1, rowSize })).then(
            (response) => {
                if (response.success) {
                    dispatch({
                        type: types.SET_TABLE_BOOKING_PENDING_LIST,
                        payload: {
                            tableBookingPendingList: {
                                ...tableBookingPendingList,
                                loading: false,
                                bookingList: response,
                            },
                        },
                    });
                } else {
                    dispatch({
                        type: types.SET_TABLE_BOOKING_PENDING_LIST,
                        payload: {
                            tableBookingPendingList: { ...tableBookingPendingList, loading: false, error: true },
                        },
                    });
                    alert('Error in fetching list');
                }
            }
        );
    };

    useEffect(() => {
        outletId && fetchTableBookingList();
    }, [outletId, activePage, rowSize, pendingListUpdate]);

    const paginationCallback = useCallback((value) => {
        setPagination(value);
    }, []);

    const rowSizeCallback = useCallback((value) => {
        setRowSize(value);
    }, []);

    const activePageCallback = useCallback((value) => {
        setActivePage(value);
    }, []);

    if (tableBookingPendingList.loading) {
        return <EuiLoadingContent lines={10} />;
    }

    if (tableBookingPendingList.error) {
        return 'Error';
    }

    return (
        <EuiFlexItem style={{ marginTop: '15px' }}>
            <TableBookingPendingList
                tableBookingList={tableBookingPendingList}
                pagination={pagination}
                paginationCallback={paginationCallback}
                rowSize={rowSize}
                rowSizeCallback={rowSizeCallback}
                activePage={activePage}
                activePageCallback={activePageCallback}
                outletId={outletId}
                fetchTableBookingList={fetchTableBookingList}
            />
        </EuiFlexItem>
    );
};

export default TableBookingPendingComponent;
