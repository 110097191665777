import API from './axios/API';
import * as types from '../reduxStore/types/billing';

export const getBillingItems = (outletId) => {
    return async (dispatch, getState) => {
        let itemList;
        let { billing, language } = getState();

        let mode = billing.mode == 'qsr' ? 'takeaway' : billing.mode;

        try {
            itemList = await API.get(
                `${language.language}/restaurants/:restaurantId/outlets/${outletId}/categories/${billing.activeCategoryId}/items?ordering_mode=${mode}`
            );
        } catch (error) {
            itemList = error;
        }

        if (itemList.success) {
            dispatch({
                type: types.SET_ITEM_LIST,
                payload: {
                    itemList: itemList,
                },
            });
        } else {
            alert('Error in category fetch');
        }
    };
};
