import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

const Expire = (props) => {
    const [visible, setVisible] = useState(true);
    const dispatch = useDispatch();

    useEffect(() => {
        setTimeout(() => {
            setVisible(false);

            dispatch({
                type: 'set-portal-active-tab-switched',
                payload: {
                    tabSwitched: false,
                },
            });
        }, props.delay);
    }, [props.delay]);

    return visible ? <div>{props.children}</div> : <div />;
};

export default Expire;
