import { EuiFlexGroup, EuiFlexItem, EuiText } from '@elastic/eui';
import React from 'react';
import CategoryList from './categoryList';
import ItemList from './billingItemList';
import LoadingOverlay from 'react-loading-overlay';
import { useSelector } from 'react-redux';

const CategorySection = (props) => {
    const { outletId } = props;
    const addonModalVisible = useSelector((state) => state.billing.addonModalVisible);

    return (
        <>
            <LoadingOverlay active={addonModalVisible}>
                <div style={{ width: '100%' }}>
                    <div>
                        {/* category list */}
                        <CategoryList outletId={outletId} />

                        {/* Item list */}
                        <ItemList outletId={outletId} />
                    </div>
                </div>
            </LoadingOverlay>
        </>
    );
};

export default CategorySection;
