import React from 'react';
import {
    EuiOverlayMask,
    EuiModal,
    EuiModalHeader,
    EuiModalHeaderTitle,
    EuiModalBody,
    EuiModalFooter,
    EuiButtonEmpty,
    EuiButton,
    EuiHealth,
    EuiHighlight,
    EuiComboBox,
    EuiFlexItem,
} from '@elastic/eui';

const EarlyTableOccupyWarningModal = (props) => {
    const { setEarlyOccupyWarningModal, occupyTableApiCall, earlyOccupyWarningModal } = props;

    const closeModal = () => {
        setEarlyOccupyWarningModal({
            event: undefined,
            modalIsOpen: false,
        });
    };

    const assignTable = () => {
        occupyTableApiCall(earlyOccupyWarningModal.event);
        closeModal();
    };

    return (
        <EuiOverlayMask>
            <EuiModal onClose={closeModal}>
                <EuiModalHeader>
                    <EuiModalHeaderTitle>
                        <h1>Early table assign</h1>
                    </EuiModalHeaderTitle>
                </EuiModalHeader>

                <EuiModalBody>
                    <EuiFlexItem>You are assigning this table too early from it's time</EuiFlexItem>
                </EuiModalBody>

                <EuiModalFooter>
                    <EuiButtonEmpty onClick={closeModal}>Cancel</EuiButtonEmpty>

                    <EuiButton onClick={assignTable} fill>
                        Continue anyway
                    </EuiButton>
                </EuiModalFooter>
            </EuiModal>
        </EuiOverlayMask>
    );
};

export default EarlyTableOccupyWarningModal;
