import { EuiLoadingContent } from '@elastic/eui';
import React, { useState, useEffect, memo } from 'react';
import API from '../../api/axios/API';
import Pagination from '../Form/Pagination';
import { NotificationTable } from './notificationTable';
import { EuiFlexItem } from '@elastic/eui';

const NotificationDetails = ({ setRefetch, refetch, openFly }) => {
    // state for handle pagination
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 10,
    });
    // state for api call
    const [appState, setAppState] = useState({
        loading: true,
        errorState: false,
        allNotifications: [],
    });

    // hook for call api
    useEffect(() => {
        // get all notification with pagination
        const getAllNotification = async () => {
            try {
                const notifications = await API.get(
                    `/restaurants/:restaurantId/notifications?page=${pagination.pageIndex + 1}`
                );
                setAppState((preState) => ({
                    ...preState,
                    loading: false,
                    allNotifications: notifications,
                }));
            } catch (e) {
                setAppState((preState) => ({
                    ...preState,
                    loading: false,
                    errorState: true,
                }));
            }
        };
        getAllNotification();
    }, [refetch, pagination.pageIndex]);

    if (appState.loading) return <EuiLoadingContent lines={10} />;
    if (appState.errorState) return 'Error...';

    // method fro handle pagination
    const handlePageChange = (page) => {
        setPagination({
            ...pagination,
            pageIndex: page,
        });
    };

    return (
        <EuiFlexItem>
            {/* list of notification */}
            <NotificationTable
                setRefetch={setRefetch}
                getAllNotificationsDetails={appState.allNotifications}
                openFly={openFly}
            />

            {/* handle pagination for notification */}
            {appState.allNotifications.notifications.total > appState.allNotifications.notifications.per_page && (
                <Pagination
                    totalRecord={appState.allNotifications.notifications.total}
                    pageSize={appState.allNotifications.notifications.per_page}
                    activePage={appState.allNotifications.notifications.current_page - 1}
                    handlePageChange={handlePageChange}
                />
            )}
        </EuiFlexItem>
    );
};

export default memo(NotificationDetails);
