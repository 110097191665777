import API from '../axios/API';

export const getTableList = (outletId, bookingRequestId) => {
    return async (dispatch, getState) => {
        let response;

        try {
            response = await API.get(
                `/restaurants/:restaurantId/outlets/${outletId}/table-booking-request/${bookingRequestId}/idle-tables`
            );
        } catch (error) {
            response = error;
        }

        return response;
    };
};
