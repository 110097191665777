import React, { useState } from 'react';
import {
    EuiFlexGroup,
    EuiFlexItem,
    EuiDragDropContext,
    EuiButton,
    EuiDroppable,
    EuiPanel,
    euiDragDropMove,
    euiDragDropReorder,
    EuiDraggable,
    htmlIdGenerator,
    EuiFormRow,
    EuiFieldText,
    EuiButtonIcon,
    EuiIcon,
    EuiCheckbox,
} from '@elastic/eui';
import { useDispatch, useSelector } from 'react-redux';
import * as types from '../../reduxStore/types/billing';
const OrderTotalDraggable = (props) => {
    const { content, idx, id } = props;
    const dispatch = useDispatch();

    const selectedDraggableItems = useSelector((state) => state.billing.selectedDraggableItems);

    const onChange = (e) => {
        let listLength = selectedDraggableItems.filter((item) => item.type != 'DROPPABLE_AREA_TYPE_1');

        let innerArray = !listLength.length ? [...selectedDraggableItems] : [];

        if (e.target.checked) {
            innerArray.push({ type: 'DROPPABLE_AREA_TYPE_1', id: id });
        } else {
            let indexValue;
            innerArray.map((item, index) => {
                if (item.id == id) {
                    indexValue = index;
                }
            });
            innerArray.splice(indexValue, 1);
        }

        dispatch({
            type: types.SET_SELECTED_DRAGGABLE_ITEMS,
            payload: {
                selectedDraggableItems: innerArray,
            },
        });
    };
    const matchedCheck = () => {
        let check = false;
        selectedDraggableItems.map((item) => {
            if (item.id == id) {
                check = true;
            }
        });
        return check;
    };

    return (
        <EuiDraggable customDragHandle={true} key={id} index={idx} draggableId={id} spacing='m'>
            {(provided, state) => (
                <EuiPanel style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <div {...provided.dragHandleProps} style={{ marginRight: '10px' }}>
                        <EuiIcon type='grab' />
                    </div>
                    <div>
                        {content}
                        {state.isDragging && ' ✨'}
                    </div>
                    <div>
                        <EuiCheckbox checked={matchedCheck()} onChange={(e) => onChange(e)} />
                    </div>
                </EuiPanel>
            )}
        </EuiDraggable>
    );
};

export default OrderTotalDraggable;
