import React, { useEffect } from 'react';
import Catalogue from '../../../modules/Menu/Tabs/Catalogue';
import { EuiFlexGroup, EuiFlexItem } from '@elastic/eui';
import { useSelector } from 'react-redux';
import { NoOutletMessage } from '../../../utils/noOutletMessage';
import { useDispatch } from 'react-redux';
import { SET_OUTLET_MENU } from '../Store/MenuTypes';

export const OutletMenuWrapper = (props) => {
    const selectedOutletId = useSelector((state) => state.outlet.selectedOutletId);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch({
            type: SET_OUTLET_MENU,
            payload: true,
        });

        return function cleanup() {
            dispatch({
                type: SET_OUTLET_MENU,
                payload: false,
            });
        };
    }, [dispatch]);

    return (
        <EuiFlexGroup direction='column' style={{ overflow: 'hidden' }}>
            {!selectedOutletId ? (
                <NoOutletMessage />
            ) : (
                <EuiFlexItem grow={10}>
                    <Catalogue />
                </EuiFlexItem>
            )}
        </EuiFlexGroup>
    );
};
