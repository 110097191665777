import React, { useCallback } from 'react';
import { EuiFlyout, EuiFlyoutHeader, EuiTitle, EuiFlyoutBody, EuiFlyoutFooter, EuiButton } from '@elastic/eui';
import ShiftItemList from './shiftItemList';
import { useDispatch, useSelector } from 'react-redux';
import { copyItemList } from '../../../api/menu/copyItemList';
import { moveItemList } from '../../../api/menu/moveItemList';
import * as type from '../../../reduxStore/types/menu';
import { fetchCategoryItem } from './Api/FetchCatalogueInfo';

const ShiftItemsFlyout = ({ flyoutState, setFlyoutState, category, isOutletMenu, setItemListState }) => {
    const categories = useSelector((state) => state.menuReducer.restaurantCategories);
    const dispatch = useDispatch();
    const itemIds = useSelector((state) => state.menu.shiftItemList);

    const closeFlyout = useCallback(() => {
        setFlyoutState({ isOpen: false, action: '' });
        dispatch({
            type: type.SET_SHIFT_ITEM_LIST,
            payload: {
                shiftItemList: [],
            },
        });
    }, [dispatch, setFlyoutState]);

    const languageId = useSelector((state) => state.language.languageId);

    const addItems = useCallback(() => {
        let id = category.category_id;
        closeFlyout();
        let copyItemIds = [];

        for (const element of itemIds) {
            copyItemIds.push(element.item_id);
        }

        const items = {
            item_ids: copyItemIds,
            category_id: id,
        };

        const moveItems = {
            item_details: itemIds,
            destination_category_id: id,
        };

        if (flyoutState.action == 'copy') {
            dispatch(copyItemList(items)).then((res) => {
                if (res.success) {
                    dispatch({
                        type: type.SET_CATEGORY_STATE,
                        payload: {
                            categoryState: {},
                        },
                    });
                    fetchCategoryItem(id, languageId).then((response) => {
                        if (response.success) {
                            setItemListState(response.items);
                        }
                    });
                } else {
                    alert('Error in copying item');
                }
            });
        } else {
            dispatch(moveItemList(moveItems)).then((res) => {
                if (res.success) {
                    dispatch({
                        type: type.SET_CATEGORY_STATE,
                        payload: {
                            categoryState: {},
                        },
                    });
                    fetchCategoryItem(id, languageId).then((response) => {
                        if (response.success) {
                            setItemListState(response.items);
                        }
                    });
                } else {
                    alert('Error in moving item');
                }
            });
        }
    }, [category.category_id, closeFlyout, dispatch, flyoutState.action, languageId, itemIds, setItemListState]);

    return (
        <div>
            {flyoutState.isOpen ? (
                <EuiFlyout onClose={closeFlyout} aria-labelledby='flyoutTitle'>
                    <EuiFlyoutHeader hasBorder>
                        <EuiTitle size='m'>
                            <h2>Select item to {flyoutState.action} to category</h2>
                        </EuiTitle>
                    </EuiFlyoutHeader>

                    <EuiFlyoutBody>
                        {categories.map((categoryData) =>
                            category.category_id !== categoryData.category_id ? (
                                <ShiftItemList
                                    isOutletMenu={isOutletMenu}
                                    category={categoryData}
                                    categoryValue={category}
                                />
                            ) : null
                        )}
                    </EuiFlyoutBody>
                    <EuiFlyoutFooter>
                        <EuiButton onClick={addItems} fill fullWidth isDisabled={!itemIds.length}>
                            Add
                        </EuiButton>
                    </EuiFlyoutFooter>
                </EuiFlyout>
            ) : null}
        </div>
    );
};

export default React.memo(ShiftItemsFlyout);
