import API from '../axios/API';

export const occupyTable = ({ outletId, tableId, customerId, cover_count }) => {
    return async () => {
        let response;
        try {
            response = await API.post(`restaurants/:restaurantId/outlets/${outletId}/tables/${tableId}/occupy`, {
                cover_count: cover_count,
                customer_id: customerId,
            });
        } catch (error) {
            response = error;
        }

        return response;
    };
};
