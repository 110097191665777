import {
    EuiButton,
    EuiFlyout,
    EuiFlyoutBody,
    EuiFlyoutFooter,
    EuiFlyoutHeader,
    EuiTitle,
    htmlIdGenerator,
} from '@elastic/eui';
import React, { useCallback, useState } from 'react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import APIV3 from '../../../api/axios/APIV3';
import { SET_TOAST_STATE } from '../../../reduxStore/types/Global/Global';
import AddonLinkCategoryItemList from './AddonLinkCategoryItemList';

const AddonLinkFlyout = ({ addonLinkFlyout, toggleFlyout, fetchAddonRecords }) => {
    const dispatch = useDispatch();
    const categories = useSelector((state) => state.menuReducer.restaurantCategories);
    const [addonLinkStatus, setAddonLinkStatus] = useState({
        link: [],
        unlink: [],
    });
    const [addonVariantLinkStatus, setAddonVariantLinkStatus] = useState({
        link: [],
        unlink: [],
    });

    // console.log('addonVariantLinkStatus', addonVariantLinkStatus);
    // console.log('addonLinkStatus', addonLinkStatus);

    useEffect(() => {
        return function cleanup() {
            setAddonLinkStatus({ link: [], unlink: [] });
            setAddonVariantLinkStatus({ link: [], unlink: [] });
        };
    }, []);

    const handleAddonLinkToggle = useCallback(
        async (status, payload, addonId, variantPayload) => {
            try {
                await APIV3.patch(`restaurants/:restaurantId/addon-groups/${addonId}/${status}-items`, {
                    item_ids: payload,
                    item_variant_option_ids: variantPayload,
                }).then((response) => {
                    if (response.success) {
                        fetchAddonRecords();
                        setAddonLinkStatus({ link: [], unlink: [] });
                        setAddonVariantLinkStatus({ link: [], unlink: [] });
                        dispatch({
                            type: SET_TOAST_STATE,
                            payload: {
                                title: 'Addon Linked Successfully',
                                color: 'success',
                            },
                        });
                    }
                });
            } catch (e) {
                dispatch({
                    type: SET_TOAST_STATE,
                    payload: {
                        title: 'Oops, there was an error',
                        color: 'danger',
                        iconType: 'help',
                        id: htmlIdGenerator()(),
                    },
                });
            }
        },
        [fetchAddonRecords, dispatch]
    );

    const handleAddonLinkCatalogue = useCallback(async () => {
        if (addonLinkStatus.link?.length || addonVariantLinkStatus.link.length) {
            handleAddonLinkToggle(
                'link',
                addonLinkStatus.link,
                addonLinkFlyout.addon.addon_group_id,
                addonVariantLinkStatus.link
            );
        } else if (addonLinkStatus.unlink?.length || addonVariantLinkStatus.unlink.length) {
            handleAddonLinkToggle(
                'unlink',
                addonLinkStatus.unlink,
                addonLinkFlyout.addon.addon_group_id,
                addonVariantLinkStatus.unlink
            );
        }
    }, [
        addonLinkStatus.link,
        addonLinkStatus.unlink,
        addonVariantLinkStatus.link,
        addonVariantLinkStatus.unlink,
        handleAddonLinkToggle,
        addonLinkFlyout.addon.addon_group_id,
    ]);

    const handleVariantLinkChange = useCallback(
        (isChecked, selectedOption) => {
            if (isChecked) {
                const doesLinkExist = addonLinkFlyout.addon.linked_variants?.filter(
                    (optionId) => optionId === selectedOption.id
                );
                if (!doesLinkExist?.length) {
                    setAddonVariantLinkStatus((prevState) => ({
                        ...prevState,
                        link: [...prevState?.link, selectedOption.id],
                    }));
                }
            } else {
                const checkedVariantLink = addonVariantLinkStatus.link.filter(
                    (optionId) => optionId !== selectedOption.id
                );
                setAddonVariantLinkStatus((prevState) => ({
                    ...prevState,
                    link: [...checkedVariantLink],
                }));
            }

            // Prepare the array for deletion
            if (isChecked) {
                const matchedVariants = addonVariantLinkStatus.unlink?.filter((unLinkId) => {
                    return unLinkId !== selectedOption.id;
                });
                setAddonVariantLinkStatus((prevState) => ({
                    ...prevState,
                    unlink: [...matchedVariants],
                }));
            } else {
                const matchedVariants = addonLinkFlyout.addon.linked_variants?.filter((optionId) => {
                    return optionId === selectedOption.id;
                })?.[0];
                if (matchedVariants) {
                    setAddonVariantLinkStatus((prevState) => ({
                        ...prevState,
                        unlink: [...prevState.unlink, matchedVariants],
                    }));
                } else {
                    setAddonVariantLinkStatus((prevState) => ({
                        ...prevState,
                        unlink: [...prevState.unlink],
                    }));
                }
            }
        },
        [addonLinkFlyout.addon.linked_variants, addonVariantLinkStatus.link, addonVariantLinkStatus.unlink]
    );

    const handleLinkChange = useCallback(
        (isChecked, selectedItem) => {
            if (isChecked) {
                const doesLinkExist = addonLinkFlyout.addon.linked_item?.filter(
                    (itemId) => itemId === selectedItem.item_id
                );

                if (!doesLinkExist?.length) {
                    setAddonLinkStatus((prevState) => ({
                        ...prevState,
                        link: [...prevState?.link, selectedItem.item_id],
                    }));
                }
            } else {
                const checkedAddonLink = addonLinkStatus.link.filter((outletId) => outletId !== selectedItem.item_id);
                setAddonLinkStatus((prevState) => ({
                    ...prevState,
                    link: [...checkedAddonLink],
                }));
            }

            // Prepare the array for deletion
            if (isChecked) {
                const matchedAddons = addonLinkStatus.unlink?.filter((itemId) => {
                    return itemId !== selectedItem.item_id;
                });
                setAddonLinkStatus((prevState) => ({
                    ...prevState,
                    unlink: [...matchedAddons],
                }));
            } else {
                const matchedAddons = addonLinkFlyout.addon.linked_item?.filter((itemId) => {
                    return itemId === selectedItem.item_id;
                })?.[0];
                if (matchedAddons) {
                    setAddonLinkStatus((prevState) => ({
                        ...prevState,
                        unlink: [...prevState.unlink, matchedAddons],
                    }));
                } else {
                    setAddonLinkStatus((prevState) => ({
                        ...prevState,
                        unlink: [...prevState.unlink],
                    }));
                }
            }
        },
        [addonLinkFlyout?.addon?.linked_item, addonLinkStatus.link, addonLinkStatus.unlink]
    );

    return (
        <>
            {addonLinkFlyout.display ? (
                <EuiFlyout onClose={toggleFlyout}>
                    <EuiFlyoutHeader hasBorder>
                        <EuiTitle>
                            <h2 id={htmlIdGenerator()()}>Link Addon to an item</h2>
                        </EuiTitle>
                    </EuiFlyoutHeader>
                    <EuiFlyoutBody>
                        {categories.map((category, index) => (
                            <AddonLinkCategoryItemList
                                category={category}
                                handleLinkChange={handleLinkChange}
                                handleVariantLinkChange={handleVariantLinkChange}
                                key={category?.category_id?.toString() + index.toString()}
                                selectedAddon={addonLinkFlyout.addon}
                                addonVariantLinkStatus={addonVariantLinkStatus}
                            />
                        ))}
                    </EuiFlyoutBody>
                    <EuiFlyoutFooter>
                        <EuiButton
                            fill
                            fullWidth
                            onClick={handleAddonLinkCatalogue}
                            disabled={
                                !addonLinkStatus.link.length &&
                                !addonLinkStatus.unlink.length &&
                                !addonVariantLinkStatus.link.length &&
                                !addonVariantLinkStatus.unlink.length
                            }
                        >
                            Save Changes
                        </EuiButton>
                    </EuiFlyoutFooter>
                </EuiFlyout>
            ) : null}
        </>
    );
};

export default React.memo(AddonLinkFlyout);
