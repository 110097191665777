import { EuiButtonEmpty, EuiFlexGroup, EuiFlexItem, EuiHorizontalRule, EuiSpacer, EuiText } from '@elastic/eui';
import { ignoreNextOnError } from '@sentry/browser/dist/helpers';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import API from '../../api/axios/API';
import * as types from '../../reduxStore/types/billing';

const CouponOffer = (props) => {
    const { offer, outletId, setCouponCodeData } = props;

    const dispatch = useDispatch();
    const cartId = useSelector((state) => state.billing.cartId);

    const billingItem = useSelector((state) => state.billing.billingItem);
    const [showMore, setShowMore] = useState(false);
    const [couponApplied, setCouponApplied] = useState(false);

    const addonGroupCreate = (addons) => {
        let customizationObj = {};

        addons.map((addon) => {
            if (customizationObj[addon.addon_group_id]) {
                let innerArray = [...customizationObj[addon.addon_group_id]];

                innerArray.push(addon);

                customizationObj[addon.addon_group_id] = innerArray;
            } else {
                customizationObj[addon.addon_group_id] = [{ ...addon }];
            }
        });
        console.log('customizaitons group object ', customizationObj);

        return customizationObj;
    };

    const updateBillingItem = (offerResponse) => {
        let billingItemObj1 = {};

        let quantityObj = {};

        offerResponse.cart.cart_items.map((item) => {
            if (item.customizations && item.customizations.length > 0) {
                console.log('billing item object in update inside if');
                let innerObj = addonGroupCreate(item.customizations);

                console.log('customizations ', innerObj);

                if (billingItemObj1[item.item_id]) {
                    let innerCustomizationArray = billingItemObj1[item.item_id].customizations;

                    innerCustomizationArray.push({ ...innerObj, addon_information: { quantity: item.quantity } });

                    billingItemObj1[item.item_id].customizations = innerCustomizationArray;

                    billingItemObj1[item.item_id].quantity = 0;
                } else {
                    item.customizations = [{ ...innerObj, addon_information: { quantity: item.quantity } }];
                    billingItemObj1[item.item_id] = item;
                    billingItemObj1[item.item_id].quantity = 0;
                }
            } else {
                console.log('billing item object in update inside else');
                billingItemObj1[item.item_id] = item;
                quantityObj[item.item_id] = item.quantity;
            }
        });

        Object.entries(quantityObj).forEach(([key, value]) => {
            if (billingItemObj1[key]) {
                console.log('billing item object in update quantity value', value);
                billingItemObj1[key].quantity = value;
            }
        });

        console.log('billing item object in update ', billingItemObj1);
        dispatch({
            type: types.SET_BILLING_ITEM,
            payload: {
                billingItem: billingItemObj1,
            },
        });
    };

    const applyOffer = async () => {
        let response;

        try {
            response = await API.put(
                `en/pos/restaurants/:restaurantId/outlets/${outletId}/carts/${cartId}/apply-offer`,
                {
                    coupon_code: offer.coupon_code,
                }
            );
        } catch (error) {
            response = error;
        }

        if (response.success) {
            updateBillingItem(response);
            dispatch({
                type: types.SET_BILLING_ITEM_CART_STRUCTURE,
                payload: {
                    billingItemCartStructure: response,
                },
            });
            setCouponCodeData(response);
            setCouponApplied(true);
            alert('Coupon applied successfully!!');
        } else {
            if (response.message) {
                alert(response.message);
            } else {
                alert('Error in coupon apply');
            }
        }
    };

    return (
        <EuiFlexItem style={{ flexDirection: 'column' }}>
            <EuiFlexItem style={{ flexDirection: 'row', justifyContent: 'space-between', paddingLeft: '10px' }}>
                <h2>
                    <b>{offer.coupon_code}</b>
                </h2>
                <EuiButtonEmpty disabled={couponApplied} onClick={applyOffer}>
                    {couponApplied ? 'APPLIED' : 'APPLY'}
                </EuiButtonEmpty>
            </EuiFlexItem>
            <EuiFlexItem>
                <EuiFlexItem style={{ paddingLeft: '10px' }}>
                    <p>{offer.title}</p>
                    <EuiSpacer size='m' />
                    {showMore && <p>{offer.description}</p>}
                </EuiFlexItem>

                <EuiButtonEmpty
                    style={{ width: '100px', backgroundColor: 'transparent' }}
                    onClick={() => setShowMore(!showMore)}
                >
                    {showMore ? 'Show less' : 'Show more'}
                </EuiButtonEmpty>
            </EuiFlexItem>
            <EuiHorizontalRule />
        </EuiFlexItem>
    );
};

export default CouponOffer;
