import React, { useCallback } from 'react';
import { EuiFormRow, EuiSpacer, EuiFlexItem, EuiSelect, EuiFieldNumber, EuiFlexGroup } from '@elastic/eui';
import { Controller, useWatch } from 'react-hook-form';
import FieldArrayNumberField from '../../Form/FieldArrayNumberField';

const gstOptions = [
    { value: '0', text: '0%' },
    { value: '5', text: '5%' },
    { value: '12', text: '12%' },
    { value: '18', text: '18%' },
    { value: '28', text: '28%' },
];

const ChoicePricing = ({ errors, index, control, field, watch, setValue }) => {
    const onChangeGST = useCallback(
        (value, index) => {
            setValue(`choices[${index}].gst_slab`, value);
        },
        [setValue]
    );

    const choiceOrderingMode = useWatch({
        control,
        name: `choices[${index}].ordering_modes`,
    });
    const doesHaveDifferentPrices = useWatch({
        control,
        name: `choices[${index}].is_enabled_same_price_details_for_all_ordering_modes`,
    });

    return (
        <>
            {doesHaveDifferentPrices ? (
                <>
                    {choiceOrderingMode?.length
                        ? choiceOrderingMode.map((orderingMode, idx) => {
                              const priceInputName = `choices[${index}].price_${orderingMode.restaurant_ordering_mode_id}`;
                              const comparePriceInputName = `choices[${index}].compare_price_${orderingMode.restaurant_ordering_mode_id}`;
                              const gstInputName = `choices[${index}].gst_slab_${orderingMode.restaurant_ordering_mode_id}`;
                              return (
                                  <EuiFlexItem key={idx} component='div'>
                                      <EuiSpacer />
                                      <EuiFlexGroup
                                          direction='column'
                                          wrap
                                          responsive
                                          gutterSize='s'
                                          style={{
                                              marginLeft: '10px',
                                          }}
                                      >
                                          <EuiFlexItem>
                                              <FieldArrayNumberField
                                                  error={errors[priceInputName] ? errors[priceInputName].message : ''}
                                                  isInvalid={errors[priceInputName]}
                                                  label={`Price ${orderingMode.restaurant_ordering_mode_name}`}
                                                  name={priceInputName}
                                                  placeholder='price'
                                                  rules={{
                                                      required: 'Please enter price',
                                                  }}
                                                  control={control}
                                                  watch={watch}
                                                  defaultValue={orderingMode[priceInputName]}
                                              />
                                          </EuiFlexItem>

                                          <EuiFlexItem>
                                              <FieldArrayNumberField
                                                  error={
                                                      errors?.[comparePriceInputName]
                                                          ? errors[comparePriceInputName].message
                                                          : ''
                                                  }
                                                  isInvalid={
                                                      errors?.[comparePriceInputName]
                                                          ? errors[comparePriceInputName]
                                                          : ''
                                                  }
                                                  label={`Compare Price ${orderingMode.restaurant_ordering_mode_name}`}
                                                  placeholder='Compare price'
                                                  name={comparePriceInputName}
                                                  rules={{
                                                      required: 'Please enter compare price',
                                                  }}
                                                  control={control}
                                                  watch={watch}
                                                  defaultValue={orderingMode[comparePriceInputName]}
                                              />
                                          </EuiFlexItem>

                                          <EuiFlexItem>
                                              <EuiFormRow
                                                  label={`GST ${orderingMode.restaurant_ordering_mode_name}`}
                                                  style={{ maxWidth: '150px' }}
                                              >
                                                  <Controller
                                                      render={() => (
                                                          <EuiSelect
                                                              options={gstOptions}
                                                              value={watch(gstInputName)}
                                                              onChange={(e) => {
                                                                  //   onChangeGST(e.target.value, gstInputName);
                                                                  setValue(gstInputName, e.target.value);
                                                              }}
                                                          />
                                                      )}
                                                      defaultValue={Number(orderingMode[gstInputName])}
                                                      name={gstInputName}
                                                      control={control}
                                                  />
                                              </EuiFormRow>
                                          </EuiFlexItem>
                                      </EuiFlexGroup>
                                      <EuiSpacer />
                                  </EuiFlexItem>
                              );
                          })
                        : null}
                </>
            ) : (
                <>
                    {/* Same Price Selected */}
                    <EuiFlexItem>
                        <EuiFormRow
                            label={'Price'}
                            error={errors[`choices[${index}].price`] ? errors[`choices[${index}].price`].message : ''}
                            isInvalid={errors[`choices[${index}].price`] ? true : false}
                        >
                            <Controller
                                render={({ field }) => (
                                    <EuiFieldNumber
                                        placeholder='Addon choice price'
                                        value={field.value}
                                        onChange={field.onChange}
                                    />
                                )}
                                name={`choices[${index}].price`}
                                min={0}
                                step={'any'}
                                control={control}
                                // inputRef={priceRef}
                                // onWheel={onWheel}
                                fullWidth={true}
                                isInvalid={errors[`choices[${index}].price`] ? true : false}
                                defaultValue={field[`price`]}
                            />
                        </EuiFormRow>
                        <EuiSpacer />
                    </EuiFlexItem>
                    <EuiFlexItem>
                        <EuiFormRow
                            label={'Compare Price'}
                            error={
                                errors[`choices[${index}].compare_price`]
                                    ? errors[`choices[${index}].compare_price`].message
                                    : ''
                            }
                            isInvalid={errors[`choices[${index}].compare_price`] ? true : false}
                        >
                            <Controller
                                render={({ field }) => (
                                    <EuiFieldNumber
                                        placeholder='Addon choice compare price'
                                        value={field.value}
                                        onChange={field.onChange}
                                    />
                                )}
                                name={`choices[${index}].compare_price`}
                                min={0}
                                step={'any'}
                                // inputRef={comparePriceRef}
                                // onWheel={onWheel}
                                control={control}
                                fullWidth={true}
                                // rules={{
                                //     required: 'Please enter addon compare price',
                                // }}
                                isInvalid={errors[`choices[${index}].compare_price`] ? true : false}
                                defaultValue={field[`compare_price`]}
                            />
                        </EuiFormRow>
                        <EuiSpacer />
                    </EuiFlexItem>
                    <EuiSpacer size='s' style={{ color: '#e9f1f8' }} />
                    <EuiFlexItem>
                        <EuiFormRow label='GST'>
                            <Controller
                                render={() => (
                                    <EuiSelect
                                        fullWidth={true}
                                        options={gstOptions}
                                        value={watch(`choices[${index}].gst_slab`)}
                                        onChange={(e) => onChangeGST(e.target.value, index)}
                                    />
                                )}
                                name={`choices[${index}].gst_slab`}
                                defaultValue={watch(`choices[${index}].gst_slab`, field[`gst_slab`])}
                                control={control}
                            />
                        </EuiFormRow>
                        <EuiSpacer />
                    </EuiFlexItem>
                </>
            )}
        </>
    );
};

export default React.memo(ChoicePricing);
