import APIV3 from '../../../api/axios/APIV3';
import { SET_TABLE_ORDER_DETAILS } from '../../../reduxStore/types/tableManagement';

export const updateTable = async (tableId, udpatedTable, outletId) => {
    let response;

    try {
        response = await APIV3.put(
            `restaurants/:restaurantId/outlets/${outletId}/outlet-tables/${tableId}`,
            udpatedTable
        );
    } catch (error) {
        response = error;
    }
    return response;
};

export const deleteTable = async (tableId) => {
    let response;

    try {
        response = await APIV3.delete(`restaurants/:restaurantId/outlet-tables/${tableId}`);
    } catch (error) {
        response = error;
    }

    return response;
};

export const createTable = async (outletId, data) => {
    let resposne;
    try {
        resposne = await APIV3.post(`restaurants/:restaurantId/outlets/${outletId}/outlet-tables`, data);
    } catch (error) {
        resposne = error;
    }

    return resposne;
};

export const fetchTableOrderDetails = (tableId) => {
    return async (dispatch, getState) => {
        let response;
        const { selectedOutletId } = getState().outlet;

        try {
            response = await APIV3.get(
                `restaurants/:restaurantId/outlets/${selectedOutletId}/outlet-tables/${tableId}`
            );
            dispatch({
                type: SET_TABLE_ORDER_DETAILS,
                payload: {
                    orders: response.orders.data,
                    current_session: response.current_session,
                },
            });
        } catch (error) {
            response = error;
        }

        return response;
    };
};
