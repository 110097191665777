import React, { useRef, useState, useCallback, useEffect } from 'react';
import {
    EuiFlexItem,
    EuiFormRow,
    EuiFieldNumber,
    EuiFieldText,
    EuiTextArea,
    EuiComboBox,
    EuiSpacer,
    EuiDatePicker,
    EuiHorizontalRule,
} from '@elastic/eui';
import { Controller } from 'react-hook-form';
import FormStepsFooter from './formStepsFooter';
import API from '../../api/axios/API';
import { useSelector, useDispatch } from 'react-redux';
import * as types from '../../reduxStore/types/billing';
import _ from 'lodash';
import MobileNumber from '../Form/MobileNumber';
import moment from 'moment';
import { isEmpty } from 'lodash';
import { getSectionTableStructure } from '../../helper/getSectionTableStructure';
import ComboBoxField from '../Form/ComboBoxField';

const CustomerBasicInfo = (props) => {
    const { errors, control, watch, reset, setValue, setButtonClicked } = props;

    const numberRef = useRef(null);
    const coverCountRef = useRef(null);
    const dispatch = useDispatch();

    const [options, setOptions] = useState([]);
    const [selectedOption, setSelectedOption] = useState([]);
    const [loading, setLoading] = useState(false);
    const [sectionList, setSectionList] = useState({});
    const [items, setItems] = useState([]);
    const [tableList, setTableList] = useState([]);
    const outletId = useSelector((state) => state.outlet.selectedOutletId);
    const mode = useSelector((state) => state.billing.mode);
    const customerFirstOrder = useSelector((state) => state.billing.customerFirstOrder);

    const getTables = () => {
        let innerArray = [];
        if (!isEmpty(sectionList)) {
            Object.entries(sectionList).forEach(([key, value]) => {
                value.tables.map((table) => {
                    // if(table.table_mode === mode){
                    innerArray.push({
                        id: table.outlet_table_id,
                        label: table.name,
                    });
                    // }
                });
            });
        } else if (!isEmpty(tableList)) {
            {
                tableList.map((table) => {
                    // if(table.table_mode === mode){
                    innerArray.push({
                        id: table.outlet_table_id,
                        label: table.name,
                    });
                    // }
                });
            }
        }

        if (innerArray.length) {
            setItems(innerArray);
        }
    };

    useEffect(() => {
        getTables();
    }, [sectionList, tableList]);

    const getTableList = async () => {
        setSectionList({});
        setTableList([]);
        let response;
        try {
            response = await API.get(`/restaurants/:restaurantId/outlets/${outletId}/tables`);
        } catch (error) {
            response = error;
        }

        if (response.success) {
            if (response.outlet_tables.length && response.outlet_tables[0].section) {
                setSectionList(getSectionTableStructure(response.outlet_tables));
            } else {
                setTableList(response.outlet_tables);
            }

            dispatch({
                type: types.SET_TABLES,
                payload: {
                    tables: response.outlet_tables,
                },
            });
        } else {
            alert('Error in fetching tables');
        }
    };

    useEffect(() => {
        if (outletId) {
            getTableList();
        }
    }, [outletId]);

    const onWheel = () => {
        numberRef && numberRef.current && numberRef.current.blur();
        coverCountRef && coverCountRef.current && coverCountRef.current.blur();
    };

    const getCustomerInformationFromNumber = async (number) => {
        let response;
        setLoading(true);
        try {
            response = await API.get(`/restaurants/:restaurantId/customers/details?keyword=${number}`);
        } catch (error) {
            response = error;
        }

        if (response.success && response.customers && response.customers.length > 0) {
            let innerArray = [];
            response.customers.map((customer) => {
                if (customer.customer_addresses && customer.customer_addresses.length > 0) {
                    customer.customer_addresses.map((address) => {
                        innerArray.push({
                            label: `${customer.mobile_number} - ${customer.name} - ${address.location_name}`,
                            address: address,
                            customer: customer,
                        });
                    });
                } else {
                    innerArray.push({
                        label: `${customer.mobile_number} - ${customer.name}`,
                        address: '',
                        customer: customer,
                    });
                }
            });

            setOptions(innerArray);
        }
        setLoading(false);
    };

    const delayedQuery = useCallback(
        _.debounce((q) => getCustomerInformationFromNumber(q), 500),
        []
    );

    const onChangeNumber = (searchValue, hasMatchingOptions) => {
        delayedQuery(searchValue);
    };

    const onChangeValue = (selectedOptions) => {
        setSelectedOption(selectedOptions);

        selectedOptions.length > 0 &&
            reset({
                name: selectedOptions[0].customer.name,
                mobile_number: `${selectedOptions[0].customer.phone_detail.country_code}${selectedOptions[0].customer.phone_detail.mobile_number}`,
                selectedCountryNumber: {
                    dialCode: selectedOptions[0].customer.phone_detail.country_code,
                    number: selectedOptions[0].customer.phone_detail.mobile_number,
                },
                address: selectedOptions[0].address ? selectedOptions[0].address.location_name : '',
                block_number: selectedOptions[0].address ? selectedOptions[0].address.block_number : '',
                land_mark: selectedOptions[0].address ? selectedOptions[0].address.land_mark : '',
                email: selectedOptions[0].customer.email,
                latitude: selectedOptions[0].address ? selectedOptions[0].address.latitude : '',
                longitude: selectedOptions[0].address ? selectedOptions[0].address.longitude : '',
                date_of_birth: selectedOptions[0].date_of_birth,
                anniversary_date: selectedOptions[0].anniversary_date,
                id: selectedOptions[0].customer.id,
            });
        // moment().subtract(1, 'day')
        // moment().subtract(1, 'day')

        dispatch({
            type: types.SET_CUSTOMER_FIRST_ORDER,
            payload: {
                customerFirstOrder: false,
            },
        });

        dispatch({
            type: types.SET_IS_DIRTY,
            payload: {
                isDirty: false,
            },
        });
    };

    const makeDirty = () => {
        if (!customerFirstOrder) {
            dispatch({
                type: types.SET_IS_DIRTY,
                payload: {
                    isDirty: true,
                },
            });
        }
    };

    const addUser = (searchValue) => {
        let isNumber = true;
        for (var i = 0; i < searchValue.length; i++) {
            let asciiCode = searchValue.charCodeAt(i);

            if (asciiCode >= 48 && asciiCode <= 57 && isNumber) {
                isNumber = true;
            } else {
                isNumber = false;
            }
        }

        if (isNumber) {
            setValue('mobile_number', `+91${searchValue}`);
            setValue('name', '');
        } else {
            setValue('name', searchValue);
            setValue('mobile_number', '');
        }
    };

    const onCreateOption = (searchValue, flattenedOptions = []) => {
        const normalizedSearchValue = searchValue.trim().toLowerCase();

        if (!normalizedSearchValue) {
            return;
        }

        const newOption = {
            label: searchValue,
        };

        // Create the option if it doesn't exist.
        if (
            flattenedOptions.findIndex((option) => option.label.trim().toLowerCase() === normalizedSearchValue) === -1
        ) {
            setOptions([...options, newOption]);
        }

        // Select the option.
        setSelectedOption([newOption]);
        makeDirty();
        addUser(searchValue);
    };

    const [dob, setDOB] = useState('');
    const [anniversaryDate, setAnniversaryDate] = useState('');
    const handleDOBChange = (date) => {
        setDOB(date);
        setValue('date_of_birth', date);
        makeDirty();
    };

    const handleAnniversaryDateChange = (date) => {
        console.log('date change', date);
        setAnniversaryDate(date);
        setValue('anniversary_date', date);
        makeDirty();
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column' }} className='customerForm'>
            <div style={{ marginBottom: '20px' }}>
                <h4>
                    <b>Customer information</b>
                </h4>
            </div>
            <div>
                <div style={{ width: '66%' }}>
                    <EuiFormRow label='Search' fullWidth={true}>
                        <Controller
                            render={({}) => (
                                <EuiComboBox
                                    placeholder='Name or number'
                                    options={options}
                                    selectedOptions={selectedOption}
                                    onSearchChange={onChangeNumber}
                                    isLoading={loading}
                                    isClearable={true}
                                    singleSelection={{ asPlainText: true }}
                                    onChange={onChangeValue}
                                    onCreateOption={onCreateOption}
                                />
                            )}
                            name='number_combobox'
                            control={control}
                        />
                    </EuiFormRow>
                    <EuiSpacer size='s' />
                    <EuiHorizontalRule margin='m' />
                </div>

                {mode === 'qsr' && (
                    <div style={{ width: '66%' }}>
                        <ComboBoxField
                            options={items}
                            selectedOptions={watch('table_id') ? watch('table_id') : []}
                            singleSelection={{ asPlainText: true }}
                            errors={errors}
                            label='Select Table'
                            name='table_id'
                            placeholder='Select a table'
                            rules={{
                                validate: (value) =>
                                    (watch('table_id') && watch('table_id').length > 0) || 'Please select one table',
                            }}
                            control={control}
                        />
                        <EuiSpacer size='s' />
                        <EuiHorizontalRule margin='m' />
                    </div>
                )}

                <EuiFormRow
                    label={'Id'}
                    style={{ display: 'none' }}
                    error={errors['id'] ? errors['id'].message : ''}
                    isInvalid={errors['id']}
                >
                    <Controller
                        render={({ field }) => <EuiFieldText value={field.value} onChange={field.onChange} />}
                        name={'id'}
                        placeholder='Customer id'
                        control={control}
                    />
                </EuiFormRow>
                <MobileNumber
                    watch={watch}
                    setValue={setValue}
                    label={'Number (compulsory)'}
                    isInvalid={errors['mobile_number']}
                    errors={errors}
                    placeholder='Enter Customer number'
                    name={'mobile_number'}
                    control={control}
                    rules={true}
                />

                <EuiSpacer />

                <div style={{ width: '66%' }}>
                    <EuiFormRow
                        label={'Name'}
                        error={errors['name'] ? errors['name'].message : ''}
                        isInvalid={errors['name']}
                    >
                        <Controller
                            render={({ field }) => <EuiFieldText value={field.value} onChange={field.onChange} />}
                            name={'name'}
                            control={control}
                            rules={{
                                required: 'Please enter customer name',
                            }}
                            onKeyDown={makeDirty}
                        />
                    </EuiFormRow>
                    {mode === 'dine_in' && (
                        <EuiFormRow
                            label={'Cover count'}
                            error={errors['cover_count'] ? errors['cover_count'].message : ''}
                            isInvalid={errors['cover_count']}
                        >
                            <Controller
                                render={({ field }) => <EuiFieldNumber value={field.value} onChange={field.onChange} />}
                                name={'cover_count'}
                                control={control}
                                rules={{
                                    required: 'Please enter customer cover count',
                                }}
                                inputRef={coverCountRef}
                                onWheel={onWheel}
                                onKeyDown={makeDirty}
                            />
                        </EuiFormRow>
                    )}
                    <EuiHorizontalRule margin='m' />
                </div>

                <EuiFormRow label='Email' isInvalid={errors.email} error={errors.email ? errors.email.message : ''}>
                    <Controller
                        render={({ field }) => <EuiFieldText value={field.value} onChange={field.onChange} />}
                        name='email'
                        control={control}
                        isInvalid={errors.email}
                        defaultValue=''
                        onKeyDown={makeDirty}
                    />
                </EuiFormRow>
                <EuiSpacer />

                <EuiFlexItem style={{ display: 'flex', flexDirection: 'row', width: '65.5%' }}>
                    <EuiFlexItem>
                        <Controller
                            render={({}) => (
                                <EuiFormRow label='Date of birth' fullWidth={true}>
                                    <EuiDatePicker
                                        selected={watch('date_of_birth') ? moment(watch('date_of_birth')) : dob}
                                        onChange={handleDOBChange}
                                    />
                                </EuiFormRow>
                            )}
                            name='date_of_birth'
                            fullWidth={true}
                            control={control}
                        />
                    </EuiFlexItem>

                    <EuiFlexItem style={{ marginLeft: '10px' }}>
                        <Controller
                            render={({}) => (
                                <EuiFormRow label='Anniversary date' fullWidth={true}>
                                    <EuiDatePicker
                                        selected={
                                            watch('anniversary_date')
                                                ? moment(watch('anniversary_date'))
                                                : anniversaryDate
                                        }
                                        onChange={handleAnniversaryDateChange}
                                    />
                                </EuiFormRow>
                            )}
                            name='anniversary_date'
                            fullWidth={true}
                            control={control}
                        />
                    </EuiFlexItem>
                </EuiFlexItem>
                <EuiSpacer />

                {mode !== 'dine_in' && (
                    <>
                        <EuiFormRow
                            label={'Block number'}
                            error={errors['block_number'] ? errors['block_number'].message : ''}
                            isInvalid={errors['block_number']}
                        >
                            <Controller
                                render={({ field }) => <EuiTextArea value={field.value} onChange={field.onChange} />}
                                style={{ height: '60px' }}
                                name={'block_number'}
                                placeholder='Customer block number'
                                control={control}
                                onKeyDown={makeDirty}
                            />
                        </EuiFormRow>
                        <EuiFormRow
                            label={'Landmark'}
                            error={errors['landmark'] ? errors['landmark'].message : ''}
                            isInvalid={errors['landmark']}
                        >
                            <Controller
                                render={({ field }) => <EuiTextArea value={field.value} onChange={field.onChange} />}
                                style={{ height: '60px' }}
                                name={'land_mark'}
                                placeholder='Customer landmark'
                                control={control}
                                onKeyDown={makeDirty}
                                // isInvalid={errors['landmark']}
                            />
                        </EuiFormRow>
                    </>
                )}
            </div>
            <div>
                <FormStepsFooter
                    setSelectedOption={setSelectedOption}
                    reset={reset}
                    errors={errors}
                    watch={watch}
                    step={2}
                    {...props}
                    setButtonClicked={setButtonClicked}
                />
            </div>
        </div>
    );
};

export default CustomerBasicInfo;
