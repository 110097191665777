import {
    EuiButton,
    EuiFlexGroup,
    EuiFlexItem,
    EuiFormRow,
    EuiPanel,
    EuiSpacer,
    EuiSuperSelect,
    EuiText,
    EuiTitle,
} from '@elastic/eui';
import React, { useCallback, useEffect } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { outletSettingsV4 } from '../../../../api/outlet/outletSettingsAPI';
import { userHasPermission } from '../../../../helper/auth';
import * as types from '../../../../reduxStore/types/outlet';

const CategoryLayout = ({ outletData, fetchOutletById }) => {
    const dispatch = useDispatch();
    const permissions = useSelector((state) => state.permission.permission);
    const {
        handleSubmit,
        control,
        watch,
        reset,
        setValue,

        formState: { isDirty, errors },
    } = useForm();

    const { fields, append, remove } = useFieldArray({
        control,
        name: 'category_list_display_settings',
    });

    const resetChanges = useCallback(() => {
        const data = outletData.outlet;

        reset({
            ...{
                category_list_display_settings: data.category_list_display_settings,
                outlet_screen_display_configurations: data.outlet_screen_display_configurations,
            },
        });
    }, [outletData, reset]);

    const onSubmit = useCallback(
        (data) => {
            dispatch(
                outletSettingsV4('layout_settings', {
                    category_list_display_settings: data.category_list_display_settings,
                })
            ).then((res) => {
                if (res.success) {
                    dispatch({
                        type: types.SET_PORTAL_ACTIVE,
                        payload: {
                            portalActive: {},
                        },
                    });
                    dispatch({
                        type: types.SET_PORTAL_ACTIVE_TAB_SWITCHED,
                        payload: {
                            tabSwitched: false,
                        },
                    });
                    fetchOutletById();
                } else {
                    alert(res.message);
                }
            });
        },
        [dispatch, fetchOutletById]
    );

    useEffect(() => {
        if (!userHasPermission(permissions, '/outlet-settings', 'write')) return;

        if (isDirty) {
            dispatch({
                type: 'set-portal-active',
                payload: {
                    portalActive: {
                        resetChanges: resetChanges,
                        submit: handleSubmit(onSubmit),
                    },
                },
            });
        } else {
            dispatch({
                type: 'set-portal-active',
                payload: {
                    portalActive: {},
                },
            });
        }
    }, [isDirty]);

    useEffect(() => {
        if (outletData?.outlet) {
            resetChanges();
        }
    }, [outletData, resetChanges]);

    const handleAddRow = useCallback(() => {
        append({ column_rank: fields?.length + 1, category_display_count: 1 });
        setValue('', '', { shouldDirty: true });
    }, [append, fields?.length, setValue]);

    const handleRemoveRow = useCallback(
        (idx) => {
            remove(idx);
            let updatedDisplaySettings = [];
            if (watch('category_list_display_settings')?.length) {
                for (let [index, setting] of watch('category_list_display_settings')?.entries()) {
                    setting.column_rank = index + 1;
                    updatedDisplaySettings.push(setting);
                }
            }
            setValue('category_list_display_settings', updatedDisplaySettings, { shouldDirty: true });
        },
        [remove, setValue, watch]
    );

    return (
        <>
            {fields?.map((field, index) => {
                return (
                    <React.Fragment key={field.id.toString()}>
                        <span>
                            <EuiFlexItem>
                                <EuiTitle size='xs'>
                                    <h4>
                                        Row{' '}
                                        {field?.row_rank
                                            ? field.row_rank
                                            : field.column_rank
                                            ? field.column_rank
                                            : null}
                                    </h4>
                                </EuiTitle>
                            </EuiFlexItem>
                            <EuiFlexGroup alignItems='flexEnd'>
                                <EuiFlexItem>
                                    <EuiFormRow label='Category Display Count'>
                                        <Controller
                                            control={control}
                                            name={`category_list_display_settings[${index}].category_display_count`}
                                            rules={{ required: 'Please Select Category Display Count' }}
                                            render={({ field }) => {
                                                return (
                                                    <EuiSuperSelect
                                                        onChange={field.onChange}
                                                        valueOfSelected={field.value}
                                                        options={rowOptions}
                                                    />
                                                );
                                            }}
                                        />
                                    </EuiFormRow>
                                </EuiFlexItem>
                                <EuiFlexItem grow={false}>
                                    {' '}
                                    <EuiButton onClick={() => handleRemoveRow(index)}>Remove</EuiButton>
                                </EuiFlexItem>
                            </EuiFlexGroup>
                        </span>
                        <EuiSpacer />
                    </React.Fragment>
                );
            })}

            <EuiButton fill onClick={handleAddRow}>
                Add Row
            </EuiButton>
        </>
    );
};

const rowOptions = [
    { value: 1, inputDisplay: '1' },
    { value: 2, inputDisplay: '2' },
    { value: 3, inputDisplay: '3' },
];

export default React.memo(CategoryLayout);
