import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    EuiOverlayMask,
    EuiModal,
    EuiModalBody,
    EuiModalHeader,
    EuiModalHeaderTitle,
    EuiModalFooter,
    EuiButton,
    EuiText,
    htmlIdGenerator,
    EuiFlyout,
    EuiFlyoutHeader,
    EuiTitle,
    EuiFlyoutBody,
    EuiFlyoutFooter,
    EuiFlexGroup,
    EuiFlexItem,
    EuiPanel,
    EuiRadio,
} from '@elastic/eui';
import * as types from '../../reduxStore/types/billing';
import BillingAddon from './billingAddon';
import { isEmpty, isEqual, sortBy, orderBy } from 'lodash';
import API from '../../api/axios/API';
import { agmarkSign } from '../../helper/agmarkSign';
import VariantListComponent from './variantListComponent';

const AddonModal = (props) => {
    const { outletId } = props;
    const addonModalVisible = useSelector((state) => state.billing.addonModalVisible);
    const selectedItemAddonList = useSelector((state) => state.billing.selectedItemAddonList);
    const selectedItem = useSelector((state) => state.billing.selectedItem);
    const billingItem = useSelector((state) => state.billing.billingItem);
    const mode = useSelector((state) => state.billing.mode);
    const languageId = useSelector((state) => state.language.languageId);
    const cartId = useSelector((state) => state.billing.cartId);
    const tableSelectedList = useSelector((state) => state.billing.tableSelectedList);
    const customerInformation = useSelector((state) => state.billing.customerInformation);
    const sequence = useSelector((state) => state.billing.sequence);

    const [addonGroupList, setAddonGroupList] = useState([]);
    const [selectedVariant, setSelectedVariant] = useState({});
    const [selectedVariantIndex, setSelectedVariantIndex] = useState(0);

    const dispatch = useDispatch();

    useEffect(() => {
        if (selectedItem.variants && selectedItem.variants.length) {
            setAddonGroupList(selectedItem.variants[0].options[selectedVariantIndex].addons);
            addonList(selectedItem.variants[0].options[selectedVariantIndex].addons);
            setSelectedVariant(selectedItem.variants[0].options[selectedVariantIndex]);

            let item = { ...selectedItem };
            item.variants[0].priceWithAddon = selectedItem.variants[0].options[selectedVariantIndex].price;

            dispatch({
                type: types.SET_SELECTED_ITEM,
                payload: {
                    selectedItem: item,
                },
            });
        } else {
            console.log('inside condition addon groups ', selectedItem.addon_groups);
            setAddonGroupList(selectedItem.addon_groups);
            addonList(selectedItem.addon_groups);
        }
    }, [selectedVariant]);

    const onCloseModal = () => {
        dispatch({
            type: types.SET_ADDON_MODAL_VISIBLE,
            payload: {
                addonModalVisible: false,
            },
        });
    };

    let MINIMUM_ADDON_CHOICE_ERROR = 'minimum addon choice required';
    let MAXIMUM_ADDON_CHOICE_ERROR = 'maximum addon choices limit';

    const [maximumAddonGroupErrors, setMaximumAddonGroupErrors] = useState({});
    const [minimumAddonGroupErrors, setMinimumAddonGroupErrors] = useState({});

    const [selectedAddOnsByGroup, setSelectedAddOnsByGroup] = useState({});
    const [totalAmount, setTotalAmount] = useState(0);

    const getInStockAddonChoices = (addonGroup) => {
        return addonGroup.choices.filter((choiceItem, choiceIndex) => {
            return choiceItem.in_stock;
        });
    };

    const formateNewAddonChoice = (choice, addonGroupChoice) => {
        return {
            choice_id: choice.choice_id,
            name: choice.name,
            choicePrice: parseFloat(choice.price),
            group_name: addonGroupChoice.group_name,
            gst_slab: choice.gst_slab,
        };
    };

    const calculateAddonAmount = (addonsByGroup) => {
        let addonChoicesAmount = 0;
        Object.values(addonsByGroup).map((groupItem) => {
            groupItem.map((choice) => {
                addonChoicesAmount = parseFloat(addonChoicesAmount) + parseFloat(choice.choicePrice);
            });
        });
        return addonChoicesAmount;
    };

    const handleAddonGroupError = (addonGroupDetail, addonGroupChoices, addonsByGroup) => {
        //new addonChoices: addonsByGroup
        let maximumErrors = { ...maximumAddonGroupErrors };
        let minimumErrors = { ...minimumAddonGroupErrors };

        let isMinimumChoiceError =
            addonGroupDetail.min_addons && addonGroupChoices.length < addonGroupDetail.min_addons;

        let isMaximumChoiceError =
            addonGroupDetail.max_addons && addonGroupChoices.length > addonGroupDetail.max_addons;

        let minimumError = isMinimumChoiceError ? [MINIMUM_ADDON_CHOICE_ERROR] : [];
        let maximumError = isMaximumChoiceError ? [MAXIMUM_ADDON_CHOICE_ERROR] : [];

        if (minimumError.length) {
            minimumErrors = {
                ...minimumErrors,
                [addonGroupDetail.addon_id]: minimumError,
            };
        } else {
            delete minimumErrors[addonGroupDetail.addon_id];
        }

        if (maximumError.length) {
            maximumErrors = {
                ...maximumErrors,
                [addonGroupDetail.addon_id]: maximumError,
            };
        } else {
            delete maximumErrors[addonGroupDetail.addon_id];
        }

        setMinimumAddonGroupErrors(minimumErrors);
        setMaximumAddonGroupErrors(maximumErrors);
        // setMaxErrors(maximumErrors);

        if (!isMaximumChoiceError) {
            setTotalAmount(calculateAddonAmount(addonsByGroup));
            setSelectedAddOnsByGroup(addonsByGroup);
        }
    };

    const addonList = (addonGroups) => {
        let addonsByGroup = { ...selectedAddOnsByGroup };
        addonGroups.map((groupItem) => {
            let inStockChoices = getInStockAddonChoices(groupItem);

            if (!(inStockChoices.length >= groupItem.min_addons)) {
                return;
            }

            if (groupItem.min_addons) {
                if (groupItem.max_addons != 1 && groupItem.min_addons != 1) {
                    let addonChoices = [];
                    groupItem.choices.map((choice, choiceIndex) => {
                        if (choiceIndex < groupItem.min_addons) {
                            let newAddonChoice = formateNewAddonChoice(choice, groupItem);
                            addonChoices.push(newAddonChoice);
                            addonsByGroup = {
                                ...addonsByGroup,
                                [groupItem.addon_id]: addonChoices,
                            };
                        }
                    });
                } else {
                    // radio button selection
                    let choice = groupItem.choices[0];
                    let newAddonChoice = formateNewAddonChoice(choice, groupItem);
                    addonsByGroup = {
                        ...addonsByGroup,
                        [groupItem.addon_id]: [newAddonChoice],
                    };
                }
            }
        });

        setTotalAmount(calculateAddonAmount(addonsByGroup));
        setSelectedAddOnsByGroup(addonsByGroup);
    };

    const sortAddonObject = (addon) => {
        let addonObj = JSON.parse(JSON.stringify(addon));

        Object.entries(addonObj).forEach(([key, value]) => {
            if (key !== 'addon_information' && key !== 'variant_information') {
                let innerArray = [...value];
                addonObj[key] = sortBy(innerArray, ['choice_id']);
            }
        });
        console.log('sortAddonObject:', addonObj);

        return addonObj;
    };

    const sortSelectedAddonByGroup = () => {
        console.log('sortSelectedAddonByGroup:selectedAddOnsByGroup:', selectedAddOnsByGroup);
        let addonObj = JSON.parse(JSON.stringify(selectedAddOnsByGroup));

        Object.entries(addonObj).forEach(([key, value]) => {
            if (key !== 'addon_information' && key !== 'variant_information') {
                let innerArray = [...value];
                addonObj[key] = sortBy(innerArray, ['choice_id']);
            }
        });

        if (!isEmpty(selectedVariant)) {
            addonObj['variant_information'] = {
                ...selectedVariant,
                variant_id: selectedVariant.id,
                variant_group_id: selectedItem.variants[0].id,
            };
        }

        return addonObj;
    };

    const checkAddonsExist = (billingItemObj) => {
        let selectedId = `${selectedItem.id}i`;
        let customizations = billingItemObj[selectedId].customizations;
        let boolCheck = true;

        billingItemObj[selectedId].customizations.map((addon) => {
            // sortAddonObject(addon)
            // sortSelectedAddonByGroup()

            let addonInformation = addon['addon_information'];

            delete addon['addon_information'];

            let objAddon = sortAddonObject(addon);
            let objGroup = sortSelectedAddonByGroup();

            let newItemVariant = {
                ...selectedVariant,
                variant_id: selectedVariant.id,
                variant_group_id: selectedItem.variants[0].id,
            };

            let condition1 = !isEmpty(selectedVariant) ? isEqual(objAddon.variant_information, newItemVariant) : true;
            let condition2 = !isEmpty(objGroup) ? isEqual(objAddon, objGroup) : true;
            let condition = condition1 && condition2;

            if (condition) {
                addonInformation.quantity += 1;
                addon['addon_information'] = addonInformation;
                boolCheck = false;
            } else {
                if (billingItemObj[selectedId]['customizations']) {
                    // this to add back to exisitong customization
                    addon['addon_information'] = addonInformation;
                    // addon['variant_information'] = newItemVariant
                }
            }
        });

        console.log('bool check ', boolCheck);

        if (boolCheck) {
            if (!isEmpty(selectedAddOnsByGroup)) {
                //this to add new customization
                selectedAddOnsByGroup['addon_information'] = { quantity: 1 };
                selectedAddOnsByGroup['variant_information'] = {
                    ...selectedVariant,
                    variant_id: selectedVariant.id,
                    variant_group_id: selectedItem.variants[0].id,
                };

                customizations.push(selectedAddOnsByGroup);
            } else if (!isEmpty(selectedVariant)) {
                customizations.push({
                    addon_information: { quantity: 1 },
                    variant_information: {
                        ...selectedVariant,
                        variant_id: selectedVariant.id,
                        variant_group_id: selectedItem.variants[0].id,
                    },
                });
            } else {
                let quantity = parseFloat(billingItemObj[selectedId].quantity);
                quantity += 1;
                billingItemObj[selectedId]['quantity'] = quantity;
            }
        }
    };

    const onAdd = () => {
        let billingItemObj = JSON.parse(JSON.stringify(billingItem));
        let innerObj = {};
        let selectedId = `${selectedItem.id}i`;

        if (billingItemObj[selectedId]) {
            innerObj = billingItemObj[selectedId];
        } else {
            innerObj = { ...selectedItem, title: selectedItem.title, description: selectedItem.description };
            innerObj.quantity = 0;
        }

        billingItemObj[selectedId] = { ...innerObj, sequence: sequence + 1 };

        if (
            billingItemObj[selectedId] &&
            billingItemObj[selectedId].customizations &&
            billingItemObj[selectedId].customizations.length > 0
        ) {
            checkAddonsExist(billingItemObj);
        } else {
            if (!isEmpty(selectedAddOnsByGroup)) {
                if (!isEmpty(selectedVariant)) {
                    billingItemObj[selectedId]['customizations'] = [
                        {
                            ...selectedAddOnsByGroup,
                            addon_information: { quantity: 1 },
                            variant_information: {
                                ...selectedVariant,
                                variant_id: selectedVariant.id,
                                variant_group_id: selectedItem.variants[0].id,
                            },
                        },
                    ];
                } else {
                    billingItemObj[selectedId]['customizations'] = [
                        { ...selectedAddOnsByGroup, addon_information: { quantity: 1 } },
                    ];
                }
            } else if (!isEmpty(selectedVariant)) {
                billingItemObj[selectedId]['customizations'] = [
                    {
                        addon_information: { quantity: 1 },
                        variant_information: {
                            ...selectedVariant,
                            variant_id: selectedVariant.id,
                            variant_group_id: selectedItem.variants[0].id,
                        },
                    },
                ];
            } else {
                billingItemObj[selectedId].quantity += 1;
            }
        }

        dispatch({
            type: types.SET_BILLING_ITEM,
            payload: {
                billingItem: billingItemObj,
            },
        });
        onCloseModal();

        addItemToCart(billingItemObj);
    };

    const addItemToCart = async (billingItemObj) => {
        let response;
        let url = '';

        if (!cartId) {
            url = `/en/pos/restaurants/:restaurantId/outlets/${outletId}/carts`;
        } else {
            url = `/en/pos/restaurants/:restaurantId/outlets/${outletId}/carts/${cartId}`;
        }

        let data = orderBillingStructureNew(billingItemObj);

        let fullObj = {
            items: { ...data },
            ordering_mode: mode == 'qsr' ? 'takeaway' : mode,
            table_ids: tableSelectedList,
            customer_address_id: customerInformation.id ? customerInformation.id : null,
        };

        if (mode === 'delivery') {
            if (customerInformation.id) {
                fullObj['customer_address'] = { id: customerInformation.id };
            } else {
                fullObj['customer_address'] = {
                    latitude: customerInformation.latitude,
                    longitude: customerInformation.longitude,
                    location_name: customerInformation.location_name,
                    block_number: customerInformation.block_number,
                    city: customerInformation.city,
                    state: customerInformation.state,
                    region: customerInformation.region,
                    land_mark: customerInformation.land_mark,
                    tag: 'home',
                };
            }
        }

        console.log('billinfg item obj ', JSON.stringify(fullObj));
        try {
            if (!cartId) {
                url = `/en/pos/restaurants/:restaurantId/outlets/${outletId}/carts`;
                response = await API.post(url, fullObj);
            } else {
                url = `/en/pos/restaurants/:restaurantId/outlets/${outletId}/carts/${cartId}`;
                response = await API.put(url, fullObj);
            }
        } catch (error) {
            response = error;
        }

        if (response.success) {
            isEmpty(billingItem) &&
                !cartId &&
                dispatch({
                    type: types.SET_CART_ID,
                    payload: {
                        cartId: response.cart.cart_id,
                    },
                });
            dispatch({
                type: types.SET_BILLING_ITEM_CART_STRUCTURE,
                payload: {
                    billingItemCartStructure: response,
                },
            });
            dispatch({
                type: types.SET_SEQUENCE_COUNT_ADD,
            });
            //add cart_item_id
            cartItemIdAdd(response, billingItemObj);
        } else {
            let billingItemObj = JSON.parse(JSON.stringify(billingItem));
            delete billingItemObj[`${selectedItem.id}i`];
            dispatch({
                type: types.SET_BILLING_ITEM,
                payload: {
                    billingItem: billingItemObj,
                },
            });

            // if(!isEmpty(response.message) && !isEmpty(response.message.errors) && response.message.errors.customer_address){
            //     alert(response.message.errors.customer_address)
            // }else{
            //     alert('Failed to add cart')
            //     errorCheck(response)
            // }
            errorCheck(response);
        }
    };

    const cartItemIdAdd = (response, itemObj) => {
        let innerObj = { ...itemObj };

        if (response.cart.cart_items.length > 0) {
            response.cart.cart_items.map((item) => {
                innerObj[`${item.item_id}i`].cart_item_id = item.cart_item_id;
            });
        }

        dispatch({
            type: types.SET_BILLING_ITEM,
            payload: {
                billingItem: innerObj,
            },
        });
    };

    const errorCheck = (orderResponse) => {
        let table_error = false;
        let customer_error = false;
        if (orderResponse.errors) {
            Object.entries(orderResponse.errors).forEach(([key, value], index) => {
                if (key.startsWith('table_ids')) {
                    table_error = true;
                }

                if (key.startsWith('customer_address')) {
                    customer_error = true;
                }
            });

            if (table_error) {
                alert('Table already assigned');
                dispatch({
                    type: types.SET_TABLE_SELECTED_LIST,
                    payload: {
                        tableSelectedList: [],
                    },
                });

                dispatch({
                    type: types.SET_CUSTOMER_INFORMATION_FINISH,
                    payload: {
                        finishCustomerInfo: false,
                    },
                });
            } else if (customer_error) {
                alert('Provide proper customer information');
                dispatch({
                    type: types.SET_CUSTOMER_INFORMATION_FINISH,
                    payload: {
                        finishCustomerInfo: false,
                    },
                });
            } else {
                alert('error in order place');
            }
        } else {
            if (!orderResponse.message) {
                alert(orderResponse.message);
            } else {
                alert('error in order place');
            }
        }
    };

    const orderBillingStructureNew = (itemInnerObj) => {
        let itemObj = {};
        Object.entries(itemInnerObj).forEach(([key, value], index) => {
            let id = `${value.id}i`;
            if (key !== 'cart_id' && key !== 'customer_details') {
                let innerItemObj = {};
                if (!itemObj[id]) {
                    innerItemObj['quantity'] = value.quantity;

                    if (value.customizations && !isEmpty(value.customizations)) {
                        innerItemObj['customizations'] = value.customizations;
                    }
                } else {
                    innerItemObj['quantity'] = parseInt(itemObj[id]['quantity']) + parseInt(value.quantity);

                    if (value.customizations && !isEmpty(value.customizations)) {
                        innerItemObj['customizations'] = value.customizations;
                    }
                }

                itemObj[value.id] = innerItemObj;
            }
        });

        return itemObj;
    };

    const BillingSectionStyle = {
        display: 'flex',
        flexDirection: 'column',
        border: '1px solid black',
        padding: '0px',
    };

    const priceCondition = () => {
        if (!isEmpty(selectedItem.variants)) {
            return selectedItem.variants[0].priceWithAddon
                ? `₹${selectedItem.variants[0].priceWithAddon.toFixed(2)}`
                : '';
        } else {
            return selectedItem.priceWithAddon
                ? `₹${selectedItem.priceWithAddon.toFixed(2)}`
                : `₹${selectedItem.price.toFixed(2)}`;
        }
    };

    return (
        <>
            {addonModalVisible && (
                <EuiPanel className='addonView' direction='column' style={BillingSectionStyle}>
                    <EuiFlexItem style={{ height: '90%', backgroundColor: 'white' }}>
                        <EuiFlexItem
                            style={{
                                padding: '20px',
                                margin: '10px 0px',
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                            }}
                        >
                            <EuiFlexItem style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                {agmarkSign('vegetarian')}
                                <h4 style={{ paddingLeft: '5px', fontSize: '16px' }}>
                                    {!isEmpty(selectedItem) ? selectedItem.title : ''}
                                </h4>
                            </EuiFlexItem>
                            <EuiFlexItem style={{ fontSize: '16px' }}>
                                {!isEmpty(selectedItem) ? priceCondition() : ''}
                            </EuiFlexItem>
                        </EuiFlexItem>

                        <hr />

                        {!isEmpty(selectedItem.variants) && (
                            <VariantListComponent
                                setSelectedAddOnsByGroup={setSelectedAddOnsByGroup}
                                setSelectedVariant={setSelectedVariant}
                                selectedVariant={selectedVariant}
                                setSelectedVariantIndex={setSelectedVariantIndex}
                            />
                        )}

                        <EuiFlexItem style={{ overflow: 'auto' }}>
                            {addonGroupList && addonGroupList.length > 0 ? (
                                <>
                                    {addonGroupList.map((addon, index) => (
                                        <BillingAddon
                                            selectedVariant={selectedVariant}
                                            setSelectedVariant={setSelectedVariant}
                                            selectedVariantIndex={selectedVariantIndex}
                                            maximumAddonGroupErrors={maximumAddonGroupErrors}
                                            minimumAddonGroupErrors={minimumAddonGroupErrors}
                                            addon={addon}
                                            selectedAddOnsByGroup={selectedAddOnsByGroup}
                                            formateNewAddonChoice={formateNewAddonChoice}
                                            setSelectedAddOnsByGroup={setSelectedAddOnsByGroup}
                                            setTotalAmount={setTotalAmount}
                                            calculateAddonAmount={calculateAddonAmount}
                                            handleAddonGroupError={handleAddonGroupError}
                                            horizontalRuler={addonGroupList.length - 1 !== index}
                                        />
                                    ))}
                                </>
                            ) : (
                                <></>
                            )}
                        </EuiFlexItem>
                    </EuiFlexItem>
                    <hr />

                    <EuiFlexItem
                        style={{
                            backgroundColor: 'white',
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            height: '10%',
                            padding: '0px 20px',
                        }}
                    >
                        <EuiButton
                            color='danger'
                            style={{ border: 'transparent' }}
                            fullWidth={true}
                            onClick={onCloseModal}
                            fill
                        >
                            <span style={{ fontSize: '14px' }}>CANCEL</span>
                        </EuiButton>

                        <EuiButton
                            style={{ marginLeft: '15px', border: 'transparent' }}
                            fullWidth={true}
                            onClick={onAdd}
                            disabled={
                                isEmpty(minimumAddonGroupErrors) && isEmpty(maximumAddonGroupErrors) ? false : true
                            }
                            fill
                        >
                            <span style={{ fontSize: '14px' }}>SELECT</span>
                        </EuiButton>
                    </EuiFlexItem>
                </EuiPanel>
            )}
        </>
    );
};

export default AddonModal;
