import React, { useCallback, useEffect, useState } from 'react';
import { EuiFlexGroup, EuiFlexItem, EuiText, EuiCheckbox, htmlIdGenerator } from '@elastic/eui';
import { useSelector } from 'react-redux';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import ItemDetailsCollaspibleNav from './ItemDetailsCollaspibleNav';
import { ReactComponent as ItemExposeIcon } from '../../../../../assets/img/svg/ItemExposeIcon.svg';

const ItemExpose = () => {
    const editItem = useSelector((state) => state.menuReducer.activeItemDetails.details);
    const orderingModes = useSelector((state) => state.menuReducer.restaurantOrderingModes);
    const activeItemId = useSelector((state) => state.menu.activeItemId);
    const { control, setValue, watch } = useFormContext();
    const itemOrderingModes = useWatch({
        control,
        name: 'ordering_modes',
    });
    const isOutletMenu = useSelector((state) => state.menuReducer.isOutletMenu);
    const [orderingModeState, setOrderingModeState] = useState([]);
    const [removedOrderingModes, setRemovedOrderingModes] = useState([]);
    const [newOrderingModes, setNewOrderingModes] = useState([]);
    const [removeNewOrderingModes, setRemoveNewOrderingModes] = useState([]);
    const [isNavOpen, setIsNavOpen] = useState(false);
    useEffect(() => {
        if (itemOrderingModes?.length) {
            setOrderingModeState(itemOrderingModes);
        }
    }, [itemOrderingModes, watch]);

    const updateOrderingMode = useCallback(
        (mode, event) => {
            const doesHaveDifferentPrices = watch('is_enabled_same_price_details_for_all_ordering_modes');

            let selectedMode = {};
            selectedMode = {
                compare_price: !doesHaveDifferentPrices ? orderingModeState[0]?.compare_price : null,
                gst_slab: !doesHaveDifferentPrices ? orderingModeState[0]?.gst_slab : null,
                packaging_charge: !doesHaveDifferentPrices ? orderingModeState[0]?.packaging_charge : null,
                price: !doesHaveDifferentPrices ? orderingModeState[0]?.price : null,
                [`price_${mode.id}`]: !doesHaveDifferentPrices ? orderingModeState[0]?.price : null,
                restaurant_ordering_mode_id: mode?.id,
                restaurant_ordering_mode_name: mode?.display_name,
                time_slot_setting: !doesHaveDifferentPrices ? orderingModeState[0]?.time_slot_setting : null,
                time_slots: !doesHaveDifferentPrices ? orderingModeState[0]?.time_slots : null,
            };
            const isChecked = event.target.checked;
            // filterChecked(mode, orderingModeState);
            if (activeItemId !== 'newId') {
                if (isChecked) {
                    setValue('ordering_modes', [...orderingModeState, selectedMode]);
                    setOrderingModeState((prevState) => [...prevState, selectedMode]);
                } else {
                    const checkedOrderingModes = orderingModeState.filter(
                        (orderingMode) =>
                            orderingMode?.restaurant_ordering_mode_id !== selectedMode?.restaurant_ordering_mode_id
                    );
                    setOrderingModeState(checkedOrderingModes);
                    setValue('ordering_modes', checkedOrderingModes);
                }

                // Prepare the delete ordering array
                if (isChecked) {
                    const matchedModes = removedOrderingModes?.filter((orderingMode) => {
                        return orderingMode?.restaurant_ordering_mode_id !== selectedMode?.restaurant_ordering_mode_id;
                    });
                    setRemovedOrderingModes(matchedModes?.restaurant_ordering_mode_id);
                    setValue('delete_ordering_modes', matchedModes?.restaurant_ordering_mode_id);
                } else {
                    const matchedModes = editItem?.ordering_modes?.filter(
                        (orderingMode) =>
                            orderingMode?.restaurant_ordering_mode_id === selectedMode?.restaurant_ordering_mode_id
                    )?.[0];
                    setRemovedOrderingModes([...removedOrderingModes, matchedModes?.restaurant_ordering_mode_id]);
                    setValue('delete_ordering_modes', [
                        ...removedOrderingModes,
                        matchedModes.restaurant_ordering_mode_id,
                    ]);
                }
            } else {
                if (isChecked) {
                    setValue('ordering_modes', [...newOrderingModes, selectedMode]);
                    setNewOrderingModes((prevState) => [...prevState, selectedMode]);
                } else {
                    const checkedOrderingModes = newOrderingModes.filter(
                        (orderingMode) =>
                            orderingMode?.restaurant_ordering_mode_id !== selectedMode?.restaurant_ordering_mode_id
                    );
                    setNewOrderingModes(checkedOrderingModes);
                    setValue('ordering_modes', checkedOrderingModes);
                }

                // Prepare the delete ordering array
                if (isChecked) {
                    const matchedModes = removeNewOrderingModes?.filter((orderingMode) => {
                        return orderingMode?.restaurant_ordering_mode_id !== selectedMode?.restaurant_ordering_mode_id;
                    });
                    setRemoveNewOrderingModes(matchedModes?.restaurant_ordering_mode_id);
                    setValue('delete_ordering_modes', matchedModes?.restaurant_ordering_mode_id);
                } else {
                    const matchedModes = editItem?.ordering_modes?.filter(
                        (orderingMode) =>
                            orderingMode?.restaurant_ordering_mode_id === selectedMode?.restaurant_ordering_mode_id
                    )?.[0];
                    setRemoveNewOrderingModes([...removeNewOrderingModes, matchedModes?.restaurant_ordering_mode_id]);
                    setValue('delete_ordering_modes', [
                        ...removeNewOrderingModes,
                        matchedModes.restaurant_ordering_mode_id,
                    ]);
                }
            }
        },
        [
            watch,
            orderingModeState,
            activeItemId,
            setValue,
            removedOrderingModes,
            editItem?.ordering_modes,
            newOrderingModes,
            removeNewOrderingModes,
        ]
    );

    return (
        <>
            <ItemDetailsCollaspibleNav
                cardTitle='Expose Item'
                isNavOpen={isNavOpen}
                cardDescription={
                    <EuiFlexGroup
                        direction='column'
                        justifyContent='spaceBetween'
                        alignItems='flexStart'
                        gutterSize='xs'
                    >
                        <EuiFlexItem>
                            <EuiText size='xs'>Decide in which ordering mode you want this item to be seen</EuiText>
                        </EuiFlexItem>
                        {itemOrderingModes?.length ? (
                            <EuiFlexItem>
                                <EuiFlexGroup wrap responsive direction='row' alignItems='center' gutterSize='xs'>
                                    <EuiFlexItem grow={false}>
                                        <EuiText style={{ fontWeight: 600 }} size='s'>
                                            Active Modes:{' '}
                                        </EuiText>{' '}
                                    </EuiFlexItem>
                                    {itemOrderingModes?.map((mode, index) => {
                                        return (
                                            <React.Fragment key={index}>
                                                <EuiFlexItem grow={false}>
                                                    <EuiText size='s'>{mode.restaurant_ordering_mode_name}</EuiText>
                                                </EuiFlexItem>
                                                {index + 1 !== itemOrderingModes?.length ? ',' : null}
                                            </React.Fragment>
                                        );
                                    })}
                                </EuiFlexGroup>
                            </EuiFlexItem>
                        ) : (
                            <EuiText size='s'>Item turned off in all ordering modes</EuiText>
                        )}
                    </EuiFlexGroup>
                }
                setIsNavOpen={setIsNavOpen}
                toggleText='Edit Basic Details'
                icon={<ItemExposeIcon />}
            >
                <EuiFlexGroup
                    alignItems='center'
                    style={{ padding: '10px' }}
                    justifyContent='flexStart'
                    wrap
                    responsive
                >
                    {orderingModes
                        .filter((orderingMode) => orderingMode.status)
                        .map((mode) => {
                            return (
                                <EuiFlexItem key={mode.id.toString()}>
                                    <EuiFlexGroup direction='row' justifyContent='flexStart' alignItems='center'>
                                        <EuiFlexItem grow={false}>
                                            <Controller
                                                render={({ field }) => (
                                                    <EuiCheckbox
                                                        onChange={(e) => {
                                                            field.onChange(e.target.checked);
                                                            updateOrderingMode(mode, e);
                                                            setValue(
                                                                `${mode.display_name}_${mode.id}`,
                                                                e.target.checked
                                                            );
                                                        }}
                                                        id={htmlIdGenerator()()}
                                                        checked={Boolean(field.value)}
                                                        disabled={isOutletMenu}
                                                        compressed
                                                    />
                                                )}
                                                name={`${mode.display_name}_${mode.id}`}
                                                control={control}
                                            />
                                        </EuiFlexItem>
                                        <EuiFlexItem grow={false}>
                                            <EuiText style={{ textTransform: 'capitalize' }}>
                                                {mode.display_name}
                                            </EuiText>
                                        </EuiFlexItem>
                                    </EuiFlexGroup>
                                </EuiFlexItem>
                            );
                        })}
                </EuiFlexGroup>
            </ItemDetailsCollaspibleNav>
        </>
    );
};

export default React.memo(ItemExpose);
