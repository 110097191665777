import { EuiContextMenuItem } from '@elastic/eui';
import React from 'react';

const PopOverItems = (props) => {
    const { closePopover, onChangeRowSize } = props;
    const items = [
        <EuiContextMenuItem
            key='10 rows'
            // icon={getIconType(10)}
            onClick={() => {
                closePopover();
                onChangeRowSize(10);
            }}
        >
            10 rows
        </EuiContextMenuItem>,
        <EuiContextMenuItem
            key='20 rows'
            // icon={getIconType(20)}
            onClick={() => {
                closePopover();
                onChangeRowSize(20);
            }}
        >
            20 rows
        </EuiContextMenuItem>,
        <EuiContextMenuItem
            key='50 rows'
            // icon={getIconType(50)}
            onClick={() => {
                closePopover();
                onChangeRowSize(50);
            }}
        >
            50 rows
        </EuiContextMenuItem>,
        <EuiContextMenuItem
            key='100 rows'
            // icon={getIconType(100)}
            onClick={() => {
                closePopover();
                onChangeRowSize(100);
            }}
        >
            100 rows
        </EuiContextMenuItem>,
    ];
    return items;
};

export default PopOverItems;
