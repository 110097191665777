import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
    EuiCard,
    EuiCollapsibleNavGroup,
    EuiFlexGroup,
    EuiFlexItem,
    EuiForm,
    EuiIcon,
    EuiLink,
    EuiSpacer,
    EuiText,
    EuiTitle,
} from '@elastic/eui';
import FormGroupDescription from '../../../components/formGroupDescription';
import ButtonGroup from '../../../components/Form/ButtonGroup';
import * as types from '../../../reduxStore/types/outlet';
import NumberField from '../../../components/Form/NumberField';
import { useDispatch, useSelector } from 'react-redux';
import { userHasPermission } from '../../../helper/auth';
import SwitchField from '../../../components/Form/SwitchField';
import CheckBoxField from '../../../components/Form/CheckBoxField';
import { restaurantSettingsAPI } from '../../../api/restaurantSettings/restaurantSettingsAPI';

const CashbackSettings = (props) => {
    const { restaurantResponse, fetchRestaurant } = props;
    const {
        handleSubmit,
        control,
        watch,
        reset,
        setValue,

        formState: { isDirty, errors },
    } = useForm();
    const [isNavOpen, setIsNavOpen] = useState(false);
    const [cardDescription, setCardDescription] = useState([]);

    const dispatch = useDispatch();
    const permissions = useSelector((state) => state.permission.permission);
    const restaurantDescriptions = useSelector((state) => state.auth.restaurantDescriptions);
    const [tabOrderingModes, setTabOrderingModes] = useState([]);

    useEffect(() => {
        if (!userHasPermission(permissions, '/restaurant-settings', 'write')) return;

        if (isDirty) {
            dispatch({
                type: 'set-portal-active',
                payload: {
                    portalActive: {
                        resetChanges: resetChanges,
                        submit: handleSubmit(onSubmit),
                    },
                },
            });
        } else {
            dispatch({
                type: 'set-portal-active',
                payload: {
                    portalActive: {},
                },
            });
        }
    }, [isDirty]);

    const resetChanges = useCallback(() => {
        setCardDescription([]);
        let defaultValue = {
            ordering_mode_cashback_configurations: [],
        };
        let restaurantData = restaurantResponse.restaurant;
        if (restaurantData.settings) {
            defaultValue.enable_reward_points_functionality =
                restaurantData.settings.enable_reward_points_functionality;
            defaultValue.cashback_earning_percentage = restaurantData.settings.cashback_earning_percentage;
            defaultValue.reward_point_expiration_duration = restaurantData.settings.reward_point_expiration_duration;
            defaultValue.signup_cashback = restaurantData.settings.signup_cashback;
            defaultValue.promo_consolidation = restaurantData.settings.promo_consolidation;
            const firstIndexCashbackPercentage =
                restaurantData?.ordering_mode_cashback_configurations?.[0]?.cashback_redeem_percentage;
            const isSameConfig = restaurantData?.ordering_mode_cashback_configurations?.every(
                (config) => config?.cashback_redeem_percentage === firstIndexCashbackPercentage
            );
            defaultValue.is_same_config = isSameConfig;
            defaultValue.redeem_for_all_modes = firstIndexCashbackPercentage;
            let firstOrderingMode = [];

            for (const orderingMode of restaurantData?.ordering_modes) {
                if (restaurantData?.ordering_mode_cashback_configurations?.length) {
                    for (const configMode of restaurantData.ordering_mode_cashback_configurations) {
                        if (orderingMode.id === configMode.restaurant_ordering_mode_id && orderingMode.status) {
                            firstOrderingMode.push(orderingMode);
                            defaultValue.ordering_mode_cashback_configurations.push({
                                displayName: orderingMode.type,
                                restaurant_ordering_mode_id: orderingMode.id,
                                cashback_redeem_percentage: configMode?.cashback_redeem_percentage,
                                ...orderingMode,
                            });
                        }
                    }
                } else {
                    if (orderingMode.status) {
                        defaultValue.ordering_mode_cashback_configurations.push({
                            displayName: orderingMode.type,
                            restaurant_ordering_mode_id: orderingMode.id,
                            cashback_redeem_percentage: null,
                            ...orderingMode,
                        });
                    }
                }
            }

            if (defaultValue?.ordering_mode_cashback_configurations?.length) {
                const returnThreeMainTabs = defaultValue?.ordering_mode_cashback_configurations?.filter(
                    (orderingMode) =>
                        orderingMode.tab === 'delivery' ||
                        orderingMode.tab === 'takeaway' ||
                        orderingMode.tab === 'dine_in'
                );

                const removeDuplicateTabs = returnThreeMainTabs?.reduce(
                    (acc, curr) => (acc.find((mode) => mode.tab === curr.tab) ? acc : [...acc, curr]),
                    []
                );

                if (removeDuplicateTabs?.length) {
                    defaultValue.tabOrderingModes = removeDuplicateTabs;
                    setTabOrderingModes(removeDuplicateTabs);
                }
            }

            for (const orderingMode of restaurantData.ordering_mode_cashback_configurations) {
                if (orderingMode.cashback_redeem_percentage) {
                    defaultValue.advance_settings = true;
                }
            }
            if (defaultValue.advance_settings) {
                setIsNavOpen(true);
            }

            if (defaultValue?.redeem_for_all_modes) {
                setCardDescription((prevState) => [
                    ...prevState,
                    `${defaultValue.redeem_for_all_modes} Redeem for ${
                        defaultValue.is_same_config ? ` all modes ` : `${firstOrderingMode?.[0]?.type}`
                    } | 100% Cashback Enable`,
                ]);
            }
            if (defaultValue.promo_consolidation) {
                setCardDescription((prevState) => [...prevState, 'Promo Consolidation On']);
            } else {
                setCardDescription((prevState) => [...prevState, 'Promo Consolidation Off']);
            }
        }

        reset({
            ...defaultValue,
        });
    }, [reset, restaurantResponse.restaurant]);

    useEffect(() => {
        if (restaurantResponse?.restaurant) {
            resetChanges();
        }
    }, [restaurantResponse, resetChanges]);

    const onSubmit = useCallback(
        (data) => {
            if (data.tabOrderingModes?.length && data?.ordering_mode_cashback_configurations?.length) {
                for (let ordering_mode_cashback_configuration of data?.ordering_mode_cashback_configurations) {
                    for (const tabOrderingMode of data.tabOrderingModes) {
                        if (
                            (tabOrderingMode.tab === 'delivery' || tabOrderingMode.tab === 'takeaway') &&
                            tabOrderingMode.restaurant_ordering_mode_id ===
                                ordering_mode_cashback_configuration.restaurant_ordering_mode_id
                        ) {
                            ordering_mode_cashback_configuration.cashback_redeem_percentage =
                                tabOrderingMode.cashback_redeem_percentage;
                        } else if (
                            tabOrderingMode.tab === 'dine_in' &&
                            ordering_mode_cashback_configuration.tab === 'dine_in'
                        ) {
                            ordering_mode_cashback_configuration.cashback_redeem_percentage =
                                tabOrderingMode.cashback_redeem_percentage;
                        }
                    }
                }
            }

            if (data.is_same_config) {
                for (const cash_back_modes of data.ordering_mode_cashback_configurations) {
                    cash_back_modes.cashback_redeem_percentage = data.redeem_for_all_modes;
                }
            }
            if (!data.advance_settings) {
                for (const configCashback of data.ordering_mode_cashback_configurations) {
                    configCashback.cashback_redeem_percentage = 100;
                }
                data.promo_consolidation = false;
            }

            dispatch(restaurantSettingsAPI('cashback_settings', data)).then((res) => {
                if (res.success) {
                    dispatch({
                        type: types.SET_PORTAL_ACTIVE,
                        payload: {
                            portalActive: {},
                        },
                    });
                    dispatch({
                        type: types.SET_PORTAL_ACTIVE_TAB_SWITCHED,
                        payload: {
                            tabSwitched: false,
                        },
                    });
                    fetchRestaurant();
                } else {
                    alert(res.message);
                }
            });
        },
        [dispatch, fetchRestaurant]
    );

    const validatePercentage = useCallback((value) => {
        return value <= 100 || 'Percentage must be less than or quals to 100';
    }, []);

    return (
        <>
            <EuiForm component='form' onSubmit={handleSubmit(onSubmit)}>
                <FormGroupDescription
                    title={restaurantDescriptions?.cashback_settings?.display_name}
                    description={restaurantDescriptions?.cashback_settings?.description}
                >
                    <ButtonGroup
                        idSelected={watch('enable_reward_points_functionality') ? '1' : '0'}
                        color='primary'
                        control={control}
                        setValue={setValue}
                        name='enable_reward_points_functionality'
                        watch={watch}
                        label={'Enable Cashback'}
                    />
                    {watch('enable_reward_points_functionality') ? (
                        <>
                            <NumberField
                                label='Cashback Earning Percentage'
                                errors={errors}
                                append='%'
                                name='cashback_earning_percentage'
                                placeholder='Please Enter Cashback'
                                rules={{
                                    required: 'Please Enter Cashback',
                                    validate: validatePercentage,
                                }}
                                step='any'
                                control={control}
                            />

                            <NumberField
                                label='Cashback expiration duration'
                                errors={errors}
                                name='reward_point_expiration_duration'
                                placeholder='Please Enter duration'
                                rules={{
                                    required: 'Please enter duration',
                                }}
                                // step='any'
                                control={control}
                                prepend={'In days'}
                            />
                            <NumberField
                                label='Cashback on signup'
                                errors={errors}
                                name='signup_cashback'
                                placeholder='Please Enter Cashback'
                                rules={{
                                    required: 'Please Enter Cashback',
                                }}
                                append={'₹'}
                                step='any'
                                control={control}
                            />
                            <EuiSpacer />

                            <React.Fragment>
                                <EuiCard
                                    layout='horizontal'
                                    title={
                                        <EuiFlexGroup
                                            direction='row'
                                            justifyContent='spaceBetween'
                                            alignItems='center'
                                            gutterSize='xs'
                                        >
                                            <EuiFlexItem grow={10}>
                                                <EuiFlexGroup
                                                    justifyContent='flexStart'
                                                    alignItems='center'
                                                    direction='row'
                                                    gutterSize='s'
                                                >
                                                    {props?.icon ? (
                                                        <EuiFlexItem grow={false}>{props.icon}</EuiFlexItem>
                                                    ) : null}
                                                    <EuiFlexItem grow={10}>
                                                        <EuiFlexGroup
                                                            justifyContent='flexStart'
                                                            direction='column'
                                                            alignItems='flexStart'
                                                            gutterSize='xs'
                                                        >
                                                            <EuiFlexItem>
                                                                <EuiFlexGroup
                                                                    direction='row'
                                                                    justifyContent='flexStart'
                                                                    alignItems='center'
                                                                    gutterSize='s'
                                                                >
                                                                    <EuiFlexItem>
                                                                        <EuiTitle size='s'>
                                                                            <h2>Advance Settings</h2>
                                                                        </EuiTitle>
                                                                    </EuiFlexItem>
                                                                    <EuiFlexItem grow={false}>
                                                                        {!isNavOpen ? (
                                                                            <EuiLink
                                                                                style={{ fontSize: '12px' }}
                                                                                onClick={() => {
                                                                                    setIsNavOpen(
                                                                                        (prevState) => !prevState
                                                                                    );
                                                                                }}
                                                                            >
                                                                                <SwitchField
                                                                                    name='advance_settings'
                                                                                    control={control}
                                                                                    label=''
                                                                                    setValue={setValue}
                                                                                />
                                                                            </EuiLink>
                                                                        ) : null}
                                                                    </EuiFlexItem>
                                                                </EuiFlexGroup>
                                                            </EuiFlexItem>
                                                            <EuiFlexItem>
                                                                {cardDescription?.map((description, idx) => (
                                                                    <EuiText
                                                                        style={{ display: 'inline-flex' }}
                                                                        size='xs'
                                                                        key={idx}
                                                                    >
                                                                        {description}
                                                                    </EuiText>
                                                                ))}
                                                            </EuiFlexItem>
                                                        </EuiFlexGroup>
                                                    </EuiFlexItem>
                                                </EuiFlexGroup>
                                            </EuiFlexItem>
                                            <EuiFlexItem>
                                                {isNavOpen ? (
                                                    <EuiFlexItem
                                                        style={{ cursor: 'pointer' }}
                                                        onClick={() => {
                                                            setIsNavOpen((prevState) => !prevState);
                                                            setValue('advance_settings', false, { shouldDirty: true });
                                                        }}
                                                    >
                                                        <EuiIcon type='cross' color='danger' />
                                                    </EuiFlexItem>
                                                ) : null}
                                            </EuiFlexItem>
                                        </EuiFlexGroup>
                                    }
                                >
                                    <EuiCollapsibleNavGroup
                                        style={{ marginBottom: isNavOpen ? '0' : '-35px' }}
                                        title={<></>}
                                        titleSize='xs'
                                        isCollapsible={true}
                                        paddingSize='none'
                                        initialIsOpen={isNavOpen}
                                        forceState={isNavOpen ? 'open' : 'closed'}
                                        arrowDisplay={'none'}
                                    >
                                        <CheckBoxField
                                            name='is_same_config'
                                            control={control}
                                            label='Same Configurations for all modes'
                                            setValue={setValue}
                                        />
                                        <EuiSpacer />
                                        {!watch('is_same_config') ? (
                                            <>
                                                {/* {watch('ordering_mode_cashback_configurations')?.map( */}
                                                {tabOrderingModes?.map((orderingMode, index) => {
                                                    return (
                                                        <NumberField
                                                            labelStyles={{ textTransform: 'capitalize' }}
                                                            key={orderingMode.restaurant_ordering_mode_id.toString()}
                                                            label={`Redeem cashback for  ${
                                                                orderingMode.tab?.includes('_')
                                                                    ? orderingMode.tab.replace('_', ' ')
                                                                    : orderingMode.tab
                                                            }`}
                                                            errors={errors}
                                                            append='%'
                                                            // name={`ordering_mode_cashback_configurations[${index}].cashback_redeem_percentage`}
                                                            name={`tabOrderingModes[${index}].cashback_redeem_percentage`}
                                                            defaultValue={`${orderingMode?.cashback_redeem_percentage}`}
                                                            placeholder='Please Enter Cashback'
                                                            rules={{
                                                                required: 'Please Enter Cashback',
                                                                validate: validatePercentage,
                                                            }}
                                                            step='any'
                                                            control={control}
                                                        />
                                                    );
                                                })}
                                            </>
                                        ) : (
                                            <NumberField
                                                label={'Redeem cashback'}
                                                errors={errors}
                                                name={`redeem_for_all_modes`}
                                                placeholder='Please Enter Cashback'
                                                rules={{
                                                    required: 'Please Enter Cashback',
                                                    validate: validatePercentage,
                                                }}
                                                append='%'
                                                step='any'
                                                control={control}
                                            />
                                        )}
                                        <ButtonGroup
                                            idSelected={watch('promo_consolidation') ? '1' : '0'}
                                            color='primary'
                                            control={control}
                                            setValue={setValue}
                                            name='promo_consolidation'
                                            watch={watch}
                                            label={'Consolidate other offers with cashback'}
                                        />
                                    </EuiCollapsibleNavGroup>
                                </EuiCard>
                            </React.Fragment>
                        </>
                    ) : (
                        ''
                    )}
                </FormGroupDescription>
            </EuiForm>
        </>
    );
};

export default React.memo(CashbackSettings);
