import React from 'react';
import { MainLayout } from '../../components/layout/mainLayout/mainLayout';
import { UserComponent } from '../../components/userComponents/userComponent';

export default () => {
    return (
        <MainLayout title={'User Settings'}>
            <UserComponent />
        </MainLayout>
    );
};
