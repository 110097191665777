import moment from 'moment';
import * as types from '../types/orders';

const initialState = {
    liveOrders: [],
    pastOrders: [],
    failedOrders: [],
    abandonedCarts: [],
    liveOrderState: {},
    currentOrderState: 'all',
    currentOrderingMode: 'all',
    ordersCount: {
        all: 0,
        placed: 0,
        restaurant_accepted: 0,
        restaurant_ready: 0,
        rider_picked_up: 0,
    },
    selectedOrderId: 0,
    tableLoader: { loader: false, error: false },
    newOrders: 0,
    selectedOrderDetails: {},
    orderDetailsLoader: { loader: true, error: false },
    date: { startDate: moment(), endDate: moment() },
    orderCurrentPage: { current_page: 0, page_size: 10 },
};

export default (state = initialState, action) => {
    switch (action.type) {
        case types.SET_LIVE_ORDERS:
            return {
                ...state,
                liveOrders: action.payload,
            };
        case types.SET_PAST_ORDERS:
            return {
                ...state,
                pastOrders: action.payload,
            };

        case types.SET_FAILED_ORDERS:
            return {
                ...state,
                failedOrders: action.payload,
            };

        case types.SET_ABANDONED_CARTS:
            return {
                ...state,
                abandonedCarts: action.payload,
            };
        case types.SET_LIVE_ORDER_STATE:
            return {
                ...state,
                liveOrderState: action.payload,
            };
        case types.SET_CURRENT_ORDER_STATE:
            return {
                ...state,
                currentOrderState: action.payload,
            };
        case types.SET_CURRENT_ORDER_MODE:
            return {
                ...state,
                currentOrderingMode: action.payload,
            };
        case types.SET_ORDERS_COUNT:
            return {
                ...state,
                ordersCount: action.payload,
            };
        case types.SET_SELECTED_ORDER:
            return {
                ...state,
                selectedOrderId: action.payload,
            };
        case types.SET_TABLE_LOADER:
            return {
                ...state,
                tableLoader: action.payload,
            };
        case types.SET_NEW_ORDERS:
            return {
                ...state,
                newOrders: action.payload,
            };
        case types.SET_SELECTED_ORDER_DETAILS:
            return {
                ...state,
                selectedOrderDetails: action.payload,
            };
        case types.SET_ORDER_DETAILS_LOADER:
            return {
                ...state,
                orderDetailsLoader: action.payload,
            };
        case types.SET_ORDER_FILTER_DATE:
            return {
                ...state,
                date: action.payload,
            };
        case types.SET_ORDER_CURRENT_PAGE:
            return {
                ...state,
                orderCurrentPage: action.payload,
            };
        default:
            return state;
    }
};
