import { EuiDatePicker, EuiFlexGroup, EuiFlexItem, EuiFormRow, EuiRadioGroup, EuiSpacer } from '@elastic/eui';
import { isEmpty } from 'lodash';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { Controller, useForm, useWatch, FormProvider } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import APIV3 from '../../../api/axios/APIV3';
import { outletSettingsV4 } from '../../../api/outlet/outletSettingsAPI';
import { userHasPermission } from '../../../helper/auth';
import NumberField from '../../../components/Form/NumberField';
import SwitchField from '../../../components/Form/SwitchField';
import VariableDiscount from '../../../components/settings/outlet-form/VariableDiscount';
import { fetchOutletOrderingModes } from '../../../api/outlet/fetchOutletOrderingModes';
import ConvenienceCharge from '../../../components/settings/outlet-form/ConvenienceCharge';

const BillPaymentConfiguration = () => {
    const {
        control,
        handleSubmit,
        formState: { errors, isDirty },
        setValue,
        reset,
        watch,
    } = useForm();
    const dispatch = useDispatch();
    const methods = {
        control,
        handleSubmit,
        formState: { errors, isDirty },
        setValue,
        reset,
        watch,
    };
    const permissions = useSelector((state) => state.permission.permission);
    const isBillPaymentEnable = useWatch({ control, name: 'is_bill_payment_enabled' });
    const isFixedAmountSelected = useWatch({ control, name: 'type' }) === 'flat';
    const outletId = useSelector((state) => state.outlet.selectedOutletId);
    const [offerConfigurations, setOfferConfigurations] = useState({});
    const outletSettings = useSelector((state) => state?.outlet?.outletOrderingModes?.settings);

    const resetChanges = useCallback(() => {
        const data = offerConfigurations;
        reset({
            fixed_discount: data?.fixed_discount ? data.fixed_discount : null,
            minimum_payment_amount: data.minimum_payment_amount ? data.minimum_payment_amount : null,
            discount_upto_amount: data.discount_upto_amount,
            custom_discount_type: outletSettings?.is_bill_payment_enabled
                ? data?.discount_below_threshold || data?.discount_below_threshold || data?.threshold_amount
                    ? 'variable_discount'
                    : 'simple_discount'
                : 'simple_discount',
            discount_below_threshold: data.discount_below_threshold ? data.discount_below_threshold : null,
            discount_above_threshold: data.discount_above_threshold ? data.discount_above_threshold : null,
            threshold_amount: data.threshold_amount ? data.threshold_amount : null,
            is_bill_payment_enabled: outletSettings?.is_bill_payment_enabled,
            bill_payment_convenience_charge_type: outletSettings?.bill_payment_convenience_charge_type
                ? outletSettings?.bill_payment_convenience_charge_type
                : 'percentage_charge',
            bill_payment_convenience_charge: outletSettings?.bill_payment_convenience_charge,
            is_convenience_charge_enable: outletSettings?.bill_payment_convenience_charge > 0 ? true : false,
            amount: data.amount,
            type: data?.type ? data.type : 'percentage',
            is_discount_enable: data.type,
            max_usage_count: data.max_usage_count,
            max_usage_count_per_user: data.max_usage_count_per_user,
            validate_from: data.validate_from ? moment(data.validate_from) : null,
            validate_to: data.validate_to ? moment(data.validate_to) : null,
        });
    }, [offerConfigurations, reset, outletSettings]);

    const onSubmit = useCallback(
        async (data) => {
            data.validate_from = data.validate_from ? moment(data.validate_from).format('YYYY-MM-DD') : null;
            data.validate_to = data.validate_to ? moment(data.validate_to).format('YYYY-MM-DD') : null;
            if (!data.is_convenience_charge_enable) {
                data.bill_payment_convenience_charge = 0;
                delete data?.bill_payment_convenience_charge_type;
            }
            if (data.is_discount_enable && data.is_bill_payment_enabled) {
                if (isEmpty(offerConfigurations)) {
                    // create offer
                    await APIV3.post(`/restaurants/:restaurantId/outlets/${outletId}/bill-payment-offers`, data);
                } else {
                    // update offer
                    await APIV3.put(
                        `/restaurants/:restaurantId/outlets/${outletId}/bill-payment-offers/${offerConfigurations.id}`,
                        data
                    );
                }
                // update the payment configurations
            }

            if (data.is_discount_enable) {
                // enable bill payment
                await dispatch(outletSettingsV4('bill_payment_offer_settings', data));
            } else {
                await dispatch(outletSettingsV4('bill_payment_offer_settings', data));
                if (!isEmpty(offerConfigurations)) {
                    // delete offer
                    await APIV3.delete(
                        `/restaurants/:restaurantId/outlets/${outletId}/bill-payment-offers/${offerConfigurations.id}`
                    );
                }
            }

            // if (data.is_bill_payment_enabled) {
            dispatch(
                outletSettingsV4('bill_payment_offer_settings', {
                    is_bill_payment_enabled: data.is_bill_payment_enabled,
                    bill_payment_convenience_charge_type: data.bill_payment_convenience_charge_type,
                    bill_payment_convenience_charge: data.bill_payment_convenience_charge,
                })
            );
            dispatch(fetchOutletOrderingModes(outletId, 'bill_payment_offer_settings'));
            // }

            fetchPaymentConfigurations();

            dispatch({
                type: 'set-portal-active',
                payload: {
                    portalActive: {},
                },
            });
            dispatch({
                type: 'set-portal-active-tab-switched',
                payload: {
                    tabSwitched: false,
                },
            });
        },
        [dispatch, offerConfigurations, outletId]
    );

    const fetchPaymentConfigurations = useCallback(async () => {
        await APIV3.get(`restaurants/:restaurantId/outlets/${outletId}/bill-payment-offers`).then((response) => {
            if (!isEmpty(response?.outlet_bill_payment_offers?.[0])) {
                setOfferConfigurations({ ...response?.outlet_bill_payment_offers?.[0] });
            } else {
                setOfferConfigurations({});
            }
        });
    }, [outletId]);

    useEffect(() => {
        if (offerConfigurations) {
            resetChanges();
        }
    }, [offerConfigurations, resetChanges, outletId]);

    useEffect(() => {
        if (outletSettings.is_bill_payment_enabled) {
            fetchPaymentConfigurations();
        } else {
            setOfferConfigurations({});
        }
    }, [outletSettings.is_bill_payment_enabled, fetchPaymentConfigurations, outletId]);

    useEffect(() => {
        if (!userHasPermission(permissions, '/outlet-settings', 'write')) return;

        if (isDirty) {
            dispatch({
                type: 'set-portal-active',
                payload: {
                    portalActive: {
                        resetChanges: resetChanges,
                        submit: handleSubmit(onSubmit),
                    },
                },
            });
        } else {
            dispatch({
                type: 'set-portal-active',
                payload: {
                    portalActive: {},
                },
            });
            dispatch({
                type: 'set-portal-active-tab-switched',
                payload: {
                    tabSwitched: false,
                },
            });
        }
    }, [isDirty]);

    useEffect(() => {
        if (!isFixedAmountSelected) {
            setValue('fixed_discount', 0);
        }
    }, [isFixedAmountSelected, setValue]);

    return (
        <FormProvider {...methods}>
            <SwitchField
                label='Enable/Disable Bill Payment'
                setValue={setValue}
                control={control}
                name='is_bill_payment_enabled'
            />
            <EuiSpacer />
            {isBillPaymentEnable ? (
                <>
                    <SwitchField
                        label='Enable/Disable Discount'
                        setValue={setValue}
                        control={control}
                        name='is_discount_enable'
                    />
                    {watch('is_discount_enable') ? (
                        <EuiFlexItem style={{ marginLeft: '24px' }}>
                            <EuiFlexItem grow={false} style={{ flexGrow: false }}>
                                <EuiSpacer />
                                <Controller
                                    control={control}
                                    name='custom_discount_type'
                                    rules={{
                                        required: 'Please select any option to continue',
                                    }}
                                    render={({ field }) => (
                                        <EuiFormRow
                                            isInvalid={errors['custom_discount_type']}
                                            error={
                                                errors['custom_discount_type']
                                                    ? errors['custom_discount_type'].message
                                                    : ''
                                            }
                                        >
                                            <EuiRadioGroup
                                                options={[
                                                    { id: 'simple_discount', label: 'Simple Discount' },
                                                    { id: 'variable_discount', label: 'Variable Discount' },
                                                ]}
                                                idSelected={field.value}
                                                onChange={field.onChange}
                                                legend={{
                                                    children: <span> {'Select a Customizable type'} </span>,
                                                }}
                                            />
                                        </EuiFormRow>
                                    )}
                                />

                                <EuiSpacer />
                                {watch('custom_discount_type') === 'variable_discount' ? <VariableDiscount /> : null}
                            </EuiFlexItem>
                            <Controller
                                control={control}
                                name='type'
                                rules={{
                                    required: 'Please select any option to continue',
                                }}
                                render={({ field }) => (
                                    <EuiFormRow
                                        isInvalid={errors['type']}
                                        error={errors['type'] ? errors['type'].message : ''}
                                    >
                                        <EuiRadioGroup
                                            options={[
                                                { id: 'percentage', label: 'Percentage Discount' },
                                                { id: 'flat', label: 'Fixed Discount' },
                                            ]}
                                            idSelected={field.value}
                                            onChange={field.onChange}
                                            legend={{
                                                children: <span> {'Select a type of Discount'} </span>,
                                            }}
                                        />
                                    </EuiFormRow>
                                )}
                            />
                            {watch('type') === 'percentage' ? (
                                <>
                                    <EuiSpacer />
                                    <NumberField
                                        errors={errors}
                                        label='Percentage Amount'
                                        control={control}
                                        // name='percentage_discount'
                                        name='amount'
                                        rules={{ required: 'Please Enter Percentage Amount' }}
                                        placeholder='Enter Percentage Discount'
                                    />
                                </>
                            ) : null}
                            {isFixedAmountSelected ? (
                                <>
                                    <EuiSpacer />
                                    <NumberField
                                        errors={errors}
                                        label='Fixed Amount'
                                        control={control}
                                        // name='fixed_discount'
                                        name='amount'
                                        placeholder='Enter Fixed Discount'
                                        rules={{
                                            required: 'Please Enter Fixed Discount',
                                            validate: () => watch('amount') > 0 || 'Amount Should be greate than 0',
                                        }}
                                    />
                                </>
                            ) : null}

                            <NumberField
                                errors={errors}
                                label='Minimum Amount'
                                control={control}
                                name='minimum_payment_amount'
                                placeholder='Enter Minimum Amount'
                                rules={{
                                    validate: (value) => {
                                        if (watch('threshold_amount')) {
                                            return (
                                                watch('threshold_amount') >= parseInt(value) ||
                                                `Amount Shouldn't be more than Threshold Amount`
                                            );
                                        }
                                    },
                                }}
                            />
                            <NumberField
                                errors={errors}
                                label='Discount Upto Amount'
                                control={control}
                                name='discount_upto_amount'
                                placeholder='Enter Discount Upto Amount'
                            />
                            <NumberField
                                errors={errors}
                                label='Max Usage Count'
                                control={control}
                                name='max_usage_count'
                                placeholder='Enter Max Usage Count'
                            />
                            <NumberField
                                errors={errors}
                                label='Max Usage Count Per User'
                                control={control}
                                name='max_usage_count_per_user'
                                placeholder='Enter Max Usage Count Per User'
                            />
                            <EuiSpacer />
                            <EuiFlexGroup style={{ marginBottom: '' }}>
                                <EuiFlexItem>
                                    <EuiFormRow
                                        label='Coupon valid from'
                                        fullWidth={true}
                                        isInvalid={errors.validate_from}
                                        error={errors.validate_from ? errors.validate_from.message : null}
                                    >
                                        <Controller
                                            render={({ field }) => (
                                                <EuiDatePicker
                                                    selected={field.value}
                                                    onChange={(value) => field.onChange(value)}
                                                    popperPlacement='bottom'
                                                    style={{ zIndex: 10000 }}
                                                />
                                            )}
                                            name='validate_from'
                                            control={control}
                                            defaultValue={watch('validate_from') ? watch('validate_from') : null}
                                            placeholder='select starting date'
                                            isInvalid={errors.validate_from}
                                            fullWidth={true}
                                        />
                                    </EuiFormRow>
                                </EuiFlexItem>
                                <EuiFlexItem>
                                    <EuiFormRow
                                        label='Coupon valid till'
                                        fullWidth={true}
                                        isInvalid={errors.validate_to}
                                        error={errors.validate_to ? errors.validate_to.message : ''}
                                    >
                                        <Controller
                                            render={({ field }) => (
                                                <EuiDatePicker
                                                    selected={field.value}
                                                    onChange={(value) => field.onChange(value)}
                                                />
                                            )}
                                            name='validate_to'
                                            control={control}
                                            defaultValue={watch('validate_to') ? watch('validate_to') : null}
                                            placeholder='select ending date'
                                            isInvalid={errors.validate_to}
                                            fullWidth={true}
                                        />
                                    </EuiFormRow>
                                </EuiFlexItem>
                            </EuiFlexGroup>
                        </EuiFlexItem>
                    ) : null}
                    <ConvenienceCharge />
                </>
            ) : null}
        </FormProvider>
    );
};

export default React.memo(BillPaymentConfiguration);
