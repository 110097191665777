import React from 'react';
import { MainLayout } from '../../components/layout/mainLayout/mainLayout';
import BannerEditComponent from '../../components/banner/bannerEdit/bannerEditComponent';

const BannerModificationPage = () => {
    return (
        <>
            <MainLayout title='Banner' historyBack={true}>
                <BannerEditComponent />
            </MainLayout>
        </>
    );
};

export default BannerModificationPage;
