import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import ComboBoxField from '../../../components/Form/ComboBoxField';
import { currencyData } from '../../../components/settings/countrySymbolAndInforamtion';
import FormGroupDescription from '../../../components/formGroupDescription';
import { EuiForm } from '@elastic/eui';
import { useDispatch, useSelector } from 'react-redux';
import { outletSettingsAPI } from '../../../api/outlet/outletSettingsAPI';
import * as types from '../../../reduxStore/types/outlet';
import { userHasPermission } from '../../../helper/auth';

const CurrencySettings = (props) => {
    const { getOutletData, fetchOutletById } = props;
    const {
        handleSubmit,
        control,
        watch,
        reset,
        setValue,

        formState: { isDirty, errors },
    } = useForm();
    const dispatch = useDispatch();

    const permissions = useSelector((state) => state.permission.permission);
    const restaurantDescriptions = useSelector((state) => state.auth.restaurantDescriptions);

    useEffect(() => {
        if (!userHasPermission(permissions, '/outlet-settings', 'write')) return;

        if (isDirty) {
            dispatch({
                type: 'set-portal-active',
                payload: {
                    portalActive: {
                        resetChanges: resetChanges,
                        submit: handleSubmit(onSubmit),
                    },
                },
            });
        } else {
            dispatch({
                type: 'set-portal-active',
                payload: {
                    portalActive: {},
                },
            });
        }
    }, [isDirty]);

    const resetChanges = () => {
        let defaultValue = {};
        defaultValue.currency_symbol = [
            {
                value: getOutletData.outlet.settings.currency_symbol,
                label: `${getOutletData.outlet.settings.currency_symbol} - ${getOutletData.outlet.settings.currency_code}`,
            },
        ];

        reset({
            ...defaultValue,
        });
    };

    useEffect(() => {
        if (getOutletData && getOutletData.outlet) {
            resetChanges();
        }
    }, [getOutletData]);

    let currencyDataSymbol = [];
    let currencyDataCode = [];

    for (const key in currencyData) {
        let value1 = currencyData[key];
        currencyDataSymbol.push({
            value: value1.symbol_native,
            label: `${value1.name} - ${value1.code} - ${value1.symbol_native} `,
        });
        // - ${value1.symbol}
    }

    // for (const key in currencyData) {
    //     let value1 = currencyData[key];
    //     currencyDataCode.push({
    //         value: value1.code,
    //         label: value1.code,
    //     });
    // }

    const onSubmit = (data) => {
        console.log('currency data ', data);
        data.currency_code = data.currency_symbol[0].label.split('-')[1].trim();
        data.currency_symbol = data.currency_symbol[0].value;
        dispatch(outletSettingsAPI('currency_settings', data)).then((res) => {
            if (res.success) {
                dispatch({
                    type: types.SET_PORTAL_ACTIVE,
                    payload: {
                        portalActive: {},
                    },
                });
                dispatch({
                    type: types.SET_PORTAL_ACTIVE_TAB_SWITCHED,
                    payload: {
                        tabSwitched: false,
                    },
                });
                fetchOutletById();
            } else {
                alert(res.message);
            }
        });
    };

    return (
        <>
            <EuiForm component='form' onSubmit={handleSubmit(onSubmit)}>
                <FormGroupDescription
                    title={restaurantDescriptions?.currency?.display_name}
                    description={restaurantDescriptions?.currency?.description}
                >
                    <ComboBoxField
                        options={currencyDataSymbol}
                        selectedOptions={watch('currency_symbol') ? watch('currency_symbol') : []}
                        onChange={(value) => setValue('currency_symbol', value)}
                        errors={errors}
                        label='Currency Symbol'
                        name='currency_symbol'
                        placeholder='Please enter your currency symbol'
                        rules={{
                            validate: (value) =>
                                (watch('currency_symbol') && watch('currency_symbol').length > 0) ||
                                'Please select currency symbol',
                        }}
                        control={control}
                        singleSelection={{ asPlainText: true }}
                    />
                    {/* <ComboBoxField
                        options={currencyDataCode}
                        selectedOptions={watch('currency_code') ? watch('currency_code') : []}
                        onChange={(value) => {
                            setValue('currency_code', value);
                            delete errors['currency_code'];
                        }}
                        errors={errors}
                        label='Currency Code'
                        name='currency_code'
                        placeholder='Please enter your currency code'
                        rules={{
                            validate: (value) =>
                                (watch('currency_code') && watch('currency_code').length > 0) || 'Please select currency code',
                        }}
                        control={control}
                        singleSelection={{ asPlainText: true }}
                    /> */}
                    {/* {
                        isDirty ? (
                            <EuiFormRow>
                                <EuiFlexGroup alignItems="center">
                                    <LoaderButton isLoading={true}/>
                                    <EuiFlexItem>
                                        <EuiButtonEmpty onClick={resetChanges}>Discard changes</EuiButtonEmpty>
                                    </EuiFlexItem>
                                </EuiFlexGroup>
                            </EuiFormRow>
                        ): ''
                    } */}
                </FormGroupDescription>
            </EuiForm>
        </>
    );
};

export default CurrencySettings;
