import React, { memo, useEffect, useState, useCallback, useMemo } from 'react';
import {
    EuiDragDropContext,
    EuiDroppable,
    EuiDraggable,
    euiDragDropReorder,
    EuiFlexItem,
    EuiText,
    EuiIcon,
    EuiButtonEmpty,
    EuiPanel,
    EuiFlexGroup,
    EuiToolTip,
    EuiLink,
} from '@elastic/eui';
import { useSelector, useDispatch } from 'react-redux';
import { userHasPermission } from '../../../helper/auth';
import { useHistory } from 'react-router-dom';
import { agmarkSign } from '../../../helper/agmarkSign';
import * as type from '../../../reduxStore/types/menu';
import ShiftItemsFlyout from './shiftItemsFlyout';
import LoadingOverlay from 'react-loading-overlay';
import { ReactComponent as MapItemIcon } from '../../../assets/img/svg/MapItemIcon.svg';
import MapItemList from './MapItemList';
import { SET_ACTIVE_ITEM_DETAILS, SET_ACTIVE_ITEM_ID } from '../Store/MenuTypes';
import {
    fetchActiveItemDetails,
    fetchCategoryItem,
    fetchOutletCategoryItem,
    updateItemsSequence,
    updateItemsSequenceForOulet,
} from './Api/FetchCatalogueInfo';
import MappedItemModal from '../menuEditorTab/MappedItemModal';

const ItemList = React.memo(({ category, isOutletMenu, itemList, setListDetails }) => {
    const selectedOutletId = useSelector((state) => state.outlet.selectedOutletId);
    const permissions = useSelector((state) => state.permission.permission);
    const languageId = useSelector((state) => state.language.languageId);
    const activeItemId = useSelector((state) => state.menu.activeItemId);
    const themeMode = useSelector((state) => state.theme.theme);
    const newIdItemDelete = useSelector((state) => state.menu.newIdItemDelete);
    const [itemListState, setItemListState] = useState(itemList);
    const [loaderOverlay, setLoaderOverlay] = useState(false);
    const [flyoutState, setFlyoutState] = useState({ isOpen: false, action: '' });
    const [mapItemsFlyout, setMapItemsFlyout] = useState(false);
    const history = useHistory();
    const dispatch = useDispatch();
    const [mapItemModal, setIsMapItemModal] = useState(false);
    const handleMapItemModal = useCallback(() => {
        setIsMapItemModal((prevState) => !prevState);
    }, []);

    useEffect(() => {
        if (itemListState.length && itemListState[itemListState.length - 1].item_id == 'newId') {
            let innerArray = [...itemListState];
            innerArray.splice(itemListState.length - 1, 1);
            setItemListState(innerArray);
            dispatch({
                type: type.EDIT_ITEM,
                payload: {
                    editItem: {},
                },
            });
        }
    }, [newIdItemDelete]);

    const checkNewIdExists = useCallback(() => {
        let boolValue = false;
        itemListState.map((item) => {
            if (item.item_id == 'newId') {
                boolValue = true;
            }
        });

        return boolValue;
    }, [itemListState]);

    const handleItemClick = useCallback(
        (id) => {
            if (activeItemId != id) {
                dispatch({
                    type: type.SET_ACTIVE_ITEM_ID,
                    payload: {
                        activeItemId: id,
                    },
                });

                dispatch({
                    type: SET_ACTIVE_ITEM_ID,
                    payload: id,
                });

                dispatch({
                    type: type.SET_ACTIVE_CATEGORY_ID,
                    payload: {
                        activeCategoryId: category?.category_id,
                    },
                });
                if (id !== 'newId') {
                    dispatch({
                        type: SET_ACTIVE_ITEM_DETAILS,
                        payload: { isLoading: true },
                    });
                    fetchActiveItemDetails(id)
                        .then((response) => {
                            if (response.success) {
                                const item = response.item;
                                dispatch({
                                    type: SET_ACTIVE_ITEM_DETAILS,
                                    payload: { details: item, isLoading: false },
                                });
                                dispatch({
                                    type: type.EDIT_ITEM,
                                    payload: {
                                        editItem: item,
                                    },
                                });
                                dispatch({
                                    type: type.SET_ADDON_ITEM_EDIT_SELECTED_LIST,
                                    payload: {
                                        selectedItemEditAddOnList: item.addon_groups?.length ? item.addon_groups : [],
                                    },
                                });
                                dispatch({
                                    type: type.SET_UPSELLING_ITEM_EDIT_SELECTED_LIST,
                                    payload: {
                                        selectedItemEditUpsellingList: item.upselling_items?.length
                                            ? item.upselling_items
                                            : [],
                                    },
                                });
                            }
                        })
                        .catch((error) => {
                            //todo: handle error
                        });
                }
            }
        },
        [activeItemId, category?.category_id, dispatch]
    );

    const renderRows = useCallback(() => {
        return itemListState?.length
            ? itemListState.map((item, idx) => {
                  return (
                      <EuiDraggable
                          spacing='m'
                          key={item.item_id}
                          index={idx}
                          draggableId={item.item_id.toString()}
                          customDragHandle={true}
                          id={idx}
                      >
                          {(provided) => (
                              <EuiPanel
                                  id={item.internal_name}
                                  //   onClick={() => handleItemClick(item.item_id)}
                                  style={{
                                      display: 'flex',
                                      flexDirection: 'row',
                                      cursor: 'pointer',
                                      transform: `translateY(0)`,
                                      backgroundColor:
                                          item.item_id.toString() === activeItemId?.toString()
                                              ? themeMode === 'light'
                                                  ? '#c4d1fe'
                                                  : '#c4d1fe4d'
                                              : 'transparent',
                                  }}
                              >
                                  {item.is_mapped_item ? (
                                      <MappedItemModal
                                          item={item}
                                          mapItemModal={mapItemModal}
                                          handleMapItemModal={handleMapItemModal}
                                      />
                                  ) : null}
                                  {userHasPermission(permissions, history.location.pathname, 'write') && (
                                      <div
                                          style={{
                                              justifyContent: 'center',
                                              display: 'flex',
                                              width: '12%',
                                              alignItems: 'center',
                                          }}
                                      >
                                          <div {...provided.dragHandleProps} id={item.internal_name}>
                                              <EuiIcon type='grab' />
                                          </div>
                                      </div>
                                  )}
                                  <div
                                      style={{
                                          width: '68%',
                                          flexDirection: 'row',
                                          display: 'flex',
                                          alignItems: 'center',
                                      }}
                                  >
                                      <EuiFlexItem style={{ flexDirection: 'row', alignItems: 'center' }}>
                                          <div style={{ marginRight: '10px' }}>
                                              {item.food_type ? agmarkSign(item.food_type) : ''}
                                          </div>
                                          <EuiFlexGroup>
                                              <EuiFlexItem grow={false} onClick={() => handleItemClick(item.item_id)}>
                                                  <EuiText>
                                                      {item.translations
                                                          ? isOutletMenu
                                                              ? item.translations.title[languageId.toString()]
                                                              : item.internal_name
                                                          : 'New Item'}
                                                  </EuiText>
                                              </EuiFlexItem>
                                              {item.is_mapped_item ? (
                                                  <EuiFlexGroup
                                                      direction='column'
                                                      gutterSize='none'
                                                      alignItems='flexEnd'
                                                      justifyContent='flexEnd'
                                                  >
                                                      <EuiFlexItem grow={false}>
                                                          <EuiToolTip
                                                              position='top'
                                                              content={
                                                                  <>
                                                                      <EuiText>
                                                                          This Items is Mapped. Click to Unlink the
                                                                          item.
                                                                      </EuiText>
                                                                  </>
                                                              }
                                                          >
                                                              <EuiIcon type='link' />
                                                          </EuiToolTip>
                                                      </EuiFlexItem>
                                                      <EuiFlexItem>
                                                          <EuiText
                                                              size='s'
                                                              dispatch={isOutletMenu}
                                                              onClick={handleMapItemModal}
                                                          >
                                                              Unlink
                                                          </EuiText>
                                                      </EuiFlexItem>
                                                  </EuiFlexGroup>
                                              ) : null}
                                          </EuiFlexGroup>
                                      </EuiFlexItem>
                                  </div>
                              </EuiPanel>
                          )}
                      </EuiDraggable>
                  );
              })
            : '';
    }, [
        activeItemId,
        handleItemClick,
        handleMapItemModal,
        history.location.pathname,
        isOutletMenu,
        itemListState,
        languageId,
        mapItemModal,
        permissions,
        themeMode,
    ]);

    const addItem = useCallback(() => {
        dispatch({
            type: type.SET_ACTIVE_CATEGORY_ID,
            payload: {
                activeCategoryId: category?.category_id ? category.category_id : category.sub_category_id,
            },
        });
        if (!checkNewIdExists()) {
            setItemListState([...itemListState, { item_id: 'newId' }]);
            dispatch({
                type: type.SET_ACTIVE_ITEM_ID,
                payload: {
                    activeItemId: 'newId',
                },
            });
            dispatch({
                type: SET_ACTIVE_ITEM_ID,
                payload: 'newId',
            });

            dispatch({
                type: SET_ACTIVE_ITEM_DETAILS,
                payload: {
                    details: {
                        item_id: 'newId',
                    },
                },
            });
            dispatch({
                type: type.EDIT_ITEM,
                payload: {
                    editItem: { item_id: 'newId' },
                },
            });
        }
    }, [category.category_id, category.sub_category_id, checkNewIdExists, dispatch, itemListState]);

    const mapItem = useCallback(() => {
        dispatch({
            type: type.SET_ACTIVE_CATEGORY_ID,
            payload: {
                activeCategoryId: category?.category_id,
            },
        });
        setMapItemsFlyout(true);
    }, [category.category_id, dispatch]);

    const onItemsDragEnd = useCallback(
        async ({ source, destination }, items, category_id) => {
            let updatedItems = [];
            if (source && destination) {
                const itemsList = euiDragDropReorder(items, source.index, destination.index);
                setLoaderOverlay(true);
                if (!isOutletMenu) {
                    updateItemsSequence(itemsList).then((res) => {
                        if (res.success) {
                            fetchCategoryItem(category_id, languageId).then((response) => {
                                setListDetails((prevState) => ({ ...prevState, isLoading: true }));
                                if (response.success) {
                                    updatedItems.push(...response.items);
                                    setItemListState(response.items);
                                    setListDetails((prevState) => ({
                                        ...prevState,
                                        items: updatedItems,
                                        isLoading: false,
                                    }));
                                }
                            });
                        }
                        setLoaderOverlay(false);
                    });
                } else {
                    updateItemsSequenceForOulet(selectedOutletId, itemsList).then((res) => {
                        if (res.success) {
                            fetchOutletCategoryItem(category_id, languageId, selectedOutletId).then((response) => {
                                setListDetails((prevState) => ({ ...prevState, isLoading: true }));
                                if (response.success) {
                                    updatedItems.push(...response.items);
                                    setItemListState(response.items);
                                    setListDetails((prevState) => ({
                                        ...prevState,
                                        items: updatedItems,
                                        isLoading: false,
                                    }));
                                }
                            });
                        }
                        setLoaderOverlay(false);
                    });
                }
            }
        },
        [dispatch, isOutletMenu, languageId, selectedOutletId, setListDetails]
    );

    return (
        <>
            <ShiftItemsFlyout
                isOutletMenu={isOutletMenu}
                category={category}
                flyoutState={flyoutState}
                setFlyoutState={setFlyoutState}
                itemList={itemList}
                setItemListState={setItemListState}
            />

            <MapItemList
                category={category}
                mapItemsFlyout={mapItemsFlyout}
                setMapItemsFlyout={setMapItemsFlyout}
                setItemListState={setItemListState}
            />

            <EuiDragDropContext
                onDragEnd={({ source, destination }) => {
                    onItemsDragEnd({ source, destination }, itemList, category.category_id);
                }}
            >
                <EuiDroppable style={{ marginTop: '16px' }} droppableId='DROPPABLE_AREA_FOR_ITEM_LIST'>
                    <LoadingOverlay active={loaderOverlay} spinner text='Re-arranging sequence'>
                        {renderRows()}
                    </LoadingOverlay>
                </EuiDroppable>
            </EuiDragDropContext>
            {userHasPermission(permissions, history.location.pathname, 'write') && !isOutletMenu && (
                <EuiFlexGroup
                    justifyContent='flexStart'
                    alignItems='center'
                    gutterSize='xs'
                    wrap
                    direction='row'
                    responsive
                >
                    <EuiFlexItem grow={false}>
                        <EuiButtonEmpty onClick={addItem}>+ Add Item</EuiButtonEmpty>
                    </EuiFlexItem>

                    <EuiFlexItem grow={false} style={{ minWidth: '120px' }}>
                        <EuiButtonEmpty onClick={mapItem}>
                            <EuiFlexGroup gutterSize='xs' alignItems='center'>
                                <EuiFlexItem grow={false}>
                                    <MapItemIcon />
                                </EuiFlexItem>
                                <EuiFlexItem grow={false}>
                                    <EuiText>Map Item</EuiText>
                                </EuiFlexItem>
                            </EuiFlexGroup>
                        </EuiButtonEmpty>
                    </EuiFlexItem>

                    <EuiFlexItem grow={false} style={{ minWidth: '150px' }}>
                        <EuiButtonEmpty onClick={() => setFlyoutState({ isOpen: true, action: 'copy' })}>
                            + Copy Existing Item
                        </EuiButtonEmpty>
                    </EuiFlexItem>

                    <EuiFlexItem grow={false} style={{ minWidth: '150px' }}>
                        <EuiButtonEmpty onClick={() => setFlyoutState({ isOpen: true, action: 'map' })}>
                            + Move Existing Item
                        </EuiButtonEmpty>
                    </EuiFlexItem>
                </EuiFlexGroup>
            )}
        </>
    );
});

export default memo(ItemList);
