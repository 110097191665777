import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { userHasPermission } from '../../helper/auth';
import LinkOffer from '../../modules/Offers/Tabs/LinkOffer';
import OfferTable from '../../modules/Offers/Tabs/OfferTable';
import {
    EuiButton,
    EuiFlexItem,
    EuiSpacer,
    EuiPopover,
    EuiSuperSelect,
    EuiPanel,
    EuiFlexGroup,
    EuiTabs,
    EuiTab,
} from '@elastic/eui';
import { useHistory } from 'react-router-dom';
import { fetchRestaurantOrderingModes } from '../settings/RestaurantSettings/Utils/utils';

const OFFER_LIST_SCREEN = 'offer_list_screen';
const OFFER_LINK_SCREEN = 'offer_link_screen';

const Offer = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    const restaurantOrderingModes = useSelector((state) => state.restaurantReducer.orderingModes);
    const isDeliveryUnavailable = restaurantOrderingModes.filter(
        (orderingMode) => !orderingMode.status && orderingMode.type === 'delivery'
    )?.length;

    useEffect(() => {
        dispatch(fetchRestaurantOrderingModes());
    }, [dispatch]);

    const [isPopoverOpen, setIsPopoverOpen] = useState(false);

    const onChangeOffer = useCallback(
        async (value) => {
            setIsPopoverOpen(false);

            setSelectOffer(value);
            if (value !== '0') {
                history.push(`/offers/create?offerType=${value}`);
            }
        },
        [history]
    );

    const options = [
        {
            value: '0',
            inputDisplay: 'Select offer',
        },
        {
            value: 'flat_discount',
            inputDisplay: 'Flat discount',
        },
        {
            value: 'percentage_discount',
            inputDisplay: <span className='eui-textTruncate eui-displayBlock'>Percentage discount</span>,
        },
        {
            value: 'free_items',
            inputDisplay: <span className='eui-textTruncate eui-displayBlock'>Get an item</span>,
        },
        {
            value: 'buy_x_get_y',
            inputDisplay: <span className='eui-textTruncate eui-displayBlock'>Buy x get y free</span>,
        },
        {
            value: 'free_delivery',
            inputDisplay: <span className='eui-textTruncate eui-displayBlock'>Free delivery</span>,
        },
        {
            value: 'menu_discount',
            inputDisplay: <span className='eui-textTruncate eui-displayBlock'>Menu Discount</span>,
        },
        {
            value: 'buy_one_get_one_free',
            inputDisplay: <span className='eui-textTruncate eui-displayBlock'>BOGO</span>,
        },
        {
            value: 'coupled_offer',
            inputDisplay: <span className='eui-textTruncate eui-displayBlock'>Coupled Offer</span>,
        },
        {
            value: 'combo_item_price_deal',
            inputDisplay: <span className='eui-textTruncate eui-displayBlock'>Value Deal</span>,
        },
    ];

    const [selectOffer, setSelectOffer] = useState(options[0].value);

    const permissions = useSelector((state) => state.permission.permission);

    const [state, setState] = useState({
        currentTab: OFFER_LIST_SCREEN,
        activeScreen: <OfferTable />,
        loading: true,
    });

    const handleTabSelection = useCallback((label) => {
        setState((prevState) => ({
            ...prevState,
            currentTab: label,
            activeScreen: label === OFFER_LINK_SCREEN ? <LinkOffer /> : <OfferTable />,
        }));
    }, []);

    const onClosePopover = () => {
        setIsPopoverOpen(!isPopoverOpen);
        setSelectOffer(options[0].value);
    };

    return (
        <>
            <EuiPanel>
                <EuiFlexGroup justifyContent='spaceBetween'>
                    <EuiFlexItem grow={false}>
                        <EuiFlexGroup gutterSize='none'>
                            <EuiFlexItem>
                                <EuiTabs>
                                    <EuiTab
                                        isSelected={state.currentTab === OFFER_LIST_SCREEN}
                                        onClick={() => handleTabSelection(OFFER_LIST_SCREEN)}
                                        style={{ paddingRight: '18px' }}
                                    >
                                        Offer List
                                    </EuiTab>
                                </EuiTabs>
                            </EuiFlexItem>
                            <EuiFlexItem>
                                <EuiTabs>
                                    <EuiTab
                                        isSelected={state.currentTab === OFFER_LINK_SCREEN}
                                        onClick={() => handleTabSelection(OFFER_LINK_SCREEN)}
                                        style={{ paddingRight: '18px' }}
                                    >
                                        Link Offer
                                    </EuiTab>
                                </EuiTabs>
                            </EuiFlexItem>
                        </EuiFlexGroup>
                    </EuiFlexItem>
                    {userHasPermission(permissions, '/offers', 'write') && (
                        <EuiFlexItem grow={false}>
                            <EuiPopover
                                button={
                                    <EuiButton
                                        fill
                                        iconType='plusInCircleFilled'
                                        onClick={() => setIsPopoverOpen(true)}
                                    >
                                        Add Offer
                                    </EuiButton>
                                }
                                isOpen={isPopoverOpen}
                                closePopover={onClosePopover}
                            >
                                <EuiFlexItem style={{ width: 300 }}>
                                    <EuiSuperSelect
                                        options={
                                            !isDeliveryUnavailable
                                                ? options
                                                : options.filter((option) => option.value !== 'free_delivery')
                                        }
                                        value={selectOffer}
                                        onChange={onChangeOffer}
                                    />
                                </EuiFlexItem>
                            </EuiPopover>
                        </EuiFlexItem>
                    )}
                </EuiFlexGroup>
            </EuiPanel>
            <EuiSpacer />
            <EuiPanel>{state.activeScreen}</EuiPanel>
        </>
    );
};

export default React.memo(Offer);
