import React from 'react';
import { EuiToast, EuiFlexGroup, EuiFlexItem, EuiText, EuiBadge, EuiSpacer, htmlIdGenerator } from '@elastic/eui';
import { getTimeAgo } from '../../helper/getPrettyTime';
import { useSelector } from 'react-redux';
import { ReactComponent as PickUp } from '../../assets/img/pickUp.svg';
import { ReactComponent as Moped } from '../../assets/img/moped.svg';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import './style.css';
import { useDispatch } from 'react-redux';
import * as types from '../../reduxStore/types/orders';
import RupeeSymbol from '../rupeeComponent.js';
import API from '../../api/axios/API';
import { getLiveOrders } from '../../api/liveOrders/getLiveOrderNewAPI';

const OrderSummaryCard = (props) => {
    const languageId = useSelector((state) => state.language.languageId);
    const dispatch = useDispatch();
    const colorProps = props.isActive
        ? {
              color: 'primary',
          }
        : {};

    const orderMode = () => {
        if (props.orderType === 'delivery') {
            return <Moped fill={themeMode === 'light' ? 'black' : 'white'} />;
        } else {
            return <PickUp fill={themeMode === 'light' ? 'black' : 'white'} />;
        }
    };

    const orderSuperSet = (value) => {
        let modules1 = value % 10,
            modules2 = value % 100;
        if (modules1.toString() === '1' && modules2.toString() !== '11') {
            return 'st';
        }
        if (modules1.toString() === '2' && modules2.toString() !== '12') {
            return 'nd';
        }
        if (modules1.toString() === '3' && modules2.toString() !== '13') {
            return 'rd';
        }
        return 'th';
    };

    const getPaymentMode = () => {
        if (props.paymentMode === 'CASH_ON_DELIVERY') {
            return (
                <EuiBadge className='custom-badge' color='#F6830F'>
                    <span style={{ color: 'white' }}>Cash on Delivery</span>
                </EuiBadge>
            );
        } else if (props.paymentMode === 'PENDING') {
            return (
                <EuiBadge className='custom-badge' color='#BD2920'>
                    <span style={{ color: 'white' }}>Pending</span>
                </EuiBadge>
            );
        } else {
            return (
                <EuiBadge className='custom-badge' color='#0E918C'>
                    <span style={{ color: 'white' }}>Online</span>
                </EuiBadge>
            );
        }
    };
    const themeMode = useSelector((state) => state.theme.theme);

    const preparationCalculator = (time, kitchen_time) => {
        const timeInSec = parseInt(Math.abs(new Date() - new Date(time.replace(/-/g, '/'))) / 1000);
        let diff = kitchen_time * 60 - timeInSec;
        if (diff > 0) {
            return diff;
        }

        return 0;
    };

    const timerProps = {
        isPlaying: true,
        size: 100,
        strokeWidth: 6,
    };

    const orderingMode = () => {
        if (props.orderType === 'dine_in') {
            return 'DINE IN';
        }

        return props.orderType.toUpperCase();
    };

    return (
        <div key={htmlIdGenerator()()} className='order-custom-toast'>
            <EuiToast
                {...colorProps}
                type='info'
                className='orders-summary-card no-shadow border-radius-4'
                style={{ backgroundColor: themeMode === 'light' ? 'white' : 'black' }}
                // onClick={() => props.setSelected(props.orderId)}
                onClick={() =>
                    dispatch({
                        type: types.SET_SELECTED_ORDER,
                        payload: props.orderId,
                    })
                }
            >
                <>
                    <EuiFlexGroup>
                        <EuiFlexItem style={{ display: 'flex', flexDirection: 'row', marginBottom: '0px' }}>
                            <div style={{ width: '21px', marginRight: '10px', marginBottom: '0px' }}>{orderMode()}</div>
                            <EuiText>{orderingMode()}</EuiText>
                        </EuiFlexItem>
                    </EuiFlexGroup>

                    <EuiFlexGroup responsive={false} justifyContent='spaceBetween'>
                        <EuiFlexItem grow={false}>
                            <EuiText size='xs' color='subdued' className='text-uppercase'>
                                <p>
                                    {props.orderItemCount} ITEMS |{' '}
                                    {props.order && getTimeAgo(props.order.status.placed_at)}
                                </p>
                            </EuiText>
                            <EuiText size='xs' color='subdued' className='text-uppercase'>
                                Outlet: <b>{props.outlet.translations.name[languageId]}</b>
                            </EuiText>
                            <EuiText
                                size='xs'
                                color='subdued'
                                className='text-uppercase'
                                style={{ display: 'flex', flexDirection: 'row' }}
                            >
                                <span>
                                    {' '}
                                    Customer's <b>{props.customerOrderCount}</b>
                                </span>
                                <span style={{ fontSize: '70%', verticalAlign: 'super' }}>
                                    {orderSuperSet(props.customerOrderCount)}
                                </span>
                                <span> order</span>
                            </EuiText>
                            <EuiText>
                                <h2>{props.name}</h2>
                            </EuiText>
                        </EuiFlexItem>
                        <EuiFlexItem grow={false}>
                            {!props.isPast && props.orderStatus.delivery_status === 'restaurant_accepted' && (
                                <>
                                    {
                                        <div className='Timmer'>
                                            <CountdownCircleTimer
                                                {...timerProps}
                                                colors={[
                                                    ['#2e923e', 0.33],
                                                    ['#f8d59b', 0.33],
                                                    ['#bb2205', 0.34],
                                                ]}
                                                duration={parseInt(props.order.kitchen_preparation_time) * 60}
                                                initialRemainingTime={preparationCalculator(
                                                    props.time,
                                                    props.order.kitchen_preparation_time
                                                )}
                                            >
                                                {({ remainingTime, animatedColor }) => {
                                                    const minutes =
                                                        Math.floor(remainingTime / 60) < 10
                                                            ? '0' + Math.floor(remainingTime / 60)
                                                            : Math.floor(remainingTime / 60);
                                                    const seconds =
                                                        remainingTime % 60 < 10
                                                            ? '0' + (remainingTime % 60)
                                                            : remainingTime % 60;
                                                    return (
                                                        <EuiText style={{ color: animatedColor }}>
                                                            {remainingTime > 0 ? `${minutes}:${seconds}` : 'Delayed'}
                                                        </EuiText>
                                                    );
                                                }}
                                            </CountdownCircleTimer>
                                        </div>
                                    }
                                </>
                            )}
                        </EuiFlexItem>
                    </EuiFlexGroup>
                    <EuiFlexGroup className='mr-top-none' responsive={false}>
                        <EuiFlexItem className='mr-top-none'>
                            <EuiText size='xs' color='subdued' className='text-justify'>
                                {props.address}
                            </EuiText>
                        </EuiFlexItem>
                    </EuiFlexGroup>
                    <EuiSpacer size='s' />
                    <EuiFlexGroup responsive={false} gutterSize='none'>
                        <EuiFlexItem grow={false} className='flex-horizontal-center'>
                            <EuiText>
                                <h1>
                                    <b>
                                        <RupeeSymbol size={'32px'} /> {props.orderTotal}
                                    </b>
                                </h1>
                            </EuiText>
                        </EuiFlexItem>
                        <EuiFlexItem grow={false} className='flex-horizontal-center mr-left-10'>
                            {getPaymentMode()}
                        </EuiFlexItem>
                    </EuiFlexGroup>
                </>
            </EuiToast>
            <EuiSpacer size='m' />
        </div>
    );
};

export default OrderSummaryCard;
