import React, { memo } from 'react';
import { EuiFlexGroup, EuiFlexItem, EuiPanel, EuiText, EuiSpacer, EuiStat, EuiHorizontalRule } from '@elastic/eui';
import CompareWidget from '../compareWidget/comapareWidget';
import RupeeSymbol from '../../rupeeComponent.js';
import { formatFloat, numberFormat } from '../../../utils/numberFormat';

const VisitorInfo = (props) => {
    return (
        <EuiFlexGroup direction={'column'}>
            <EuiFlexGroup direction={'column'}>
                <EuiFlexItem>
                    <EuiText>
                        <h4>Cart</h4>
                    </EuiText>
                    <EuiHorizontalRule />
                </EuiFlexItem>
            </EuiFlexGroup>
            <EuiFlexGroup direction={'row'} style={{ width: '50%' }}>
                <EuiFlexItem>
                    <EuiPanel>
                        <EuiStat
                            title={numberFormat(props.outlet.cart_count, 0, 'en-IN')}
                            // titleColor='secondary'
                            description='Cart count'
                            isLoading={props.isLoading}
                        />
                    </EuiPanel>
                </EuiFlexItem>
                <EuiFlexItem>
                    <EuiPanel>
                        <EuiStat
                            title={
                                props.outlet.cart_amount
                                    ? '₹' + numberFormat(props.outlet.cart_amount, 0, 'en-IN')
                                    : '₹' + 0
                            }
                            // titleColor='secondary'
                            description='Cart Amount'
                            isLoading={props.isLoading}
                        />
                    </EuiPanel>
                </EuiFlexItem>
            </EuiFlexGroup>

            <EuiSpacer size='s' />

            <EuiFlexGroup direction={'column'}>
                <EuiFlexItem>
                    <EuiText>
                        <h4>Delivery</h4>
                    </EuiText>
                    <EuiHorizontalRule margin={'s'} />
                </EuiFlexItem>

                <EuiFlexItem>
                    <EuiFlexGroup direction={'row'} style={{ width: '50%' }}>
                        <EuiFlexItem>
                            <EuiPanel>
                                <EuiStat
                                    title={
                                        props.outlet.total_delivery_cost
                                            ? '₹' + numberFormat(props.outlet.total_delivery_cost, 0, 'en-IN')
                                            : '₹' + 0
                                    }
                                    // titleColor='secondary'
                                    description='Delivery cost'
                                    isLoading={props.isLoading}
                                />
                            </EuiPanel>
                        </EuiFlexItem>
                        <EuiFlexItem>
                            <EuiPanel>
                                <EuiStat
                                    title={
                                        props.outlet.cost_per_order
                                            ? '₹' + numberFormat(props.outlet.cost_per_order, 0, 'en-IN')
                                            : '₹' + 0
                                    }
                                    // titleColor='secondary'
                                    description='Cost per order'
                                    isLoading={props.isLoading}
                                />
                            </EuiPanel>
                        </EuiFlexItem>
                    </EuiFlexGroup>
                </EuiFlexItem>
            </EuiFlexGroup>
            {/* <EuiSpacer size='s' /> */}
            {/* <EuiFlexGroup direction={'column'}>
                <CompareWidget {...props} />
            </EuiFlexGroup> */}
        </EuiFlexGroup>
    );
};

export default memo(VisitorInfo);
