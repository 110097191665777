import {
    EuiModal,
    EuiModalHeader,
    EuiModalHeaderTitle,
    EuiModalBody,
    EuiButtonEmpty,
    EuiButton,
    EuiModalFooter,
    EuiOverlayMask,
    EuiFormRow,
    EuiFieldText,
    EuiForm,
} from '@elastic/eui';
import React, { useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import * as types from '../../reduxStore/types/billing';

const ItemCommentModal = (props) => {
    const { setCommentModal, previousOrders, currentItemComment, currentIndex, currentSuperIndex } = props;

    const billingItem = useSelector((state) => state.billing.billingItem);
    const previousOrdersReducer = useSelector((state) => state.billing.previousOrders);

    const dispatch = useDispatch();

    let defaultValueForComment = {};

    if (previousOrders) {
        defaultValueForComment = {
            item_comment: previousOrdersReducer[currentSuperIndex].cart.cart_items[currentIndex].special_instruction,
        };
    } else {
        defaultValueForComment = {
            item_comment: billingItem[currentItemComment.id]['special_instruction'],
        };
    }

    const {
        register,
        handleSubmit,
        control,
        watch,
        reset,
        setValue,

        formState: { errors },
    } = useForm({
        defaultValues: { ...defaultValueForComment },
    });

    const closeModal = () => {
        setCommentModal(false);
    };

    const onSubmit = (data) => {
        if (previousOrders) {
            let innerArray = [...previousOrdersReducer];

            console.log(`index ${currentIndex}, superIndex - ${currentSuperIndex}, innerArray - ${innerArray}`);

            innerArray[currentSuperIndex].cart.cart_items[currentIndex].special_instruction = data.item_comment;

            dispatch({
                type: types.SET_PREVIOUS_ORDERS,
                payload: {
                    previousOrders: innerArray,
                },
            });
        } else {
            let billingItemObj = JSON.parse(JSON.stringify(billingItem));

            billingItemObj[currentItemComment.id]['special_instruction'] = data.item_comment;

            dispatch({
                type: types.SET_BILLING_ITEM,
                payload: {
                    billingItem: billingItemObj,
                },
            });
        }
        closeModal();
    };

    return (
        <EuiOverlayMask>
            <EuiForm component='form' onSubmit={handleSubmit(onSubmit)}>
                <EuiModal onClose={closeModal} initialFocus='[name=popswitch]'>
                    <EuiModalHeader>
                        <EuiModalHeaderTitle>Special Instruction</EuiModalHeaderTitle>
                    </EuiModalHeader>

                    <EuiModalBody>
                        <EuiFormRow
                            label={'Special Instruction'}
                            isInvalid={errors['item_comment']}
                            error={errors['item_comment'] ? errors['item_comment'].message : ''}
                            fullWidth={true}
                        >
                            <Controller
                                render={({ field }) => <EuiFieldText value={field.value} onChange={field.onChange} />}
                                name={'item_comment'}
                                control={control}
                                fullWidth={true}
                                placeholder={'Special instruction'}
                            />
                        </EuiFormRow>
                    </EuiModalBody>

                    <EuiModalFooter>
                        <EuiButtonEmpty onClick={closeModal}>Cancel</EuiButtonEmpty>

                        <EuiButton type='submit' fill>
                            Save
                        </EuiButton>
                    </EuiModalFooter>
                </EuiModal>
            </EuiForm>
        </EuiOverlayMask>
    );
};

export default ItemCommentModal;
