import {
    EuiFlexGroup,
    EuiFlexItem,
    EuiGlobalToastList,
    EuiLoadingContent,
    EuiSpacer,
    htmlIdGenerator,
} from '@elastic/eui';
import _, { debounce, isEmpty } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SET_AVAILABILITY_CATALOGUE } from '../../../reduxStore/types/ItemAvailabilityTypes';
import AvailabilityTimeModal from '../../../components/menuComponentsRefactor/itemAvailability/Treeview/AvailabilityTimeModal';
import { fetchSearchedCategoryItem } from '../../../components/menuComponentsRefactorCopy/catalogue/Api/FetchCatalogueInfo';
import { fetchItemAvailabilityCategories } from '../../../components/menuComponentsRefactorCopy/catalogue/Api/ItemAvailability/FetchItemAvailabilityData';
import FilterAvailability from '../../../components/ItemAvailability/FilterAvailability';
import FilterSearchedItems from '../../../components/ItemAvailability/FilterSearchedItems';
import CategoryRow, {
    checkCategoryStatus,
} from '../../../components/ItemAvailability/ItemAvailabilityScreen/CategoryRow';

const ItemAvailabilityScreen = () => {
    const categories = useSelector((state) => state.itemAvailabilityReducer.outlet_categories);
    const languageId = useSelector((state) => state.language.languageId);
    const dispatch = useDispatch();
    const outletId = useSelector((state) => state.outlet.selectedOutletId);
    const [toasts, setToasts] = useState([]);
    const [filteredCategories, setFilteredCategories] = useState([]);
    const [state, setState] = useState({
        loading: true,
        availabilityModalData: {},
    });

    useEffect(() => {
        setFilteredCategories(categories);
    }, [categories]);

    const fetchItemAvailabilityData = useCallback(async () => {
        setState((prevState) => ({
            ...prevState,
            loading: true,
        }));

        try {
            let response = await fetchItemAvailabilityCategories(outletId);

            if (response.success) {
                dispatch({
                    type: SET_AVAILABILITY_CATALOGUE,
                    payload: response.categories,
                });
            }
        } catch (error) {
            setToasts([
                ...toasts,
                {
                    title: error?.message ? error.message : error,
                    id: htmlIdGenerator()(),
                },
            ]);
        }

        setState((prevState) => ({
            ...prevState,
            loading: false,
        }));
    }, [dispatch, outletId, toasts]);

    const removeToast = useCallback(
        (removedToast) => {
            setToasts(toasts.filter((toast) => toast.id !== removedToast.id));
        },
        [toasts]
    );

    useEffect(() => {
        fetchItemAvailabilityData();
    }, [outletId]);

    const handleAvailablityModal = useCallback(
        (modalData) => {
            setState((prevState) => ({
                ...prevState,
                availabilityModalData: modalData,
            }));
            if (modalData?.toast) {
                setToasts([
                    ...toasts,
                    {
                        title: modalData.toast.title,
                        id: htmlIdGenerator()(),
                    },
                ]);
            }
        },
        [toasts]
    );

    const handleFilterChange = useCallback(
        (value) => {
            if (value === 'out_of_stock') {
                // display out of stock categories
                let outOfStockCategories = [];
                for (const category of categories) {
                    let category_status = checkCategoryStatus(
                        category,
                        categories.filter((categoryItem) => categoryItem.parent_id === category.id)
                    ).status;
                    if (category_status === 'turned_off' || category_status === 'partial_state') {
                        outOfStockCategories.push(category);
                    }
                }

                setFilteredCategories(outOfStockCategories?.length ? outOfStockCategories : []);
            } else if (value === 'all_items') {
                setFilteredCategories(categories);
            }
        },
        [categories]
    );

    const debouncedSearch = debounce(async (text, languageId) => {
        setState((prevState) => ({
            ...prevState,
            loading: true,
        }));
        await fetchSearchedCategoryItem(text, languageId).then((response) => {
            if (response?.categories?.length) {
                let matched_categories = [];
                for (const existingCategory of filteredCategories) {
                    for (const searchedCategory of response.categories) {
                        if (existingCategory.id === searchedCategory.category_id) {
                            matched_categories.push({ ...existingCategory, items: searchedCategory?.items });
                        }
                    }
                }
                setFilteredCategories(matched_categories);
            }
            setState((prevState) => ({
                ...prevState,
                loading: false,
            }));
        });
    }, 1000);

    const handleSearchedItems = useCallback(
        (query) => {
            if (!_.isEmpty(query)) {
                debouncedSearch(query, languageId);
            } else {
                setFilteredCategories(categories);
            }
        },
        [categories, debouncedSearch, languageId]
    );

    useEffect(() => {
        return function cleanup() {
            debouncedSearch.cancel();
        };
    }, [debouncedSearch]);

    return (
        <>
            <EuiFlexGroup justifyContent='spaceBetween' direction='row' alignItems='flexEnd'>
                <EuiFlexItem style={{ minWidth: 280 }} grow={false}>
                    <FilterSearchedItems
                        isLoading={state.loading}
                        currentTab={'item_availability'}
                        handleSearchedItems={handleSearchedItems}
                    />
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                    <FilterAvailability isLoading={state.loading} handleFilterChange={handleFilterChange} />
                </EuiFlexItem>
            </EuiFlexGroup>
            <EuiSpacer />
            {state.loading ? (
                <React.Fragment>
                    <EuiLoadingContent lines={7} />
                </React.Fragment>
            ) : (
                <React.Fragment>
                    {state.availabilityModalData?.showModal && !isEmpty(state.availabilityModalData?.modalResponse) ? (
                        <AvailabilityTimeModal
                            handleAvailablityModal={handleAvailablityModal}
                            state={state.availabilityModalData}
                        />
                    ) : null}

                    <EuiGlobalToastList toasts={toasts} dismissToast={removeToast} toastLifeTimeMs={6000} />
                    {filteredCategories
                        ?.filter((category) => {
                            return category.parent_id === null;
                        })
                        .map((category) => {
                            return (
                                <CategoryRow
                                    fetchItemAvailabilityData={fetchItemAvailabilityData}
                                    key={category.id.toString()}
                                    category={category}
                                    searchedItems={category?.items}
                                    handleAvailablityModal={handleAvailablityModal}
                                />
                            );
                        })}
                </React.Fragment>
            )}
        </>
    );
};

export default React.memo(ItemAvailabilityScreen);
