import React, { useEffect } from 'react';
import { EuiForm, EuiSpacer } from '@elastic/eui';
import CheckBoxField from '../../../components/Form/CheckBoxField';
import { useForm } from 'react-hook-form';
import FormGroupDescription from '../../../components/formGroupDescription';
import ButtonGroup from '../../../components/Form/ButtonGroup';
import { useDispatch, useSelector } from 'react-redux';
import { outletSettingsAPI } from '../../../api/outlet/outletSettingsAPI';
import * as types from '../../../reduxStore/types/outlet';
import { userHasPermission } from '../../../helper/auth';

const OtherSettings = (props) => {
    const { getOutletData, outletId, fetchOutletById } = props;
    const {
        handleSubmit,
        control,
        watch,
        reset,
        setValue,

        formState: { isDirty, errors },
    } = useForm();
    const dispatch = useDispatch();
    const permissions = useSelector((state) => state.permission.permission);
    const restaurantDescriptions = useSelector((state) => state.auth.restaurantDescriptions);

    useEffect(() => {
        if (!userHasPermission(permissions, '/outlet-settings', 'write')) return;

        if (isDirty) {
            dispatch({
                type: 'set-portal-active',
                payload: {
                    portalActive: {
                        resetChanges: resetChanges,
                        submit: handleSubmit(onSubmit),
                    },
                },
            });
        } else {
            dispatch({
                type: 'set-portal-active',
                payload: {
                    portalActive: {},
                },
            });
        }
    }, [isDirty]);

    useEffect(() => {
        if (getOutletData && getOutletData.outlet) {
            resetChanges();
        }
    }, [getOutletData]);

    const resetChanges = () => {
        let defaultValue = {};

        defaultValue.enable_auto_accept_order = getOutletData.outlet.settings.enable_auto_accept_order;

        reset({
            ...defaultValue,
        });
    };

    const onSubmit = (data) => {
        dispatch(outletSettingsAPI('other_settings', data)).then((res) => {
            if (res.success) {
                dispatch({
                    type: types.SET_PORTAL_ACTIVE,
                    payload: {
                        portalActive: {},
                    },
                });
                dispatch({
                    type: types.SET_PORTAL_ACTIVE_TAB_SWITCHED,
                    payload: {
                        tabSwitched: false,
                    },
                });
                fetchOutletById();
            } else {
                alert(res.message);
            }
        });
    };

    return (
        <>
            <EuiForm component='form' onSubmit={handleSubmit(onSubmit)}>
                <FormGroupDescription
                    title={restaurantDescriptions?.auto_accept?.display_name}
                    description={restaurantDescriptions?.auto_accept?.description}
                >
                    <ButtonGroup
                        idSelected={watch('enable_auto_accept_order') ? '1' : '0'}
                        color='primary'
                        control={control}
                        setValue={setValue}
                        name='enable_auto_accept_order'
                        watch={watch}
                        label={'Enable auto accept order'}
                    />

                    <EuiSpacer />

                    {/* {
                        isDirty ? (
                            <EuiFormRow>
                                <EuiFlexGroup alignItems="center">
                                    <LoaderButton isLoading={true}/>
                                    <EuiFlexItem>
                                        <EuiButtonEmpty onClick={resetChanges}>Discard changes</EuiButtonEmpty>
                                    </EuiFlexItem>
                                </EuiFlexGroup>
                            </EuiFormRow>
                        ): ''
                    } */}
                </FormGroupDescription>
            </EuiForm>
        </>
    );
};

export default OtherSettings;
