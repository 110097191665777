import API from '../axios/API';

export const updateTableBookingStatus = (outletId, bookingRequestId, status, tableList = []) => {
    return async (dispatch, getState) => {
        let response;

        let { language } = getState();

        try {
            response = await API.put(
                `${language.language}/pos/restaurants/:restaurantId/outlets/${outletId}/table-booking-requests/${bookingRequestId}/change-status`,
                {
                    status: status,
                    table_ids: tableList,
                }
            );
        } catch (error) {
            response = error;
        }

        return response;
    };
};
