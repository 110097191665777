import {
    EuiButton,
    EuiCheckbox,
    EuiFlexItem,
    EuiModal,
    EuiModalBody,
    EuiModalFooter,
    htmlIdGenerator,
} from '@elastic/eui';
import React from 'react';
import { useEffect } from 'react';
import { useCallback } from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { removeMapItem } from '../../../api/menu/RemoveMapItems';
import { SET_MAP_ITEM_LIST } from '../../../reduxStore/types/menu';
import { fetchCategoryItem } from '../catalogue/Api/FetchCatalogueInfo';

const MappedItemModal = ({ mapItemModal, handleMapItemModal, item }) => {
    const [unLinkItem, setUnLinkItem] = useState(false);
    const dispatch = useDispatch();
    const languageId = useSelector((state) => state.language.languageId);

    const handleChange = useCallback((event) => {
        setUnLinkItem(event.target.checked);
    }, []);

    const handleUnLinkItem = useCallback(async () => {
        let payload = {
            item_id: item.item_id,
            category_id: item.category_id,
        };
        handleMapItemModal();
        removeMapItem(payload).then((response) => {
            if (response.success) {
                dispatch({
                    type: SET_MAP_ITEM_LIST,
                    payload: [],
                });
                fetchCategoryItem(item.category_id, languageId).then((response) => {
                    if (response.success) {
                        // setItemListState(response.items);
                    }
                });
            }
        });
    }, [dispatch, item.category_id, item.item_id, languageId, handleMapItemModal]);

    useEffect(() => {
        return function cleanup(params) {
            setUnLinkItem(false);
        };
    }, []);

    return (
        <>
            {mapItemModal ? (
                <EuiModal onClose={handleMapItemModal}>
                    <EuiModalBody>
                        <EuiFlexItem style={{ paddingTop: '12px' }}>
                            <EuiCheckbox
                                id={htmlIdGenerator()()}
                                onChange={handleChange}
                                checked={unLinkItem}
                                label='Would you like to unlink this item?'
                            />
                        </EuiFlexItem>
                    </EuiModalBody>
                    <EuiModalFooter>
                        <EuiButton disabled={!unLinkItem} onClick={handleUnLinkItem}>
                            Confirm Changes
                        </EuiButton>
                    </EuiModalFooter>
                </EuiModal>
            ) : null}
        </>
    );
};

export default React.memo(MappedItemModal);
