import React from 'react';
import moment from 'moment';
import './style.css';
import { isEmpty } from 'lodash';
import { capitalize } from '../../helper/capitalize';

export default class KotPrint extends React.Component {
    getTotalItem = (items) => {
        let count = 0;
        items.map((item) => {
            count = count + item.quantity;
        });
        return count;
    };

    getPaymentMethod = (mode) => {
        if (mode === 'CASH_ON_DELIVERY') {
            return 'Cash on delivery';
        } else {
            return 'Online';
        }
    };

    renderItemRow = (orderItem, index) => {
        return (
            <tr key={index}>
                <td style={{ textAlign: 'initial', paddingLeft: '4px' }}>
                    <b>{orderItem.item.translations.title[this.props.languageId]}</b>

                    {!isEmpty(orderItem.variant_option_information) &&
                    !isEmpty(orderItem.variant_option_information.variant_group_information) ? (
                        <span style={{ display: 'block' }}>
                            - {orderItem.variant_option_information.translations.title[this.props.languageId]}
                        </span>
                    ) : (
                        ''
                    )}

                    {orderItem.customizations &&
                        orderItem.customizations.map((addons, index) => (
                            <span style={{ display: 'block' }}>
                                - {addons.translations.name[this.props.languageId]}
                            </span>
                        ))}
                </td>
                <td style={{ verticalAlign: 'initial', textAlign: 'middle' }}>&nbsp; &nbsp;x{orderItem.quantity}</td>
            </tr>
        );
    };
    render() {
        if (isEmpty(this.props.order)) return;
        return (
            <div>
                <table cellPadding={0} cellSpacing={0} style={{ borderCollapse: 'collapse' }}>
                    <tbody>
                        <tr style={{ borderStyle: 'solid', border: '0px', borderBottom: '1px' }}>
                            <td colSpan={2}>&nbsp;</td>
                        </tr>
                        <tr>
                            <td colSpan={2} style={{ textAlign: 'center' }}>
                                <b>Fab Systems</b>
                            </td>
                        </tr>
                        <tr>
                            <td>Order #</td>
                            <td>&nbsp;{this.props.order.order_label}</td>
                        </tr>
                        <tr>
                            <td>Order Time</td>
                            <td>&nbsp;{moment(this.props.order.placed_at).format('Do MMMM h:mm a')}</td>
                        </tr>
                        <tr>
                            <td>Outlet</td>
                            <td>
                                <b>{this.props.order?.outlet?.translations?.name[this.props.languageId]}</b>
                            </td>
                        </tr>
                        <tr>
                            <td>Name</td>
                            <td>{this.props.order.customer.name}</td>
                        </tr>
                        <tr>
                            <td>Number</td>
                            <td>
                                {this.props.order.customer.country_code} {this.props.order.customer.mobile_number}
                            </td>
                        </tr>

                        {this.props.order.customer.email ? (
                            <tr>
                                <td>Email</td>
                                <td>{this.props.order.customer.email}</td>
                            </tr>
                        ) : (
                            ''
                        )}

                        <tr>
                            <td>Total Items</td>
                            <td>
                                &nbsp;<b>{this.getTotalItem(this.props.order.cart.cart_items)}</b>
                            </td>
                        </tr>
                        <tr>
                            <td>Mode</td>
                            <td>{capitalize(this.props.order.ordering_mode)}</td>
                        </tr>
                        {this.props.order?.table?.code ? (
                            <tr>
                                <td>Table</td>
                                <td>{this.props.order?.table?.code}</td>
                            </tr>
                        ) : null}

                        <tr>
                            <td>Payment</td>
                            <td>
                                {this.props.order.payment_method &&
                                    this.getPaymentMethod(this.props.order.payment_method.payment_method)}
                            </td>
                        </tr>
                        {this.props.order.cart.special_instructions && (
                            <tr>
                                <td>Instructions</td>
                                <td>
                                    <span style={{ paddingLeft: '5px' }}>
                                        {this.props.order.cart.special_instructions}
                                    </span>
                                </td>
                            </tr>
                        )}

                        <tr>
                            <td colSpan={2}>&nbsp;</td>
                        </tr>
                        <tr>
                            <td style={{ textAlign: 'initial' }}>Item</td>
                            <td style={{ textAlign: 'middle' }}>Qty</td>
                        </tr>
                        <tr>
                            <td colSpan={2}>&nbsp;</td>
                        </tr>

                        {this.props.order.cart.cart_items.map((orderItem, index) => {
                            return (
                                <td
                                    style={
                                        orderItem?.children?.length
                                            ? {
                                                  borderLeft: '3px solid black',
                                                  height: 'auto',
                                                  display: 'block',
                                                  marginBottom: '12px',
                                              }
                                            : { display: 'block' }
                                    }
                                >
                                    {this.renderItemRow(orderItem, index)}
                                    {orderItem?.children?.length
                                        ? orderItem.children.map((childOrderItem, index) => {
                                              return this.renderItemRow(childOrderItem, 'child-' + index);
                                          })
                                        : null}
                                </td>
                            );
                        })}
                        <tr>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                        </tr>
                        <tr>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                        </tr>
                    </tbody>
                </table>

                {/* <div style={{pageBreakAfter:'always', clear: 'both'}}>&nbsp;</div>  */}
            </div>
        );
    }
}
