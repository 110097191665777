import React, { useState, useEffect } from 'react';
import { isEmpty } from 'lodash';
import { EuiCheckbox, htmlIdGenerator, EuiCheckableCard, EuiText } from '@elastic/eui';
import { useSelector, useDispatch } from 'react-redux';
import { agmarkSign } from '../../helper/agmarkSign';
import * as types from '../../reduxStore/types/billing';

const AddonCheckableCard = (props) => {
    const {
        selectedAddOnsByGroup,
        addon,
        choice,
        formateNewAddonChoice,
        setSelectedAddOnsByGroup,
        setTotalAmount,
        calculateAddonAmount,
        handleAddonGroupError,
        selectedVariant,
        setSelectedVariant,
        selectedVariantIndex,
    } = props;

    const languageId = useSelector((state) => state.language.languageId);

    const selectedItem = useSelector((state) => state.billing.selectedItem);
    const dispatch = useDispatch();

    const [checked, setChecked] = useState(false);

    const AddonStyle = {
        width: '20%',
        height: '100%',
        border: '1px solid #d8dadc',
        margin: '10px',
        overflowWrap: 'break-word',
        wordWrap: 'break-word',
        wordBreak: 'break-word',
        whiteSpace: 'normal',
        display: 'inline-block',
        hyphens: 'auto',
        fontSize: '14px',
        cursor: choice.in_stock ? 'pointer' : 'default',
        background: choice.in_stock ? 'transparent' : '#fafbfd',
    };

    useEffect(() => {
        let group_obj = selectedAddOnsByGroup[addon.addon_id];

        let checked = false;

        if (group_obj && !isEmpty(group_obj)) {
            let innerArray = group_obj.filter((choiceData) => choice.choice_id === choiceData.choice_id);

            checked = innerArray && innerArray.length > 0 ? true : false;
        }

        setChecked(checked);
    }, [selectedAddOnsByGroup]);

    const selectedItemPrice = () => {
        let item = { ...selectedItem };
        let price = item.price;

        Object.entries(selectedAddOnsByGroup).forEach(([key, value]) => {
            value.map((addon) => {
                price += addon.choicePrice;
            });
        });

        item.priceWithAddon = price;

        dispatch({
            type: types.SET_SELECTED_ITEM,
            payload: {
                selectedItem: item,
            },
        });
    };

    const selectedVariantPrice = () => {
        let item = { ...selectedItem };
        let price = item.variants[0].options[selectedVariantIndex].price;
        Object.entries(selectedAddOnsByGroup).forEach(([key, value]) => {
            value.map((addon) => {
                price += addon.choicePrice;
            });
        });

        item.variants[0].priceWithAddon = price;

        // setSelectedVariant(item)
        dispatch({
            type: types.SET_SELECTED_ITEM,
            payload: {
                selectedItem: item,
            },
        });
    };
    useEffect(() => {
        if (!isEmpty(selectedVariant)) {
            selectedVariantPrice();
        } else {
            selectedItemPrice();
        }
    }, [selectedAddOnsByGroup]);

    const onChangeCheckable = () => {
        let addonsByGroup = { ...selectedAddOnsByGroup };
        let newAddonChoice = formateNewAddonChoice(choice, addon);

        let hasAddOnGroup = addonsByGroup.hasOwnProperty(addon.addon_id);

        if (hasAddOnGroup) {
            // if checkbox selection.
            let addonGroup = addonsByGroup[addon.addon_id];
            let hasAddonChoiceIndex = addonGroup.findIndex((choice) => {
                return choice.choice_id == newAddonChoice.choice_id;
            });

            if (hasAddonChoiceIndex != -1) {
                // if addonChoice exists.
                addonGroup.splice(hasAddonChoiceIndex, 1);
                addonsByGroup[addon.addon_id] = addonGroup;
                if (!addonGroup.length) {
                    delete addonsByGroup[addon.addon_id];
                }
            } else {
                // if addon choice not exists.
                addonGroup = [...addonGroup, newAddonChoice];
                addonsByGroup[addon.addon_id] = addonGroup;
            }
            handleAddonGroupError(addon, addonGroup, addonsByGroup);
        } else {
            // checkbox group not exists.
            addonsByGroup = {
                ...addonsByGroup,
                [addon.addon_id]: [newAddonChoice],
            };
            handleAddonGroupError(addon, [newAddonChoice], addonsByGroup);
        }

        setSelectedAddOnsByGroup(addonsByGroup);

        setChecked(!checked);
    };

    return (
        <>
            {/* onClick={() => choice.in_stock && onChangeCheckable()} */}
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <div
                    style={{
                        padding: '5px 0px',
                        display: 'flex',
                        flexDirection: 'row',
                        padding: '0px 20px',
                    }}
                >
                    <EuiCheckbox
                        id={htmlIdGenerator()()}
                        checked={checked}
                        disabled={choice.in_stock ? false : true}
                        onChange={(e) => choice.in_stock && onChangeCheckable()}
                        label={<EuiText color='default'>{choice.name}</EuiText>}
                    />
                </div>

                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        padding: '10px 20px',
                        fontSize: '14px',
                    }}
                >
                    <b>₹{choice.price.toFixed(2)}</b>
                </div>
            </div>
        </>
    );
};

export default AddonCheckableCard;
