import React from 'react';
import { EuiTabbedContent, EuiLoadingContent } from '@elastic/eui';
import TableBookingPendingComponent from './tableBookingPendingComponent';
import TableBookingCalander from './tableBookingCalander';
import { EuiFlexItem } from '@elastic/eui';
import { useSelector } from 'react-redux';

const TableBookingComponent = () => {
    const selectedOutlet = useSelector((state) => state.outlet.selectedOutletId);
    const tabs = [
        {
            id: 'calender_view',
            name: 'Calendar',
            content: <TableBookingCalander outletId={selectedOutlet} />,
        },
        {
            id: 'pending_list',
            name: 'Pending List',
            content: <TableBookingPendingComponent outletId={selectedOutlet} />,
        },
    ];

    return (
        <EuiFlexItem>
            <EuiFlexItem style={{ marginTop: '15px' }}>
                {selectedOutlet ? (
                    <EuiTabbedContent tabs={tabs} initialSelectedTab={tabs[0]} autoFocus='selected' />
                ) : (
                    <EuiLoadingContent lines={10} />
                )}
            </EuiFlexItem>
        </EuiFlexItem>
    );
};

export default TableBookingComponent;
