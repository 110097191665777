import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { EuiFlexGroup, EuiFlexItem, EuiDescribedFormGroup, EuiForm, EuiFormRow, EuiButtonGroup } from '@elastic/eui';
import ButtonGroup from '../Form/ButtonGroup';
import NumberField from '../Form/NumberField';
import SelectField from '../Form/SelectField';
import ComboBoxField from '../Form/ComboBoxField';
import { useForm } from 'react-hook-form';
import { getCategoryList } from '../../api/category/getCategoryList';
import { isEmpty } from 'lodash';
import { Controller } from 'react-hook-form';
import RadioField from '../Form/RadioField';
import { CategoryDrag } from './categoryDrag';
import * as types from '../../reduxStore/types/outlet';

const PrinterComponent = (props) => {
    const { outletId } = props;
    const {
        watch,
        setValue,
        reset,
        control,
        handleSubmit,

        formState: { isDirty, errors },
    } = useForm({});
    const [printerOptions, setPrinteroptions] = useState([]);
    const [categoryOptions, setCategoryOptions] = useState([]);
    const [updateForm, setUpdateForm] = useState({});
    const [defaultPrinterList, setDefaultPrinterList] = useState(false);
    const languageId = useSelector((state) => state.language.languageId);
    const dispatch = useDispatch();

    useEffect(() => {
        if (isDirty) {
            dispatch({
                type: 'set-portal-active',
                payload: {
                    portalActive: {
                        resetChanges: resetChanges,
                        submit: handleSubmit(onSubmit),
                    },
                },
            });
        } else {
            dispatch({
                type: 'set-portal-active',
                payload: {
                    portalActive: {},
                },
            });
        }
    }, [isDirty]);

    const resetChanges = () => {
        let printerConfigurations = JSON.parse(localStorage.getItem('printer_configurations'));

        if (!isEmpty(printerConfigurations) && printerConfigurations.outlet_id != outletId) {
            reset({});
            return;
        }

        if (printerConfigurations && !isEmpty(printerConfigurations)) {
            var printerNamesWithCategories = {};

            if (printerOptions.length) {
                printerOptions.map((printer) => {
                    if (printerConfigurations[printer.value]) {
                        printerNamesWithCategories[printer.value]['data'] = printerConfigurations[printer.value]?.data
                            ? printerConfigurations[printer.value].data
                            : [];
                        printerNamesWithCategories[printer.value]['count'] = printerConfigurations[printer.value]?.count
                            ? printerConfigurations[printer.value].count
                            : '';
                    }
                });
            }

            reset({
                outlet_id: outletId,
                ...printerConfigurations,
                ...printerNamesWithCategories,
            });
        }
    };

    useEffect(() => {
        resetChanges();
    }, [printerOptions, updateForm, outletId]);

    // useEffect(() => {
    //     dispatch(getCategoryList()).then((res) => {
    //         if (res.success) {
    //             res.categories.map((category) => {
    //                 category.label = category.translations.title[languageId];

    //                 if (category?.sub_categories?.length) {
    //                     category.sub_categories.map((subCategory) => {
    //                         subCategory.label = subCategory.translations.title[languageId];
    //                     });
    //                 }
    //             });

    //             setCategoryOptions(res.categories);
    //         }
    //     });
    // }, []);

    useEffect(() => {
        var printersList = JSON.parse(localStorage.getItem('printers'));

        if (printersList?.length) {
            let innerArray = [];
            printersList.map((printer) => {
                innerArray.push({ value: printer.name, inputDisplay: printer.displayName, label: printer.displayName });
            });
            setPrinteroptions(innerArray);
        }
    }, []);

    const onSubmit = (data) => {
        data.outlet_id = outletId;
        localStorage.setItem('printer_configurations', JSON.stringify(data));
        setUpdateForm({ ...data });
        dispatch({
            type: types.SET_PORTAL_ACTIVE,
            payload: {
                portalActive: {},
            },
        });
        dispatch({
            type: types.SET_PORTAL_ACTIVE_TAB_SWITCHED,
            payload: {
                tabSwitched: false,
            },
        });
    };

    return (
        <EuiForm component='form'>
            <EuiFlexGroup direction='column'>
                <EuiFlexItem>
                    <EuiDescribedFormGroup title={<h3>Use Printer</h3>} description='Set use printer functionality'>
                        <Controller
                            render={({ field }) => (
                                <EuiFormRow label={''}>
                                    <EuiButtonGroup
                                        options={[
                                            { id: 1, label: 'Yes, I want to use printer', iconType: 'check' },
                                            { id: 0, label: "No, I doesn't want to use printer", iconType: 'cross' },
                                        ]}
                                        idSelected={field.value}
                                        onChange={field.onChange}
                                        name='use_printer'
                                        color='primary'
                                        value={watch('use_printer') ? '1' : '0'}
                                    />
                                </EuiFormRow>
                            )}
                            name='use_printer'
                            control={control}
                        />
                    </EuiDescribedFormGroup>
                </EuiFlexItem>

                {watch('use_printer') ? (
                    <>
                        <EuiFlexItem>
                            <EuiDescribedFormGroup
                                title={<h3>Auto print</h3>}
                                description='Enable auto print functionality'
                            >
                                <ButtonGroup
                                    idSelected={watch('auto_print') ? '1' : '0'}
                                    color='primary'
                                    control={control}
                                    setValue={setValue}
                                    name='auto_print'
                                    label={''}
                                />
                            </EuiDescribedFormGroup>
                        </EuiFlexItem>

                        {/* Bill default printer */}
                        <EuiFlexItem>
                            <EuiDescribedFormGroup
                                title={<h3>Select BILL Printer</h3>}
                                description='Select default printer'
                            >
                                <RadioField
                                    name='bill_radio'
                                    label='Billing'
                                    options={[
                                        { id: 'single', label: 'Single printer setup' },
                                        { id: 'multiple', label: 'Multiple printer setup' },
                                    ]}
                                    idSelected={watch('bill_radio')}
                                    onChange={(id) => setValue('bill_radio', id, { shouldDirty: true })}
                                    control={control}
                                    defaultValue={'single'}
                                />
                            </EuiDescribedFormGroup>
                        </EuiFlexItem>

                        {watch('bill_radio') === 'single' ? (
                            <EuiFlexItem>
                                <EuiDescribedFormGroup
                                    title={<h3>Select BILL Printer</h3>}
                                    description='Select default printer'
                                >
                                    <SelectField
                                        errors={errors}
                                        label=''
                                        name='default_bill_printer'
                                        options={printerOptions}
                                        valueOfSelected={
                                            watch('default_bill_printer') ? watch('default_bill_printer') : null
                                        }
                                        onChangeData={(value) =>
                                            setValue('default_bill_printer', value, { shouldDirty: true })
                                        }
                                        placeholder='Please enter your BILL printer'
                                        control={control}
                                        watch={watch}
                                        defaultValue={
                                            watch('default_bill_printer') ? watch('default_bill_printer') : null
                                        }
                                    />
                                </EuiDescribedFormGroup>
                            </EuiFlexItem>
                        ) : (
                            <EuiFlexItem>
                                <EuiDescribedFormGroup
                                    title={<h3>Select BILL Printer</h3>}
                                    description='Select default printer'
                                >
                                    <ComboBoxField
                                        options={printerOptions}
                                        selectedOptions={
                                            watch(`default_bill_printers_list`)
                                                ? watch(`default_bill_printers_list`)
                                                : []
                                        }
                                        onChange={(selectedOptions) =>
                                            setValue('default_bill_printers_list', selectedOptions, {
                                                shouldDirty: true,
                                            })
                                        }
                                        errors={errors}
                                        label=''
                                        name={`default_bill_printers_list`}
                                        placeholder='Select printers'
                                        control={control}
                                        watch={watch}
                                        map
                                    />
                                </EuiDescribedFormGroup>
                            </EuiFlexItem>
                        )}

                        {watch('bill_radio') === 'multiple' ? (
                            watch('default_bill_printers_list') &&
                            watch('default_bill_printers_list').map((printer) => (
                                <EuiFlexItem>
                                    <EuiDescribedFormGroup
                                        title={<h3>Number of copies for {printer.inputDisplay}</h3>}
                                        description='Enter number of copies you want to print for each bill'
                                    >
                                        <NumberField
                                            label={''}
                                            errors={errors}
                                            name={`bill_print_count_${printer.value}`}
                                            placeholder='Enter BILL count'
                                            control={control}
                                        />
                                    </EuiDescribedFormGroup>
                                </EuiFlexItem>
                            ))
                        ) : (
                            <EuiFlexItem>
                                <EuiDescribedFormGroup
                                    title={<h3>Number of copies for bill</h3>}
                                    description='Enter number of copies you want to print for each bill'
                                >
                                    <NumberField
                                        label={''}
                                        errors={errors}
                                        name={`bill_print_count`}
                                        placeholder='Enter BILL count'
                                        control={control}
                                    />
                                </EuiDescribedFormGroup>
                            </EuiFlexItem>
                        )}

                        {/* KOT default printer */}
                        <EuiFlexItem>
                            <EuiDescribedFormGroup
                                title={<h3>Select KOT Printer</h3>}
                                description='Select default printer'
                            >
                                <RadioField
                                    name='kot_radio'
                                    label='Kot'
                                    options={[
                                        { id: 'singleKot', label: 'Single printer setup' },
                                        { id: 'multipleKot', label: 'Multiple printer setup' },
                                    ]}
                                    idSelected={watch('kot_radio')}
                                    onChange={(id) => setValue('kot_radio', id, { shouldDirty: true })}
                                    control={control}
                                    defaultValue={'singleKot'}
                                />
                            </EuiDescribedFormGroup>
                        </EuiFlexItem>

                        {watch('kot_radio') === 'singleKot' ? (
                            <EuiFlexItem>
                                <EuiDescribedFormGroup
                                    title={<h3>Select KOT Printer</h3>}
                                    description='Select default printer'
                                >
                                    <SelectField
                                        errors={errors}
                                        label=''
                                        name='default_kot_printer'
                                        options={printerOptions}
                                        valueOfSelected={
                                            watch('default_kot_printer') ? watch('default_kot_printer') : null
                                        }
                                        onChangeData={(value) =>
                                            setValue('default_kot_printer', value, { shouldDirty: true })
                                        }
                                        placeholder='Please enter your KOT printer'
                                        control={control}
                                        watch={watch}
                                        defaultValue={
                                            watch('default_kot_printer') ? watch('default_kot_printer') : null
                                        }
                                    />
                                </EuiDescribedFormGroup>

                                <EuiDescribedFormGroup
                                    title={<h3>Number of copies for print KOT</h3>}
                                    description='Enter number of copies you want to print for each bill'
                                >
                                    <NumberField
                                        label={''}
                                        errors={errors}
                                        name='kot_print_count'
                                        placeholder='Enter KOT count'
                                        control={control}
                                    />
                                </EuiDescribedFormGroup>
                            </EuiFlexItem>
                        ) : (
                            <EuiFlexItem>
                                <EuiDescribedFormGroup
                                    title={<h3>Select KOT Printer</h3>}
                                    description='Select default printer'
                                >
                                    <ComboBoxField
                                        options={printerOptions}
                                        selectedOptions={
                                            watch(`default_kot_printers_list`) ? watch(`default_kot_printers_list`) : []
                                        }
                                        onChange={(selectedOptions) => {
                                            setValue('default_kot_printers_list', selectedOptions, {
                                                shouldDirty: true,
                                            });
                                            setDefaultPrinterList(!defaultPrinterList);
                                        }}
                                        errors={errors}
                                        label=''
                                        name={`default_kot_printers_list`}
                                        placeholder='Select printers'
                                        control={control}
                                        watch={watch}
                                        map
                                    />
                                </EuiDescribedFormGroup>
                            </EuiFlexItem>
                        )}

                        {watch('kot_radio') === 'multipleKot' ? (
                            <EuiFlexItem>
                                <EuiDescribedFormGroup
                                    title={<h3>Split kot by category</h3>}
                                    description='Enable to split incoming order from different printers'
                                >
                                    <ButtonGroup
                                        idSelected={watch('split_kot') ? '1' : '0'}
                                        color='primary'
                                        control={control}
                                        setValue={setValue}
                                        name='split_kot'
                                        label={''}
                                    />
                                </EuiDescribedFormGroup>
                            </EuiFlexItem>
                        ) : (
                            ''
                        )}

                        {watch('default_kot_printers_list') &&
                        watch('default_kot_printers_list').length &&
                        watch('split_kot') &&
                        watch('kot_radio') === 'multipleKot' ? (
                            <EuiFlexItem style={{ display: 'flex', flexDirection: 'column' }}>
                                <CategoryDrag
                                    printerList={watch('default_kot_printers_list')}
                                    watch={watch}
                                    setValue={setValue}
                                    errors={errors}
                                    control={control}
                                    outletId={outletId}
                                />
                                {watch('default_kot_printers_list').map((printer) => (
                                    <EuiFlexItem style={{ display: 'none' }}>
                                        <ComboBoxField
                                            options={categoryOptions}
                                            selectedOptions={
                                                watch(`printers.${printer.value}`)
                                                    ? watch(`printers.${printer.value}`)
                                                    : []
                                            }
                                            onChangeData={(value) =>
                                                setValue(`printers.${printer.value}`, value, { shouldDirty: true })
                                            }
                                            errors={errors}
                                            label=''
                                            name={`printers.${printer.value}.data`}
                                            placeholder='Select Categoryies'
                                            control={control}
                                            watch={watch}
                                        />
                                    </EuiFlexItem>
                                ))}
                            </EuiFlexItem>
                        ) : (
                            ''
                        )}
                    </>
                ) : (
                    ''
                )}

                <EuiFlexItem style={{ display: 'none' }}>
                    <NumberField label={''} errors={errors} name={'outlet_id'} control={control} d value={outletId} />
                </EuiFlexItem>
            </EuiFlexGroup>
        </EuiForm>
    );
};

export default PrinterComponent;
