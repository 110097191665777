import React from 'react';
import {
    EuiButton,
    EuiModal,
    EuiModalBody,
    EuiModalFooter,
    EuiModalHeader,
    EuiModalHeaderTitle,
    EuiFlexGroup,
    EuiFlexItem,
    EuiText,
} from '@elastic/eui';

export const OrderRatingUserInfoModal = (props) => {
    /* const [state, setState] = useState({
    apiDataLoaded: false
  }) */
    const closeModal = () => {
        props.closeUserInfoModal();
    };
    /* useEffect(() => {
    /!* getUserInfo(1, 34) *!/
  }, [props.userId]) */
    return (
        <>
            {props.userModalOpen && (
                // <EuiOverlayMask>
                <EuiModal
                    onClose={() => {
                        closeModal();
                    }}
                    style={{ zIndex: 11000 }}
                >
                    <EuiModalHeader>
                        <EuiModalHeaderTitle>Customer Information</EuiModalHeaderTitle>
                    </EuiModalHeader>

                    <EuiModalBody>
                        <EuiFlexGroup responsive={false}>
                            <EuiFlexItem grow={false}>
                                <EuiFlexGroup responsive={false} direction='column'>
                                    <EuiFlexItem grow={false}>
                                        <EuiText>
                                            <p>
                                                Name: <b>{props.userName}</b>
                                            </p>
                                        </EuiText>
                                    </EuiFlexItem>
                                    <EuiFlexItem grow={false}>
                                        <EuiText>
                                            <p>
                                                Address (user input): <b>{props.inputAddess}</b>
                                            </p>
                                        </EuiText>
                                    </EuiFlexItem>
                                    <EuiFlexItem grow={false}>
                                        <EuiText>
                                            <p />
                                        </EuiText>
                                    </EuiFlexItem>
                                    <EuiFlexItem grow={false}>
                                        <EuiText>
                                            <p>
                                                Location Pin: <b>{props.locationPin}</b>
                                            </p>
                                        </EuiText>
                                    </EuiFlexItem>
                                    <EuiFlexItem grow={false}>
                                        <EuiText>
                                            <p>
                                                Phone Number:{' '}
                                                <b>
                                                    {props.countryCode}
                                                    {props.phoneNumber}
                                                </b>
                                            </p>
                                        </EuiText>
                                    </EuiFlexItem>
                                </EuiFlexGroup>
                            </EuiFlexItem>
                            {/* <EuiFlexItem>
                                    <EuiFlexGroup responsive={false} direction='column'>
                                        <EuiFlexItem grow={false}>
                                            <EuiText>
                                                <p>
                                                    <b>{props.userName}</b>
                                                </p>
                                            </EuiText>
                                        </EuiFlexItem>
                                        <EuiFlexItem grow={false}>
                                            <EuiText>
                                                <p>
                                                    <b>{props.inputAddess}</b>
                                                </p>
                                            </EuiText>
                                        </EuiFlexItem>
                                        <EuiFlexItem grow={false}>
                                            <EuiText>
                                                <p>
                                                    <b>{props.locationPin}</b>
                                                </p>
                                            </EuiText>
                                        </EuiFlexItem>
                                        <EuiFlexItem grow={false}>
                                            <EuiText>
                                                <p>
                                                    <b>{props.phoneNumber}</b>
                                                </p>
                                            </EuiText>
                                        </EuiFlexItem>
                                    </EuiFlexGroup>
                                </EuiFlexItem> */}
                        </EuiFlexGroup>
                    </EuiModalBody>
                    <EuiModalFooter>
                        <EuiButton onClick={() => closeModal()} fill>
                            Close
                        </EuiButton>
                    </EuiModalFooter>
                </EuiModal>
                //  </EuiOverlayMask>
            )}
        </>
    );
};
