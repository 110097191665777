import { useSelector } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import React, { useEffect, useState } from 'react';
import { EuiFlexGroup, EuiFlexItem, EuiPanel, EuiSpacer, EuiText } from '@elastic/eui';
import ItemVariantsList from './ItemVariantsList';
import ItemLinkOrderingModes from './ItemLinkOrderingModes';

const CategoryItems = ({ item, fieldIndex, category_id }) => {
    const petPoojaItems = useSelector((state) => state.petPoojaReducer.petPoojaItems);
    const [selectedItem, setSelectedItem] = useState([]);
    const languageId = useSelector((state) => state.language.languageId);
    const linkedItems = useSelector((state) => state.petPoojaReducer.petPoojaLinkedItems);
    const [itemsOptions, setItemsOptions] = useState([]);
    const [variantOptions, setVariantOptions] = useState([]);
    const selectedOutletId = useSelector((state) => state.outlet.selectedOutletId);
    const outletOrderingModesEnabled = useSelector((state) => state.outlet.orderingModes)?.filter(
        (orderingMode) => orderingMode?.is_enabled
    );

    const {
        control,
        formState: { errors },
    } = useFormContext({
        mode: 'all',
    });

    useEffect(() => {
        if (petPoojaItems?.length) {
            let filterSelectedItem = linkedItems.filter((itemData) => {
                return itemData.module_id == item.item_id && itemData.module_name === 'item';
            });

            let optionItems = [];
            for (const petPoojaItem of petPoojaItems) {
                optionItems.push({
                    id: petPoojaItem.itemid,
                    itemid: petPoojaItem.itemid,
                    uuid: petPoojaItem.itemid.toString(),
                    label: petPoojaItem.itemname,
                    title: petPoojaItem.itemname,
                    module_id: item.item_id,
                    module_name: !item?.variants?.length ? 'item' : 'variant_item',
                    outlet_id: selectedOutletId,
                    third_party_module_id: petPoojaItem.itemid,
                    item_packingcharges: petPoojaItem.item_packingcharges,
                    ...item,
                });
            }
            const filterSelectedPetPoojaItem = linkedItems.filter((itemData) => {
                return (
                    itemData.module_id == item.item_id &&
                    (itemData.module_name === 'item' || itemData.module_name === 'variant_item')
                );
            });
            setItemsOptions(optionItems);

            let filterItems = petPoojaItems.filter(
                (item) => item?.itemid == filterSelectedPetPoojaItem?.[0]?.third_party_module_id
            );
            if (filterItems?.length) {
                let variants = [];
                filterItems[0]?.variation?.forEach((option) => {
                    variants.push({
                        id: option.variationid,
                        label: option.name,
                        module_name: 'variant_option',
                        outlet_id: selectedOutletId,
                        third_party_service_provider: 'pet-pooja',
                        third_party_module_id: option.variationid,
                        variationid: option.variationid,
                        // module_id: item.item_id,

                        ...option,
                    });
                });
                setVariantOptions(variants);
            }

            if (!filterSelectedItem.length) {
                setSelectedItem([]);
                return;
            }
            let obj = { id: filterSelectedItem[0].third_party_module_id, label: filterSelectedItem[0].title };
            setSelectedItem([obj]);
        }
    }, [petPoojaItems, linkedItems, item, languageId, selectedOutletId]);

    return (
        <>
            <EuiPanel>
                <EuiFlexGroup direction='row' justifyContent='spaceBetween' alignItems='flexStart'>
                    <EuiFlexItem grow={false}>
                        <EuiText>{item.internal_name}</EuiText>
                    </EuiFlexItem>

                    <EuiFlexItem grow={false} style={{ minWidth: 300 }}>
                        {item.ordering_modes?.map((orderingMode, modeIndex) => {
                            return outletOrderingModesEnabled?.map((enabledMode) => {
                                return (
                                    <React.Fragment key={enabledMode.restaurant_ordering_mode_id.toString()}>
                                        {enabledMode.restaurant_ordering_mode_id ===
                                        orderingMode.restaurant_ordering_mode_id ? (
                                            <ItemLinkOrderingModes
                                                key={
                                                    orderingMode.restaurant_ordering_mode_id.toString() +
                                                    modeIndex.toString()
                                                }
                                                control={control}
                                                orderingMode={orderingMode}
                                                errors={errors}
                                                category_id={category_id}
                                                fieldIndex={fieldIndex}
                                                modeIndex={modeIndex}
                                                itemsOptions={itemsOptions}
                                                setSelectedItem={setSelectedItem}
                                                item={item}
                                                setVariantOptions={setVariantOptions}
                                            />
                                        ) : null}
                                    </React.Fragment>
                                );
                            });
                        })}
                    </EuiFlexItem>
                </EuiFlexGroup>
            </EuiPanel>
            <EuiSpacer />
            {item?.variants?.[0]?.options?.length ? (
                <>
                    {item.variants[0].options.map((option, index) => {
                        return (
                            <ItemVariantsList
                                key={option.id.toString()}
                                variantOptions={variantOptions}
                                fieldIndex={fieldIndex}
                                optionIndex={index}
                                selectedParentOption={selectedItem}
                                option={option}
                                item={item}
                                petPoojaItemList={itemsOptions}
                                category_id={category_id}
                            />
                        );
                    })}
                </>
            ) : null}
        </>
    );
};

export default React.memo(CategoryItems);
