import {
    EuiComboBox,
    EuiFlexGroup,
    EuiFlexItem,
    EuiFormRow,
    EuiPanel,
    EuiSpacer,
    EuiText,
    EuiTextColor,
} from '@elastic/eui';
import { isEmpty } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import AddonChoiceOrderingModes from './AddonChoiceOrderingModes';

const AddonGroupLink = ({ group, groupOptions, setStateToSetDirtyFalse }) => {
    const {
        control,
        formState: { errors, isDirty },
        setValue,
        watch,
    } = useFormContext({
        mode: 'all',
    });
    const { append, fields } = useFieldArray({
        control,
        name: `addon_${group.addonGroupId}`,
    });
    const outletOrderingModesEnabled = useSelector((state) => state.outlet.orderingModes)?.filter(
        (orderingMode) => orderingMode?.is_enabled
    );
    const [petpoojaAddonChoices, setPetpoojaAddonChoices] = useState([]);
    const [selectedChoices, setSelectedChoices] = useState([]);
    const [errorMessageCheck, setErrorMessageCheck] = useState('');
    const [groupName, setGroupName] = useState('');
    const linkedItems = useSelector((state) => state.petPoojaReducer.petPoojaLinkedAddons);

    useEffect(() => {
        if (!isDirty) {
            setErrorMessageCheck([]);
        }
    }, [isDirty]);

    useEffect(() => {
        // console.log('group', group);
        // petPoojaChoices
        // addOnChoices
        setPetpoojaAddonChoices(group.petPoojaChoices);
    }, [group]);

    useEffect(() => {
        if (!isEmpty(group)) {
            append({ ...group });
            setStateToSetDirtyFalse((prevItems) => [...prevItems, { ...group }]);
            setGroupName(`addon_${group.addonGroupId}[0].selectedGroup`);
        }

        return function cleanup() {
            setGroupName('');
        };
    }, [append, group, setStateToSetDirtyFalse]);

    const handleAddonGroupSelection = useCallback(
        (selectedValue, item) => {
            setPetpoojaAddonChoices(selectedValue?.length ? selectedValue[0]?.addOnChoices : []);
            setValue(groupName, selectedValue, { shouldDirty: true });
            if (selectedValue?.length) {
                selectedValue[0].module_id = group.addonGroupId;
            }

            // UNLINK LOGIC
            if (!selectedValue?.length && item?.length && linkedItems?.length) {
                const currentRemovedItem = item[0];
                for (const linkedItem of linkedItems) {
                    if (
                        linkedItem.third_party_module_id == currentRemovedItem.third_party_module_id &&
                        currentRemovedItem.module_id == linkedItem.module_id
                    ) {
                        if (watch('menu_unlinks')?.length) {
                            setValue('menu_unlinks', [...watch('menu_unlinks'), currentRemovedItem], {
                                shouldDirty: true,
                            });
                        } else {
                            setValue('menu_unlinks', [currentRemovedItem], { shouldDirty: true });
                        }
                    }
                }
            }
            // REMOVE UNLINK ITEMS FROM UNLINK STATE IF LINKED BACK

            if (selectedValue?.length && watch('menu_unlinks')?.length) {
                const removeItemFromUnlinkState = watch('menu_unlinks')?.filter((menu_unlink) => {
                    return !(
                        menu_unlink.third_party_module_id === selectedValue[0].third_party_module_id &&
                        selectedValue[0].module_id === menu_unlink.module_id
                    );
                });

                setValue('menu_unlinks', removeItemFromUnlinkState, { shouldDirty: true });
            }
        },
        [group.addonGroupId, groupName, linkedItems, setValue, watch]
    );

    const conditionCheck = useCallback(
        (selectedItem) => {
            console.log('selectedItem', selectedItem);
            console.log('group', group);
            if (!selectedItem?.length) return false;

            if (group.addOnChoices?.length) {
                if (selectedItem?.[0]?.addOnChoices?.length >= group.addOnChoices?.length) {
                    setErrorMessageCheck(``);
                    return false;
                } else {
                    setErrorMessageCheck(`Choices mismatch`);
                    return true;
                }
            }

            // if (
            //     !(group.addOnChoices.length > 0 && selectedItem?.[0]?.addOnChoices?.length > 0) ||
            //     (group.addOnChoices.length === 0 && selectedItem?.[0]?.addOnChoices?.length === 0)
            // ) {
            //     setErrorMessageCheck(`Choices mismatch`);
            //     return true;
            // }

            return false;
        },
        [group.addOnChoices]
    );

    console.log('errorMessageCheck', errorMessageCheck);

    return (
        <React.Fragment>
            <EuiPanel style={{ marginLeft: '20px' }}>
                <EuiFlexGroup direction='row' justifyContent='spaceBetween' alignItems='flexStart'>
                    <EuiFlexItem grow={false}>
                        <EuiText>{group.internal_name}</EuiText>
                    </EuiFlexItem>

                    <EuiFlexItem grow={false} style={{ minWidth: 300 }}>
                        <EuiFormRow
                            display={errors?.message}
                            error={errors[groupName] ? errors[groupName]?.message : null}
                        >
                            <Controller
                                control={control}
                                name={groupName}
                                rules={{
                                    validate: {
                                        positive: (selectedItem) => {
                                            return conditionCheck(selectedItem) ? errorMessageCheck : null;
                                        },
                                    },
                                }}
                                render={({ field }) => {
                                    return (
                                        <EuiComboBox
                                            options={groupOptions}
                                            placeholder='Select group'
                                            isClearable={true}
                                            singleSelection={true}
                                            selectedOptions={field.value}
                                            // onChange={field.onChange}
                                            onChange={(selectedValue) => {
                                                field.onChange(selectedValue);
                                                handleAddonGroupSelection(selectedValue, field.value);
                                            }}
                                            onBlur={field.onBlur}
                                        />
                                    );
                                }}
                            />
                        </EuiFormRow>
                        <EuiSpacer size='s' />
                        <EuiTextColor color='danger'>{errorMessageCheck}</EuiTextColor>
                    </EuiFlexItem>
                </EuiFlexGroup>
            </EuiPanel>
            <EuiSpacer />
            <EuiFlexGroup
                style={{ marginLeft: '40px' }}
                direction='column'
                justifyContent='spaceBetween'
                alignItems='flexStart'
                gutterSize='none'
            >
                {fields?.[0]?.addOnChoices?.map((choice, choiceIndex) => {
                    return (
                        <EuiFlexItem key={choice?.choice_id.toString()}>
                            <EuiPanel>
                                <EuiFlexGroup>
                                    <EuiFlexItem grow={false}>
                                        <EuiText>{choice?.name}</EuiText>
                                    </EuiFlexItem>
                                    <EuiFlexItem grow={false} style={{ minWidth: 300 }}>
                                        {choice?.orderingModes?.map((orderingMode, modeIndex) => {
                                            return outletOrderingModesEnabled?.map((enabledMode) => {
                                                return (
                                                    <React.Fragment
                                                        key={enabledMode.restaurant_ordering_mode_id.toString()}
                                                    >
                                                        {enabledMode.restaurant_ordering_mode_id ===
                                                        orderingMode.restaurant_ordering_mode_id ? (
                                                            <AddonChoiceOrderingModes
                                                                orderingMode={orderingMode}
                                                                choiceIndex={choiceIndex}
                                                                addonGroupId={group.addonGroupId}
                                                                modeIndex={modeIndex}
                                                                groupName={groupName}
                                                                choice={choice}
                                                                petpoojaAddonChoices={petpoojaAddonChoices}
                                                                // .filter(
                                                                //     (petpoojaChoices) =>
                                                                //         !selectedChoices.some(
                                                                //             (selectedChoice) =>
                                                                //                 selectedChoice.petpoojaAddonChoice.choice_id ===
                                                                //                 petpoojaChoices.choice_id
                                                                //         )
                                                                // )
                                                                setSelectedChoices={setSelectedChoices}
                                                            />
                                                        ) : null}
                                                    </React.Fragment>
                                                );
                                            });
                                        })}
                                    </EuiFlexItem>
                                </EuiFlexGroup>
                            </EuiPanel>
                            <EuiSpacer />
                        </EuiFlexItem>
                    );
                })}
            </EuiFlexGroup>
            <EuiSpacer />
        </React.Fragment>
    );
};

export default React.memo(AddonGroupLink);
