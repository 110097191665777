import { EuiFlyout, EuiFlyoutBody, EuiFlyoutHeader, EuiTitle, EuiFlyoutFooter, EuiButton } from '@elastic/eui';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { mapItemsToCategory } from '../../../api/menu/MapItemsToCategory';
import { SET_MAP_ITEM_LIST } from '../../../reduxStore/types/menu';
import { fetchCategoryItem } from './Api/FetchCatalogueInfo';
import ShiftItemList from './shiftItemList';

const MapItemList = ({ category, mapItemsFlyout, setMapItemsFlyout, isOutletMenu, setItemListState }) => {
    const dispatch = useDispatch();
    const categories = useSelector((state) => state.menuReducer.restaurantCategories);
    const languageId = useSelector((state) => state.language.languageId);
    const item_ids = useSelector((state) => state.menu.mapItemList);
    const handleClose = useCallback(() => {
        setMapItemsFlyout(false);
    }, [setMapItemsFlyout]);

    const handleItemMapping = useCallback(() => {
        let id = category.category_id;

        const items = {
            item_ids: item_ids,
            category_id: id,
        };

        handleClose();

        mapItemsToCategory(items).then((response) => {
            if (response.success) {
                dispatch({
                    type: SET_MAP_ITEM_LIST,
                    payload: [],
                });
                fetchCategoryItem(id, languageId).then((response) => {
                    if (response.success) {
                        setItemListState(response.items);
                    }
                });
            }
        });
    }, [category.category_id, item_ids, handleClose, dispatch, languageId, setItemListState]);

    return (
        <>
            {mapItemsFlyout ? (
                <EuiFlyout onClose={handleClose} aria-labelledby='map_items'>
                    <EuiFlyoutHeader hasBorder>
                        <EuiTitle size='m'>
                            <h2>Select item to map</h2>
                        </EuiTitle>
                    </EuiFlyoutHeader>

                    <EuiFlyoutBody>
                        {categories.map((categoryData) =>
                            category.category_id !== categoryData.category_id ? (
                                <ShiftItemList
                                    isMapItem={true}
                                    isOutletMenu={isOutletMenu}
                                    category={categoryData}
                                    categoryValue={category}
                                />
                            ) : null
                        )}
                    </EuiFlyoutBody>
                    <EuiFlyoutFooter>
                        <EuiButton fill fullWidth onClick={handleItemMapping} isDisabled={!item_ids.length}>
                            Map
                        </EuiButton>
                    </EuiFlyoutFooter>
                </EuiFlyout>
            ) : null}
        </>
    );
};

export default React.memo(MapItemList);
