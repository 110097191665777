import React, { useEffect, useState, useCallback } from 'react';
import { EuiFlexItem, EuiText, EuiButton, EuiEmptyPrompt } from '@elastic/eui';
import { useDispatch, useSelector } from 'react-redux';
import AddRider from './addRider';
import RiderList from './riderList';
import { userHasPermission } from '../../helper/auth';
import { NoOutletMessage } from '../../utils/noOutletMessage';

const flyoutCallback = (setIsFlyoutVisible) => {
    setIsFlyoutVisible(true);
};

const refetchCallback = (value, setRefetch) => {
    setRefetch(value);
};

const Rider = () => {
    const permissions = useSelector((state) => state.permission.permission);
    const selectedOutletId = useSelector((state) => state.outlet.selectedOutletId);
    const outletList = useSelector((state) => state.outlet.outletList);
    const [refetch, setRefetch] = useState(false);
    const [isFlyoutVisible, setIsFlyoutVisible] = useState(false);
    const dispatch = useDispatch();

    const closeFlyout = () => {
        setIsFlyoutVisible(false);
        dispatch({
            type: 'set-edit-rider-id',
            payload: {
                edit_rider_id: null,
            },
        });
    };

    const showFlyoutCallback = useCallback(() => {
        flyoutCallback(setIsFlyoutVisible);
    }, []);

    const setRefetchCallback = useCallback((value) => {
        refetchCallback(value, setRefetch);
    }, []);

    let flyout;
    if (isFlyoutVisible) {
        flyout = <AddRider setRefetch={setRefetch} closeFlyout={closeFlyout} getAllOutlets={outletList} />;
    }

    return (
        <>
            {flyout}
            <EuiFlexItem
                style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', marginBottom: '10px' }}
            >
                {userHasPermission(permissions, '/riders', 'write') && selectedOutletId && (
                    <EuiButton fill iconType='plusInCircleFilled' onClick={showFlyoutCallback}>
                        Add Rider
                    </EuiButton>
                )}
            </EuiFlexItem>

            {selectedOutletId ? (
                <RiderList
                    setRefetch={setRefetchCallback}
                    refetch={refetch}
                    id={selectedOutletId}
                    showFlyout={showFlyoutCallback}
                />
            ) : (
                <NoOutletMessage />
            )}
        </>
    );
};

export default Rider;
