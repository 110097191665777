import { EuiFlexGroup, EuiFlexItem, EuiForm, EuiFormRow, EuiSwitch } from '@elastic/eui';
import React, { useEffect, useState } from 'react';
import { Controller, useForm, FormProvider } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { userHasPermission } from '../../../../helper/auth';
import AutoAssignmentSelection from '../../../../components/settings/outlet-form/AutoAssignmentSelection';
import ManualInterventionTime from '../../../../components/settings/outlet-form/ManualInterventionTime';
import * as types from '../../../../reduxStore/types/outlet';
import { outletSettingsAPI } from '../../../../api/outlet/outletSettingsAPI';

const AutoAssignmentRider = ({ fetchOutletById, getOutletData }) => {
    const defaultValues = {
        rider_auto_assign_switch: 0,
    };
    const dispatch = useDispatch();
    const methods = useForm();
    const {
        handleSubmit,
        register,
        watch,
        reset,
        control,
        formState: { isDirty, errors },
        setValue,
    } = methods;
    const permissions = useSelector((state) => state.permission.permission);
    const [checked, setChecked] = useState(null);
    let defaultValue = {};

    const [list, setList] = useState([]);

    useEffect(() => {
        setList(getOutletData.outlet?.settings?.rider_service_preferences);
    }, [getOutletData.outlet]);

    useEffect(() => {
        if (getOutletData.outlet?.settings?.rider_service_preferences.length === 0) {
            let rider_services = [];
            if (getOutletData.outlet?.third_party_rider_setting?.dunzo_integration_available !== null) {
                rider_services.push({
                    id: 2,
                    service_name: 'dunzo',
                    preference_rank: 2,
                    waiting_time: 3,
                });
            }
            if (getOutletData.outlet?.third_party_rider_setting?.elt_integration_available !== null) {
                rider_services.push({
                    id: 5,
                    service_name: 'elt',
                    preference_rank: 5,
                    waiting_time: 3,
                });
            }
            if (getOutletData.outlet?.third_party_rider_setting?.is_shadowfax_integration_enabled !== null) {
                rider_services.push({
                    id: 3,
                    service_name: 'shadowfax',
                    preference_rank: 3,
                    waiting_time: 3,
                });
            }
            if (getOutletData.outlet?.settings?.enable_own_rider_service !== null) {
                rider_services.push({
                    id: 1,
                    service_name: 'in_house_rider',
                    preference_rank: 1,
                    waiting_time: 3,
                });
            }
            if (getOutletData.outlet?.third_party_rider_setting?.wefast_integration_available !== null) {
                rider_services.push({
                    id: 4,
                    service_name: 'wefast',
                    preference_rank: 4,
                    waiting_time: 3,
                });
            }
            setList(rider_services);
        }
    }, [getOutletData.outlet]);

    useEffect(() => {
        if (!userHasPermission(permissions, '/outlet-settings', 'write')) return;

        if (isDirty) {
            dispatch({
                type: 'set-portal-active',
                payload: {
                    portalActive: {
                        resetChanges: resetChanges,
                        submit: handleSubmit(onSubmit),
                    },
                },
            });
        } else {
            dispatch({
                type: 'set-portal-active',
                payload: {
                    portalActive: {},
                },
            });
        }
    }, [isDirty]);

    const resetChanges = () => {
        if (
            getOutletData.outlet?.settings?.enable_rider_auto_assignment_by_priority === 1 ||
            getOutletData.outlet?.settings?.enable_rider_auto_assignment_by_preference === 1
        ) {
            defaultValue.rider_auto_assign_switch = 1;
        } else {
            defaultValue.rider_auto_assign_switch = false;
        }
        if (getOutletData.outlet?.settings?.intervention_time) {
            if (getOutletData.outlet?.settings?.intervention_time.minutes !== null) {
                defaultValue.intervention_time_min = getOutletData.outlet?.settings?.intervention_time.minutes;
            }
            if (getOutletData.outlet?.settings?.intervention_time.seconds !== null) {
                defaultValue.intervention_time_sec = getOutletData.outlet?.settings?.intervention_time.seconds;
            }
            if (
                getOutletData.outlet?.settings?.intervention_time.minutes === 0 &&
                getOutletData.outlet?.settings?.intervention_time.seconds === 0
            ) {
                defaultValue.manual_intervention = 'no';
            } else {
                defaultValue.manual_intervention = 'yes';
            }
        } else {
            defaultValue.manual_intervention = 'no';
        }
        if (getOutletData.outlet?.settings?.enable_rider_auto_assignment_by_preference === 1) {
            defaultValue.assignment_selection = 'preference';
        }
        if (getOutletData.outlet?.settings?.enable_rider_auto_assignment_by_priority === 1) {
            defaultValue.assignment_selection = 'priority';
        }
        getOutletData.outlet?.settings?.rider_service_preferences.map((service) => {
            if (service?.service_name === 'dunzo') {
                defaultValue.dunzo = service?.waiting_time;
            }
            if (service?.service_name === 'shadowfax') {
                defaultValue.shadowfax = service?.waiting_time;
            }
            if (service?.service_name === 'in_house_rider') {
                defaultValue.in_house_rider = service?.waiting_time;
            }
            if (service?.service_name === 'wefast') {
                defaultValue.wefast = service?.waiting_time;
            }
            if (service?.service_name === 'elt') {
                defaultValue.elt = service?.waiting_time;
            }
        });

        reset({
            ...defaultValue,
        });
    };

    const onChangeSwitch = (e) => {
        setChecked(e.target.checked);
        setValue('rider_auto_assign_switch', e.target.checked ? 1 : false, {
            shouldDirty: true,
        });
    };

    const onSubmit = async (data) => {
        let value = {};
        list.map((third_party_riders) => {
            if (data?.dunzo !== null && third_party_riders?.service_name === 'dunzo') {
                third_party_riders.waiting_time = parseInt(data?.dunzo);
            }
            if (data?.shadowfax !== null && third_party_riders?.service_name === 'shadowfax') {
                third_party_riders.waiting_time = parseInt(data?.shadowfax);
            }
            if (data?.in_house_rider !== null && third_party_riders?.service_name === 'in_house_rider') {
                third_party_riders.waiting_time = parseInt(data?.in_house_rider);
            }
            if (data?.wefast !== null && third_party_riders?.service_name === 'wefast') {
                third_party_riders.waiting_time = parseInt(data?.wefast);
            }
            if (data?.elt !== null && third_party_riders?.service_name === 'elt') {
                third_party_riders.waiting_time = parseInt(data?.elt);
            }
        });
        value = {
            ...(data.rider_auto_assign_switch
                ? {
                      enable_rider_auto_assignment_by_priority: data.assignment_selection === 'priority' ? 1 : 0,
                      enable_rider_auto_assignment_by_preference: data.assignment_selection === 'preference' ? 1 : 0,
                  }
                : { enable_rider_auto_assignment_by_priority: 0, enable_rider_auto_assignment_by_preference: 0 }),
            ...(!(data.assignment_selection === 'priority') && { rider_service_preferences: list }),
            intervention_time: {
                minutes: data.intervention_time_min,
                seconds: data.intervention_time_sec,
            },
        };
        dispatch(outletSettingsAPI('auto_assignment_rider_settings', value)).then((res) => {
            if (res.success) {
                fetchOutletById();
            }
        });
        dispatch({
            type: types.SET_PORTAL_ACTIVE,
            payload: {
                portalActive: {},
            },
        });
        dispatch({
            type: types.SET_PORTAL_ACTIVE_TAB_SWITCHED,
            payload: {
                tabSwitched: false,
            },
        });
    };

    useEffect(() => {
        if (getOutletData && getOutletData.outlet) {
            resetChanges();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getOutletData]);

    return (
        <FormProvider {...methods}>
            <EuiForm style={{ maxWidth: '40vw' }} component='form' onSubmit={handleSubmit(onSubmit)}>
                <EuiFlexGroup direction='column'>
                    <EuiFlexItem style={{ fontWeight: '700', fontSize: '20px' }}>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-start' }}>
                            <p>Rider Auto Assignment</p>
                            <div style={{ marginLeft: '24px' }}>
                                <EuiFormRow
                                    isInvalid={errors.rider_auto_assign_switch}
                                    error={
                                        errors.rider_auto_assign_switch
                                            ? errors['rider_auto_assign_switch'].message
                                            : ''
                                    }
                                >
                                    <Controller
                                        render={({ field }) => (
                                            <EuiSwitch
                                                checked={field.value}
                                                disabled={!userHasPermission(permissions, '/outlet-mode', 'write')}
                                                onChange={(e) => onChangeSwitch(e)}
                                                // defaultChecked={isRiderAutoAssigned}
                                            />
                                        )}
                                        name='rider_auto_assign_switch'
                                        control={control}
                                        // defaultValue={isRiderAutoAssigned}
                                        // rules={{ required: 'Please Select Any' }}
                                        fullWidth={true}
                                    />
                                </EuiFormRow>
                            </div>
                        </div>
                    </EuiFlexItem>
                    <EuiFlexItem>
                        <p style={{ color: '#6a717d' }}>
                            When turned ON, the system will automatically assign a rider for an order based on number of
                            factors such as available delivery channels, assignment selection, intervention time etc.
                        </p>
                    </EuiFlexItem>
                </EuiFlexGroup>
                {/* || selectedAssignmentType) && checked */}
                {/* watch('rider_auto_assign_switch') && */}
                {watch('rider_auto_assign_switch') && <AutoAssignmentSelection list={list} setList={setList} />}
                {watch('rider_auto_assign_switch') && <ManualInterventionTime />}
            </EuiForm>
        </FormProvider>
    );
};

export default AutoAssignmentRider;
