import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { EuiFormRow, EuiSpacer } from '@elastic/eui';
import FormGroupDescription from '../../../components/formGroupDescription';
import TextField from '../../../components/Form/TextField';
import ColorField from '../../../components/Form/ColorField';
import { useDispatch, useSelector } from 'react-redux';
import { restaurantSettingsAPI } from '../../../api/restaurantSettings/restaurantSettingsAPI';
import * as types from '../../../reduxStore/types/outlet';
import API from '../../../api/axios/API';
import { isEmpty } from 'lodash';
import { userHasPermission } from '../../../helper/auth';
import LanguageBannerImage from '../../Banners/Components/LanguageBannerImage';

const BrandRestaurantSettings = (props) => {
    const { restaurantResponse, fetchRestaurant } = props;
    const {
        handleSubmit,
        control,
        watch,
        reset,
        setValue,

        formState: { isDirty, errors },
    } = useForm();
    const dispatch = useDispatch();
    const [imageDeleteState, setImageDeleteState] = useState({});
    const [selectedLogoImages, setSelectedLogoImages] = useState({});
    const [selectedFooterImage, setSelectedFooterImage] = useState({});
    const permissions = useSelector((state) => state.permission.permission);
    const restaurantDescriptions = useSelector((state) => state.auth.restaurantDescriptions);
    const languages = useSelector((state) => state.language.availableLanguages);

    useEffect(() => {
        if (!userHasPermission(permissions, '/restaurant-settings', 'write')) return;
        if (isDirty) {
            dispatch({
                type: 'set-portal-active',
                payload: {
                    portalActive: {
                        resetChanges: resetChanges,
                        submit: handleSubmit(onSubmit),
                    },
                },
            });
        } else {
            dispatch({
                type: 'set-portal-active',
                payload: {
                    portalActive: {},
                },
            });
        }
    }, [isDirty]);

    useEffect(() => {
        if (restaurantResponse && restaurantResponse.restaurant) {
            resetChanges();
        }
    }, [restaurantResponse]);

    const resetChanges = () => {
        let defaultValue = {};

        let restaurantData = restaurantResponse.restaurant;

        defaultValue.languages = restaurantData.languages.map((item, index) => ({
            label: item.language_label,
            language: item.language,
            language_label: item.language_label,
        }));
        defaultValue.brand_color_primary = restaurantData.brand_color_primary;
        defaultValue.brand_color_secondary = restaurantData.brand_color_secondary;

        if (restaurantData?.settings?.logo_intro_image_url) {
            setSelectedLogoImages(restaurantData?.settings?.logo_intro_image_url);
        }

        if (restaurantData?.settings?.logo_footer_image_url) {
            setSelectedFooterImage(restaurantData?.settings?.logo_footer_image_url);
        }

        defaultValue.logo_footer_image =
            restaurantData.settings && restaurantData.settings.logo_footer_image_url
                ? {
                      ...restaurantData.settings.logo_footer_image_url,
                      src:
                          restaurantData.settings.logo_footer_image_url.image_url &&
                          restaurantData.settings.logo_footer_image_url.image_url,
                  }
                : null;

        defaultValue.logo_intro_image_id =
            restaurantData.settings && restaurantData.settings.logo_intro_image_url
                ? {
                      ...restaurantData.settings.logo_intro_image_url,
                      src:
                          restaurantData.settings.logo_intro_image_url.image_url &&
                          restaurantData.settings.logo_intro_image_url.image_url,
                  }
                : null;

        let translationObject = {
            like_to_order: {},
            special_instruction_label: {},
        };

        languages?.map((item) => {
            translationObject.special_instruction_label[item.language] =
                restaurantData.translations.special_instruction_label[item.language];
            translationObject.like_to_order[item.language] = restaurantData.translations.like_to_order[item.language];
            if (translationObject.like_to_order[item.language] === undefined) {
                translationObject.like_to_order[item.language] = '';
            }
            if (translationObject.special_instruction_label[item.language] === undefined) {
                translationObject.special_instruction_label[item.language] = '';
            }
        });

        defaultValue.translations = translationObject;

        setImageDeleteState({
            logo_footer_image: defaultValue.logo_footer_image,
            logo_intro_image_id: defaultValue.logo_intro_image_id,
        });

        reset({
            ...defaultValue,
        });
    };

    const imageChecker = useCallback(
        (data) => {
            if (data.logo_footer_image) {
                if (
                    imageDeleteState.logo_footer_image &&
                    data.logo_footer_image.id !== imageDeleteState.logo_footer_image.id
                ) {
                    try {
                        API.delete(`/restaurants/:restaurantId/uploads/${imageDeleteState.logo_footer_image.id}`);
                    } catch (error) {
                        console.log('error in image delete');
                    }
                }
            } else {
                if (!isEmpty(imageDeleteState.logo_footer_image)) {
                    try {
                        API.delete(`/restaurants/:restaurantId/uploads/${imageDeleteState.logo_footer_image.id}`);
                    } catch (error) {
                        console.log('error in image delete');
                    }
                }
            }

            if (data.logo_intro_image_id) {
                if (
                    imageDeleteState.logo_intro_image_id &&
                    data.logo_intro_image_id.id !== imageDeleteState.logo_intro_image_id.id
                ) {
                    try {
                        API.delete(`/restaurants/:restaurantId/uploads/${imageDeleteState.logo_intro_image_id.id}`);
                    } catch (error) {
                        console.log('error in image delete');
                    }
                }
            } else {
                if (!isEmpty(imageDeleteState.logo_intro_image_id)) {
                    try {
                        API.delete(`/restaurants/:restaurantId/uploads/${imageDeleteState.logo_intro_image_id.id}`);
                    } catch (error) {
                        console.log('error in image delete');
                    }
                }
            }
        },
        [imageDeleteState?.logo_footer_image, imageDeleteState?.logo_intro_image_id]
    );

    const onSubmit = useCallback(
        (data) => {
            imageChecker(data);

            if (!isEmpty(data.logo_footer_image)) {
                data.logo_footer_image_id = data.logo_footer_image.id;
            }
            if (!isEmpty(selectedLogoImages)) {
                data.logo_footer_image_id = selectedLogoImages.id;
            }

            if (!isEmpty(data.logo_intro_image_id)) {
                data.logo_intro_image_id = data.logo_intro_image_id.id;
            }
            if (!isEmpty(selectedFooterImage)) {
                data.logo_footer_image_id = selectedFooterImage.id;
            }

            dispatch(restaurantSettingsAPI('brand_settings', data)).then((res) => {
                if (res.success) {
                    dispatch({
                        type: types.SET_PORTAL_ACTIVE,
                        payload: {
                            portalActive: {},
                        },
                    });
                    dispatch({
                        type: types.SET_PORTAL_ACTIVE_TAB_SWITCHED,
                        payload: {
                            tabSwitched: false,
                        },
                    });
                    fetchRestaurant();
                } else {
                    alert(res.message);
                }
            });
        },
        [dispatch, fetchRestaurant, imageChecker, selectedFooterImage, selectedLogoImages]
    );

    return (
        <>
            <FormGroupDescription
                title={restaurantDescriptions?.brand_logos?.display_name}
                description={restaurantDescriptions?.brand_logos?.description}
            >
                <EuiFormRow label='Brand Logo'>
                    <LanguageBannerImage
                        withRemove={true}
                        buttonLabel={'Select an image'}
                        selectedImgButtonLabel='Change Category Image'
                        imageType={'intro_logo_image'}
                        selectedImages={selectedLogoImages}
                        name='editCategory.category_image_url'
                        defaultImageValue={restaurantResponse.restaurant?.settings?.logo_intro_image_url}
                        setValue={setValue}
                        buttonPosition='default'
                        aspectRatio={{
                            width: 1,
                            height: 1,
                        }}
                        // label='Brand Logo'
                        setSelectedImages={setSelectedLogoImages}
                    />
                </EuiFormRow>

                <EuiSpacer />
                <EuiFormRow label='Footer Logo'>
                    <LanguageBannerImage
                        withRemove={true}
                        buttonLabel={'Select an image'}
                        selectedImgButtonLabel='Change Category Image'
                        imageType={'intro_logo_image'}
                        selectedImages={selectedFooterImage}
                        name='editCategory.category_image_url'
                        defaultImageValue={restaurantResponse.restaurant?.settings?.logo_footer_image_url}
                        setValue={setValue}
                        buttonPosition='default'
                        aspectRatio={{
                            width: 1,
                            height: 1,
                        }}
                        setSelectedImages={setSelectedFooterImage}
                    />
                </EuiFormRow>
            </FormGroupDescription>
            <FormGroupDescription
                title={restaurantDescriptions?.brand_colors?.display_name}
                description={restaurantDescriptions?.brand_colors?.description}
            >
                <ColorField
                    errors={errors}
                    label='Brand Color (Primary color) '
                    name='brand_color_primary'
                    placeholder='Please brand color'
                    rules={{ required: 'Please enter primary color' }}
                    control={control}
                    watch={watch}
                />
                <ColorField
                    errors={errors}
                    label='Brand Color (Secondary color) '
                    name='brand_color_secondary'
                    placeholder='Please brand color'
                    rules={{ required: 'Please enter secondary color' }}
                    control={control}
                    watch={watch}
                />
            </FormGroupDescription>

            <FormGroupDescription
                title={restaurantDescriptions?.greeting_message?.display_name}
                description={restaurantDescriptions?.greeting_message?.description}
            >
                {watch('languages') &&
                    watch('languages').length > 0 &&
                    watch('languages').map((item, index) => (
                        <>
                            <TextField
                                isInvalid={
                                    errors.translations &&
                                    errors.translations.like_to_order &&
                                    errors.translations.like_to_order.hasOwnProperty(item.language)
                                }
                                error={
                                    errors.translations &&
                                    errors.translations.like_to_order &&
                                    errors.translations.like_to_order.hasOwnProperty(item.language) &&
                                    errors.translations.like_to_order[item.language]
                                        ? errors.translations.like_to_order[item.language].message
                                        : ''
                                }
                                errors={errors}
                                label={index === 0 ? <strong>Greeting message</strong> : ''}
                                name={`translations.like_to_order.${item.language}`}
                                placeholder={`Greeting message: ${item.language_label} `}
                                control={control}
                                watch={watch}
                            />
                        </>
                    ))}
            </FormGroupDescription>

            <FormGroupDescription
                title={restaurantDescriptions?.special_instruction_message?.display_name}
                description={restaurantDescriptions?.special_instruction_message?.description}
            >
                {watch('languages') &&
                    watch('languages').length > 0 &&
                    watch('languages').map((item, index) => (
                        <>
                            <TextField
                                isInvalid={
                                    errors.translations &&
                                    errors.translations.special_instruction_label &&
                                    errors.translations.special_instruction_label.hasOwnProperty(item.language)
                                }
                                error={
                                    errors.translations &&
                                    errors.translations.special_instruction_label &&
                                    errors.translations.special_instruction_label.hasOwnProperty(item.language) &&
                                    errors.translations.special_instruction_label[item.language]
                                        ? errors.translations.special_instruction_label[item.language].message
                                        : ''
                                }
                                errors={errors}
                                label={index === 0 ? <strong>Special instruction message</strong> : ''}
                                name={`translations.special_instruction_label.${item.language}`}
                                placeholder={`Special instruction message: ${item.language_label} `}
                                control={control}
                                watch={watch}
                            />
                        </>
                    ))}
            </FormGroupDescription>
        </>
    );
};

export default BrandRestaurantSettings;
