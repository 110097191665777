import React, { useEffect, useState } from 'react';
import {
    EuiButton,
    EuiConfirmModal,
    EuiFlexGroup,
    EuiFlexItem,
    EuiOverlayMask,
    EuiModalFooter,
    EuiModal,
    EuiModalHeader,
    EuiModalHeaderTitle,
    EuiModalBody,
    EuiText,
    EuiHealth,
    EuiHighlight,
    EuiComboBox,
    EuiForm,
    EuiFormRow,
    EuiFieldNumber,
} from '@elastic/eui';
import { ReactComponent as ShiftArrow } from '../../assets/img/shiftArrow.svg';
import './styleTextBox.css';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import './style.css';
import { useForm, Controller } from 'react-hook-form';
import OtpInput from 'react-otp-input';

const OtpModal = (props) => {
    const { placeOrder, setOtpModalState, setParentValue, generateOTPForRedemePoints, status } = props;

    const {
        register,
        handleSubmit,
        control,
        watch,
        reset,
        setValue,

        formState: { errors },
    } = useForm({
        defaultValues: {},
    });

    const [otpState, setOtpState] = useState('');
    const customerInformation = useSelector((state) => state.billing.customerInformation);
    const handleChange = (otp) => setOtpState(otp);

    useEffect(() => {
        generateOTPForRedemePoints();
    }, []);

    const closeModal = () => {
        setOtpModalState(false);
    };

    const onSubmit = () => {
        setParentValue('otp', otpState);
        if (status.bill) {
            document.getElementById('printButton').click();
        } else {
            placeOrder();
        }

        closeModal();
    };

    let initalMinutes = 0;
    let initialSeconds = 10;
    let otpLength = 4;

    const [minutes, setMinutes] = useState(initalMinutes);
    const [seconds, setSeconds] = useState(initialSeconds);

    useEffect(() => {
        let myInterval = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1);
            }
            if (seconds === 0) {
                if (minutes === 0) {
                    clearInterval(myInterval);
                } else {
                    setMinutes(minutes - 1);
                    setSeconds(59);
                }
            }
        }, 1000);

        return () => {
            clearInterval(myInterval);
        };
    });

    const otpCall = () => {
        generateOTPForRedemePoints();
        setMinutes(initalMinutes);
        setSeconds(initialSeconds);
    };

    return (
        <>
            <EuiOverlayMask>
                {/* <EuiForm component='form' onSubmit={handleSubmit(onSubmit)}> */}
                <EuiModal onClose={closeModal}>
                    <EuiModalHeader>
                        <EuiModalHeaderTitle>
                            <h1>OTP Verification</h1>
                        </EuiModalHeaderTitle>
                    </EuiModalHeader>
                    <EuiModalBody>
                        {/* <div style={{width: '100%', height: '100%'}}> */}
                        {/* <EuiFlexItem> */}
                        {/* <EuiFormRow
                                        style={{marginTop: '0px', width: '48%'}}
                                        label={'OTP'}
                                        fullWidth={true}
                                        error={errors['otp_value'] ? errors['otp_value'].message : ''}
                                        isInvalid={errors['otp_value'] && errors['otp_value'].message}
                                    >
                                        <Controller
                                            render={(field) => <EuiFieldNumber value={field.value} onChange={field.onChange} />}
                                            name={'otp_value'}
                                            placeholder='OTP code'
                                            control={control}
                                            fullWidth={true}
                                            rules={{
                                                required: 'Please enter otp code',
                                            }}
                                            isInvalid={errors['otp_value'] && errors['otp_value'].message}
                                        />
                                    </EuiFormRow> */}

                        {/* </EuiFlexItem> */}
                        {/* </div> */}
                        <EuiFlexGroup
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                                height: '100%',
                            }}
                        >
                            <EuiFlexItem>
                                <p>One Time Password has been sent to your mobile, </p>
                                <p>
                                    {customerInformation.mobile_number}, please enter same here to verify. Valid for 10
                                    minutes.
                                </p>
                            </EuiFlexItem>
                            <EuiFlexItem
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                    marginTop: '10px',
                                }}
                            >
                                <OtpInput
                                    value={otpState}
                                    onChange={handleChange}
                                    numInputs={otpLength}
                                    separator={<span>-</span>}
                                    inputStyle={{ height: '45px', width: '45px', padding: '10px' }}
                                />
                            </EuiFlexItem>

                            <EuiFlexItem
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    marginTop: '15px',
                                }}
                            >
                                <div>
                                    {minutes === 0 && seconds === 0 ? (
                                        '00 : 00'
                                    ) : (
                                        <h1>
                                            {' '}
                                            {minutes < 10 ? `0${minutes}` : minutes}:
                                            {seconds < 10 ? `0${seconds}` : seconds}
                                        </h1>
                                    )}
                                </div>

                                <div style={{ marginTop: '15px' }}>
                                    Not received OTP?{' '}
                                    <EuiButton
                                        onClick={() => otpCall()}
                                        style={{ height: '30px' }}
                                        fill
                                        disabled={!(minutes === 0 && seconds === 0)}
                                    >
                                        Resend Now
                                    </EuiButton>
                                </div>
                            </EuiFlexItem>
                        </EuiFlexGroup>
                    </EuiModalBody>
                    <EuiModalFooter>
                        <EuiButton style={{ marginLeft: '15px', fontSize: '14px' }} onClick={closeModal}>
                            CANCEL
                        </EuiButton>
                        {/* type="submit" */}
                        <EuiButton
                            onClick={onSubmit}
                            disabled={!(otpState.length == otpLength)}
                            style={{ fontSize: '14px', backgroundColor: '#285af6', border: 'transparent' }}
                            fill
                        >
                            Submit
                        </EuiButton>
                    </EuiModalFooter>
                </EuiModal>
                {/* </EuiForm> */}
            </EuiOverlayMask>
        </>
    );
};

export default OtpModal;
