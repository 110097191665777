import React, { useCallback, useState } from 'react';
import { EuiTableRow, EuiTableRowCell, EuiText, EuiFlexItem, EuiNotificationBadge } from '@elastic/eui';
import { useSelector, useDispatch } from 'react-redux';
import { agmarkSign } from '../../helper/agmarkSign';
import { capitalize } from '../../helper/capitalize';
import * as types from '../../reduxStore/types/billing';
import _ from 'lodash';

const ItemListTableRow = (props) => {
    const { item, addItemWithoutAddon } = props;
    const billingItem = useSelector((state) => state.billing.billingItem);
    const dispatch = useDispatch();

    const [itemCountState, setItemCountState] = useState(0);

    const delayedQuery = useCallback(
        _.debounce((item, itemCountState, billingItem) => {
            addItemWithoutAddon(item, itemCountState, billingItem);
            setItemCountState(0);
        }, 500),
        []
    );

    const itemCount = (id) => {
        let count = 0;

        if (billingItem[id]) {
            count += billingItem[id].quantity;

            if (billingItem[id].customizations && billingItem[id].customizations.length > 0) {
                billingItem[id].customizations.map((addon) => {
                    count += addon.addon_information.quantity;
                });
            }
        }

        return count;
    };

    const onClickRow = () => {
        if ((item.addon_groups && item.addon_groups.length > 0) || (item.variants && item.variants.length)) {
            dispatch({
                type: types.SET_ADDON_MODAL_VISIBLE,
                payload: {
                    addonModalVisible: true,
                },
            });
        } else {
            setItemCountState((prevState) => ++prevState);
            delayedQuery(item, itemCountState + 1, billingItem);
        }
        dispatch({
            type: types.SET_SELECTED_ITEM,
            payload: {
                selectedItem: item,
            },
        });
    };

    return (
        <>
            <EuiTableRow onClick={onClickRow}>
                <EuiTableRowCell key='data1' width='90%'>
                    <EuiFlexItem style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        {itemCount(`${item.id}i`) ? (
                            <EuiNotificationBadge>{itemCount(`${item.id}i`)}</EuiNotificationBadge>
                        ) : (
                            ''
                        )}

                        <div style={{ paddingLeft: '10px' }}>{agmarkSign(item.food_type)}</div>
                        <EuiFlexItem style={{ paddingLeft: '10px' }}>
                            <h4>
                                <b>{capitalize(item.title)}</b>
                            </h4>
                            <span className='itemDescriptionForTable'>Description goes here..</span>
                        </EuiFlexItem>
                    </EuiFlexItem>
                </EuiTableRowCell>
                <EuiTableRowCell key='data1' width='10%'>
                    <EuiText>₹{item.price}</EuiText>
                </EuiTableRowCell>
            </EuiTableRow>
        </>
    );
};

export default ItemListTableRow;
