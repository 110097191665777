import { EuiCollapsibleNavGroup, EuiText } from '@elastic/eui';
import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { fetchCategoryItem } from './Api/FetchCatalogueInfo';
import CheckableItem from './checkableItem';

const SubCategoryShift = ({ isOutletMenu, categoryValue, isMapItem, subCategories, upSellingSelection }) => {
    const [listDetails, setListDetails] = useState({
        items: [],
        isLoading: false,
    });
    const languageId = useSelector((state) => state.language.languageId);

    const itemListRender = (itemList) => {
        let list = [];
        if (itemList?.length) {
            itemList.map((item) => {
                list.push(
                    <CheckableItem
                        key={item.item_id.toString()}
                        isMapItem={isMapItem}
                        isOutletMenu={isOutletMenu}
                        item={item}
                        upSellingSelection={upSellingSelection}
                        itemList={itemList}
                    />
                );
            });
        }

        return list;
    };

    const handleCategoryClick = useCallback(
        (selectedCategory) => {
            setListDetails((prevState) => ({ ...prevState, isLoading: true }));
            let items = [];
            fetchCategoryItem(selectedCategory.category_id, languageId)
                .then((response) => {
                    if (response.success) {
                        items.push(...response.items);
                        setListDetails((prevState) => ({ ...prevState, items: items, isLoading: false }));
                    } else {
                        setListDetails((prevState) => ({ ...prevState, isLoading: false }));
                    }
                })
                .catch((err) => {
                    setListDetails((prevState) => ({ ...prevState, isLoading: false }));
                });
        },
        [languageId]
    );
    return (
        <>
            {categoryValue?.category_id != subCategories?.category_id ? (
                <EuiCollapsibleNavGroup
                    key={subCategories.category_id.toString()}
                    background='light'
                    title={
                        <EuiText>
                            <h3>
                                {isOutletMenu
                                    ? subCategories?.translations?.title[languageId]
                                    : subCategories?.internal_name}
                            </h3>
                        </EuiText>
                    }
                    isCollapsible={true}
                    initialIsOpen={false}
                    arrowDisplay={'left'}
                    isLoading={listDetails.isLoading}
                    onToggle={(isOpen) => {
                        if (isOpen && !listDetails.items.length) {
                            handleCategoryClick(subCategories);
                        }
                    }}
                >
                    {itemListRender(listDetails.items)}
                </EuiCollapsibleNavGroup>
            ) : (
                ''
            )}
        </>
    );
};

export default React.memo(SubCategoryShift);
