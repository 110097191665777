import React, { useEffect, useState, useCallback } from 'react';
import { MainLayout } from '../../components/layout/mainLayout/mainLayout';
import { EuiFlexGroup, EuiFlexItem, EuiSpacer, EuiButtonEmpty, EuiPanel, EuiText } from '@elastic/eui';
import { useSelector } from 'react-redux';
import * as types from '../../reduxStore/types/orders';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import LiveOrdersTableForPast from '../../components/liveOrders/liveOrdersTableForPast';
import LiveOrderDetailsForPast from '../../components/liveOrders/liveOrderDetailsPastOrders';
import '../../components/liveOrders/style.css';
import { downloadPastOrderCsvFile } from '../../api/pastOrder/downloadPastOrderCsvFile';
import CustomDatePicker from '../../components/Credits/CustomDatePicker';

const PastOrdersPage = () => {
    const [startDateTime, setStartDateTime] = useState(moment());
    const [endDateTime, setEndDateTime] = useState(moment());
    const languageId = useSelector((state) => state.language.languageId);

    const [state, setState] = useState({
        apiDataLoaded: false,
        last_page: 0,
        current_page: 0,
        page_size: 10,
        loaderPage: false,
        totalCounts: 0,
    });
    const selectedOrderId = useSelector((state) => state.orders.selectedOrderId);
    const dispatch = useDispatch();
    const pastOrders = useSelector((state) => state.orders.pastOrders);

    const filteredOrder = pastOrders.filter((data) => data.order_id === selectedOrderId);

    useEffect(() => {
        (() => {
            if (filteredOrder.length < 1 && pastOrders.length > 0) {
                dispatch({
                    type: types.SET_SELECTED_ORDER,
                    payload: pastOrders[0].order_id,
                });
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filteredOrder]);

    const downloadPastOrder = useCallback(async () => {
        await dispatch(downloadPastOrderCsvFile(startDateTime, endDateTime, languageId));
    }, [dispatch, endDateTime, languageId, startDateTime]);

    return (
        <MainLayout isSingleScreen={true} backgroundColorChange={true}>
            <EuiFlexGroup justifyContent='spaceBetween' alignItems='center'>
                <EuiFlexItem grow={false}>
                    <CustomDatePicker
                        startDate={startDateTime}
                        setStartDate={setStartDateTime}
                        endDate={endDateTime}
                        setEndDate={setEndDateTime}
                        displayFormat='DD-MM-YYYY'
                    />
                </EuiFlexItem>

                {pastOrders.length ? (
                    <div>
                        <EuiButtonEmpty onClick={downloadPastOrder} style={{ marginRight: '10px' }}>
                            Download CSV
                        </EuiButtonEmpty>
                        <EuiText>({state.page_size} items from below page)</EuiText>
                    </div>
                ) : (
                    ''
                )}
            </EuiFlexGroup>
            <EuiSpacer />
            <EuiFlexGroup>
                <EuiFlexItem grow={6}>
                    <div>
                        <EuiPanel paddingSize='s' className='pastOrderPanel scrollbar-sm'>
                            <LiveOrdersTableForPast
                                startDateTime={startDateTime}
                                endDateTime={endDateTime}
                                state={state}
                                setState={setState}
                                isPast={true}
                            />
                        </EuiPanel>
                    </div>
                </EuiFlexItem>
                <EuiFlexItem grow={4}>
                    <div>
                        <EuiPanel paddingSize='s' className='pastOrderPanel scrollbar-sm'>
                            <LiveOrderDetailsForPast
                                startDateTime={startDateTime}
                                endDateTime={endDateTime}
                                state={state}
                                isPast={true}
                            />
                        </EuiPanel>
                    </div>
                </EuiFlexItem>
            </EuiFlexGroup>
        </MainLayout>
    );
};

export default PastOrdersPage;
