import React, { useState, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import NumberField from '../../../components/Form/NumberField';
import { EuiForm, EuiFormRow, EuiRadioGroup } from '@elastic/eui';
import FormGroupDescription from '../../../components/formGroupDescription';
import { outletSettingsAPI } from '../../../api/outlet/outletSettingsAPI';
import { useDispatch, useSelector } from 'react-redux';
import * as types from '../../../reduxStore/types/outlet';
import { userHasPermission } from '../../../helper/auth';

const ServiceRadius = ({ getOutletData, fetchOutletById }) => {
    const {
        handleSubmit,
        control,
        watch,
        reset,
        setValue,

        formState: { isDirty, errors },
    } = useForm();
    let defaultValue = {};
    const dispatch = useDispatch();
    const permissions = useSelector((state) => state.permission.permission);
    const restaurantDescriptions = useSelector((state) => state.auth.restaurantDescriptions);

    const resetChanges = () => {
        const outletData = getOutletData.outlet;
        defaultValue.latitude = outletData.outlet_address.latitude;
        defaultValue.longitude = outletData.outlet_address.longitude;
        defaultValue.service_radius = outletData.outlet_address.service_radius;
        if (
            outletData.settings.consider_own_service_radius === 1 &&
            outletData.settings.consider_third_party_rider_service_radius === 1
        ) {
            setValue('serviceability', 'in_house_riders_plus_third_party_riders', { shouldDirty: false });
            defaultValue.serviceability = 'in_house_riders_plus_third_party_riders';
        }
        if (
            outletData.settings.consider_own_service_radius === 1 &&
            outletData.settings.consider_third_party_rider_service_radius === 0
        ) {
            setValue('serviceability', 'consider_own_service_radius', { shouldDirty: false });
            defaultValue.serviceability = 'consider_own_service_radius';
        }
        reset({
            ...defaultValue,
        });
    };

    useEffect(() => {
        if (getOutletData && getOutletData.outlet) {
            resetChanges();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getOutletData]);

    useEffect(() => {
        if (!userHasPermission(permissions, '/outlet-settings', 'write')) return;

        if (isDirty) {
            dispatch({
                type: 'set-portal-active',
                payload: {
                    portalActive: {
                        resetChanges: resetChanges,
                        submit: handleSubmit(onSubmit),
                    },
                },
            });
        } else {
            dispatch({
                type: 'set-portal-active',
                payload: {
                    portalActive: {},
                },
            });
            dispatch({
                type: 'set-portal-active-tab-switched',
                payload: {
                    tabSwitched: false,
                },
            });
        }
    }, [isDirty]);

    const serviceability = [
        {
            id: `consider_own_service_radius`,
            label: 'Accept orders from customers in outlet’s service radius',
        },
        {
            id: `in_house_riders_plus_third_party_riders`,
            label: 'Accept orders from customers in Store Service Radius & 3rd Party Delivery Areas combined',
        },
    ];
    const onSubmit = async (data) => {
        if (data.serviceability === 'consider_own_service_radius') {
            data.consider_own_service_radius = 1;
            data.consider_third_party_rider_service_radius = 0;
        }
        if (data.serviceability === 'in_house_riders_plus_third_party_riders') {
            data.consider_own_service_radius = 1;
            data.consider_third_party_rider_service_radius = 1;
        }
        dispatch(outletSettingsAPI('delivery_settings', data)).then((res) => {
            if (res.success) {
                dispatch({
                    type: types.SET_PORTAL_ACTIVE,
                    payload: {
                        portalActive: {},
                    },
                });
                dispatch({
                    type: types.SET_PORTAL_ACTIVE_TAB_SWITCHED,
                    payload: {
                        tabSwitched: false,
                    },
                });
                fetchOutletById();
            } else {
                alert(res.message);
            }
        });
    };

    return (
        <EuiForm component='form' onSubmit={handleSubmit(onSubmit)} style={{ marginTop: 20 }}>
            <FormGroupDescription
                title={restaurantDescriptions?.locate_your_outlet?.display_name}
                description={restaurantDescriptions?.locate_your_outlet?.description}
            >
                <NumberField
                    label={<strong>Latitude</strong>}
                    errors={errors}
                    name='latitude'
                    placeholder='Please Enter latitude'
                    rules={{
                        required: 'Please enter latitude',
                    }}
                    step='any'
                    control={control}
                />

                <NumberField
                    label={<strong>Longitude</strong>}
                    errors={errors}
                    name='longitude'
                    placeholder='Please Enter longitude'
                    rules={{
                        required: 'Please enter longitude',
                    }}
                    step='any'
                    control={control}
                />
            </FormGroupDescription>
            <FormGroupDescription
                title={restaurantDescriptions?.service_radius?.display_name}
                description={restaurantDescriptions?.service_radius?.description}
            >
                <NumberField
                    label={<strong>Service availability radius</strong>}
                    errors={errors}
                    name='service_radius'
                    placeholder='Please Enter radius'
                    rules={{
                        required: 'Please enter radius',
                    }}
                    prepend='In Km'
                    step='any'
                    control={control}
                />
            </FormGroupDescription>

            <FormGroupDescription
                title={'Serviceability'}
                description={restaurantDescriptions?.service_radius?.description}
            >
                <EuiFormRow isInvalid={errors.serviceability} fullWidth={true}>
                    <Controller
                        render={({ onChange }) => (
                            <EuiRadioGroup
                                options={serviceability}
                                idSelected={
                                    watch('serviceability') ? watch('serviceability') : 'consider_own_service_radius'
                                }
                                onChange={(id) =>
                                    setValue(
                                        'serviceability',
                                        id === 'consider_own_service_radius'
                                            ? 'consider_own_service_radius'
                                            : id === 'in_house_riders_plus_third_party_riders'
                                            ? 'in_house_riders_plus_third_party_riders'
                                            : 'consider_own_service_radius',
                                        { shouldDirty: true }
                                    )
                                }
                                legend={{
                                    children: <span> Select what radius would you like to serve? </span>,
                                }}
                            />
                        )}
                        name='serviceability'
                        defaultValue={watch('serviceability') ? watch('serviceability') : 'consider_own_service_radius'}
                        control={control}
                        fullWidth={true}
                    />
                </EuiFormRow>
            </FormGroupDescription>
        </EuiForm>
    );
};

export default ServiceRadius;
