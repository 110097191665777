import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import NumberField from '../../../components/Form/NumberField';
import FormGroupDescription from '../../../components/formGroupDescription';
import { EuiForm } from '@elastic/eui';
import { userHasPermission } from '../../../helper/auth';
import { useSelector, useDispatch } from 'react-redux';
import { outletSettingsAPI } from '../../../api/outlet/outletSettingsAPI';

const CODLimit = ({ fetchOutletById, getOutletData }) => {
    const {
        handleSubmit,
        control,
        reset,

        formState: { isDirty, errors },
    } = useForm();
    let defaultValue = {};
    const permissions = useSelector((state) => state.permission.permission);
    const dispatch = useDispatch();
    const restaurantDescriptions = useSelector((state) => state.auth.restaurantDescriptions);

    const onSubmit = (data) => {
        dispatch(outletSettingsAPI('order_configuration', data)).then((res) => {
            if (res.success) {
                dispatch({
                    type: 'set-portal-active',
                    payload: {
                        portalActive: {},
                    },
                });
                dispatch({
                    type: 'set-portal-active-tab-switched',
                    payload: {
                        tabSwitched: false,
                    },
                });
                fetchOutletById();
            } else {
                alert(res.message);
            }
        });
    };

    const resetChanges = () => {
        const outletData = getOutletData.outlet;
        defaultValue.cash_payment_limit_on_delivery_order = outletData.settings.cash_payment_limit_on_delivery_order;
        defaultValue.cash_payment_limit_on_takeaway_order = outletData.settings.cash_payment_limit_on_takeaway_order;
        defaultValue.cash_payment_limit_on_dine_in_order = outletData.settings.cash_payment_limit_on_dine_in_order;
        reset({
            ...defaultValue,
        });
    };

    useEffect(() => {
        if (getOutletData && getOutletData.outlet) {
            resetChanges();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getOutletData]);

    useEffect(() => {
        if (!userHasPermission(permissions, '/outlet-settings', 'write')) return;

        if (isDirty) {
            dispatch({
                type: 'set-portal-active',
                payload: {
                    portalActive: {
                        resetChanges: resetChanges,
                        submit: handleSubmit(onSubmit),
                    },
                },
            });
        } else {
            dispatch({
                type: 'set-portal-active',
                payload: {
                    portalActive: {},
                },
            });
            dispatch({
                type: 'set-portal-active-tab-switched',
                payload: {
                    tabSwitched: false,
                },
            });
        }
    }, [isDirty]);

    return (
        <EuiForm component='form' onSubmit={handleSubmit(onSubmit)} style={{ marginTop: 20 }}>
            <FormGroupDescription
                title={restaurantDescriptions.cash_payment_limit_on_delivery_order.display_name}
                description={restaurantDescriptions.cash_payment_limit_on_delivery_order.description}
            >
                <NumberField
                    label={<strong></strong>}
                    errors={errors}
                    name='cash_payment_limit_on_delivery_order'
                    placeholder='COD limit for delivery'
                    rules={{
                        required: 'Please enter COD limit',
                    }}
                    prepend='₹'
                    step='any'
                    control={control}
                />
            </FormGroupDescription>
            <FormGroupDescription
                title={restaurantDescriptions.cash_payment_limit_on_takeaway_order.display_name}
                description={restaurantDescriptions.cash_payment_limit_on_takeaway_order.description}
            >
                <NumberField
                    label={<strong></strong>}
                    errors={errors}
                    name='cash_payment_limit_on_takeaway_order'
                    placeholder='COD limit for Takeaway'
                    rules={{
                        required: 'Please enter COD limit',
                    }}
                    prepend='₹'
                    step='any'
                    control={control}
                />
            </FormGroupDescription>
            <FormGroupDescription
                title={restaurantDescriptions.cash_payment_limit_on_dine_in_order.display_name}
                description={restaurantDescriptions.cash_payment_limit_on_dine_in_order.description}
            >
                <NumberField
                    label={<strong></strong>}
                    errors={errors}
                    name='cash_payment_limit_on_dine_in_order'
                    placeholder='COD limit for Dine In'
                    rules={{
                        required: 'Please enter COD limit',
                    }}
                    prepend='₹'
                    step='any'
                    control={control}
                />
            </FormGroupDescription>
        </EuiForm>
    );
};

export default CODLimit;
