import React from 'react';
import { MainLayout } from '../../components/layout/mainLayout/mainLayout';
import CategoryEditComponentReStructure from '../../components/menuComponentsRefactorCopy/categoryEdit/CategoryEditComponentReStructure';

const CategoryModificationPage = () => {
    return (
        <>
            <MainLayout title='Category' historyBack={true}>
                <CategoryEditComponentReStructure />
            </MainLayout>
        </>
    );
};

export default CategoryModificationPage;
