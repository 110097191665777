import React, { useCallback, useState } from 'react';
import { EuiButtonEmpty, EuiCollapsibleNavGroup, EuiFlexItem, EuiText } from '@elastic/eui';
import { useSelector } from 'react-redux';
import CheckableItem from './checkableItem';
import { fetchCategoryItem } from './Api/FetchCatalogueInfo';
import SubCategoryShift from './SubCategoryShift';

const ShiftItemList = (props) => {
    const { category, categoryValue, isMapItem, upSellingSelection } = props;
    const isOutletMenu = useSelector((state) => state.menuReducer.isOutletMenu);
    const languageId = useSelector((state) => state.language.languageId);
    const [listDetails, setListDetails] = useState({
        items: [],
        sub_categories: [],
        isLoading: false,
    });

    const itemListRender = useCallback(
        (itemList) => {
            let list = [];
            if (itemList?.length) {
                itemList.map((item) => {
                    list.push(
                        <CheckableItem
                            key={item.item_id.toString()}
                            isMapItem={isMapItem}
                            isOutletMenu={isOutletMenu}
                            upSellingSelection={upSellingSelection}
                            item={item}
                            itemList={itemList}
                        />
                    );
                });
            }

            return list;
        },
        [isMapItem, isOutletMenu, upSellingSelection]
    );

    const categories = useSelector((state) => state.menuReducer.restaurantCategories);
    const subCategories = categories.filter(
        (sub_category) => sub_category.parent_id && sub_category.parent_id === category.category_id
    );

    const doesSubCategoryExist =
        subCategories.filter((sub_category) => sub_category.parent_id === category.category_id).length > 0;

    const handleCategoryClick = useCallback(
        (selectedCategory) => {
            let items = [];
            if (!doesSubCategoryExist) {
                setListDetails((prevState) => ({ ...prevState, isLoading: true }));
                fetchCategoryItem(selectedCategory.category_id, languageId)
                    .then((response) => {
                        if (response.success) {
                            items.push(...response.items);
                            setListDetails((prevState) => ({ ...prevState, items: items, isLoading: false }));
                        } else {
                            setListDetails((prevState) => ({ ...prevState, isLoading: false }));
                        }
                        setListDetails((prevState) => ({
                            ...prevState,
                            isLoading: false,
                        }));
                    })
                    .catch((err) => {
                        setListDetails((prevState) => ({
                            ...prevState,
                            isLoading: false,
                        }));
                    });
            }
        },
        [doesSubCategoryExist, languageId]
    );

    return (
        <>
            {category.parent_id === null ? (
                <EuiCollapsibleNavGroup
                    key={category.category_id.toString()}
                    background='light'
                    title={
                        <EuiText>
                            <h3>
                                {isOutletMenu ? category?.translations?.title[languageId] : category?.internal_name}
                            </h3>
                        </EuiText>
                    }
                    isCollapsible={true}
                    initialIsOpen={false}
                    arrowDisplay={'left'}
                    isLoading={listDetails.isLoading}
                    onToggle={(isOpen) => {
                        if (isOpen && !listDetails.items.length) {
                            handleCategoryClick(category);
                        }
                    }}
                >
                    {listDetails.isLoading && !subCategories?.length ? (
                        <EuiFlexItem grow={false}>
                            <EuiButtonEmpty isLoading={true}>Loading</EuiButtonEmpty>
                        </EuiFlexItem>
                    ) : (
                        <>
                            {subCategories?.length ? (
                                <EuiFlexItem style={{ minWidth: '40vw' }}>
                                    {subCategories.map((subCategory) => {
                                        return (
                                            <SubCategoryShift
                                                categoryValue={categoryValue}
                                                isMapItem={isMapItem}
                                                subCategories={subCategory}
                                                upSellingSelection={upSellingSelection}
                                            />
                                        );
                                    })}
                                </EuiFlexItem>
                            ) : null}

                            {itemListRender(listDetails.items)}
                        </>
                    )}
                </EuiCollapsibleNavGroup>
            ) : (
                <></>
            )}
        </>
    );
};

export default ShiftItemList;
