import {
    EuiBadge,
    EuiButton,
    EuiFieldSearch,
    EuiFilterButton,
    EuiFilterGroup,
    EuiFilterSelectItem,
    EuiFlexGroup,
    EuiFlexItem,
    EuiForm,
    EuiFormControlLayout,
    EuiFormRow,
    EuiModal,
    EuiModalBody,
    EuiModalFooter,
    EuiModalHeader,
    EuiModalHeaderTitle,
    EuiOverlayMask,
    EuiPopover,
    EuiPopoverTitle,
    EuiText,
    EuiTitle,
} from '@elastic/eui';
import Axios from 'axios';
import React, { useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import API from '../../api/axios/API';
import NumberField from '../Form/NumberField';

const tagStyles = {
    background: 'transparent',
    border: '1px',
    borderStyle: 'solid',
    borderColor: '#EEE',
    padding: '8px 10px',
    borderRadius: '16px',
    margin: '12px 0',
};

const PaymentModal = (props) => {
    const {
        formState: { errors },
        control,
        setValue,
        watch,
    } = useForm();
    const [selectedCredit, setSelectedCredit] = useState(250);
    const [amountError, setAmountError] = useState(false);
    const outlet_id = useSelector((state) => state.outlet.selectedOutletId);
    const restaurant_id = useSelector((state) => state.auth.selectedRestaurantId);

    function loadScript(src) {
        return new Promise((resolve) => {
            const script = document.createElement('script');
            script.src = src;
            script.onload = () => {
                resolve(true);
            };
            script.onerror = () => {
                resolve(false);
            };
            document.body.appendChild(script);
        });
    }

    async function displayRazorpay() {
        props.setPaymentLoader(true);
        const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js');
        const inputAmount = watch('payment_amount');
        if (!res) {
            alert('Razorpay SDK failed to load. Are you online?');
            return;
        }
        props.setShowPaymentModal(false);

        // creating a new order
        const result = await API.post('restaurants/:restaurantId/add-credits', {
            amount: inputAmount,
            service_type: props.creditType === 'infrastructure' ? 'infrastructure_credit' : 'service_credit',
            entity_name: props.creditType === 'infrastructure' ? 'restaurant' : 'outlet',
            entity_id: props.creditType === 'infrastructure' ? restaurant_id : outlet_id,
        });

        if (!result) {
            alert('Server error!');
            return;
        }

        if (result.success) {
            props.setUpdateTopupData(true);
        } else {
            alert(result.message);
            props.setLoader(false);
            return;
        }

        // Getting the order details back
        const { razorpay_key } = result;
        const { amount, currency, id, razorpay_order_id } = result.razorpay_order;
        const { name, email, mobile_number } = result.user;

        const options = {
            key: razorpay_key,
            amount: amount.toString(),
            currency: currency,
            name: 'Levaral Sofwares Private Limited',
            description: result.reference_id,
            image: 'https://api.app.fab.delivery/images/levaral_logo.png',
            order_id: razorpay_order_id,
            handler: async function (response) {
                const data = {
                    // orderCreationId: razorpay_order_id,
                    razorpay_payment_id: response.razorpay_payment_id,
                    razorpay_order_id: response.razorpay_order_id,
                    razorpay_signature: response.razorpay_signature,
                };

                const result = await Axios.post('https://api.staging.fab.delivery/api/razorpay/callback', data);

                alert(result.data.message);
            },
            prefill: {
                name: name,
                email: email,
                contact: mobile_number,
            },
            notes: { address: 'Soumya Dey Corporate Office' },
            theme: {
                color: '#035280',
            },
        };

        var rzp1 = new window.Razorpay(options);
        props.setPaymentLoader(false);
        rzp1.open();
    }

    const numberRef = useRef(null);
    const onWheel = () => {
        numberRef && numberRef.current && numberRef.current.blur();
    };

    return (
        <EuiOverlayMask>
            <div
                style={{
                    border: '1px',
                    borderStyle: 'solid',
                    background: 'white',
                    boxShadow:
                        '0 40px 64px 0 rgb(65 78 101 / 10%), 0 24px 32px 0 rgb(65 78 101 / 10%), 0 16px 16px 0 rgb(65 78 101 / 10%), 0 8px 8px 0 rgb(65 78 101 / 10%), 0 4px 4px 0 rgb(65 78 101 / 10%), 0 2px 2px 0 rgb(65 78 101 / 10%)',
                    borderColor: '#c6cad1',
                    width: '398px',
                }}
            >
                <EuiForm component='form' onSubmit={(e) => e.preventDefault()}>
                    <div style={{ padding: '20px 24px' }}>
                        <div style={{ width: '100%' }}>
                            <div
                                style={{
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                }}
                            >
                                <div>
                                    <EuiText size='l'>
                                        <h4> Add credits</h4>
                                    </EuiText>
                                </div>
                                <div onClick={() => props.setShowPaymentModal(false)} style={{ cursor: 'pointer' }}>
                                    <svg
                                        xmlns='http://www.w3.org/2000/svg'
                                        height='18px'
                                        viewBox='0 0 24 24'
                                        width='18px'
                                        fill='#000000'
                                    >
                                        <path d='M0 0h24v24H0V0z' fill='none' />
                                        <path d='M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z' />
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div style={{ padding: '0 24px' }}>
                        <EuiFormControlLayout
                            clear={{
                                onClick: () => {
                                    setValue('payment_amount', 250);
                                    setSelectedCredit(250);
                                },
                            }}
                        >
                            <Controller
                                name={'payment_amount'}
                                rules={{
                                    required: 'This field is required',
                                }}
                                control={control}
                                fullWidth={true}
                                inputRef={numberRef}
                                onWheel={onWheel}
                                defaultValue={selectedCredit}
                                render={({ field }) => (
                                    <input
                                        type='number'
                                        className='euiFieldText input-remove-hyphen'
                                        aria-label='Use aria labels when no actual label is in use'
                                        value={Number(selectedCredit)}
                                        onChange={(e) => {
                                            setValue('payment_amount', Number(e.target.value.replace(/^0+/, '')));
                                            setSelectedCredit(Number(e.target.value.replace(/^0+/, '')));
                                        }}
                                        placeholder={field.value}
                                        min={250}
                                    />
                                )}
                            ></Controller>
                        </EuiFormControlLayout>

                        <EuiFlexGroup wrap responsive={false} gutterSize='xs'>
                            <EuiFlexItem
                                grow={false}
                                onClick={() => {
                                    setSelectedCredit(Number(selectedCredit) + 250);
                                    setValue('payment_amount', selectedCredit + 250);
                                }}
                            >
                                <EuiBadge style={tagStyles}>+ 250</EuiBadge>
                            </EuiFlexItem>
                            <EuiFlexItem
                                grow={false}
                                onClick={() => {
                                    setSelectedCredit(Number(selectedCredit) + 500);
                                    setValue('payment_amount', selectedCredit + 500);
                                }}
                            >
                                <EuiBadge style={tagStyles}>+ 500</EuiBadge>
                            </EuiFlexItem>
                            <EuiFlexItem
                                grow={false}
                                onClick={() => {
                                    setSelectedCredit(Number(selectedCredit) + 1000);
                                    setValue('payment_amount', selectedCredit + 1000);
                                }}
                            >
                                <EuiBadge style={tagStyles}>+ 1000</EuiBadge>
                            </EuiFlexItem>
                            <EuiFlexItem
                                grow={false}
                                onClick={() => {
                                    setSelectedCredit(Number(selectedCredit) + 1500);
                                    setValue('payment_amount', selectedCredit + 1500);
                                }}
                            >
                                <EuiBadge style={tagStyles}>+ 1500</EuiBadge>
                            </EuiFlexItem>
                        </EuiFlexGroup>

                        {selectedCredit < 250 ? (
                            <EuiText size='xs'>250 credit = ₹250</EuiText>
                        ) : (
                            <EuiText size='xs'>
                                {selectedCredit} credit = ₹{selectedCredit} + processing fee
                            </EuiText>
                        )}
                        {amountError && (
                            <EuiText size='xs' color='red'>
                                Amount can't be below 250 INR
                            </EuiText>
                        )}
                    </div>

                    <div style={{ margin: '16px 24px', float: 'right' }}>
                        <p
                            onClick={() => {
                                if (Number(watch('payment_amount')) > 249) {
                                    displayRazorpay();
                                } else {
                                    setAmountError(true);
                                }
                            }}
                            style={{
                                padding: '14px 32px',
                                background: '#005cb1',
                                color: 'white',
                                cursor: 'pointer',
                                borderRadius: '3px',
                            }}
                        >
                            Next
                        </p>
                    </div>
                </EuiForm>
            </div>
        </EuiOverlayMask>
    );
};

export default PaymentModal;
