import React, { useState, memo } from 'react';
import { Chart, BarSeries, Axis, Settings, LineSeries } from '@elastic/charts';
import { EUI_CHARTS_THEME_DARK, EUI_CHARTS_THEME_LIGHT } from '@elastic/eui/dist/eui_charts_theme';
import {
    EuiPanel,
    EuiTitle,
    EuiStat,
    EuiFlexItem,
    EuiSpacer,
    EuiText,
    EuiFlexGroup,
    EuiRadioGroup,
    htmlIdGenerator,
    EuiFilterButton,
    EuiFilterGroup,
    EuiHorizontalRule,
    EuiComboBox,
    EuiFormRow,
} from '@elastic/eui';
import { orderBy } from 'lodash';
import '@elastic/charts/dist/theme_light.css';
import { useSelector } from 'react-redux';
import moment from 'moment';

const VisitorChart = (props) => {
    const themeMode = useSelector((state) => state.theme.theme);
    const euiChartTheme = themeMode === 'light' ? EUI_CHARTS_THEME_LIGHT : EUI_CHARTS_THEME_DARK;
    const languageId = useSelector((state) => state.language.languageId);

    const [isCategoryFilterOn, setIsCategoryFilterOn] = useState(true);
    const [isRevenueFilterOn, setIsRevenueFilterOn] = useState(true);

    const toggleCategoryFilter = () => {
        setIsCategoryFilterOn(!isCategoryFilterOn);
    };

    const toggleRevenueFilter = () => {
        setIsRevenueFilterOn(!isRevenueFilterOn);
    };

    const [categoryItemSelected, setCategoryItemSelected] = useState(`categorylist-${props.outlet_id}`);

    const [costCountSelected, setCostCountSelected] = useState(`revenue-${props.outlet_id}`);

    const ratingFormat = () => {
        let innerArray = [];
        props.ratingList &&
            props.ratingList.map((rating) => {
                // if(rating.avarage_feedback){
                innerArray.push([rating.order_date, rating.avarage_feedback === null ? 0 : rating.avarage_feedback]);
                // }
            });

        return innerArray;
    };

    const revenueChartFunction = (name) => {
        let revenueArray = [];
        if (props.outlet && props.outlet.revenue_sales_by_date) {
            props.outlet.revenue_sales_by_date.map((revenue) =>
                revenueArray.push({
                    x: moment(revenue.order_date).format('DD-MM-YYYY'),
                    y: name === 'Sales' ? (revenue.sales ? revenue.sales : 0) : revenue.revenue ? revenue.revenue : 0,
                    g: name,
                })
            );
        }
        return revenueArray;
    };

    const sortArray = (highest_selling, key) => {
        var len = highest_selling.length;
        for (var i = len - 1; i >= 0; i--) {
            for (var j = 1; j <= i; j++) {
                if (parseInt(highest_selling[j - 1][key]) < parseInt(highest_selling[j][key])) {
                    var temp = highest_selling[j - 1];
                    highest_selling[j - 1] = highest_selling[j];
                    highest_selling[j] = temp;
                }
            }
        }
        return highest_selling;
    };

    const topSellingChartFunction = () => {
        let innerArray = [];
        if (isCategoryFilterOn) {
            if (props.outlet && props.outlet.highest_selling_categories.length > 0) {
                let sortedArray = orderBy(
                    props.outlet.highest_selling_categories,
                    ['total_category_price_percent'],
                    ['desc']
                );
                sortedArray.map((category) => {
                    return innerArray.push({
                        item: category.titles[languageId],
                        total_sales: category.total_price ? category.total_price : 0,
                    });
                });
            }
        } else {
            console.log('props.outlet.highest_selling_items.length', props.outlet.highest_selling_items.length);
            if (props.outlet && props.outlet.highest_selling_items.length > 0) {
                console.log('props.outlet.highest_selling_items', props.outlet.highest_selling_items);
                let sortedArray = orderBy(props.outlet.highest_selling_items, ['total_item_quantity_percent'], ['asc']);

                sortedArray.map((items) => {
                    return innerArray.push({
                        item: items.titles[languageId],
                        total_sales: items.total_price ? items.total_price : 0,
                    });
                });
            }
        }

        return innerArray;
    };

    return (
        <>
            <EuiSpacer size='s' />
            <EuiFlexGroup direction='row'>
                {!props.isRatingChart && (
                    <>
                        <EuiFlexGroup direction='column'>
                            <EuiFlexItem>
                                <EuiTitle size='l'>
                                    <h4>Top Selling</h4>
                                </EuiTitle>
                                <EuiSpacer size='s' />
                            </EuiFlexItem>
                            <EuiFlexItem>
                                <EuiFilterGroup>
                                    <EuiFilterButton>Filter</EuiFilterButton>
                                    <EuiFilterButton
                                        withNext
                                        hasActiveFilters={isCategoryFilterOn}
                                        onClick={toggleCategoryFilter}
                                    >
                                        Category
                                    </EuiFilterButton>
                                    <EuiFilterButton
                                        hasActiveFilters={!isCategoryFilterOn}
                                        onClick={toggleCategoryFilter}
                                    >
                                        Item
                                    </EuiFilterButton>

                                    <EuiFilterButton
                                        withNext
                                        hasActiveFilters={isRevenueFilterOn}
                                        onClick={toggleRevenueFilter}
                                    >
                                        Revenue
                                    </EuiFilterButton>
                                    <EuiFilterButton
                                        hasActiveFilters={!isRevenueFilterOn}
                                        onClick={toggleRevenueFilter}
                                    >
                                        Count
                                    </EuiFilterButton>
                                </EuiFilterGroup>
                            </EuiFlexItem>
                            <EuiHorizontalRule />
                            <EuiFlexItem>
                                <Chart size={{ height: 250 }}>
                                    <Settings theme={euiChartTheme} rotation={90} showLegend={false} />
                                    <BarSeries
                                        id='orders'
                                        name='Order Revenue'
                                        data={topSellingChartFunction()}
                                        xAccessor='item'
                                        yAccessors={['total_sales']}
                                    />
                                    <Axis id='bottom-axis' position={'left'} showGridLines={false} />
                                    <Axis id='left-axis' position={'bottom'} />
                                </Chart>
                            </EuiFlexItem>
                        </EuiFlexGroup>
                        <EuiFlexGroup direction='column'>
                            <EuiFlexItem>
                                <EuiTitle size='l'>
                                    <h4>Top Selling</h4>
                                </EuiTitle>
                                <EuiSpacer size='s' />
                            </EuiFlexItem>
                            <EuiFlexItem>
                                <EuiFilterGroup>
                                    <EuiFilterButton>Filter</EuiFilterButton>
                                    <EuiFilterButton
                                        withNext
                                        hasActiveFilters={isCategoryFilterOn}
                                        onClick={toggleCategoryFilter}
                                    >
                                        Category
                                    </EuiFilterButton>
                                    <EuiFilterButton
                                        hasActiveFilters={!isCategoryFilterOn}
                                        onClick={toggleCategoryFilter}
                                    >
                                        Item
                                    </EuiFilterButton>

                                    <EuiFilterButton
                                        withNext
                                        hasActiveFilters={isRevenueFilterOn}
                                        onClick={toggleRevenueFilter}
                                    >
                                        Revenue
                                    </EuiFilterButton>
                                    <EuiFilterButton
                                        hasActiveFilters={!isRevenueFilterOn}
                                        onClick={toggleRevenueFilter}
                                    >
                                        Count
                                    </EuiFilterButton>
                                </EuiFilterGroup>
                            </EuiFlexItem>
                            <EuiHorizontalRule />
                            <EuiFlexItem>
                                <Chart size={{ height: 250 }}>
                                    <Settings theme={euiChartTheme} rotation={90} showLegend={false} />
                                    <BarSeries
                                        id='orders'
                                        name='Order Revenue'
                                        data={topSellingChartFunction()}
                                        xAccessor='item'
                                        yAccessors={['total_sales']}
                                    />
                                    <Axis id='bottom-axis' position={'left'} showGridLines={false} />
                                    <Axis id='left-axis' position={'bottom'} />
                                </Chart>
                            </EuiFlexItem>
                        </EuiFlexGroup>
                    </>
                )}

                {props.isRatingChart && (
                    <EuiFlexItem style={{ heigth: '350px' }}>
                        <EuiSpacer size='s' />
                        <EuiPanel>
                            <EuiStat title='' description='' textAlign='right'>
                                <Chart size={{ height: 260 }}>
                                    <BarSeries
                                        id='Average rating'
                                        data={ratingFormat()}
                                        xAccessor={0}
                                        yAccessors={[1]}
                                    />
                                    <Axis
                                        id='bottom-axis-order-rating'
                                        position='bottom'
                                        // title={'1 Mar 2019'}
                                        // tickFormat={timeFormatter(niceTimeFormatByDay(1))}
                                        showGridLines={false}
                                        style={undefined}
                                    />
                                    <Axis id='left-axis-order-rating' position='left' style={undefined} />
                                </Chart>
                            </EuiStat>
                        </EuiPanel>
                    </EuiFlexItem>
                )}
            </EuiFlexGroup>
        </>
    );
};

export default memo(VisitorChart);
