import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { userHasPermission } from '../../helper/auth';
import NotificationDetails from './notificationDetails';
import { AddNotificationFlyout } from './addNotificationFlyout';
import API from '../../api/axios/API';
import { EuiText, EuiFlexGroup, EuiFlexItem, EuiButton, EuiSpacer, EuiLoadingContent } from '@elastic/eui';

const openFly = (state, setState) => {
    setState({
        ...state,
        isOpen: true,
    });
};

const refetchCallback = (value, setRefetch) => {
    setRefetch(value);
};

export const Notification = () => {
    const dispatch = useDispatch();
    const permissions = useSelector((state) => state.permission.permission);
    const [refetch, setRefetch] = useState(false);

    const [state, setState] = useState({
        isOpen: false,
        isNew: true,
    });
    // state for api
    const [appState, setAppState] = useState({
        loading: true,
        responceForScreen: null,
        responceForCategories: null,
        refetch: false,
        errorState: false,
    });

    // api call hook
    useEffect(() => {
        const getDataForScreenAndCategory = async () => {
            try {
                // api call for mobile screen and category api
                const getMultipleData = await Promise.all([
                    API.get(`/mobile/screens-list`),
                    // API.get(`/restaurants/:restaurantId/categories/list`),
                ]);
                setAppState((preState) => ({
                    ...preState,
                    loading: false,
                    responceForScreen: getMultipleData[0],
                    // responceForCategories: getMultipleData[1],
                }));
            } catch (e) {
                setAppState((preState) => ({
                    ...preState,
                    loading: false,
                    errorState: true,
                }));
            }
        };
        getDataForScreenAndCategory();
    }, []);

    const closeFly = () => {
        setState({
            ...state,
            isOpen: false,
        });
        dispatch({
            type: 'set-reset-id',
            payload: {
                resendID: null,
            },
        });
        dispatch({
            type: 'set-edit-id',
            payload: {
                editID: null,
            },
        });
    };
    const openNewNotificationFly = () => {
        setState({
            ...state,
            isOpen: true,
            isNew: true,
        });
    };

    const openFlyCallback = useCallback((value) => {
        openFly(state, setState);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const setRefetchCallback = useCallback((value) => {
        refetchCallback(value, setRefetch);
    }, []);

    if (appState.loading) return <EuiLoadingContent lines={10} />;
    if (appState.errorState) return 'error...';

    return (
        <Fragment>
            {state.isOpen && (
                <AddNotificationFlyout
                    allScreen={appState.responceForScreen}
                    allCategories={appState.responceForCategories}
                    setRefetch={setRefetch}
                    isOpen={state.isOpen}
                    closeFly={closeFly}
                />
            )}
            <EuiFlexItem style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                {userHasPermission(permissions, '/notifications', 'write') && (
                    <EuiButton fill iconType='plusInCircleFilled' onClick={() => openNewNotificationFly()}>
                        Notification
                    </EuiButton>
                )}
            </EuiFlexItem>
            <EuiSpacer />

            <NotificationDetails setRefetch={setRefetchCallback} refetch={refetch} openFly={openFlyCallback} />
        </Fragment>
    );
};
