import { EuiFlexGroup, EuiFlexItem, EuiIcon, EuiSwitch, EuiToolTip } from '@elastic/eui';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import API from '../../../api/axios/API';
import { userHasPermission } from '../../../helper/auth';
import { toastsErrorMessage, toastSuccessMessage } from '../../../utils/toasts';

const OfferActions = ({ actions, setRefetchForOffer, allOffers }) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const permissions = useSelector((state) => state.permission.permission);

    const onChangeActive = useCallback(
        async (id) => {
            if (window.confirm('Are you sure you want to change offer status ?')) {
                const toggle = await API.patch(`restaurants/:restaurantId/offers/${id}/toggle-active `);
                if (toggle.success) {
                    setRefetchForOffer((preState) => !preState);
                    toastSuccessMessage('Offer status successfully updated', dispatch);
                } else {
                    toastsErrorMessage('We are facing issues, Please try again later.', dispatch);
                }
            }
        },
        [dispatch, setRefetchForOffer]
    );

    const handleEditController = useCallback(
        async (id, value) => {
            dispatch({
                type: 'set-offers',
                payload: {
                    offers: allOffers.offers.data,
                },
            });

            history.push(`/offers/edit/${id}?offerType=${value}`);
        },
        [allOffers.offers, dispatch, history]
    );

    const deleteSelectedOffer = useCallback(
        async (id) => {
            try {
                const deleteOffer = await API.delete(`restaurants/:restaurantId/offers/${id}`);
                if (deleteOffer.success === true) {
                    toastSuccessMessage('Offer Delete successfully', dispatch);
                    setRefetchForOffer((preState) => !preState);
                }
            } catch (e) {
                toastsErrorMessage('Not deleted, please try later.', dispatch);
            }
        },
        [dispatch, setRefetchForOffer]
    );

    const handleDeleteController = useCallback(
        async (id) => {
            if (window.confirm('Are you sure you want to delete this offer ?')) {
                await deleteSelectedOffer(id);
            }
        },
        [deleteSelectedOffer]
    );

    return (
        <EuiFlexGroup direction='row' gutterSize='s' justifyContent='flexEnd' alignItems='center' wrap responsive>
            <EuiFlexItem grow={false}>
                {userHasPermission(permissions, '/offers', 'write') && (
                    <EuiToolTip position='top' content='Switch for offer visibility'>
                        <EuiSwitch
                            label=''
                            compressed
                            key={actions.active + 'active'}
                            checked={actions.active ? true : false}
                            onChange={() => onChangeActive(actions.id)}
                        />
                    </EuiToolTip>
                )}
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
                {userHasPermission(permissions, '/offers', 'write') && (
                    <EuiToolTip position='top' content='Edit the offer'>
                        <EuiIcon
                            type='documentEdit'
                            size='original'
                            color='primary'
                            style={{ cursor: 'pointer' }}
                            onClick={() => handleEditController(actions.id, actions.offer_type)}
                        />
                    </EuiToolTip>
                )}
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
                {userHasPermission(permissions, '/offers', 'delete') && (
                    <EuiToolTip position='top' content='Delete the offer'>
                        <EuiIcon
                            type='trash'
                            size='original'
                            color='danger'
                            style={{ cursor: 'pointer' }}
                            onClick={() => handleDeleteController(actions.id)}
                        />
                    </EuiToolTip>
                )}
            </EuiFlexItem>
        </EuiFlexGroup>
    );
};

export default React.memo(OfferActions);
