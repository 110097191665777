import { EuiButton } from '@elastic/eui';
import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { userHasPermission } from '../../../helper/auth';

const UploadButton = () => {
    const permissions = useSelector((state) => state.permission.permission);

    const history = useHistory();

    return (
        <>
            <EuiButton disabled={!userHasPermission(permissions, history.location.pathname, 'write')}>
                Upload an image
            </EuiButton>
        </>
    );
};

export default React.memo(UploadButton);
