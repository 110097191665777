import React, { memo } from 'react';
import {
    EuiTable,
    EuiTableHeader,
    EuiTableBody,
    EuiTableHeaderCell,
    EuiRadio,
    EuiTableRowCell,
    EuiTableRow,
    EuiButtonEmpty,
} from '@elastic/eui';
import API from '../../api/axios/API';
import { useDispatch, useSelector } from 'react-redux';
import { userHasPermission } from '../../helper/auth';

const UserTablelist = (props) => {
    const dispatch = useDispatch();
    const permissions = useSelector((state) => state.permission.permission);
    const { setUseSideEffect } = props;

    const mutate = async (id) => {
        const deleteUser = await API.delete(`restaurants/:restaurantId/users/${id}`);
        if (deleteUser.success) {
            alert('Delete user successfully');
        } else {
            alert('Please try later');
        }
    };

    let column = [
        {
            id: 'radioSelect',
            width: '40px',
        },
        {
            id: 'name',
            label: 'Name',
        },
        {
            id: 'number',
            label: 'Number',
        },
        {
            id: 'email',
            label: 'Email',
        },
        {
            id: 'edit',
            label: 'Edit',
        },
        {
            id: 'delete',
            label: 'Delete',
        },
    ];

    if (!userHasPermission(permissions, '/users', 'delete') && !userHasPermission(permissions, '/users', 'write')) {
        column = [
            {
                id: 'radioSelect',
                width: '40px',
            },
            {
                id: 'name',
                label: 'Name',
            },
            {
                id: 'number',
                label: 'Number',
            },
            {
                id: 'email',
                label: 'Email',
            },
        ];
    } else if (!userHasPermission(permissions, '/users', 'delete')) {
        column = [
            {
                id: 'radioSelect',
                width: '40px',
            },
            {
                id: 'name',
                label: 'Name',
            },
            {
                id: 'number',
                label: 'Number',
            },
            {
                id: 'email',
                label: 'Email',
            },
            {
                id: 'edit',
                label: 'Edit',
            },
        ];
    } else if (!userHasPermission(permissions, '/users', 'write')) {
        column = [
            {
                id: 'radioSelect',
                width: '40px',
            },
            {
                id: 'name',
                label: 'Name',
            },
            {
                id: 'number',
                label: 'Number',
            },
            {
                id: 'email',
                label: 'Email',
            },
            {
                id: 'delete',
                label: 'Delete',
            },
        ];
    } else {
        column = [
            {
                id: 'radioSelect',
                width: '40px',
            },
            {
                id: 'name',
                label: 'Name',
            },
            {
                id: 'number',
                label: 'Number',
            },
            {
                id: 'email',
                label: 'Email',
            },
            {
                id: 'edit',
                label: 'Edit',
            },
            {
                id: 'delete',
                label: 'Delete',
            },
        ];
    }

    const onChange = (id) => {
        // setState(parseInt(e.target.id))

        setUseSideEffect(id);
        props.setSelectedUser(id);
    };

    const renderHeaderCell = () => {
        const headers = [];

        column.map((column) =>
            headers.push(
                <EuiTableHeaderCell width={column.width} key={column.id}>
                    {column.label}
                </EuiTableHeaderCell>
            )
        );

        return headers.length ? headers : null;
    };
    const editUser = async (id) => {
        const allUser = await API.get('restaurants/:restaurantId/users');
        let filterUser = null;
        filterUser =
            allUser &&
            allUser.users &&
            allUser.users.filter((item, index) => {
                if (item.user.id === id) {
                    return item.user;
                }
                return null;
            })[0];
        dispatch({
            type: 'edit_user',
            payload: {
                editUser: filterUser,
            },
        });
        props.openFlyOut();
    };
    const deleteUser = async (id) => {
        if (window.confirm('Are you sure you want to delete this record ?')) {
            await mutate(id);
            props.setPermissionUpdate((prevState) => !prevState);
        }
    };
    const renderRows = () => {
        const rows = props.users['users'].map((item) => {
            let user = item['user'];
            return (
                <EuiTableRow key={user['id']}>
                    <EuiTableRowCell width='32px'>
                        <EuiRadio
                            // id={user['id']}
                            checked={props.selectedUser === user['id']}
                            onChange={(e) => {
                                onChange(user['id']);
                            }}
                        />
                    </EuiTableRowCell>
                    <EuiTableRowCell>{user['name']}</EuiTableRowCell>
                    <EuiTableRowCell>{`${user['country_code']} ${user['mobile_number']}`}</EuiTableRowCell>
                    <EuiTableRowCell>{user['email']}</EuiTableRowCell>
                    {userHasPermission(permissions, '/users', 'write') && (
                        <EuiTableRowCell onClick={() => editUser(user['id'])}>
                            <EuiButtonEmpty size='s' iconType='documentEdit'>
                                {' '}
                                Edit{' '}
                            </EuiButtonEmpty>
                        </EuiTableRowCell>
                    )}
                    {userHasPermission(permissions, '/users', 'delete') && (
                        <EuiTableRowCell onClick={() => deleteUser(user['id'])}>
                            <EuiButtonEmpty size='s' iconType='cross'>
                                {' '}
                                Delete{' '}
                            </EuiButtonEmpty>
                        </EuiTableRowCell>
                    )}
                </EuiTableRow>
            );
        });

        return rows;
    };
    return (
        <>
            {props.users && (
                <EuiTable>
                    <EuiTableHeader>{renderHeaderCell()}</EuiTableHeader>
                    <EuiTableBody width='32px'>{renderRows()}</EuiTableBody>
                </EuiTable>
            )}
        </>
    );
};

export default memo(UserTablelist);
