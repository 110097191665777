import {
    EuiDragDropContext,
    euiDragDropReorder,
    EuiDraggable,
    EuiDroppable,
    EuiFlexGroup,
    EuiFlexItem,
    EuiIcon,
    EuiPanel,
    EuiText,
} from '@elastic/eui';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { outletSettingsV4 } from '../../../../api/outlet/outletSettingsAPI';
import { userHasPermission } from '../../../../helper/auth';
import * as types from '../../../../reduxStore/types/outlet';

const HomeScreenLayout = ({ outletData, fetchOutletById }) => {
    const dispatch = useDispatch();
    const permissions = useSelector((state) => state.permission.permission);
    const {
        handleSubmit,
        watch,
        reset,
        setValue,

        formState: { isDirty },
    } = useForm();
    const [list, setList] = useState([]);

    const resetChanges = useCallback(() => {
        const data = outletData.outlet;
        setList([...data?.outlet_screen_display_configurations]);
        reset({
            ...{
                outlet_screen_display_configurations: data.outlet_screen_display_configurations,
            },
        });
    }, [outletData, reset]);

    const onSubmit = useCallback(() => {
        dispatch(
            outletSettingsV4('layout_settings', {
                outlet_screen_display_configurations: watch('outlet_screen_display_configurations'),
            })
        ).then((res) => {
            if (res.success) {
                dispatch({
                    type: types.SET_PORTAL_ACTIVE,
                    payload: {
                        portalActive: {},
                    },
                });
                dispatch({
                    type: types.SET_PORTAL_ACTIVE_TAB_SWITCHED,
                    payload: {
                        tabSwitched: false,
                    },
                });
                fetchOutletById();
            } else {
                alert(res.message);
            }
        });
    }, [dispatch, fetchOutletById, watch]);

    useEffect(() => {
        if (!userHasPermission(permissions, '/outlet-settings', 'write')) return;

        if (isDirty) {
            dispatch({
                type: 'set-portal-active',
                payload: {
                    portalActive: {
                        resetChanges: resetChanges,
                        submit: handleSubmit(onSubmit),
                    },
                },
            });
        } else {
            dispatch({
                type: 'set-portal-active',
                payload: {
                    portalActive: {},
                },
            });
        }
    }, [isDirty]);

    useEffect(() => {
        if (outletData?.outlet) {
            resetChanges();
        }
    }, [outletData, resetChanges]);

    const handleOnDragEnd = useCallback(
        ({ source, destination }) => {
            let items = euiDragDropReorder(list, source.index, destination.index);

            for (const [index, item] of items.entries()) {
                item.display_rank = index + 1;
            }

            setList(items);
            setValue('outlet_screen_display_configurations', items, { shouldDirty: true });
        },
        [list, setValue]
    );

    return (
        <>
            {' '}
            <EuiDragDropContext onDragEnd={handleOnDragEnd}>
                <EuiDroppable droppableId='DROPPABLE_AREA_FOR_ITEM_LIST'>
                    {list?.map((config, idx) => {
                        return (
                            <EuiDraggable
                                spacing='m'
                                key={config.id}
                                index={idx}
                                draggableId={config.id.toString()}
                                customDragHandle={true}
                                id={idx}
                            >
                                {(provided) => (
                                    <EuiPanel>
                                        <EuiFlexGroup alignItems='center' justifyContent='flexStart'>
                                            <EuiFlexItem grow={false} {...provided.dragHandleProps}>
                                                <EuiIcon type='grab' />
                                            </EuiFlexItem>
                                            <EuiFlexItem grow={false}>
                                                <EuiText>{config.section_name}</EuiText>
                                            </EuiFlexItem>
                                        </EuiFlexGroup>
                                    </EuiPanel>
                                )}
                            </EuiDraggable>
                        );
                    })}
                </EuiDroppable>
            </EuiDragDropContext>
        </>
    );
};

export default React.memo(HomeScreenLayout);
