import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { EuiCard, EuiLoadingContent, EuiSpacer, EuiText } from '@elastic/eui';
import ItemListForCategorySelected from './itemListForCategorySelected';
import ComboBoxField from '../Form/ComboBoxField';
import { useFormContext } from 'react-hook-form';
import { useCallback } from 'react';
import { fetchCategoryItemsForAddonLink } from '../menuComponentsRefactorCopy/catalogue/Api/FetchCatalogueInfo';
import { isEmpty } from 'lodash';
import { useParams } from 'react-router-dom';

const CoupledOffer = (props) => {
    const { allCategories } = props;
    const { offerId } = useParams();
    const languageId = useSelector((state) => state.language.languageId);
    const [categoryList, setCategoryList] = useState([]);
    const [isItemsLoading, setItemsLoading] = useState(false);
    const [loading, setLoading] = useState(true);
    const {
        control,
        watch,
        setValue,
        reset,
        formState: { errors },
    } = useFormContext();

    useEffect(() => {
        if (allCategories?.length) {
            let categoryList = [];
            allCategories.forEach((category) => {
                if (!category.parent_id) {
                    categoryList.push({
                        label: category.internal_name,
                        id: category.category_id,
                        isSubCategory: false,
                        data: category,
                        items: [], // effected from tree structure API
                    });
                } else {
                    categoryList.push({
                        label: category.internal_name,
                        id: category.category_id,
                        isSubCategory: true,
                        subItems: [], // effected from tree structure API
                        parent_id: category.parent_id, // newly added
                    });
                }
            });

            setCategoryList(categoryList);
        }
    }, [allCategories, languageId]);

    useEffect(() => {
        if (!offerId) {
            setLoading(false);
        }
    }, [offerId]);

    const onChange = useCallback(
        async (selectedOptions) => {
            if (!selectedOptions?.length) {
                setItemsLoading(false);
            } else {
                setItemsLoading(true);
            }
            if (selectedOptions.length) {
                const itemResponse = await fetchCategoryItemsForAddonLink(selectedOptions[0].id, languageId);
                setItemsLoading(false);
                if (itemResponse.success) {
                    selectedOptions[0] = {
                        ...selectedOptions[0],
                        items: selectedOptions[0].isSubCategory ? [] : itemResponse.items,
                        subItems: selectedOptions[0].isSubCategory ? itemResponse.items : [],
                        // subItems: itemResponse.items,
                    };
                }
            }
            setValue('primary_category_id', selectedOptions, { shouldDirty: true });
            let defaultValue = {};
            defaultValue['items'] = [];
            if (selectedOptions[0]?.subItems) {
                selectedOptions[0].subItems.forEach((item) => {
                    // itemArray.map((item) => {
                    defaultValue['items'].push({
                        module_id: item.item_id,
                        module_name: 'items',
                        secondary_category_id: '',
                        hasVaraint: item?.variants?.length && item?.variants[0]?.options?.length,
                        name: item.internal_name,
                    });
                    if (item?.variants?.length && item?.variants[0]?.options?.length) {
                        item.variants[0].options.forEach((option) => {
                            defaultValue['items'].push({
                                module_id: option.id,
                                module_name: 'variant',
                                secondary_category_id: '',
                                name: option.translations.title[languageId],
                            });
                        });
                    }
                    // });
                });
            }
            selectedOptions.length &&
                selectedOptions[0].items.forEach((item) => {
                    defaultValue['items'].push({
                        module_id: item.item_id,
                        module_name: 'items',
                        secondary_category_id: '',
                        hasVaraint: item?.variants?.length && item?.variants[0]?.options?.length,
                        name: item.internal_name,
                    });
                    if (item?.variants?.length && item?.variants[0]?.options?.length) {
                        item.variants[0].options.forEach((option) => {
                            defaultValue['items'].push({
                                module_id: option.id,
                                module_name: 'variant',
                                secondary_category_id: '',
                                name: option.translations.title[languageId],
                            });
                        });
                    }
                });
            let records = watch();

            reset({
                ...records,
                ...defaultValue,
                items: defaultValue['items'],
            });
        },
        [languageId, reset, setValue, watch]
    );

    const getItemOrVariantName = useCallback(
        (itemData, items) => {
            let name = '';

            if (itemData.module_name === 'variant') {
                items.map((item) => {
                    if (item?.variants?.length && item?.variants[0]?.options?.length) {
                        item.variants[0].options.map((option) => {
                            if (option.id == itemData.module_id) {
                                name = option.translations.title[languageId];
                            }
                        });
                    }
                });
            } else {
                items.map((item) => {
                    if (item.item_id == itemData.module_id) {
                        name = item.internal_name;
                    }
                });
            }

            return name;
        },
        [languageId]
    );

    const getCategoryObject = useCallback(
        (id) => {
            let categoryData = {};
            if (allCategories?.length) {
                allCategories.forEach((category) => {
                    if (category.category_id === id) {
                        categoryData = {
                            id: category.category_id,
                            label: category.internal_name,
                            isSubCategory: false,
                            data: category,
                            items: category?.items ? category?.items : [],
                            name: category.internal_name,
                        };
                    }

                    if (category?.sub_categories?.length) {
                        category.sub_categories.forEach((subCategory) => {
                            if (subCategory.sub_category_id === id) {
                                categoryData = {
                                    id: subCategory.sub_category_id,
                                    label: subCategory.internal_name,
                                    isSubCategory: true,
                                    data: subCategory,
                                    items: subCategory.items,
                                    name: subCategory.internal_name,
                                };
                            }
                        });
                    }

                    if (category.category_id === id) {
                        categoryData = {
                            id: category.category_id,
                            label: category.internal_name,
                            isSubCategory: false,
                            data: category,
                            items: category?.items ? category?.items : [],
                            name: category.internal_name,
                        };
                    }
                });
                if (categoryData) {
                    setLoading(false);
                }
            }

            return categoryData;
        },
        [allCategories]
    );

    const onChangeEdit = useCallback(
        (selectedOptions) => {
            setValue('primary_category_id', selectedOptions, { shouldDirty: true });

            let defaultValue = {};
            const dummyDefault = {};
            defaultValue['items'] = [];
            dummyDefault['items'] = [];
            let items = watch('items');
            const selectedCategoryItems = watch('primary_category_id')[0]?.items;
            const result = selectedCategoryItems.map((selected_category) => {
                const secondaryItem = items.find((item) => item.module_id === selected_category.item_id);
                selected_category.secondary_category_id = secondaryItem?.secondary_category_id;
                selected_category.module_name = secondaryItem ? secondaryItem?.module_name : null;
                selected_category.module_id = secondaryItem ? secondaryItem?.module_id : null;

                return selected_category;
            });
            if (result?.length > 0) {
                result.forEach((item) => {
                    let categoryObject = getCategoryObject(item?.secondary_category_id);
                    let name = getItemOrVariantName(item, selectedOptions[0].items);
                    dummyDefault['items'].push({
                        module_id: item?.module_id !== null ? item.module_id : item.item_id,
                        module_name: item?.module_name !== null ? item.module_name : 'items',
                        secondary_category_id:
                            item?.secondary_category_id !== null && !isEmpty(categoryObject) ? [categoryObject] : [],
                        hasVaraint: item?.variants?.length && item?.variants[0]?.options?.length,
                        name: name !== '' ? name : item?.translations?.title[languageId],
                    });

                    if (item?.variants?.length && item?.variants[0]?.options?.length) {
                        item.variants[0].options.forEach((option) => {
                            let objSecondary = {};

                            items.forEach((item) => {
                                objSecondary = getCategoryObject(item?.secondary_category_id);
                                dummyDefault['items'].push({
                                    module_id: option.id,
                                    module_name: 'variant',
                                    secondary_category_id: item.module_id === option.id ? [objSecondary] : '',
                                    name: option?.translations?.title[languageId],
                                });
                            });
                        });
                    }
                });
                if (dummyDefault['items']?.length > 0) {
                    const result = Array.from(
                        dummyDefault['items']
                            .reduce(
                                (m, o) => m.set(o.module_id, (!o.secondary_category_id && m.get(o.module_id)) || o),
                                new Map()
                            )
                            .values()
                    );

                    if (result.length > 0) {
                        result?.forEach((item) => {
                            defaultValue['items'].push({
                                hasVaraint: item?.hasVaraint ? item.hasVaraint : 0,
                                module_id: item.module_id,
                                module_name: item.module_name,
                                name: item.name,
                                secondary_category_id: item?.secondary_category_id?.length
                                    ? item.secondary_category_id
                                    : [],
                            });
                        });
                    }
                }
            }
            let records = watch();
            reset({
                ...records,
                ...defaultValue,
            });
            setLoading(false);
        },
        [getCategoryObject, getItemOrVariantName, languageId, reset, setValue, watch]
    );

    useEffect(() => {
        if (offerId && watch('primary_category_id')?.length) {
            onChangeEdit(watch('primary_category_id'));
        }
    }, []);

    // dummyState from props

    if (loading) {
        return 'loading...';
    }

    return (
        <>
            <ComboBoxField
                options={categoryList}
                selectedOptions={watch('primary_category_id') ? watch('primary_category_id') : []}
                singleSelection={true}
                errors={errors}
                label='Select Primary category'
                name='primary_category_id'
                placeholder='Select a primary category'
                isDisabled={isItemsLoading}
                rules={{
                    required: 'Please select one primary category',
                    // validate: (value) =>
                    //     (watch('primary_category_id') && watch('primary_category_id').length > 0) ||
                    //     'Please select one primary category',
                }}
                control={control}
                onChange={onChange}
                map
            />
            <EuiSpacer siz='s' />
            {watch('primary_category_id')?.length ? (
                <>
                    <EuiText>
                        <h2>Items</h2>
                    </EuiText>

                    <EuiSpacer siz='s' />

                    {!watch('items')?.length ? <EuiCard title='No items found for the selected category' /> : null}

                    {!isItemsLoading ? (
                        <ItemListForCategorySelected {...props} categoryList={categoryList} />
                    ) : (
                        <EuiLoadingContent lines={3} />
                    )}
                </>
            ) : null}
        </>
    );
};

export default React.memo(CoupledOffer);
