import React, { useState } from 'react';
import { EuiDraggable, EuiPanel, EuiFlexGroup, EuiFlexItem, EuiIcon, EuiText } from '@elastic/eui';
import { useSelector, useDispatch } from 'react-redux';
import { userHasPermission } from '../../../helper/auth';
import RestaurantSubCategoryList from './restaurantSubCategoryList';

const RestaurantCategoryComponent = (props) => {
    const { item, index } = props;
    const permissions = useSelector((state) => state.permission.permission);
    const [isSubCategoryOpen, setIsSubCategoryOpen] = useState(false);

    return (
        <>
            <EuiDraggable
                spacing='m'
                key={item?.category_id?.toString()}
                index={index}
                draggableId={item?.category_id?.toString()}
                customDragHandle={true}
            >
                {(provided) => (
                    <EuiPanel className='custom' paddingSize='m'>
                        <EuiFlexGroup>
                            <EuiFlexItem
                                grow={false}
                                style={{
                                    justifyContent: 'center',
                                }}
                            >
                                {userHasPermission(permissions, '/menu', 'write') && (
                                    <div {...provided.dragHandleProps}>
                                        <EuiIcon type='grab' />
                                    </div>
                                )}
                            </EuiFlexItem>
                            <EuiFlexItem
                                style={{
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                }}
                            >
                                <EuiText> {item.internal_name} </EuiText>
                            </EuiFlexItem>

                            {item?.sub_categories?.length ? (
                                <div
                                    onClick={() => setIsSubCategoryOpen(!isSubCategoryOpen)}
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        cursor: 'pointer',
                                        marginRight: '5px',
                                    }}
                                >
                                    <EuiIcon type={isSubCategoryOpen ? 'arrowDown' : 'arrowUp'} />
                                </div>
                            ) : (
                                ''
                            )}
                        </EuiFlexGroup>
                    </EuiPanel>
                )}
            </EuiDraggable>
            {item?.sub_categories?.length && isSubCategoryOpen ? (
                <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '20px', marginRight: '4px' }}>
                    <RestaurantSubCategoryList item={item} />
                </div>
            ) : (
                ''
            )}
        </>
    );
};

export default RestaurantCategoryComponent;
