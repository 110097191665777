import React, { useEffect, useState } from 'react';
import {
    EuiBasicTable,
    EuiFlexItem,
    EuiButton,
    EuiBadge,
    EuiRadio,
    EuiSpacer,
    htmlIdGenerator,
    EuiText,
    EuiIcon,
    EuiTextColor,
    EuiLoadingContent,
    EuiCard,
    EuiFlexGroup,
} from '@elastic/eui';
import { useSelector, useDispatch } from 'react-redux';
import * as types from '../../reduxStore/types/orders';
import RupeeSymbol from '../rupeeComponent.js/index.js';
import { ReactComponent as PickUp } from '../../assets/img/pickUp.svg';
import { ReactComponent as TableIcon } from '../../assets/img/svg/LiveOrderTableIcon.svg';
import { ReactComponent as SelfServe } from '../../assets/img/svg/self_human.svg';
import { ReactComponent as Moped } from '../../assets/img/moped.svg';
import { ReactComponent as DineIn } from '../../assets/img/dineIn.svg';
import { isEmpty } from 'lodash';
import { getPastOrders } from '../../api/pastOrder/getPastOrders';
import { getAbandonedOrders } from '../../api/abandonedOrders/getAbandonedOrders';
import { getCustomerOrders } from '../../api/customer/getCustomerOrders';
import TimerComponent from './timerComponent';

export const paymentMethod = (payment) => {
    if (!isEmpty(payment)) {
        switch (payment.payment_method) {
            case 'CREDIT_CARD':
                return 'CREDIT CARD';
            case 'DEBIT_CARD':
                return 'DEBIT CARD';
            case 'NET_BANKING':
                return 'NET BANKING';
            case 'BALANCE':
                return 'PAYTM';
            case 'UPI':
                return 'UPI';
            case 'UPI_INTENT':
                return 'UPI';
            case 'CREDIT_CARD/DEBIT_CARD':
                return 'STRIPE';
            case 'CASH_ON_DELIVERY':
                return 'CASH';
            case 'PAY_LATTER':
                return 'PENDING';
            default:
                return 'PENDING';
        }
    }
};

const LiveOrdersTable = (props) => {
    const [items, setItems] = useState([]);
    let orders = useSelector((state) =>
        props.isPast
            ? state.orders.pastOrders
            : props.isCustomerOrder
            ? state.customer.customerOrderDetails
            : props.isAbandoned
            ? state.orders.abandonedCarts
            : state.orders.liveOrders
    );
    const currentOrderState = useSelector((state) => state.orders.currentOrderState);
    const currentOrderingMode = useSelector((state) => state.orders.currentOrderingMode);
    const tableLoader = useSelector((state) => state.orders.tableLoader);
    const ordersCount = useSelector((state) => state.orders.ordersCount);
    const selectedOrderId = useSelector((state) => state.orders.selectedOrderId);
    const themeMode = useSelector((state) => state.theme.theme);
    const dispatch = useDispatch();
    const [newOrders, setNewOrders] = useState([]);

    const [pagination, setPagination] = useState({
        pageIndex: 0,
        totalItemCount: 0,
        pageSize: 10,
        pageSizeOptions: [10, 50, 100],
    });

    useEffect(() => {
        if (props.isPast || props.isAbandoned || props.isCustomerOrder) {
            setPagination({
                pageIndex: props.state.current_page,
                totalItemCount: props.state.totalCounts,
                pageSize: props.state.page_size,
                pageSizeOptions: [10, 50, 100],
            });
        }
    }, [props.state]);

    const apiPaginationCall = (state, orderingMode) => {
        if (props.isPast) {
            dispatch({
                type: 'set-table-loader',
                payload: { loader: true, error: false },
            });
            dispatch(getPastOrders(props.startDateTime, props.endDateTime, state, orderingMode)).then((res) => {
                dispatch({
                    type: 'set-table-loader',
                    payload: { loader: false, error: false },
                });
                setPagination({
                    ...pagination,
                    pageIndex: res.order_collection.orders.current_page - 1,
                    totalItemCount: res.order_collection.orders.total,
                });
            });
        } else if (props.isAbandoned) {
            dispatch({
                type: 'set-table-loader',
                payload: { loader: true, error: false },
            });
            dispatch(getAbandonedOrders(props.startDateTime, props.endDateTime, state, orderingMode)).then((res) => {
                dispatch({
                    type: 'set-table-loader',
                    payload: { loader: false, error: false },
                });
                setPagination({
                    ...pagination,
                    pageIndex: res.carts.data.current_page - 1,
                    totalItemCount: res.carts.data.total,
                });
            });
        } else if (props.isCustomerOrder) {
            dispatch({
                type: 'set-table-loader',
                payload: { loader: true, error: false },
            });
            dispatch(getCustomerOrders(props.startDateTime, props.endDateTime, state, orderingMode)).then((res) => {
                dispatch({
                    type: 'set-table-loader',
                    payload: { loader: false, error: false },
                });
                setPagination({
                    ...pagination,
                    pageIndex: res.order_collection.orders.current_page - 1,
                    totalItemCount: res.order_collection.orders.total,
                });
            });
        }
    };

    useEffect(() => {
        apiPaginationCall(props.state, currentOrderingMode);
    }, [props.state, props.endDateTime]);

    const onTableChange = ({ page = {} }) => {
        const { index: pageIndex, size: pageSize } = page;
        setPagination({ ...pagination, pageIndex, pageSize });
        props.setState({
            ...props.state,
            current_page: pageIndex,
            page_size: pageSize,
        });
    };

    const [state, setState] = useState(true);

    const setTimeOutFunction = (liveOrder) => {
        if (liveOrder?.ordering_mode === 'takeaway' && liveOrder.restaurant_ready_at) {
            let date1 = new Date(liveOrder.restaurant_ready_at);
            let date2 = new Date();
            let differenceInMs = date2.getTime() - date1.getTime();

            if (differenceInMs / 60000 < 5) {
                const timer = setTimeout(() => {
                    setState(htmlIdGenerator()());
                }, differenceInMs);
            }
        }
    };

    const riderStatus = (order) => {
        if (order.rider_accept_at) {
            if (order.rider_picked_up_at) {
                return 'Rider picked up';
            } else if (order.rider_reached_to_location_at) {
                return 'Rider reached location';
            } else if (order.rider_reached_restaurant_at) {
                return 'Rider reached restaurant';
            } else if (order.rider_accept_at) {
                return 'Rider accepted';
            }
        }

        return false;
    };

    useEffect(() => {
        let allOrders = [];
        let newOrders = [];
        orders.map((liveOrder) => {
            if (
                !liveOrder.restaurant_accepted_at &&
                currentOrderState === 'all' &&
                window.location.pathname === '/live-orders'
            ) {
                newOrders.push({
                    id: !props.isAbandoned ? liveOrder.order_id : liveOrder.cart_id,
                    customer: liveOrder?.customer?.name,
                    // age: props.isAbandoned ? getTimeAgo(liveOrder.updated_at) : getTimeAgo(liveOrder.placed_at),
                    age: props.isAbandoned ? liveOrder.updated_at : liveOrder.placed_at,
                    value: liveOrder.net_total,
                    type: liveOrder,
                    status: liveOrder?.delivery_status ? liveOrder.delivery_status : '',
                    order_details: liveOrder,
                    setTimeOut: setTimeOutFunction(liveOrder),
                });
            } else {
                allOrders.push({
                    id: !props.isAbandoned ? liveOrder.order_id : liveOrder.cart_id,
                    customer: liveOrder?.customer?.name,
                    // age: props.isAbandoned ? getTimeAgo(liveOrder.updated_at) : getTimeAgo(liveOrder.placed_at) ,
                    age: props.isAbandoned ? liveOrder.updated_at : liveOrder.placed_at,
                    value: liveOrder.net_total,
                    type: liveOrder,
                    status: liveOrder?.delivery_status ? liveOrder.delivery_status : '',
                    order_details: liveOrder,
                    setTimeOut: setTimeOutFunction(liveOrder),
                });
            }
        });
        setNewOrders(newOrders);
        setItems(allOrders);
        // getNewOrders()
    }, [orders, state]);

    // useEffect(() => {
    //     if(!isEmpty(orders) && window.location.pathname === '/live-orders'){
    //         let newOrdersData = []
    //         newOrdersData = orders.filter((data) => !data.restaurant_accepted_at);
    //         orders = orders.filter((data) => data.restaurant_accepted_at);
    //         setNewOrders(newOrdersData)
    //     }
    // }, [orders])

    const orderStatus = (item) => {
        if (item.order_details.rider_picked_up_at) {
            return 'DISPATCHED';
        }

        if (item.order_details.restaurant_ready_at) {
            return 'READY';
        }
        if (item.order_details.restaurant_accepted_at) {
            return 'PREPARING';
        }

        return 'PENDING';
    };

    const calculateElapsedTime = (selectedOrder) => {
        if (selectedOrder.restaurant_ready_at) {
            let date1 = new Date(selectedOrder.restaurant_ready_at);
            let date2 = new Date();
            let differenceInMins = (date2.getTime() - date1.getTime()) / 60000;

            return differenceInMins > 5;
        }
        return false;
    };

    const calculateElapsedTimeMS = (selectedOrder) => {
        if (selectedOrder.placed_at) {
            let date1 = new Date(selectedOrder.placed_at);
            let date2 = new Date();
            let differenceInMs = date2.getTime() - date1.getTime();
            return differenceInMs;
        }
    };

    let columnsData = [
        {
            field: 'customer',
            name: 'Customer',
            sortable: true,
            width: '40%',
            'data-test-subj': 'firstNameCell',
            render: (customer, item) => {
                if (props.isPast || props.isCustomerOrder) {
                    return (
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <EuiText size='s'>{customer}</EuiText>
                            {item.order_details.delivery_status === 'restaurant_cancelled' ? (
                                <>
                                    <EuiSpacer size='xs' />
                                    <EuiFlexItem style={{ flexDirection: 'row', alignItems: 'center' }}>
                                        <EuiIcon size='s' type='crossInACircleFilled' color='danger' />
                                        &nbsp;
                                        <EuiText size='xs'>
                                            <EuiTextColor color='danger'>Cancelled Order</EuiTextColor>
                                        </EuiText>
                                    </EuiFlexItem>
                                </>
                            ) : (
                                <>
                                    <EuiSpacer size='xs' />
                                    <EuiFlexItem style={{ flexDirection: 'row', alignItems: 'center' }}>
                                        <EuiIcon size='s' type='checkInCircleFilled' color='success' />
                                        &nbsp;
                                        <EuiText size='xs'>
                                            <EuiTextColor color='secondary'>Delivered Order</EuiTextColor>
                                        </EuiText>
                                    </EuiFlexItem>
                                </>
                            )}
                        </div>
                    );
                } else if (item.order_details.waiting_for_rider_assignment) {
                    return (
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <EuiText size='s'>{customer}</EuiText>
                            {!props.isPast && !props.isAbandoned && !props.isCustomerOrder ? (
                                <>
                                    <EuiSpacer size='xs' />
                                    <EuiFlexItem style={{ flexDirection: 'row', alignItems: 'center' }}>
                                        <EuiIcon size='s' type='alert' color='subdued' />
                                        &nbsp;
                                        <EuiText size='xs'>
                                            <EuiTextColor color='subdued' size='xs'>
                                                Rider will auto assign
                                            </EuiTextColor>{' '}
                                        </EuiText>
                                    </EuiFlexItem>
                                </>
                            ) : (
                                ''
                            )}
                        </div>
                    );
                } else if (item.order_details.is_rider_assigned) {
                    // (item.order_details.rider || item.order_details.elt_order || item.order_details.dunzo_order || item.order_details.wefast_order || item.order_details.scout_order)
                    return (
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <EuiText size='s'>{customer}</EuiText>
                            {!props.isPast && !props.isAbandoned && !props.isCustomerOrder ? (
                                <>
                                    <EuiSpacer size='xs' />
                                    <EuiFlexItem style={{ flexDirection: 'row', alignItems: 'center' }}>
                                        {item.order_details.rider_accept_at ? (
                                            <>
                                                <Moped fill='#017D73' height='16' width='16' />
                                                &nbsp;
                                                <EuiText size='xs'>
                                                    <EuiTextColor color='secondary'>
                                                        {riderStatus(item.order_details)}
                                                    </EuiTextColor>
                                                </EuiText>
                                            </>
                                        ) : (
                                            <>
                                                <EuiIcon size='s' type='checkInCircleFilled' color='success' />
                                                &nbsp;
                                                <EuiText size='xs'>
                                                    <EuiTextColor color='secondary'>Rider Assigned</EuiTextColor>
                                                </EuiText>
                                            </>
                                        )}
                                    </EuiFlexItem>
                                </>
                            ) : (
                                ''
                            )}
                        </div>
                    );
                } else if (item.order_details.rider_assignment_action_required) {
                    return (
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <EuiText size='s'>{customer}</EuiText>
                            {!props.isPast && !props.isAbandoned && !props.isCustomerOrder ? (
                                <>
                                    <EuiSpacer size='xs' />
                                    <EuiFlexItem style={{ flexDirection: 'row', alignItems: 'center' }}>
                                        <EuiIcon size='s' type='alert' color='danger' />
                                        &nbsp;
                                        <EuiText size='xs'>
                                            <EuiTextColor color='danger' size='xs'>
                                                Action required
                                            </EuiTextColor>{' '}
                                        </EuiText>
                                    </EuiFlexItem>
                                </>
                            ) : (
                                ''
                            )}
                        </div>
                    );
                } else if (item.order_details.ordering_mode === 'delivery' && !item.order_details.is_rider_assigned) {
                    // !(item.order_details.rider || item.order_details.elt_order || item.order_details.dunzo_order || item.order_details.wefast_order || item.order_details.scout_order)
                    return (
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <EuiText size='s'>{customer}</EuiText>
                            {!props.isPast && !props.isAbandoned && !props.isCustomerOrder ? (
                                <>
                                    <EuiSpacer size='xs' />
                                    <EuiFlexItem style={{ flexDirection: 'row', alignItems: 'center' }}>
                                        <EuiIcon size='s' type='iInCircle' color='warning' />
                                        &nbsp;
                                        <EuiText size='xs'>
                                            <EuiTextColor color='warning' size='xs'>
                                                Rider not assigned
                                            </EuiTextColor>{' '}
                                        </EuiText>
                                    </EuiFlexItem>
                                </>
                            ) : (
                                ''
                            )}
                        </div>
                    );
                } else if (
                    item.order_details.ordering_mode !== 'delivery' &&
                    calculateElapsedTime(item.order_details)
                ) {
                    return (
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <EuiText size='s'>{customer}</EuiText>
                            {!props.isPast && !props.isAbandoned && !props.isCustomerOrder ? (
                                <>
                                    <EuiSpacer size='xs' />

                                    <EuiFlexItem style={{ flexDirection: 'row', alignItems: 'center' }}>
                                        <EuiIcon size='s' type='alert' color='danger' />
                                        &nbsp;
                                        <EuiText size='xs'>
                                            <EuiTextColor color='danger' size='xs'>
                                                Action required
                                            </EuiTextColor>{' '}
                                        </EuiText>
                                    </EuiFlexItem>
                                </>
                            ) : (
                                ''
                            )}
                        </div>
                    );
                }

                const type = item?.order_details?.ordering_mode_details?.type
                    ? item?.order_details?.ordering_mode_details?.type
                    : item?.type?.ordering_mode_details?.type
                    ? item?.type?.ordering_mode_details?.type
                    : item?.type?.ordering_mode;
                return (
                    <EuiFlexItem>
                        <EuiText size='s'>{customer}</EuiText>

                        <EuiFlexGroup gutterSize='none' alignItems='center' justifyContent='flexStart'>
                            <EuiFlexItem grow={false}>
                                {type === 'qsr' || type === 'fine_dine' ? (
                                    <TableIcon
                                        height='18'
                                        width='18'
                                        fill={themeMode === 'light' ? 'black' : 'white'}
                                    />
                                ) : null}
                            </EuiFlexItem>
                            <EuiFlexItem grow={false} style={{ marginLeft: '6px' }}>
                                <EuiText size='xs'>
                                    <EuiTextColor color='warning'>
                                        {type === 'self_serve' ? 'Self Serve' : item?.order_details?.table?.code}
                                    </EuiTextColor>
                                </EuiText>
                            </EuiFlexItem>
                        </EuiFlexGroup>
                    </EuiFlexItem>
                );
            },
        },
        {
            field: 'age',
            name: 'Age',
            width: '15%',
            truncateText: true,
            align: 'center',
            render: (age, item) => {
                return <TimerComponent time={age} />;
            },
        },
        {
            field: 'value',
            name: 'Value',
            width: '15%',
            align: 'center',
            render: (value, item) => {
                return (
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <div>
                            <EuiText size='s'>
                                <RupeeSymbol size={'14px'} />
                                {value}
                            </EuiText>
                        </div>
                        {!props.isAbandoned && item?.order_details?.payment_method ? (
                            <>
                                <EuiSpacer size='xs' />
                                <div
                                    style={{
                                        border:
                                            item?.order_details?.payment_method?.payment_method == 'CASH_ON_DELIVERY'
                                                ? '1px solid rgb(246, 131, 15)'
                                                : '1px solid rgb(14, 145, 140)',
                                    }}
                                >
                                    <EuiText style={{ margin: '0 2px' }} size='xs'>
                                        {paymentMethod(item?.order_details?.payment_method)}
                                    </EuiText>
                                </div>
                            </>
                        ) : null}
                    </div>
                );
            },
        },
        {
            field: 'type',
            name: 'Type',
            width: '15%',
            align: 'center',
            render: (type) => {
                const modeType = type?.ordering_mode_details?.type
                    ? type?.ordering_mode_details?.type
                    : type.ordering_mode;

                if (modeType === 'takeaway') {
                    return (
                        <EuiFlexItem style={{ alignItems: 'center' }}>
                            <PickUp height='18' width='18' fill={themeMode === 'light' ? 'black' : 'white'} />{' '}
                            <span style={{ fontSize: '12px' }}>Takeaway</span>{' '}
                        </EuiFlexItem>
                    );
                } else if (modeType === 'delivery') {
                    return (
                        <EuiFlexItem style={{ alignItems: 'center' }}>
                            <Moped height='18' width='18' fill={themeMode === 'light' ? 'black' : 'white'} />{' '}
                            <span style={{ fontSize: '12px' }}>Delivery</span>{' '}
                        </EuiFlexItem>
                    );
                } else if (modeType === 'fine_dine') {
                    return (
                        <EuiFlexItem style={{ alignItems: 'center' }}>
                            <DineIn height='18' width='18' fill={themeMode === 'light' ? 'black' : 'white'} />{' '}
                            <span style={{ fontSize: '12px' }}>Dine in</span>{' '}
                        </EuiFlexItem>
                    );
                } else if (modeType === 'qsr') {
                    return (
                        <EuiFlexItem style={{ alignItems: 'center' }}>
                            <DineIn height='18' width='18' fill={themeMode === 'light' ? 'black' : 'white'} />{' '}
                            <span style={{ fontSize: '12px' }}>QSR</span>{' '}
                        </EuiFlexItem>
                    );
                } else if (modeType === 'self_serve') {
                    return (
                        <EuiFlexItem style={{ alignItems: 'center' }}>
                            <SelfServe height='18' width='18' fill={themeMode === 'light' ? 'black' : 'white'} />{' '}
                            <span style={{ fontSize: '12px' }}>Self Serve</span>{' '}
                        </EuiFlexItem>
                    );
                }
            },
        },
        {
            field: 'status',
            name: 'Status',
            width: '15%',
            align: 'center',
            render: (status, item) => {
                let statusData = orderStatus(item);
                return (
                    <EuiBadge
                        color={
                            statusData == 'PENDING'
                                ? '#275AF6'
                                : statusData == 'PREPARING'
                                ? '#4DBE9F'
                                : statusData == 'READY'
                                ? '#1B1A28'
                                : '#C0C5FF'
                        }
                    >
                        {statusData}
                    </EuiBadge>
                );
            },
        },
    ];

    const [columns, setColumns] = useState([]);
    useEffect(() => {
        if (props.isPast || props.isAbandoned || props.isCustomerOrder) {
            let innerColumns = [...columnsData];
            innerColumns.splice(4, 1);
            setColumns(innerColumns);
        } else {
            setColumns(columnsData);
        }
    }, []);

    const getRowProps = (item) => {
        const { id } = item;
        return {
            'data-test-subj': `row-${id}`,
            style: { backgroundColor: selectedOrderId == id ? (themeMode === 'light' ? '#f5f7fa' : '#6d737e') : '' },
            className: 'customRowClass',
            onClick: () => {
                dispatch({
                    type: types.SET_SELECTED_ORDER,
                    payload: id,
                });
            },
        };
    };

    const getCellProps = (item, column) => {
        const { id } = item;
        const { field } = column;
        return {
            className: 'customCellClass',
            'data-test-subj': `cell-${id}-${field}`,
            textOnly: true,
        };
    };

    const onChangeRadio = (name) => {
        dispatch({
            type: types.SET_SELECTED_ORDER,
            payload: '',
        });
        dispatch({
            type: types.SET_SELECTED_ORDER_DETAILS,
            payload: {},
        });
        dispatch({
            type: types.SET_CURRENT_ORDER_MODE,
            payload: name,
        });

        apiPaginationCall({ current_page: 0, page_size: 10 }, name);
    };

    const onStatusChange = (type) => {
        dispatch({
            type: types.SET_SELECTED_ORDER,
            payload: '',
        });
        dispatch({
            type: types.SET_SELECTED_ORDER_DETAILS,
            payload: {},
        });
        dispatch({
            type: types.SET_CURRENT_ORDER_STATE,
            payload: type,
        });
    };

    return (
        <>
            <EuiFlexItem grow={1}>
                <div
                    style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    <div style={{ width: '50%', fontSize: '20.3px', fontWeight: 'bold' }}>
                        <EuiText>
                            <h3>
                                <b>
                                    {props.isPast
                                        ? 'Past orders'
                                        : props.isAbandoned
                                        ? 'Abandoned orders'
                                        : props.isCustomerOrder
                                        ? 'Customer orders'
                                        : 'Live Orders'}
                                </b>
                            </h3>
                        </EuiText>
                    </div>

                    <div
                        style={{
                            width: '50%',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            paddingRight: '0 5px',
                        }}
                    >
                        <EuiRadio
                            className='radioFontSize'
                            id={htmlIdGenerator()()}
                            checked={currentOrderingMode === 'all'}
                            label='All'
                            onChange={(e) => onChangeRadio('all')}
                        />
                        <EuiRadio
                            className='radioFontSize'
                            id={htmlIdGenerator()()}
                            checked={currentOrderingMode === 'takeaway'}
                            label='Takeaway'
                            onChange={(e) => onChangeRadio('takeaway')}
                        />
                        <EuiRadio
                            className='radioFontSize'
                            id={htmlIdGenerator()()}
                            checked={currentOrderingMode === 'delivery'}
                            label='Delivery'
                            onChange={(e) => onChangeRadio('delivery')}
                        />
                        <EuiRadio
                            className='radioFontSize'
                            id={htmlIdGenerator()()}
                            checked={currentOrderingMode === 'dine_in'}
                            label='Dine In'
                            onChange={(e) => onChangeRadio('dine_in')}
                        />
                    </div>
                </div>
            </EuiFlexItem>

            {!props.isPast && !props.isAbandoned && !props.isCustomerOrder ? (
                <>
                    <EuiSpacer />
                    <EuiFlexItem
                        grow={1}
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            width: '100%',
                            height: '32px',
                            backgroundColor: 'rgba(105, 112, 125, 0.2)',
                        }}
                    >
                        <EuiFlexItem
                            style={{
                                backgroundColor: currentOrderState == 'all' ? '#0071c2' : 'rgba(105, 112, 125, 0.2)',
                                justifyContent: 'center',
                                width: '20%',
                                boxShadow: currentOrderState == 'all' ? '0 0 7px 0 rgba(0, 0, 0, 0.5)' : '',
                                borderRadius: currentOrderState == 'all' ? '3px' : '',
                            }}
                        >
                            <EuiButton
                                onClick={() => onStatusChange('all')}
                                style={{
                                    fontSize: '14px',
                                    textDecorationLine: 'none',
                                    border: 'none',
                                    boxShadow: 'none',
                                    background: 'transparent',
                                    color: currentOrderState == 'all' ? '#FFFFFF' : '#5a606b',
                                }}
                            >
                                All ({ordersCount.all})
                            </EuiButton>
                        </EuiFlexItem>

                        <EuiFlexItem
                            style={{
                                backgroundColor: currentOrderState == 'placed' ? '#0071c2' : 'rgba(105, 112, 125, 0.2)',
                                justifyContent: 'center',
                                width: '20%',
                                boxShadow: currentOrderState == 'placed' ? '0 0 7px 0 rgba(0, 0, 0, 0.5)' : '',
                                borderRadius: currentOrderState == 'placed' ? '3px' : '',
                            }}
                        >
                            <EuiButton
                                onClick={() => onStatusChange('placed')}
                                style={{
                                    fontSize: '14px',
                                    textDecorationLine: 'none',
                                    border: 'none',
                                    boxShadow: 'none',
                                    background: 'transparent',
                                    color: currentOrderState == 'placed' ? '#FFFFFF' : '#5a606b',
                                }}
                            >
                                Pending ({ordersCount.placed ? ordersCount.placed : 0})
                            </EuiButton>
                        </EuiFlexItem>

                        <EuiFlexItem
                            style={{
                                backgroundColor:
                                    currentOrderState == 'restaurant_accepted' ? '#0071c2' : 'rgba(105, 112, 125, 0.2)',
                                justifyContent: 'center',
                                width: '20%',
                                boxShadow:
                                    currentOrderState == 'restaurant_accepted' ? '0 0 7px 0 rgba(0, 0, 0, 0.5)' : '',
                                borderRadius: currentOrderState == 'restaurant_accepted' ? '3px' : '',
                            }}
                        >
                            <EuiButton
                                onClick={() => onStatusChange('restaurant_accepted')}
                                style={{
                                    fontSize: '14px',
                                    textDecorationLine: 'none',
                                    border: 'none',
                                    boxShadow: 'none',
                                    background: 'transparent',
                                    color: currentOrderState == 'restaurant_accepted' ? '#FFFFFF' : '#5a606b',
                                }}
                            >
                                Preparing ({ordersCount.restaurant_accepted ? ordersCount.restaurant_accepted : 0})
                            </EuiButton>
                        </EuiFlexItem>

                        <EuiFlexItem
                            style={{
                                backgroundColor:
                                    currentOrderState == 'restaurant_ready' ? '#0071c2' : 'rgba(105, 112, 125, 0.2)',
                                justifyContent: 'center',
                                width: '20%',
                                boxShadow:
                                    currentOrderState == 'restaurant_ready' ? '0 0 7px 0 rgba(0, 0, 0, 0.5)' : '',
                                borderRadius: currentOrderState == 'restaurant_ready' ? '3px' : '',
                            }}
                        >
                            <EuiButton
                                onClick={() => onStatusChange('restaurant_ready')}
                                style={{
                                    fontSize: '14px',
                                    textDecorationLine: 'none',
                                    border: 'none',
                                    boxShadow: 'none',
                                    background: 'transparent',
                                    color: currentOrderState == 'restaurant_ready' ? '#FFFFFF' : '#5a606b',
                                }}
                            >
                                Ready ({ordersCount.restaurant_ready ? ordersCount.restaurant_ready : 0})
                            </EuiButton>
                        </EuiFlexItem>

                        <EuiFlexItem
                            style={{
                                backgroundColor:
                                    currentOrderState == 'rider_picked_up' ? '#0071c2' : 'rgba(105, 112, 125, 0.2)',
                                justifyContent: 'center',
                                width: '20%',
                                boxShadow: currentOrderState == 'rider_picked_up' ? '0 0 7px 0 rgba(0, 0, 0, 0.5)' : '',
                                borderRadius: currentOrderState == 'rider_picked_up' ? '3px' : '',
                            }}
                        >
                            <EuiButton
                                onClick={() => onStatusChange('rider_picked_up')}
                                style={{
                                    fontSize: '14px',
                                    textDecorationLine: 'none',
                                    border: 'none',
                                    boxShadow: 'none',
                                    background: 'transparent',
                                    color: currentOrderState == 'rider_picked_up' ? '#FFFFFF' : '#5a606b',
                                }}
                            >
                                Dispatched ({ordersCount.rider_picked_up ? ordersCount.rider_picked_up : 0})
                            </EuiButton>
                        </EuiFlexItem>
                    </EuiFlexItem>
                </>
            ) : (
                ''
            )}

            <EuiFlexItem style={{ marginTop: '10px' }} grow={8}>
                {!tableLoader.loader ? (
                    <>
                        {newOrders.length ? (
                            <EuiFlexItem>
                                <EuiCard
                                    style={{
                                        backgroundColor: themeMode === 'light' ? '#dfe7ff' : '#445a91',
                                        padding: '0px',
                                        boxShadow:
                                            '0 10px 10px -2px rgb(152 162 179 / 30%), 0 1px 24px -2px rgb(152 162 179 / 30%)',
                                    }}
                                    paddingSize='none'
                                    layout='horizontal'
                                    title={
                                        <EuiFlexItem style={{ padding: '5px 10px' }}>
                                            <EuiText size='s'>
                                                <h3>
                                                    <b>New Orders</b>
                                                </h3>
                                            </EuiText>
                                        </EuiFlexItem>
                                    }
                                    description={
                                        <EuiBasicTable
                                            items={newOrders}
                                            rowHeader='firstName'
                                            columns={columns}
                                            rowProps={getRowProps}
                                            cellProps={getCellProps}
                                            noItemsMessage='No orders found'
                                            pagination={
                                                props.isPast || props.isAbandoned || props.isCustomerOrder
                                                    ? pagination
                                                    : ''
                                            }
                                            onChange={
                                                props.isPast || props.isAbandoned || props.isCustomerOrder
                                                    ? onTableChange
                                                    : ''
                                            }
                                        />
                                    }
                                />
                                <EuiSpacer size='m' />
                            </EuiFlexItem>
                        ) : (
                            ''
                        )}
                        <EuiFlexItem>
                            {newOrders.length ? (
                                <EuiText size='s'>
                                    <h3>
                                        <b>Running orders</b>
                                    </h3>
                                </EuiText>
                            ) : (
                                ''
                            )}
                            <EuiBasicTable
                                items={items}
                                rowHeader='firstName'
                                columns={columns}
                                rowProps={getRowProps}
                                cellProps={getCellProps}
                                noItemsMessage='No orders found'
                                pagination={
                                    props.isPast || props.isAbandoned || props.isCustomerOrder ? pagination : ''
                                }
                                onChange={
                                    props.isPast || props.isAbandoned || props.isCustomerOrder ? onTableChange : ''
                                }
                            />
                        </EuiFlexItem>
                    </>
                ) : (
                    <EuiLoadingContent lines={10} />
                )}
            </EuiFlexItem>
        </>
    );
};

export default LiveOrdersTable;
