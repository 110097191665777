import { EuiButton, EuiFlexGroup, EuiFlexItem } from '@elastic/eui';
import React, { useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { userHasPermission } from '../../helper/auth';
import TableCreationFlyout from './TableCreationFlyout';

const AddTable = ({ isEditTableMode }) => {
    const history = useHistory();
    const sections = useSelector((state) => state.tableManagement.sectionList);
    const [showFlyout, setShowFlyout] = useState(false);
    const permissions = useSelector((state) => state.permission.permission);

    const handleFlyoutState = useCallback(() => {
        setShowFlyout((prevState) => !prevState);
    }, []);

    return (
        <>
            <EuiFlexGroup justifyContent='flexEnd'>
                <EuiFlexItem grow={false}>
                    <EuiButton
                        disabled={
                            !sections?.length ||
                            isEditTableMode ||
                            !userHasPermission(permissions, history.location.pathname, 'write')
                        }
                        fill
                        onClick={handleFlyoutState}
                    >
                        Add Table
                    </EuiButton>
                </EuiFlexItem>
            </EuiFlexGroup>
            {showFlyout ? <TableCreationFlyout closeFlyout={handleFlyoutState} /> : null}
        </>
    );
};

export default React.memo(AddTable);
