import { EuiComboBox, EuiFormRow, EuiSpacer, EuiTextColor } from '@elastic/eui';
import { isEmpty } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { getErrorMesage, getItem, getTaxAmount } from './utils/utils';

const ItemLinkOrderingModes = ({
    orderingMode,
    category_id,
    fieldIndex,
    modeIndex,
    itemsOptions,
    setSelectedItem,
    item,
    setVariantOptions,
}) => {
    const [amountError, setAmountError] = useState([]);
    const petPoojaTaxes = useSelector((state) => state.petPoojaReducer.petPoojaTaxes);
    const petPoojaRestaurant = useSelector((state) => state.petPoojaReducer.petPoojaRestaurant);
    const optionCount = item?.variants[0]?.options?.length;
    const variantCount = item?.variants?.length;
    const linkedItems = useSelector((state) => state.petPoojaReducer.petPoojaLinkedItems);
    const selectedOutletId = useSelector((state) => state.outlet.selectedOutletId);
    const petPoojaItems = useSelector((state) => state.petPoojaReducer.petPoojaItems);
    const {
        setValue,
        control,
        formState: { errors, isDirty },
        watch,
    } = useFormContext();

    useEffect(() => {
        if (!isDirty) {
            setAmountError([]);
        }
    }, [isDirty]);

    const itemVerification = useCallback(
        (selectedOption) => {
            let itemResponse = getItem(selectedOption[0].itemid, petPoojaItems);
            let { taxArrayObj, taxAmount } = getTaxAmount(itemResponse, petPoojaTaxes, item.price);

            selectedOption[0] = {
                ...selectedOption[0],
                tax: taxArrayObj,
            };
            let fabSystemTaxAmount = (Number(item.price) * Number(item.gst_slab)) / 100;
            let errorArray = [];
            let errorCheck = false;
            if (!item?.variants?.length) {
                if (item?.ordering_modes?.length) {
                    for (const orderingMode of item.ordering_modes) {
                        if (
                            orderingMode?.restaurant_ordering_mode_id ===
                            orderingMode?.selected_item?.[0]?.restaurant_ordering_mode_id
                        ) {
                            // selectedOption[0].restaurant_ordering_mode_id
                            if (orderingMode.price != itemResponse.price) {
                                errorCheck = true;
                                errorArray.push(
                                    `Fab system price ${orderingMode.price} ≠ Pet Pooja price ${itemResponse.price}`
                                );
                            }
                            if (
                                orderingMode.packaging_charge != itemResponse.item_packingcharges &&
                                petPoojaRestaurant?.[0]?.details?.packaging_applicable_on === 'ITEM'
                            ) {
                                errorCheck = true;
                                errorArray.push(
                                    `Packaging Fab System ${orderingMode.packaging_charge} ≠ Pacakging Pet Pooja ${itemResponse.item_packingcharges}`
                                );
                            }

                            if (fabSystemTaxAmount != taxAmount) {
                                errorCheck = true;
                                errorArray.push(`Tax Fab System ${fabSystemTaxAmount} ≠ Tax Pet Pooja ${taxAmount}`);
                            }
                        }
                    }
                    const filteredErrors = errorArray.reduce(
                        (acc, curr) => (acc?.find((error) => error === curr) ? acc : [...acc, curr]),
                        []
                    );
                    setAmountError(filteredErrors);
                }
            }

            if (
                selectedOption.length &&
                !(
                    ((optionCount >= 0 ? optionCount : variantCount) >= 0 && 0 <= itemResponse?.variation?.length) ||
                    ((optionCount >= 0 ? optionCount : variantCount) === 0 && itemResponse?.variation?.length === 0)
                )
            ) {
                errorCheck = true;
                setAmountError([
                    ...errorArray,
                    `Variants mismatch Fab System ${optionCount >= 0 ? optionCount : variantCount} ≠ Pet Pooja ${
                        itemResponse?.variation?.length
                    }`,
                ]);
                errorArray.push(
                    `Variants mismatch Fab System ${optionCount >= 0 ? optionCount : variantCount} ≠ Pet Pooja ${
                        itemResponse?.variation?.length
                    }`
                );
            }

            return { taxArrayObj, errorCheck, errorArray };
        },
        [item, petPoojaItems, petPoojaRestaurant, petPoojaTaxes]
    );

    const checkForVariantValidation = useCallback((petpoojaItem, fabItem) => {
        let error = false;
        let errorArray = [];
        const petPoojaVariantCount = petpoojaItem[0]?.variation?.length;
        const fabVariantCount = fabItem?.variants?.[0]?.options?.length ? fabItem?.variants?.[0]?.options.length : 0;

        if (!(petPoojaVariantCount >= fabVariantCount)) {
            error = true;
            setAmountError([
                ...errorArray,
                `Variants mismatch Fab System ${fabVariantCount} ≠ Variants Pet Pooja ${petPoojaVariantCount}`,
            ]);
            errorArray.push(
                `Variants mismatch Fab System ${fabVariantCount} ≠ Variants Pet Pooja ${petPoojaVariantCount}`
            );
        } else if (petPoojaVariantCount !== 0) {
            if (fabVariantCount < 1) {
                error = true;
                setAmountError([
                    ...errorArray,
                    `Variants mismatch Fab System ${fabVariantCount} ≠ Variants Pet Pooja ${petPoojaVariantCount}`,
                ]);
                errorArray.push(
                    `Variants mismatch Fab System ${fabVariantCount} ≠ Variants Pet Pooja ${petPoojaVariantCount}`
                );
            }
        } else if (petpoojaItem[0]?.variation.length > 0 && fabItem?.variants.length === 0) {
            setAmountError([
                ...errorArray,
                `Variants mismatch Fab System ${fabVariantCount} ≠ Variants Pet Pooja ${petPoojaVariantCount}`,
            ]);
            errorArray.push(
                `Variants mismatch Fab System ${fabVariantCount} ≠ Variants Pet Pooja ${petPoojaVariantCount}`
            );
            error = true;
        }

        return error;
    }, []);

    const checkItemValidation = useCallback(
        (selectedOption, orderingModeId) => {
            let isItemValid = false;
            if (!selectedOption?.length) {
                isItemValid = true;
                setSelectedItem([]);
                setAmountError([]);
            } else {
                let { taxArrayObj, errorCheck, errorArray } = itemVerification(selectedOption);
                selectedOption[0].id = selectedOption[0]?.third_party_module_id;
                selectedOption[0].restaurant_ordering_mode_id = orderingModeId;
                let innerArray = [];
                innerArray.push({
                    from: 'item',
                    fromId: item.item_id,
                    selectedLinkId: selectedOption[0].id,
                    title: selectedOption[0].label,
                    tax: taxArrayObj,
                    packaging_charge: selectedOption[0].item_packingcharges,
                    price: selectedOption[0].price,
                    restaurant_ordering_mode_id: orderingModeId,
                });

                selectedOption[0] = {
                    ...selectedOption[0],
                    ...innerArray[0],
                };

                setSelectedItem([...selectedOption]);
                // const petPoojaItem = '';
                const petPoojaItem = getItem(selectedOption[0].itemid, petPoojaItems);
                // variantSet({ id: selectedOption[0].id }); for variants
                let variantError = checkForVariantValidation([petPoojaItem], item);

                if (errorCheck || variantError) {
                    isItemValid = false;
                } else {
                    setAmountError([]);
                    isItemValid = true;
                }
                if (errorArray?.length) {
                    isItemValid = false;
                } else {
                    isItemValid = true;
                }
            }

            return isItemValid;
        },
        [amountError, checkForVariantValidation, item, itemVerification, petPoojaItems, setSelectedItem]
    );

    const handleItemLinkChange = useCallback(
        (selectedValue, item) => {
            if (selectedValue?.[0]?.ordering_modes) {
                delete selectedValue[0]?.ordering_modes;
            }

            setValue(`item_${category_id}[${fieldIndex}.ordering_modes[${modeIndex}].selected_item]`, selectedValue, {
                shouldDirty: true,
            });
            let filterItems = petPoojaItems.filter((item) => item?.itemid == selectedValue?.[0]?.third_party_module_id);
            if (filterItems?.length) {
                let variants = [];
                filterItems[0]?.variation?.forEach((option) => {
                    variants.push({
                        id: option.variationid,
                        label: option.name,
                        module_name: 'variant_option',
                        outlet_id: selectedOutletId,
                        third_party_service_provider: 'pet-pooja',
                        third_party_module_id: option.variationid,
                        variationid: option.variationid,
                        // module_id: item.item_id,

                        ...option,
                    });
                });
                setVariantOptions(variants);
            }

            // UNLINK LOGIC
            if (!selectedValue?.length && item?.length && linkedItems?.length) {
                const currentRemovedItem = item[0];
                for (const linkedItem of linkedItems) {
                    if (
                        linkedItem.third_party_module_id === currentRemovedItem.third_party_module_id &&
                        linkedItem.restaurant_ordering_mode_id === orderingMode.restaurant_ordering_mode_id &&
                        currentRemovedItem.module_id === linkedItem.module_id
                    ) {
                        if (watch('menu_unlinks')?.length) {
                            setValue('menu_unlinks', [...watch('menu_unlinks'), currentRemovedItem], {
                                shouldDirty: true,
                            });
                        } else {
                            setValue('menu_unlinks', [currentRemovedItem], { shouldDirty: true });
                        }
                    }
                }
            }
            // REMOVE UNLINK ITEMS FROM UNLINK STATE IF LINKED BACK

            if (selectedValue?.length && watch('menu_unlinks')?.length) {
                const removeItemFromUnlinkState = watch('menu_unlinks')?.filter((menu_unlink) => {
                    return !(
                        menu_unlink.third_party_module_id === selectedValue[0].third_party_module_id &&
                        menu_unlink.restaurant_ordering_mode_id === orderingMode.restaurant_ordering_mode_id &&
                        selectedValue[0].module_id === menu_unlink.module_id
                    );
                });

                setValue('menu_unlinks', removeItemFromUnlinkState, { shouldDirty: true });
            }
        },
        [
            category_id,
            fieldIndex,
            linkedItems,
            modeIndex,
            petPoojaItems,
            selectedOutletId,
            setValue,
            setVariantOptions,
            watch,
            orderingMode,
        ]
    );

    return (
        <React.Fragment>
            <EuiFormRow
                label={orderingMode.restaurant_ordering_mode_name}
                display={errors.message}
                error={
                    errors[`item_${category_id}[${fieldIndex}.ordering_modes[${modeIndex}].selected_item]`]
                        ? errors[`item_${category_id}[${fieldIndex}.ordering_modes[${modeIndex}].selected_item]`]
                              .message
                        : null
                }
            >
                <Controller
                    control={control}
                    name={`item_${category_id}[${fieldIndex}.ordering_modes[${modeIndex}].selected_item]`}
                    rules={{
                        validate: {
                            positive: (selectedOption) => {
                                return !checkItemValidation(selectedOption, orderingMode.restaurant_ordering_mode_id)
                                    ? amountError
                                    : true;
                            },
                        },
                    }}
                    render={({ field }) => {
                        return (
                            <EuiComboBox
                                options={itemsOptions}
                                placeholder='Select Item'
                                isClearable={true}
                                singleSelection={true}
                                selectedOptions={field.value}
                                onBlur={field.onBlur}
                                onChange={(selectedValue) => {
                                    field.onChange(selectedValue);
                                    handleItemLinkChange(selectedValue, field.value);
                                }}
                            />
                        );
                    }}
                />
            </EuiFormRow>
            <EuiSpacer size='s' />
            {!isEmpty(amountError) ? (
                <EuiTextColor siz='s' color='danger'>
                    {getErrorMesage(amountError)}
                </EuiTextColor>
            ) : null}
            <EuiSpacer size='s' />
        </React.Fragment>
    );
};

export default React.memo(ItemLinkOrderingModes);
