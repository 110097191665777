import React from 'react';
import { MainLayout } from '../../components/layout/mainLayout/mainLayout';
// import BillingComponent from '../../components/billing/billingComponent';

const Billing = (props) => {
    return (
        <>
            <MainLayout title='POS' showOutletSelection={true}>
                {/* <BillingComponent /> */}
            </MainLayout>
        </>
    );
};

export default Billing;
