import API from './axios/API';

export const applyDiscount = ({ outletId, data, value }) => {
    return async (dispatch, getState) => {
        let response;
        let { language, billing } = getState();

        let orderId;

        let tableId = billing.tableSelectedList[0];

        if (billing.mode === 'dine_in') {
            orderId = billing.tableData.orders.length > 0 && billing.tableData.orders[0].order_id;
        } else {
            orderId = billing.tableData.order_id;
        }

        try {
            response = await API.put(
                `${language.language}/pos/restaurants/:restaurantId/outlets/${outletId}/orders/${orderId}/apply-discount`,
                {
                    type: value,
                    value: data.discount_value,
                    discount_reason: data.discount,
                    customer: { ...billing.customerInformation },
                }
            );
        } catch (error) {
            response = error;
        }

        return response;
    };
};
