import { EuiBottomBar, EuiButtonIcon, EuiFlexItem, EuiPortal, EuiText } from '@elastic/eui';
import { isEmpty } from 'lodash';
import React from 'react';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const PetPoojaSyncePortalNote = () => {
    const dispatch = useDispatch();
    const tabSwitched = useSelector((state) => state.outlet.tabSwitched);
    const petPoojaSynced = useSelector((state) => state.user.petPoojaSynced);

    const closePetPoojaSyncPortal = useCallback(() => {
        dispatch({
            type: 'set-pet-pooja-synced',
            payload: {
                petPoojaSynced: { synced: true, message: '' },
            },
        });
    }, [dispatch]);

    return (
        <>
            {!isEmpty(petPoojaSynced) && !petPoojaSynced.synced ? (
                <EuiPortal>
                    <EuiBottomBar style={{ backgroundColor: tabSwitched ? 'red' : '', padding: '20px' }}>
                        <EuiFlexItem style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <EuiFlexItem>
                                <EuiButtonIcon
                                    onClick={closePetPoojaSyncPortal}
                                    color='#ffffff'
                                    iconType='crossInACircleFilled'
                                />
                            </EuiFlexItem>
                            <EuiFlexItem style={{ marginLeft: '10px' }}>
                                <EuiText>{petPoojaSynced.message}</EuiText>
                            </EuiFlexItem>
                        </EuiFlexItem>
                    </EuiBottomBar>
                </EuiPortal>
            ) : null}
        </>
    );
};

export default React.memo(PetPoojaSyncePortalNote);
