import React from 'react';
import { useSelector } from 'react-redux';
import { EuiFlexItem, EuiText, EuiSpacer } from '@elastic/eui';
import AddonCheckableCard from './addonCheckableCard';

const BillingAddon = (props) => {
    const {
        addon,
        selectedAddOnsByGroup,
        formateNewAddonChoice,
        setSelectedAddOnsByGroup,
        setTotalAmount,
        calculateAddonAmount,
        handleAddonGroupError,
        maximumAddonGroupErrors,
        minimumAddonGroupErrors,
        selectedVariant,
        setSelectedVariant,
        selectedVariantIndex,
        horizontalRuler,
    } = props;

    const languageId = useSelector((state) => state.language.languageId);

    const AddonGroupStyle = {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
    };

    const AddonCardStyle = {
        display: 'flex',
        flexDirection: 'column',
    };

    let isSingleSelectable = addon.min_addons == 1 && addon.max_addons == 1;

    const renderTopTag = () => {
        let hasSameMaxMinChoices = addon.min_addons == addon.max_addons && addon.min_addons && addon.max_addons;

        if (isSingleSelectable) {
            return null;
        }

        return (
            <EuiText>
                <i>
                    {' '}
                    {!addon.min_addons ? ' (Optional)' : null}
                    {hasSameMaxMinChoices ? `(select ${addon.min_addons}) ` : null}
                    {addon.min_addons >= 1 && !hasSameMaxMinChoices ? `(select at least ${addon.min_addons}) ` : null}
                    {addon.max_addons > 0 && !hasSameMaxMinChoices ? `(maximum ${addon.max_addons})` : null}
                </i>
            </EuiText>
        );
    };

    const addonChoiceError = () => {
        let minimumError = minimumAddonGroupErrors[addon.addon_id];

        if (minimumError) {
            return <p style={{ fontSize: '12px', color: 'red' }}>{minimumError}</p>;
        }

        let maximumError = maximumAddonGroupErrors[addon.addon_id];

        if (maximumError) {
            return <p style={{ fontSize: '12px', color: 'red' }}>{maximumError}</p>;
        }
    };

    return (
        <>
            <div style={AddonGroupStyle}>
                <EuiFlexItem style={{ flexDirection: 'column' }}>
                    <EuiFlexItem style={{ flexDirection: 'row', padding: '10px 20px 0px' }}>
                        <EuiText>
                            <h4>{addon.group_name}&nbsp;</h4>
                        </EuiText>
                        <h4>{renderTopTag()}</h4>
                    </EuiFlexItem>
                    {addon.description ? (
                        <p style={{ fontSize: '12px', padding: '5px 20px' }}>Addon description goes here....</p>
                    ) : (
                        ''
                    )}
                    <div style={{ paddingLeft: '20px' }}>{addonChoiceError()}</div>
                    <EuiSpacer size='s' />
                </EuiFlexItem>
                <div style={AddonCardStyle}>
                    {addon.choices.map((choice) => (
                        <AddonCheckableCard
                            selectedVariant={selectedVariant}
                            setSelectedVariant={setSelectedVariant}
                            selectedVariantIndex={selectedVariantIndex}
                            choice={choice}
                            selectedAddOnsByGroup={selectedAddOnsByGroup}
                            addon={addon}
                            formateNewAddonChoice={formateNewAddonChoice}
                            setSelectedAddOnsByGroup={setSelectedAddOnsByGroup}
                            setTotalAmount={setTotalAmount}
                            calculateAddonAmount={calculateAddonAmount}
                            handleAddonGroupError={handleAddonGroupError}
                        />
                    ))}
                </div>
                {horizontalRuler ? <hr /> : ''}
            </div>
        </>
    );
};

export default BillingAddon;
