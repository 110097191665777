import API from '../axios/API';

export const createCustomer = (data) => {
    return async () => {
        let response;

        try {
            response = await API.post(`en/pos/restaurants/:restaurantID/customers`, data);
        } catch (error) {
            response = error;
        }

        return response;
    };
};
