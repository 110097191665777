import React, { useState } from 'react';
import { EuiDraggable, EuiPanel, EuiFlexGroup, EuiFlexItem, EuiIcon, EuiText, EuiButtonIcon } from '@elastic/eui';
import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import { userHasPermission } from '../../../helper/auth';
import OutletSubCategoryList from './outletSubCategoryList';
import { useCallback } from 'react';
import { fetchOutletCategoryItem } from '../catalogue/Api/FetchCatalogueInfo';

const OutletCategoryComponent = (props) => {
    const {
        item,
        selectedCategory,
        setSelectedCategory,
        setSelectedCategoriesItems,
        handleUnlink,
        outletBannerList,
        setOutletBannerList,
        index,
    } = props;
    const permissions = useSelector((state) => state.permission.permission);
    const [isSubCategoryOpen, setIsSubCategoryOpen] = useState(false);
    const outletId = useSelector((state) => state.outlet.selectedOutletId);
    const languageId = useSelector((state) => state.language.languageId);

    const handleCategorySelection = useCallback(
        (item) => {
            setSelectedCategory(item);
            fetchOutletCategoryItem(item.category_id, languageId, outletId).then((response) => {
                if (response?.items?.length) {
                    setSelectedCategoriesItems(response.items);
                } else {
                    setSelectedCategoriesItems([]);
                }
            });
        },
        [languageId, outletId, setSelectedCategoriesItems, setSelectedCategory]
    );

    return (
        <>
            <EuiDraggable
                spacing='m'
                key={item?.category_id?.toString()}
                index={index}
                draggableId={item?.category_id?.toString()}
                customDragHandle={true}
            >
                {(provided) => (
                    <EuiPanel
                        style={{
                            backgroundColor: !isEmpty(selectedCategory)
                                ? item?.category_id == selectedCategory?.category_id
                                    ? '#c4d1fe'
                                    : 'transparent'
                                : 'transparent',
                        }}
                        className='custom'
                        paddingSize='m'
                    >
                        <EuiFlexGroup>
                            <EuiFlexItem
                                grow={false}
                                style={{
                                    justifyContent: 'center',
                                }}
                            >
                                {userHasPermission(permissions, '/menu', 'write') && (
                                    <div {...provided.dragHandleProps}>
                                        <EuiIcon type='grab' />
                                    </div>
                                )}
                            </EuiFlexItem>
                            <EuiFlexItem
                                onClick={() => handleCategorySelection(item)}
                                style={{
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    cursor: 'pointer',
                                }}
                            >
                                <EuiText> {item.internal_name} </EuiText>
                            </EuiFlexItem>

                            {item?.sub_categories?.length ? (
                                <div
                                    onClick={() => setIsSubCategoryOpen(!isSubCategoryOpen)}
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        cursor: 'pointer',
                                    }}
                                >
                                    <EuiIcon type={isSubCategoryOpen ? 'arrowDown' : 'arrowUp'} />
                                </div>
                            ) : null}

                            <EuiFlexItem grow={false}>
                                {userHasPermission(permissions, '/menu', 'delete') && (
                                    <EuiButtonIcon
                                        iconType='cross'
                                        size='s'
                                        color='danger'
                                        onClick={() => handleUnlink(item.category_id)}
                                    />
                                )}
                            </EuiFlexItem>
                        </EuiFlexGroup>
                    </EuiPanel>
                )}
            </EuiDraggable>
            {item?.sub_categories?.length && isSubCategoryOpen ? (
                <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '20px', marginRight: '4px' }}>
                    <OutletSubCategoryList
                        setSelectedCategoriesItems={setSelectedCategoriesItems}
                        setSelectedCategory={setSelectedCategory}
                        selectedCategory={selectedCategory}
                        item={item}
                        outletBannerList={outletBannerList}
                        setOutletBannerList={setOutletBannerList}
                        outletId={outletId}
                        handleCategorySelection={handleCategorySelection}
                    />
                </div>
            ) : (
                ''
            )}
        </>
    );
};

export default React.memo(OutletCategoryComponent);
