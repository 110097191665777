import React, { memo } from 'react';
import { EuiDraggable, EuiFlexGroup, EuiFlexItem, EuiText, EuiIcon, EuiPanel, EuiButtonIcon } from '@elastic/eui';
import { useSelector } from 'react-redux';
import { userHasPermission } from '../../../helper/auth';

const AddOnChoiceList = (props) => {
    const languageId = useSelector((state) => state.language.languageId);
    const permissions = useSelector((state) => state.permission.permission);

    const onDelete = async (id) => {
        props.setChoiceDeleteId(id);
        props.setChoiceDeleteConfirmationModal(true);
    };
    const renderList = () => {
        if (props.addOnList.restaurant_addon_groups.length < 1) {
            return <EuiText>There are no choices in this group.</EuiText>;
        }

        let choiceArray = [];
        props.addOnList.restaurant_addon_groups.map((addon) => {
            if (addon.addon_group_id === props.selectedGroup) {
                addon.choices.map((choice, index) => {
                    choiceArray.push(
                        <EuiDraggable
                            spacing='s'
                            key={choice.choice_id.toString()}
                            index={index}
                            draggableId={choice.choice_id.toString()}
                            customDragHandle={true}
                        >
                            {(provided) => (
                                <EuiPanel className='custom scrollbar' paddingSize='m'>
                                    <EuiFlexGroup>
                                        <EuiFlexItem grow={false} style={{ justifyContent: 'center' }}>
                                            <div {...provided.dragHandleProps}>
                                                <EuiIcon type='grab' />
                                            </div>
                                        </EuiFlexItem>
                                        <EuiFlexItem
                                            style={{
                                                justifyContent: 'space-between',
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <EuiFlexItem
                                                grow={9}
                                                style={{
                                                    flexDirection: 'row',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <EuiText>{choice.translations.name[languageId.toString()]}</EuiText>
                                            </EuiFlexItem>
                                            <EuiFlexItem grow={1}>
                                                {userHasPermission(permissions, '/menu', 'delete') && (
                                                    <EuiButtonIcon
                                                        color='danger'
                                                        style={{ background: 'transparent' }}
                                                        onClick={() => onDelete(choice.choice_id)}
                                                        iconType='trash'
                                                        aria-label='Next'
                                                    />
                                                )}

                                                {/* <EuiButtonEmpty style={{background: 'transparent'}} onClick={() => onDelete(choice.choice_id)}>
                                                    delete
                                                </EuiButtonEmpty> */}
                                            </EuiFlexItem>
                                        </EuiFlexItem>
                                    </EuiFlexGroup>
                                </EuiPanel>
                            )}
                        </EuiDraggable>
                    );
                    return null;
                });
            }
        });

        return choiceArray;
    };

    const displayMessage = () => {
        return (
            <EuiPanel hasBorder>
                <EuiText>Please select a group.</EuiText>
            </EuiPanel>
        );
    };

    return <>{props.addOnList && props.selectedGroup ? renderList() : displayMessage()}</>;
};
export default memo(AddOnChoiceList);
