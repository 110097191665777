import {
    EuiSpacer,
    EuiText,
    EuiFlexItem,
    EuiKeyPadMenuItem,
    htmlIdGenerator,
    EuiFlexGroup,
    EuiDragDropContext,
    EuiDroppable,
    EuiDraggable,
    euiDragDropReorder,
    EuiIcon,
    EuiButtonEmpty,
} from '@elastic/eui';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import APIV3 from '../../api/axios/APIV3';
import { SET_TABLE_ORDER_DETAILS } from '../../reduxStore/types/tableManagement';
import { fetchTableOrderDetails } from './Api/tableOperations';
import OrdersFlyout from './OrdersFlyout';
import TableCreationFlyout from './TableCreationFlyout';

const TableInfo = ({ tables, isEditTableMode }) => {
    const dispatch = useDispatch();
    const [showFlyout, setShowFlyout] = useState(false);
    const [selectedTable, setSelectedTable] = useState({});
    const [orderFlyout, setOrderFlyout] = useState(false);
    const [list, setList] = useState([]);
    const outletId = useSelector((state) => state.outlet.selectedOutletId);

    const handleFlyoutState = useCallback((table) => {
        setShowFlyout((prevState) => !prevState);
        setSelectedTable(table);
    }, []);

    useEffect(() => {
        if (tables?.length) {
            setList(tables);
        }
    }, [tables]);

    const handleOrderFlyoutState = useCallback(
        (table) => {
            setOrderFlyout((prevState) => !prevState);
            setSelectedTable(table);
            if (!orderFlyout) {
                dispatch(fetchTableOrderDetails(table?.id));
            } else {
                dispatch({
                    type: SET_TABLE_ORDER_DETAILS,
                    payload: {},
                });
            }
        },
        [dispatch, orderFlyout]
    );

    const onDragEnd = useCallback(
        async ({ source, destination }) => {
            if (source && destination) {
                const items = euiDragDropReorder(list, source.index, destination.index);
                for (const [index, item] of items.entries()) {
                    item.display_rank = index + 1;
                }
                setList(items);

                if (items?.length) {
                    await APIV3.patch(`/restaurants/:restaurantId/outlets/${outletId}/outlet-tables/change-sequence`, {
                        tables: items,
                    });
                }
            }
        },
        [list, outletId]
    );

    return (
        <>
            <EuiSpacer />
            <EuiDragDropContext onDragEnd={onDragEnd}>
                <EuiDroppable
                    droppableId='DROPPABLE_AREA'
                    isDropDisabled={!isEditTableMode}
                    direction='horizontal'
                    style={{ padding: `${isEditTableMode ? '20px' : '0'} ` }}
                    spacing={`${isEditTableMode ? 'l' : 'none'} `}
                    withPanel={isEditTableMode}
                >
                    <EuiFlexGroup
                        gutterSize='s'
                        wrap
                        responsive
                        direction='row'
                        justifyContent='flexStart'
                        alignItems='center'
                    >
                        {list?.map((table, idx) => {
                            let net_total = 0;
                            if (table?.current_session?.orders?.length) {
                                table.current_session.orders.filter(
                                    (order) => (net_total = parseInt(order.net_total) + net_total)
                                );
                            }

                            let tableStatusColor = table?.current_session?.kot_running_order_counts
                                ? '#E94616'
                                : table?.current_session?.running_order_counts > 0 &&
                                  table?.current_session?.kot_running_order_counts === 0
                                ? '#7de2b5'
                                : '#FFFFFF';

                            return (
                                <EuiDraggable
                                    spacing='m'
                                    key={table.id.toString()}
                                    index={idx}
                                    draggableId={table.id.toString()}
                                    customDragHandle={true}
                                    hasInteractiveChildren={true}
                                >
                                    {(provided) => (
                                        <EuiFlexItem
                                            {...(isEditTableMode && { ...provided.dragHandleProps })}
                                            {...provided.dragHandleProps}
                                            grow={false}
                                            style={{ minWidth: 100 }}
                                            key={idx}
                                        >
                                            <EuiKeyPadMenuItem
                                                style={{ border: '1px solid grey', background: tableStatusColor }}
                                                onClick={() =>
                                                    isEditTableMode
                                                        ? handleFlyoutState(table)
                                                        : table?.current_session
                                                        ? handleOrderFlyoutState(table)
                                                        : null
                                                }
                                                id={htmlIdGenerator()()}
                                            >
                                                <EuiFlexGroup
                                                    direction='column'
                                                    gutterSize='xs'
                                                    justifyContent='center'
                                                    alignItems='center'
                                                >
                                                    {isEditTableMode ? (
                                                        <EuiFlexItem>
                                                            <EuiButtonEmpty
                                                                size='s'
                                                                color='success'
                                                                iconType={'pencil'}
                                                                iconSize='s'
                                                            >
                                                                <EuiText size='xs'>Edit</EuiText>
                                                            </EuiButtonEmpty>
                                                        </EuiFlexItem>
                                                    ) : null}
                                                    {isEditTableMode ? (
                                                        <EuiFlexItem>
                                                            <EuiIcon type='grab' />{' '}
                                                        </EuiFlexItem>
                                                    ) : null}
                                                    <EuiFlexItem>
                                                        <EuiText style={{ wordBreak: 'break-all' }}>
                                                            {table.internal_name}
                                                        </EuiText>
                                                    </EuiFlexItem>
                                                    <EuiFlexItem>
                                                        <EuiText size='xs'>
                                                            {net_total ? '₹' + net_total : null}
                                                        </EuiText>
                                                    </EuiFlexItem>
                                                </EuiFlexGroup>
                                            </EuiKeyPadMenuItem>
                                        </EuiFlexItem>
                                    )}
                                </EuiDraggable>
                            );
                        })}
                    </EuiFlexGroup>
                </EuiDroppable>
            </EuiDragDropContext>

            {showFlyout ? (
                <TableCreationFlyout
                    closeFlyout={handleFlyoutState}
                    selectedTable={selectedTable}
                    tableState='update'
                />
            ) : null}
            {orderFlyout ? <OrdersFlyout selectedTable={selectedTable} closeFlyout={handleOrderFlyoutState} /> : null}
        </>
    );
};

export default React.memo(TableInfo);
