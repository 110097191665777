import React, { useEffect, useState } from 'react';
import { EuiFlexGroup, EuiFlexItem, EuiButton, EuiLoadingContent } from '@elastic/eui';
import API from '../../api/axios/API';
import { isEmpty } from 'lodash';
import { useDispatch } from 'react-redux';
import { getLiveOrders } from '../../api/liveOrders/getLiveOrderNewAPI';
import { getLiveOrderDetailsAPI } from '../../api/liveOrders/getLiveOrderDetailsAPI';

export const ShadowFaxRiderTab = (props) => {
    const { order } = props;
    const [priceEstimate, setPriceEstimate] = useState({});
    const dispatch = useDispatch();

    const tdStyle = {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
    };

    const shadowFaxCostEstimate = async () => {
        let shdowFaxResponse;
        try {
            shdowFaxResponse = await API.get(
                `/restaurants/:restaurantId/shadowfax/get-order-estimation-time?orderId=${props.orderId}`
            );
        } catch (error) {
            shdowFaxResponse = error;
        }

        if (shdowFaxResponse.success) {
            setPriceEstimate(shdowFaxResponse);
        } else {
            alert(shdowFaxResponse.message);
        }
    };

    useEffect(() => {
        shadowFaxCostEstimate();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const assignShadowFaxRider = async () => {
        let shadowFaxResponse = '';
        props.setRiderModalOpen();
        try {
            shadowFaxResponse = await API.post(`/restaurants/:restaurantId/shadowfax/assign-order `, {
                orderId: props.orderId,
            });
        } catch (error) {
            shadowFaxResponse = error;
        }

        if (shadowFaxResponse.success) {
            // props.stateUpdateData((prevState) => !prevState)
            dispatch(getLiveOrders());
            dispatch(getLiveOrderDetailsAPI());
        } else {
            alert(shadowFaxResponse.message);
        }
    };

    return (
        <EuiFlexGroup direction='column'>
            <EuiFlexItem>
                {!isEmpty(priceEstimate) ? (
                    <>
                        {priceEstimate?.shadowfax_order_estimation?.serviceable ? (
                            <EuiFlexItem>
                                <b>ShadowFax Estimates</b>
                                <table style={{ marginTop: '10px' }}>
                                    <tr style={{ borderBottom: '1px solid #D3DAE6' }}>
                                        <td style={{ borderRight: '1px solid #D3DAE6' }}>Pickup Estimation</td>
                                        <td style={tdStyle}>
                                            {priceEstimate.shadowfax_order_estimation.pickup_eta
                                                ? `${priceEstimate.shadowfax_order_estimation.pickup_eta} Mins`
                                                : ''}
                                        </td>
                                    </tr>
                                    <tr style={{ borderBottom: '1px solid #D3DAE6' }}>
                                        <td style={{ borderRight: '1px solid #D3DAE6' }}>Drop Estimation</td>
                                        <td style={tdStyle}>
                                            {priceEstimate.shadowfax_order_estimation.drop_eta
                                                ? `${priceEstimate.shadowfax_order_estimation.drop_eta} Mins`
                                                : ''}
                                        </td>
                                    </tr>

                                    <tr style={{ borderBottom: '1px solid #D3DAE6' }}>
                                        <td style={{ borderRight: '1px solid #D3DAE6' }}>Estimated Delivery Cost</td>

                                        <td style={tdStyle}>
                                            {priceEstimate.shadowfax_order_estimation.delivery_cost
                                                ? `₹${parseFloat(
                                                      priceEstimate.shadowfax_order_estimation.delivery_cost
                                                  ).toFixed(2)}`
                                                : ''}
                                        </td>
                                    </tr>
                                </table>
                            </EuiFlexItem>
                        ) : (
                            <EuiFlexItem>
                                <b>ShadowFax Estimates</b>
                                <table style={{ marginTop: '10px' }}>
                                    <tr style={{ borderBottom: '1px solid #D3DAE6' }}>
                                        <td style={{ borderRight: '1px solid #D3DAE6' }}>Reason</td>
                                        <td style={tdStyle}>
                                            {priceEstimate.shadowfax_order_estimation.reason
                                                ? priceEstimate.shadowfax_order_estimation.reason
                                                : ''}
                                        </td>
                                    </tr>

                                    <tr style={{ borderBottom: '1px solid #D3DAE6' }}>
                                        <td style={{ borderRight: '1px solid #D3DAE6' }}>Estimated Delivery Cost</td>

                                        <td style={tdStyle}>
                                            {priceEstimate.shadowfax_order_estimation.delivery_cost
                                                ? `₹${parseFloat(
                                                      priceEstimate.shadowfax_order_estimation.delivery_cost
                                                  ).toFixed(2)}`
                                                : ''}
                                        </td>
                                    </tr>
                                </table>
                            </EuiFlexItem>
                        )}
                    </>
                ) : (
                    <EuiLoadingContent lines={5} />
                )}
            </EuiFlexItem>
            <EuiFlexItem style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <EuiButton
                    disabled={!priceEstimate?.shadowfax_order_estimation?.serviceable}
                    fullWidth={true}
                    fill
                    onClick={assignShadowFaxRider}
                >
                    Assign Shadow Fax Rider
                </EuiButton>
            </EuiFlexItem>
        </EuiFlexGroup>
    );
};
