import React, { useEffect } from 'react';
import {
    EuiPopover,
    EuiForm,
    EuiFlexGroup,
    EuiFlexItem,
    EuiFormRow,
    EuiButton,
    EuiFieldNumber,
    htmlIdGenerator,
} from '@elastic/eui';
import TimePicker from 'react-time-picker';
import { Controller, useForm } from 'react-hook-form';
import NumberField from './NumberField';

const KitchenTimePopover = (props) => {
    const { append, setIsPopoverOpen, isPopoverOpen, appendValues } = props;
    const {
        handleSubmit,
        control,
        watch,
        reset,
        setValue,
        trigger,

        formState: { errors },
    } = useForm();

    const onSubmit = (data, e) => {};

    return (
        <>
            <EuiPopover
                button={props.button}
                isOpen={isPopoverOpen}
                closePopover={() => setIsPopoverOpen(false)}
                anchorPosition='upCenter'
            >
                <EuiForm component='form' onSubmit={handleSubmit(onSubmit)}>
                    <EuiFlexGroup alignItems='center' style={{ margin: '5px' }}>
                        <EuiFlexItem grow={8}>
                            <EuiFlexGroup>
                                <EuiFlexItem>
                                    <EuiFormRow>
                                        <Controller
                                            render={({ field }) => (
                                                <TimePicker
                                                    value={field.value}
                                                    // onChangE={onChange}
                                                    onChange={(time) => {
                                                        setValue('start_time', time);
                                                    }}
                                                    format='hh:mm a'
                                                    amPmAriaLabel='Select AM/PM'
                                                    closeClock={true}
                                                    disableClock={true}
                                                    required={true}
                                                />
                                            )}
                                            name={'start_time'}
                                            control={control}
                                            fullWidth={true}
                                            rules={{ required: 'Please enter proper date' }}
                                            errors={errors}
                                        />
                                    </EuiFormRow>
                                </EuiFlexItem>
                                <EuiFlexItem>
                                    <EuiFormRow>
                                        <Controller
                                            render={({ field }) => (
                                                <TimePicker
                                                    value={field.value}
                                                    onChange={(time) => {
                                                        setValue('end_time', time);
                                                    }}
                                                    format='hh:mm a'
                                                    amPmAriaLabel='Select AM/PM'
                                                    closeClock={true}
                                                    disableClock={true}
                                                    required={true}
                                                />
                                            )}
                                            name={'end_time'}
                                            control={control}
                                            fullWidth={true}
                                            rules={{ required: 'Please enter proper date' }}
                                            errors={errors}
                                        />
                                    </EuiFormRow>
                                </EuiFlexItem>
                                <EuiFlexItem>
                                    <NumberField
                                        label=''
                                        isInvalid={errors.preparation_time_in_rush}
                                        errors={errors}
                                        name='preparation_time_in_rush'
                                        placeholder='Preparation time rush hours'
                                        rules={{
                                            required: 'Please enter preparation time in rush hours',
                                            // validate: (value) => value > 0 || 'Please enter value greater than 0',
                                        }}
                                        step='any'
                                        min={1}
                                        control={control}
                                        // prepend={"Mins"}
                                    />
                                </EuiFlexItem>
                            </EuiFlexGroup>
                        </EuiFlexItem>
                        <EuiFlexItem grow={1}>
                            <EuiFlexGroup alignItems={'center'}>
                                <EuiFlexItem>
                                    <EuiButton
                                        type='submit'
                                        onClick={() => {
                                            if (
                                                watch('start_time') &&
                                                watch('end_time') &&
                                                watch('preparation_time_in_rush')
                                            ) {
                                                append({
                                                    start_time: watch('start_time'),
                                                    end_time: watch('end_time'),
                                                    preparation_time_in_rush: watch('preparation_time_in_rush'),
                                                });
                                                setIsPopoverOpen(false);
                                            }
                                        }}
                                    >
                                        save
                                    </EuiButton>
                                </EuiFlexItem>
                            </EuiFlexGroup>
                        </EuiFlexItem>
                    </EuiFlexGroup>
                </EuiForm>
            </EuiPopover>
        </>
    );
};

export default KitchenTimePopover;
