import React from 'react';

export default class ReportPrint extends React.Component {
    render() {
        const reportsData = this.props.reportsData;

        return (
            <>
                <table
                    style={{
                        borderCollapse: 'collapse',
                        width: '99%',
                        alignContent: 'center',
                        margin: '0 auto',
                    }}
                >
                    <tbody style={{ width: '100%' }}>
                        {reportsData.map((data, index) => (
                            <React.Fragment key={index}>
                                {!data['column-1'] && !data['column-2'] && !data['column-3'] ? null : (
                                    <tr key={index}>
                                        <td
                                            align='left'
                                            style={
                                                index === 0
                                                    ? { fontSize: 16, fontWeight: 700 }
                                                    : { padding: '4px 0', fontSize: 14 }
                                            }
                                        >
                                            {data['column-1']}
                                        </td>
                                        <td align='right' style={{ fontSize: 14 }}>
                                            {data['column-2']}
                                        </td>
                                        <td align='right' style={{ paddingLeft: '26px', fontSize: 14 }}>
                                            {data['column-3']}
                                        </td>
                                    </tr>
                                )}
                            </React.Fragment>
                        ))}
                    </tbody>
                </table>
            </>
        );
    }
}
