import {
    EuiEmptyPrompt,
    EuiFlexGroup,
    EuiFlexItem,
    EuiGlobalToastList,
    EuiLoadingContent,
    EuiSpacer,
    htmlIdGenerator,
} from '@elastic/eui';
import { isEmpty } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import API from '../../../api/axios/API';
import { petPoojaSyncValidate } from '../../../api/menu/petPoojaSyncValidate';
import { fetchOrderingModes } from '../../../api/outlet/fetchOrderingModes';
import { userHasPermission } from '../../../helper/auth';
import { getFlatToTreeStructureNew } from '../../../helper/getFlatToTreeStructureNew';
import {
    GET_PET_POOJA_ITEMS,
    GET_PET_POOJA_LINKED_ITEMS,
    GET_PET_POOJA_RESTAURANT,
    GET_PET_POOJA_TAXES,
    GET_SYSTEM_CATEGORIES,
} from '../../../reduxStore/types/PetPoojaItemTypes';
import UnlinkedItems from '../../../components/PetPoojaMenuLink/UnlinkedItems';
import { linkItemToPetPooja } from '../../../components/PetPoojaLink/Api/linkSelectedToPetPooja';
import Category from '../../../components/PetPoojaLink/Category';
import PetPoojaSynceButton from '../../../components/PetPoojaLink/PetPoojaSynceButton';
import { itemLinkingResetValue } from '../../../components/PetPoojaLink/utils/itemLinkingResetValue';
import { itemOnSubmitStructureFormate } from '../../../components/PetPoojaLink/utils/itemOnSubmitStructureFormate';

const PetPoojaItemLink = () => {
    const dispatch = useDispatch();
    const outletId = useSelector((state) => state.outlet.selectedOutletId);
    const outletList = useSelector((state) => state.outlet.outletList);
    const permissions = useSelector((state) => state.permission.permission);
    const [isPetpoojaLinkEnable, setPetpoojaLinkEnable] = useState(true);
    const [loading, setLoading] = useState(false);
    const [petPoojaSyncLoader, setPetPoojaSyncLoader] = useState(false);
    const [categories, setCategories] = useState([]);
    const [totalCategoryItems, setTotalCategoryItems] = useState([]);
    const petPoojaItems = useSelector((state) => state.petPoojaReducer.petPoojaItems);
    const loadingLines = new Array(3).fill([]);
    const [toasts, setToasts] = useState([]);
    const linkedItems = useSelector((state) => state.petPoojaReducer.petPoojaLinkedItems);
    const petPoojaTaxes = useSelector((state) => state.petPoojaReducer.petPoojaTaxes);
    const [validationFailedItems, setValidationFailedItem] = useState({
        items: {},
        category: {},
        variants: {},
    });

    const methods = useForm({
        mode: 'all',
    });

    console.log('methods', methods.formState.errors);

    const clearValidationFormError = useCallback(() => {
        setValidationFailedItem({ items: {}, category: {}, variants: {} });
    }, []);

    const fetchRecords = useCallback(async () => {
        setLoading(true);
        dispatch(fetchOrderingModes());
        const responses = await Promise.all([
            API.get(`restaurants/:restaurantId/outlets/${outletId}/categories/list`),
            API.get(`restaurants/:restaurantId/outlets/${outletId}/pet-pooja/menu-fetch`),
            API.get(`restaurants/:restaurantId/outlets/${outletId}/pet-pooja/menu-links`),
        ]);
        if (responses?.length) {
            if (responses[0].success) {
                dispatch({
                    type: GET_SYSTEM_CATEGORIES,
                    payload: responses[0].category,
                });
                setCategories(getFlatToTreeStructureNew(responses[0].category));
            }
            if (responses[1].success) {
                dispatch({
                    type: GET_PET_POOJA_ITEMS,
                    payload: responses[1].pet_pooja_menu.items,
                });
                dispatch({
                    type: GET_PET_POOJA_TAXES,
                    payload: responses[1].pet_pooja_menu.taxes,
                });
                dispatch({
                    type: GET_PET_POOJA_RESTAURANT,
                    payload: responses[1].pet_pooja_menu.restaurants,
                });
            }
            if (responses[2].success) {
                dispatch({
                    type: GET_PET_POOJA_LINKED_ITEMS,
                    payload: responses[2].order_response,
                });
            }
            setLoading(false);
        }
    }, [dispatch, outletId]);

    useEffect(() => {
        if (outletId) {
            let outlet = outletList.find((outlet) => parseInt(outlet.value) === parseInt(outletId));

            if (!outlet?.outlet_data?.outlet_third_party_menu_setting?.is_pet_pooja_integration_enabled) {
                setPetpoojaLinkEnable(false);
                return;
            }

            setPetpoojaLinkEnable(true);
        }
        fetchRecords();

        return function cleanup() {
            setTotalCategoryItems([]);
            clearValidationFormError();
        };
    }, [outletId, fetchRecords, outletList, clearValidationFormError]);

    const validateSync = useCallback(() => {
        setPetPoojaSyncLoader(true);
        dispatch(petPoojaSyncValidate()).then((response) => {
            if (response.success) {
                clearValidationFormError();
                setToasts([
                    ...toasts,
                    {
                        title: 'This outlet Fully Synced',
                        id: htmlIdGenerator()(),
                        color: 'success',
                    },
                ]);
            } else {
                setValidationFailedItem({
                    ...validationFailedItems,
                    items: response?.unlinked_items,
                    variants: response?.unLinked_item_variant_option,
                });
                setToasts([
                    ...toasts,
                    {
                        title: 'This outlet is not Fully Synced',
                        id: htmlIdGenerator()(),
                        color: 'danger',
                    },
                ]);
            }
            setPetPoojaSyncLoader(false);
        });
    }, [clearValidationFormError, dispatch, toasts, validationFailedItems]);

    const fetchUpdatedLinkedItems = useCallback(() => {
        return new Promise(async (resolve, reject) => {
            let response = await API.get(`restaurants/:restaurantId/outlets/${outletId}/pet-pooja/menu-links`);
            if (response?.order_response?.length >= 0) {
                dispatch({
                    type: GET_PET_POOJA_LINKED_ITEMS,
                    payload: response.order_response,
                });
                resolve(response.order_response);
            } else {
                reject();
            }
        });
    }, [dispatch, outletId]);

    const updatedLinkedItems = useCallback(
        async (categories, totalCategoryItems) => {
            setLoading(true);
            methods.reset({});
            const order_response = await fetchUpdatedLinkedItems();

            if (order_response?.length >= 0) {
                let defaultValue = {};
                if (categories?.length) {
                    for (const category of categories) {
                        itemLinkingResetValue(
                            defaultValue,
                            category,
                            totalCategoryItems,
                            order_response,
                            petPoojaItems,
                            petPoojaTaxes
                        );
                        if (category?.sub_categories?.length) {
                            for (const subCategory of category?.sub_categories) {
                                itemLinkingResetValue(
                                    defaultValue,
                                    subCategory,
                                    totalCategoryItems,
                                    order_response,
                                    petPoojaItems,
                                    petPoojaTaxes
                                );
                            }
                        }
                    }
                }
                if (!isEmpty(defaultValue)) {
                    methods.reset({ ...defaultValue });
                }
                setLoading(false);
            } else {
                setLoading(false);
            }
        },
        [fetchUpdatedLinkedItems, methods, petPoojaItems, petPoojaTaxes]
    );

    const handleReset = useCallback(() => {
        let defaultValue = {};
        if (categories?.length) {
            for (const category of categories) {
                itemLinkingResetValue(
                    defaultValue,
                    category,
                    totalCategoryItems,
                    linkedItems,
                    petPoojaItems,
                    petPoojaTaxes
                );
                if (category?.sub_categories?.length) {
                    for (const subCategory of category?.sub_categories) {
                        itemLinkingResetValue(
                            defaultValue,
                            subCategory,
                            totalCategoryItems,
                            linkedItems,
                            petPoojaItems,
                            petPoojaTaxes
                        );
                    }
                }
            }
        }
        methods.reset({ ...defaultValue });
    }, [linkedItems, methods, petPoojaItems, petPoojaTaxes, totalCategoryItems]);

    // todo fix hack categories dependency
    // every time user toggle category, the handleReset function is being called and the data
    // reset again. ALL ITEM DATA
    // this results in loos of previous selected item in previous categories
    // by removing the categories dependency it results preserving the previous selected items in other previously
    // opned categories

    const onSubmit = useCallback(
        (data) => {
            if (!isEmpty(methods.formState.errors)) {
                return;
            }
            if (categories?.length) {
                let menu_links = [];
                for (const category of categories) {
                    itemOnSubmitStructureFormate(data, category.category_id, menu_links);
                    if (category?.sub_categories?.length) {
                        for (const subCategory of category?.sub_categories) {
                            itemOnSubmitStructureFormate(data, subCategory.category_id, menu_links);
                        }
                    }
                }

                const uniqueArr = menu_links?.reduce(
                    (acc, curr) =>
                        acc?.find(
                            (item) =>
                                item.third_party_module_id === curr.third_party_module_id &&
                                curr.module_name === item.module_name &&
                                curr.module_id === item.module_id &&
                                curr.restaurant_ordering_mode_id === item.restaurant_ordering_mode_id
                        )
                            ? acc
                            : [...acc, curr],
                    []
                );

                const removedAlreadyLinkedItems = uniqueArr?.filter((item) => !item?.linkedItem);

                data.external_menu_links = removedAlreadyLinkedItems?.length ? removedAlreadyLinkedItems : [];

                // REMOVE DUPLICATE UNLINK ITEMS
                const uniqueUnlinkedItems = data?.menu_unlinks?.reduce(
                    (acc, curr) =>
                        acc?.find(
                            (item) =>
                                item.third_party_module_id === curr.third_party_module_id &&
                                curr.module_id === item.module_id &&
                                curr.module_name === item.module_name &&
                                curr.restaurant_ordering_mode_id === item.restaurant_ordering_mode_id
                        )
                            ? acc
                            : [...acc, curr],
                    []
                );

                data.external_menu_unlink = uniqueUnlinkedItems?.length ? uniqueUnlinkedItems : [];

                linkItemToPetPooja(data, outletId).then((res) => {
                    if (!res?.error) {
                        dispatch({
                            type: 'set-portal-active',
                            payload: {
                                portalActive: {},
                            },
                        });
                        setToasts([
                            ...toasts,
                            {
                                title: 'Item Saved Successfully',
                                id: htmlIdGenerator()(),
                                color: 'success',
                            },
                        ]);
                        // fetchRecords();
                        // handleReset();
                        updatedLinkedItems(categories, totalCategoryItems);
                    }
                });
            }
        },
        [methods.formState.errors, categories, outletId, dispatch, toasts, updatedLinkedItems, totalCategoryItems]
    );

    const resetChanges = useCallback(() => {
        handleReset();
    }, [handleReset]);

    useEffect(() => {
        if (categories?.length) {
            resetChanges();
        }
    }, [categories, resetChanges]);

    useEffect(() => {
        if (!userHasPermission(permissions, '/menu', 'write')) return;

        if (methods.formState.isDirty) {
            dispatch({
                type: 'set-portal-active',
                payload: {
                    portalActive: {
                        resetChanges: resetChanges,
                        submit: methods.handleSubmit(onSubmit),
                    },
                },
            });
        } else {
            dispatch({
                type: 'set-portal-active',
                payload: {
                    portalActive: {},
                },
            });
        }
    }, [methods.formState.isDirty]);

    const removeToast = useCallback(
        (removedToast) => {
            setToasts(toasts.filter((toast) => toast.id !== removedToast.id));
        },
        [toasts]
    );

    if (!isPetpoojaLinkEnable) {
        return (
            <EuiFlexItem
                component='div'
                style={{ position: 'absolute', top: '50%', left: '50%', transform: `translate(-50%, -50%)` }}
            >
                <EuiEmptyPrompt
                    iconType='editorStrike'
                    title={<h2>PetPooja Settings</h2>}
                    body={<p>Please enable this from outlet setings - Third party POS.</p>}
                />
            </EuiFlexItem>
        );
    }

    if (loading) {
        return (
            <EuiFlexGroup direction='column'>
                {loadingLines.map((line, index) => {
                    return (
                        <EuiFlexItem key={index} grow={false}>
                            <EuiLoadingContent lines={3} />
                        </EuiFlexItem>
                    );
                })}
            </EuiFlexGroup>
        );
    }

    return (
        <FormProvider {...methods}>
            <EuiGlobalToastList toasts={toasts} dismissToast={removeToast} toastLifeTimeMs={6000} />
            <EuiFlexGroup alignItems='flexEnd' justifyContent='flexStart' direction='column'>
                <EuiFlexItem grow={false}>
                    <PetPoojaSynceButton isLoading={petPoojaSyncLoader} handleOnClick={validateSync} />
                </EuiFlexItem>
                {(!isEmpty(validationFailedItems.items) || !isEmpty(validationFailedItems.variants)) && (
                    <EuiFlexItem grow={false} style={{ width: '95%' }}>
                        <UnlinkedItems validationFailedItems={validationFailedItems} />
                    </EuiFlexItem>
                )}
                <EuiFlexItem grow={10} style={{ width: '97%' }}>
                    <EuiFlexGroup direction='column'>
                        <EuiFlexItem>
                            {categories?.map((category, index) => {
                                return (
                                    <Category
                                        category={category}
                                        key={category.category_id.toString() + index.toString()}
                                        setTotalCategoryItems={setTotalCategoryItems}
                                    />
                                );
                            })}
                        </EuiFlexItem>
                    </EuiFlexGroup>
                </EuiFlexItem>
            </EuiFlexGroup>
            <EuiSpacer />
        </FormProvider>
    );
};

export default React.memo(PetPoojaItemLink);
