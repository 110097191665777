import React, { memo, useEffect } from 'react';
import {
    EuiTable,
    EuiTableHeader,
    EuiTableBody,
    EuiTableHeaderCell,
    EuiCheckbox,
    EuiTableRowCell,
    EuiTableRow,
} from '@elastic/eui';
import API from '../../api/axios/API';

const UserAccessPermissions = (props) => {
    const permissionChangeApi = async (permissionArray) => {
        const updatePermission = await API.put(`restaurants/:restaurantId/users/permissions`, {
            permissions: permissionArray,
        });
        return updatePermission;
    };

    const renderHeaderCell = () => {
        const headers = [<EuiTableHeaderCell key='access_module'> Access Module</EuiTableHeaderCell>];
        props.modules['permissions'].map((column) => {
            headers.push(
                <EuiTableHeaderCell key={column['permission_id']}> {column['permission']}</EuiTableHeaderCell>
            );

            return null;
        });
        return headers.length ? headers : null;
    };

    const onRemovePermission = async ({ objectId, permissionId, permission, permissionList }) => {
        let permissionArray = [];
        if (permission.permission === 'read') {
            permissionList.map((value) =>
                permissionArray.push({
                    is_allowed: 0,
                    permission_object_id: objectId,
                    user_id: props.selectedUser,
                    permission_id: value.permission_id,
                })
            );
        } else if (permission.permission === 'write') {
            let permissionArrayId = permissionList.filter((value) => value.permission === 'delete');
            permissionArray.push({
                is_allowed: 0,
                permission_object_id: objectId,
                user_id: props.selectedUser,
                permission_id: permissionArrayId[0].permission_id,
            });
            permissionArray.push({
                is_allowed: 0,
                permission_object_id: objectId,
                user_id: props.selectedUser,
                permission_id: permissionId,
            });
        } else {
            permissionArray.push({
                is_allowed: 0,
                permission_object_id: objectId,
                user_id: props.selectedUser,
                permission_id: permissionId,
            });
        }
        const response = await permissionChangeApi(permissionArray);

        return response;
    };

    const onAddPermission = async ({ objectId, permissionId, permission, permissionList }) => {
        let permissionArray = [];
        if (permission.permission === 'delete') {
            permissionList.map((value) =>
                permissionArray.push({
                    is_allowed: 1,
                    permission_object_id: objectId,
                    user_id: props.selectedUser,
                    permission_id: value.permission_id,
                })
            );
        } else if (permission.permission === 'write') {
            let permissionArrayId = permissionList.filter((value) => value.permission === 'read');
            permissionArray.push({
                is_allowed: 1,
                permission_object_id: objectId,
                user_id: props.selectedUser,
                permission_id: permissionArrayId[0].permission_id,
            });
            permissionArray.push({
                is_allowed: 1,
                permission_object_id: objectId,
                user_id: props.selectedUser,
                permission_id: permissionId,
            });
        } else {
            permissionArray.push({
                is_allowed: 1,
                permission_object_id: objectId,
                user_id: props.selectedUser,
                permission_id: permissionId,
            });
        }
        const response = await permissionChangeApi(permissionArray);

        return response;
    };

    const onChangePermission = async (objectId, permissionId, permission, permissionList, itemName, e) => {
        try {
            const is_allowed = e.target.checked ? 1 : 0;
            let updatedPermission;
            if (!is_allowed) {
                updatedPermission = await onRemovePermission({ objectId, permissionId, permission, permissionList });
            } else {
                updatedPermission = await onAddPermission({ objectId, permissionId, permission, permissionList });
            }
            if (updatedPermission.success === true) {
                props.setPermissionUpdate((prevState) => !prevState);
            }
        } catch (error) {
            console.log('Mutation error - ', error);
        }
    };

    const checkBoxDisable = (id) => (
        <EuiCheckbox id={id.toString()} checked={false} disabled={true} onChange={(e) => {}} />
    );

    const permissionCheckBox = (object, item, permission, permissionList) => {
        const module = item['object_name'];
        if (
            module === 'dashboard' &&
            (permission.permission === 'write' || permission.permission.toString() === 'delete')
        ) {
            return checkBoxDisable(permission['permission_id']);
        } else if (
            module === 'customers' &&
            (permission.permission === 'write' || permission.permission.toString() === 'delete')
        ) {
            return checkBoxDisable(permission['permission_id']);
        } else if (module === 'outlet' && permission.permission.toString() === 'delete') {
            return checkBoxDisable(permission['permission_id']);
        } else if (
            module === 'order-rating' &&
            (permission.permission === 'write' || permission.permission.toString() === 'delete')
        ) {
            return checkBoxDisable(permission['permission_id']);
        } else if (module === 'item_availability' && permission.permission.toString() === 'delete') {
            return checkBoxDisable(permission['permission_id']);
        }

        return (
            <EuiCheckbox
                id={permission['permission_id'].toString()}
                checked={object[item['object_name']][permission.permission]}
                onChange={(e) =>
                    onChangePermission(
                        item.object_id,
                        permission.permission_id,
                        permission,
                        permissionList,
                        item['object_name'],
                        e
                    )
                }
            />
        );
    };
    const renderRows = () => {
        let object = {};
        props.modules['modules'].map((module) => {
            object[module['object_name']] = {};
            props.modules['permissions'].map(
                (permission) => (object[module['object_name']][permission['permission']] = false)
            );
            return null;
        });
        props.user['permissions'].map((permission) =>
            permission.permissions.map(
                (permissionItem) => (object[permission.permission_object.name][permissionItem.permission] = true)
            )
        );

        const rows = props.modules['modules'].map((item) => {
            return (
                <EuiTableRow key={item['object_id']}>
                    <EuiTableRowCell>{item['object_name']}</EuiTableRowCell>
                    {props.modules['permissions'].map((permission) => {
                        return (
                            <EuiTableRowCell key={permission.permission_id}>
                                {permissionCheckBox(object, item, permission, props.modules['permissions'])}
                            </EuiTableRowCell>
                        );
                    })}
                </EuiTableRow>
            );
        });
        return rows;
    };

    return (
        <>
            {props.user && (
                <EuiTable>
                    <EuiTableHeader>{renderHeaderCell()}</EuiTableHeader>
                    <EuiTableBody>{renderRows()}</EuiTableBody>
                </EuiTable>
            )}
        </>
    );
};

export default memo(UserAccessPermissions);
