import { EuiButton } from '@elastic/eui';
import React from 'react';

const PetPoojaSynceButton = ({ isLoading, handleOnClick }) => {
    return (
        <EuiButton fill isLoading={isLoading} onClick={handleOnClick}>
            Validate Sync
        </EuiButton>
    );
};

export default PetPoojaSynceButton;
