import React from 'react';
import { useSelector } from 'react-redux';
import { MainLayout } from '../../components/layout/mainLayout/mainLayout';
import OutletSettingsForm from '../../components/settings/OutletSettingsForm';
import { NoOutletMessage } from '../../utils/noOutletMessage';

const OutletSettingsPage = () => {
    const selectedOutletId = useSelector((state) => state.outlet.selectedOutletId);

    return (
        <MainLayout title='Edit Outlet Information' outlet={true}>
            {selectedOutletId ? <OutletSettingsForm /> : <NoOutletMessage />}
        </MainLayout>
    );
};

export default OutletSettingsPage;
