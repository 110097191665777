import React, { useState, useEffect } from 'react';
import { EuiLoadingContent } from '@elastic/eui';
import API from '../../api/axios/API';
import OutletForm from './outlet-form/outletForm';
import { GET_OUTLET_ORDERING_MODES } from '../../reduxStore/types/outlet';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useCallback } from 'react';

const OutletSettingsForm = () => {
    const [outletDetails, setOutletDetails] = useState({
        isLoading: true,
        error: null,
        outlet: undefined,
    });
    const dispatch = useDispatch();
    const outletId = useSelector((state) => state.outlet.selectedOutletId);

    const fetchOutletById = useCallback(async () => {
        let response;
        try {
            response = await API.get(
                `${process.env.REACT_APP_BASE_URL_V4}/restaurants/:restaurantId/outlets/${outletId}`
            );
        } catch (error) {
            response = error;
        }

        if (response.success) {
            dispatch({
                type: GET_OUTLET_ORDERING_MODES,
                payload: response.outlet,
            });
            response = {
                ...outletDetails,
                isLoading: false,
                outlet: response,
                error: false,
            };
        } else {
            response = {
                ...outletDetails,
                isLoading: false,
                error: true,
            };
        }
        setOutletDetails(response);
        return response;
    }, [dispatch, outletDetails, outletId]);

    useEffect(() => {
        fetchOutletById();
    }, [outletId]);

    if (outletDetails.isLoading) return <EuiLoadingContent lines={10} />;
    if (outletDetails.error) return <h1>An Error Occured.</h1>;
    return (
        <>
            <OutletForm getOutletData={outletDetails.outlet} fetchOutletById={fetchOutletById} />
        </>
    );
};

export default OutletSettingsForm;
