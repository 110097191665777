import React from 'react';
import { MainLayout } from '../../components/layout/mainLayout/mainLayout';
import BillingComponent from '../../components/billingCopy/billingComponent';
import { useSelector } from 'react-redux';
import { capitalize } from '../../helper/capitalize';

const BillingCopy = (props) => {
    const mode = useSelector((state) => state.billing.mode);
    const finishCustomerInfo = useSelector((state) => state.billing.finishCustomerInfo);

    const getHeadingName = () => {
        if (!finishCustomerInfo) {
            return 'POS';
        } else {
            switch (mode) {
                case 'dine_in':
                    return 'Dine In';
                default:
                    return capitalize(mode);
            }
        }
    };

    return (
        <>
            <MainLayout
                title={getHeadingName()}
                showOutletSelection={true}
                backButton={getHeadingName() !== 'POS' ? true : false}
                holdButton={true}
            >
                <BillingComponent />
            </MainLayout>
        </>
    );
};

export default BillingCopy;
