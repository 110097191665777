import React, { useState, useEffect, useCallback } from 'react';
import {
    EuiFlexGroup,
    EuiFlexItem,
    EuiText,
    EuiPanel,
    EuiStat,
    EuiButtonEmpty,
    EuiSpacer,
    EuiLoadingContent,
} from '@elastic/eui';
import moment from 'moment';
import '../../styles/datePickerStyle.css';
import VisitorChart from '../dashboardComponents/visitorChart/visitorChart';
import OrderRatingTable from './orderRatingTable';
import API from '../../api/axios/API';
import { useSelector } from 'react-redux';
import { OrderFlyout } from './orderFlyout';
import { isEmpty } from 'lodash';
import OrderRatingCsvDownload from './orderRatingCSVDownload';
import StarRatingComponent from './starRatingComponent';
import CustomDatePicker from '../Credits/CustomDatePicker';

export const OrderRatingComponent = (props) => {
    const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 });
    const [orderList, setOrderList] = useState({});
    const [startDateTime, setStartDateTime] = useState(moment());
    const [endDateTime, setEndDateTime] = useState(moment());
    const selectedOutlet = useSelector((state) => state.outlet.selectedOutletId);
    const [activePage, setActivePage] = useState(0);
    const [rowSize, setRowSize] = useState(10);

    const [csvState, setCsvState] = useState(false);
    const [orderLoading, setOrderLaoding] = useState(true);

    const csvStateCallback = useCallback((value) => {
        setCsvState(value);
    }, []);

    const fetchOrderList = async () => {
        setOrderLaoding(true);
        let orderResponse;
        try {
            orderResponse = await API.get(
                `/restaurants/:restaurantId/outlets/${selectedOutlet}/orders?page=${
                    activePage + 1
                }&per_page=${rowSize}&start_date=${startDateTime.format('YYYY-MM-DD')}&end_date=${endDateTime.format(
                    'YYYY-MM-DD'
                )}`
            );
        } catch (error) {
            orderResponse = error;
        }

        if (orderResponse.success) {
            setOrderList(orderResponse);
        }
        setOrderLaoding(false);
        return null;
    };

    useEffect(() => {
        selectedOutlet && fetchOrderList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedOutlet, endDateTime, activePage, rowSize, startDateTime]);
    // pagination, endDateTime

    const [flyoutOpen, setFlyoutOpen] = useState(false);
    const [selectedOrder, setSelectedOrder] = useState({});

    let modal = (
        <>
            <OrderFlyout setSelectedOrder={setSelectedOrder} setFlyoutOpen={setFlyoutOpen} order={selectedOrder} />
        </>
    );

    const paginationCallback = useCallback((value) => {
        setPagination(value);
    }, []);

    const flyoutCallback = useCallback((value) => {
        setFlyoutOpen(value);
    }, []);

    const selectedOrderCallback = useCallback((value) => {
        setSelectedOrder(value);
    }, []);

    const rowSizeCallback = useCallback((value) => {
        setRowSize(value);
    }, []);

    const activePageCallback = useCallback((value) => {
        setActivePage(value);
    }, []);

    const orderListContentCheck = () => {
        if (orderLoading) {
            return <EuiLoadingContent lines={10} />;
        }

        if (isEmpty(orderList)) {
            return (
                <div>
                    <div style={{ position: 'absolute', top: '50%', left: '50%' }}>
                        <br />
                        <EuiText>
                            <h3 style={{ textAlign: 'center' }}>No Outlet found!</h3>
                        </EuiText>
                    </div>
                </div>
            );
        }
    };

    return (
        <>
            <EuiFlexGroup direction='column'>
                {flyoutOpen && modal}
                <EuiFlexItem style={{ flexDirection: 'row', justifyContent: 'space-between', padding: 2 }}>
                    <CustomDatePicker
                        startDate={startDateTime}
                        setStartDate={setStartDateTime}
                        endDate={endDateTime}
                        setEndDate={setEndDateTime}
                        displayFormat='DD-MM-YYYY'
                    />
                </EuiFlexItem>
                {!orderLoading ? (
                    <>
                        <EuiFlexItem style={{ flexDirection: 'row' }}>
                            <EuiFlexItem style={{ flexDirection: 'row', width: '100%' }}>
                                <EuiPanel style={{ width: '1%', marginRight: '10px' }}>
                                    <EuiFlexItem
                                        style={{ flexDirection: 'row', justifyContent: 'space-between' }}
                                        grow={6}
                                    >
                                        <EuiStat
                                            title={orderList.order_collection.order_count}
                                            description='Delivered Orders'
                                            textAlign='left'
                                        />
                                        <EuiStat
                                            title={orderList.order_collection.rated_orders}
                                            description='Rated orders'
                                            textAlign='left'
                                        />
                                    </EuiFlexItem>
                                    <EuiFlexItem>
                                        <StarRatingComponent orderList={orderList} />
                                    </EuiFlexItem>
                                </EuiPanel>
                                <EuiPanel
                                    style={{
                                        width: '1%',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        display: 'flex',
                                    }}
                                >
                                    <EuiFlexItem style={{ alignItems: 'center', justifyContent: 'center' }}>
                                        <EuiStat
                                            title={
                                                <span style={{ fontSize: '50px', paddingTop: '10px' }}>
                                                    {orderList.order_collection.outlet_average_rating.toFixed(2)}{' '}
                                                </span>
                                            }
                                            description={
                                                <span style={{ fontSize: '25px', paddingBottom: '10px' }}>
                                                    Average Rating
                                                </span>
                                            }
                                            textAlign='left'
                                        />
                                    </EuiFlexItem>
                                </EuiPanel>
                                <EuiFlexItem style={{ width: '90%' }}></EuiFlexItem>
                            </EuiFlexItem>
                        </EuiFlexItem>
                        <EuiFlexItem>
                            {/* <EuiText>
                                    <h2>Average ratings</h2>
                                </EuiText>
                                <EuiHorizontalRule /> */}
                            <VisitorChart isRatingChart ratingList={orderList.order_collection.order_by_dates} />
                        </EuiFlexItem>
                        <EuiFlexItem>
                            <EuiFlexItem
                                style={{ justifyContent: 'space-between', alignItems: 'center', flexDirection: 'row' }}
                            >
                                <EuiText>Order List</EuiText>
                                {orderList?.order_collection?.orders?.data?.length ? (
                                    <div>
                                        <EuiButtonEmpty aria-label='csv button' onClick={() => setCsvState(true)}>
                                            Download CSV
                                        </EuiButtonEmpty>
                                        <EuiText>({rowSize} items from below page)</EuiText>
                                    </div>
                                ) : (
                                    ''
                                )}

                                {csvState && (
                                    <OrderRatingCsvDownload
                                        order={orderList}
                                        csvStateCallback={csvStateCallback}
                                        selectedOutlet={selectedOutlet}
                                    />
                                )}
                            </EuiFlexItem>

                            <EuiSpacer size='s' />
                            <OrderRatingTable
                                orderList={orderList}
                                pagination={pagination}
                                // setPagination={setPagination}
                                paginationCallback={paginationCallback}
                                // setFlyoutOpen={setFlyoutOpen}
                                flyoutCallback={flyoutCallback}
                                // setSelectedOrder={setSelectedOrder}
                                selectedOrderCallback={selectedOrderCallback}
                                rowSize={rowSize}
                                // setRowSize={setRowSize}
                                rowSizeCallback={rowSizeCallback}
                                activePage={activePage}
                                // setActivePage={setActivePage}
                                activePageCallback={activePageCallback}
                            />
                        </EuiFlexItem>
                    </>
                ) : (
                    orderListContentCheck()
                )}
            </EuiFlexGroup>
        </>
    );
};
