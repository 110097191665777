import {
    EuiButton,
    EuiCard,
    EuiFlexGroup,
    EuiFlexItem,
    EuiLoadingContent,
    EuiPanel,
    EuiSideNav,
    EuiSpacer,
    EuiTitle,
} from '@elastic/eui';
import React, { useCallback, useEffect } from 'react';
import { useMemo } from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { MainLayout } from '../../components/layout/mainLayout';
import MediaSelectionImage from '../../components/UIComponent/ImageSelection/MediaSelectionImage';
import UploadButton from '../../components/UIComponent/ImageSelection/UploadButton';
import { userHasPermission } from '../../helper/auth';
import { fetchImageList } from './Api/fetchImageList';
import UploadImageDropZone from './Components/UploadImageDropZone';

const MediaLibraryPage = () => {
    const [state, setState] = useState({
        images: [],
        isLoading: false,
        showUploadImageModal: false,
    });
    const themeMode = useSelector((state) => state.theme.theme);
    const [selectedTab, setSelectedTab] = useState('categories');
    const permissions = useSelector((state) => state.permission.permission);
    const history = useHistory();

    const fetchImages = useCallback(async (imageType) => {
        await fetchImageList(imageType, setState);
    }, []);

    const onChangeTab = useCallback((tab) => {
        setSelectedTab(tab);
    }, []);

    const sidebarItems = useMemo(() => {
        return [
            {
                id: 'categories',
                name: 'Categories',
                onClick: () => onChangeTab('categories'),
                isSelected: selectedTab === 'categories',
                aspectRatio: 1 / 1.066,
            },
            {
                id: 'items',
                name: 'Items',
                onClick: () => onChangeTab('items'),
                isSelected: selectedTab === 'items',
                aspectRatio: 1,
            },
            {
                id: 'banner',
                name: 'Banner',
                onClick: () => onChangeTab('banner'),
                isSelected: selectedTab === 'banner',
                aspectRatio: 1 / 1.066,
            },
            // {
            //     id: 'addon_choices',
            //     name: 'Addon Choices',
            //     onClick: () => onChangeTab('addon_choices'),
            //     isSelected: selectedTab === 'addon_choices',
            //     aspectRatio: 1 / 1.066,
            // },
            {
                id: 'variant_options',
                name: 'Variant Options',
                onClick: () => onChangeTab('variant_options'),
                isSelected: selectedTab === 'variant_options',
                aspectRatio: 1,
            },
            {
                id: 'notifications',
                name: 'Notifications',
                onClick: () => onChangeTab('notifications'),
                isSelected: selectedTab === 'notifications',
                aspectRatio: 1 / 1.066,
            },
            {
                id: 'intro_logo_image',
                name: 'Intro Logo Image',
                onClick: () => onChangeTab('intro_logo_image'),
                isSelected: selectedTab === 'intro_logo_image',
                aspectRatio: 1 / 1.066,
            },
            {
                id: 'footer_image',
                name: 'Footer Image ',
                onClick: () => onChangeTab('footer_image'),
                isSelected: selectedTab === 'footer_image',
                aspectRatio: 1 / 1.066,
            },
            {
                id: 'riders',
                name: 'Riders',
                onClick: () => onChangeTab('riders'),
                isSelected: selectedTab === 'riders',
                aspectRatio: 1 / 1.066,
            },
            {
                id: 'primary_banner',
                name: 'Primary Banner',
                onClick: () => onChangeTab('primary_banner'),
                isSelected: selectedTab === 'primary_banner',
                aspectRatio: 1 / 0.572,
            },
            {
                id: 'secondary_banner',
                name: 'Secondary Banner',
                onClick: () => onChangeTab('secondary_banner'),
                isSelected: selectedTab === 'secondary_banner',
                aspectRatio: 1 / 1.066,
            },
            {
                id: 'tertiary_banner',
                name: 'Tertiary Banner',
                onClick: () => onChangeTab('tertiary_banner'),
                isSelected: selectedTab === 'tertiary_banner',
                aspectRatio: 1 / 1.066,
            },
        ];
    }, [onChangeTab, selectedTab]);

    const sideNav = useMemo(() => {
        return [
            {
                name: '',
                id: 0,
                items: sidebarItems,
            },
        ];
    }, [sidebarItems]);

    const handleUploadImageModalToggle = useCallback(() => {
        setState((prevState) => ({
            ...prevState,
            showUploadImageModal: !prevState.showUploadImageModal,
        }));
    }, []);

    useEffect(() => {
        fetchImages(selectedTab);
    }, [fetchImages, selectedTab]);

    const aspectRatio = sidebarItems.filter((item) => item.id === selectedTab)?.[0]?.aspectRatio;

    return (
        <MainLayout title='Media Library' backgroundColorChange>
            <>
                {state.showUploadImageModal ? (
                    <UploadImageDropZone
                        fetchImages={fetchImages}
                        imageType={selectedTab}
                        handleUploadImageModalToggle={handleUploadImageModalToggle}
                        aspectRatio={aspectRatio ? aspectRatio : 1}
                    />
                ) : null}

                <EuiSpacer size='s' />
                <EuiFlexGroup
                    wrap
                    responsive
                    direction='row'
                    justifyContent='spaceBetween'
                    alignItems='flexStart'
                    gutterSize='l'
                >
                    <EuiFlexItem style={{ position: 'sticky', top: '50px' }} grow={false}>
                        <EuiPanel>
                            <EuiSideNav
                                aria-label='Media Types'
                                mobileTitle='Navigate'
                                style={{
                                    // backgroundColor: themeMode === 'light' ? '#fafbfd' : '#1a1b20',
                                    padding: '8px',
                                }}
                                items={sideNav}
                            />
                        </EuiPanel>
                    </EuiFlexItem>
                    {state.isLoading ? (
                        <>
                            <EuiFlexItem>
                                <EuiPanel>
                                    <EuiLoadingContent lines={4} />
                                </EuiPanel>
                            </EuiFlexItem>
                        </>
                    ) : (
                        <>
                            <EuiPanel style={{ marginTop: '12px' }}>
                                <EuiFlexGroup direction='column'>
                                    <EuiFlexItem>
                                        <EuiFlexGroup justifyContent='spaceBetween' alignItems='flexEnd'>
                                            <EuiFlexItem grow={false}>
                                                <EuiTitle size='s'>
                                                    <h4>
                                                        {sidebarItems.map((item) =>
                                                            item.id === selectedTab ? item.name : null
                                                        )}
                                                    </h4>
                                                </EuiTitle>
                                            </EuiFlexItem>
                                            <EuiFlexItem onClick={handleUploadImageModalToggle} grow={false}>
                                                <UploadButton />
                                            </EuiFlexItem>
                                        </EuiFlexGroup>
                                    </EuiFlexItem>
                                    {state.images?.length ? (
                                        <EuiFlexItem>
                                            <EuiFlexGroup
                                                wrap
                                                responsive
                                                direction='row'
                                                alignItems='flexStart'
                                                gutterSize='l'
                                            >
                                                {state.images.map((image) => {
                                                    return (
                                                        <MediaSelectionImage
                                                            isModuleComponent
                                                            key={image.id.toString()}
                                                            image={image}
                                                        />
                                                    );
                                                })}
                                            </EuiFlexGroup>
                                        </EuiFlexItem>
                                    ) : (
                                        <EuiFlexItem>
                                            <EuiCard title='No Images Found' titleSize='xs' />
                                        </EuiFlexItem>
                                    )}
                                </EuiFlexGroup>
                            </EuiPanel>
                        </>
                    )}
                </EuiFlexGroup>
            </>
        </MainLayout>
    );
};

export default MediaLibraryPage;
