import * as types from '../types/outlet';

const initialState = {
    outletList: [],
    selectedOutletId: '',
    categoryOptions: [],
    categoryList: [],
    selectedOutlet: null,
    activeCategoryId: null,
    activeItemId: null,
    itemList: [],
    categoryMainList: {},
    selectedItem: {},
    editOutletId: null,
    portalActive: {},
    tabSwitched: false,
    submitLoader: false,
    outletOrderingModes: null,
    orderingModes: [],
};

export default (state = initialState, action) => {
    switch (action.type) {
        case types.SET_PORTAL_ACTIVE:
            return {
                ...state,
                portalActive: action.payload.portalActive,
            };
        case types.SET_PORTAL_ACTIVE_TAB_SWITCHED:
            return {
                ...state,
                tabSwitched: action.payload.tabSwitched,
            };
        case types.SET_OUTLET_LIST:
            return {
                ...state,
                outletList: action.payload.outletList,
            };
        case types.SET_SELECTED_OUTLET_ID:
            return {
                ...state,
                selectedOutletId: action.payload.selectedOutletId,
            };
        case types.SET_CATEGORY_OPTIONS:
            return {
                ...state,
                categoryOptions: action.payload.categoryOptions,
            };
        case types.SET_CATEGORY_LIST:
            return {
                ...state,
                categoryList: action.payload.categoryList,
            };
        case types.SET_SELECTED_OUTLET:
            return {
                ...state,
                selectedOutlet: action.payload.selectedOutlet,
            };
        case types.SET_OUTLET_ACTIVE_CATEGORY_ID:
            return {
                ...state,
                activeCategoryId: action.payload.activeCategoryId,
            };
        case types.SET_ITEM_LIST:
            return {
                ...state,
                itemList: action.payload.itemList,
            };
        case types.SET_CATEGORY_MAIN_LIST:
            return {
                ...state,
                categoryMainList: action.payload.categoryMainList,
            };
        case types.SET_OUTLET_ACTIVE_ITEM_ID:
            return {
                ...state,
                activeItemId: action.payload.activeItemId,
            };
        case types.SET_OUTLET_SELECTED_ITEM:
            return {
                ...state,
                selectedItem: action.payload.selectedItem,
            };
        case types.SET_EDIT_OUTLET_ID:
            return {
                ...state,
                editOutletId: action.payload.editOutletId,
            };
        case types.SET_SUBMIT_LOADER:
            return {
                ...state,
                submitLoader: action.payload.submitLoader,
            };
        case types.GET_OUTLET_ORDERING_MODES:
            return {
                ...state,
                outletOrderingModes: action.payload,
            };
        case types.SET_ORDERING_MODES:
            return {
                ...state,
                orderingModes: action.payload,
            };
        default:
            return state;
    }
};
