import API from './axios/API';
import * as types from '../reduxStore/types/billing';

export const getBillingCategories = (outletId) => {
    return async (dispatch, getState) => {
        let categoryList;
        let { billing, language } = getState();

        let mode = billing.mode == 'qsr' ? 'takeaway' : billing.mode;
        console.log('billing.mode ', mode);

        try {
            categoryList = await API.get(
                `${language.language}/restaurants/:restaurantId/outlets/${outletId}/outlet-categories?ordering_mode=${mode}`
            );
        } catch (error) {
            categoryList = error;
        }

        if (categoryList.success) {
            dispatch({
                type: types.SET_BILLING_CATEGORIES,
                payload: {
                    categories: { ...categoryList },
                },
            });

            if (categoryList.outlet_categories && categoryList.outlet_categories.length > 0) {
                dispatch({
                    type: types.SET_ACTIVE_CATEGORY_ID,
                    payload: {
                        activeCategoryId: categoryList.outlet_categories[0].category_id,
                    },
                });
            }
        } else {
            alert('Error in category fetch');
        }
    };
};
