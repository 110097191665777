import React, { useState, useRef, useEffect } from 'react';
import {
    EuiFlexItem,
    EuiStepsHorizontal,
    EuiButton,
    EuiOverlayMask,
    EuiConfirmModal,
    EuiCheckbox,
    htmlIdGenerator,
    EuiFormRow,
    EuiFieldNumber,
    EuiFieldText,
    EuiCheckableCard,
    EuiPopover,
    EuiText,
    EuiTable,
    EuiTableRow,
    EuiTableRowCell,
    EuiButtonEmpty,
} from '@elastic/eui';
import { isEmpty } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import * as types from '../../reduxStore/types/billing';
import ReactToPrint from 'react-to-print';
import BillingInvoicePrint from '../print/BillingInvoicePrint';
import moment from 'moment';
import API from '../../api/axios/API';
import PaymentOptions from './paymentOptions';
import Stepper from 'react-stepper-horizontal';
import OtpModal from './otpModal';
import { getErroMessage } from '../../helper/getErrorMessage';
import StepWizardChangeModal from './stepWizardChangeModal';
import { ReactComponent as BackArrow } from '../../assets/img/backArrow.svg';

const BillingSectionButtonList = (props) => {
    const {
        placeOrderCall,
        setRedemePoint,
        setRedemePointConfirmationModal,
        setDiscountFlyout,
        status,
        setStatus,
        setButtonClicked,
        resetBillingUser,
        outletId,
        customer_name,
        customer_address,
        value,
        discountData,
        paymentOptionSelected,
        watch,
        redemePoint,
        setValue,
        generateOTPForRedemePoints,
    } = props;

    const dispatch = useDispatch();
    const billingItem = useSelector((state) => state.billing.billingItem);
    const itemFreeList = useSelector((state) => state.billing.itemFreeList);
    const mode = useSelector((state) => state.billing.mode);
    const languageId = useSelector((state) => state.language.languageId);
    const languageCode = useSelector((state) => state.language.language);
    let restaurantList = useSelector((state) => state.auth.restaurantList);
    let redemePointValueOnPurchase = useSelector((state) => state.auth.redemePointValueOnPurchase);
    let selectedRestuarantId = useSelector((state) => state.auth.selectedRestaurantId);

    const tableSelectedList = useSelector((state) => state.billing.tableSelectedList);
    const customerInformation = useSelector((state) => state.billing.customerInformation);
    const finishCustomerInfo = useSelector((state) => state.billing.finishCustomerInfo);
    const cartId = useSelector((state) => state.billing.cartId);
    const orderId = useSelector((state) => state.billing.orderId);

    const tableData = useSelector((state) => state.billing.tableData);
    const previousOrders = useSelector((state) => state.billing.previousOrders);
    const billingItemCartStructure = useSelector((state) => state.billing.billingItemCartStructure);
    const selectedOutletId = useSelector((state) => state.outlet.selectedOutletId);

    const invoicePrinterRef = useRef(null);

    const [horizontalSteps, setHorizontalSteps] = useState([]);
    const [showTableResetModal, setShowTableResetModal] = useState(false);
    const [otpModalState, setOtpModalState] = useState(false);
    const [statusSelect, setStatusSelect] = useState({ kot: false, bill: false, payment: false, close: false });

    const [openStepWizardModal, setOpenStepWizard] = useState(false);
    const [stepValue, setStepValue] = useState('');

    const reset = useSelector((state) => state.billing.reset);

    useEffect(() => {
        if (!isEmpty(tableData)) {
            if (!status.payment) {
                setStatus({
                    kot: tableData.status_information.kot_running_at || tableData.status_information.occupied_at,
                    bill: tableData.status_information.bill_printed_at,
                    payment: tableData.status_information.payment_done_at,
                    close: false,
                });
            }

            if (!isEmpty(billingItem)) {
                setStatus({
                    kot: true,
                    bill: false,
                    payment: false,
                    close: false,
                });
            }
        } else {
            resetProgressBar();
        }
    }, [tableData]);

    // useEffect(() => {
    //     resetProgressBar()
    // }, [mode])

    useEffect(() => {
        // horizontalStepsUpdate(status)
        // setStatus(status)
    }, [status]);

    useEffect(() => {
        if (isEmpty(billingItem)) {
            setStatus(status);
        } else {
            setStatus({
                kot: true,
                bill: false,
                payment: false,
                close: false,
            });
        }
    }, [billingItem]);

    useEffect(() => {
        if (!isEmpty(tableData)) {
            setStatus({
                kot: tableData.status_information.kot_running_at || tableData.status_information.occupied_at,
                bill: tableData.status_information.bill_printed_at,
                payment: tableData.status_information.payment_done_at,
                close: false,
            });
        }
    }, []);

    useEffect(() => {
        if (isEmpty(tableData) && finishCustomerInfo) {
            setStatus({
                kot: true,
                bill: false,
                payment: false,
                close: false,
            });
        }

        // if(!finishCustomerInfo && isEmpty(billingItem)){
        //     dispatch({
        //         type: types.SET_CUSTOMER_INFORMATION,
        //         payload:{
        //             customerInformation: {}
        //         }
        //     })
        // }
    }, [finishCustomerInfo]);

    const resetProgressBar = () => {
        setStatus({ kot: false, bill: false, payment: false, close: false });

        // horizontalStepsUpdate({
        //     kot: false,
        //     bill: false,
        //     payment: false,
        //     close: false
        // })
    };

    const billStatusChange = () => {
        if (status.bill) {
            setStatus({ ...status, bill: false, payment: false, close: false });
            // setStatusSelect({...status, bill: false,payment: false, close: false})
        } else {
            setStatus({ ...status, bill: true });
            // setStatusSelect({...status, bill: true})
            // statusBarUpdate()
        }
    };

    const kotStatusChange = () => {
        if (status.bill) {
            setStatus({ kot: false, bill: false, payment: false, close: false });
            // setStatusSelect({kot: false, bill: false,payment: false, close: false})
        } else {
            setStatus({ ...status, kot: !status.kot });
            // setStatusSelect({...status, kot: !status.kot})
        }
    };

    const onClose = async () => {
        let response;

        try {
            response = await API.patch(
                `restaurants/:restaurantId/outlets/${outletId}/tables/${tableData.outlet_table_id}/status-update`,
                {
                    status: 'idle',
                }
            );
        } catch (error) {
            response = error;
        }

        if (response.success) {
            setStatus({ ...status, close: !status.close });
            resetBillingUser();
        } else {
            if (response.message) {
                alert(response.message);
            } else {
                alert('Fail to update status');
            }
        }
    };

    const horizontalStepsUpdate = (status) => {
        if (mode === 'dine_in') {
            setHorizontalSteps([
                {
                    title: 'KOT',
                    isComplete: status.kot,
                    // isSelected: statusSelect.kot,
                    disabled: !finishCustomerInfo,
                    onClick: () => finishCustomerInfo && kotStatusChange(),
                },
                {
                    title: 'Print Bill',
                    isComplete: status.bill,
                    // isSelected: statusSelect.bill,
                    disabled: !status.kot,
                    onClick: () => billStatusChange(),
                },
                {
                    title: 'Payment',
                    isComplete: status.payment,
                    // isSelected: statusSelect.payment,
                    disabled: !status.bill,
                    onClick: () => setStatus({ ...status, payment: !status.payment }),
                    // setStatusSelect({...status, payment: !status.payment})
                    // setStatus({...status, payment: !status.payment})
                },
                {
                    title: 'Close',
                    isComplete: status.close,
                    // isSelected: statusSelect.close,
                    disabled: !status.payment,
                    onClick: () => onClose(),
                },
            ]);
        } else {
            setHorizontalSteps([
                {
                    title: 'KOT',
                    isComplete: status.kot,
                    disabled: !finishCustomerInfo,
                    onClick: () => finishCustomerInfo && kotStatusChange(),
                },
                {
                    title: 'Print Bill',
                    isComplete: status.bill,
                    disabled: !status.kot,
                    onClick: () => billStatusChange(),
                },
                {
                    title: 'Payment',
                    isComplete: status.payment,
                    disabled: !status.bill,
                    onClick: () => setStatus({ ...status, payment: !status.payment }),
                },
            ]);
        }
    };

    useEffect(() => {
        let status = { kot: false, bill: false, payment: false, close: false };

        horizontalStepsUpdate(status);
    }, []);

    const onCloseTable = () => {
        setStatus({ ...status, close: true });
        setShowTableResetModal(true);
    };

    const splitModalOpen = () => {
        dispatch({
            type: types.SET_SPLIT_ORDER_MODAL,
            payload: {
                splitOrderModal: true,
            },
        });
    };

    const onPlaceOrder = () => {
        if (redemePoint) {
            setOtpModalState(true);
            // if(watch('redeme_points') && watch('otp')){
            //     placeOrderCall()
            // }
        } else {
            placeOrderCall();
        }
    };

    const onConfirmReset = async () => {
        let response;

        try {
            response = await API.patch(
                `/restaurants/:restaurantId/outlets/${outletId}/tables/${tableData.outlet_table_id}/status-update`,
                {
                    status: 'Idle',
                }
            );
        } catch (error) {
            response = error;
        }

        if (response.success) {
            dispatch({
                type: types.SET_CUSTOMER_INFORMATION_FINISH,
                payload: {
                    finishCustomerInfo: false,
                },
            });
            setShowTableResetModal(false);
            resetBillingUser();
        } else {
            alert('Error in updating table status');
        }
    };

    let resetModal = (
        <EuiOverlayMask>
            <EuiConfirmModal
                title='Are you sure you want to reset this table?'
                onCancel={() => setShowTableResetModal(false)}
                onConfirm={onConfirmReset}
                cancelButtonText="No, don't do it"
                confirmButtonText='Yes, do it'
                buttonColor='danger'
                defaultFocusedButton='cancel'
            >
                <p>You&rsquo;re about to reset this table</p>
                <p>Are you sure you want to do this?</p>
            </EuiConfirmModal>
        </EuiOverlayMask>
    );

    const updatePrintStatus = async () => {
        statusBarUpdate();

        if (redemePoint) {
            setOtpModalState(true);
        } else {
            document.getElementById('printButton').click();
        }
    };

    const completeOrder = async () => {
        let response;
        try {
            response = await API.patch(`/restaurants/:restaurantId/orders/${orderId}/change-status`, {
                status: 'customer_picked_up',
            });
        } catch (error) {
            response = error;
        }

        if (response.success) {
            console.log('response ', response);
        } else {
            alert('Error in completing order');
        }
    };

    const statusBarUpdate = async () => {
        let response;

        try {
            let url = '';
            if (mode === 'dine_in') {
                url = `restaurants/:restaurantId/outlets/${outletId}/tables/${tableData.outlet_table_id}/status-update`;
            } else {
                if (!isEmpty(previousOrders) && previousOrders.length > 0) {
                    url = `restaurants/:restaurantId/orders/${previousOrders[0].order_id}/change-status`;
                }
            }

            response = await API.patch(url, {
                status: 'bill_print',
            });
        } catch (error) {
            response = error;
        }

        if (response.success) {
            // alert("Update status")
        } else {
            alert('Fail to update status');
        }
    };

    const kotButtonCondition = () => {
        let condition = false;

        if (status.kot && !status.bill && !status.payment) {
            condition = true;
        }
        return condition;
    };

    const billPrintButtonCondition = () => {
        return status.bill ? true : false;
    };

    const subTotal = () => {
        let subTotal = 0;

        if (!isEmpty(billingItemCartStructure)) {
            subTotal = billingItemCartStructure.cart.gross_total;
        }

        if (!isEmpty(tableData)) {
            subTotal += tableData.gross_total;
        }

        return subTotal.toFixed(2);
    };

    const discountAmount = () => {
        let discount_amount = 0;
        if (!isEmpty(billingItemCartStructure)) {
            discount_amount = billingItemCartStructure.cart.discount_amount;
        }

        if (!isEmpty(tableData)) {
            discount_amount += tableData.discount_amount;
        }

        return parseFloat(discount_amount).toFixed(2);
    };

    const grandTotal = () => {
        let total = 0;
        if (!isEmpty(billingItemCartStructure)) {
            total = billingItemCartStructure.cart.net_total;
        }

        if (!isEmpty(tableData)) {
            total += tableData.net_total;
        }

        if (total > 0 && redemePoint && customerInformation.reward_points / redemePointValueOnPurchase) {
            if (customerInformation.reward_points / redemePointValueOnPurchase > total) {
                total = 0;
            } else {
                total -= customerInformation.reward_points / redemePointValueOnPurchase;
            }
        }

        return parseFloat(total).toFixed(2);
    };

    const redemePointsTotal = () => {
        let points = 0;
        if (redemePoint) {
            let total = 0;
            if (!isEmpty(billingItemCartStructure)) {
                total = billingItemCartStructure.cart.net_total;
            }

            if (!isEmpty(tableData)) {
                total += tableData.net_total;
            }

            if (total > 0 && customerInformation.reward_points / redemePointValueOnPurchase) {
                if (customerInformation.reward_points / redemePointValueOnPurchase > total) {
                    points = total;
                } else {
                    points = customerInformation.reward_points / redemePointValueOnPurchase;
                }
            }
        }

        return parseFloat(points).toFixed(2);
    };

    const closeMethod = () => {
        console.log('close button clicked');
    };

    const colorCondition = (type) => {
        if (type === 'kot') {
            if (status.kot && !status.bill && !status.payment) {
                return true;
            }
        } else if (type === 'bill') {
            if (status.bill && !status.payment) {
                return true;
            }
        } else if (type === 'payment') {
            if (status.payment && !status.close) {
                return true;
            }
        } else if (type === 'close') {
            if (status.close) {
                return true;
            }
        }

        return false;
    };

    const homeButtonCondition = (type) => {
        if (type === 'reset') {
            dispatch({
                type: types.SET_BILLING_ITEM,
                payload: {
                    billingItem: {},
                },
            });
        } else if (type === 'home') {
            resetBillingUser();

            setStatus({ kot: false, bill: false, payment: false, close: false });
        }
    };

    const onStepClicked = (type) => {
        if (type === 'kot') {
            setStatus({
                kot: true,
                bill: false,
                payment: false,
                close: false,
            });
        } else if (type === 'bill' && previousOrders.length) {
            setStatus({
                kot: false,
                bill: true,
                payment: false,
                close: false,
            });
        } else if (type === 'payment' && (mode !== 'dine_in' || previousOrders.length)) {
            setStatus({
                kot: false,
                bill: false,
                payment: true,
                close: false,
            });
        } else if (type === 'close' && previousOrders.length) {
            setStatus({
                kot: false,
                bill: false,
                payment: true,
                close: false,
            });
            onClose();
        }
    };

    const onStepChange = (value) => {
        if (isEmpty(billingItem)) {
            onStepClicked(value);
        } else {
            setStepValue(value);
            setOpenStepWizard(true);
        }
    };

    console.log('status in button', status);
    return (
        <>
            {showTableResetModal && resetModal}
            {otpModalState && (
                <OtpModal
                    status={status}
                    placeOrder={placeOrderCall}
                    setOtpModalState={setOtpModalState}
                    setParentValue={setValue}
                    generateOTPForRedemePoints={generateOTPForRedemePoints}
                />
            )}
            {openStepWizardModal && (
                <StepWizardChangeModal
                    setOpenStepWizard={setOpenStepWizard}
                    stepValue={stepValue}
                    onStepClicked={onStepClicked}
                />
            )}

            <div style={{ display: 'none' }}>
                <BillingInvoicePrint
                    customerInformation={customerInformation}
                    redemePointValueOnPurchase={redemePointValueOnPurchase}
                    redemePoints={redemePoint}
                    pointsValue={redemePointsTotal}
                    outletId={selectedOutletId}
                    billingItem={billingItem}
                    tableData={tableData}
                    billingItemCartStructure={billingItemCartStructure}
                    previousOrders={previousOrders}
                    languageId={languageId}
                    ref={invoicePrinterRef}
                    restaurantList={restaurantList}
                    selectedRestuarantId={selectedRestuarantId}
                    customer_name={customer_name}
                    customer_address={customer_address}
                    mode={mode}
                />
            </div>

            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                {!status.payment && (
                    <div
                        style={{
                            padding: '0 20px',
                            display: 'flex',
                            flexDirection: 'row',
                            margin: '5px',
                            marginBottom: '8px',
                        }}
                    >
                        <table style={{ width: '100%', fontSize: '14px' }}>
                            <tr style={{ borderBottom: '1px solid #D3DAE6' }}>
                                <td style={{ width: '60%' }}></td>
                                <td style={{ width: '20%', borderRight: '1px solid #D3DAE6' }}>Item total</td>
                                <td style={{ width: '20%' }}>
                                    <b>&#8377; {subTotal()}</b>
                                </td>
                            </tr>
                            <tr style={{ borderBottom: '1px solid #D3DAE6' }}>
                                <td style={{ width: '60%' }}></td>
                                <td style={{ width: '20%', borderRight: '1px solid #D3DAE6' }}>
                                    {/* onClick={() => !(!finishCustomerInfo && (isEmpty(previousOrders) || isEmpty(billingItem))) && setDiscountFlyout(true)} */}
                                    <span>Discount</span>
                                </td>
                                <td style={{ width: '20%' }}>
                                    <b>&#8377; {discountAmount()}</b>
                                </td>
                            </tr>
                            <tr style={{ borderBottom: '1px solid #D3DAE6' }}>
                                <td style={{ width: '60%' }}></td>
                                <td style={{ width: '20%', borderRight: '1px solid #D3DAE6' }}>GST</td>
                                <td style={{ width: '20%' }}>
                                    <b>₹ {!isEmpty(tableData) ? tableData.gst_amount.toFixed(2) : '0.00'}</b>
                                </td>
                            </tr>
                            <tr style={{ margin: '10px', borderBottom: '1px solid #D3DAE6' }}>
                                <td style={{ width: '60%' }}></td>
                                <td style={{ width: '20%', borderRight: '1px solid #D3DAE6' }}>
                                    <b>Net Total</b>
                                </td>
                                <td style={{ width: '20%' }}>
                                    <b>&#8377; {grandTotal()}</b>
                                </td>
                            </tr>
                        </table>
                    </div>
                )}

                {kotButtonCondition() || billPrintButtonCondition() ? (
                    <EuiFlexItem
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '99%',
                            padding: '6px 10px',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}
                    >
                        {kotButtonCondition() ? (
                            <>
                                <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                                    <EuiButton
                                        size='s'
                                        fill
                                        style={{
                                            fontSize: '14px',
                                            width: '20%',
                                            marginLeft: '5px',
                                            backgroundColor: '#666666',
                                            color: '#ffffff',
                                            border: 'transparent',
                                        }}
                                        disabled={isEmpty(billingItem) || !finishCustomerInfo}
                                        onClick={() =>
                                            dispatch({
                                                type: types.SET_BILLING_ITEM,
                                                payload: {
                                                    billingItem: {},
                                                },
                                            })
                                        }
                                    >
                                        RESET KOT
                                    </EuiButton>
                                    <EuiButton
                                        size='s'
                                        fill
                                        style={{
                                            fontSize: '14px',
                                            width: '20%',
                                            marginLeft: '5px',
                                            backgroundColor: '#ff5858',
                                            color: '#ffffff',
                                            border: 'transparent',
                                        }}
                                        type='submit'
                                        onClick={() => setButtonClicked('hold')}
                                        disabled={isEmpty(billingItem) || !finishCustomerInfo}
                                    >
                                        HOLD
                                    </EuiButton>

                                    {mode === 'dine_in' ? (
                                        <EuiButton
                                            size='s'
                                            type='submit'
                                            style={{
                                                fontSize: '14px',
                                                fontFamily: 'Metropolis-Bold',
                                                width: '60%',
                                                backgroundColor: '#275af6',
                                                color: '#ffffff',
                                                marginLeft: '5px',
                                                border: 'transparent',
                                            }}
                                            disabled={isEmpty(billingItem) || !finishCustomerInfo}
                                            onClick={() => {
                                                onPlaceOrder();
                                                setButtonClicked('place');
                                            }}
                                        >
                                            PLACE
                                        </EuiButton>
                                    ) : (
                                        <EuiButton
                                            size='s'
                                            onClick={() => finishCustomerInfo && onStepClicked('payment')}
                                            style={{
                                                fontSize: '14px',
                                                width: '60%',
                                                backgroundColor: '#275af6',
                                                color: '#ffffff',
                                                marginLeft: '5px',
                                                border: 'transparent',
                                            }}
                                        >
                                            CONTINUE
                                        </EuiButton>
                                    )}
                                </div>
                                {mode === 'dine_in' ? (
                                    <EuiButtonEmpty
                                        onClick={() => finishCustomerInfo && onStepChange('bill')}
                                        size='s'
                                        style={{ fontSize: '14px' }}
                                    >
                                        PROCEED TO BILL
                                    </EuiButtonEmpty>
                                ) : (
                                    ''
                                )}
                            </>
                        ) : (
                            ''
                        )}

                        {billPrintButtonCondition() ? (
                            <>
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        width: '100%',
                                    }}
                                >
                                    <div
                                        style={{ marginRight: '10px', cursor: 'pointer' }}
                                        onClick={() => finishCustomerInfo && onStepChange('kot')}
                                    >
                                        <BackArrow />
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                                        <EuiButton
                                            size='s'
                                            fill
                                            style={{
                                                width: '33%',
                                                marginLeft: '5px',
                                                backgroundColor: '#625046',
                                                border: 'transparent',
                                                fontSize: '14px',
                                                fontFamily: 'Metropolis-Bold',
                                            }}
                                            disabled={isEmpty(billingItem) && isEmpty(previousOrders)}
                                            onClick={splitModalOpen}
                                        >
                                            SPLIT BILL
                                        </EuiButton>

                                        <EuiButton
                                            size='s'
                                            fill
                                            style={{
                                                width: '33%',
                                                marginLeft: '5px',
                                                backgroundColor: '#625046',
                                                border: 'transparent',
                                                fontSize: '14px',
                                                fontFamily: 'Metropolis-Bold',
                                            }}
                                            // disabled={!isEmpty(previousOrders) || !isEmpty(billingItem)}
                                            onClick={() => setDiscountFlyout(true)}
                                        >
                                            Discount
                                        </EuiButton>

                                        <div style={{ width: '33%', marginLeft: '5px' }}>
                                            <EuiButton
                                                size='s'
                                                fill
                                                fullWidth={true}
                                                style={{
                                                    backgroundColor: '#2100ff',
                                                    border: 'transparent',
                                                    fontSize: '14px',
                                                    fontFamily: 'Metropolis-Bold',
                                                }}
                                                disabled={isEmpty(billingItem) && isEmpty(previousOrders)}
                                                onClick={updatePrintStatus}
                                            >
                                                PRINT BILL
                                            </EuiButton>
                                            <div style={{ display: 'none' }}>
                                                <ReactToPrint
                                                    copyStyles={false}
                                                    trigger={() => (
                                                        <EuiButton
                                                            id='printButton'
                                                            fill
                                                            fullWidth={true}
                                                            style={{
                                                                backgroundColor: '#2100ff',
                                                                border: 'transparent',
                                                                fontSize: '14px',
                                                                fontFamily: 'Metropolis-Bold',
                                                            }}
                                                            disabled={isEmpty(billingItem) && isEmpty(previousOrders)}
                                                            // disabled={isEmpty(billingItem)}
                                                            // onClick={updatePrintStatus}
                                                        >
                                                            PRINT BILL
                                                        </EuiButton>
                                                    )}
                                                    content={() => invoicePrinterRef.current}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {mode === 'dine_in' ? (
                                    <EuiButtonEmpty
                                        onClick={() => finishCustomerInfo && onStepChange('payment')}
                                        size='s'
                                        style={{ fontSize: '14px' }}
                                    >
                                        PROCEED TO PAYMENT
                                    </EuiButtonEmpty>
                                ) : (
                                    ''
                                )}
                            </>
                        ) : (
                            ''
                        )}

                        {/* {
                                (mode !== "dine_in"  && status.payment) ? (
                                    <EuiButton
                                        style={{ width: '100px', marginLeft: '5px',fontSize: '14px', fontFamily: 'Metropolis-Bold', }}
                                        // disabled={isEmpty(customerInformation)}
                                        onClick={completeOrder}
                                    >
                                        Complete
                                    </EuiButton>
                                ) : ''
                            } */}
                    </EuiFlexItem>
                ) : (
                    ''
                )}

                {/* <div style={{marginTop: '13px', display: 'flex', flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center', width: '97%'}}>

                    <div onClick={() => homeButtonCondition('home')} className="homeArrow" style={{marginLeft: '10px', color: 'white', cursor: 'pointer',width: mode==="dine_in" ? '16%': '21%'}}>
                        <p className="stepText" style={{paddingLeft: '0px'}}>
                            HOME
                        </p>
                    </div>

                    <div>
                    <div style={{marginRight: '10px', cursor: 'pointer'}} onClick={() => homeButtonCondition('home')}>
                        <BackArrow />
                    </div>
                    </div>

                    <div onClick={() => finishCustomerInfo && onStepChange('kot')} className="arrowBlock" style={{'--my-color-var': colorCondition('kot') ? '#ffce00' : '#d8d8d8', cursor: 'pointer', width: mode==="dine_in" ? '25%': '40%'}}>
                        <p className="stepText">
                            KOT
                        </p>
                    </div>
                    
                    <div onClick={() => finishCustomerInfo && onStepChange('bill')} className="arrowBlock" style={{'--my-color-var': colorCondition('bill') ? '#ffce00' : '#d8d8d8', cursor: 'pointer', width: mode==="dine_in" ? '16%': '21%'}}>
                        <p className="stepText">
                            BILL
                        </p>
                    </div>

                    <div onClick={() => finishCustomerInfo && onStepChange('payment')} className="arrowBlock" style={{'--my-color-var': colorCondition('payment') ? '#ffce00' : '#d8d8d8', cursor: 'pointer', width: mode==="dine_in" ? '25%': '40%'}}>
                        <p className="stepText">
                            PAY
                        </p>
                    </div>

                    {
                        mode === "dine_in" ? (
                            <div className="arrowBlock" style={{'--my-color-var': colorCondition('close') ? '#ffce00' : '#d8d8d8', cursor: 'pointer', width: mode==="dine_in" ? '25%': '40%'}} onClick={() => onStepChange('close')}>
                                <p className="stepText">
                                    CLOSE
                                </p>
                            </div>
                        ): ''
                    }
                </div> */}
            </div>
        </>
    );
};

export default BillingSectionButtonList;
