import React, { useState, useRef, useEffect } from 'react';
import {
    EuiFlyout,
    EuiFlyoutHeader,
    EuiTitle,
    EuiFlyoutBody,
    EuiFlexGroup,
    EuiFlyoutFooter,
    EuiFlexItem,
    EuiButtonEmpty,
    EuiFormRow,
    EuiFieldPassword,
    EuiButton,
    EuiSelect,
    EuiFieldNumber,
    EuiFieldText,
    EuiTextColor,
    EuiButtonIcon,
    EuiForm,
    EuiTabbedContent,
} from '@elastic/eui';
import API from '../../api/axios/API';
import { useDispatch } from 'react-redux';
import * as types from '../../reduxStore/types/billing';
import { Controller, useForm } from 'react-hook-form';
import { isEmpty } from 'lodash';
import CouponCodeTab from './couponCodeTab';
import OtherOfferTab from './otherOffersTab';

const DiscountFlyout = (props) => {
    const {
        setDiscountFlyout,
        subTotal,
        setValueBllingSection,
        setOptionsValueBillingSection,
        setDiscountData,
        outletId,
        setCouponCodeData,
    } = props;

    const onCloseModal = () => {
        setDiscountFlyout(false);
    };

    const tabs = [
        {
            id: '1',
            name: 'Coupon Code',
            content: <CouponCodeTab outletId={outletId} setCouponCodeData={setCouponCodeData} />,
        },
        { id: '2', name: 'Other Discount', content: <OtherOfferTab {...props} /> },
    ];

    //tab state
    const [selectedTab, setSelectedTab] = useState(tabs[0]);

    return (
        <>
            <EuiFlyout ownFocus onClose={onCloseModal} aria-labelledby='flyoutTitle'>
                <EuiFlyoutHeader>
                    <EuiTitle size='m'>
                        <h2 id='flyoutTitle'>Discount</h2>
                    </EuiTitle>
                </EuiFlyoutHeader>
                <EuiFlyoutBody style={{ width: '100%' }}>
                    <EuiTabbedContent
                        tabs={tabs}
                        selectedTab={selectedTab}
                        onTabClick={(tab) => {
                            setSelectedTab(tab);
                        }}
                    />
                </EuiFlyoutBody>
                <EuiFlyoutFooter>
                    <EuiFlexGroup justifyContent='spaceBetween'>
                        <EuiFlexItem grow={false}>
                            <EuiButtonEmpty iconType='cross' onClick={onCloseModal} flush='left'>
                                Close
                            </EuiButtonEmpty>
                        </EuiFlexItem>
                    </EuiFlexGroup>
                </EuiFlyoutFooter>
            </EuiFlyout>
        </>
    );
};

export default DiscountFlyout;
