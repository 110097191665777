import React, { useCallback, useEffect } from 'react';
import { EuiSpacer, EuiText } from '@elastic/eui';
import { isEmpty } from 'lodash';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { fetchOutletOrderingModes } from '../../../api/outlet/fetchOutletOrderingModes';
import { userHasPermission } from '../../../helper/auth';
import * as types from '../../../reduxStore/types/outlet';
import TextField from '../../../components/Form/TextField';
import { updateOutletOrderingModes as saveBillSettings } from '../../../components/settings/outlet-form/utils';
import AceEditor from 'react-ace';

const BillSetting = () => {
    const {
        control,
        handleSubmit,
        formState: { errors, isDirty },
        reset,
    } = useForm();
    const outletData = useSelector((state) => state?.outlet?.outletOrderingModes);
    const dispatch = useDispatch();
    const permissions = useSelector((state) => state.permission.permission);
    const outletId = useSelector((state) => state.outlet.selectedOutletId);

    const resetChanges = useCallback(() => {
        reset({
            legal_name: outletData?.legal_name,
            bill_header: outletData?.settings?.bill_header,
            bill_footer: outletData?.settings?.bill_footer,
        });
    }, [reset, outletData]);

    const getOrderingModes = useCallback(async () => {
        dispatch(fetchOutletOrderingModes(outletId, 'bill_payment_settings'));
    }, [dispatch, outletId]);

    useEffect(() => {
        getOrderingModes();
    }, [getOrderingModes]);

    const onSubmit = useCallback(
        async (data) => {
            if (data) {
                await saveBillSettings(outletId, data, 'bill_payment_settings').then((res) => {
                    if (!res?.isError) {
                        getOrderingModes();
                        dispatch({
                            type: types.SET_PORTAL_ACTIVE,
                            payload: {
                                portalActive: {},
                            },
                        });
                        dispatch({
                            type: types.SET_PORTAL_ACTIVE_TAB_SWITCHED,
                            payload: {
                                tabSwitched: false,
                            },
                        });
                    }
                });
            }
        },
        [dispatch, getOrderingModes, outletId]
    );

    useEffect(() => {
        if (!isEmpty(outletData)) {
            resetChanges();
        }
    }, [outletData, resetChanges, outletId]);

    useEffect(() => {
        if (!userHasPermission(permissions, '/outlet-settings', 'write')) return;

        if (isDirty) {
            dispatch({
                type: 'set-portal-active',
                payload: {
                    portalActive: {
                        resetChanges: resetChanges,
                        submit: handleSubmit(onSubmit),
                    },
                },
            });
        } else {
            dispatch({
                type: 'set-portal-active',
                payload: {
                    portalActive: {},
                },
            });
            dispatch({
                type: 'set-portal-active-tab-switched',
                payload: {
                    tabSwitched: false,
                },
            });
        }
    }, [isDirty]);

    return (
        <>
            <TextField
                label='Legal Name'
                placeholder='Enter Legal Name'
                errors={errors}
                name={`legal_name`}
                control={control}
            />{' '}
            <EuiSpacer />
            <EuiText size='s' style={{ fontWeight: 'bold', fontSize: 12 }}>
                Bill Header
            </EuiText>
            <Controller
                control={control}
                name='bill_header'
                render={({ field }) => {
                    return (
                        <AceEditor
                            style={codeEditorStyles}
                            placeholder='Add Bill Header'
                            mode='plain_text'
                            theme='github'
                            onChange={field.onChange}
                            value={field.value ? field.value : ''}
                            name='bill_header'
                            height='180px'
                            showGutter={false}
                            wrapEnabled={true}
                            highlightActiveLine={false}
                            editorProps={{ $blockScrolling: true }}
                            enableBasicAutocompletion={true}
                            enableLiveAutocompletion={true}
                            setOptions={{
                                enableSnippets: true,
                                fontSize: '10pt',
                            }}
                        />
                    );
                }}
            />
            <EuiSpacer />
            <EuiText size='s' style={{ fontWeight: 'bold', fontSize: 12 }}>
                Bill Footer
            </EuiText>
            <Controller
                control={control}
                name='bill_footer'
                render={({ field }) => {
                    return (
                        <AceEditor
                            style={codeEditorStyles}
                            placeholder='Add Bill Footer'
                            mode='plain_text'
                            theme='github'
                            onChange={field.onChange}
                            value={field.value ? field.value : ''}
                            name='bill_footer'
                            height='180px'
                            showGutter={false}
                            wrapEnabled={true}
                            highlightActiveLine={false}
                            editorProps={{ $blockScrolling: true }}
                            enableBasicAutocompletion={true}
                            enableLiveAutocompletion={true}
                            setOptions={{
                                enableSnippets: true,
                                fontSize: '10pt',
                            }}
                        />
                    );
                }}
            />
        </>
    );
};

export default React.memo(BillSetting);
export const codeEditorStyles = {
    border: `1px solid rgb(17 43 134 / 10%)`,
    borderRadius: '6px',
    background: '#fbfcfd',
};
