import React, { useCallback, useEffect, useState } from 'react';
import {
    EuiOverlayMask,
    EuiButton,
    EuiModal,
    EuiFlyoutBody,
    EuiFlyoutFooter,
    EuiModalHeader,
    EuiModalHeaderTitle,
    EuiFlexGroup,
    EuiFlexItem,
    EuiButtonIcon,
    EuiFormRow,
    EuiSelect,
    EuiButtonEmpty,
    EuiHorizontalRule,
    EuiForm,
    EuiEmptyPrompt,
    EuiFlyout,
    EuiFlyoutHeader,
} from '@elastic/eui';
import { useDispatch, useSelector } from 'react-redux';
import { useFieldArray, Controller, useWatch } from 'react-hook-form';
import FieldArrayTextField from '../../Form/FieldArrayTextField';
import TextField from '../../Form/TextField';
import TextAreaField from '../../Form/TextAreaField';
import * as types from '../../../reduxStore/types/menu';
import ButtonGroup from '../../Form/ButtonGroup';
import { useForm } from 'react-hook-form';
import { isEmpty } from 'lodash';
import VariantExpose from './VariantExpose';
import VariantPricing from './VariantPricing';
import CheckBoxField from '../../Form/CheckBoxField';
import { SET_SAVED_VARIANT_GROUP } from '../Store/MenuTypes';
import VariantImage from './VariantImage';

const VariantModal = (props) => {
    const { setVaraintModalState, editItem, isOutletMenu } = props;
    const availableLanguages = useSelector((state) => state.language.availableLanguages);
    const variantGroup = useSelector((state) => state.menu.variantGroup);
    const variantGroupTranslations = useSelector((state) => state.menu.variantGroupTranslations);
    const variantModalSaved = useSelector((state) => state.menu.variantModalSaved);
    const buttonDisable = useSelector((state) => state.menu.buttonDisable);
    const variantGroupReducer = useSelector((state) => state.menu.variantGroup);
    const recentlySavedData = useSelector((state) => state.menuReducer.savedVariantGroup);
    const [filteredModes, setFilteredModes] = useState([]);
    const dispatch = useDispatch();

    const {
        handleSubmit,
        control,
        watch,
        reset,
        setValue,

        formState: { errors },
    } = useForm({});

    const {
        fields: fieldsVariant,
        append: appendVariant,
        remove: removeVariant,
    } = useFieldArray({
        control,
        name: 'variantGroup',
    });

    const groups = useWatch({
        control,
        name: 'variantGroup',
    });

    const languageId = useSelector((state) => state.language.languageId);

    useEffect(() => {
        let isOrderingModeSelected = [];
        watch('variantGroup')?.filter((group) => {
            group.ordering_modes.filter((orderingMode) => isOrderingModeSelected.push({ ...orderingMode, ...group }));
        });
        const filteredModes = isOrderingModeSelected?.reduce(
            (acc, curr) => (acc?.find((mode) => mode?.id === curr?.id) ? acc : [...acc, curr]),
            []
        );
        setFilteredModes([...filteredModes]);
    }, [groups]);

    useEffect(() => {
        if (!isEmpty(editItem) && editItem.item_id != 'newId' && !variantModalSaved && !variantGroupReducer?.length) {
            let editDefaultValue = {};
            let variantOptionArray = [];
            if (editItem.variants && editItem.variants.length) {
                editDefaultValue['variant_group_id'] = editItem.variants[0].id;
                editDefaultValue['variant_group_title'] = editItem.variants[0].translations.title[languageId];
                editDefaultValue['variant_group_description'] =
                    editItem.variants[0].translations.description[languageId];
                editDefaultValue['show_agmark'] = editItem.variants[0].show_agmark ? 1 : 0;

                for (let key in editItem.variants[0].translations.title) {
                    editDefaultValue[`variant_group_title-${key}`] = editItem.variants[0].translations.title[key];
                }

                for (let key in editItem.variants[0].translations.description) {
                    editDefaultValue[`variant_group_description-${key}`] =
                        editItem.variants[0].translations.description[key];
                }

                editItem.variants[0].options.map((option, optionIndex) => {
                    let isEnabled = [];
                    let isDisabled;
                    if (option.ordering_modes?.length) {
                        option.ordering_modes.forEach((orderingMode, modeIdx) => {
                            // editDefaultValue[`variantGroup[${optionIndex}].ordering_modes[${modeIdx}].price`] =
                            //     orderingMode.price;
                            // editDefaultValue[`variantGroup[${optionIndex}].ordering_modes[${modeIdx}].gst_slab`] =
                            //     orderingMode.gst_slab ? orderingMode.gst_slab : 0;
                            // editDefaultValue[
                            //     `variantGroup[${optionIndex}].ordering_modes[${modeIdx}].packaging_charge`
                            // ] = orderingMode.packaging_charge ? orderingMode.packaging_charge : 0;
                            // editDefaultValue[`variantGroup[${optionIndex}].ordering_modes[${modeIdx}].compare_price`] =
                            //     orderingMode.compare_price ? orderingMode.compare_price : 0;
                        });

                        const same_price = option.ordering_modes[0].price;
                        const same_compare_price = option.ordering_modes[0].compare_price;
                        const same_packaging = option.ordering_modes[0].packaging_charge;
                        const same_gst_slab = option.ordering_modes[0].gst_slab;
                        for (let index = 0; index < option.ordering_modes.length; index++) {
                            const element = option.ordering_modes[index];
                            if (
                                same_price == element.price &&
                                same_packaging == element.packaging_charge &&
                                same_gst_slab == element.gst_slab &&
                                same_compare_price == element.compare_price
                            ) {
                                isEnabled.push('enabled');
                            } else {
                                isEnabled.push('disabled');
                            }
                        }
                    }

                    isDisabled = isEnabled?.find((status) => status === 'disabled') === 'disabled';
                    for (const [modeIdx, element] of option.ordering_modes?.entries()) {
                        editDefaultValue[
                            `${element.restaurant_ordering_mode_name}_${element.restaurant_ordering_mode_id}_${optionIndex}`
                        ] = true;
                        // variantGroup[${optionIndex}].ordering_modes[${modeIdx}].serves_unit
                        // editDefaultValue[`variantGroup[${optionIndex}].ordering_modes[${modeIdx}].quantity`] =
                        //     element.quantity;
                        // editDefaultValue[`variantGroup[${optionIndex}].ordering_modes[${modeIdx}].quantity_unit_id`] =
                        //     element.quantity_unit_id;
                        // editDefaultValue[`variantGroup[${optionIndex}].ordering_modes[${modeIdx}].serves`] =
                        //     element.serves;
                        // editDefaultValue[`variantGroup[${optionIndex}].ordering_modes[${modeIdx}].serves_unit`] =
                        //     element.serves_unit;
                    }
                    let innerObj = {
                        compare_price: option?.ordering_modes?.[0].compare_price,
                        price: option?.ordering_modes?.[0].price,
                        packaging_charge: option?.ordering_modes?.[0].packaging_charge,
                        gst_slab: option?.ordering_modes?.[0].gst_slab,

                        quantity_unit_id: option?.ordering_modes?.[0].quantity_unit_id,
                        quantity: option?.ordering_modes?.[0].quantity,
                        serves: option?.ordering_modes?.[0].serves,
                        serves_unit: option?.ordering_modes?.[0].serves_unit,

                        food_type: option.food_type,
                        id: option.id,
                        image_url: option.image_url,
                        // set the ordering modes for options
                        ordering_modes: option.ordering_modes,
                        is_enabled_same_price_details_for_all_ordering_modes: isDisabled ? true : false,
                    };

                    editDefaultValue['quantity_unit_id'] = option?.ordering_modes?.[0].quantity_unit_id;
                    editDefaultValue['quantity'] = option?.ordering_modes?.[0].quantity;
                    editDefaultValue['serves'] = option?.ordering_modes?.[0].serves;
                    editDefaultValue['serves_unit'] = option?.ordering_modes?.[0].serves_unit;

                    for (let key in option.translations.title) {
                        innerObj[`variant_title-${key}`] = option.translations.title[key];
                    }

                    variantOptionArray.push(innerObj);
                });
            }

            reset({
                ...editDefaultValue,
                variantGroup: variantOptionArray.length
                    ? variantOptionArray
                    : [
                          {
                              title: '',
                              description: '',
                              price: editItem.price,
                              packaging_charge: editItem.packaging_charge,
                              compare_price: editItem.compare_price,
                              gst_slab: editItem.gst_slab,
                              food_type: editItem.food_type,
                              ordering_modes: [],
                              is_enabled_same_price_details_for_all_ordering_modes: false,
                          },
                          {
                              title: '',
                              description: '',
                              price: '',
                              packaging_charge: '',
                              compare_price: '',
                              gst_slab: '0',
                              food_type: 'vegetarian',
                              ordering_modes: [],
                              is_enabled_same_price_details_for_all_ordering_modes: false,
                          },
                      ],
            });
        } else {
            reset({
                show_agmark: true,
                variantGroup: [
                    {
                        title: '',
                        description: '',
                        price: '',
                        packaging_charge: '',
                        compare_price: '',
                        gst_slab: '0',
                        food_type: 'vegetarian',
                        ordering_modes: [],
                        is_enabled_same_price_details_for_all_ordering_modes: false,
                    },
                    {
                        title: '',
                        description: '',
                        price: '',
                        packaging_charge: '',
                        compare_price: '',
                        gst_slab: '0',
                        food_type: 'vegetarian',
                        ordering_modes: [],
                        is_enabled_same_price_details_for_all_ordering_modes: false,
                    },
                ],
            });
        }
    }, [editItem]);

    useEffect(() => {
        if (editItem.variants && editItem.variants.length && variantGroup.length && isEmpty(recentlySavedData)) {
            reset({
                variantGroup: variantGroup,
                ...variantGroupTranslations,
            });
        } else if (!isEmpty(recentlySavedData)) {
            let editDefaultValue = {
                variantGroup: {
                    ordering_modes: [],
                },
            };
            let translationObj = {};

            availableLanguages.map((language) => {
                translationObj[`variant_group_title-${language.language_id}`] =
                    recentlySavedData[`variant_group_title-${language.language_id}`];
                translationObj[`variant_group_description-${language.language_id}`] =
                    recentlySavedData[`variant_group_description-${language.language_id}`];
            });
            let variantOptionArray = [];
            for (const [index, data] of recentlySavedData?.variantGroup.entries()) {
                let obj = {
                    compare_price: data?.ordering_modes?.[0]?.compare_price,
                    price: data?.ordering_modes?.[0]?.price,
                    packaging_charge: data?.ordering_modes?.[0]?.packaging_charge,
                    gst_slab: data?.ordering_modes?.[0]?.gst_slab,
                    food_type: data?.food_type,
                    id: data?.id,
                    image_url: data?.image_url,
                    // set the ordering modes for options
                    ordering_modes: data?.ordering_modes,
                    is_enabled_same_price_details_for_all_ordering_modes:
                        data?.is_enabled_same_price_details_for_all_ordering_modes,
                };
                for (const key of availableLanguages) {
                    obj[`variant_title-${key.language_id}`] = data[`variant_title-${key.language_id}`];
                }

                for (const orderingMode of data.ordering_modes) {
                    editDefaultValue[
                        `${orderingMode.restaurant_ordering_mode_name}_${orderingMode.restaurant_ordering_mode_id}_${index}`
                        // element.restaurant_ordering_mode_name
                    ] = true;
                }
                variantOptionArray.push(obj);
                editDefaultValue['variant_group_id'] = data.id;
            }

            reset({
                ...translationObj,
                ...editDefaultValue,
                variantGroup: variantOptionArray,
                show_agmark: recentlySavedData.show_agmark ? 1 : 0,
            });
        }
    }, [
        availableLanguages,
        editItem.variants,
        recentlySavedData,
        reset,
        variantGroup,
        variantGroupTranslations,
        variantModalSaved,
    ]);

    const onChangeFoodType = useCallback(
        (value, name) => {
            setValue(name, value);
        },
        [setValue]
    );

    const onRemove = useCallback(
        (index) => {
            removeVariant(index);
        },
        [removeVariant]
    );

    const onAppend = useCallback(() => {
        appendVariant({
            ordering_modes: [],
        });
    }, [appendVariant]);

    const closeModal = useCallback(() => {
        setVaraintModalState(false);
    }, [setVaraintModalState]);

    const onSubmit = useCallback(
        (data) => {
            if (data.variantGroup) {
                for (let [index, group] of data?.variantGroup?.entries()) {
                    if (group.is_enabled_same_price_details_for_all_ordering_modes) {
                        group?.ordering_modes.forEach((orderMode) => {
                            orderMode.restaurant_ordering_mode_id = orderMode?.id
                                ? orderMode.id
                                : orderMode?.restaurant_ordering_mode_id
                                ? orderMode.restaurant_ordering_mode_id
                                : null;
                        });
                    } else {
                        group?.ordering_modes.forEach((orderMode) => {
                            orderMode['price'] = group.price;
                            orderMode['compare_price'] = group.compare_price;
                            orderMode['gst_slab'] = group.gst_slab;
                            orderMode['packaging_charge'] = group.packaging_charge;
                            orderMode['quantity_unit_id'] = group.quantity_unit_id;
                            orderMode['quantity'] = group.quantity;
                            orderMode['serves'] = group.serves;
                            orderMode['serves_unit'] = group.serves_unit;
                            orderMode.restaurant_ordering_mode_id = orderMode?.id
                                ? orderMode.id
                                : orderMode?.restaurant_ordering_mode_id
                                ? orderMode.restaurant_ordering_mode_id
                                : null;
                        });
                    }
                }
                dispatch({
                    type: types.SET_VARIANT_GROUP,
                    payload: {
                        variantGroup: data.variantGroup,
                    },
                });

                dispatch({
                    type: SET_SAVED_VARIANT_GROUP,
                    payload: data,
                });

                let translationObj = {};
                availableLanguages.map((language) => {
                    translationObj[`variant_group_title-${language.language_id}`] =
                        data[`variant_group_title-${language.language_id}`];
                    translationObj[`variant_group_description-${language.language_id}`] =
                        data[`variant_group_description-${language.language_id}`];
                });

                dispatch({
                    type: types.SET_VARIANT_GROUP_TRANSLATIONS,
                    payload: {
                        variantGroupTranslations: {
                            ...translationObj,
                            show_agmark: data.show_agmark,
                            variant_group_id: data.variant_group_id,
                        },
                    },
                });

                dispatch({
                    type: types.SET_VARIANT_MODAL_SAVED,
                    payload: {
                        variantModalSaved: true,
                    },
                });
            } else {
                dispatch({
                    type: types.SET_VARIANT_GROUP,
                    payload: {
                        variantGroup: [],
                    },
                });

                dispatch({
                    type: types.SET_VARIANT_GROUP_TRANSLATIONS,
                    payload: {
                        variantGroupTranslations: {},
                    },
                });

                dispatch({
                    type: types.SET_VARIANT_MODAL_SAVED,
                    payload: {
                        variantModalSaved: true,
                    },
                });

                reset({
                    variantGroup: [],
                });
            }

            closeModal();
        },
        [availableLanguages, closeModal, dispatch, reset]
    );

    return (
        <EuiForm component='form'>
            <EuiFlyout size={'l'} onClose={closeModal}>
                {/* <EuiModal style={{ width: 800 }} onClose={closeModal}> */}
                <EuiFlyoutHeader>
                    <EuiModalHeaderTitle>
                        {editItem.item_id != 'newId' ? (
                            <h1>Create variant for {editItem.translations.title[languageId]}</h1>
                        ) : (
                            ''
                        )}
                    </EuiModalHeaderTitle>
                </EuiFlyoutHeader>

                <EuiFlyoutBody>
                    {fieldsVariant.length ? (
                        <>
                            {availableLanguages.map((language) => (
                                <EuiFlexItem style={{ margin: '10px 0' }}>
                                    <TextField
                                        label={'Group title'}
                                        name={`variant_group_title-${language.language_id}`}
                                        placeholder='Group title'
                                        rules={{
                                            required: 'Please enter group title',
                                        }}
                                        control={control}
                                        errors={errors}
                                        watch={watch}
                                        prepend={language.language_label}
                                    />
                                </EuiFlexItem>
                            ))}

                            <EuiFlexItem style={{ display: 'none' }}>
                                <TextField
                                    label={''}
                                    name={`variant_group_id`}
                                    placeholder=''
                                    control={control}
                                    errors={errors}
                                    watch={watch}
                                />
                            </EuiFlexItem>

                            {availableLanguages.map((language) => (
                                <EuiFlexItem style={{ margin: '10px 0' }}>
                                    <TextAreaField
                                        label={'Group description'}
                                        name={`variant_group_description-${language.language_id}`}
                                        placeholder={`Group description - ${language.language_label}`}
                                        control={control}
                                        errors={errors}
                                        watch={watch}
                                        style={{ height: '70px' }}
                                        // prepend={language.language_label}
                                    />
                                </EuiFlexItem>
                            ))}

                            <ButtonGroup
                                idSelected={watch('show_agmark') ? 1 : 0}
                                color='primary'
                                control={control}
                                setValue={setValue}
                                name='show_agmark'
                                watch={watch}
                                label={'Show Agmark'}
                            />

                            <EuiHorizontalRule />

                            {fieldsVariant.map((field, index) => (
                                <EuiFlexGroup key={field.id} direction='column'>
                                    <b style={{ marginLeft: '12px' }}>Variant {index + 1}</b>
                                    <VariantExpose
                                        index={index}
                                        isOutletMenu={isOutletMenu}
                                        setValue={setValue}
                                        control={control}
                                        watch={watch}
                                        fieldVariant={field}
                                    />
                                    <EuiFlexItem>
                                        <CheckBoxField
                                            name={`variantGroup[${index}].is_enabled_same_price_details_for_all_ordering_modes`}
                                            label='Different Price for all ordering mode?'
                                            control={control}
                                            setValue={setValue}
                                            isDisabled={isOutletMenu}
                                        />
                                    </EuiFlexItem>
                                    <EuiFlexItem
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'space-between',
                                            alignItems: 'start',
                                        }}
                                    >
                                        <EuiFlexItem style={{ display: 'flex', flexDirection: 'column' }}>
                                            <EuiFlexItem>
                                                <EuiFormRow label='Food type'>
                                                    <Controller
                                                        render={() => (
                                                            <EuiSelect
                                                                options={foodType}
                                                                value={watch(`variantGroup[${index}].food_type`)}
                                                                onChange={(e) =>
                                                                    onChangeFoodType(
                                                                        e.target.value,
                                                                        `variantGroup[${index}].food_type`
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                        defaultValue={field.food_type ? field.food_type : 'vegetarian'}
                                                        name={`variantGroup[${index}].food_type`}
                                                        control={control}
                                                    />
                                                </EuiFormRow>
                                            </EuiFlexItem>
                                            {availableLanguages.map((language) => (
                                                <EuiFlexItem grow={4} style={{ marginTop: '10px' }}>
                                                    <FieldArrayTextField
                                                        error={
                                                            errors.variantGroup &&
                                                            errors.variantGroup.hasOwnProperty(index) &&
                                                            errors.variantGroup[index][
                                                                `variant_title-${language.language_id}`
                                                            ]
                                                                ? errors.variantGroup[index][
                                                                      `variant_title-${language.language_id}`
                                                                  ].message
                                                                : ''
                                                        }
                                                        isInvalid={
                                                            errors.variantGroup &&
                                                            errors.variantGroup.hasOwnProperty(index) &&
                                                            errors.variantGroup[index][
                                                                `variant_title-${language.language_id}`
                                                            ]
                                                                ? errors.variantGroup[index][
                                                                      `variant_title-${language.language_id}`
                                                                  ]
                                                                : ''
                                                        }
                                                        label={'Variant Title'}
                                                        name={`variantGroup[${index}].variant_title-${language.language_id}`}
                                                        placeholder='Variant title'
                                                        rules={{
                                                            required: 'Please enter variant title',
                                                        }}
                                                        control={control}
                                                        watch={watch}
                                                        prepend={language.language_label}
                                                        defaultValue={field[`variant_title-${language.language_id}`]}
                                                    />
                                                </EuiFlexItem>
                                            ))}
                                        </EuiFlexItem>

                                        <VariantPricing
                                            index={index}
                                            errors={errors}
                                            watch={watch}
                                            control={control}
                                            fieldVariant={field}
                                            setValue={setValue}
                                        />
                                    </EuiFlexItem>

                                    <EuiFlexItem style={{ display: 'none' }}>
                                        <FieldArrayTextField
                                            label={'id'}
                                            name={`variantGroup[${index}].id`}
                                            placeholder='id'
                                            control={control}
                                            watch={watch}
                                            defaultValue={field.id}
                                        />
                                    </EuiFlexItem>

                                    <EuiFlexItem>
                                        <VariantImage
                                            setValue={setValue}
                                            control={control}
                                            index={index}
                                            defaultValue={field.image_url}
                                        />
                                    </EuiFlexItem>

                                    {!isOutletMenu ? (
                                        <EuiButtonIcon
                                            style={{ width: '50px' }}
                                            onClick={() => onRemove(index)}
                                            title='Delete this variant'
                                            iconType='minusInCircleFilled'
                                            aria-labelledby='minusInCircleFilled'
                                        />
                                    ) : (
                                        ''
                                    )}

                                    <EuiHorizontalRule />
                                </EuiFlexGroup>
                            ))}
                        </>
                    ) : (
                        <EuiEmptyPrompt
                            iconType='editorStrike'
                            title={<h2>You have no Varinats</h2>}
                            body={'Click to + Add variants if you want to have variants.'}
                        />
                    )}

                    {!isOutletMenu ? (
                        <EuiFlexItem>
                            <EuiButtonEmpty style={{ width: '120px' }} onClick={() => onAppend()}>
                                + Add Variant
                            </EuiButtonEmpty>
                        </EuiFlexItem>
                    ) : (
                        ''
                    )}
                </EuiFlyoutBody>

                <EuiFlyoutFooter>
                    <EuiFlexGroup>
                        <EuiFlexItem>
                            <EuiButton onClick={closeModal}>Close</EuiButton>
                        </EuiFlexItem>
                        {!isOutletMenu ? (
                            <EuiFlexItem>
                                <EuiButton
                                    aria-labelledby='button'
                                    disabled={buttonDisable || watch('variantGroup')?.length !== filteredModes?.length}
                                    onClick={handleSubmit(onSubmit)}
                                    type='submit'
                                    fill
                                >
                                    Save
                                </EuiButton>
                            </EuiFlexItem>
                        ) : null}
                    </EuiFlexGroup>
                </EuiFlyoutFooter>
                {/* </EuiModal> */}
            </EuiFlyout>
        </EuiForm>
    );
};

const foodType = [
    { value: 'vegetarian', text: 'Veg' },
    { value: 'non-vegetarian', text: 'Non Veg' },
    { value: 'egg', text: 'Eggs' },
];

export default VariantModal;
