import { EuiFlexGroup, EuiFlexItem, EuiPanel, EuiSpacer, EuiText, EuiTitle } from '@elastic/eui';
import { isEmpty } from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';

const UnlinkedItems = ({ validationFailedItems }) => {
    const languageId = useSelector((state) => state.language.languageId);

    return (
        <>
            <EuiSpacer size='xs' />
            <EuiPanel>
                <EuiFlexGroup direction='column' gutterSize='xs' alignItems='flexStart' justifyContent='center'>
                    {validationFailedItems?.items && (
                        <EuiFlexItem>
                            <EuiTitle size='xs'>
                                <h3 style={{ color: '#FF6961' }}>Items are not linked</h3>
                            </EuiTitle>
                        </EuiFlexItem>
                    )}
                    {validationFailedItems?.category?.internal_name && (
                        <EuiFlexItem>
                            <EuiTitle size='s'>
                                <h1>Unlinked Item Category: {validationFailedItems.category.internal_name}</h1>
                            </EuiTitle>
                        </EuiFlexItem>
                    )}
                    {validationFailedItems?.items?.internal_name && (
                        <EuiFlexItem>
                            <EuiText>Unlinked Item: {validationFailedItems.items.internal_name}</EuiText>
                        </EuiFlexItem>
                    )}
                    {validationFailedItems?.variants && (
                        <EuiFlexItem>
                            <EuiTitle size='xs'>
                                <h3 style={{ color: '#FF6961' }}>Variants are not linked</h3>
                            </EuiTitle>
                        </EuiFlexItem>
                    )}
                    {validationFailedItems?.variants?.translations && (
                        <EuiFlexItem>
                            <EuiText>
                                Unlinked Variants: {validationFailedItems?.variants?.translations.title[languageId]}
                            </EuiText>
                        </EuiFlexItem>
                    )}
                    {!isEmpty(validationFailedItems?.addon) && (
                        <>
                            <EuiFlexItem>
                                <EuiTitle size='xs'>
                                    <h3 style={{ color: '#FF6961' }}>Addon is not linked</h3>
                                </EuiTitle>
                            </EuiFlexItem>
                            <EuiFlexItem>
                                <EuiTitle size='s'>
                                    <h1>
                                        Unlinked Addon Group:{' '}
                                        {validationFailedItems?.addon?.label
                                            ? validationFailedItems.addon.label
                                            : validationFailedItems?.addon?.internal_name}
                                    </h1>
                                </EuiTitle>
                            </EuiFlexItem>
                        </>
                    )}
                </EuiFlexGroup>
            </EuiPanel>
        </>
    );
};

export default React.memo(UnlinkedItems);
