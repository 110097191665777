import { EuiBottomBar, EuiButton, EuiButtonEmpty, EuiFlexItem, EuiPortal } from '@elastic/eui';
import { isEmpty } from 'lodash';
import React from 'react';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

const FormActionPortal = () => {
    const dispatch = useDispatch();
    const portalActive = useSelector((state) => state.outlet.portalActive);
    const submitLoader = useSelector((state) => state.outlet.submitLoader);

    const discardChanges = useCallback(() => {
        portalActive.resetChanges();
        dispatch({
            type: 'set-portal-active',
            payload: {
                portalActive: {},
            },
        });
    }, [dispatch, portalActive]);

    return (
        <>
            {!isEmpty(portalActive) ? (
                <EuiPortal>
                    <EuiBottomBar style={{ backgroundColor: 'white', zIndex: 999 }}>
                        <EuiFlexItem style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                            <EuiFlexItem
                                grow={2}
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'flex-end',
                                    justifyContent: 'flex-end',
                                }}
                            >
                                <EuiFlexItem style={{ marginRight: '15px' }}>
                                    <EuiButtonEmpty onClick={discardChanges} size='s' fill color='danger'>
                                        Discard changes
                                    </EuiButtonEmpty>
                                </EuiFlexItem>

                                <EuiFlexItem>
                                    <EuiButton
                                        isLoading={submitLoader}
                                        onClick={() => portalActive.submit()}
                                        size='s'
                                        fill
                                    >
                                        Save
                                    </EuiButton>
                                </EuiFlexItem>
                            </EuiFlexItem>
                        </EuiFlexItem>
                    </EuiBottomBar>
                </EuiPortal>
            ) : null}
        </>
    );
};

export default React.memo(FormActionPortal);
