import React from 'react';
import { MainLayout } from '../../components/layout/mainLayout/mainLayout';
import { LiveOrdersComponent } from '../../components/liveOrders/liveOrders';

const LiveOrdersPage = () => {
    return (
        <MainLayout isSingleScreen={true} backgroundColorChange={true}>
            <LiveOrdersComponent />
        </MainLayout>
    );
};

export default LiveOrdersPage;
