import API from '../axios/API';

export const itemSearch = (keyword) => {
    return async (dispatch, getState) => {
        let response;
        let { outlet, language, billing } = getState();

        try {
            response = await API.get(
                `${language.language}/restaurants/:restaurantId/outlets/${outlet.selectedOutletId}/categories/search?ordering_mode=${billing.mode}&keyword=${keyword}`
            );
        } catch (error) {
            response = error;
        }

        return response;
    };
};
