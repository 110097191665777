import { isEmpty } from 'lodash';

export const getErrorMesage = (amountError) => {
    let errorMessage = [];

    amountError.map((error, idx) => {
        errorMessage.push(<li key={idx}>{error}</li>);
    });

    return <ul>{errorMessage}</ul>;
};

export const getItem = (id, petPoojaItemList) => {
    let items = petPoojaItemList.filter((addon) => addon.itemid == id);

    if (items.length) {
        // let variants = items[0].variation.filter(variant => variant.variationid == id)
        return items[0];
    }
};

export const getTaxAmount = (item, petPoojaTaxes, price) => {
    let taxAmount = 0;
    if (item?.item_tax) {
        let taxArray = item.item_tax.split(',');
        let taxMatching = petPoojaTaxes.filter((tax) => {
            if (tax.taxtype == '1' && taxArray.includes(tax.taxid)) {
                taxAmount += (price * tax.tax) / 100;
            }

            return taxArray.includes(tax.taxid);
        });

        return { taxArrayObj: taxMatching, taxAmount };
    }

    return { taxArrayObj: [], taxAmount: taxAmount };
};

export const getLinkedPetpoojaAddonChoice = (choiceId, linkedItems, petPoojaAddonGroups) => {
    let filterData = linkedItems.filter(
        (item) => item.module_id == choiceId && item.module_name === 'restaurant_addon_groups_choice'
    );

    if (filterData.length) {
        let petpoojaData = [];
        petPoojaAddonGroups.map((item) => {
            item.addongroupitems.map((choice) => {
                if (choice.addonitemid == filterData[0].third_party_module_id) {
                    petpoojaData = choice;
                }
            });
        });

        if (!isEmpty(petpoojaData)) {
            return { ...petpoojaData, id: petpoojaData.addonitemid, label: petpoojaData.addonitem_name };
        }
    }

    return [];
};

export const getPetpoojaChoices = (id, linkedItems, petPoojaAddonGroups) => {
    let filterData = linkedItems.filter((item) => item.module_id == id);

    if (filterData.length) {
        let petpoojaData = petPoojaAddonGroups.filter((item) => {
            return item.addongroupid === filterData[0].third_party_module_id;
        });

        petpoojaData?.length &&
            petpoojaData[0]?.addongroupitems &&
            petpoojaData[0].addongroupitems.map((choice) => {
                choice.choice_id = choice.addonitemid;
                choice.label = choice.addonitem_name;
            });

        return petpoojaData?.length ? petpoojaData[0].addongroupitems : [];
    }

    return [];
};

export const getLinkedPetpoojaAddonGroup = (id, linkedItems, petPoojaAddonGroups) => {
    let filterData = linkedItems.filter((item) => item.module_id == id);

    if (filterData.length) {
        let petpoojaData = petPoojaAddonGroups.filter((item) => {
            return item.addongroupid == filterData[0].third_party_module_id;
        });

        return petpoojaData.length
            ? { ...petpoojaData[0], id: petpoojaData[0].addongroupid, label: petpoojaData[0].addongroup_name }
            : [];
    }

    return [];
};
