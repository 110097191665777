import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { EuiForm } from '@elastic/eui';
import FormGroupDescription from '../../../components/formGroupDescription';
import ComboBoxField from '../../../components/Form/ComboBoxField';
import { languages } from '../../../components/settings/allLanguages';
import { useDispatch, useSelector } from 'react-redux';
import { restaurantSettingsAPI } from '../../../api/restaurantSettings/restaurantSettingsAPI';
import * as types from '../../../reduxStore/types/outlet';
import { userHasPermission } from '../../../helper/auth';

const RestaurantLanguageSettings = (props) => {
    const { restaurantResponse, fetchRestaurant } = props;
    const {
        handleSubmit,
        control,
        watch,
        reset,
        setValue,

        formState: { isDirty, errors },
    } = useForm();

    const [selectedLanguages, setSelectedLanguages] = useState(watch('languages') ? watch('languages') : []);

    const availableLanguage = languages;
    const dispatch = useDispatch();
    const permissions = useSelector((state) => state.permission.permission);
    const restaurantDescriptions = useSelector((state) => state.auth.restaurantDescriptions);

    useEffect(() => {
        if (!userHasPermission(permissions, '/restaurant-settings', 'write')) return;

        if (isDirty) {
            dispatch({
                type: 'set-portal-active',
                payload: {
                    portalActive: {
                        resetChanges: resetChanges,
                        submit: handleSubmit(onSubmit),
                    },
                },
            });
        } else {
            dispatch({
                type: 'set-portal-active',
                payload: {
                    portalActive: {},
                },
            });
        }
    }, [isDirty]);

    useEffect(() => {
        if (restaurantResponse && restaurantResponse.restaurant) {
            resetChanges();
        }
    }, [restaurantResponse]);

    const resetChanges = () => {
        let defaultValue = {};
        let restaurantData = restaurantResponse.restaurant;
        defaultValue.languages = restaurantData.languages.map((item, index) => ({
            label: item.language_label,
            language: item.language,
            language_label: item.language_label,
        }));

        reset({
            ...defaultValue,
        });
    };

    const onChangeLanguage = (selectedLanguage) => {
        setSelectedLanguages(selectedLanguage);
        setValue('languages', selectedLanguage);
    };

    const onSubmit = (data) => {
        dispatch(restaurantSettingsAPI('language_settings', data)).then((res) => {
            if (res.success) {
                dispatch({
                    type: types.SET_PORTAL_ACTIVE,
                    payload: {
                        portalActive: {},
                    },
                });
                dispatch({
                    type: types.SET_PORTAL_ACTIVE_TAB_SWITCHED,
                    payload: {
                        tabSwitched: false,
                    },
                });
                fetchRestaurant();
            } else {
                alert(res.message);
            }
        });
    };

    return (
        <>
            <EuiForm component='form' onSubmit={handleSubmit(onSubmit)}>
                <FormGroupDescription
                    title={restaurantDescriptions?.languages_in_app?.display_name}
                    description={restaurantDescriptions?.languages_in_app?.description}
                >
                    <ComboBoxField
                        options={availableLanguage}
                        selectedOptions={watch('languages') ? watch('languages') : []}
                        onChange={onChangeLanguage}
                        errors={errors}
                        label='Select languages'
                        name='languages'
                        placeholder='Select one or more languages for the application'
                        rules={{
                            validate: (value) =>
                                (watch('languages') && watch('languages').length > 0) ||
                                'Please select atleast one language',
                        }}
                        control={control}
                    />
                </FormGroupDescription>
            </EuiForm>
        </>
    );
};

export default RestaurantLanguageSettings;
