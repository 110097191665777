import { EuiComboBox, EuiFormRow, EuiSpacer, EuiTextColor } from '@elastic/eui';
import { isEmpty } from 'lodash';
import React, { useCallback, useState } from 'react';
import { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { getErrorMesage, getItem, getTaxAmount } from './utils/utils';

const VariantLinkOrderingModes = ({
    optionOrderingMode,
    optionIndex,
    category_id,
    fieldIndex,
    selectedParentOption,
    option,
    uniqueVariantOptions,
    modeIndex,
}) => {
    const petPoojaItems = useSelector((state) => state.petPoojaReducer.petPoojaItems);
    const {
        control,
        watch,
        formState: { isDirty },
        setValue,
    } = useFormContext();
    const linkedItems = useSelector((state) => state.petPoojaReducer.petPoojaLinkedItems);
    const [amountError, setAmountError] = useState([]);
    const petPoojaTaxes = useSelector((state) => state.petPoojaReducer.petPoojaTaxes);
    const petPoojaRestaurant = useSelector((state) => state.petPoojaReducer.petPoojaRestaurant);

    useEffect(() => {
        if (!isDirty) {
            setAmountError([]);
        }
    }, [isDirty]);

    const checkVariantValidation = useCallback(
        (selectedOption) => {
            let isItemValid = false;
            if (!selectedOption?.length) {
                isItemValid = true;
                setAmountError([]);
            } else {
                let itemResponse = getItem(selectedParentOption[0]?.id, petPoojaItems);
                let { taxArrayObj, taxAmount } = getTaxAmount(itemResponse, petPoojaTaxes, option.price);
                selectedOption[0] = {
                    ...selectedOption[0],
                    tax: taxArrayObj,
                };
                let fabSystemTaxAmount = (option.price * option.gst_slab) / 100;

                let errorCheck = false;
                let errorArray = [];
                for (const orderingMode of option?.ordering_modes) {
                    if (orderingMode?.restaurant_ordering_mode_id === optionOrderingMode.restaurant_ordering_mode_id) {
                        if (
                            parseFloat(orderingMode.price).toFixed(2) !== parseFloat(selectedOption[0].price).toFixed(2)
                        ) {
                            errorCheck = true;
                            errorArray.push(
                                `Fab system price ${orderingMode.price} ≠ Pet Pooja price ${selectedOption[0].price}`
                            );
                        }

                        if (
                            orderingMode.packaging_charge != selectedOption[0].item_packingcharges &&
                            petPoojaRestaurant?.[0]?.details?.packaging_applicable_on === 'ITEM'
                        ) {
                            errorCheck = true;
                            errorArray.push(
                                `Packaging Fab System ${orderingMode.packaging_charge} ≠ Pacakging Pet Pooja ${selectedOption[0].item_packingcharges}`
                            );
                        }

                        if (fabSystemTaxAmount != taxAmount) {
                            errorCheck = true;
                            errorArray.push(`Tax Fab System ${fabSystemTaxAmount} ≠ Tax Pet Pooja ${taxAmount}`);
                        }
                    }
                }

                setAmountError(errorArray);

                if (amountError || errorCheck) {
                    isItemValid = false;
                } else {
                    isItemValid = true;
                }

                return isItemValid;
            }
        },
        [
            amountError,
            option,
            optionOrderingMode.restaurant_ordering_mode_id,
            petPoojaItems,
            petPoojaRestaurant,
            petPoojaTaxes,
            selectedParentOption,
        ]
    );

    const handleVariantLinkChange = useCallback(
        (selectedValue, item) => {
            if (selectedValue?.length) {
                selectedValue[0].module_id = option.id;
                selectedValue[0].restaurant_ordering_mode_id = optionOrderingMode.restaurant_ordering_mode_id;
            }

            setValue(
                `item_${category_id}[${fieldIndex}].variants[0].options[${optionIndex}].ordering_modes[${modeIndex}].selected_item`,
                selectedValue,
                {
                    shouldDirty: true,
                }
            );

            // UNLINK LOGIC
            if (!selectedValue?.length && item?.length && linkedItems?.length) {
                const currentRemovedItem = item[0];
                for (const linkedItem of linkedItems) {
                    if (
                        (linkedItem?.third_party_module_id === currentRemovedItem?.variant_item_id ||
                            linkedItem?.third_party_module_id === currentRemovedItem?.variationid) &&
                        linkedItem?.restaurant_ordering_mode_id === currentRemovedItem?.restaurant_ordering_mode_id &&
                        currentRemovedItem?.module_id === linkedItem?.module_id
                    ) {
                        const currentRemovedItemWithOption = {
                            ...currentRemovedItem,
                            ...linkedItem,
                            module_name: 'variant_option',
                        };
                        if (watch('menu_unlinks')?.length) {
                            setValue(
                                'menu_unlinks',
                                [
                                    ...watch('menu_unlinks'),
                                    { ...currentRemovedItem, ...linkedItem },
                                    currentRemovedItemWithOption,
                                ],
                                {
                                    shouldDirty: true,
                                }
                            );
                        } else {
                            setValue(
                                'menu_unlinks',
                                [{ ...currentRemovedItem, ...linkedItem }, currentRemovedItemWithOption],
                                {
                                    shouldDirty: true,
                                }
                            );
                        }
                    }
                }
            }

            if (selectedValue?.length && watch('menu_unlinks')?.length) {
                const removeItemFromUnlinkState = watch('menu_unlinks')?.filter((menu_unlink) => {
                    return !(
                        menu_unlink.variationid === selectedValue[0].variationid &&
                        menu_unlink.restaurant_ordering_mode_id === optionOrderingMode.restaurant_ordering_mode_id &&
                        selectedValue[0].module_id === menu_unlink.module_id
                    );
                });
                setValue('menu_unlinks', removeItemFromUnlinkState, { shouldDirty: true });
            }
        },
        [category_id, fieldIndex, linkedItems, modeIndex, option?.id, optionIndex, setValue, watch, optionOrderingMode]
    );

    return (
        <React.Fragment key={optionOrderingMode.restaurant_ordering_mode_id}>
            <EuiFormRow label={optionOrderingMode.restaurant_ordering_mode_name}>
                <Controller
                    control={control}
                    name={`item_${category_id}[${fieldIndex}].variants[0].options[${optionIndex}].ordering_modes[${modeIndex}].selected_item`}
                    rules={{
                        validate: {
                            positive: (selectedOption) => {
                                return checkVariantValidation(selectedOption);
                            },
                        },
                    }}
                    render={({ field }) => {
                        return (
                            <EuiComboBox
                                isClearable={true}
                                singleSelection={true}
                                selectedOptions={field.value}
                                onBlur={field.onBlur}
                                onChange={(selectedValue) => {
                                    field.onChange(selectedValue);
                                    handleVariantLinkChange(selectedValue, field.value);
                                }}
                                options={
                                    watch(
                                        `item_${category_id}[${fieldIndex}.ordering_modes[${modeIndex}].selected_item]`
                                    )?.length
                                        ? uniqueVariantOptions
                                        : []
                                }
                                placeholder={
                                    watch(
                                        `item_${category_id}[${fieldIndex}.ordering_modes[${modeIndex}].selected_item]`
                                    )?.length
                                        ? uniqueVariantOptions?.length
                                            ? 'Select variant'
                                            : 'No variant found for this item '
                                        : 'Select parent item first'
                                }
                            />
                        );
                    }}
                />
            </EuiFormRow>
            <EuiSpacer size='s' />
            {!isEmpty(amountError) ? <EuiTextColor color='danger'>{getErrorMesage(amountError)}</EuiTextColor> : ''}
            <EuiSpacer size='s' />
        </React.Fragment>
    );
};

export default React.memo(VariantLinkOrderingModes);
