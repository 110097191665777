import React from 'react';
import {
    EuiButton,
    EuiModal,
    EuiModalBody,
    EuiModalFooter,
    EuiModalHeader,
    EuiModalHeaderTitle,
    EuiOverlayMask,
    EuiFlexGroup,
    EuiFlexItem,
    htmlIdGenerator,
    EuiCard,
} from '@elastic/eui';
import { ReactComponent as Moped } from '../../assets/img/moped.svg';

export const UserInfoModal = (props) => {
    /* const [state, setState] = useState({
    apiDataLoaded: false
  }) */
    const closeModal = () => {
        props.closeUserInfoModal();
    };
    /* useEffect(() => {
    /!* getUserInfo(1, 34) *!/
  }, [props.userId]) */

    return (
        <div key={htmlIdGenerator()()}>
            {props.userModalOpen && (
                <EuiOverlayMask>
                    <EuiModal
                        onClose={() => {
                            closeModal();
                        }}
                        // style={{zIndex: 6500}}
                    >
                        {/* <EuiModalHeader>
                            <EuiModalHeaderTitle>Customer Information</EuiModalHeaderTitle>
                        </EuiModalHeader> */}

                        <EuiModalBody>
                            <EuiFlexGroup responsive={false}>
                                <EuiFlexItem>
                                    {/* backgroundColor: themeMode === 'light' ? '#D0F2EB' : '#636a69' */}
                                    <EuiCard
                                        style={{ boxShadow: 'none' }}
                                        display='plain'
                                        titleSize='xs'
                                        layout='horizontal'
                                        paddingSize='s'
                                        title={
                                            <div
                                                style={{ display: 'flex', alignItems: 'center', flexDirection: 'row' }}
                                            >
                                                <div style={{ marginLeft: '10px' }}>
                                                    <span>Customer Information</span>
                                                </div>
                                            </div>
                                        }
                                        description={
                                            <table>
                                                <tr style={{ borderBottom: '1px solid #D3DAE6' }}>
                                                    <td style={{ borderRight: '1px solid #D3DAE6' }}>Name </td>
                                                    <td>
                                                        <b>{props.userName}</b>
                                                    </td>
                                                </tr>
                                                {props.inputAddess ? (
                                                    <tr style={{ borderBottom: '1px solid #D3DAE6' }}>
                                                        <td style={{ borderRight: '1px solid #D3DAE6' }}>
                                                            Address (user input)
                                                        </td>
                                                        <td>
                                                            <b>{props.inputAddess}</b>
                                                        </td>
                                                    </tr>
                                                ) : (
                                                    ''
                                                )}

                                                {props.locationPin ? (
                                                    <tr style={{ borderBottom: '1px solid #D3DAE6' }}>
                                                        <td style={{ borderRight: '1px solid #D3DAE6' }}>
                                                            Location Pin
                                                        </td>
                                                        <td>
                                                            <b>{props.locationPin}</b>
                                                        </td>
                                                    </tr>
                                                ) : (
                                                    ''
                                                )}

                                                <tr style={{ borderBottom: '1px solid #D3DAE6' }}>
                                                    <td style={{ borderRight: '1px solid #D3DAE6' }}>Phone Number </td>
                                                    <td>
                                                        <b>
                                                            {props.countryCode}
                                                            {props.phoneNumber}
                                                        </b>
                                                    </td>
                                                </tr>

                                                {props.email ? (
                                                    <tr style={{ borderBottom: '1px solid #D3DAE6' }}>
                                                        <td style={{ borderRight: '1px solid #D3DAE6' }}>Email </td>
                                                        <td>
                                                            <b>{props.email}</b>
                                                        </td>
                                                    </tr>
                                                ) : (
                                                    ''
                                                )}

                                                {props.userInfo && (
                                                    <>
                                                        <tr style={{ borderBottom: '1px solid #D3DAE6' }}>
                                                            <td style={{ borderRight: '1px solid #D3DAE6' }}>
                                                                Block Number
                                                            </td>
                                                            <td>
                                                                <b>{props.userInfo.block_number}</b>
                                                            </td>
                                                        </tr>
                                                        <tr style={{ borderBottom: '1px solid #D3DAE6' }}>
                                                            <td style={{ borderRight: '1px solid #D3DAE6' }}>
                                                                Address
                                                            </td>
                                                            <td>
                                                                <b>{props.userInfo.location_name}</b>
                                                            </td>
                                                        </tr>
                                                        <tr style={{ borderBottom: '1px solid #D3DAE6' }}>
                                                            <td style={{ borderRight: '1px solid #D3DAE6' }}>
                                                                Region{' '}
                                                            </td>
                                                            <td>
                                                                <b>{props.userInfo.region}</b>
                                                            </td>
                                                        </tr>
                                                        <tr style={{ borderBottom: '1px solid #D3DAE6' }}>
                                                            <td style={{ borderRight: '1px solid #D3DAE6' }}>City </td>
                                                            <td>
                                                                <b>{props.userInfo.city}</b>
                                                            </td>
                                                        </tr>
                                                        <tr style={{ borderBottom: '1px solid #D3DAE6' }}>
                                                            <td style={{ borderRight: '1px solid #D3DAE6' }}>State </td>
                                                            <td>
                                                                <b>{props.userInfo.state}</b>
                                                            </td>
                                                        </tr>
                                                        {props.customerOrderCount ? (
                                                            <tr style={{ borderBottom: '1px solid #D3DAE6' }}>
                                                                <td style={{ borderRight: '1px solid #D3DAE6' }}>
                                                                    Customer order counts{' '}
                                                                </td>
                                                                <td>
                                                                    <b>{props.customerOrderCount}</b>
                                                                </td>
                                                            </tr>
                                                        ) : (
                                                            ''
                                                        )}

                                                        {props.lifeTimeValue ? (
                                                            <tr style={{ borderBottom: '1px solid #D3DAE6' }}>
                                                                <td style={{ borderRight: '1px solid #D3DAE6' }}>
                                                                    Life time value{' '}
                                                                </td>
                                                                <td>
                                                                    <b>{props.lifeTimeValue}</b>
                                                                </td>
                                                            </tr>
                                                        ) : (
                                                            ''
                                                        )}
                                                    </>
                                                )}
                                            </table>
                                        }
                                    />
                                </EuiFlexItem>
                                {/* <EuiFlexItem grow={false}>
                                    <EuiFlexGroup responsive={false} direction='column'>
                                        <EuiFlexItem grow={false}>
                                            <EuiText>
                                                <p>
                                                    Name: <b>{props.userName}</b>
                                                </p>
                                            </EuiText>
                                        </EuiFlexItem>
                                        <EuiFlexItem grow={false}>
                                            <EuiText>
                                                <p>
                                                    Address (user input): <b>{props.inputAddess}</b>
                                                </p>
                                            </EuiText>
                                        </EuiFlexItem>
                                        <EuiFlexItem grow={false}>
                                            <EuiText>
                                                <p />
                                            </EuiText>
                                        </EuiFlexItem>
                                        <EuiFlexItem grow={false}>
                                            <EuiText>
                                                <p>
                                                    Location Pin: <b>{props.locationPin}</b>
                                                </p>
                                            </EuiText>
                                        </EuiFlexItem>
                                        <EuiFlexItem grow={false}>
                                            <EuiText>
                                                <p>
                                                    Phone Number: <b>{props.phoneNumber}</b>
                                                </p>
                                            </EuiText>
                                        </EuiFlexItem>
                                    </EuiFlexGroup>
                                </EuiFlexItem> */}
                            </EuiFlexGroup>
                        </EuiModalBody>
                        <EuiModalFooter>
                            <EuiButton onClick={() => closeModal()} fill>
                                Close
                            </EuiButton>
                        </EuiModalFooter>
                    </EuiModal>
                </EuiOverlayMask>
            )}
        </div>
    );
};
