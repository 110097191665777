import { EuiFlexItem, EuiHorizontalRule, EuiText, htmlIdGenerator } from '@elastic/eui';
import { isEmpty } from 'lodash';
import moment from 'moment';
import React from 'react';
import { ReactComponent as RupeeImage } from '../../assets/img/rupeeImage.svg';

export default class TableOrdersBillPrint extends React.Component {
    getPaymentMethod = (mode) => {
        if (mode === 'CASH_ON_DELIVERY') {
            return 'Cash';
        } else {
            return 'Paid';
        }
    };

    customerAddress = () => {
        if (this.props.details?.[0].customer_address) {
            return (
                <EuiText key={htmlIdGenerator()()}>
                    Address: {this.props.details?.[0].customer_address.block_number},
                    {this.props.details?.[0].customer_address.land_mark},{this.props.details?.[0].customer_address.city}
                </EuiText>
            );
        }
    };

    orderingMode = () => {
        if (this.props.details?.[0].ordering_mode === 'delivery') {
            return 'Delivery';
        } else if (this.props.details?.[0].ordering_mode === 'takeaway') {
            return 'Pick Up';
        }

        return 'Dine In';
    };

    billSettingStrings = (string) => {
        let newLine = '';

        if (string) {
            newLine = string.split('\n');
        }

        return newLine;
    };

    renderItemRowsNew = (orderItem) => {
        let customizationTotal = 0;
        return (
            <React.Fragment key={htmlIdGenerator()()}>
                {/* <span style={{ height: 20, width: '100%', background: 'red' }}></span> */}
                <td key={htmlIdGenerator()()} style={{ paddingTop: '6px', fontSize: 14 }}>
                    {orderItem.item.translations.title[this.props.languageId]}
                    {!isEmpty(orderItem.variant_option_information) &&
                    !isEmpty(orderItem.variant_option_information.variant_group_information) ? (
                        <div>
                            - {orderItem.variant_option_information.translations.title[this.props.languageId]}[
                            {orderItem.variant_option_information.price.toFixed(2)}]
                        </div>
                    ) : (
                        ''
                    )}

                    {orderItem.customizations &&
                        orderItem.customizations.map((addons) => {
                            customizationTotal = customizationTotal + parseFloat(addons.price);
                            return (
                                <span key={htmlIdGenerator()()} style={{ fontSize: 12, display: 'block' }}>
                                    - {addons.translations.name[this.props.languageId]} [{addons.price.toFixed(2)}]
                                </span>
                            );
                        })}
                </td>

                <td
                    style={{ textAlign: 'right', paddingTop: '6px', fontSize: 14, verticalAlign: 'text-top' }}
                    key={htmlIdGenerator()()}
                >
                    {orderItem.promotional_quantity ? orderItem.promotional_quantity : orderItem.quantity}
                </td>

                <td style={{ textAlign: 'right', paddingTop: '6px', fontSize: 14, verticalAlign: 'text-top' }}>
                    {parseFloat(orderItem.price.toFixed(2)) + parseFloat(customizationTotal.toFixed(2))}
                </td>

                {orderItem.promotional_quantity || !orderItem.price ? <td style={{ color: 'green' }}>Free </td> : ''}
                {orderItem.price ? (
                    <td
                        style={
                            orderItem.promotional_quantity
                                ? {
                                      textDecoration: 'line-through',
                                      margin: '8px 0',
                                      fontSize: 14,
                                      paddingTop: '6px',
                                      verticalAlign: 'text-top',
                                  }
                                : {
                                      textAlign: 'right',
                                      margin: '8px 0',
                                      fontSize: 14,
                                      paddingTop: '6px',
                                      verticalAlign: 'text-top',
                                  }
                        }
                    >
                        {(parseFloat(orderItem.price.toFixed(2)) + parseFloat(customizationTotal.toFixed(2))) *
                            parseInt(orderItem.quantity)}
                    </td>
                ) : (
                    ''
                )}
                {/* <br /> */}
            </React.Fragment>
        );
    };

    getGrossTotal = (details) => {
        let gross_total = 0;

        for (const order of details) {
            gross_total = gross_total + order?.gross_total;
        }

        return gross_total.toFixed(2);
    };

    getRewardsPoints = (details) => {
        let rewards_points = 0;

        for (const order of details) {
            rewards_points = rewards_points + order?.cart?.reward_points?.used_reward_points?.value;
        }

        return rewards_points;
    };

    getDiscountAmount = (details) => {
        let discount_amount = 0;

        for (const order of details) {
            discount_amount = discount_amount + order?.discount_amount;
        }

        return discount_amount.toFixed(2);
    };

    getGstAmount = (details) => {
        let amount = 0;

        for (const order of details) {
            amount = amount + order?.gst_amount;
        }

        return parseInt(amount) / 2;
    };

    getVariousCharges = (details) => {
        let packaging_charge = 0;
        let convenience_charge = 0;
        let delivery_charge = 0;
        let net_total = 0;

        for (const order of details) {
            packaging_charge = packaging_charge + order?.cart.packaging_charge;
            convenience_charge = convenience_charge + order?.cart.convenience_charge;
            delivery_charge = delivery_charge + order?.cart?.delivery_charge;
            net_total = net_total + order?.net_total;
        }

        return {
            packaging_charge: packaging_charge.toFixed(2),
            convenience_charge: convenience_charge,
            delivery_charge: delivery_charge,
            net_total: net_total.toFixed(2),
        };
    };

    render() {
        const topCustomerSection = this.props.details[0];
        let restaurantName = this.props.restaurantList.restaurants.filter(
            (restaurant) => restaurant.id === parseInt(this.props.selectedRestaurantId)
        );
        const details = this.props.details;
        let spaceBetweenAlign = { justifyContent: 'space-between', flexDirection: 'row', display: 'flex' };
        let centerAlign = { justifyContent: 'center', alignItems: 'center', display: 'flex', textAlign: 'center' };
        const thStyle = { fontWeight: 'normal', verticalAlign: 'text-top' };

        return (
            <>
                <EuiFlexItem style={{ fontSize: 14 }} id='printInvoice' key={htmlIdGenerator()()}>
                    <div>
                        <span style={{ fontSize: '16px', fontWeight: 'bold', ...centerAlign }}>
                            {restaurantName[0].name}
                        </span>
                    </div>
                    {topCustomerSection?.outlet?.legal_name ? (
                        <EuiFlexItem style={centerAlign}>
                            <span>{topCustomerSection.outlet.legal_name}</span>
                        </EuiFlexItem>
                    ) : null}
                    <EuiFlexItem style={centerAlign}>
                        <span>
                            {
                                topCustomerSection?.outlet?.outlet_address?.translations?.address_line_1[
                                    this.props.languageId
                                ]
                            }
                        </span>
                    </EuiFlexItem>
                    {topCustomerSection?.outlet?.settings?.gst_number && (
                        <EuiFlexItem style={centerAlign}>
                            <span>GSTIN: {topCustomerSection.outlet.settings.gst_number}</span>
                        </EuiFlexItem>
                    )}

                    {topCustomerSection?.outlet?.settings?.bill_header ? (
                        this.billSettingStrings(topCustomerSection?.outlet?.settings?.bill_header)?.length ? (
                            this.billSettingStrings(topCustomerSection?.outlet?.settings?.bill_header)?.map((line) => {
                                return (
                                    <span style={{ display: 'block', whiteSpace: 'pre', ...centerAlign }}>{line}</span>
                                );
                            })
                        ) : (
                            <span style={{ display: 'block', whiteSpace: 'pre', ...centerAlign }}>
                                {topCustomerSection?.outlet?.settings?.bill_header}
                            </span>
                        )
                    ) : null}
                    <EuiHorizontalRule />
                    <table style={{ width: '100%', paddingBottom: '6px' }}>
                        <thead style={{ textAlign: 'left', fontWeight: 'normal' }}>
                            <tr style={{ fontWeight: 'normal', fontSize: 14, width: '50%' }}>
                                {topCustomerSection?.status?.placed_at ? (
                                    <td style={thStyle}>
                                        Date:{' '}
                                        {topCustomerSection?.status?.placed_at &&
                                            moment(topCustomerSection.status.placed_at).format('DD/MM/YY HH:mm')}
                                    </td>
                                ) : null}
                                {topCustomerSection?.table?.code ? (
                                    <td style={thStyle}>
                                        <p>
                                            {this.orderingMode()}{' '}
                                            {topCustomerSection?.table?.code ? (
                                                <>: {topCustomerSection?.table?.code}</>
                                            ) : null}
                                        </p>
                                    </td>
                                ) : null}
                            </tr>
                        </thead>
                        <tbody>
                            <tr style={{ fontSize: 14 }}>
                                {topCustomerSection?.payment_method ? (
                                    <td style={{ width: '50%', verticalAlign: 'text-top' }}>
                                        Payment:{' '}
                                        {topCustomerSection?.payment_method &&
                                            this.getPaymentMethod(topCustomerSection.payment_method.payment_method)}
                                    </td>
                                ) : null}
                                {topCustomerSection?.customer?.mobile_number ? (
                                    <>
                                        <td>
                                            Number: {topCustomerSection?.customer?.country_code}{' '}
                                            {topCustomerSection?.customer?.mobile_number}
                                        </td>
                                    </>
                                ) : null}
                            </tr>
                            <tr style={{ fontSize: 14 }}>
                                <td>{this.customerAddress()}</td>
                            </tr>
                            <tr style={{ fontSize: 14 }}>
                                {topCustomerSection?.customer.name ? (
                                    <td>Name: {topCustomerSection?.customer.name}</td>
                                ) : null}
                                {topCustomerSection?.customer?.email ? (
                                    <td style={thStyle}>Email: {topCustomerSection?.customer?.email}</td>
                                ) : null}
                            </tr>
                        </tbody>
                    </table>
                    <table
                        style={{
                            width: '100%',
                            borderCollapse: 'collapse',
                            borderBottom: '0.5px solid grey',
                        }}
                    >
                        <thead style={{ textAlign: 'left', width: '100%' }}>
                            <tr style={{ width: '100%' }}>
                                <th
                                    style={{
                                        width: '70%',
                                        borderBottom: '0.5px solid #000',
                                        fontSize: 14,
                                        padding: '8px 0',
                                    }}
                                >
                                    Item(s)
                                </th>
                                <th
                                    style={{
                                        width: '10%',
                                        textAlign: 'right',
                                        borderBottom: '0.5px solid #000',
                                        fontSize: 14,
                                        padding: '8px 0',
                                    }}
                                >
                                    Qty
                                </th>
                                <th
                                    style={{
                                        width: '10%',
                                        textAlign: 'right',
                                        borderBottom: '0.5px solid #000',
                                        fontSize: 14,
                                        padding: '8px 0',
                                    }}
                                >
                                    Price
                                </th>
                                <th
                                    style={{
                                        width: '10%',
                                        textAlign: 'right',
                                        borderBottom: '0.5px solid #000',
                                        fontSize: 14,
                                        padding: '8px 0',
                                    }}
                                >
                                    Amount
                                </th>
                            </tr>
                        </thead>

                        <tbody>
                            {this.props.details?.map((order) =>
                                order?.cart?.cart_items?.map((orderItem) => {
                                    return (
                                        <>
                                            <tr>{this.renderItemRowsNew(orderItem)}</tr>
                                            <tr>
                                                {orderItem?.children?.length
                                                    ? orderItem.children.map((childOrderItem) => {
                                                          return this.renderItemRowsNew(childOrderItem);
                                                      })
                                                    : null}
                                            </tr>
                                        </>
                                    );
                                })
                            )}
                        </tbody>
                    </table>
                    <EuiHorizontalRule />
                    <EuiFlexItem style={spaceBetweenAlign}>
                        <span style={{ fontSize: 14 }}>Gross total</span>
                        <span style={{ fontSize: 14 }}>
                            <RupeeImage style={{ height: '9px', width: '10px' }} />
                            {this.getGrossTotal(details)}
                        </span>
                    </EuiFlexItem>

                    {this.getRewardsPoints(details) > 0 ? (
                        <EuiFlexItem style={spaceBetweenAlign}>
                            <span style={{ fontSize: 14 }}>Rewards</span>
                            <span style={{ fontSize: 14 }}>
                                <RupeeImage style={{ height: '9px', width: '10px' }} />
                                {this.getRewardsPoints(details)}{' '}
                            </span>
                        </EuiFlexItem>
                    ) : null}

                    {this.getDiscountAmount(details) > 0 ? (
                        <EuiFlexItem style={spaceBetweenAlign}>
                            <span style={{ fontSize: 14 }}>Discount</span>
                            <span style={{ fontSize: 14 }}>
                                <RupeeImage style={{ height: '9px', width: '10px' }} />
                                {this.getDiscountAmount(details)}
                            </span>
                        </EuiFlexItem>
                    ) : null}

                    {this.getGstAmount(details) > 0 ? (
                        <EuiFlexItem style={spaceBetweenAlign}>
                            <span style={{ fontSize: 14 }}>SGST</span>
                            <span style={{ fontSize: 14 }}>
                                <RupeeImage style={{ height: '9px', width: '10px' }} />
                                {this.getGstAmount(details)}
                            </span>
                        </EuiFlexItem>
                    ) : null}

                    {this.getGstAmount(details) > 0 ? (
                        <EuiFlexItem style={spaceBetweenAlign}>
                            <span style={{ fontSize: 14 }}>CGST</span>
                            <span style={{ fontSize: 14 }}>
                                <RupeeImage style={{ height: '9px', width: '10px' }} />
                                {this.getGstAmount(details)}
                            </span>
                        </EuiFlexItem>
                    ) : null}

                    {this.getVariousCharges(details).packaging_charge > 0 ? (
                        <EuiFlexItem style={spaceBetweenAlign}>
                            <span style={{ fontSize: 14 }}>Packaging Charge</span>
                            <span style={{ fontSize: 14 }}>
                                <RupeeImage style={{ height: '9px', width: '10px' }} />
                                {this.getVariousCharges(details).packaging_charge}
                            </span>
                        </EuiFlexItem>
                    ) : null}

                    {this.getVariousCharges(details).convenience_charge > 0 ? (
                        <EuiFlexItem style={spaceBetweenAlign}>
                            <span style={{ fontSize: 14 }}>Convenience Charge</span>
                            <span style={{ fontSize: 14 }}>
                                <RupeeImage style={{ height: '9px', width: '10px' }} />
                                {this.getVariousCharges(details).convenience_charge}
                            </span>
                        </EuiFlexItem>
                    ) : null}

                    <EuiFlexItem style={spaceBetweenAlign}>
                        <span style={{ fontSize: 14 }}>Delivery Charge</span>
                        <span style={{ fontSize: 14 }}>
                            <RupeeImage style={{ height: '9px', width: '10px' }} />
                            {this.getVariousCharges(details).delivery_charge}
                        </span>
                    </EuiFlexItem>
                    <EuiHorizontalRule />

                    <EuiFlexItem style={spaceBetweenAlign}>
                        <b style={{ fontSize: 16 }}>Grand Total</b>
                        <b style={{ fontSize: 16 }}>
                            {' '}
                            <RupeeImage style={{ height: '9px', width: '10px' }} />
                            {this.getVariousCharges(details).net_total}
                        </b>
                    </EuiFlexItem>

                    <EuiHorizontalRule />
                    {topCustomerSection?.outlet?.settings?.bill_footer ? (
                        this.billSettingStrings(topCustomerSection?.outlet?.settings?.bill_footer)?.length ? (
                            this.billSettingStrings(topCustomerSection?.outlet?.settings?.bill_footer)?.map((line) => {
                                return (
                                    <span style={{ display: 'block', whiteSpace: 'pre', ...centerAlign }}>{line}</span>
                                );
                            })
                        ) : (
                            <span style={{ display: 'block', whiteSpace: 'pre', ...centerAlign }}>
                                {topCustomerSection?.outlet?.settings?.bill_footer}
                            </span>
                        )
                    ) : null}
                    <EuiHorizontalRule />
                    <EuiFlexItem style={{ justifyContent: 'center', flexDirection: 'row', display: 'flex' }}>
                        Thank you.
                    </EuiFlexItem>
                    <EuiFlexItem style={centerAlign}>
                        <b>Powered by Fab Systems</b>
                    </EuiFlexItem>
                </EuiFlexItem>
            </>
        );
    }
}
