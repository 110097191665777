import {
    EuiCard,
    EuiFlexGroup,
    EuiFlexItem,
    EuiPanel,
    EuiSpacer,
    EuiText,
    EuiTitle,
    EuiButtonEmpty,
} from '@elastic/eui';
import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import SectionCreationFlyout from './SectionCreationFlyout';
import TableInfo from './TableInfo';
import TableStatusColorInformer from './TableStatusColorInformer';

const Sections = ({ isEditTableMode }) => {
    const sections = useSelector((state) => state.tableManagement.sectionList);
    const [selectedSection, setSelectedSection] = useState(null);
    const [showFlyout, setShowFlyout] = useState(false);

    const handleFlyoutState = useCallback(async (section) => {
        setShowFlyout((prevState) => !prevState);
        setSelectedSection(section);
    }, []);

    if (!sections?.length) {
        return (
            <>
                <EuiSpacer />
                <EuiCard title='No Section Found' description='Please create a new one' />
            </>
        );
    }

    return (
        <>
            <EuiSpacer />

            <TableStatusColorInformer />

            <EuiSpacer />

            <EuiFlexGroup direction='column'>
                {sections?.map((section) => {
                    return (
                        <EuiFlexItem key={section.id.toString()}>
                            <EuiPanel>
                                <EuiFlexGroup justifyContent='flexStart' alignItems='center'>
                                    <EuiFlexItem grow={false}>
                                        <EuiTitle size='s'>
                                            <h2>{section.internal_name}</h2>
                                        </EuiTitle>
                                    </EuiFlexItem>
                                    {isEditTableMode ? (
                                        <EuiFlexItem
                                            style={{ cursor: 'pointer' }}
                                            onClick={() => handleFlyoutState(section)}
                                            grow={false}
                                        >
                                            <EuiFlexGroup gutterSize='s' alignItems='center'>
                                                <EuiButtonEmpty
                                                    size='s'
                                                    color='success'
                                                    iconType={'pencil'}
                                                    iconSize='s'
                                                >
                                                    <EuiText size='xs'>Edit</EuiText>
                                                </EuiButtonEmpty>
                                            </EuiFlexGroup>
                                        </EuiFlexItem>
                                    ) : null}
                                </EuiFlexGroup>
                                {section?.tables?.length ? (
                                    <TableInfo isEditTableMode={isEditTableMode} tables={section.tables} />
                                ) : null}
                            </EuiPanel>
                        </EuiFlexItem>
                    );
                })}
            </EuiFlexGroup>
            {showFlyout ? (
                <SectionCreationFlyout
                    closeFlyout={handleFlyoutState}
                    selectedSection={selectedSection}
                    sectionState='update'
                />
            ) : null}
        </>
    );
};

export default React.memo(Sections);
