import React from 'react';
import { EuiFlexItem, EuiText, EuiFlexGroup, EuiFormRow, EuiRadioGroup } from '@elastic/eui';
import { Controller, useFormContext } from 'react-hook-form';
import TimeSlot from '../../components/Form/TimeSlot';
const itemTimeSlot = [
    { id: 'no_time_slots_offer', label: 'Offer is available at  for all days of the week ' },
    { id: 'same_time_slot_for_all_days_offer', label: 'Offer is available at same time for all days of the week' },
    {
        id: 'different_time_for_different_days_offer',
        label: 'Offer is available at specific time for specific days of the week',
    },
];

const OfferTimming = (props) => {
    const {
        control,
        watch,
        setValue,
        formState: { errors },
    } = useFormContext();

    return (
        <>
            <EuiFlexItem style={{ paddingLeft: '10px' }}>
                <EuiFlexItem>
                    <EuiText size='s'>
                        <span>Please Specify the timings when this offer will be available on fab delivery</span>
                    </EuiText>
                    <EuiText size='s' color='warning'>
                        Each day can have only 6 time slots
                    </EuiText>
                </EuiFlexItem>
                <EuiFlexGroup alignItems='center' style={{ marginTop: '10px' }}>
                    <EuiFlexItem grow={false}>
                        <EuiFormRow isInvalid={errors.timer_for_item} fullWidth={true}>
                            <Controller
                                render={({}) => (
                                    <EuiRadioGroup
                                        options={itemTimeSlot}
                                        idSelected={
                                            watch('timer_for_item') === 'no_time_slots_offer'
                                                ? 'no_time_slots_offer'
                                                : watch('timer_for_item') === 'same_time_slot_for_all_days_offer'
                                                ? 'same_time_slot_for_all_days_offer'
                                                : watch('timer_for_item') === 'different_time_for_different_days_offer'
                                                ? 'different_time_for_different_days_offer'
                                                : 'no_time_slots_offer'
                                        }
                                        onChange={(id) =>
                                            setValue(
                                                'timer_for_item',
                                                id === 'no_time_slots_offer'
                                                    ? 'no_time_slots_offer'
                                                    : id === 'same_time_slot_for_all_days_offer'
                                                    ? 'same_time_slot_for_all_days_offer'
                                                    : id === 'different_time_for_different_days_offer'
                                                    ? 'different_time_for_different_days_offer'
                                                    : 'no_time_slots_offer',
                                                { shouldDirty: true }
                                            )
                                        }
                                        legend={{
                                            children: <span> Decide this Item lifetime </span>,
                                        }}
                                    />
                                )}
                                name='timer_for_item'
                                defaultValue={true}
                                control={control}
                                fullWidth={true}
                            />
                        </EuiFormRow>
                    </EuiFlexItem>
                </EuiFlexGroup>
                <EuiFlexItem>
                    {watch('timer_for_item') === 'same_time_slot_for_all_days_offer' && (
                        <>
                            <EuiFlexGroup
                                style={{
                                    background: '#9895CA',
                                    marginTop: '20px',
                                    marginBottom: '30px',
                                }}
                            >
                                <EuiFlexItem grow={2}>Days</EuiFlexItem>
                                <EuiFlexItem grow={4}>Start Time</EuiFlexItem>
                                <EuiFlexItem grow={4}>End Time</EuiFlexItem>
                            </EuiFlexGroup>
                            <EuiFlexItem style={{ padding: '10px' }}>
                                <TimeSlot
                                    control={control}
                                    setValue={setValue}
                                    name='time'
                                    errors={errors}
                                    watch={watch}
                                />
                            </EuiFlexItem>
                        </>
                    )}
                    {watch('timer_for_item') === 'different_time_for_different_days_offer' && (
                        <>
                            <EuiFlexGroup
                                style={{
                                    background: '#9895CA',
                                    marginTop: '20px',
                                    marginBottom: '30px',
                                }}
                                columns={3}
                            >
                                <EuiFlexItem grow={2}>Days</EuiFlexItem>
                                <EuiFlexItem grow={4}>Start Time</EuiFlexItem>
                                <EuiFlexItem grow={4}>End Time</EuiFlexItem>
                            </EuiFlexGroup>

                            <EuiFlexGroup
                                style={{
                                    marginBottom: 30,
                                    paddingBottom: 40,
                                    marginRight: 15,
                                    borderBottom: '1px solid black',
                                }}
                            >
                                <EuiFlexGroup
                                    style={{
                                        flexDirection: 'column',
                                        textAlign: 'center',
                                    }}
                                >
                                    <EuiFlexItem
                                        style={{
                                            textAlign: 'left',
                                            marginLeft: '3%',
                                        }}
                                    >
                                        <strong> Sunday </strong>
                                    </EuiFlexItem>

                                    <TimeSlot
                                        control={control}
                                        setValue={setValue}
                                        name={`weak.sunday`}
                                        errors={errors}
                                        watch={watch}
                                    />
                                </EuiFlexGroup>
                            </EuiFlexGroup>

                            <EuiFlexGroup
                                style={{
                                    marginBottom: 30,
                                    paddingBottom: 40,
                                    marginRight: 15,
                                    borderBottom: '1px solid black',
                                }}
                            >
                                <EuiFlexGroup
                                    style={{
                                        flexDirection: 'column',
                                        textAlign: 'center',
                                    }}
                                >
                                    <EuiFlexItem
                                        style={{
                                            textAlign: 'left',
                                            marginLeft: '3%',
                                        }}
                                    >
                                        {' '}
                                        <strong> Monday </strong>{' '}
                                    </EuiFlexItem>
                                    <TimeSlot
                                        control={control}
                                        setValue={setValue}
                                        name={`weak.monday`}
                                        errors={errors}
                                        watch={watch}
                                    />
                                </EuiFlexGroup>
                            </EuiFlexGroup>

                            <EuiFlexGroup
                                style={{
                                    marginBottom: 30,
                                    paddingBottom: 40,
                                    marginRight: 15,
                                    borderBottom: '1px solid black',
                                }}
                            >
                                <EuiFlexGroup
                                    style={{
                                        flexDirection: 'column',
                                        textAlign: 'center',
                                    }}
                                >
                                    <EuiFlexItem
                                        style={{
                                            textAlign: 'left',
                                            marginLeft: '3%',
                                        }}
                                    >
                                        {' '}
                                        <strong> Tuesday </strong>{' '}
                                    </EuiFlexItem>
                                    <TimeSlot
                                        control={control}
                                        setValue={setValue}
                                        name={`weak.tuesday`}
                                        errors={errors}
                                        watch={watch}
                                    />
                                </EuiFlexGroup>
                            </EuiFlexGroup>

                            <EuiFlexGroup
                                style={{
                                    marginBottom: 30,
                                    paddingBottom: 40,
                                    marginRight: 15,
                                    borderBottom: '1px solid black',
                                }}
                            >
                                <EuiFlexGroup
                                    style={{
                                        flexDirection: 'column',
                                        textAlign: 'center',
                                    }}
                                >
                                    <EuiFlexItem
                                        style={{
                                            textAlign: 'left',
                                            marginLeft: '3%',
                                        }}
                                    >
                                        {' '}
                                        <strong> Wednesday </strong>{' '}
                                    </EuiFlexItem>
                                    <TimeSlot
                                        control={control}
                                        setValue={setValue}
                                        name={`weak.wednesday`}
                                        errors={errors}
                                        watch={watch}
                                    />
                                </EuiFlexGroup>
                            </EuiFlexGroup>

                            <EuiFlexGroup
                                style={{
                                    marginBottom: 30,
                                    paddingBottom: 40,
                                    marginRight: 15,
                                    borderBottom: '1px solid black',
                                    justifyContent: 'center',
                                }}
                            >
                                <EuiFlexGroup
                                    style={{
                                        flexDirection: 'column',
                                        textAlign: 'center',
                                    }}
                                >
                                    <EuiFlexItem
                                        style={{
                                            textAlign: 'left',
                                            marginLeft: '3%',
                                        }}
                                    >
                                        {' '}
                                        <strong> Thursday </strong>{' '}
                                    </EuiFlexItem>
                                    <TimeSlot
                                        control={control}
                                        setValue={setValue}
                                        name={`weak.thursday`}
                                        errors={errors}
                                        watch={watch}
                                    />
                                </EuiFlexGroup>
                            </EuiFlexGroup>

                            <EuiFlexGroup
                                style={{
                                    marginBottom: 30,
                                    paddingBottom: 40,
                                    marginRight: 15,
                                    borderBottom: '1px solid black',
                                }}
                            >
                                <EuiFlexGroup
                                    style={{
                                        flexDirection: 'column',
                                        textAlign: 'center',
                                    }}
                                >
                                    <EuiFlexItem
                                        style={{
                                            textAlign: 'left',
                                            marginLeft: '3%',
                                        }}
                                    >
                                        {' '}
                                        <strong> Friday </strong>{' '}
                                    </EuiFlexItem>
                                    <TimeSlot
                                        control={control}
                                        setValue={setValue}
                                        name={`weak.friday`}
                                        errors={errors}
                                        watch={watch}
                                    />
                                </EuiFlexGroup>
                            </EuiFlexGroup>

                            <EuiFlexGroup
                                style={{
                                    marginBottom: 30,
                                    paddingBottom: 40,
                                    marginRight: 15,
                                    borderBottom: '1px solid black',
                                }}
                            >
                                <EuiFlexGroup
                                    style={{
                                        flexDirection: 'column',
                                        textAlign: 'center',
                                    }}
                                >
                                    <EuiFlexItem
                                        style={{
                                            textAlign: 'left',
                                            marginLeft: '3%',
                                        }}
                                    >
                                        {' '}
                                        <strong> Saturday </strong>{' '}
                                    </EuiFlexItem>
                                    <TimeSlot
                                        control={control}
                                        setValue={setValue}
                                        name={`weak.saturday`}
                                        errors={errors}
                                        watch={watch}
                                    />
                                </EuiFlexGroup>
                            </EuiFlexGroup>
                        </>
                    )}
                    {/* todo: error message display */}
                    {/* {stateUpdate && (
                        <EuiTextColor color='danger'>please enter time slot</EuiTextColor>
                    )} */}
                </EuiFlexItem>
            </EuiFlexItem>
        </>
    );
};

export default React.memo(OfferTimming);
