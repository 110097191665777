import * as types from '../types/banner';

const initialState = {
    banners: [],
    currentBanner: null,
    bannerLoader: { loader: true, error: false },
    outletBannerLoader: { loader: true, error: false },
    bannerType: 'primary',
};

export default (state = initialState, action) => {
    switch (action.type) {
        case types.SET_BANNER:
            return {
                ...state,
                banners: action.payload.banners,
            };
        case types.SET_CURRENT_BANNER_ID:
            return {
                ...state,
                currentBanner: action.payload.currentBannerId,
            };
        case types.SET_BANNER_LOADER:
            return {
                ...state,
                bannerLoader: action.payload.bannerLoader,
            };
        case types.SET_OUTLET_BANNER_LOADER:
            return {
                ...state,
                outletBannerLoader: action.payload.outletBannerLoader,
            };
        case types.SET_CURRENT_BANNERS_SCREEN:
            return {
                ...state,
                bannerType: action.payload,
            };

        default:
            return state;
    }
};
