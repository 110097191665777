import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { EuiForm } from '@elastic/eui';
import TextField from '../../../components/Form/TextField';
import NumberField from '../../../components/Form/NumberField';
import ButtonGroup from '../../../components/Form/ButtonGroup';
import FormGroupDescription from '../../../components/formGroupDescription';
import { useDispatch, useSelector } from 'react-redux';
import { restaurantSettingsAPI } from '../../../api/restaurantSettings/restaurantSettingsAPI';
import * as types from '../../../reduxStore/types/outlet';
import { userHasPermission } from '../../../helper/auth';

const AppSettings = (props) => {
    const { restaurantResponse, fetchRestaurant } = props;
    const {
        handleSubmit,
        control,
        watch,
        reset,
        setValue,
        formState: { isDirty, errors },
    } = useForm();
    const dispatch = useDispatch();
    const permissions = useSelector((state) => state.permission.permission);
    const restaurantDescriptions = useSelector((state) => state.auth.restaurantDescriptions);
    const availableLanguages = useSelector((state) => state.language.availableLanguages);

    useEffect(() => {
        if (!userHasPermission(permissions, '/restaurant-settings', 'write')) return;
        if (isDirty) {
            dispatch({
                type: 'set-portal-active',
                payload: {
                    portalActive: {
                        resetChanges: resetChanges,
                        submit: handleSubmit(onSubmit),
                    },
                },
            });
        } else {
            dispatch({
                type: 'set-portal-active',
                payload: {
                    portalActive: {},
                },
            });
        }
    }, [isDirty]);

    useEffect(() => {
        if (restaurantResponse && restaurantResponse.restaurant) {
            resetChanges();
        }
    }, [restaurantResponse]);

    const resetChanges = () => {
        let defaultValue = {};
        let restaurantData = restaurantResponse.restaurant;
        defaultValue.name = restaurantData.name;
        defaultValue.app_name = restaurantData.settings && restaurantData.settings.app_name;
        defaultValue.apply_mask = restaurantData.settings.apply_mask;
        defaultValue.logo_height = restaurantData.settings ? restaurantData.settings.logo_height : null;
        defaultValue.is_customer_email_required = restaurantData.settings.is_customer_email_required;
        defaultValue.repeat_usage_time = restaurantData.settings.repeat_usage_time;

        defaultValue.languages = restaurantData.languages.map((item, index) => ({
            label: item.language_label,
            language: item.language,
            language_label: item.language_label,
        }));

        let translationObject = {
            restaurant_name: {},
        };

        for (const language_label in restaurantData.translations.restaurant_name) {
            translationObject.restaurant_name[language_label] =
                restaurantData.translations.restaurant_name[language_label];
        }

        defaultValue.translations = translationObject;

        reset({
            ...defaultValue,
        });
    };

    const onSubmit = (data) => {
        if (data.translations) {
            availableLanguages.map((language) => {
                if (language.language_label === 'English') {
                    data.translations.restaurant_name[language.language] = data.name;
                }
            });
        } else {
            data.translations = { restaurant_name: { en: data.name } };
        }

        dispatch(restaurantSettingsAPI('app_settings', data)).then((res) => {
            if (res.success) {
                dispatch({
                    type: types.SET_PORTAL_ACTIVE,
                    payload: {
                        portalActive: {},
                    },
                });
                dispatch({
                    type: types.SET_PORTAL_ACTIVE_TAB_SWITCHED,
                    payload: {
                        tabSwitched: false,
                    },
                });
                fetchRestaurant();
            } else {
                alert(res.message);
            }
        });
    };

    return (
        <>
            <EuiForm component='form' onSubmit={handleSubmit(onSubmit)}>
                <FormGroupDescription
                    title={restaurantDescriptions?.name_of_restaurant?.display_name}
                    description={restaurantDescriptions?.name_of_restaurant?.description}
                >
                    <TextField
                        errors={errors}
                        name='name'
                        placeholder='Restaurant name: English'
                        rules={{ required: 'Please enter name' }}
                        control={control}
                        watch={watch}
                    />
                    {watch('languages') &&
                        watch('languages').length > 0 &&
                        watch('languages').map((item, index) => (
                            <>
                                {item.language_label != 'English' ? (
                                    <TextField
                                        errors={errors}
                                        isInvalid={
                                            errors.translations &&
                                            errors.translations.restaurant_name &&
                                            errors.translations.restaurant_name.hasOwnProperty(item.language)
                                        }
                                        error={
                                            errors.translations &&
                                            errors.translations.restaurant_name &&
                                            errors.translations.restaurant_name.hasOwnProperty(item.language) &&
                                            errors.translations.restaurant_name[item.language]
                                                ? errors.translations.restaurant_name[item.language].message
                                                : ''
                                        }
                                        // label={index === 0 ? <strong>Restaurant name</strong> : ''}
                                        name={`translations.restaurant_name.${item.language}`}
                                        placeholder={`Restaurant name: ${item.language_label} `}
                                        rules={{ required: 'Please write restaurant name ' }}
                                        control={control}
                                        watch={watch}
                                        rows={4}
                                    />
                                ) : (
                                    ''
                                )}
                            </>
                        ))}
                </FormGroupDescription>
                <FormGroupDescription
                    title={restaurantDescriptions?.application_display_name?.display_name}
                    description={restaurantDescriptions?.application_display_name?.description}
                >
                    <TextField
                        errors={errors}
                        // label='Application display name'
                        name='app_name'
                        placeholder='Enter the display name of the application'
                        rules={{ required: 'Please Enter your application name' }}
                        control={control}
                        watch={watch}
                    />
                </FormGroupDescription>
                <FormGroupDescription
                    title={restaurantDescriptions?.customer_email_compulsory?.display_name}
                    description={restaurantDescriptions?.customer_email_compulsory?.description}
                >
                    <ButtonGroup
                        idSelected={watch('is_customer_email_required') ? '1' : '0'}
                        color='primary'
                        control={control}
                        setValue={setValue}
                        name='is_customer_email_required'
                        watch={watch}
                    />
                </FormGroupDescription>
                <FormGroupDescription
                    title={restaurantDescriptions?.apply_mask_on_category_images?.display_name}
                    description={restaurantDescriptions?.apply_mask_on_category_images?.description}
                >
                    <ButtonGroup
                        idSelected={watch('apply_mask') ? '1' : '0'}
                        color='primary'
                        control={control}
                        setValue={setValue}
                        name='apply_mask'
                        watch={watch}
                        // label={'Apply mask on image'}
                    />
                </FormGroupDescription>
                <FormGroupDescription
                    title={restaurantDescriptions?.logo_height?.display_name}
                    description={restaurantDescriptions?.logo_height?.description}
                >
                    <NumberField
                        errors={errors}
                        name='logo_height'
                        placeholder='Please Enter logo height'
                        control={control}
                    />
                </FormGroupDescription>
                <FormGroupDescription
                    title={restaurantDescriptions?.offer_usage_time?.display_name}
                    description={restaurantDescriptions?.offer_usage_time?.description}
                >
                    <NumberField
                        errors={errors}
                        name='repeat_usage_time'
                        placeholder='Please Enter usage time'
                        control={control}
                        prepend='Mins'
                    />
                </FormGroupDescription>
            </EuiForm>
        </>
    );
};

export default AppSettings;
