import API from '../axios/API';

export const getReportsData = (outletString, startDate, endDate, sort, direction, type, pagination) => {
    return async (dispatch, getState) => {
        let response;
        try {
            response = await API.get(
                `restaurants/:restaurantId/summary?page=${pagination.pageIndex + 1}&per_page=${
                    pagination.pageSize
                }&${outletString}&start_date=${startDate.format('YYYY-MM-DD')}&end_date=${endDate.format(
                    'YYYY-MM-DD'
                )}&sort_by=${sort}&sort_direction=${direction}&type=${type}`
            );
        } catch (error) {
            response = error;
        }
        return response;
    };
};
