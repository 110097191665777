import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { EuiForm } from '@elastic/eui';
import FormGroupDescription from '../../../components/formGroupDescription';
import TextAreaField from '../../../components/Form/TextAreaField';
import { useDispatch, useSelector } from 'react-redux';
import { restaurantSettingsAPI } from '../../../api/restaurantSettings/restaurantSettingsAPI';
import * as types from '../../../reduxStore/types/outlet';
import { userHasPermission } from '../../../helper/auth';

const LegalSettings = (props) => {
    const { restaurantResponse, fetchRestaurant } = props;
    const {
        handleSubmit,
        control,
        watch,
        reset,
        setValue,

        formState: { isDirty, errors },
    } = useForm();
    const dispatch = useDispatch();
    const permissions = useSelector((state) => state.permission.permission);
    const restaurantDescriptions = useSelector((state) => state.auth.restaurantDescriptions);

    useEffect(() => {
        if (!userHasPermission(permissions, '/restaurant-settings', 'write')) return;

        if (isDirty) {
            dispatch({
                type: 'set-portal-active',
                payload: {
                    portalActive: {
                        resetChanges: resetChanges,
                        submit: handleSubmit(onSubmit),
                    },
                },
            });
        } else {
            dispatch({
                type: 'set-portal-active',
                payload: {
                    portalActive: {},
                },
            });
        }
    }, [isDirty]);

    useEffect(() => {
        if (restaurantResponse && restaurantResponse.restaurant) {
            resetChanges();
        }
    }, [restaurantResponse]);

    const resetChanges = () => {
        let defaultValue = {};
        let restaurantData = restaurantResponse.restaurant;
        defaultValue.languages = restaurantData.languages.map((item, index) => ({
            label: item.language_label,
            language: item.language,
            language_label: item.language_label,
        }));

        let translationObject = {
            return_policy: {},
        };

        for (const language_label in restaurantData.translations.return_policy) {
            translationObject.return_policy[language_label] = restaurantData.translations.return_policy[language_label];
        }

        defaultValue.translations = translationObject;

        reset({
            ...defaultValue,
        });
    };

    const onSubmit = (data) => {
        dispatch(restaurantSettingsAPI('legal', data)).then((res) => {
            if (res.success) {
                dispatch({
                    type: types.SET_PORTAL_ACTIVE,
                    payload: {
                        portalActive: {},
                    },
                });
                dispatch({
                    type: types.SET_PORTAL_ACTIVE_TAB_SWITCHED,
                    payload: {
                        tabSwitched: false,
                    },
                });
                fetchRestaurant();
            } else {
                alert(res.message);
            }
        });
    };

    return (
        <>
            <EuiForm component='form' onSubmit={handleSubmit(onSubmit)}>
                <FormGroupDescription
                    title={restaurantDescriptions?.return_policy?.display_name}
                    description={restaurantDescriptions?.return_policy?.description}
                >
                    {watch('languages') &&
                        watch('languages').length > 0 &&
                        watch('languages').map((item, index) => (
                            <TextAreaField
                                errors={errors}
                                isInvalid={
                                    errors.translations &&
                                    errors.translations.return_policy &&
                                    errors.translations.return_policy.hasOwnProperty(item.language)
                                }
                                error={
                                    errors.translations &&
                                    errors.translations.return_policy &&
                                    errors.translations.return_policy.hasOwnProperty(item.language) &&
                                    errors.translations.return_policy[item.language]
                                        ? errors.translations.return_policy[item.language].message
                                        : ''
                                }
                                label={index === 0 ? <strong>Return policy</strong> : ''}
                                name={`translations.return_policy.${item.language}`}
                                placeholder={`Write return policy: ${item.language_label}`}
                                rules={{ required: 'Please write return policy' }}
                                control={control}
                                watch={watch}
                                rows={4}
                            />
                        ))}
                </FormGroupDescription>
            </EuiForm>
        </>
    );
};

export default LegalSettings;
