import React from 'react';
import { EuiFlexItem, EuiRadio, EuiText, htmlIdGenerator, EuiSpacer } from '@elastic/eui';
import { useSelector } from 'react-redux';
import { capitalize } from '../../helper/capitalize';

const VariantListComponent = (props) => {
    const { selectedVariant, setSelectedVariant, setSelectedVariantIndex, setSelectedAddOnsByGroup } = props;

    const selectedItem = useSelector((state) => state.billing.selectedItem);

    const variantChange = (selectedVariant, index) => {
        setSelectedVariantIndex(index);
        setSelectedVariant(selectedVariant);
        setSelectedAddOnsByGroup({});
    };

    return (
        <>
            <EuiFlexItem style={{ padding: '10px 20px' }}>
                <EuiFlexItem style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <EuiText>
                        <h4>Variant</h4>
                    </EuiText>
                    {/* style={{fontSize : '14px', fontFamily: 'Metropolis-Medium'}}     */}
                </EuiFlexItem>
                <EuiSpacer size='s' />
                <EuiFlexItem>
                    {selectedItem.variants[0].options.map((option, index) => (
                        <EuiFlexItem style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                            <EuiRadio
                                id={htmlIdGenerator()()}
                                label={<EuiText color='default'>{capitalize(option.title)}</EuiText>}
                                checked={option.id == selectedVariant.id}
                                onChange={(e) => variantChange(option, index)}
                            />

                            <b style={{ fontSize: '14px' }}>{`₹${option.price.toFixed(2)}`}</b>
                        </EuiFlexItem>
                    ))}
                </EuiFlexItem>
            </EuiFlexItem>
            <hr />
        </>
    );
};

export default VariantListComponent;
