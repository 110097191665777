import {
    EuiButton,
    EuiDragDropContext,
    EuiDroppable,
    EuiFlexItem,
    EuiFormRow,
    EuiIconTip,
    EuiSelect,
    EuiText,
    EuiTextColor,
} from '@elastic/eui';
import React, { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import AddOnCardSequence from '../../../../../components/menuComponentsRefactorCopy/menuEditorTab/addOnCardSequence';
import ItemDetailsCollaspibleNav from './ItemDetailsCollaspibleNav';
import { ReactComponent as ItemAddonGroup } from '../../../../../assets/img/svg/ItemAddonGroup.svg';

const ItemAddonGroups = ({
    addonOptions,
    variantConditionForPrice,
    onChangeAddon,
    addonSelected,
    setVaraintAddonLinkModalState,
    onDragEnd,
    selectedItemEditAddOnList,
    onAddonDelete,
}) => {
    const [isNavOpen, setIsNavOpen] = useState(false);
    const isOutletMenu = useSelector((state) => state.menuReducer.isOutletMenu);
    const { control } = useFormContext();

    return (
        <>
            <ItemDetailsCollaspibleNav
                cardTitle='Link Addon Groups'
                cardDescription='Add-on groups for this items'
                isNavOpen={isNavOpen}
                setIsNavOpen={setIsNavOpen}
                toggleText='Edit Item Pricing'
                icon={<ItemAddonGroup />}
            >
                <EuiFlexItem style={{ paddingLeft: '10px' }}>
                    <EuiFlexItem>
                        <EuiText size='s'>
                            <span>You can offer customisation options like toppings, extras, addons for customer.</span>
                        </EuiText>
                        <EuiText size='s'>
                            <span>
                                You can also define if customer selction of these options is optional or mandatory.
                            </span>
                        </EuiText>
                    </EuiFlexItem>
                    <EuiFlexItem
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            padding: '15px 0px',
                        }}
                    >
                        <EuiTextColor
                            style={{
                                padding: '1.5px 4px 0 0',
                            }}
                            size='s'
                            color='secondary'
                        >
                            What's this{' '}
                        </EuiTextColor>

                        <EuiIconTip type='questionInCircle' content={<span>This is AddOns for Item</span>} />
                    </EuiFlexItem>

                    {/* Link variant with addons */}

                    {!variantConditionForPrice() ? (
                        <EuiFlexItem style={{ margin: '10px 0' }}>
                            <EuiButton disabled={isOutletMenu} onClick={() => setVaraintAddonLinkModalState(true)}>
                                Link Addon with Varaint
                            </EuiButton>
                        </EuiFlexItem>
                    ) : (
                        <>
                            <EuiFlexItem>
                                <EuiFormRow label='Add on Groups' fullWidth={true}>
                                    <Controller
                                        render={({ field }) => (
                                            <EuiSelect
                                                options={addonOptions}
                                                onChange={(e) => onChangeAddon(e.target.value)}
                                                disabled={isOutletMenu}
                                                value={addonSelected}
                                            />
                                        )}
                                        name='group_name'
                                        fullWidth={true}
                                        control={control}
                                    />
                                </EuiFormRow>
                            </EuiFlexItem>

                            <EuiFlexItem
                                style={{
                                    flexDirection: 'row',
                                    overflowY: 'auto',
                                    width: '100%',
                                }}
                                className='scrollbar-sm'
                            >
                                <EuiDragDropContext onDragEnd={onDragEnd}>
                                    <EuiDroppable
                                        droppableId='CUSTOM_HANDLE_DROPPABLE_AREA'
                                        spacing='m'
                                        withPanel
                                        direction='horizontal'
                                        style={{
                                            display: 'flex',
                                            width: '35vw',
                                            heigth: '200px',
                                            overflow: 'auto',
                                        }}
                                    >
                                        {selectedItemEditAddOnList?.length ? (
                                            <AddOnCardSequence
                                                addOnList={selectedItemEditAddOnList}
                                                addOnDelete={onAddonDelete}
                                                disabled={isOutletMenu}
                                            />
                                        ) : (
                                            <EuiText> No AddOns Found</EuiText>
                                        )}
                                    </EuiDroppable>
                                </EuiDragDropContext>
                            </EuiFlexItem>
                        </>
                    )}
                </EuiFlexItem>
            </ItemDetailsCollaspibleNav>
        </>
    );
};

export default React.memo(ItemAddonGroups);
