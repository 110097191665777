import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
    EuiText,
    EuiDataGrid,
    EuiLink,
    EuiContextMenuItem,
    EuiPopover,
    EuiPagination,
    EuiContextMenuPanel,
    EuiFlexItem,
    EuiFlexGroup,
    EuiButtonEmpty,
} from '@elastic/eui';
import moment from 'moment';
import { updateTableBookingStatus } from '../../api/tableBooking/updateTableBookingStatus';
import RejectTableBookingModal from './rejectTableBookingModal';
import TableAssignModal from './tableAssignModal';

const TableBookingPendingList = (props) => {
    const {
        orderList,
        pagination,
        setPagination,
        setFlyoutOpen,
        setSelectedOrder,
        activePage,
        setActivePage,
        rowSize,
        setRowSize,
        tableBookingList,
        outletId,
        fetchTableBookingList,
    } = props;

    const history = useHistory();
    const dispatch = useDispatch();

    const [rejectBooking, setRejectBooking] = useState(false);
    const [bookingId, setBookingId] = useState('');
    const [booking, setBooking] = useState({});

    const columns = [
        {
            id: 'customer_name',
            display: 'Name',
            displayAsText: 'Name',
            isSortable: true,
        },
        {
            id: 'customer_number',
            display: 'Customer Number',
            displayAsText: 'Customer Number',
        },
        {
            id: 'occupancy',
            display: 'Occupancy',
            displayAsText: 'Occupancy',
        },
        {
            id: 'date_time',
            display: 'Date & Time',
            displayAsText: 'Date & Time',
            isSortable: true,
        },
        {
            id: 'comment',
            display: 'Comment',
            displayAsText: 'Comment',
        },
        {
            id: 'action',
            display: 'Action',
            displayAsText: 'Action',
        },
    ];

    const onChangeItemsPerPage = useCallback(
        (pageSize) => {
            props.paginationCallback((pagination) => ({
                ...pagination,
                pageSize,
                pageIndex: 0,
            }));
        },
        [props.paginationCallback]
    );

    const [sortingColumns, setSortingColumns] = useState([]);

    const orderFlyoutOpen = (order) => {
        props.flyoutCallback(true);
        props.selectedOrderCallback(order);
    };

    const onSort = useCallback(
        (sortingColumns) => {
            setSortingColumns(sortingColumns);
        },
        [setSortingColumns]
    );

    const [visibleColumns, setVisibleColumns] = useState(() => columns.map(({ id }) => id));

    const bookingStatusChange = (bookingRequestId, status) => {
        dispatch(updateTableBookingStatus(outletId, bookingRequestId, status)).then((response) => {
            if (response.success) {
                fetchTableBookingList();
            } else {
                alert('Error in updating status');
            }
        });
    };

    let mappedItems =
        tableBookingList.bookingList &&
        tableBookingList.bookingList.outlet_table_requests &&
        tableBookingList.bookingList.outlet_table_requests.data.length > 0
            ? tableBookingList.bookingList.outlet_table_requests.data.map((booking) => {
                  return {
                      customer_name: (
                          <>{<EuiText key={booking.id + 'customer_name'}>{booking.customer_name}</EuiText>}</>
                      ),
                      customer_number: (
                          <>
                              {
                                  <EuiText key={booking.id + 'customer_number'}>
                                      {' '}
                                      {booking.country_code} {booking.mobile_number}
                                  </EuiText>
                              }
                          </>
                      ),
                      occupancy: (
                          <>
                              {
                                  <EuiText key={booking.id + 'occupancy'}>
                                      {' '}
                                      {booking.number_of_persons ? booking.number_of_persons : 'Not applicable'}
                                  </EuiText>
                              }
                          </>
                      ),
                      date_time: (
                          <>
                              {
                                  <EuiText key={booking.id + 'date_time'}>
                                      {booking.date &&
                                          booking.time &&
                                          moment(`${booking.date} ${booking.time}`).format(
                                              'DD-MM-YYYY hh:mm:ss A'
                                          )}{' '}
                                  </EuiText>
                              }
                          </>
                      ),
                      comment: (
                          <>
                              {
                                  <EuiText key={booking.id + 'comment'}>
                                      {' '}
                                      {booking.comment ? booking.comment : 'Not applicable'}
                                  </EuiText>
                              }
                          </>
                      ),
                      action: (
                          <>
                              {
                                  <EuiText key={booking.id + 'actions'}>
                                      <EuiButtonEmpty
                                          style={{ background: 'transparent' }}
                                          // onClick={() => bookingStatusChange(booking.id, 'approved')}
                                          onClick={() => {
                                              setBooking(booking);
                                              setBookingId(booking.id);
                                          }}
                                      >
                                          Approve
                                      </EuiButtonEmpty>
                                      {' | '}
                                      <EuiButtonEmpty
                                          style={{ background: 'transparent' }}
                                          onClick={() => {
                                              setRejectBooking(true);
                                              setBookingId(booking.id);
                                          }}
                                          // bookingStatusChange(booking.booking_id, 'rejected')
                                      >
                                          Reject
                                      </EuiButtonEmpty>
                                  </EuiText>
                              }
                          </>
                      ),
                  };
              })
            : [];

    const renderCellValue = useMemo(() => {
        return ({ rowIndex, columnId, setCellProps }) => {
            return mappedItems.hasOwnProperty(rowIndex) ? mappedItems[rowIndex][columnId] : null;
        };
    }, [mappedItems]);

    const [isPopoverOpen, setIsPopoverOpen] = useState(false);
    const PAGE_COUNT = Math.ceil(tableBookingList.bookingList.outlet_table_requests.total / rowSize);

    const onChangeRowSize = (row_size) => {
        props.activePageCallback(0);
        props.rowSizeCallback(row_size);
    };

    const onButtonClick = () => setIsPopoverOpen((isPopoverOpen) => !isPopoverOpen);
    const closePopover = () => setIsPopoverOpen(false);

    const goToPage = (pageNumber) => props.activePageCallback(pageNumber);

    const getIconType = (size) => {
        return size === rowSize ? 'check' : 'empty';
    };

    const button = (
        <EuiButtonEmpty size='s' color='text' iconType='arrowDown' iconSide='right' onClick={onButtonClick}>
            Rows per page: {rowSize}
        </EuiButtonEmpty>
    );

    const items = [
        <EuiContextMenuItem
            key='10 rows'
            icon={getIconType(10)}
            onClick={() => {
                closePopover();
                onChangeRowSize(10);
            }}
        >
            10 rows
        </EuiContextMenuItem>,
        <EuiContextMenuItem
            key='20 rows'
            icon={getIconType(20)}
            onClick={() => {
                closePopover();
                onChangeRowSize(20);
            }}
        >
            20 rows
        </EuiContextMenuItem>,
        <EuiContextMenuItem
            key='50 rows'
            icon={getIconType(50)}
            onClick={() => {
                closePopover();
                onChangeRowSize(50);
            }}
        >
            50 rows
        </EuiContextMenuItem>,
        <EuiContextMenuItem
            key='100 rows'
            icon={getIconType(100)}
            onClick={() => {
                closePopover();
                onChangeRowSize(100);
            }}
        >
            100 rows
        </EuiContextMenuItem>,
    ];

    return (
        <>
            {bookingId && !rejectBooking && (
                <TableAssignModal
                    booking={booking}
                    outletId={outletId}
                    bookingId={bookingId}
                    setBookingId={setBookingId}
                    fetchTableBookingList={fetchTableBookingList}
                />
            )}
            {rejectBooking && (
                <RejectTableBookingModal
                    bookingId={bookingId}
                    setRejectBooking={setRejectBooking}
                    bookingStatusChange={bookingStatusChange}
                />
            )}
            <EuiDataGrid
                aria-label='Table Booking List'
                columns={columns}
                columnVisibility={{ visibleColumns, setVisibleColumns }}
                rowCount={mappedItems.length}
                inMemory={{ level: 'sorting' }}
                renderCellValue={renderCellValue}
                sorting={{ columns: sortingColumns, onSort }}
            />
            <EuiFlexGroup justifyContent='spaceBetween' alignItems='center'>
                <EuiFlexItem grow={false}>
                    <EuiPopover
                        button={button}
                        isOpen={isPopoverOpen}
                        closePopover={closePopover}
                        panelPaddingSize='none'
                    >
                        <EuiContextMenuPanel items={items} />
                    </EuiPopover>
                </EuiFlexItem>

                <EuiFlexItem grow={false}>
                    <EuiPagination
                        aria-label='Custom pagination example'
                        pageCount={PAGE_COUNT}
                        activePage={activePage}
                        onPageClick={goToPage}
                    />
                </EuiFlexItem>
            </EuiFlexGroup>
        </>
    );
};

export default TableBookingPendingList;
