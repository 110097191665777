// import './errorReporting';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Provider } from 'react-redux';
import store from './reduxStore/index';
import { setSuperAgent } from './config/superagent';
import * as Sentry from '@sentry/browser';
import * as SentryError from '@sentry/react';
import './styles/globalStyle.scss';
import { BrowserRouter as Router } from 'react-router-dom';

setSuperAgent(store);

if (process.env.REACT_APP_SENTRY_ENVIRONNMENT !== 'staging') {
    Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_URL,
        environment: process.env.REACT_APP_SENTRY_ENVIRONNMENT,
        sourceMapReference: true,
    });
}

const render = (Component) => {
    return ReactDOM.render(
        <Provider store={store}>
            <SentryError.ErrorBoundary fallback={'An error has occurred'}>
                <Router>
                    <Component />
                </Router>
            </SentryError.ErrorBoundary>
        </Provider>,
        document.getElementById('root')
    );
};

render(App);

if (module.hot) {
    module.hot.accept('./App', () => {
        const NextApp = require('./App').default;
        render(NextApp);
    });
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
