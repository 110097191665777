import React, { useEffect } from 'react';
import {
    EuiButton,
    EuiFlexGroup,
    EuiFlexItem,
    EuiPanel,
    EuiSpacer,
    EuiText,
    EuiFlyout,
    EuiFlyoutHeader,
    EuiTitle,
    EuiFlyoutBody,
    EuiFlyoutFooter,
    EuiButtonEmpty,
    EuiAccordion,
    EuiHorizontalRule,
    EuiTable,
    EuiTableHeader,
    EuiTableBody,
    EuiTableHeaderCell,
    EuiTableRow,
    EuiTableRowCell,
    EuiNotificationBadge,
} from '@elastic/eui';
import { useSelector, useDispatch } from 'react-redux';
import * as types from '../../reduxStore/types/billing';
import { isEmpty } from 'lodash';
import API from '../../api/axios/API';
import { capitalize } from '../../helper/capitalize';
import { agmarkSign } from '../../helper/agmarkSign';

const HoldOrderModal = (props) => {
    const { outletId } = props;

    const holdOrder = useSelector((state) => state.billing.holdOrder);
    const tables = useSelector((state) => state.billing.tables);
    const customerInformation = useSelector((state) => state.billing.customerInformation);
    const mode = useSelector((state) => state.billing.mode);
    const tableSelectedList = useSelector((state) => state.billing.tableSelectedList);
    const billingItem = useSelector((state) => state.billing.billingItem);

    const dispatch = useDispatch();

    const onCloseModal = () => {
        // setHolOrderModal(false);
        dispatch({
            type: types.SET_HOLD_ORDER_MODAL,
            payload: {
                holdOrderModal: false,
            },
        });
    };

    useEffect(() => {
        let localStorageHold = localStorage.getItem('holdOrder');

        if (localStorageHold) {
            dispatch({
                type: types.SET_HOLD_ORDER,
                payload: {
                    holdOrder: JSON.parse(localStorageHold),
                },
            });
        } else {
            dispatch({
                type: types.SET_HOLD_ORDER,
                payload: {
                    holdOrder: [],
                },
            });
        }
    }, []);

    const previousOrdersList = (tableList) => {
        console.log('table list ', tableList, 'tables ', tables);

        tables.map((table) => {
            if (table.outlet_table_id == tableList[0]) {
                dispatch({
                    type: types.SET_PREVIOUS_ORDERS,
                    payload: {
                        previousOrders: table.orders,
                    },
                });
            }
        });
    };

    const addOrder = (order, index) => {
        dispatch({
            type: types.SET_RESET_STATE,
        });

        let innerArray = [...holdOrder];

        dispatch({
            type: types.SET_MODE,
            payload: {
                mode: innerArray[index].customer_details.mode,
            },
        });

        addItemToCart(order, order.cart_id);

        dispatch({
            type: types.SET_CART_ID,
            payload: {
                cartId: order.cart_id,
            },
        });

        dispatch({
            type: types.SET_CUSTOMER_INFORMATION,
            payload: {
                customerInformation: { ...innerArray[index].customer_details },
            },
        });

        if (
            mode === 'dine_in' &&
            innerArray[index].customer_details.table_list &&
            innerArray[index].customer_details.table_list.length > 0
        ) {
            previousOrdersList(innerArray[index].customer_details.table_list);
            dispatch({
                type: types.SET_TABLE_SELECTED_LIST,
                payload: {
                    tableSelectedList: innerArray[index].customer_details.table_list,
                },
            });
        }

        dispatch({
            type: types.SET_CUSTOMER_INFORMATION_FINISH,
            payload: {
                finishCustomerInfo: true,
            },
        });

        delete order.cart_id;
        delete order.customer_details;

        dispatch({
            type: types.SET_BILLING_ITEM,
            payload: {
                billingItem: order,
            },
        });

        innerArray.splice(index, 1);

        localStorage.setItem('holdOrder', JSON.stringify(innerArray));

        dispatch({
            type: types.SET_HOLD_ORDER,
            payload: {
                holdOrder: innerArray,
            },
        });

        onCloseModal();
    };

    const onRemoveOrder = (index) => {
        let innerArray = [...holdOrder];
        innerArray.splice(index, 1);

        localStorage.setItem('holdOrder', JSON.stringify(innerArray));

        dispatch({
            type: types.SET_HOLD_ORDER,
            payload: {
                holdOrder: innerArray,
            },
        });
    };

    const orderBillingStructureNew = (billingItem) => {
        let itemObj = {};

        Object.entries(billingItem).forEach(([key, value], index) => {
            if (key !== 'cart_id' && key !== 'customer_details') {
                let innerItemObj = {};
                if (!itemObj[value.id]) {
                    innerItemObj['quantity'] = value.quantity;

                    if (value.customizations && !isEmpty(value.customizations)) {
                        innerItemObj['customizations'] = value.customizations;
                    }

                    if (value.special_instruction) {
                        innerItemObj['special_instruction'] = value.special_instruction;
                    }
                } else {
                    innerItemObj['quantity'] = parseInt(itemObj[value.id]['quantity']) + parseInt(value.quantity);

                    if (value.customizations && !isEmpty(value.customizations)) {
                        innerItemObj['customizations'] = value.customizations;
                    }

                    if (value.special_instruction) {
                        innerItemObj['special_instruction'] = value.special_instruction;
                    }
                }

                itemObj[value.id] = innerItemObj;
            }
        });

        console.log('order billing structure new ', itemObj);

        return itemObj;
    };

    const addItemToCart = async (order, cartId) => {
        let response;
        let url = '';

        if (!cartId) {
            url = `/en/pos/restaurants/:restaurantId/outlets/${outletId}/carts`;
        } else {
            url = `/en/pos/restaurants/:restaurantId/outlets/${outletId}/carts/${cartId}`;
        }

        let data = orderBillingStructureNew(order);

        if (mode === 'delivery') {
            if (customerInformation.id) {
                data['customer_address'] = { id: customerInformation.id };
            } else {
                data['customer_address'] = {
                    latitude: customerInformation.latitude,
                    longitude: customerInformation.longitude,
                    location_name: customerInformation.location_name,
                    block_number: customerInformation.block_number,
                    city: customerInformation.city,
                    state: customerInformation.state,
                    region: customerInformation.region,
                    land_mark: customerInformation.land_mark,
                    tag: 'home',
                };
            }
        }

        try {
            if (!cartId) {
                url = `/en/pos/restaurants/:restaurantId/outlets/${outletId}/carts`;
                response = await API.post(url, {
                    items: { ...data },
                    ordering_mode: mode,
                    table_ids: tableSelectedList,
                });
            } else {
                url = `/en/pos/restaurants/:restaurantId/outlets/${outletId}/carts/${cartId}`;
                response = await API.put(url, {
                    items: { ...data },
                    ordering_mode: mode,
                    table_ids: tableSelectedList,
                });
            }
        } catch (error) {
            response = error;
        }

        if (response.success) {
            isEmpty(billingItem) &&
                !cartId &&
                dispatch({
                    type: types.SET_CART_ID,
                    payload: {
                        cartId: response.cart.cart_id,
                    },
                });
            dispatch({
                type: types.SET_BILLING_ITEM_CART_STRUCTURE,
                payload: {
                    billingItemCartStructure: response,
                },
            });

            //add cart_item_id
            cartItemIdAdd(response, order);
        } else {
            // let billingItemObj = JSON.parse(JSON.stringify(billingItem));
            // delete billingItemObj[key]
            // dispatch({
            //     type: types.SET_BILLING_ITEM,
            //     payload: {
            //         billingItem: billingItemObj,
            //     },
            // });
            // alert('Failed to add cart')
        }
    };

    const cartItemIdAdd = (response, itemObj) => {
        let innerObj = { ...itemObj };

        if (response.cart.cart_items.length > 0) {
            response.cart.cart_items.map((item) => {
                innerObj[`${item.item_id}i`].cart_item_id = item.cart_item_id;
            });
        }

        dispatch({
            type: types.SET_BILLING_ITEM,
            payload: {
                billingItem: innerObj,
            },
        });
    };

    const renderHeaderCell = () => {
        let headers = [];

        headers.push(
            <EuiTableHeaderCell key={1} width='62%'>
                Item
            </EuiTableHeaderCell>
        );

        headers.push(
            <EuiTableHeaderCell key={2} width='20%' align='center'>
                Qty.
            </EuiTableHeaderCell>
        );

        headers.push(
            <EuiTableHeaderCell key={3} width='20%' align='right'>
                Amount
            </EuiTableHeaderCell>
        );
        return headers;
    };

    const itemPushMethodPreviousOrders = (items, item) => {
        items.push(
            <EuiTableRow key={1}>
                <EuiTableRowCell>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div style={{ padding: '0px 8px 5px 0px' }}>{agmarkSign(item.food_type)}</div>

                        <div>
                            <div>
                                <span>{item.title}</span>
                            </div>
                        </div>
                    </div>
                </EuiTableRowCell>

                <EuiTableRowCell align='center'>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            alignItems: 'center',
                            border: '1px solid #d3dae6',
                            borderRadius: '3px',
                        }}
                    >
                        <div style={{ padding: '0 5px 2px' }}>
                            <EuiNotificationBadge>
                                {item.quantity ? item.quantity : item.promotional_quantity}
                            </EuiNotificationBadge>
                        </div>
                    </div>
                </EuiTableRowCell>

                <EuiTableRowCell align='right'>
                    <span>
                        {' '}
                        ₹ {item.quantity ? item.price * item.quantity : item.price * item.promotional_quantity}
                    </span>
                </EuiTableRowCell>
            </EuiTableRow>
        );

        return items;
    };

    const convertAddonStructure = (value, from) => {
        let addonStructure = {};

        value.customizations.map((addon) => {
            let innerArray = addonStructure[addon.addon_group_id] ? addonStructure[addon.addon_group_id] : [];

            innerArray.push(addon);

            addonStructure[addon.addon_group_id] = innerArray;

            addonStructure['addon_information'] = {
                quantity: value.addonQuantity ? value.addonQuantity : value.quantity,
                special_instruction: value.special_instruction ? value.special_instruction : '',
            };
        });

        return addonStructure;
    };

    const addonList = (addon) => {
        let addonList = [];
        if (!isEmpty(addon)) {
            Object.entries(addon).forEach(([key, value]) => {
                if (key !== 'addon_information' && key !== 'variant_information') {
                    value.map((itemAddon, index) => {
                        // index === 0 && addonList.push(<p style={{ fontSize: '12px' }}>&nbsp; {itemAddon.group_name}</p>);
                        addonList.push(
                            <p style={{ fontSize: '10px' }}>
                                - {itemAddon.name} [₹ {itemAddon.choicePrice}]
                            </p>
                        );
                    });
                }
            });
        }

        return addonList;
    };

    const itemPushMethodForAddonsPreviousOrders = (items, item, addon) => {
        items.push(
            <EuiTableRow>
                <EuiTableRowCell>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div style={{ padding: '0px 8px 5px 0px' }}>{agmarkSign(item.food_type)}</div>
                        <div>
                            <div>
                                <span>{item.title}</span>
                            </div>

                            {addonList(addon)}
                        </div>
                    </div>
                </EuiTableRowCell>
                <EuiTableRowCell align='center'>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            border: '1px solid #d3dae6',
                            borderRadius: '3px',
                        }}
                    >
                        <div style={{ padding: '0 5px 2px' }}>
                            <EuiNotificationBadge>{addon.addon_information.quantity}</EuiNotificationBadge>
                        </div>
                    </div>
                </EuiTableRowCell>

                <EuiTableRowCell align='right'>
                    <span>₹ {itemTotal(item, addon) * addon.addon_information.quantity}</span>
                </EuiTableRowCell>
            </EuiTableRow>
        );
    };

    const itemTotal = (item, addon) => {
        let total = item.price;

        Object.entries(addon).forEach(([key, value]) => {
            if (key !== 'addon_information' && key !== 'variant_information') {
                value.map((itemAddon) => {
                    total += itemAddon.choicePrice
                        ? parseFloat(itemAddon.choicePrice)
                        : parseFloat(itemAddon.choicePrice);
                });
            }
        });

        return total;
    };

    const itemList = (order) => {
        let items = [];

        Object.entries(order).forEach(([key, value]) => {
            if (!['customer_details', 'cart_id'].includes(key)) {
                // && value.customizations.length < 1
                if (!value.customizations) {
                    itemPushMethodPreviousOrders(items, value);
                } else {
                    // && value.customizations.length < 1
                    if ((value.quantity > 0 || value.promotional_quantity > 0) && !value.customizations) {
                        itemPushMethodPreviousOrders(items, value);
                    }

                    if (value.customizations && value.customizations.length > 0) {
                        value.customizations.map((addon, index) => {
                            itemPushMethodForAddonsPreviousOrders(items, value, addon);
                        });
                    }
                }
            }
        });

        return (
            <EuiTable id={'holdOrders'}>
                <EuiTableHeader>{renderHeaderCell()}</EuiTableHeader>
                <EuiTableBody>{items}</EuiTableBody>
            </EuiTable>
        );
    };

    const getTableName = (order) => {
        let tableName = order.customer_details && order.customer_details.mode;

        if (order.customer_details && order.customer_details.table_list && order.customer_details.table_list.length) {
            tables.map((table) => {
                if (table.outlet_table_id == order.customer_details.table_list[0]) {
                    tableName = table.name;
                }
            });
        }

        return tableName;
    };

    return (
        <>
            <EuiFlyout ownFocus onClose={onCloseModal} aria-labelledby='flyoutTitle'>
                <EuiFlyoutHeader hasBorder>
                    <EuiTitle size='m'>
                        <h2 id='flyoutTitle'>Hold Orders</h2>
                    </EuiTitle>
                </EuiFlyoutHeader>
                <EuiFlyoutBody style={{ width: '100%' }}>
                    <EuiFlexGroup direction='column' style={{ padding: '10px' }}>
                        {holdOrder.length > 0 ? (
                            holdOrder.map((order, index) => (
                                <>
                                    <EuiAccordion
                                        paddingSize='s'
                                        id={`accordionForm${index}`}
                                        className='euiAccordionForm'
                                        arrowDisplay='right'
                                        id='accordion1'
                                        buttonContent={
                                            <>
                                                <EuiFlexItem style={{ margin: '10px 0' }}>
                                                    <EuiFlexItem
                                                        grow={7}
                                                        style={{
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                            alignItems: 'flex-start',
                                                        }}
                                                    >
                                                        <span className='orderFirstTitle'>
                                                            {capitalize(getTableName(order))}
                                                        </span>
                                                        <span className='orderSecondTitle'>
                                                            {capitalize(order.customer_details.name)}
                                                        </span>
                                                        <span className='orderDetailOutletName'>
                                                            {order.customer_details.country_code
                                                                ? order.customer_details.country_code
                                                                : ''}{' '}
                                                            {order.customer_details.mobile_number}
                                                        </span>
                                                    </EuiFlexItem>
                                                </EuiFlexItem>
                                            </>
                                        }
                                    >
                                        {itemList(order)}

                                        <EuiFlexItem style={{ flexDirection: 'row', margin: '15px 0 8px 0' }}>
                                            <EuiButton fill size='s' onClick={() => onRemoveOrder(index)}>
                                                clear
                                            </EuiButton>

                                            <EuiButton
                                                fill
                                                size='s'
                                                style={{ marginLeft: '10px' }}
                                                onClick={() => addOrder(order, index)}
                                            >
                                                Add
                                            </EuiButton>
                                        </EuiFlexItem>
                                    </EuiAccordion>
                                </>
                            ))
                        ) : (
                            <div
                                style={{
                                    display: 'flex',
                                    width: '100%',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                <b>No orders on Hold</b>
                            </div>
                        )}
                    </EuiFlexGroup>
                </EuiFlyoutBody>
                <EuiFlyoutFooter>
                    <EuiFlexGroup justifyContent='spaceBetween'>
                        <EuiFlexItem grow={false}>
                            <EuiButtonEmpty iconType='cross' onClick={onCloseModal} flush='left'>
                                Close
                            </EuiButtonEmpty>
                        </EuiFlexItem>
                    </EuiFlexGroup>
                </EuiFlyoutFooter>
            </EuiFlyout>
        </>
    );
};

export default HoldOrderModal;
