import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { isEmpty } from 'lodash';
import { useForm, FormProvider } from 'react-hook-form';
import { htmlIdGenerator } from '@elastic/eui';
import BasicDetails from './BasicDetails';
import Configure from './Configure';
import Clubbing from './Clubbing';
import VisibilityAndLinking from './VisibilityAndLinking';
import ValidityAndAcceptibility from './ValidityAndAcceptibility';
import OfferTimming from '../../../components/offer/offerTimming';
import TermsAndConditions from '../../../components/offer/termsAndConditions';
import CoupledOffer from '../../../components/offerModification/coupledOffer';
import API from '../../../api/axios/API';
import { useHistory } from 'react-router-dom';
import ApplyOrderingModes from './ApplyOrderingModes';
import {
    fetchCategoryItemsForAddonLink,
    fetchRestaurantCatalogue,
} from '../../../components/menuComponentsRefactorCopy/catalogue/Api/FetchCatalogueInfo';
import {
    resetBasicDetails,
    resetClubbingDetails,
    resetConfigureDetails,
    resetCoupledOfferCheck,
    resetOfferApply,
    resetOfferCreationForm,
    resetOfferTimingDetails,
    resetValidityApplicability,
    resetVisibilityLinkingDetails,
} from '../Utils/formResetUtilities';
import { getItemOrVariantName, timeSlotDataOffers as timeSlotData } from '../Utils/utils';
import { toastsErrorMessage, toastSuccessMessage } from '../../../utils/toasts';
import OfferValidationError from './OfferValidationError';
import OfferSection from './OfferSection';
import { COUPLED_OFFER, FREE_DELIVERY, MENU_DISCOUNT } from '../OfferTypes.js/OfferTypes';

const OfferCreateEditForm = ({ offer, selectedOfferType }) => {
    const languageId = useSelector((state) => state.language.languageId);
    const languages = useSelector((state) => state.language.availableLanguages);
    const [categories, setCategories] = useState([]);
    const restaurantOrderingModes = useSelector((state) => state.restaurantReducer.orderingModes);

    const history = useHistory();
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(true);
    const [showError, setShowError] = useState(true);
    const [dummyState, setDummyState] = useState('');
    const [defaultValue, setDefaultValue] = useState({});
    const [errorMessage, setErrorMessage] = useState('');
    const [submitError, setSubmitError] = useState(false);

    useEffect(() => {
        if (!offer?.id || selectedOfferType !== COUPLED_OFFER) {
            setLoading(false);
        }
    }, [selectedOfferType, offer?.id]);

    const getItemsOfSelectedCategory = useCallback(
        async (id) => {
            let items = [];
            let categoryData = {};

            if (categories?.length) {
                var results = Promise.all(
                    categories.map(async (category) => {
                        if (category?.category_id === id) {
                            await fetchCategoryItemsForAddonLink(category.category_id, languageId)
                                .then((response) => {
                                    items = [...response.items];
                                    categoryData = {
                                        id: category?.category_id,
                                        label: category?.internal_name,
                                        isSubCategory: category.parent_id ? true : false,
                                        data: category,
                                        items: response.items,
                                        foo: true,
                                    };
                                    setLoading(false);
                                })
                                .catch((errr) => {
                                    setLoading(false);
                                });
                        }
                    })
                );
                await results;
            }

            return { items, categoryData };
        },
        [categories, languageId]
    );

    const methods = useForm({ defaultValues: defaultValue });
    const {
        handleSubmit,
        watch,
        reset,
        formState: { isDirty },
    } = methods;

    useEffect(() => {
        if (isDirty) {
            dispatch({
                type: 'set-portal-active',
                payload: {
                    portalActive: {
                        resetChanges: resetChanges,
                        submit: handleSubmit(onSubmit),
                    },
                },
            });
        } else {
            dispatch({
                type: 'set-portal-active',
                payload: {
                    portalActive: {},
                },
            });
        }
    }, [isDirty]);

    useEffect(() => {
        if (selectedOfferType === COUPLED_OFFER) {
            fetchRestaurantCatalogue(languageId)
                .then((response) => {
                    if (response.success) {
                        setCategories(response.categories);
                    }
                })
                .catch((err) => {});
        }
    }, [selectedOfferType, languageId, dispatch]);

    const coupledOffer = useCallback(
        (defaultValue, offerDetails) => {
            return new Promise(async (resolve, reject) => {
                setLoading(true);
                let id;
                if (offerDetails?.linked_categories?.length) {
                    id = offerDetails.linked_categories[0].primary_category_id;
                }

                if (id) {
                    let { items, categoryData } = await getItemsOfSelectedCategory(id);
                    defaultValue.primary_category_id = [categoryData];

                    let innerAray = [];
                    offerDetails.linked_categories.forEach((item) => {
                        let name = getItemOrVariantName(item, items, languageId);
                        innerAray.push({ ...item, name });
                    });
                    defaultValue['items'] = innerAray;
                    resolve(defaultValue);
                }
            });
        },
        [getItemsOfSelectedCategory, languageId]
    );

    const resetChanges = useCallback(async () => {
        const defaultValue = {};
        if (offer?.id) {
            if (offer.offer_type === 'coupled_offer') {
                setLoading(true);
            }
            defaultValue.offer_type = offer.offer_type;

            resetBasicDetails(defaultValue, offer);

            await resetCoupledOfferCheck(defaultValue, selectedOfferType, offer, coupledOffer);

            resetConfigureDetails(defaultValue, selectedOfferType, offer, languageId);

            resetOfferApply(defaultValue, offer);

            resetVisibilityLinkingDetails(defaultValue, offer);

            resetClubbingDetails(defaultValue, offer);

            resetValidityApplicability(defaultValue, offer, moment);

            resetOfferTimingDetails(defaultValue, offer);

            reset({
                ...defaultValue,
                time: defaultValue.time ? defaultValue.time : [],
                week: defaultValue.week
                    ? defaultValue.week
                    : { sunday: [], monday: [], tuesday: [], wednesday: [], thursday: [], friday: [], saturday: [] },
            });

            setDummyState(htmlIdGenerator()());
        } else {
            if (selectedOfferType) {
                resetOfferCreationForm(defaultValue, selectedOfferType, languages, restaurantOrderingModes);

                reset({ ...defaultValue });
            }
        }

        setDefaultValue(defaultValue);
    }, [coupledOffer, offer, languageId, languages, reset, restaurantOrderingModes, selectedOfferType]);

    useEffect(() => {
        resetChanges();
    }, [resetChanges]);

    const onSubmit = async (data) => {
        timeSlotData(data);

        data.offer_type = selectedOfferType;

        if (selectedOfferType === 'coupled_offer') {
            data.primary_category_id = data.primary_category_id[0].id;
            let items = [];
            data.items.forEach((item) => {
                if (item?.secondary_category_id?.length) {
                    items.push({ ...item, secondary_category_id: item.secondary_category_id[0].id });
                }
            });

            delete data.items;
            data.linked_categories = items;
        }

        if (data.min_cart_amount) {
            data.min_cart_amount = data.minimum_cart_amount_value;
        } else {
            data.min_cart_amount = 0;
        }

        if (data.max_discount_amount) {
            data.max_discount_amount = data.max_discount_amount_value;
        } else {
            data.max_discount_amount = 0;
        }
        // if (data.min_cart_item_quantity !== '') {
        //     if (data.min_cart_item_quantity === true) {
        //         data.min_cart_item_quantity = 0;
        //     } else {
        //         data.min_cart_item_quantity = data.min_cart_item_quantity_value;
        //     }
        // }

        if (data.buy_x1 !== '') {
            data.min_cart_item_quantity = data.buy_x1;
        }

        if (data.get_y1 !== '') {
            data.quantity_of_free_item = data.get_y1;
        }
        // set title and description
        if (data.offer_type !== 'menu_discount') {
            const title = {};
            const description = {};
            for (const property in data.translations.title) {
                title[parseInt(property)] = data.translations.title[property];
            }
            for (const property in data.translations.description) {
                description[parseInt(property)] = data.translations.description[property];
            }
            data.translations.title = title;
            data.translations.description = description;
        } else {
            data.translations = null;
        }

        if (!isEmpty(data?.translations?.terms_and_conditions)) {
            let terms_and_conditions = {};

            for (const property in data.translations.terms_and_conditions) {
                terms_and_conditions[parseInt(property)] = data.translations.terms_and_conditions[property];
            }

            data.translations.terms_and_conditions = terms_and_conditions;
        }

        if (data.allow_offer_clubbing) {
            data.allow_offer_clubbing = 1;
        } else {
            data.allow_offer_clubbing = 0;
        }
        if (data.auto_apply) {
            data.auto_apply = 1;
        } else {
            data.auto_apply = 0;
        }

        data.promo_consolidation = data.promo_consolidation ? 1 : 0;

        if (data.max_usage_count) {
            data.max_usage_count = data.number_of_coupen_available;
        } else {
            data.max_usage_count = 0;
        }

        if (data.max_usage_count_per_user) {
            data.max_usage_count_per_user = data.number_of_coupen_available_per_user;
        } else {
            data.max_usage_count_per_user = 0;
        }
        if (data.display_in_cart_tab) {
            data.display_in_cart_tab = 1;
        } else {
            data.display_in_cart_tab = 0;
        }
        if (data.display_in_offers_tab) {
            data.display_in_offers_tab = 1;
        } else {
            data.display_in_offers_tab = 0;
        }

        if (data.customer_ids === true) {
            data.customer_ids = [];
        } else {
            data.customer_ids = data.selected_users;
        }

        if (data.offer_type === 'free_delivery') {
            data.delivery = 1;
            data.dine_in = 0;
            data.takeaway = 0;
        } else {
            // set delivery
            if (data.offer_for_delivary === false) {
                data.delivery = 0; //display not
            } else {
                data.delivery = 1; //display
            }

            // set dinein
            if (data.offer_for_dining === false) {
                data.dine_in = 0;
            } else {
                data.dine_in = 1;
            }

            // set takeaway
            if (data.offer_for_pickup === false) {
                data.takeaway = 0;
            } else {
                data.takeaway = 1;
            }
        }

        if (data.catogaries === true || data.catogaries === 'all_categories') {
            data.required_items = null;
            data.catogaries = null;
        } else if (data.catogaries === 'specific_categories') {
            data.required_items = null;
            data.categories = data.category.categories;
            if (data.categories) {
                data.categories.forEach((item, index) => {
                    item.quantity = parseInt(item.quantity);
                });
            }
        } else {
            data.catogaries = null;
            if (data?.required_items?.length) {
                data.required_items.forEach((item, index) => {
                    item.quantity = parseInt(item.quantity);
                });
            }
        }
        // set free item quantity
        if (data?.free_items?.items) {
            data.free_items.items.forEach((item, index) => {
                item.quantity = parseInt(item.quantity);
            });
        }

        data.validate_from = data.validate_from ? moment(data.validate_from).format('YYYY-MM-DD') : null;
        data.validate_to = data.validate_to ? moment(data.validate_to).format('YYYY-MM-DD') : null;
        const filteredOrderingModes = data.ordering_modes.filter((mode) => mode?.restaurant_ordering_mode_id);

        data.ordering_modes = filteredOrderingModes;

        data.min_required_order_counts =
            data?.min_required_order_counts >= 0 ? parseInt(data.min_required_order_counts) : null;

        data.max_required_order_counts =
            data?.max_required_order_counts >= 0 ? parseInt(data.max_required_order_counts) : null;

        if (data.offer_type === 'free_delivery') {
            const deliveryMode = restaurantOrderingModes?.filter((orderingMode) => orderingMode.type === 'delivery');
            if (deliveryMode?.length) {
                data.ordering_modes = [
                    {
                        restaurant_ordering_mode_id: deliveryMode[0].id,
                        restaurant_ordering_mode_name: deliveryMode[0].display_name,
                    },
                ];
            }
        }

        if (offer?.id) {
            data.id = offer?.id;
            await handleUpdateOfferAction(data);
        } else {
            await handleOfferCreationAction(data);
        }
    };

    const handleUpdateOfferAction = useCallback(
        async (data) => {
            try {
                const offerEdit = await API.put(`restaurants/:restaurantId/offers/${data.id}`, data);
                if (offerEdit.success) {
                    dispatch({
                        type: 'set-portal-active',
                        payload: {
                            portalActive: {},
                        },
                    });
                    setErrorMessage('');
                    setSubmitError(false);
                    history.goBack();
                    toastSuccessMessage('Offer successfully updated.', dispatch);
                } else {
                    setSubmitError(true);
                    setErrorMessage(offerEdit.errors);
                }
            } catch (e) {
                toastsErrorMessage('Error in updating offer.', dispatch);
                setErrorMessage(e.errors);
                setSubmitError(true);
            }
        },
        [dispatch, history]
    );

    const handleOfferCreationAction = useCallback(
        async (data) => {
            try {
                const addOffer = await API.post(`restaurants/:restaurantId/offers`, data);
                if (addOffer.success) {
                    reset({});
                    toastSuccessMessage('Offer successfully added', dispatch);
                    history.goBack();
                    dispatch({
                        type: 'set-portal-active',
                        payload: {
                            portalActive: {},
                        },
                    });
                    setErrorMessage('');
                    setSubmitError(false);
                } else {
                    setSubmitError(true);
                    setErrorMessage(addOffer.errors);
                }
            } catch (e) {
                toastsErrorMessage('Error in adding offer', dispatch);
                setErrorMessage(e.errors);
                setSubmitError(true);
            }
        },
        [dispatch, history, reset]
    );

    if (loading) {
        return '';
    }

    return (
        <FormProvider {...methods}>
            {selectedOfferType !== COUPLED_OFFER ? (
                <>
                    {selectedOfferType !== MENU_DISCOUNT && (
                        <OfferSection
                            Component={<BasicDetails selectedOfferType={selectedOfferType} />}
                            initialIsOpen={true}
                            title='Basic Details'
                        />
                    )}
                    <OfferSection
                        Component={<Configure selectedOfferType={selectedOfferType} allCategories={categories} />}
                        title='Configure'
                    />
                    <OfferSection Component={<Clubbing selectedOfferType={selectedOfferType} />} title='Clubbing' />

                    {selectedOfferType !== MENU_DISCOUNT && (
                        <OfferSection Component={<VisibilityAndLinking />} title='Visibily and Linking' />
                    )}
                    {selectedOfferType !== FREE_DELIVERY && (
                        <OfferSection Component={<ApplyOrderingModes />} title='Offer Apply' />
                    )}
                    <OfferSection
                        Component={<ValidityAndAcceptibility selectedOfferType={selectedOfferType} />}
                        title='Validity and Applicability'
                    />
                    <OfferSection Component={<OfferTimming />} title='Offer Timming' />
                    <OfferSection Component={<TermsAndConditions />} title='Terms &#38; conditions' />
                </>
            ) : (
                <>
                    <OfferSection
                        Component={<BasicDetails selectedOfferType={selectedOfferType} />}
                        initialIsOpen={true}
                        title='Basic Details'
                    />

                    {offer?.id ? (
                        <>
                            {watch('primary_category_id')?.length ? (
                                <OfferSection
                                    Component={<CoupledOffer dummyState={dummyState} allCategories={categories} />}
                                    title='Item List'
                                />
                            ) : null}
                        </>
                    ) : (
                        <OfferSection
                            Component={<CoupledOffer dummyState={dummyState} allCategories={categories} />}
                            title='Item List'
                        />
                    )}
                </>
            )}
            <OfferValidationError
                submitError={submitError}
                showError={showError}
                setShowError={setShowError}
                errorMessage={errorMessage}
            />
        </FormProvider>
    );
};

export default React.memo(OfferCreateEditForm);
