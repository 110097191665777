import { EuiFlexGroup, EuiFlexItem, EuiText, EuiNotificationBadge } from '@elastic/eui';
import React from 'react';

const TableStatusColorInformer = () => {
    const statusInformer = new Array([
        { color: '#FFFFFF', label: 'Idle' },
        { color: '#7de2b5', label: 'Running Order' },
        { color: '#E94616', label: 'KOT Running' },
    ]);

    return (
        <>
            <EuiFlexGroup gutterSize='s' alignItems='center' justifyContent='flexStart'>
                {statusInformer[0].map((item, idx) => {
                    return (
                        <React.Fragment key={idx.toString()}>
                            <EuiFlexItem grow={false}>
                                {item.label === 'Idle' ? (
                                    <div
                                        style={{
                                            borderRadius: '100%',
                                            background: '#FFF',
                                            height: '12px',
                                            width: '12px',
                                            border: `2px solid grey`,
                                        }}
                                    />
                                ) : (
                                    <EuiNotificationBadge
                                        component='div'
                                        style={{
                                            background: item.color,
                                            clipPath: 'circle(40% at 50% 50%)',
                                            height: '12px',
                                            width: '12px',
                                        }}
                                        size='s'
                                    />
                                )}
                            </EuiFlexItem>
                            <EuiFlexItem grow={false}>
                                <EuiText>{item.label}</EuiText>
                            </EuiFlexItem>
                        </React.Fragment>
                    );
                })}
            </EuiFlexGroup>
        </>
    );
};

export default React.memo(TableStatusColorInformer);
