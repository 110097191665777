import React, { useRef } from 'react';
import {
    EuiFlexGroup,
    EuiFlexItem,
    EuiStat,
    EuiButton,
    EuiPanel,
    EuiButtonIcon,
    EuiFormRow,
    EuiFieldNumber,
    EuiFieldText,
} from '@elastic/eui';
import { Controller, useFieldArray } from 'react-hook-form';
import { isEmpty } from 'lodash';

const AmountSplitTab = (props) => {
    const { watch, setValue, errors, control } = props;

    const numberRef = useRef(null);

    const { fields, append, remove } = useFieldArray({
        control,
        name: 'choicesAmount',
    });

    const remainingAmountField = () => {
        let percentage = props.total;
        let record = watch();
        if (!isEmpty(record)) {
            record.choicesAmount.map((choice, i) => {
                percentage -= parseFloat(choice.number);
            });
        }

        if (percentage < 0 || !percentage) {
            percentage = 0;
        }

        return percentage;
    };

    const onWheel = () => {
        numberRef && numberRef.current && numberRef.current.blur();
    };

    return (
        <EuiFlexGroup direction={'column'}>
            <EuiFlexItem>
                <EuiFlexItem
                    style={{ width: '25%', marginTop: '10px', justifyContent: 'flex-end', flexDirection: 'row' }}
                >
                    <EuiButton
                        style={{ width: '10%' }}
                        onClick={() => append({ number: remainingAmountField(), name: `Person ${fields.length + 1}` })}
                    >
                        Add Person
                    </EuiButton>
                </EuiFlexItem>
                <EuiPanel style={{ width: '25%', marginTop: '10px' }}>
                    <EuiStat title={props.total} description='Total' textAlign='center' titleSize='l' />
                </EuiPanel>
            </EuiFlexItem>

            <EuiFlexItem style={{ width: '98%', flexDirection: 'row', flexWrap: 'wrap' }}>
                {fields.map((field, index) => {
                    return (
                        <EuiPanel
                            key={field.id}
                            style={{
                                width: '20%',
                                marginTop: '10px',
                                marginRight: '10px',
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >
                            <EuiButtonIcon
                                iconType={'minusInCircle'}
                                disabled={fields.length == 2}
                                onClick={() => {
                                    remove(index);
                                }}
                            />
                            <EuiFlexItem style={{ alignItems: 'center' }}>
                                <EuiFormRow
                                    fullWidth={true}
                                    error={
                                        errors[`choicesAmount[${index}].name`]
                                            ? errors[`choicesAmount[${index}].name`].message
                                            : ''
                                    }
                                    isInvalid={errors[`choicesAmount[${index}].name`]}
                                    style={{ width: '150px' }}
                                >
                                    <Controller
                                        render={({ field }) => (
                                            <EuiFieldText value={field.value} onChange={field.onChange} />
                                        )}
                                        name={`choicesAmount[${index}].name`}
                                        placeholder='Customer name'
                                        control={control}
                                        fullWidth={true}
                                        isInvalid={
                                            errors[`choicesAmount[${index}].name`] &&
                                            errors[`choicesAmount[${index}].name`].message
                                        }
                                        defaultValue={field[`name`]}
                                    />
                                </EuiFormRow>
                                <EuiFormRow
                                    fullWidth={true}
                                    error={
                                        errors[`choicesAmount[${index}].number`]
                                            ? errors[`choicesAmount[${index}].number`].message
                                            : ''
                                    }
                                    isInvalid={errors[`choicesAmount[${index}].number`]}
                                    style={{ width: '150px' }}
                                >
                                    <Controller
                                        render={({ field }) => (
                                            <EuiFieldNumber value={field.value} onChange={field.onChange} />
                                        )}
                                        min={0}
                                        name={`choicesAmount[${index}].number`}
                                        placeholder='Amount'
                                        control={control}
                                        fullWidth={true}
                                        inputRef={numberRef}
                                        onWheel={onWheel}
                                        step={'any'}
                                        isInvalid={
                                            errors[`choicesAmount[${index}].number`] &&
                                            errors[`choicesAmount[${index}].number`].message
                                        }
                                        defaultValue={field[`number`]}
                                    />
                                </EuiFormRow>
                                {/* <EuiStat
                                    title={percentageCalculateField(index)}
                                    textAlign='center'
                                    titleSize="l" 
                                /> */}
                            </EuiFlexItem>
                            {/* <EuiFlexItem style={{flexDirection: 'row', justifyContent: 'flex-end'}}>
                                <div style={{width: '100px'}}>
                                    <EuiFormRow
                                        fullWidth={true}
                                        error={
                                            errors[`choicesAmount[${index}].number`] ? errors[`choicesAmount[${index}].number`].message : ''
                                        }
                                        isInvalid={errors[`choicesAmount[${index}].number`]}
                                    >
                                        <Controller
                                            render={({ field }) => <EuiFieldNumber value={field.value} onChange={field.onChange} />}
                                            min={0}
                                            name={`choicesAmount[${index}].number`}
                                            placeholder='Customer number'
                                            control={control}
                                            fullWidth={true}
                                            inputRef={numberRef}
                                            onWheel={onWheel}
                                            step={"any"}
                                            isInvalid={errors[`choicesAmount[${index}].number`] && errors[`choicesAmount[${index}].number`].message}
                                            defaultValue={field[`number`]}
                                        />
                                    </EuiFormRow>
                                </div>
                            </EuiFlexItem>   */}
                        </EuiPanel>
                    );
                })}
            </EuiFlexItem>
        </EuiFlexGroup>
    );
};

export default AmountSplitTab;
