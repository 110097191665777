import React, { useState } from 'react';
import { EuiText, EuiFlexGroup, EuiFlexItem, EuiButton, EuiSpacer } from '@elastic/eui';
import API from '../../api/axios/API';
import { getLiveOrders } from '../../api/liveOrders/getLiveOrderNewAPI';
import { getLiveOrderDetailsAPI } from '../../api/liveOrders/getLiveOrderDetailsAPI';
import { useDispatch } from 'react-redux';

export const ETLDeliveryManagement = (props) => {
    const dispatch = useDispatch();
    const { order } = props;
    const [loading, setLoading] = useState(false);

    const assignEltRider = async () => {
        setLoading(true);
        let eltResponse = '';
        props.setRiderModalOpen();
        try {
            eltResponse = await API.post(`/restaurants/:restaurantId/elt/assign-orders `, {
                orderId: props.orderId,
            });
        } catch (error) {
            eltResponse = error;
        }

        if (eltResponse.success) {
            dispatch(getLiveOrders());
            dispatch(getLiveOrderDetailsAPI());
            alert(eltResponse.eltresponse.response.order_status);
        } else {
            alert(eltResponse.message);
        }
        setLoading(false);
    };

    return (
        <EuiFlexGroup direction='column'>
            <EuiFlexItem>
                <EuiText>ELT assigned order can't be cancelled.</EuiText>
                <EuiSpacer />
                <EuiButton fullWidth={true} isLoading={loading} fill onClick={assignEltRider}>
                    Assign ELT Rider
                </EuiButton>
            </EuiFlexItem>
        </EuiFlexGroup>
    );
};
