import React, { useEffect } from 'react';
import { EuiForm, EuiButton, EuiSpacer, EuiFormRow, EuiFlexItem, EuiFlexGroup, EuiButtonEmpty } from '@elastic/eui';
import { Controller, useForm } from 'react-hook-form';
import TextField from '../../../components/Form/TextField';
import FormGroupDescription from '../../../components/formGroupDescription';
import LoaderButton from '../../../components/Form/loaderButton';
import { useDispatch, useSelector } from 'react-redux';
import { outletSettingsAPI } from '../../../api/outlet/outletSettingsAPI';
import * as types from '../../../reduxStore/types/outlet';
import { userHasPermission } from '../../../helper/auth';

const FoodLicence = (props) => {
    const { getOutletData, outletId, fetchOutletById } = props;
    const {
        handleSubmit,
        control,
        watch,
        reset,
        setValue,

        formState: { isDirty, errors },
    } = useForm();
    const dispatch = useDispatch();
    const permissions = useSelector((state) => state.permission.permission);
    const restaurantDescriptions = useSelector((state) => state.auth.restaurantDescriptions);

    useEffect(() => {
        if (!userHasPermission(permissions, '/outlet-settings', 'write')) return;

        if (isDirty) {
            dispatch({
                type: 'set-portal-active',
                payload: {
                    portalActive: {
                        resetChanges: resetChanges,
                        submit: handleSubmit(onSubmit),
                    },
                },
            });
        } else {
            dispatch({
                type: 'set-portal-active',
                payload: {
                    portalActive: {},
                },
            });
        }
    }, [isDirty]);

    const resetChanges = () => {
        let defaultValue = {};
        defaultValue.fssai_licence =
            getOutletData.outlet && getOutletData.outlet.fssai_licence && getOutletData.outlet.fssai_licence.licence;
        reset({
            ...defaultValue,
        });
    };

    useEffect(() => {
        if (getOutletData && getOutletData.outlet) {
            resetChanges();
        }
    }, [getOutletData]);

    const onSubmit = (data) => {
        dispatch(outletSettingsAPI('licence', data)).then((res) => {
            if (res.success) {
                dispatch({
                    type: types.SET_PORTAL_ACTIVE,
                    payload: {
                        portalActive: {},
                    },
                });
                dispatch({
                    type: types.SET_PORTAL_ACTIVE_TAB_SWITCHED,
                    payload: {
                        tabSwitched: false,
                    },
                });
                fetchOutletById();
            } else {
                alert(res.message);
            }
        });
    };

    return (
        <>
            <EuiForm component='form' onSubmit={handleSubmit(onSubmit)} style={{ marginTop: 20 }}>
                <FormGroupDescription
                    title={restaurantDescriptions?.license_number?.display_name}
                    description={restaurantDescriptions?.license_number?.description}
                >
                    <TextField
                        errors={errors}
                        label={<b>Licence</b>}
                        name='fssai_licence'
                        placeholder='Please enter fssai licence'
                        control={control}
                    />
                    <EuiSpacer />
                </FormGroupDescription>
            </EuiForm>
        </>
    );
};

export default FoodLicence;
