import React from 'react';
import { MainLayout } from '../../components/layout/mainLayout/mainLayout';
import Rider from '../../components/riders/rider';

const RidersPage = () => {
    return (
        <MainLayout title='Riders' outlet={true}>
            <Rider />
        </MainLayout>
    );
};

export default RidersPage;
