import API from '../axios/API';

export const orderStatusChange = (orderId, data) => {
    return async (dispatch, getState) => {
        let response;
        let { outlet } = getState();

        try {
            response = await API.patch(`/restaurants/:restaurantId/orders/${orderId}/change-status`, data);
        } catch (error) {
            response = error;
        }

        return response;
    };
};
