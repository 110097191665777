import { GET_OUTLET_ORDERING_MODES } from '../../reduxStore/types/outlet';
import API from '../axios/API';

export const fetchOutletOrderingModes = (outletId, section = 'ordering_mode') => {
    return async (dispatch) => {
        let resposne;

        try {
            resposne = await API.get(
                `${process.env.REACT_APP_BASE_URL_V4}/restaurants/:restaurantId/outlets/${outletId}?section=${section}`
            );

            dispatch({
                type: GET_OUTLET_ORDERING_MODES,
                payload: resposne.outlet,
            });
        } catch (error) {
            resposne = error;
        }

        return resposne;
    };
};
