import { EuiButtonGroup } from '@elastic/eui';
import React from 'react';
import { toggleButtonsCompressed } from '../../utils/utils';
import './styles.css';

const AvailabilitySwitch = ({
    toggleCompressedIdSelected,
    handleAvailabilitySwitchChange,
    buttonColor,
    buttonSize,
    isCategory,
    selectedElement,
    name,
}) => {
    return (
        <EuiButtonGroup
            name={name}
            options={toggleButtonsCompressed}
            legend='Availability Switch'
            idSelected={toggleCompressedIdSelected}
            onChange={(optionId) =>
                handleAvailabilitySwitchChange(optionId, isCategory ? true : false, selectedElement)
            }
            color={buttonColor}
            isFullWidth
            buttonSize={'compressed'}
            className='availability-switch'
        />
    );
};

export default React.memo(AvailabilitySwitch);
