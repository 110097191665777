import React, { useState } from 'react';
import { EuiDragDropContext, EuiDroppable } from '@elastic/eui';
import { useSelector } from 'react-redux';
import LoadingOverlay from 'react-loading-overlay';
import CategoryRow from './categoryRow';

const CategoryChildRow = (props) => {
    const { category, isOutletMenu } = props;
    const categories = useSelector((state) => state.menuReducer.restaurantCategories);

    const [childCategories, setChildCategories] = useState(
        categories.filter((caetgoryItem) => caetgoryItem.parent_id === category.category_id)
    );

    return (
        <>
            <EuiDragDropContext onDragEnd={(dragProp) => {}}>
                <EuiDroppable droppableId='DROPPABLE_AREA_FOR_SUBCATEGORIES'>
                    <LoadingOverlay spinner text='Rearranging sequence'>
                        {childCategories.map((subCategory, index) => (
                            <CategoryRow
                                key={subCategory.category_id}
                                isOutletMenu={isOutletMenu}
                                category={subCategory}
                                index={index}
                            />
                        ))}
                    </LoadingOverlay>
                </EuiDroppable>
            </EuiDragDropContext>
        </>
    );
};

export default CategoryChildRow;
