import React, { useEffect, useState } from 'react';
import {
    EuiFlexItem,
    EuiFormRow,
    EuiSelect,
    EuiDragDropContext,
    EuiDroppable,
    EuiText,
    euiDragDropReorder,
} from '@elastic/eui';
import { Controller } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import AddonCard from './addOnCardSequence';
import { isEmpty } from 'lodash';
import * as types from '../../../reduxStore/types/menu';

const VariantAddonLinking = (props) => {
    const { option, control, withVariant, variantAddonLinkState, setVariantAddonLinkState, isOutletMenu } = props;
    const languageId = useSelector((state) => state.language.languageId);
    const addOnList = useSelector((state) => state.menuReducer.addons);
    const variantAddonLink = useSelector((state) => state.menu.variantAddonLink);
    const [addonSelected, setAddonSelected] = useState('');
    const [addOnListState, setAddOnListState] = useState([]);

    const selectedAddonDetails = (id) => {
        let addonDetail = {};
        addOnListState.map((addon) => {
            if (addon.value == id) {
                addonDetail = addon.record;
            }
        });

        return addonDetail;
    };

    useEffect(() => {
        let addon_list = [...addOnList];

        if (variantAddonLinkState[option.id] && variantAddonLinkState[option.id].length) {
            variantAddonLinkState[option.id].map((addon) => {
                addon_list.map((addonList, index) => {
                    if (addonList.value == addon.addon_group_id) {
                        addon_list.splice(index, 1);
                    }
                });
            });
            setAddOnListState(addon_list);
        }
        if (addon_list?.length) {
            let addOnArray = [{ value: 0, text: 'Select addon', record: {} }];
            addon_list?.map((addon) =>
                addOnArray.push({
                    value: addon.addon_group_id,
                    text: `${addon.translations.group_name[languageId]} - ${addon.internal_name}`,
                    record: addon,
                })
            );

            if (addOnArray.length) {
                setAddOnListState(addOnArray);
            }
        }
    }, [addOnList]);

    const onChangeAddon = (value) => {
        let addonDetails = selectedAddonDetails(value);

        let innerObj = JSON.parse(JSON.stringify(variantAddonLinkState));

        if (innerObj[option.id]) {
            let innerArray = [...innerObj[option.id]];
            innerArray.push(addonDetails);
            innerObj[option.id] = innerArray;
        } else {
            innerObj[option.id] = [addonDetails];
        }

        setVariantAddonLinkState(innerObj);

        setAddonSelected(value);

        removeAddonFromList(value);
    };

    const filterAddons = (id, currentId) => {
        return id == currentId;
    };

    const removeAddonFromList = (value) => {
        let addonArray = [...addOnListState];
        let indexNumber = addonArray.findIndex((addon) => filterAddons(addon.value, value));
        if (indexNumber >= 0) {
            addonArray.splice(indexNumber, 1);
            setAddOnListState(addonArray);
        }
    };

    const addAddonToList = (addon) => {
        setAddOnListState([
            ...addOnListState,
            { value: addon.addon_group_id, text: addon.translations.group_name[languageId], record: addon },
        ]);
    };

    const onDragEnd = ({ source, destination }) => {
        if (source && destination) {
            const items = euiDragDropReorder(variantAddonLinkState[option.id], source.index, destination.index);

            let innerObj = JSON.parse(JSON.stringify(variantAddonLinkState));
            innerObj[option.id] = items;

            setVariantAddonLinkState(innerObj);
        }
    };

    const onDeleteAddon = (index) => {
        let innerObj = JSON.parse(JSON.stringify(variantAddonLinkState));
        let innerArray = innerObj[option.id];

        addAddonToList(innerArray[index]);

        innerArray.splice(index, 1);

        innerObj[option.id] = innerArray;

        setVariantAddonLinkState(innerObj);
    };

    return (
        <>
            <div
                style={{
                    backgroundColor: 'rgb(242, 240, 240)',
                    width: '100%',
                    fontSize: '14px',
                    margin: '10px 0 0',
                    padding: '10px 0 10px 20px',
                }}
            >
                <h1>{withVariant ? option[`variant_title-${languageId}`] : option.translations.title[languageId]}</h1>
            </div>

            <div style={{ display: 'flex', flexDirection: 'column', width: '100%', margin: '10px' }}>
                <EuiFlexItem>
                    <EuiFormRow label='Add on Groups' fullWidth={true}>
                        <Controller
                            render={({}) => (
                                <EuiSelect
                                    options={addOnListState}
                                    onChange={(e) => onChangeAddon(e.target.value)}
                                    value={addonSelected}
                                    disabled={isOutletMenu}
                                />
                            )}
                            name='group_name'
                            fullWidth={true}
                            control={control}
                        />
                    </EuiFormRow>
                </EuiFlexItem>

                <EuiFlexItem
                    style={{
                        flexDirection: 'row',
                        overflowY: 'auto',
                        width: '100%',
                        marginTop: '10px',
                    }}
                >
                    <EuiDragDropContext onDragEnd={onDragEnd}>
                        <EuiDroppable
                            droppableId='CUSTOM_HANDLE_DROPPABLE_AREA'
                            spacing='m'
                            withPanel
                            direction='horizontal'
                            style={{
                                display: 'flex',
                                width: '100%',
                                heigth: '200px',
                                overflow: 'auto',
                            }}
                        >
                            {(!isEmpty(variantAddonLinkState) &&
                                variantAddonLinkState[option.id] &&
                                variantAddonLinkState[option.id].length > 0) ||
                            variantAddonLink.length ? (
                                <AddonCard
                                    addOnList={
                                        variantAddonLinkState[option.id]
                                            ? variantAddonLinkState[option.id]
                                            : variantAddonLink[option.id]
                                    }
                                    addOnDelete={onDeleteAddon}
                                    disabled={isOutletMenu}
                                />
                            ) : (
                                <EuiText> No AddOns Found</EuiText>
                            )}
                        </EuiDroppable>
                    </EuiDragDropContext>
                </EuiFlexItem>
            </div>
        </>
    );
};

export default VariantAddonLinking;
