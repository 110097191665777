import { EuiFlexGroup, EuiFlexItem, EuiLoadingContent, EuiBasicTable, EuiFieldSearch, EuiBeacon } from '@elastic/eui';
import React, { useEffect, useState, useCallback } from 'react';
import TableAssignData from './tableAssignData';
import FormStepsFooter from './formStepsFooter';
import API from '../../api/axios/API';
import { useDispatch, useSelector } from 'react-redux';
import * as types from '../../reduxStore/types/billing';
import * as typesTableManagement from '../../reduxStore/types/tableManagement';
import { isEmpty } from 'lodash';
import { getSectionList } from '../../api/tableManagement/sectionList';
import _ from 'lodash';
import { ReactComponent as Clock } from '../../assets/img/clock.svg';
import { ReactComponent as TableBookingTime } from '../../assets/img/tableBookingTime.svg';
import { nextBooking } from '../../helper/nextBooking';
import { getTimeAgo } from '../../helper/getPrettyTime';
import { capitalize } from '../../helper/capitalize';
import { getSectionTableStructure } from '../../helper/getSectionTableStructure';
import { getTableDetails } from '../../api/billing/getTableDetails';

const TableAssign = (props) => {
    const { errors, watch, outletId, setButtonClicked, reset } = props;
    const [tableList, setTableList] = useState([]);
    const [sectionList, setSectionList] = useState({});
    const dispatch = useDispatch();

    const tableSelectedList = useSelector((state) => state.billing.tableSelectedList);
    const dineInStepsStatus = useSelector((state) => state.billing.dineInStepsStatus);
    const mode = useSelector((state) => state.billing.mode);
    const [items, setItems] = useState([]);
    const [tableSearchState, setTableSearchState] = useState([]);

    const getTables = () => {
        let innerArray = [];
        if (!isEmpty(sectionList)) {
            Object.entries(sectionList).forEach(([key, value]) => {
                innerArray.push({
                    id: value.id,
                    tableName: key,
                });

                value.tables.map((table) => {
                    // if(table.table_mode === mode){
                    innerArray.push({
                        id: table.outlet_table_id,
                        tableName: table.name,
                        customer:
                            table.status_information &&
                            table.status_information.customer &&
                            table.status_information.customer.name,
                        pax:
                            table.status_information && table.status_information.cover_count
                                ? `${table.status_information.cover_count}/${table.capacity}`
                                : `0/${table.capacity}`,
                        amount: `₹${table.net_total.toFixed(2)}`,
                        status: table.status,
                        time: table.status_information.occupied_at ? table.status_information.occupied_at : '',
                        table: table,
                    });
                    // }
                });
            });
        } else if (!isEmpty(tableList)) {
            {
                tableList.map((table) => {
                    // if(table.table_mode === mode){
                    innerArray.push({
                        id: table.outlet_table_id,
                        tableName: table.name,
                        customer:
                            table.status_information &&
                            table.status_information.customer &&
                            table.status_information.customer.name,
                        pax:
                            table.status_information && table.status_information.cover_count
                                ? `${table.status_information.cover_count}/${table.capacity}`
                                : `0/${table.capacity}`,
                        amount: `₹${table.net_total.toFixed(2)}`,
                        status: table.status,
                        time: table.status_information.occupied_at ? table.status_information.occupied_at : '',
                        table: table,
                    });
                    // }
                });
            }
        }

        if (innerArray.length) {
            setItems(innerArray);
            setTableSearchState(innerArray);
        }
    };

    useEffect(() => {
        getTables();
    }, [tableList, sectionList]);

    const tableSelect = (tableData) => {
        if (tableSelectedList.length > 0) {
            if (tableSelectedList[0] === tableData.outlet_table_id) {
                dispatch({
                    type: types.SET_TABLE_SELECTED_LIST,
                    payload: {
                        tableSelectedList: [],
                    },
                });
            } else {
                tableListUpdate(tableData);
            }
        } else {
            tableListUpdate(tableData);
        }
    };

    const tableListUpdate = (tableData) => {
        let innerArray = [];

        if (tableData) {
            let selected = innerArray.findIndex((id) => {
                return id === tableData.outlet_table_id;
            });

            if (selected < 0) {
                innerArray.push(tableData.outlet_table_id);
            } else {
                innerArray.splice(selected, 1);
            }

            dispatch({
                type: types.SET_TABLE_SELECTED_LIST,
                payload: {
                    tableSelectedList: innerArray,
                },
            });
        }
    };

    const setTableDetailsData = (tableData) => {
        if (tableData.orders && tableData.orders.length > 0) {
            let orderId = tableData.orders[0].order_id;

            dispatch({
                type: types.SET_PREVIOUS_ORDERS,
                payload: {
                    previousOrders: tableData.orders,
                },
            });

            if (!dineInStepsStatus[orderId]) {
                let statusObj = JSON.parse(JSON.stringify(dineInStepsStatus));

                statusObj[orderId] = {
                    kot: false,
                    bill: false,
                    payment: false,
                    close: false,
                };

                dispatch({
                    type: types.SET_DINE_IN_STEPS_STATUS,
                    payload: {
                        dineInStepsStatus: statusObj,
                    },
                });
            }

            dispatch({
                type: types.SET_ORDER_ID,
                payload: {
                    orderId: orderId,
                },
            });

            dispatch({
                type: types.SET_CUSTOMER_INFORMATION_FINISH,
                payload: {
                    finishCustomerInfo: true,
                },
            });

            dispatch({
                type: types.SET_CUSTOMER_INFORMATION,
                payload: {
                    customerInformation: tableData.orders[0].customer,
                },
            });

            dispatch({
                type: types.SET_TABLE_SELECTED_LIST,
                payload: {
                    tableSelectedList: [tableData.outlet_table_id],
                },
            });

            dispatch({
                type: types.SET_TABLE_DATA,
                payload: {
                    tableData: tableData,
                },
            });
        } else {
            dispatch({
                type: types.SET_CUSTOMER_INFORMATION_FINISH,
                payload: {
                    finishCustomerInfo: true,
                },
            });

            dispatch({
                type: types.SET_CUSTOMER_INFORMATION,
                payload: {
                    customerInformation: tableData.status_information.customer,
                },
            });

            dispatch({
                type: types.SET_TABLE_SELECTED_LIST,
                payload: {
                    tableSelectedList: [tableData.outlet_table_id],
                },
            });

            dispatch({
                type: types.SET_TABLE_DATA,
                payload: {
                    tableData: tableData,
                },
            });
        }
    };

    const onTableRunning = (tableData) => {
        dispatch(getTableDetails(tableData.outlet_table_id)).then((res) => {
            if (res.success) {
                setTableDetailsData(res.orders);
            }
        });
    };

    const tableManagement = (tableData) => {
        switch (tableData.status) {
            case 'Running':
                onTableRunning(tableData);
                return;
            case 'Idle':
                tableSelect(tableData);
                return;
            case 'KotRunning':
                onTableRunning(tableData);
                return;
            case 'occupied':
                onTableRunning(tableData);
                return;
            case 'bill_print':
                onTableRunning(tableData);
                return;
            default:
                return;
        }
    };

    const colorCondition = (status) => {
        switch (status) {
            case 'Idle':
                return '#16e865';
            case 'occupied':
                return '#bbe9fa';
            case 'KotRunning':
                return '#fff8bd';
            case 'Running':
                return '#f6bcbd';
            case 'BillPrinted':
                return '#bbfad6';
            case 'paymentDone':
                return '#bdc2fa';
        }
    };

    const columns = [
        {
            field: 'tableName',
            name: <b>Table Name</b>,
            sortable: true,
            render: (tableName, items) => {
                if (!items.pax) {
                    return (
                        <div style={{ paddingTop: '4px' }}>
                            <span>
                                {tableName === '+  Add New' ? (
                                    <b>{capitalize(tableName)}</b>
                                ) : (
                                    <span>{capitalize(tableName)}</span>
                                )}
                            </span>
                        </div>
                    );
                }

                return (
                    <div
                        style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: '10px' }}
                        onClick={() => tableSelect()}
                    >
                        <div style={{ marginRight: '8px' }}>
                            <EuiBeacon style={{ backgroundColor: colorCondition(items.status) }} />
                        </div>
                        <div style={{ paddingTop: '4px' }}>
                            <span>
                                {tableName === '+  Add New' ? (
                                    <b>{capitalize(tableName)}</b>
                                ) : (
                                    <span>{capitalize(tableName)}</span>
                                )}
                            </span>
                        </div>
                    </div>
                );
            },
        },
        {
            field: 'pax',
            name: <b>Pax/Capacity</b>,
            render: (pax) => <span>{pax}</span>,
        },
        {
            field: 'customer',
            name: <b>Customer</b>,
            render: (customer) => <span>{customer}</span>,
        },
        {
            field: 'amount',
            name: <b>Amount</b>,
            render: (amount) => <span>{amount}</span>,
        },
        {
            field: 'time',
            name: <b>Time</b>,
            render: (time, items) => {
                if (items.status !== 'Idle' && time) {
                    return (
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end' }}>
                            <div style={{ paddingBottom: '1px' }}>
                                <Clock />
                            </div>
                            <div style={{ padding: '0px 0px 2px 5px' }}>
                                <span style={{ fontSize: '14px' }}>{getTimeAgo(time)}</span>
                            </div>
                        </div>
                    );
                } else if (['Idle', 'BillPrinted'].includes(items.status) && nextBooking(items.booking_requests)) {
                    return (
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                height: '25px',
                                marginTop: '10px',
                                borderRadius: '3px',
                                backgroundColor: '#275af6',
                            }}
                        >
                            <div style={{ paddingLeft: '2px' }}>
                                <TableBookingTime />
                            </div>
                            <span fill style={{ fontSize: '14px', paddingLeft: '10px', color: 'white' }}>
                                Booking in {nextBooking(items.booking_requests)}
                            </span>
                        </div>
                    );
                }
            },
        },
    ];

    const getRowProps = (item) => {
        const { id, table } = item;
        return {
            'data-test-subj': `row-${id}`,
            className: !item.pax ? 'customRowClassForSection' : 'customRowClass',
            onClick: () => {
                tableManagement(table);
            },
        };
    };

    const getCellProps = (item, column) => {
        const { id } = item;
        const { field } = column;
        return {
            className: !item.pax ? 'customerClassForRowHeight' : 'customCellClass',
            'data-test-subj': `cell-${id}-${field}`,
            textOnly: true,
        };
    };

    const fetchSectionList = () => {
        dispatch(getSectionList(outletId)).then((res) => {
            if (res.success) {
                dispatch({
                    type: typesTableManagement.SET_SECTION_LIST,
                    payload: {
                        sectionList: res.outlet_table_section,
                    },
                });
            } else {
                alert('Error in fetching section list');
            }
        });
    };

    const getTableList = async () => {
        setSectionList({});
        setTableList([]);
        let response;
        try {
            response = await API.get(`/restaurants/:restaurantId/outlets/${outletId}/tables`);
        } catch (error) {
            response = error;
        }

        if (response.success) {
            if (response.outlet_tables.length && response.outlet_tables[0].section) {
                setSectionList(getSectionTableStructure(response.outlet_tables));
            } else {
                setTableList(response.outlet_tables);
            }

            dispatch({
                type: types.SET_TABLES,
                payload: {
                    tables: response.outlet_tables,
                },
            });
        } else {
            alert('Error in fetching tables');
        }
    };

    useEffect(() => {
        if (outletId) {
            getTableList();
        }
    }, [outletId]);

    const tablesData = () => {
        let innerArray = [];

        if (!isEmpty(sectionList)) {
            Object.entries(sectionList).forEach(([key, value]) => {
                innerArray.push(
                    <>
                        <div
                            style={{
                                backgroundColor: '#f2f0f0',
                                width: '100%',
                                fontSize: '14px',
                                margin: '10px 0px 0px',
                                padding: '10px 0px 10px 20px',
                            }}
                        >
                            <b>{key}</b>
                        </div>

                        <div className='tableList' style={{ paddingLeft: '20px', paddingTop: '15px' }}>
                            {value.tables.map((table) => (
                                <TableAssignData
                                    tableData={table}
                                    selectedOutlet={outletId}
                                    getTableList={getTableList}
                                />
                            ))}
                        </div>
                    </>
                );
            });
        } else if (!isEmpty(tableList)) {
            innerArray.push(
                <div className='tableList' style={{ paddingLeft: '20px', paddingTop: '15px' }}>
                    {tableList.map((table) => (
                        <TableAssignData tableData={table} selectedOutlet={outletId} getTableList={getTableList} />
                    ))}
                </div>
            );
        }

        return <>{innerArray}</>;
    };

    const fetchmatchingTables = (value, items) => {
        let innerArray = [];

        items.map((table) => {
            if (table.tableName.toLowerCase().includes(value.toLowerCase())) {
                innerArray.push(table);
            }
        });

        setItems(innerArray);
    };

    const delayedQuery = useCallback(
        _.debounce((q, items) => fetchmatchingTables(q, items), 500),
        []
    );

    const searchTables = (e) => {
        if (e.target.value) {
            delayedQuery(e.target.value, tableSearchState);
        } else {
            setItems([...tableSearchState]);
        }
    };

    const renderTables = () => {
        if (isEmpty(tableList) && isEmpty(sectionList)) {
            return <EuiLoadingContent lines={10} />;
        }

        return (
            <>
                <div className='tableList' style={{ padding: '15px 0' }}>
                    <EuiBasicTable
                        items={items}
                        rowHeader='tableName'
                        columns={columns}
                        rowProps={getRowProps}
                        cellProps={getCellProps}
                    />
                </div>

                <EuiFlexItem>
                    <FormStepsFooter
                        reset={reset}
                        errors={errors}
                        watch={watch}
                        step={1}
                        {...props}
                        setButtonClicked={setButtonClicked}
                    />
                </EuiFlexItem>
            </>
        );
    };

    return (
        <>
            <EuiFlexGroup direction='column'>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                    <EuiFieldSearch
                        fullWidth
                        placeholder='Search table'
                        onChange={searchTables}
                        isClearable={true}
                        aria-label='search of table'
                        defaultChecked=''
                    />
                </div>
                {renderTables()}
            </EuiFlexGroup>
        </>
    );
};

export default TableAssign;
