import React from 'react';
import { isEmpty } from 'lodash';
import RupeeSymbol from '../rupeeComponent.js/index.js';
import './style.css';

const OrderTotalBill = (props) => {
    const { selectedOrder } = props;

    const offersListDiscountAmount = (order) => {
        let offers = 0;

        if (order?.cart?.offers && order?.cart?.offers?.length) {
            order.cart.offers.map((offer) => {
                if (offer.offer_type !== 'free_delivery') {
                    offers += offer.discount_amount;
                }
                return null;
            });
        }

        return offers;
    };

    const checkFreeDeliveryOffer = (order) => {
        let offers = '';

        if (order?.cart?.offers && order?.cart?.offers?.length) {
            order.cart.offers.map((offer) => {
                if (offer.offer_type === 'free_delivery' && offer.coupon_code) {
                    offers += offer.coupon_code + ', ';
                }
                return null;
            });

            if (offers) {
                offers = '[' + offers.substring(0, offers.length - 2) + ']';
            }
        }

        return offers;
    };

    const offersList = (order) => {
        let offers = '';

        if (order?.cart?.offers && order?.cart?.offers?.length) {
            order.cart.offers.map((offer) => {
                if (offer.offer_type !== 'free_delivery' && offer.coupon_code) {
                    offers += offer.coupon_code + ', ';
                }
                return null;
            });

            if (offers) {
                offers = '[' + offers.substring(0, offers.length - 2) + ']';
            }
        }

        return offers;
    };

    if (isEmpty(selectedOrder)) return null;
    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
            <table style={{ width: '100%' }}>
                {offersListDiscountAmount(selectedOrder) ? (
                    <tr className='borderColorForTable'>
                        <td className='borderForFirstTd'>
                            <span>Discount {offersList(selectedOrder)}</span>
                        </td>
                        <td className='borderForSecondTd'>
                            <RupeeSymbol size={'12px'} />
                            {parseFloat(offersListDiscountAmount(selectedOrder)).toFixed(2)}
                        </td>
                    </tr>
                ) : (
                    ''
                )}

                {selectedOrder.cart &&
                !isEmpty(selectedOrder.cart.cashback) &&
                selectedOrder.cart.cashback.used_cashback ? (
                    <tr className='borderColorForTable'>
                        <td className='borderForFirstTd'>Cashback</td>
                        <td className='borderForSecondTd'>
                            <RupeeSymbol size={'12px'} />
                            {selectedOrder.cart.cashback.used_cashback}
                        </td>
                    </tr>
                ) : (
                    ''
                )}

                <tr className='borderColorForTable'>
                    <td className='borderForFirstTd'>
                        <span>Gross Total</span>
                    </td>
                    <td className='borderForSecondTd'>
                        <RupeeSymbol size={'12px'} />
                        {selectedOrder?.gross_total?.toFixed(2)}
                    </td>
                </tr>

                <tr className='borderColorForTable'>
                    <td className='borderForFirstTd'>Other Charges & Taxes</td>
                    <td className='borderForSecondTd'>
                        {selectedOrder?.cart?.gst_amount ||
                        selectedOrder?.cart?.packaging_charge ||
                        selectedOrder?.cart?.convenience_charge ? (
                            <>
                                <RupeeSymbol size={'12px'} />
                                {(
                                    selectedOrder.cart.gst_amount +
                                    selectedOrder.cart.packaging_charge +
                                    selectedOrder.cart.convenience_charge
                                ).toFixed(2)}
                            </>
                        ) : (
                            <>
                                <RupeeSymbol size={'12px'} />
                                0.00
                            </>
                        )}
                    </td>
                </tr>
                {!props.isFromTableManagement ? (
                    <tr className='borderColorForTable'>
                        <td className='borderForFirstTd'>Delivery Charge {checkFreeDeliveryOffer(selectedOrder)}</td>
                        <td className='borderForSecondTd'>
                            {checkFreeDeliveryOffer(selectedOrder) ? (
                                <span style={{ color: 'green' }}>Free </span>
                            ) : (
                                <>
                                    <RupeeSymbol size={'12px'} />
                                    {selectedOrder.delivery_charge.toFixed(2)}{' '}
                                </>
                            )}
                        </td>
                    </tr>
                ) : null}

                <tr className='borderColorForTable'>
                    <td className='borderForFirstTd'>
                        <b>Net Total</b>
                    </td>
                    <td className='borderForSecondTd'>
                        <b>
                            <RupeeSymbol size={'12px'} />
                            {selectedOrder?.net_total?.toFixed(2)}
                        </b>
                    </td>
                </tr>
            </table>
        </div>
    );
};

export default OrderTotalBill;
