import { EuiPopover, EuiSuperSelect, EuiText } from '@elastic/eui';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const FilterAvailability = ({ handleFilterChange, isLoading }) => {
    const outletId = useSelector((state) => state.outlet.selectedOutletId);
    const categories = useSelector((state) => state.itemAvailabilityReducer.outlet_categories);
    const filterOptions = [
        {
            value: 'all_items',
            inputDisplay: <EuiText size='s'>All Items</EuiText>,
        },
        {
            value: 'out_of_stock',
            inputDisplay: <EuiText size='s'>Out Of Stock</EuiText>,
        },
    ];

    const [toggleIconId, setToggleIconId] = useState();

    useEffect(() => {
        setToggleIconId(filterOptions[0].value);
    }, [outletId, categories]);

    const handleSelectionChange = useCallback(
        (value) => {
            handleFilterChange(value);
            setToggleIconId(value);
        },
        [handleFilterChange]
    );

    return (
        <EuiPopover
            button={
                <EuiSuperSelect
                    style={{ width: '250px' }}
                    options={filterOptions}
                    valueOfSelected={toggleIconId}
                    onChange={handleSelectionChange}
                    disabled={isLoading}
                />
            }
        />
    );
};

export default React.memo(FilterAvailability);
