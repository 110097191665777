import { EuiButton, EuiFlexGroup, EuiFlexItem, EuiImage, EuiPanel, EuiText } from '@elastic/eui';
import React, { useCallback, useState } from 'react';
import _ from 'lodash';

const MediaSelectionImage = ({ image, selectedImages, handleImageSelection, isModuleComponent }) => {
    const [allowFullScreen, setAllowFullScreen] = useState(false);

    const handleFullScreenImage = useCallback(() => {
        setAllowFullScreen(true);
    }, []);

    const handleOnClick = useCallback(() => {
        !isModuleComponent ? handleImageSelection(image) : handleFullScreenImage();
    }, [handleFullScreenImage, handleImageSelection, image, isModuleComponent]);

    return (
        <>
            <EuiFlexItem grow={false}>
                <EuiPanel className={selectedImages?.id === image.id && `active-border-card`}>
                    <EuiFlexGroup direction='column'>
                        <EuiFlexItem>
                            <EuiImage
                                allowFullScreen={allowFullScreen}
                                size='m'
                                hasShadow
                                alt={image.original_file_name}
                                src={image.image_url}
                            />
                        </EuiFlexItem>
                        <EuiFlexItem>
                            <EuiText
                                size='s'
                                style={{
                                    wordBreak: 'break-all',
                                    maxWidth: '228px',
                                    margin: '0 auto',
                                }}
                            >
                                {_.truncate(image.original_file_name, {
                                    length: 50,
                                })}
                            </EuiText>
                        </EuiFlexItem>
                        {!isModuleComponent ? (
                            <EuiFlexItem>
                                <EuiButton style={{ width: '100%' }} onClick={handleOnClick}>
                                    {!isModuleComponent ? 'Select this image' : 'View Image'}
                                </EuiButton>
                            </EuiFlexItem>
                        ) : null}
                    </EuiFlexGroup>
                </EuiPanel>
            </EuiFlexItem>
        </>
    );
};

export default React.memo(MediaSelectionImage);
