import { EuiBottomBar, EuiButtonIcon, EuiFlexItem, EuiPortal, EuiText } from '@elastic/eui';
import { isEmpty } from 'lodash';
import React from 'react';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import Expire from '../Form/Expire';

const TabSwitchErrorPortal = () => {
    const dispatch = useDispatch();
    let tabSwitched = useSelector((state) => state.outlet.tabSwitched);
    const portalActive = useSelector((state) => state.outlet.portalActive);

    const closeErrorPortal = useCallback(() => {
        dispatch({
            type: 'set-portal-active-tab-switched',
            payload: {
                tabSwitched: false,
            },
        });
    }, [dispatch]);

    return (
        <>
            {!isEmpty(portalActive) && tabSwitched ? (
                <Expire delay='3000'>
                    <EuiPortal>
                        {/* opacity: tabSwitched ? '0.5': '', */}
                        <EuiBottomBar
                            style={{ backgroundColor: tabSwitched ? 'red' : '', padding: '20px', zIndex: 10000 }}
                        >
                            <EuiFlexItem style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <EuiFlexItem>
                                    <EuiButtonIcon
                                        onClick={closeErrorPortal}
                                        color='#ffffff'
                                        iconType='crossInACircleFilled'
                                    />
                                </EuiFlexItem>
                                <EuiFlexItem style={{ marginLeft: '10px' }}>
                                    <EuiText>Save or Discard changes</EuiText>
                                </EuiFlexItem>
                            </EuiFlexItem>
                        </EuiBottomBar>
                    </EuiPortal>
                </Expire>
            ) : null}
        </>
    );
};

export default React.memo(TabSwitchErrorPortal);
