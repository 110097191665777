import {
    GET_PET_POOJA_ITEMS,
    GET_PET_POOJA_LINKED_ITEMS,
    GET_SYSTEM_CATEGORIES,
    GET_PET_POOJA_TAXES,
    GET_PET_POOJA_RESTAURANT,
    SET_PET_POOJA_LINKED_ADDONS,
    SET_SYSTEM_ADDON_GROUPS,
    SET_PET_POOJA_ADDON_GROUPS,
} from '../types/PetPoojaItemTypes';

const initialState = {
    systemCategories: [],
    petPoojaItems: null,
    petPoojaLinkedItems: null,
    petPoojaTaxes: null,
    petPoojaRestaurant: null,
    systemAddonGroups: null,
    petPoojaAddonGroups: null,
    petPoojaLinkedAddons: null,
};

export default function petPoojaReducer(state = initialState, action) {
    switch (action.type) {
        case GET_SYSTEM_CATEGORIES:
            return {
                ...state,
                systemCategories: action.payload,
            };
        case GET_PET_POOJA_ITEMS:
            return {
                ...state,
                petPoojaItems: action.payload,
            };
        case GET_PET_POOJA_LINKED_ITEMS:
            return {
                ...state,
                petPoojaLinkedItems: action.payload,
            };
        case GET_PET_POOJA_TAXES:
            return { ...state, petPoojaTaxes: action.payload };

        case GET_PET_POOJA_RESTAURANT:
            return {
                ...state,
                petPoojaRestaurant: action.payload,
            };
        case SET_PET_POOJA_LINKED_ADDONS:
            return {
                ...state,
                petPoojaLinkedAddons: action.payload,
            };
        case SET_SYSTEM_ADDON_GROUPS:
            return {
                ...state,
                systemAddonGroups: action.payload,
            };
        case SET_PET_POOJA_ADDON_GROUPS:
            return {
                ...state,
                petPoojaAddonGroups: action.payload,
            };

        default:
            return state;
    }
}
