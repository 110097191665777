import React, { useCallback, useRef } from 'react';
import {
    EuiFlexGroup,
    EuiFieldNumber,
    EuiFormRow,
    EuiSpacer,
    EuiFieldText,
    EuiSelect,
    EuiText,
    EuiFlexItem,
    EuiButtonIcon,
    EuiCollapsibleNavGroup,
    EuiButton,
} from '@elastic/eui';
import { Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';
import ChoicePricing from './ChoicePricing';
import ChoiceExpose from './ChoiceExpose';
import CheckBoxField from '../../Form/CheckBoxField';

export const AddOnChoices = (props) => {
    const { control, errors, setValue, watch } = props;
    const { fields, append, remove } = props.fieldArray;
    const availableLanguages = useSelector((state) => state.language.availableLanguages);
    const orderingModes = useSelector((state) => state.menuReducer.restaurantOrderingModes);
    const priceRef = useRef(null);
    const comparePriceRef = useRef(null);
    const onChangeFoodType = useCallback(
        (value, index) => {
            props.setValue(`choices[${index}].food_type`, value);
        },
        [props]
    );

    const onRemove = useCallback(
        (index) => {
            remove(index);
        },
        [remove]
    );

    const onAppend = useCallback(() => {
        let newChoice = {};
        availableLanguages.forEach((language) => {
            newChoice[`choice_title${language.language_id}`] = '';
        });
        newChoice['price'] = 0;
        newChoice['compare_price'] = 0;
        newChoice['gst_slab'] = '0';
        newChoice['show_agmark'] = '';
        newChoice['food_type'] = 'vegetarian';
        newChoice['image'] = null;
        newChoice['choice_id'] = null;
        append(newChoice);
    }, [append, availableLanguages]);

    const onWheel = useCallback(() => {
        priceRef && priceRef.current && priceRef.current.blur();
        comparePriceRef && comparePriceRef.current && comparePriceRef.current.blur();
    }, []);

    return (
        <EuiFlexGroup direction='column'>
            <EuiFlexItem>
                <EuiButton fill onClick={() => onAppend()}>
                    Add Choice
                </EuiButton>
            </EuiFlexItem>

            {fields.map((field, index) => {
                return (
                    <EuiCollapsibleNavGroup
                        key={field.id}
                        background='light'
                        title={
                            <EuiText>
                                <h3>{`Choice ${index + 1}`}</h3>
                            </EuiText>
                        }
                        isCollapsible={true}
                        initialIsOpen={false}
                        arrowDisplay={'left'}
                        extraAction={
                            <EuiFlexItem style={{ marginRight: '10px' }}>
                                <EuiButtonIcon
                                    aria-label='cross'
                                    onClick={() => onRemove(index)}
                                    title='Delete this choice'
                                    iconType='cross'
                                />
                            </EuiFlexItem>
                        }
                    >
                        <EuiFlexItem>
                            {availableLanguages.map((language, i) => {
                                return (
                                    <div key={i}>
                                        <EuiFormRow
                                            label={`Choice Title - ${language.language_label}`}
                                            error={
                                                errors.choices &&
                                                errors.choices.hasOwnProperty(index) &&
                                                errors.choices[index][`choice_title${language.language_id}`]
                                                    ? errors.choices[index][`choice_title${language.language_id}`]
                                                          .message
                                                    : ''
                                            }
                                            isInvalid={
                                                errors.choices &&
                                                errors.choices.hasOwnProperty(index) &&
                                                errors.choices[index][`choice_title${language.language_id}`]
                                                    ? true
                                                    : false
                                            }
                                        >
                                            <Controller
                                                render={({ field }) => (
                                                    <EuiFieldText
                                                        placeholder='Addon choice title'
                                                        value={field.value}
                                                        onChange={field.onChange}
                                                    />
                                                )}
                                                name={`choices[${index}].choice_title${language.language_id}`}
                                                control={props.control}
                                                fullWidth={true}
                                                rules={{
                                                    required: 'Please enter addon choice title',
                                                }}
                                                isInvalid={
                                                    errors.choices &&
                                                    errors.choices.hasOwnProperty(index) &&
                                                    errors.choices[index][`choice_title${language.language_id}`]
                                                        ? true
                                                        : false
                                                }
                                                defaultValue={field[`choice_title${language.language_id}`]}
                                            />
                                        </EuiFormRow>
                                        <EuiSpacer />
                                    </div>
                                );
                            })}
                        </EuiFlexItem>

                        <ChoiceExpose index={index} setValue={setValue} control={control} watch={watch} field={field} />

                        <EuiFlexItem>
                            <CheckBoxField
                                name={`choices[${index}].is_enabled_same_price_details_for_all_ordering_modes`}
                                label='Different Price for all ordering mode?'
                                control={control}
                                setValue={setValue}
                            />
                        </EuiFlexItem>

                        <ChoicePricing
                            errors={errors}
                            index={index}
                            control={control}
                            field={field}
                            watch={watch}
                            setValue={setValue}
                        />

                        <EuiFlexItem>
                            <EuiText>
                                <span>Type of food :</span>
                            </EuiText>

                            <EuiFormRow>
                                <Controller
                                    render={() => (
                                        <EuiSelect
                                            fullWidth={true}
                                            options={options}
                                            value={props.watch(`choices[${index}].food_type`)}
                                            onChange={(e) => onChangeFoodType(e.target.value, index)}
                                        />
                                    )}
                                    name={`choices[${index}].food_type`}
                                    defaultValue={props.watch(`choices[${index}].food_type`, field[`food_type`])}
                                    control={props.control}
                                />
                            </EuiFormRow>
                            <EuiSpacer />
                        </EuiFlexItem>
                        <EuiSpacer size='s' style={{ color: '#e9f1f8' }} />
                    </EuiCollapsibleNavGroup>
                );
            })}
        </EuiFlexGroup>
    );
};

const gstOptions = [
    { value: '0', text: '0%' },
    { value: '5', text: '5%' },
    { value: '12', text: '12%' },
    { value: '18', text: '18%' },
    { value: '28', text: '28%' },
];

const options = [
    { value: 'non-vegetarian', text: 'Non Veg' },
    { value: 'vegetarian', text: 'Veg' },
    { value: 'egg', text: 'Eggs' },
];
