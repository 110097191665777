import React, { useState, useEffect, useRef } from 'react';
import {
    EuiOverlayMask,
    EuiModal,
    EuiModalHeader,
    EuiModalHeaderTitle,
    EuiModalBody,
    EuiFlexGroup,
    EuiFlexItem,
    EuiButton,
    EuiFieldNumber,
    EuiModalFooter,
    EuiButtonIcon,
    EuiTextColor,
} from '@elastic/eui';
import { formatedDataForKitchen } from '../settings/formatedDataForKitchen';
import moment from 'moment';
import { useSelector } from 'react-redux';

export const PreparationTimeModal = (props) => {
    const { order, setPreparationTimeModal, updateOrderStatusCall, pos, setPreparationTimeDetails } = props;
    const [value, setValue] = useState('');
    const numberRef = useRef(null);
    const outletList = useSelector((state) => state.outlet.outletList);
    const selectedOutletId = useSelector((state) => state.outlet.selectedOutletId);

    const filterOutlet = () => {
        let outletData;
        outletList.map((outlet) => {
            if (outlet.value === selectedOutletId) {
                outletData = outlet.outlet_data;
            }
        });
        return outletData;
    };
    const getOutlet = () => {
        if (pos) {
            return filterOutlet();
        } else {
            return order.outlet;
        }
    };

    const rushHoursMethod = (formatedData) => {
        let rush_time;
        // let currentHour = moment().format('HH:MM');
        let currentHour = parseInt(moment().format('HH:MM').split(':').join(''));
        // let currentMinute = parseInt(moment().format('mm'));
        formatedData.map((innerDate) => {
            let startHour = parseInt(innerDate.start_time.split(':').join(''));
            // let startMinute = parseInt(innerDate.start_time.split(':')[1]);
            let endHour = parseInt(innerDate.end_time.split(':').join(''));
            // let endMinute = parseInt(innerDate.end_time.split(':')[1]);
            // , "start min ", startMinute, "end min ", endMinute
            // console.log("current hrs ", currentHour, "start hrs ", startHour, "end hrs ", endHour)
            if (
                startHour < currentHour &&
                currentHour < endHour
                // startMinute <= currentMinute &&
                // currentMinute < endMinute
            ) {
                rush_time = innerDate.preparation_time_in_rush;
            }
            return null;
        });
        return rush_time;
    };

    useEffect(() => {
        let currentHour = parseInt(moment().format('hh'));
        let currentMinute = parseInt(moment().format('mm'));
        let order = getOutlet();
        if (order.settings.rush_hour_time_slot_setting === 'no_time_slots') {
            setValue(parseInt(order.settings.kitchen_preparation_time));
        } else if (order.settings.rush_hour_time_slot_setting === 'same_time_slot_for_all_days') {
            let data = formatedDataForKitchen(order.settings.rush_hour_schedules);
            let rush_time = 0;
            for (var key in data) {
                rush_time = rushHoursMethod(data[key]);
            }
            if (rush_time) {
                setValue(rush_time);
            } else {
                setValue(parseInt(order.settings.kitchen_preparation_time));
            }
        } else if (order.settings.rush_hour_time_slot_setting === 'different_time_slots_for_different_days') {
            let data = formatedDataForKitchen(order.settings.rush_hour_schedules);
            let weekDay = moment().day();
            let rush_time = 0;
            data[weekDay] &&
                data[weekDay].map((innerDate) => {
                    let startHour = parseInt(innerDate.start_time.split(':')[0]);
                    let startMinute = parseInt(innerDate.start_time.split(':')[1]);
                    let endHour = parseInt(innerDate.end_time.split(':')[0]);
                    let endMinute = parseInt(innerDate.end_time.split(':')[1]);
                    if (
                        startHour < currentHour &&
                        currentHour < endHour &&
                        startMinute < currentMinute &&
                        currentMinute < endMinute
                    ) {
                        rush_time = innerDate.preparation_time_in_rush;
                    }
                    return null;
                });
            if (rush_time) {
                setValue(rush_time);
            } else {
                setValue(parseInt(order.settings.kitchen_preparation_time));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onWheel = () => {
        numberRef.current.blur();
    };
    const [invalidNumber, setInvalidNumber] = useState(false);
    const submitOrder = () => {
        if (value >= 1) {
            // pos ? updateOrderStatusCall('restaurant_accepted', value) : updateOrderStatusCall(order, order.order_id, 'restaurant_accepted', value);
            if (
                !props?.order?.outlet?.settings?.enable_auto_assignment_rider_by_priority &&
                !props?.order?.outlet?.settings?.enable_auto_assignment_rider_by_preference &&
                props.liveOrderComponenent &&
                order.ordering_mode == 'delivery' &&
                !(
                    order.rider ||
                    order.elt_order ||
                    order.dunzo_order ||
                    order.wefast_order ||
                    order.scout_order ||
                    order.shadowfax_order
                )
            ) {
                props.setPreparationTimeDetails({ status: 'restaurant_accepted', value });
            } else {
                updateOrderStatusCall('restaurant_accepted', value);
            }

            closeModal();
        } else {
            setInvalidNumber(true);
        }

        if (props.liveOrderComponent) {
            // setPreparationTimeModal(false)
            if (
                !(
                    order.rider ||
                    order.elt_order ||
                    order.dunzo_order ||
                    order.wefast_order ||
                    order.scout_order ||
                    order.shadowfax_order
                ) &&
                order.ordering_mode === 'delivery' &&
                !props?.order?.outlet?.settings?.enable_auto_assignment_rider_by_priority &&
                !props?.order?.outlet?.settings?.enable_auto_assignment_rider_by_preference
            ) {
                props.setRiderModal(true);
            }
        }
    };

    const closeModal = () => {
        if (props.liveOrderComponent) {
            setPreparationTimeModal(false);
            // if(!(order.rider || order.elt_order || order.dunzo_order || order.wefast_order || order.scout_order || order.shadowfax_order) && order.ordering_mode === "delivery"){
            //     props.setRiderModal(true)
            // }
        } else {
            setPreparationTimeModal(false);
        }
    };

    return (
        <>
            <EuiOverlayMask>
                <EuiModal
                    onClose={closeModal}
                    // style={{height: '500px', width: '768px'}}
                >
                    <EuiModalHeader>
                        <EuiModalHeaderTitle>Preparation Estimate</EuiModalHeaderTitle>
                    </EuiModalHeader>

                    <EuiModalBody>
                        <EuiFlexGroup direction='row' alignItems='center'>
                            <EuiFlexItem grow={1}>
                                <EuiButtonIcon
                                    fill
                                    iconType='minusInCircle'
                                    onClick={() =>
                                        setValue((prevState) => {
                                            if (parseInt(prevState) > 1) {
                                                return parseInt(prevState) - 1;
                                            }
                                        })
                                    }
                                />
                            </EuiFlexItem>

                            <EuiFlexItem grow={8}>
                                <EuiFieldNumber
                                    min={1}
                                    prepend='Min'
                                    placeholder='preparation time'
                                    value={value}
                                    onChange={(e) => setValue(e.target.value)}
                                    inputRef={numberRef}
                                    onWheel={onWheel}
                                />
                                {invalidNumber && (
                                    <EuiTextColor style={{ fontSize: '12px' }} color='danger'>
                                        Preparation time should be minimum 1.
                                    </EuiTextColor>
                                )}
                            </EuiFlexItem>

                            <EuiFlexItem grow={1}>
                                <EuiButtonIcon
                                    fill
                                    iconType='plusInCircle'
                                    onClick={() => setValue((prevState) => parseInt(prevState) + 1)}
                                />
                            </EuiFlexItem>
                        </EuiFlexGroup>
                    </EuiModalBody>
                    <EuiModalFooter>
                        <EuiButton fill onClick={closeModal}>
                            Close
                        </EuiButton>
                        <EuiButton fill onClick={() => submitOrder()}>
                            Submit
                        </EuiButton>
                    </EuiModalFooter>
                </EuiModal>
            </EuiOverlayMask>
        </>
    );
};
