import React, { useEffect, useState } from 'react';
import { EuiHeader, EuiIcon, EuiFlexItem, EuiImage, EuiFlexGroup, EuiButton, EuiText, EuiPopover } from '@elastic/eui';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import './header.scss';
import PILOTLOGO from '../../../assets/img/PILOTLOGO.png';
import LogoName from '../../../assets/img/LogoName.png';
import Plane from '../../../assets/img/Plane.png';
import CollapsibleNavbar from './CollapsibleNavbar';
import { OutletToggleDropDown } from '../../outletToggleDropDown';
import { CashToggleDropDown } from '../../cashToggleDropDown';
import { useMemo } from 'react';

export const Header = React.memo((props) => {
    const history = useHistory();
    const currentTheme = useSelector((state) => state.theme.theme);
    const creditAlerts = useSelector((state) => state.creditAlerts.alerts);

    useEffect(() => {
        if (history.location.pathname === '/billing') {
            props.navDrawerRef.current.collapseDrawer();
        }
    }, [history]);

    const isFixed = true;

    useEffect(() => {
        if (isFixed) document.body.classList.add('euiBody--headerIsFixed--double');

        return () => {
            document.body.classList.remove('euiBody--headerIsFixed--double');
        };
    }, [isFixed]);

    const Logo = useMemo(() => {
        return (
            <EuiFlexItem style={{ marginLeft: '10px', marginTop: '5px' }}>
                {currentTheme == 'light' ? (
                    <EuiImage size='s' src={PILOTLOGO} alt='PILOT' />
                ) : (
                    <div>
                        <img style={{ height: '30px', width: '40px' }} src={Plane} alt='plane' />
                        <img style={{ height: '30px', width: '80px' }} src={LogoName} alt='logo' />
                    </div>
                )}
            </EuiFlexItem>
        );
    });

    const outlet_names =
        creditAlerts?.low_credit_outlets?.length > 0 &&
        creditAlerts.low_credit_outlets.map((outlet) => {
            return outlet?.translations?.name['1'];
        });

    const [isPopoverOpen, setIsPopoverOpen] = useState(false);

    const onButtonClick = () => setIsPopoverOpen((isPopoverOpen) => !isPopoverOpen);
    const closePopover = () => setIsPopoverOpen(false);

    const headers = (
        <div
            style={{
                position: 'fixed',
                top: '0',
                width: '100%',
                zIndex: 999,
            }}
        >
            {creditAlerts?.low_credit_outlets?.length > 0 && creditAlerts?.low_credit_outlets_message && (
                <EuiHeader
                    sections={[
                        {
                            items: [
                                <>
                                    {creditAlerts?.low_credit_outlets?.length > 1 ? (
                                        <div
                                            style={{
                                                marginLeft: '25px',
                                                display: 'flex',
                                                direction: 'row',
                                                position: 'relative',
                                            }}
                                        >
                                            <p>{creditAlerts?.low_credit_outlets_message}</p>
                                            <EuiPopover
                                                button={
                                                    <EuiIcon
                                                        tabIndex='10'
                                                        style={{ margin: '0 6px' }}
                                                        type='alert'
                                                        // title='Icon with tooltip'
                                                        onMouseLeave={onButtonClick}
                                                        onMouseEnter={onButtonClick}
                                                    />
                                                }
                                                isOpen={isPopoverOpen}
                                                closePopover={closePopover}
                                            >
                                                <EuiText style={{ width: 150 }}>
                                                    <p>{outlet_names}</p>
                                                </EuiText>
                                            </EuiPopover>
                                        </div>
                                    ) : (
                                        <div style={{ marginLeft: '16px' }}>
                                            {creditAlerts?.low_credit_outlets_message}
                                        </div>
                                    )}
                                </>,
                            ],
                            borders: 'none',
                        },
                        {
                            items: [
                                <EuiFlexItem style={{ marginLeft: '12px', cursor: 'pointer' }}>
                                    <EuiButton color='ghost' size='s' onClick={() => history.push('/credits')}>
                                        Recharge
                                    </EuiButton>
                                </EuiFlexItem>,
                            ],
                            borders: 'none',
                        },
                    ]}
                    style={{
                        background: 'red',
                        color: 'white',
                        border: 'none',
                        zIndex: 1000,
                        borderBottom: '1px solid white',
                    }}
                />
            )}

            {creditAlerts?.low_credit_restaurants_message && (
                <EuiHeader
                    sections={[
                        {
                            items: [
                                <EuiFlexGroup
                                    direction='row'
                                    alignItems='center'
                                    style={{ width: '100%', marginLeft: '13px' }}
                                >
                                    {creditAlerts?.low_credit_restaurants_message && (
                                        <EuiFlexItem>
                                            <EuiText>{creditAlerts?.low_credit_restaurants_message}</EuiText>
                                        </EuiFlexItem>
                                    )}
                                </EuiFlexGroup>,
                            ],
                            borders: 'none',
                        },
                        {
                            items: [
                                <EuiFlexItem style={{ marginLeft: '12px', cursor: 'pointer' }}>
                                    <EuiButton color='ghost' size='s' onClick={() => history.push('/credits')}>
                                        Recharge
                                    </EuiButton>
                                </EuiFlexItem>,
                            ],
                            borders: 'none',
                        },
                    ]}
                    style={{ background: 'red', color: 'white', border: 'none', zIndex: 1000 }}
                />
            )}
            <EuiHeader
                sections={[
                    {
                        items: [<CollapsibleNavbar />, Logo],
                    },
                    {
                        items: [
                            <OutletToggleDropDown />,
                            <svg
                                width='2'
                                height='25'
                                viewBox='0 0 2 25'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                                style={{ margin: '0 16px' }}
                            >
                                <path
                                    d='M1.25 24.75L1.25 0.750001'
                                    stroke={currentTheme === 'dark' ? '#fff' : 'black'}
                                />
                            </svg>,
                            <CashToggleDropDown />,
                        ],
                        borders: 'none',
                    },
                ]}
            />
        </div>
    );

    return (
        <>
            <div>{headers}</div>
        </>
    );
});
