import React from 'react';
import MapComponent from '../map';
import {
    EuiOverlayMask,
    EuiModal,
    EuiModalHeader,
    EuiModalHeaderTitle,
    EuiModalBody,
    EuiModalFooter,
    EuiButton,
} from '@elastic/eui';

const MapModal = (props) => {
    const { setMapModal, errors, control, watch, reset, setValue } = props;
    const onCloseModal = () => {
        setMapModal(false);
    };

    return (
        <>
            <EuiOverlayMask>
                <EuiModal onClose={onCloseModal} style={{ width: '1000px', height: '500px' }}>
                    <EuiModalHeader>
                        <EuiModalHeaderTitle>Addons</EuiModalHeaderTitle>
                    </EuiModalHeader>
                    <EuiModalBody>
                        <MapComponent
                            watch={watch}
                            setValue={setValue}
                            errors={errors}
                            control={control}
                            reset={reset}
                        />
                    </EuiModalBody>

                    <EuiModalFooter>
                        <EuiButton onClick={onCloseModal} fill>
                            Close
                        </EuiButton>
                    </EuiModalFooter>
                </EuiModal>
            </EuiOverlayMask>
        </>
    );
};

export default MapModal;
