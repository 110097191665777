import React, { useState, useEffect, useCallback } from 'react';
import {
    EuiButton,
    EuiButtonEmpty,
    EuiFlexGroup,
    EuiFlexItem,
    EuiFlyout,
    EuiFlyoutBody,
    EuiFlyoutFooter,
    EuiFlyoutHeader,
    EuiTitle,
    EuiSpacer,
    EuiForm,
    EuiRadio,
    EuiText,
    EuiTextColor,
    EuiButtonIcon,
} from '@elastic/eui';
import { useSelector, useDispatch } from 'react-redux';
import { useForm, useFieldArray } from 'react-hook-form';
import { AddOnChoices } from './addOnChoices';
import * as types from '../../../reduxStore/types/addon';
import * as typesMenu from '../../../reduxStore/types/menu';
import API from '../../../api/axios/API';
import { isEmpty } from 'lodash';
import NumberField from '../../Form/NumberField';
import TextField from '../../Form/TextField';
import TextAreaField from '../../Form/TextAreaField';
import ButtonGroup from '../../Form/ButtonGroup';

export const AddOnAddGroupFlyout = (props) => {
    const { defaultValue, apiFetch } = props;
    const editGroupId = useSelector((state) => state.addon.addonEditId);
    const availableLanguages = useSelector((state) => state.language.availableLanguages);
    const buttonDisable = useSelector((state) => state.menu.buttonDisable);
    const addonState = useSelector((state) => state.menu.addonState);
    const [showError, setShowError] = useState(false);
    const dispatch = useDispatch();
    const {
        handleSubmit,
        control,
        watch,
        setValue,

        formState: { errors },
    } = useForm({
        defaultValues: defaultValue,
    });

    const { fields, append, remove } = useFieldArray({
        control,
        name: 'choices',
    });

    const [imageDeleteState, setImageDeleteState] = useState([]);

    useEffect(() => {
        let innerArray = {};
        defaultValue &&
            defaultValue.choices &&
            defaultValue.choices.map((choice) => (innerArray[`${choice.choice_id}`] = choice.image));
        setImageDeleteState(innerArray);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [errorMessage, setErrorMessage] = useState('');

    const minimumRadio = [
        {
            id: 'minimumRadio1',
            label: <EuiText>There is no minimum addOn Selection</EuiText>,
        },
        {
            id: 'minimumRadio2',
            label: <EuiText>Set minimum addOn selection</EuiText>,
        },
    ];

    const maximumRadio = [
        {
            id: 'maximumRadio1',
            label: <EuiText>There is no maximum addOn Selection</EuiText>,
        },
        {
            id: 'maximumRadio2',
            label: <EuiText>Set maximum addOn selection</EuiText>,
        },
    ];

    const [minimumRadioSelected, setMinimumRadioSelected] = useState(
        !defaultValue['minimum'] ? minimumRadio[0].id : minimumRadio[1].id
    );

    const [maximumRadioSelected, setMaximumRadioSelected] = useState(
        !defaultValue['maximum'] ? maximumRadio[0].id : maximumRadio[1].id
    );

    const closeFlyout = useCallback(() => {
        props.setIsOpen(false);
        setMinimumRadioSelected('minimumRadio1');
        setMaximumRadioSelected('maximumRadio1');

        dispatch({
            type: types.SET_ADDON_EDIT_ID,
            payload: {
                editGroupId: null,
            },
        });
        props.setEditAddonObject({});
    }, [dispatch, props]);
    const [buttonLoader, setButtonLoader] = useState(false);

    const onSubmit = async (data) => {
        setShowError(true);
        // setButtonLoader(true);

        // handle prices, ordering_modes
        if (data.choices?.length) {
            for (const choice of data.choices) {
                if (!choice.is_enabled_same_price_details_for_all_ordering_modes) {
                    // Same Price For Every Ordering Mode
                    choice?.ordering_modes.forEach((orderMode) => {
                        orderMode.price = choice.price;
                        orderMode.compare_price = choice.compare_price;
                        orderMode.gst_slab = choice.gst_slab;
                    });
                } else {
                    choice?.ordering_modes.forEach((orderMode) => {
                        orderMode['price'] = choice[`price_${orderMode.restaurant_ordering_mode_id}`];
                        orderMode['compare_price'] = choice[`compare_price_${orderMode.restaurant_ordering_mode_id}`]
                            ? choice[`compare_price_${orderMode.restaurant_ordering_mode_id}`]
                            : 0;
                        orderMode['gst_slab'] = choice[`gst_slab_${orderMode.restaurant_ordering_mode_id}`]
                            ? choice[`gst_slab_${orderMode.restaurant_ordering_mode_id}`]
                            : 0;
                    });
                }
            }
        }

        if (editGroupId) {
            EditGroup(data);
        } else {
            AddGroup(data);
        }
        setButtonLoader(false);
    };

    const AddGroup = async (data) => {
        let groupTranslationObject = { group_name: {}, description: {} };
        let choiceArray = [];

        availableLanguages.map((language, index) => {
            groupTranslationObject.group_name[`${language.language_id}`] = data[`title${language.language_id}`];
            groupTranslationObject.description[`${language.language_id}`] = data[`description${language.language_id}`];
        });

        data['choices'] &&
            data['choices'].map((choice) => {
                let choiceObject = {};
                choiceObject['choice_id'] = choice['choice_id'] ? choice['choice_id'] : null;
                choiceObject['price'] = choice['price'] ? choice['price'] : 0;
                choiceObject['compare_price'] = choice['compare_price'] ? choice['compare_price'] : 0;
                // choiceObject['show_agmark'] = choice['show_agmark'] ? choice['show_agmark'] : false;
                choiceObject['gst_slab'] = choice['gst_slab'];
                choiceObject['food_type'] = choice['food_type'];
                choiceObject['image_id'] = choice['image'] ? choice['image'].id : null;
                choiceObject['ordering_modes'] = choice['ordering_modes'] ? choice['ordering_modes'] : [];
                choiceObject['delete_ordering_modes'] = data?.delete_ordering_modes ? data.delete_ordering_modes : [];

                let choiceTranslationObject = {};

                availableLanguages.map((language) => {
                    choiceTranslationObject[`${language.language_id}`] = choice[`choice_title${language.language_id}`];
                });

                choiceObject['translations'] = {
                    name: choiceTranslationObject,
                };

                choiceArray.push(choiceObject);
                return null;
            });

        let addGroup = '';
        try {
            addGroup = await API.post(`/restaurants/:restaurantId/restaurant-addon-groups`, {
                min_addons: data['minimum'] !== undefined ? data['minimum'] : null,
                max_addons: data['maximum'] !== undefined ? data['maximum'] : null,
                translations: groupTranslationObject,
                choices: choiceArray,
                internal_name: data.internal_name,
                show_agmark: data.show_agmark ? data.show_agmark : 0,
            });
        } catch (error) {
            addGroup = error;
        }

        if (addGroup.success) {
            props.setApiFetchState((prevState) => !prevState);
            apiFetch();
            dispatch({
                type: typesMenu.SET_ADDON_STATE,
                payload: {
                    addonState: !addonState,
                },
            });
            closeFlyout();
            setErrorMessage('');
        } else {
            setErrorMessage(addGroup.errors);
        }
    };

    const imageChecker = useCallback(
        async (data) => {
            await data.choices.map((choice) => {
                if (choice.image) {
                    if (
                        imageDeleteState[`${choice.choice_id}`] &&
                        choice.image.id !== imageDeleteState[`${choice.choice_id}`].id
                    ) {
                        try {
                            API.delete(
                                `/restaurants/:restaurantId/uploads/${imageDeleteState[`${choice.choice_id}`].id}`
                            );
                        } catch (error) {
                            console.log('error in image delete ', error);
                        }
                    }
                } else {
                    if (!isEmpty(imageDeleteState[`${choice.choice_id}`])) {
                        try {
                            API.delete(
                                `/restaurants/:restaurantId/uploads/${imageDeleteState[`${choice.choice_id}`].id}`
                            );
                        } catch (error) {
                            console.log('error in image delete ', error);
                        }
                    }
                }
                return null;
            });
        },
        [imageDeleteState]
    );

    const EditGroup = useCallback(
        async (data) => {
            imageChecker(data);

            let groupTranslationObject = { group_name: {}, description: {} };
            let choiceArray = [];

            availableLanguages.map((language, index) => {
                groupTranslationObject.group_name[`${language.language_id}`] = data[`title${language.language_id}`];
                groupTranslationObject.description[`${language.language_id}`] =
                    data[`description${language.language_id}`];
            });

            data['choices'] &&
                data['choices'].map((choice) => {
                    let choiceObject = {};
                    choiceObject['choice_id'] = choice['choice_id'] ? choice['choice_id'] : null;
                    choiceObject['price'] = choice['price'] ? choice['price'] : 0;
                    choiceObject['compare_price'] = choice['compare_price'] ? choice['compare_price'] : 0;
                    // choiceObject['show_agmark'] = choice['show_agmark'] ? choice['show_agmark'] : false;
                    choiceObject['gst_slab'] = choice['gst_slab'];
                    choiceObject['food_type'] = choice['food_type'];
                    choiceObject['image_id'] = choice['image'] ? choice['image'].id : null;
                    choiceObject['ordering_modes'] = choice['ordering_modes'] ? choice['ordering_modes'] : [];
                    choiceObject['delete_ordering_modes'] = data?.delete_ordering_modes
                        ? data.delete_ordering_modes
                        : [];

                    let choiceTranslationObject = {};
                    availableLanguages.map(
                        (language, index) =>
                            (choiceTranslationObject[`${language.language_id}`] =
                                choice[`choice_title${language.language_id}`])
                    );
                    choiceObject['translations'] = {
                        name: choiceTranslationObject,
                    };

                    choiceArray.push(choiceObject);
                    return null;
                });

            let editGroup = '';
            try {
                editGroup = await API.put(`/restaurants/:restaurantId/restaurant-addon-groups/${editGroupId}`, {
                    min_addons: data['minimum'] !== undefined ? data['minimum'] : null,
                    max_addons: data['maximum'] !== undefined ? data['maximum'] : null,
                    translations: groupTranslationObject,
                    choices: choiceArray,
                    internal_name: data.internal_name,
                    show_agmark: data.show_agmark,
                });
            } catch (err) {
                editGroup = err;
            }

            if (editGroup.success) {
                props.setApiFetchState((prevState) => !prevState);
                closeFlyout();
                setErrorMessage('');
                apiFetch();
                dispatch({
                    type: typesMenu.SET_ADDON_STATE,
                    payload: {
                        addonState: !addonState,
                    },
                });
            } else {
                setErrorMessage(editGroup.errors);
            }
        },
        [addonState, availableLanguages, closeFlyout, dispatch, editGroupId, imageChecker, props]
    );

    const displayErrorServer = () => {
        let errorsList = [];

        if (!isEmpty(errorMessage)) {
            for (var key in errorMessage) {
                errorsList.push(<EuiTextColor color='danger'>{errorMessage[key]}</EuiTextColor>);
            }
        }
        return errorsList;
    };

    const displayErrorMessage = (errors) => {
        let innerArray = [];
        if (!isEmpty(errors)) {
            for (var key in errors) {
                if (key === 'choices') {
                    for (var choiceKey in errors[key]) {
                        for (var innerValidation in errors[key][choiceKey]) {
                            innerArray.push(
                                <EuiText>
                                    <ul>
                                        <li>
                                            {errors[key][choiceKey][innerValidation].message} -{' '}
                                            {parseInt(choiceKey) + 1}
                                        </li>
                                    </ul>
                                </EuiText>
                            );
                        }
                    }
                } else {
                    innerArray.push(
                        <EuiText>
                            <ul>
                                <li>{errors[key].message}</li>
                            </ul>
                        </EuiText>
                    );
                }
            }
        }
        return innerArray;
    };

    return (
        <>
            {props.isOpen && (
                <EuiForm component='form'>
                    <EuiFlyout
                        onClose={closeFlyout}
                        hideCloseButton
                        aria-labelledby='flyoutComplicatedTitle'
                        style={{ zIndex: 11000 }}
                    >
                        <EuiFlyoutHeader hasBorder>
                            <EuiTitle size='m'>
                                <h2 id='flyoutComplicatedTitle'>Manage Add on Group</h2>
                            </EuiTitle>
                        </EuiFlyoutHeader>
                        <EuiFlyoutBody>
                            <TextField
                                label={'Internal name'}
                                errors={errors}
                                isInvalid={errors['internal_name']}
                                name={'internal_name'}
                                placeholder='Internal name'
                                rules={{
                                    required: 'Please enter internal name',
                                }}
                                control={control}
                            />
                            <EuiSpacer />
                            {availableLanguages.map((language, index) => {
                                return (
                                    <div key={language.language_id}>
                                        <TextField
                                            label={!index && `Addon group title `}
                                            errors={errors}
                                            isInvalid={errors['title' + language.language_id]}
                                            name={`title${language.language_id}`}
                                            control={control}
                                            rules={{
                                                required: 'Please enter addon title',
                                            }}
                                            placeholder={`Addon title - ${language.language_label}`}
                                        />
                                        <EuiSpacer />
                                    </div>
                                );
                            })}
                            {availableLanguages.map((language, index) => {
                                return (
                                    <div key={language.language_id}>
                                        <TextAreaField
                                            label={!index && `Addon group description `}
                                            errors={errors}
                                            isInvalid={errors['description' + language.language_id]}
                                            name={`description${language.language_id}`}
                                            control={control}
                                            placeholder={`Addon description - ${language.language_label}`}
                                        />
                                        <EuiSpacer />
                                    </div>
                                );
                            })}
                            <ButtonGroup
                                idSelected={watch('show_agmark') ? '1' : '0'}
                                color='primary'
                                control={control}
                                setValue={setValue}
                                name='show_agmark'
                                watch={watch}
                                label={'Show Agmark'}
                            />

                            <EuiText>
                                <strong>Minimum Selection</strong>
                            </EuiText>
                            <EuiFlexItem style={{ padding: '10px' }}>
                                <EuiRadio
                                    id={'minimumRadio1'}
                                    label={<EuiText>There is no minimum addOn Selection</EuiText>}
                                    checked={minimumRadioSelected === 'minimumRadio1' ? true : false}
                                    onChange={(e) => setMinimumRadioSelected(e.target.id)}
                                />

                                <EuiRadio
                                    id={'minimumRadio2'}
                                    label={<EuiText>Set minimum addOn selection</EuiText>}
                                    checked={minimumRadioSelected === 'minimumRadio2' ? true : false}
                                    onChange={(e) => setMinimumRadioSelected(e.target.id)}
                                />

                                {minimumRadioSelected === 'minimumRadio2' && (
                                    <NumberField
                                        label='Minimum Selection'
                                        errors={errors}
                                        isInvalid={errors.hasOwnProperty('minimum')}
                                        name={'minimum'}
                                        min={0}
                                        control={control}
                                        placeholder='Minimum selection'
                                        rules={{
                                            required: 'Please enter minimum selection',
                                        }}
                                    />
                                )}
                            </EuiFlexItem>

                            <EuiText>
                                <strong>Maximum Selection</strong>
                            </EuiText>
                            <EuiFlexItem style={{ padding: '10px' }}>
                                <EuiRadio
                                    id={'maximumRadio1'}
                                    label={<EuiText>There is no maximum addOn Selection</EuiText>}
                                    checked={maximumRadioSelected === 'maximumRadio1' ? true : false}
                                    onChange={(e) => setMaximumRadioSelected(e.target.id)}
                                />

                                <EuiRadio
                                    id={'maximumRadio2'}
                                    label={<EuiText>Set maximum addOn selection</EuiText>}
                                    checked={maximumRadioSelected === 'maximumRadio2' ? true : false}
                                    onChange={(e) => setMaximumRadioSelected(e.target.id)}
                                />

                                {maximumRadioSelected === 'maximumRadio2' && (
                                    <NumberField
                                        label='Maximum Selection'
                                        errors={errors}
                                        isInvalid={errors.hasOwnProperty('maximum')}
                                        name={'maximum'}
                                        min={0}
                                        control={control}
                                        placeholder='Maximum selection'
                                        rules={{
                                            required: 'Please enter maximum selection',
                                        }}
                                    />
                                )}
                            </EuiFlexItem>

                            <EuiSpacer size='s' />

                            <AddOnChoices
                                errors={errors}
                                control={control}
                                setValue={setValue}
                                watch={watch}
                                defaultValue={defaultValue}
                                fieldArray={{ fields, append, remove }}
                            />
                        </EuiFlyoutBody>
                        <EuiFlyoutFooter>
                            {!isEmpty(errors) && showError && (
                                <EuiFlexGroup
                                    direction='column'
                                    style={{ backgroundColor: '#f8e9e9', padding: '15px', margin: '10px' }}
                                >
                                    <EuiFlexItem
                                        style={{
                                            flexDirection: 'row',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <EuiTextColor color='danger' style={{ paddingBottom: '10px' }}>
                                            <b>Please resolve below validations</b>
                                        </EuiTextColor>
                                        <EuiButtonIcon
                                            color={'warning'}
                                            onClick={() => setShowError(false)}
                                            iconType='cross'
                                            aria-label='cross'
                                        />
                                    </EuiFlexItem>
                                    {displayErrorMessage(errors)}
                                </EuiFlexGroup>
                            )}
                            {!isEmpty(errorMessage) && showError && (
                                <EuiFlexGroup
                                    direction='column'
                                    style={{ backgroundColor: '#f8e9e9', padding: '15px', margin: '10px' }}
                                >
                                    <EuiFlexItem
                                        style={{
                                            flexDirection: 'row',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <EuiTextColor color='danger' style={{ paddingBottom: '10px' }}>
                                            <b>Please resolve below validations</b>
                                        </EuiTextColor>
                                        <EuiButtonIcon
                                            color={'warning'}
                                            onClick={() => {
                                                setErrorMessage('');
                                                setShowError(false);
                                            }}
                                            iconType='cross'
                                            aria-label='cross'
                                        />
                                    </EuiFlexItem>
                                    <EuiFlexItem>{displayErrorServer()}</EuiFlexItem>
                                </EuiFlexGroup>
                            )}
                            <EuiFlexGroup justifyContent='spaceBetween'>
                                <EuiFlexItem grow={false}>
                                    <EuiButtonEmpty iconType='cross' onClick={closeFlyout}>
                                        Close
                                    </EuiButtonEmpty>
                                </EuiFlexItem>
                                <EuiFlexItem grow={false}>
                                    <EuiButton
                                        onClick={handleSubmit(onSubmit)}
                                        type='submit'
                                        fill
                                        isLoading={buttonLoader || buttonDisable}
                                    >
                                        Save
                                    </EuiButton>
                                </EuiFlexItem>
                            </EuiFlexGroup>
                        </EuiFlyoutFooter>
                    </EuiFlyout>
                </EuiForm>
            )}
        </>
    );
};
