import React, { useEffect, useState } from 'react';
import {
    EuiOverlayMask,
    EuiModal,
    EuiModalHeader,
    EuiModalHeaderTitle,
    EuiModalBody,
    EuiModalFooter,
    EuiButtonEmpty,
    EuiButton,
    EuiHealth,
    EuiHighlight,
    EuiComboBox,
    EuiFlexItem,
} from '@elastic/eui';
import { getTableList } from '../../api/tableBooking/getTableList';
import { useDispatch } from 'react-redux';
import { getErroMessage } from '../../helper/getErrorMessage';
import { updateTableBookingStatus } from '../../api/tableBooking/updateTableBookingStatus';
import moment from 'moment';

const TableAssignModal = (props) => {
    const {
        setSelectedEvent,
        selectedEvent,
        outletId,
        bookingId,
        setBookingId,
        fetchTableBookingList,
        booking,
        fetchList,
    } = props;
    const [tableList, setTableList] = useState([]);
    const [selectedTable, setSelectedTable] = useState([]);
    const [errorMessage, setErrorMessage] = useState(false);
    const dispatch = useDispatch();

    const closeModal = () => {
        if (bookingId) {
            setBookingId('');
        } else {
            setSelectedEvent({});
        }
    };

    useEffect(() => {
        dispatch(getTableList(outletId, booking ? booking.id : selectedEvent.id)).then((res) => {
            if (res.success) {
                let tables = [];

                res.tables.map((table) => {
                    tables.push({
                        ...table,
                        id: table.id,
                        label: table.name,
                        color: !table.bookings.length ? 'green' : '#bb2205',
                        online: !table.bookings.length ? 1 : 0,
                        disabled: !table.bookings.length ? false : true,
                    });
                });

                let onlineTables = tables.filter((table) => table.online === 1);
                let offlineTables = tables.filter((table) => table.online === 0);
                tables = onlineTables.concat(offlineTables);

                setTableList(tables);
            } else {
                alert(getErroMessage(res));
            }
        });
    }, []);

    useEffect(() => {
        if (selectedEvent && selectedEvent.booked_tables.length && tableList.length) {
            tableList.map((table) => {
                if (table.id == selectedEvent.booked_tables[0].id) {
                    setSelectedTable([table]);
                }
            });
        }
    }, [tableList]);

    const renderOption = (option, searchValue, contentClassName) => {
        const { color, label, value } = option;
        return (
            <EuiHealth color={color}>
                <span className={contentClassName}>
                    <EuiHighlight search={searchValue}>{label}</EuiHighlight>
                </span>
            </EuiHealth>
        );
    };

    const bookingStatusChange = () => {
        dispatch(
            updateTableBookingStatus(
                outletId,
                bookingId ? bookingId : selectedEvent.id,
                'approved',
                selectedTable.length ? [selectedTable[0].id] : []
            )
        ).then((response) => {
            if (response.success) {
                if (bookingId) {
                    fetchTableBookingList();
                } else {
                    fetchList();
                }
            } else {
                alert('Error in updating status');
            }
        });
    };

    const assignTable = () => {
        if (bookingId) {
            bookingStatusChange();
            setBookingId('');
        } else {
            if (selectedTable.length) {
                bookingStatusChange();
                setSelectedEvent({});
                setErrorMessage(false);
            } else {
                setErrorMessage(true);
            }
        }
    };

    const onChange = (selectedOptions) => {
        setSelectedTable(selectedOptions);
    };

    console.log('selected event  ', selectedEvent);
    console.log('selected event table list  ', tableList);
    return (
        <EuiOverlayMask>
            <EuiModal onClose={closeModal} initialFocus='[name=popswitch]'>
                <EuiModalHeader>
                    <EuiModalHeaderTitle>
                        <h1>Table Assign</h1>
                    </EuiModalHeaderTitle>
                </EuiModalHeader>

                <EuiModalBody>
                    <EuiFlexItem>
                        <EuiFlexItem>
                            <EuiComboBox
                                placeholder='Select a Table'
                                singleSelection={{
                                    asPlainText: true,
                                }}
                                options={tableList}
                                onChange={onChange}
                                selectedOptions={selectedTable}
                                isClearable={false}
                                renderOption={renderOption}
                            />
                        </EuiFlexItem>
                        <EuiFlexItem style={{ marginTop: '15px', color: 'red' }}>
                            {errorMessage ? <span style={{ fontSize: '12px' }}>Please select a table</span> : ''}
                        </EuiFlexItem>
                    </EuiFlexItem>

                    <EuiFlexItem style={{ marginTop: '20px' }}>
                        {bookingId ? (
                            <>
                                <p>Name: {booking.customer_name}</p>
                                <p>Date: {moment(`${booking.date} ${booking.time}`).format('DD-MM-YYYY hh:mm:ss a')}</p>
                                {booking.comment ? <p>Comment: {booking.comment}</p> : ''}
                            </>
                        ) : (
                            <>
                                <p>Name: {selectedEvent.title}</p>
                                <p>Date: {moment(selectedEvent.start).format('DD-MM-YYYY hh:mm:ss a')}</p>
                                {selectedEvent.des ? <p>Comment: {selectedEvent.des}</p> : ''}
                            </>
                        )}
                    </EuiFlexItem>
                </EuiModalBody>

                <EuiModalFooter>
                    <EuiButtonEmpty onClick={closeModal}>Cancel</EuiButtonEmpty>

                    <EuiButton onClick={assignTable} fill>
                        Assign
                    </EuiButton>
                </EuiModalFooter>
            </EuiModal>
        </EuiOverlayMask>
    );
};

export default TableAssignModal;
