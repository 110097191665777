import React, { useState, useEffect } from 'react';
import { EuiSelect, EuiFlexItem, EuiComboBox } from '@elastic/eui';
import { useSelector, useDispatch } from 'react-redux';
import { initializeRestaurant } from '../../reduxStore/actions/initilizationActionCreator';
import { useHistory } from 'react-router-dom';
import { isEmpty } from 'lodash';

export const RestaurantSelection = () => {
    const dispatch = useDispatch();
    const [state, setState] = useState([]);
    const restaurantID = useSelector((state) => state.auth.selectedRestaurantId);
    const restaurantList = useSelector((state) => state.auth.restaurantList);
    let defaultValue = [];
    let history = useHistory();

    useEffect(() => {
        const selectedRestaurant = [];
        restaurantList.restaurants.forEach((restaurant) => {
            if (parseInt(restaurant.id) === parseInt(restaurantID)) {
                selectedRestaurant.push({ value: restaurant, label: restaurant.name });
            }
        });
        setState(selectedRestaurant);
    }, [restaurantID]);

    const onChange = (selectedOptions) => {
        setState(selectedOptions);
        if (selectedOptions.length > 0) {
            const id = selectedOptions[0].value.id;
            localStorage.setItem('selectedRestaurant', id);
            dispatch(initializeRestaurant(id, history));
        }
    };

    const [restaurants, setRestaurants] = useState([]);
    useEffect(() => {
        let innerRestaurant = [];
        if (!restaurants.length) {
            restaurantList.restaurants.map((restaurant) => {
                return innerRestaurant.push({ value: restaurant, label: restaurant.name });
            });
            setRestaurants(innerRestaurant);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [restaurantList]);

    if (isEmpty(restaurantList)) return null;

    return (
        <>
            <EuiFlexItem style={{ padding: '3px' }}>
                <EuiComboBox
                    options={restaurants}
                    selectedOptions={state}
                    onChange={onChange}
                    singleSelection={true}
                    aria-label='restaurant list'
                    fullWidth
                />
                {/* console.log('state', state) */}
            </EuiFlexItem>
        </>
    );
};
