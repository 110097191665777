import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    EuiFlexGroup,
    EuiFlexItem,
    EuiText,
    EuiForm,
    EuiFormRow,
    EuiSpacer,
    EuiTextColor,
    EuiButtonIcon,
    EuiButton,
    EuiSuperSelect,
} from '@elastic/eui';
import { useForm, Controller } from 'react-hook-form';
import * as types from '../../../reduxStore/types/menu';
import API from '../../../api/axios/API';
import { isEmpty } from 'lodash';
import swal from 'sweetalert';
import TextField from '../../Form/TextField';
import TextAreaField from '../../Form/TextAreaField';
import FormGroupDescription from '../../formGroupDescription';
import { useHistory } from 'react-router-dom';
import DeleteModal from '../../menuComponentsRefactorCopy/menuEditorTab/deleteModal';
import SwitchField from '../../Form/SwitchField';
import CategoryVisibility from './CategoryVisibility';
import LanguageBannerImage from '../../../modules/Banners/Components/LanguageBannerImage';

const CategoryEditComponentReStructure = (props) => {
    const flyoutState = useSelector((state) => state.menu.activeEditCategoryFlyout);
    const editCategory = useSelector((state) => state.menu.editCategory);
    const addCategory = useSelector((state) => state.menu.addCategory);
    const availableLanguages = useSelector((state) => state.language.availableLanguages);
    const languageId = useSelector((state) => state.language.languageId);
    const menuList = useSelector((state) => state.menu.menuList);
    const showDeleteConfirmationModal = useSelector((state) => state.menu.showDeleteConfirmationModal);
    const dispatch = useDispatch();
    const history = useHistory();
    const [selectedImages, setSelectedImages] = useState({});
    const [errorMessage, setErrorMessage] = useState('');
    const [categoryList, setCategoryList] = useState([]);
    const [imageDeleteState, setImageDeleteState] = useState({ upload_image: {}, menuImage: {} });
    const [buttonLoadercategoryEdit, setButtonLoaderCategoryEdit] = useState(false);
    const [showError, setShowError] = useState(false);
    const [reuploadImgValue, setReuploadImgValue] = useState({});

    const {
        register,
        handleSubmit,
        control,
        watch,
        setValue,
        reset,

        formState: { isDirty, errors },
    } = useForm({
        defaultValues: {
            editCategory: editCategory,
            apply_mask: 0,
            tile_details_position: editCategory.tile_details_position,
            gradient_position: editCategory.gradient_position,
        },
    });
    useEffect(() => {
        if (!isEmpty(watch(`editCategory.category_image_url`))) {
            setSelectedImages(watch(`editCategory.category_image_url`));
        } else {
            setSelectedImages({});
        }
    }, [watch, isDirty]);

    useEffect(() => {
        return () => {
            dispatch({
                type: types.SET_ADD_CATEGORY_ID,
                payload: {
                    addCategory: null,
                },
            });
            dispatch({
                type: types.EDIT_CATEGORY,
                payload: {
                    editCategory: {},
                },
            });
        };
    }, [dispatch]);

    const resetChanges = () => {
        if (editCategory.category_image_url) {
            let defaultValueObject = {};
            for (const element of editCategory?.ordering_modes) {
                defaultValueObject[`mode_${element.restaurant_ordering_mode_id}`] = true;
            }
            reset({
                ...editCategory,
                hide_category_tile_details: editCategory?.hide_category_tile_details,
                upload_image: {
                    id: editCategory.category_image_url.id,
                    src: editCategory.category_image_url.image_url,
                    image_url: editCategory.category_image_url.image_url,
                    result: null,
                },
                editCategory: {
                    theme: editCategory?.theme,
                    ordering_modes: editCategory?.ordering_modes,
                    category_image_url: editCategory.category_image_url,
                    ...defaultValueObject,
                },
            });
        } else if (!isEmpty(editCategory)) {
            reset({ ...editCategory });
        } else {
            let obj = {
                delivery: '',
                dine_in: '',
                hidden: '',
                hide_category_tile_details: '',
                internal_name: '',
                size: '',
                takeaway: '',
                upload_image: { id: '', src: '', image_url: '', result: null },
                tile_details_position: '',
                gradient_position: '',
            };
            availableLanguages.map((item) => {
                obj['description' + item.language_id] = '';
                obj['title' + item.language_id] = '';
                obj['size' + item.language_id] = '';
                obj['serves' + item.language_id] = '';
            });
            reset(obj);
        }
    };
    useEffect(() => {
        if (isDirty) {
            dispatch({
                type: 'set-portal-active',
                payload: {
                    portalActive: {
                        resetChanges: resetChanges,
                        submit: handleSubmit(EditCategory),
                    },
                },
            });
        } else {
            dispatch({
                type: 'set-portal-active',
                payload: {
                    portalActive: {},
                },
            });
        }
    }, [isDirty]);

    useEffect(() => {
        setImageDeleteState({
            upload_image: editCategory.category_image_url,
            menuImage: editCategory.category_menu_image_url,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editCategory]);

    const closeFlyout = () => {
        // setSelectedAddon([])
        dispatch({
            type: 'active-edit-category-flyout',
            payload: {
                activeEditCategoryFlyout: { ...flyoutState, state: false },
            },
        });
        // selectedAddOnList
        dispatch({
            type: types.SET_ADDON_SELECTED_LIST,
            payload: {
                selectedAddOnList: [],
            },
        });

        dispatch({
            type: types.EDIT_CATEGORY,
            payload: {
                editCategory: {},
            },
        });
    };

    const imageChecker = (data) => {
        if (data.upload_image) {
            if (imageDeleteState.upload_image && data.upload_image.id !== imageDeleteState.upload_image.id) {
                try {
                    API.delete(`/restaurants/:restaurantId/uploads/${imageDeleteState.upload_image.id}`);
                } catch (error) {
                    console.log('error in image delete ', error);
                }
            }
        } else {
            if (!isEmpty(imageDeleteState.upload_image)) {
                try {
                    API.delete(`/restaurants/:restaurantId/uploads/${imageDeleteState.upload_image.id}`);
                } catch (error) {
                    console.log('error in image delete ', error);
                }

                data.upload_image = imageDeleteState.upload_image;
            }
        }
    };

    useEffect(() => {
        if (!isEmpty(reuploadImgValue)) {
            setReuploadImgValue(reuploadImgValue);
        }
    }, [reuploadImgValue]);

    const EditCategory = async (data) => {
        console.log('data are', data);
        setButtonLoaderCategoryEdit(true);
        let titles = {};
        let descriptions = {};
        let sizes = {};
        let serves = {};

        availableLanguages.map((language) => {
            titles[language.language_id] = data[`title${language.language_id}`];
            descriptions[language.language_id] = data[`description${language.language_id}`];
            sizes[language.language_id] = data[`size${language.language_id}`];
            serves[language.language_id] = data[`serves${language.language_id}`];
            return null;
        });
        let translationObject = {
            title: titles,
            description: descriptions,
            size: sizes,
            serves: serves,
        };

        let value = window.location.search.split('=');

        value[1] === 'add'
            ? addCategoryFunction(data, translationObject)
            : editCategoryFunction(data, translationObject);

        setButtonLoaderCategoryEdit(false);
    };

    const editCategoryFunction = async (data, translationObject) => {
        let res;

        try {
            res = await API.put(`/restaurants/:restaurantId/categories/${editCategory.id}`, {
                category_image_id: data?.editCategory?.category_image_url
                    ? data.editCategory.category_image_url.id
                    : null,
                apply_mask: data?.apply_mask ? data.apply_mask : 0,
                category_menu_image_upload_id: data.menuImage ? data.menuImage.id : null,
                hidden: data?.hidden ? 1 : 0,
                hide_category_tile_details: data.hide_category_tile_details,
                dine_in: data.dine_in ? 1 : 0,
                takeaway: data.takeaway ? 1 : 0,
                delivery: data.delivery ? 1 : 0,
                internal_name: data.internal_name,
                translations: translationObject,
                parent_id: editCategory.parent_id ? editCategory.parent_id : null,
                theme: data.editCategory?.theme ? data.editCategory.theme : null,
                ordering_modes: data.editCategory?.ordering_modes ? data.editCategory.ordering_modes : [],
                delete_ordering_modes: data?.delete_ordering_modes ? data.delete_ordering_modes : [],
                tile_details_position: data.tile_details_position,
                gradient_position: data.gradient_position,
            });
        } catch (error) {
            res = error;
        }

        if (res.success) {
            dispatch({
                type: types.SET_CATEGORY_STATE,
                payload: {
                    categoryState: {},
                },
            });

            history.goBack();

            setErrorMessage('');
            closeFlyout();
            dispatch({
                type: 'set-portal-active',
                payload: {
                    portalActive: {},
                },
            });
            swal('Category successfully edited...', '', 'success');
        } else {
            setErrorMessage(res.errors);
            if (!isEmpty(res?.errors?.category_image_id)) {
                swal('Please Select Image', '', 'error');
            } else if (!isEmpty(res?.errors?.internal_name)) {
                swal(res.errors.internal_name, '', 'error');
            } else {
                swal('Failed to edit category...', '', 'error');
            }
        }
    };

    const addCategoryFunction = async (data, translationObject) => {
        let res;
        try {
            res = await API.post(`/restaurants/:restaurantId/categories`, {
                category_image_id: data?.editCategory?.category_image_url
                    ? data.editCategory.category_image_url.id
                    : null,
                apply_mask: data?.apply_mask ? data.apply_mask : 0,
                category_menu_image_upload_id: data.menuImage ? data.menuImage.id : null,
                hidden: data?.hidden ? 1 : 0,
                hide_category_tile_details: data?.hide_category_tile_details ? data.hide_category_tile_details : 0,
                dine_in: data.dine_in ? 1 : 0,
                takeaway: data.takeaway ? 1 : 0,
                delivery: data.delivery ? 1 : 0,
                internal_name: data.internal_name,
                translations: translationObject,
                parent_id: addCategory,
                theme: data.editCategory?.theme ? data.editCategory.theme : null,
                ordering_modes: data.editCategory?.ordering_modes ? data.editCategory.ordering_modes : [],
                delete_ordering_modes: data?.delete_ordering_modes ? data.delete_ordering_modes : [],
                tile_details_position: data.tile_details_position,
                gradient_position: data.gradient_position,
            });
        } catch (error) {
            res = error;
        }

        if (res.success) {
            dispatch({
                type: types.SET_CATEGORY_STATE,
                payload: {
                    categoryState: {},
                },
            });
            dispatch({
                type: 'set-portal-active',
                payload: {
                    portalActive: {},
                },
            });

            history.goBack();

            setErrorMessage('');
            closeFlyout();
            swal('Category successfully added...', '', 'success');
        } else {
            setErrorMessage(res.errors);
            if (!isEmpty(res?.errors?.category_image_id)) {
                swal('Please Select Image', '', 'error');
            } else if (!isEmpty(res?.errors?.internal_name)) {
                swal(res.errors.internal_name, '', 'error');
            } else {
                swal('Failed to edit category...', '', 'error');
            }
        }
    };

    useEffect(() => {
        let categoryParentListArray = [{ value: 0, text: 'select parent category' }];

        if (menuList.length > 0) {
            menuList.map((category) => {
                editCategory.id !== category.category_id &&
                    categoryParentListArray.push({
                        value: category.category_id,
                        text: category.translations.title[languageId],
                    });
                return null;
            });
        }

        if (categoryParentListArray.length > 1) {
            setCategoryList(categoryParentListArray);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const displayErrorServer = () => {
        let errorsList = [];
        if (!isEmpty(errorMessage)) {
            for (var key in errorMessage) {
                errorsList.push(<EuiTextColor color='danger'>{errorMessage[key]}</EuiTextColor>);
            }
        }
        return errorsList;
    };

    const displayError = (errors) => {
        let innerArray = [];
        if (!isEmpty(errors)) {
            for (var key in errors) {
                innerArray.push(
                    <EuiText>
                        <ul>
                            <li>{errors[key].message}</li>
                        </ul>
                    </EuiText>
                );
            }
        }
        return innerArray;
    };

    const onCategoryParentChange = (value) => {
        setValue('parent_category', value);
    };

    const onDeleteCategory = () => {
        dispatch({
            type: types.SET_DELETE_CONFIRMATION_MODAL,
            payload: {
                showDeleteConfirmationModal: true,
            },
        });

        dispatch({
            type: types.SET_DELETE_CATEGORY_ID,
            payload: {
                deleteCategoryId: editCategory.id,
            },
        });
    };

    const handleTitlePositionGradientChange = useCallback(
        (value) => {
            setValue('tile_details_position', value, { shouldDirty: true });
            setValue('gradient_position', value, { shouldDirty: true });
        },
        [setValue]
    );

    return (
        <>
            {showDeleteConfirmationModal && <DeleteModal />}

            {window.location.search.includes('edit') ? (
                <EuiFlexItem style={{ alignItems: 'flex-end' }}>
                    <EuiButton
                        onClick={onDeleteCategory}
                        style={{ width: '200px' }}
                        size='s'
                        iconType='trash'
                        fill
                        color='danger'
                    >
                        Delete
                    </EuiButton>
                </EuiFlexItem>
            ) : (
                ''
            )}

            <EuiForm component='form' onSubmit={handleSubmit(EditCategory)}>
                <FormGroupDescription title='Category image'>
                    {!isEmpty(selectedImages) ? (
                        <>
                            <EuiFlexGroup>
                                <EuiFlexItem grow={false}>
                                    <Controller
                                        control={control}
                                        name='apply_mask'
                                        render={({ field }) => {
                                            return (
                                                <input
                                                    type='checkbox'
                                                    label='Apply mask'
                                                    id='apply_mask'
                                                    checked={field.value}
                                                    onChange={field.onChange}
                                                />
                                            );
                                        }}
                                    />
                                </EuiFlexItem>
                                <EuiFlexItem grow={false}>
                                    <label htmlFor='apply_mask'>Apply Mask</label>
                                </EuiFlexItem>
                            </EuiFlexGroup>
                        </>
                    ) : null}
                    <EuiSpacer size='s' />
                    <LanguageBannerImage
                        withRemove={true}
                        buttonLabel={'Select an image'}
                        selectedImgButtonLabel='Change Category Image'
                        imageType={'categories'}
                        selectedImages={selectedImages}
                        name='editCategory.category_image_url'
                        defaultImageValue={watch(`editCategory.category_image_url`)}
                        setValue={setValue}
                        buttonPosition='default'
                        aspectRatio={{
                            width: 1,
                            height: 1,
                        }}
                        // label='Brand Logo'
                        setSelectedImages={setSelectedImages}
                    />
                </FormGroupDescription>

                <FormGroupDescription title='Internal name'>
                    <EuiFlexItem>
                        <TextField
                            errors={errors}
                            label=''
                            name='internal_name'
                            placeholder='Please enter internal name'
                            rules={{ required: 'Please enter Internal name' }}
                            control={control}
                            defaultValue={editCategory.internal_name}
                        />
                    </EuiFlexItem>
                </FormGroupDescription>

                <FormGroupDescription title='Title'>
                    {availableLanguages.map((language, index) => {
                        return (
                            <React.Fragment key={index.toString()}>
                                <EuiFlexItem>
                                    <TextField
                                        errors={errors}
                                        label={index === 0 ? 'Title' : ''}
                                        name={`title${language.language_id}`}
                                        defaultValue={
                                            editCategory[`title${language.language_id}`]
                                                ? editCategory[`title${language.language_id}`]
                                                : ''
                                        }
                                        placeholder={`Category title - ${language.language_label}`}
                                        rules={{
                                            required: 'Please enter category title',
                                        }}
                                        control={control}
                                    />
                                </EuiFlexItem>
                                {availableLanguages.length - 1 !== index && <EuiSpacer />}
                            </React.Fragment>
                        );
                    })}
                </FormGroupDescription>

                {!addCategory ? (
                    <>
                        <FormGroupDescription title='Description'>
                            {availableLanguages.map((language, index) => (
                                <React.Fragment key={index.toString()}>
                                    <EuiFlexItem>
                                        <TextAreaField
                                            errors={errors}
                                            label={index === 0 ? 'Description' : ''}
                                            name={`description${language.language_id}`}
                                            defaultValue={
                                                editCategory[`description${language.language_id}`]
                                                    ? editCategory[`description${language.language_id}`]
                                                    : ''
                                            }
                                            placeholder={`Description - ${language.language_label}`}
                                            control={control}
                                        />
                                    </EuiFlexItem>
                                    {availableLanguages.length - 1 !== index && <EuiSpacer />}
                                </React.Fragment>
                            ))}
                        </FormGroupDescription>

                        <FormGroupDescription title='Size'>
                            {availableLanguages.map((language, index) => (
                                <React.Fragment key={index.toString()}>
                                    <EuiFlexItem>
                                        <TextField
                                            errors={errors}
                                            label={index === 0 ? 'Size' : ''}
                                            name={`size${language.language_id}`}
                                            defaultValue={
                                                editCategory[`size${language.language_id}`]
                                                    ? editCategory[`size${language.language_id}`]
                                                    : ''
                                            }
                                            placeholder={`Category size - ${language.language_label}`}
                                            control={control}
                                        />
                                    </EuiFlexItem>
                                    {availableLanguages.length - 1 !== index && <EuiSpacer />}
                                </React.Fragment>
                            ))}
                        </FormGroupDescription>

                        <FormGroupDescription title='Serves'>
                            {availableLanguages.map((language, index) => (
                                <React.Fragment key={index.toString()}>
                                    <EuiFlexItem>
                                        <TextField
                                            errors={errors}
                                            label={index === 0 ? 'Serves' : ''}
                                            name={`serves${language.language_id}`}
                                            defaultValue={
                                                editCategory[`serves${language.language_id}`]
                                                    ? editCategory[`serves${language.language_id}`]
                                                    : ''
                                            }
                                            placeholder={`Category serves - ${language.language_label}`}
                                            control={control}
                                        />
                                    </EuiFlexItem>
                                    {availableLanguages.length - 1 !== index && <EuiSpacer />}
                                </React.Fragment>
                            ))}
                        </FormGroupDescription>

                        <FormGroupDescription title='Tile Details Position'>
                            <Controller
                                render={({ field }) => {
                                    return (
                                        <EuiSuperSelect
                                            options={positionOptions}
                                            valueOfSelected={field.value}
                                            onChange={handleTitlePositionGradientChange}
                                        />
                                    );
                                }}
                                control={control}
                                name='tile_details_position'
                            />
                        </FormGroupDescription>
                        <FormGroupDescription title='Gradient Position'>
                            <Controller
                                render={({ field }) => {
                                    return (
                                        <EuiSuperSelect
                                            options={positionOptions}
                                            valueOfSelected={field.value}
                                            onChange={field.onChange}
                                            disabled
                                        />
                                    );
                                }}
                                control={control}
                                name='gradient_position'
                            />
                        </FormGroupDescription>

                        <FormGroupDescription title='Theme'>
                            <EuiFlexItem>
                                <Controller
                                    render={({ field }) => {
                                        return (
                                            <EuiFormRow
                                                label={'Select Theme'}
                                                isInvalid={errors['theme']}
                                                error={errors['theme'] ? errors['Select Theme'].message : ''}
                                                fullWidth={true}
                                            >
                                                <EuiSuperSelect
                                                    options={[
                                                        {
                                                            value: 'full_size_image',
                                                            inputDisplay: 'Full Size Image',
                                                        },
                                                        { value: 'square_image', inputDisplay: 'Square Image' },
                                                    ]}
                                                    valueOfSelected={field.value}
                                                    onChange={field.onChange}
                                                    // props.onChange
                                                    placeholder={''}
                                                />
                                            </EuiFormRow>
                                        );
                                    }}
                                    name={'editCategory.theme'}
                                    control={control}
                                    fullWidth
                                />
                            </EuiFlexItem>
                            {<EuiSpacer />}
                        </FormGroupDescription>

                        <FormGroupDescription title='Category Visibility'>
                            <CategoryVisibility control={control} setValue={setValue} />
                        </FormGroupDescription>
                    </>
                ) : (
                    ''
                )}

                <FormGroupDescription title={'Hidden category'}>
                    <SwitchField
                        name={'hidden'}
                        defaultValue={watch('editCategory.hidden') ? true : false}
                        control={control}
                        setValue={setValue}
                        label=''
                    />
                </FormGroupDescription>
                {!addCategory ? (
                    <FormGroupDescription title='Hide Category tile'>
                        <SwitchField
                            name={'hide_category_tile_details'}
                            defaultValue={watch('editCategory.hide_category_tile_details') ? true : false}
                            control={control}
                            setValue={setValue}
                            label=''
                        />
                    </FormGroupDescription>
                ) : null}

                {!isEmpty(errorMessage) && showError && (
                    <EuiFlexGroup
                        direction='column'
                        style={{
                            backgroundColor: '#f8e9e9',
                            margin: '10px',
                            padding: '15px',
                            borderRadius: '5px',
                        }}
                    >
                        <EuiFlexItem
                            style={{
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                            }}
                        >
                            <EuiTextColor color='danger' style={{ paddingBottom: '10px' }}>
                                <b>Please resolve below validations</b>
                            </EuiTextColor>
                            <EuiButtonIcon
                                color={'warning'}
                                onClick={() => setShowError(false)}
                                iconType='cross'
                                aria-label='cross'
                            />
                        </EuiFlexItem>
                        {displayErrorServer()}
                    </EuiFlexGroup>
                )}
                {!isEmpty(errors) && showError && (
                    <EuiFlexGroup direction='column' style={{ backgroundColor: '#f8e9e9', padding: '15px' }}>
                        <EuiFlexItem
                            style={{
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                            }}
                        >
                            <EuiTextColor color='danger' style={{ paddingBottom: '10px' }}>
                                <b>Please resolve below validations</b>
                            </EuiTextColor>
                            <EuiButtonIcon
                                color={'warning'}
                                onClick={() => setShowError(false)}
                                iconType='cross'
                                aria-label='cross'
                            />
                        </EuiFlexItem>
                        {displayError(errors)}
                    </EuiFlexGroup>
                )}
            </EuiForm>
        </>
    );
};

export default CategoryEditComponentReStructure;
const positionOptions = [
    {
        value: 'top-left',
        inputDisplay: 'Top Left',
    },
    {
        value: 'top-right',
        inputDisplay: 'Top Right',
    },
    {
        value: 'bottom-left',
        inputDisplay: 'Bottom Left',
    },
    {
        value: 'bottom-right',
        inputDisplay: 'Bottom Right',
    },
];
