import React, { useEffect, useState } from 'react';
import {
    EuiDragDropContext,
    EuiEmptyPrompt,
    EuiFlexItem,
    EuiFlexGroup,
    EuiDroppable,
    EuiSpacer,
    EuiText,
    EuiDraggable,
    EuiIcon,
    EuiPanel,
    EuiLoadingContent,
    euiDragDropMove,
    EuiFormRow,
} from '@elastic/eui';
import { useSelector, useDispatch } from 'react-redux';
import { getCategoryList } from '../../api/category/getCategoryList';
import NumberField from '../Form/NumberField';
import { isEmpty } from 'lodash';

export const CategoryDrag = (props) => {
    const { printerList, watch, setValue, control, errors, outletId } = props;
    const [categoryOptions, setCategoryOptions] = useState([]);
    const [categoryLoader, setCategoryLoader] = useState(false);

    const languageId = useSelector((state) => state.language.languageId);
    const dispatch = useDispatch();

    useEffect(() => {
        setCategoryLoader(true);
        dispatch(getCategoryList(outletId)).then((res) => {
            if (res.success) {
                let printerObject = watch('printers');
                let printerCategories = [];

                for (const [key, value] of Object.entries(printerObject)) {
                    if (printerList[0].value === key) continue;
                    printerCategories.push(...value.data);
                }

                res.outlet_categories.map((category) => {
                    category.label = category.translations.title[languageId];

                    if (category?.sub_categories?.length) {
                        category.sub_categories.map((subCategory) => {
                            subCategory.label = subCategory.translations.title[languageId];
                        });
                    }
                });

                let filterData = res.outlet_categories.filter(
                    (category) =>
                        !printerCategories.some(
                            (printerCategory) => printerCategory.category_id === category.category_id
                        )
                );

                setValue(`printers.${printerList[0].value}.data`, filterData);
                // setCategoryOptions(filterData)
            }
            setCategoryLoader(false);
        });
    }, []);

    const functionForCategoryHandle = (id, data) => {
        setValue(`printers.${id}.data`, data, { shouldDirty: true });
    };

    const onDragEnd = ({ source, destination }) => {
        let lists = { CUSTOM_HANDLE_DROPPABLE_AREA_CATEGORY: categoryOptions };
        let actions = { CUSTOM_HANDLE_DROPPABLE_AREA_CATEGORY: setCategoryOptions };

        let printersCategory = watch('printers');

        printerList.forEach((printer) => {
            lists[printer.value] = printersCategory[printer.value].data;
            actions[printer.value] = functionForCategoryHandle;
        });

        if (source && destination) {
            if (source.droppableId !== destination.droppableId) {
                const sourceId = source.droppableId;
                const destinationId = destination.droppableId;
                const result = euiDragDropMove(lists[sourceId], lists[destinationId], source, destination);

                if (sourceId === 'CUSTOM_HANDLE_DROPPABLE_AREA_CATEGORY') {
                    actions[sourceId](result[sourceId]);
                } else {
                    actions[sourceId](sourceId, result[sourceId]);
                }

                if (destinationId === 'CUSTOM_HANDLE_DROPPABLE_AREA_CATEGORY') {
                    actions[destinationId](result[destinationId]);
                } else {
                    actions[destinationId](destinationId, result[destinationId]);
                }
            }
        }
    };

    if (categoryLoader) {
        return <EuiLoadingContent lines={5} />;
    }

    const getPrinterList = () => {
        let printerObject = watch('printers');

        if (isEmpty(printerObject)) return;

        let innerArray = [];
        printerList.map((printer) => {
            innerArray.push(
                <EuiFlexItem style={{ width: '25%', flex: 'none' }}>
                    <div
                        style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '93%' }}
                    >
                        <EuiText>
                            <b>{printer.value}</b>
                        </EuiText>

                        <EuiFormRow style={{ width: '15%' }} helpText='print'>
                            <NumberField
                                label={''}
                                errors={errors}
                                name={`printers.${printer.value}.count`}
                                placeholder='Print'
                                control={control}
                                compressed
                            />
                        </EuiFormRow>
                    </div>

                    <EuiSpacer />

                    <EuiDroppable
                        style={{ maxHeight: '400px', overflow: 'auto', marginRight: '15px' }}
                        droppableId={printer.value}
                        spacing='m'
                        withPanel
                    >
                        {printerObject[printer.value] && printerObject[printer.value].data?.length ? (
                            printerObject[printer.value].data.map((category, idx) => {
                                return (
                                    <EuiDraggable
                                        spacing='m'
                                        key={
                                            category.category_id
                                                ? category.category_id.toString()
                                                : category.sub_category_id
                                        }
                                        index={idx}
                                        draggableId={
                                            category.category_id
                                                ? category.category_id.toString()
                                                : category.sub_category_id
                                        }
                                        customDragHandle={true}
                                    >
                                        {(provided) => (
                                            <EuiPanel className='custom' paddingSize='m'>
                                                <EuiFlexGroup>
                                                    <EuiFlexItem
                                                        grow={false}
                                                        style={{
                                                            justifyContent: 'center',
                                                        }}
                                                    >
                                                        <div {...provided.dragHandleProps}>
                                                            <EuiIcon type='grab' />
                                                        </div>
                                                    </EuiFlexItem>
                                                    <EuiFlexItem
                                                        style={{
                                                            flexDirection: 'row',
                                                            alignItems: 'center',
                                                        }}
                                                    >
                                                        <EuiText> {category.translations.title[languageId]} </EuiText>
                                                    </EuiFlexItem>
                                                </EuiFlexGroup>
                                            </EuiPanel>
                                        )}
                                    </EuiDraggable>
                                );
                            })
                        ) : (
                            <EuiFlexGroup wrap style={{ marginTop: 10 }}>
                                <EuiFlexItem>
                                    <EuiEmptyPrompt
                                        iconType='editorStrike'
                                        title={<h2>No Linked Category</h2>}
                                        body={<p>You have not linked any categories to this printer.</p>}
                                    />
                                </EuiFlexItem>
                            </EuiFlexGroup>
                        )}
                    </EuiDroppable>
                </EuiFlexItem>
            );
        });

        return innerArray;
    };

    return (
        <>
            <EuiFlexItem style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', width: '100%' }}>
                <EuiDragDropContext onDragEnd={onDragEnd}>{getPrinterList()}</EuiDragDropContext>
            </EuiFlexItem>
        </>
    );
};
