import {
    EuiButton,
    EuiFieldNumber,
    EuiFlexGroup,
    EuiFlexItem,
    EuiFormRow,
    EuiSelect,
    EuiSuperSelect,
    EuiText,
    EuiCard,
    EuiSpacer,
} from '@elastic/eui';
import React, { useEffect, useState, useCallback } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import ItemDetailsCollaspibleNav from './ItemDetailsCollaspibleNav';
import CheckBoxField from '../../../../../components/Form/CheckBoxField';
import TextField from '../../../../../components/Form/TextField';
import OrderingModePrices from '../../../../../components/menuComponentsRefactorCopy/menuEditorTab/OrderingModePrices';
import { ReactComponent as ItemPricingIcon } from '../../../../../assets/img/svg/ItemPricingIcon.svg';

const gstOptions = [
    { value: '0', text: '0%' },
    { value: '5', text: '5%' },
    { value: '12', text: '12%' },
    { value: '18', text: '18%' },
    { value: '28', text: '28%' },
];

const ItemPricing = ({
    setVaraintModalState,
    variantConditionForPrice,
    priceRef,
    comparePriceRef,
    packagingPriceRef,
}) => {
    const {
        control,
        setValue,
        formState: { errors },
        watch,
    } = useFormContext();
    const isOutletMenu = useSelector((state) => state.menuReducer.isOutletMenu);
    const quantityUnits = useSelector((state) => state.menuReducer.quantityUnits);
    const [quantityIdOptions, setQuantityIdOptions] = useState([]);
    const [isNavOpen, setIsNavOpen] = useState(false);

    const onWheel = useCallback(() => {
        priceRef?.current?.blur();
        comparePriceRef?.current?.blur();
        packagingPriceRef?.current?.blur();
    }, [comparePriceRef, packagingPriceRef, priceRef]);

    const onChangeGST = useCallback(
        (value) => {
            setValue('gst_slab', value);
        },
        [setValue]
    );

    useEffect(() => {
        if (quantityUnits?.length) {
            let units = [];
            for (const element of quantityUnits) {
                units.push({
                    value: element.id,
                    inputDisplay: element.unit,
                });
            }
            setQuantityIdOptions(units);
        }
    }, [quantityUnits]);

    return (
        <>
            <ItemDetailsCollaspibleNav
                cardTitle='Item Pricing'
                cardDescription='Make pricing changes for this item'
                isNavOpen={isNavOpen}
                setIsNavOpen={setIsNavOpen}
                toggleText='Edit Item Pricing'
                icon={<ItemPricingIcon />}
            >
                <EuiFlexItem>
                    {variantConditionForPrice() ? (
                        <EuiFlexGroup
                            direction='column'
                            alignItems='flexStart'
                            justifyContent='flexStart'
                            gutterSize='s'
                        >
                            <EuiFlexItem>
                                <CheckBoxField
                                    name='is_enabled_same_price_details_for_all_ordering_modes'
                                    label='Different Price for all ordering mode?'
                                    control={control}
                                    setValue={setValue}
                                    isDisabled={isOutletMenu}
                                />
                            </EuiFlexItem>

                            <>
                                {watch('is_enabled_same_price_details_for_all_ordering_modes') ? (
                                    <>
                                        {watch('ordering_modes')?.length ? (
                                            watch('ordering_modes').map((orderingMode, index) => {
                                                return (
                                                    <OrderingModePrices
                                                        key={index}
                                                        index={index}
                                                        orderingMode={orderingMode}
                                                        errors={errors}
                                                        control={control}
                                                        priceRef={priceRef}
                                                        onWheel={onWheel}
                                                        isOutletMenu={isOutletMenu}
                                                        comparePriceRef={comparePriceRef}
                                                        packagingPriceRef={packagingPriceRef}
                                                        gstOptions={gstOptions}
                                                        watch={watch}
                                                    />
                                                );
                                            })
                                        ) : (
                                            <>
                                                {/* This will be showen when client creates new item */}
                                                {watch('ordering_modes')?.length ? (
                                                    <>
                                                        {watch('ordering_modes').map((orderingMode, index) => {
                                                            return (
                                                                <EuiFlexItem
                                                                    component='div'
                                                                    key={orderingMode.id.toString()}
                                                                >
                                                                    {orderingMode.status ? (
                                                                        <>
                                                                            <OrderingModePrices
                                                                                key={index}
                                                                                index={index}
                                                                                orderingMode={orderingMode}
                                                                                errors={errors}
                                                                                control={control}
                                                                                priceRef={priceRef}
                                                                                onWheel={onWheel}
                                                                                isOutletMenu={isOutletMenu}
                                                                                comparePriceRef={comparePriceRef}
                                                                                packagingPriceRef={packagingPriceRef}
                                                                                gstOptions={gstOptions}
                                                                                quantityIdOptions={quantityIdOptions}
                                                                                watch={watch}
                                                                            />
                                                                        </>
                                                                    ) : null}
                                                                </EuiFlexItem>
                                                            );
                                                        })}
                                                    </>
                                                ) : (
                                                    <EuiCard title='Please Select an Ordering Mode to continue' />
                                                )}
                                            </>
                                        )}
                                    </>
                                ) : (
                                    <>
                                        <EuiSpacer />
                                        <EuiFlexGroup
                                            gutterSize='s'
                                            direction='row'
                                            justifyContent='flexStart'
                                            alignItems='flexStart'
                                            wrap
                                            responsive
                                            // style={{ margin: '12px 8px' }}
                                        >
                                            <EuiFlexItem
                                                style={{
                                                    minWidth: 120,
                                                }}
                                            >
                                                <EuiFormRow
                                                    style={{
                                                        maxWidth: '150px',
                                                    }}
                                                    label={'price'}
                                                    error={errors['price'] ? errors['price'].message : ''}
                                                    isInvalid={errors['price']}
                                                >
                                                    <Controller
                                                        render={({ field }) => (
                                                            <EuiFieldNumber
                                                                value={field.value}
                                                                onChange={field.onChange}
                                                                placeholder='Item price'
                                                            />
                                                        )}
                                                        name={'price'}
                                                        min={0}
                                                        step={'any'}
                                                        control={control}
                                                        inputRef={priceRef}
                                                        onWheel={onWheel}
                                                        rules={{
                                                            required: 'Please enter item price',
                                                        }}
                                                        isInvalid={errors['price']}
                                                        disabled={isOutletMenu}
                                                    />
                                                </EuiFormRow>
                                                <EuiText
                                                    size='s'
                                                    style={{
                                                        color: '#b8b9bd',
                                                    }}
                                                >
                                                    <span>Excluding All taxes</span>
                                                </EuiText>
                                            </EuiFlexItem>
                                            <EuiFlexItem
                                                style={{
                                                    minWidth: 120,
                                                }}
                                            >
                                                <EuiFormRow
                                                    style={{
                                                        minWidth: 120,
                                                    }}
                                                    label={'Compare Price'}
                                                >
                                                    <Controller
                                                        render={({ field }) => (
                                                            <EuiFieldNumber
                                                                value={field.value}
                                                                onChange={field.onChange}
                                                                placeholder='Item compare price'
                                                            />
                                                        )}
                                                        name={'compare_price'}
                                                        min={0.0}
                                                        step={'any'}
                                                        control={control}
                                                        inputRef={comparePriceRef}
                                                        onWheel={onWheel}
                                                        disabled={isOutletMenu}
                                                    />
                                                </EuiFormRow>
                                            </EuiFlexItem>
                                            <EuiFlexItem
                                                style={{
                                                    minWidth: 120,
                                                }}
                                            >
                                                <EuiFormRow
                                                    style={{
                                                        minWidth: 120,
                                                    }}
                                                    label={'packaging'}
                                                >
                                                    <Controller
                                                        render={({ field }) => (
                                                            <EuiFieldNumber
                                                                value={field.value}
                                                                onChange={field.onChange}
                                                                placeholder='Item packaging'
                                                            />
                                                        )}
                                                        name={'packaging_charge'}
                                                        min={0.0}
                                                        step={'any'}
                                                        control={control}
                                                        inputRef={packagingPriceRef}
                                                        onWheel={onWheel}
                                                        disabled={isOutletMenu}
                                                    />
                                                </EuiFormRow>
                                                <EuiText
                                                    size='s'
                                                    style={{
                                                        color: '#b8b9bd',
                                                    }}
                                                >
                                                    <span>Including All taxes</span>
                                                </EuiText>
                                            </EuiFlexItem>
                                            <EuiFlexItem
                                                style={{
                                                    minWidth: 120,
                                                }}
                                            >
                                                <EuiFormRow
                                                    label='GST'
                                                    style={{
                                                        minWidth: 120,
                                                    }}
                                                >
                                                    <Controller
                                                        render={({ field }) => (
                                                            <EuiSelect
                                                                options={gstOptions}
                                                                value={watch('gst_slab')}
                                                                onChange={(e) => onChangeGST(e.target.value)}
                                                                disabled={isOutletMenu}
                                                            />
                                                        )}
                                                        name='gst_slab'
                                                        control={control}
                                                    />
                                                </EuiFormRow>
                                            </EuiFlexItem>
                                        </EuiFlexGroup>
                                        <EuiSpacer />
                                        <EuiFlexGroup
                                            gutterSize='s'
                                            direction='row'
                                            justifyContent='flexStart'
                                            alignItems='flexStart'
                                            wrap
                                            responsive
                                            // style={{ margin: '12px 8px' }}
                                        >
                                            <EuiFlexItem>
                                                <TextField
                                                    label={`Quantity`}
                                                    name={'quantity'}
                                                    control={control}
                                                    inputRef={priceRef}
                                                    onWheel={onWheel}
                                                    defaultValue={watch('quantity')}
                                                    disabled={isOutletMenu}
                                                    errors={errors}
                                                    placeholder='Item Quantity'
                                                    style={{
                                                        minWidth: 120,
                                                    }}
                                                />
                                            </EuiFlexItem>
                                            <EuiFlexItem>
                                                <EuiFormRow
                                                    label={`Quantity Unit Id`}
                                                    style={{
                                                        minWidth: 120,
                                                    }}
                                                >
                                                    <Controller
                                                        render={({ field }) => {
                                                            return (
                                                                <EuiSuperSelect
                                                                    options={quantityIdOptions}
                                                                    valueOfSelected={field.value}
                                                                    onChange={field.onChange}
                                                                />
                                                            );
                                                        }}
                                                        name={'quantity_unit_id'}
                                                        min={0}
                                                        step={'any'}
                                                        control={control}
                                                        inputRef={priceRef}
                                                        onWheel={onWheel}
                                                        // defaultValue={orderingMode[quantity_unit_id]}
                                                        disabled={isOutletMenu}
                                                    />
                                                </EuiFormRow>
                                            </EuiFlexItem>
                                            <EuiFlexItem>
                                                <TextField
                                                    label={`Serves`}
                                                    name={'serves'}
                                                    control={control}
                                                    inputRef={priceRef}
                                                    onWheel={onWheel}
                                                    // defaultValue={orderingMode[serves]}
                                                    disabled={isOutletMenu}
                                                    errors={errors}
                                                    placeholder='Item Serves'
                                                    style={{
                                                        minWidth: 120,
                                                    }}
                                                />
                                            </EuiFlexItem>
                                            <EuiFlexItem>
                                                <TextField
                                                    label={`Serves Unit`}
                                                    name={'serves_unit'}
                                                    control={control}
                                                    inputRef={priceRef}
                                                    onWheel={onWheel}
                                                    // defaultValue={orderingMode[serves_unit]}
                                                    disabled={isOutletMenu}
                                                    errors={errors}
                                                    placeholder='Item Serves Unit'
                                                    style={{
                                                        minWidth: 120,
                                                    }}
                                                />
                                            </EuiFlexItem>
                                        </EuiFlexGroup>
                                    </>
                                )}
                            </>
                        </EuiFlexGroup>
                    ) : null}

                    <EuiFlexItem style={{ margin: '10px 0' }}>
                        <EuiSpacer />
                        <EuiButton isDisabled={isOutletMenu} onClick={() => setVaraintModalState(true)}>
                            {!variantConditionForPrice() ? 'SEE VARIANTS' : 'ADD VARIANTS'}
                        </EuiButton>
                    </EuiFlexItem>
                </EuiFlexItem>
            </ItemDetailsCollaspibleNav>
        </>
    );
};

export default React.memo(ItemPricing);
