import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
// import { v4 as uuidv4, v1 as uuid } from 'uuid';
import NumberField from '../../../components/Form/NumberField';
import RadioField from '../../../components/Form/RadioField';
import SelectField from '../../../components/Form/SelectField';
import KitchenTimeSlot from '../../../components/Form/KitchenTimeSlot';
import { formatedDataForKitchen } from '../../../components/settings/formatedDataForKitchen';
import { userHasPermission } from '../../../helper/auth';
import API from '../../../api/axios/API';
import { EuiFlexGroup, EuiFlexItem, EuiForm, EuiButtonEmpty, EuiFormRow, EuiButton } from '@elastic/eui';
import FormGroupDescription from '../../../components/formGroupDescription';
import LoaderButton from '../../../components/Form/loaderButton';
import { outletSettingsAPI } from '../../../api/outlet/outletSettingsAPI';
import * as types from '../../../reduxStore/types/outlet';

const PreparationTime = ({ getOutletData, fetchOutletById }) => {
    const permissions = useSelector((state) => state.permission.permission);
    const restaurantDescriptions = useSelector((state) => state.auth.restaurantDescriptions);
    const {
        handleSubmit,
        control,
        watch,
        reset,
        setValue,

        formState: { isDirty, errors },
    } = useForm();
    let defaultValue = {};

    const [loader, setLoader] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!userHasPermission(permissions, '/outlet-settings', 'write')) return;

        if (isDirty) {
            dispatch({
                type: 'set-portal-active',
                payload: {
                    portalActive: {
                        resetChanges: resetChanges,
                        submit: handleSubmit(onSubmit),
                    },
                },
            });
        } else {
            dispatch({
                type: 'set-portal-active',
                payload: {
                    portalActive: {},
                },
            });
        }
    }, [isDirty]);

    const kitchen_timeSlot = [
        {
            value: '60',
            inputDisplay: 'There is no rush hour ',
        },
        {
            value: '70',
            inputDisplay: 'Add some rush hour on all days',
        },
        {
            value: '80',
            inputDisplay: 'Differant rush hour on differant days',
        },
    ];

    const resetChanges = () => {
        const outletData = getOutletData.outlet;
        defaultValue.kitchen_preparation_time = outletData.settings.kitchen_preparation_time;
        defaultValue.average_table_turn_around_time = outletData.settings.average_table_turn_around_time;
        defaultValue.average_table_turn_around_time_for_rush_hours =
            outletData.settings.average_table_turn_around_time_for_rush_hours;

        //set rush our
        defaultValue.kitchen_time_slot =
            outletData.settings.rush_hour_time_slot_setting === 'no_time_slots'
                ? '60'
                : outletData.settings.rush_hour_time_slot_setting === 'same_time_slot_for_all_days'
                ? '70'
                : outletData.settings.rush_hour_time_slot_setting === 'different_time_slots_for_different_days'
                ? '80'
                : '60';
        if (outletData.settings.rush_hour_time_slot_setting === 'same_time_slot_for_all_days') {
            let formatedTimeSlotInfoRushour = formatedDataForKitchen(outletData.settings.rush_hour_schedules);

            let fieldData = [];
            formatedTimeSlotInfoRushour &&
                formatedTimeSlotInfoRushour[1] &&
                formatedTimeSlotInfoRushour[1].map((item, index) =>
                    fieldData.push({
                        // id: uuid(),
                        start_time: item.start_time,
                        end_time: item.end_time,
                        preparation_time_in_rush: item.preparation_time_in_rush,
                    })
                );
            defaultValue.kitchen_time = fieldData;
        }
        defaultValue.kitchen_time_weak = {
            sunday: [],
            monday: [],
            tuesday: [],
            wednesday: [],
            thursday: [],
            friday: [],
            saturday: [],
        };
        if (outletData.settings.rush_hour_time_slot_setting === 'different_time_slots_for_different_days') {
            let formatedTimeSlotInfo = formatedDataForKitchen(outletData.settings.rush_hour_schedules);
            for (const key in formatedTimeSlotInfo) {
                if (key === 7) {
                    formatedTimeSlotInfo[7].map((item) =>
                        defaultValue.kitchen_time_weak.sunday.push({
                            // id: uuidv4(),
                            start_time: item.start_time,
                            end_time: item.end_time,
                            preparation_time_in_rush: item.preparation_time_in_rush,
                        })
                    );
                }
                if (key === 1) {
                    formatedTimeSlotInfo[1].forEach((item) =>
                        defaultValue.kitchen_time_weak.monday.push({
                            // id: uuidv4(),
                            start_time: item.start_time,
                            end_time: item.end_time,
                            preparation_time_in_rush: item.preparation_time_in_rush,
                        })
                    );
                }

                if (key === 2) {
                    formatedTimeSlotInfo[2].map((item) =>
                        defaultValue.kitchen_time_weak.tuesday.push({
                            // id: uuidv4(),
                            start_time: item.start_time,
                            end_time: item.end_time,
                            preparation_time_in_rush: item.preparation_time_in_rush,
                        })
                    );
                }

                if (key === 3) {
                    formatedTimeSlotInfo[3].map((item) =>
                        defaultValue.kitchen_time_weak.wednesday.push({
                            // id: uuidv4(),
                            start_time: item.start_time,
                            end_time: item.end_time,
                            preparation_time_in_rush: item.preparation_time_in_rush,
                        })
                    );
                }

                if (key === 4) {
                    formatedTimeSlotInfo[4].map((item) => {
                        defaultValue.kitchen_time_weak.thursday.push({
                            // id: uuidv4(),
                            start_time: item.start_time,
                            end_time: item.end_time,
                            preparation_time_in_rush: item.preparation_time_in_rush,
                        });

                        return null;
                    });
                }

                if (key === 5) {
                    formatedTimeSlotInfo[5].map((item) =>
                        defaultValue.kitchen_time_weak.friday.push({
                            // id: uuidv4(),
                            start_time: item.start_time,
                            end_time: item.end_time,
                            preparation_time_in_rush: item.preparation_time_in_rush,
                        })
                    );
                }

                if (key === 6) {
                    formatedTimeSlotInfo[6].map((item) =>
                        defaultValue.kitchen_time_weak.saturday.push({
                            // id: uuidv4(),
                            start_time: item.start_time,
                            end_time: item.end_time,
                            preparation_time_in_rush: item.preparation_time_in_rush,
                        })
                    );
                }
            }
        }
        reset({
            ...defaultValue,
            time: defaultValue.time ? defaultValue.time : [],
            weak: defaultValue.weak
                ? defaultValue.weak
                : { sunday: [], monday: [], tuesday: [], wednesday: [], thursday: [], friday: [], saturday: [] },
            kitchen_time: defaultValue.kitchen_time ? defaultValue.kitchen_time : [],
            kitchen_time_weak: defaultValue.kitchen_time_weak
                ? defaultValue.kitchen_time_weak
                : { sunday: [], monday: [], tuesday: [], wednesday: [], thursday: [], friday: [], saturday: [] },
        });
    };

    useEffect(() => {
        if (getOutletData && getOutletData.outlet) {
            resetChanges();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getOutletData]);

    const onSubmit = async (data) => {
        data.id = null;
        if (getOutletData) {
            data.id = getOutletData.outlet.outlet_id;
        }
        // data.settings = {
        data.kitchen_preparation_time = data.kitchen_preparation_time;
        // };
        let rush_hour_schedules;
        if (data.kitchen_time_slot === '60' || data.kitchen_time_slot === true) {
            data.rush_hour_schedules = null;
            data.rush_hour_time_slot_setting = 'no_time_slots';
        } else if (data.kitchen_time_slot === '70') {
            data.rush_hour_time_slot_setting = 'same_time_slot_for_all_days';
            rush_hour_schedules = [];

            let singleTimeSlot =
                data.kitchen_time &&
                data.kitchen_time.length > 0 &&
                data.kitchen_time.filter((item, index) => {
                    if (
                        item.start_time !== undefined &&
                        item.end_time !== undefined &&
                        item.preparation_time_in_rush !== undefined
                    ) {
                        return item;
                    }
                    return null;
                });
            singleTimeSlot &&
                singleTimeSlot.length > 0 &&
                rush_hour_schedules.push({
                    days_of_week: [1, 2, 3, 4, 5, 6, 7],
                    time_slots: singleTimeSlot,
                });
            data.rush_hour_schedules = rush_hour_schedules.length > 0 ? rush_hour_schedules : null;
            if (!data.rush_hour_schedules) {
                data.rush_hour_time_slot_setting = 'no_time_slots';
            }
        } else if (data.kitchen_time_slot === 8) {
            data.rush_hour_time_slot_setting = 'different_time_slots_for_different_days';

            rush_hour_schedules = [];

            let sunday =
                data.kitchen_time_weak &&
                data.kitchen_time_weak.sunday &&
                data.kitchen_time_weak.sunday.filter((item, index) => {
                    if (
                        item.start_time !== undefined &&
                        item.end_time !== undefined &&
                        item.preparation_time_in_rush !== undefined
                    ) {
                        return item;
                    }
                    return null;
                });

            sunday &&
                sunday.length > 0 &&
                rush_hour_schedules.push({
                    days_of_week: [7],
                    time_slots: sunday,
                });

            let monday =
                data.kitchen_time_weak &&
                data.kitchen_time_weak.monday &&
                data.kitchen_time_weak.monday.filter((item, index) => {
                    if (
                        item.start_time !== undefined &&
                        item.end_time !== undefined &&
                        item.preparation_time_in_rush !== undefined
                    ) {
                        return item;
                    }
                    return null;
                });
            monday &&
                monday.length > 0 &&
                rush_hour_schedules.push({
                    days_of_week: [1],
                    time_slots: monday,
                });

            let tuesday =
                data.kitchen_time_weak &&
                data.kitchen_time_weak.tuesday &&
                data.kitchen_time_weak.tuesday.filter((item, index) => {
                    if (
                        item.start_time !== undefined &&
                        item.end_time !== undefined &&
                        item.preparation_time_in_rush !== undefined
                    ) {
                        return item;
                    }
                    return null;
                });
            tuesday &&
                tuesday.length > 0 &&
                rush_hour_schedules.push({
                    days_of_week: [2],
                    time_slots: tuesday,
                });

            let wednesday =
                data.kitchen_time_weak &&
                data.kitchen_time_weak.wednesday &&
                data.kitchen_time_weak.wednesday.filter((item, index) => {
                    if (
                        item.start_time !== undefined &&
                        item.end_time !== undefined &&
                        item.preparation_time_in_rush !== undefined
                    ) {
                        return item;
                    }
                    return null;
                });
            wednesday &&
                wednesday.length > 0 &&
                rush_hour_schedules.push({
                    days_of_week: [3],
                    time_slots: wednesday,
                });

            let thursday =
                data.kitchen_time_weak &&
                data.kitchen_time_weak.thursday &&
                data.kitchen_time_weak.thursday.filter((item, index) => {
                    if (
                        item.start_time !== undefined &&
                        item.end_time !== undefined &&
                        item.preparation_time_in_rush !== undefined
                    ) {
                        return item;
                    }
                    return null;
                });
            thursday &&
                thursday.length > 0 &&
                rush_hour_schedules.push({
                    days_of_week: [4],
                    time_slots: thursday,
                });

            let friday =
                data.kitchen_time_weak &&
                data.kitchen_time_weak.friday &&
                data.kitchen_time_weak.friday.filter((item, index) => {
                    if (
                        item.start_time !== undefined &&
                        item.end_time !== undefined &&
                        item.preparation_time_in_rush !== undefined
                    ) {
                        return item;
                    }
                    return null;
                });
            friday &&
                friday.length > 0 &&
                rush_hour_schedules.push({
                    days_of_week: [5],
                    time_slots: friday,
                });

            let saturday =
                data.kitchen_time_weak &&
                data.kitchen_time_weak.saturday &&
                data.kitchen_time_weak.saturday.filter((item, index) => {
                    if (
                        item.start_time !== undefined &&
                        item.end_time !== undefined &&
                        item.preparation_time_in_rush !== undefined
                    ) {
                        return item;
                    }
                    return null;
                });
            saturday &&
                saturday.length > 0 &&
                rush_hour_schedules.push({
                    days_of_week: [6],
                    time_slots: saturday,
                });

            data.rush_hour_schedules = rush_hour_schedules.length > 0 ? rush_hour_schedules : null;
            if (!data.rush_hour_schedules) {
                data.rush_hour_time_slot_setting = 'no_time_slots';
            }
        } else {
            rush_hour_schedules = null;
            data.rush_hour_time_slot_setting = 'no_time_slots';
            data.rush_hour_schedules = null;
        }

        setLoader(true);
        dispatch(outletSettingsAPI('preparation_time', data)).then((res) => {
            if (res.success) {
                dispatch({
                    type: types.SET_PORTAL_ACTIVE,
                    payload: {
                        portalActive: {},
                    },
                });
                dispatch({
                    type: types.SET_PORTAL_ACTIVE_TAB_SWITCHED,
                    payload: {
                        tabSwitched: false,
                    },
                });
                fetchOutletById();
            } else {
                alert(res.message);
            }
        });
        setLoader(false);
    };
    return (
        <>
            {/* onSubmit={handleSubmit(onSubmit)} */}
            <EuiForm component='form' style={{ marginTop: 20 }}>
                <FormGroupDescription
                    title={restaurantDescriptions?.kitchen_preparation_time?.display_name}
                    description={restaurantDescriptions?.kitchen_preparation_time?.description}
                >
                    <NumberField
                        // label='Kitchen Prep Time'
                        errors={errors}
                        name='kitchen_preparation_time'
                        placeholder='Please enter kitchen preparation time'
                        rules={{
                            required: 'Please enter kitchen preparation time',
                            validate: (value) => value > 0 || 'Please enter positive value',
                        }}
                        step='any'
                        min={0}
                        control={control}
                        helpText='Average time taken by kitchen to serve the order.'
                    />
                </FormGroupDescription>
                {/* <FormGroupDescription title="Average table turn around time" description="description goes here...">
                <NumberField
                    // label='Average Table turn around time'
                    errors={errors}
                    name='average_table_turn_around_time'
                    placeholder='Please enter average turn around time '
                    rules={{
                        required: 'Please enter average turn around time ',
                        validate: (value) => value > 0 || 'Please enter positive value',
                    }}
                    step='any'
                    min={0}
                    control={control}
                    helpText="Average time to serve one table."
                />
            </FormGroupDescription> */}
                {/* <FormGroupDescription title="Average table turn around time for rush hours" description="description goes here...">
                <NumberField
                    // label='Average Table turn around time for rush hours'
                    errors={errors}
                    name='average_table_turn_around_time_for_rush_hours'
                    placeholder='Please enter average turn around time for rush hours'
                    rules={{
                        required: 'Please enter average turn around time for rush hours',
                        validate: (value) => value > 0 || 'Please enter positive value',
                    }}
                    step='any'
                    min={0}
                    control={control}
                    helpText="Average time to serve one table in rush hours"
                />
            </FormGroupDescription> */}

                <FormGroupDescription
                    title={restaurantDescriptions?.kitchen_rush_hours?.display_name}
                    description={restaurantDescriptions?.kitchen_rush_hours?.description}
                >
                    <SelectField
                        // label={"Decide kitchen rush hour slot"}
                        name='kitchen_time_slot'
                        options={kitchen_timeSlot}
                        valueOfSelected={watch('kitchen_time_slot') ? watch('kitchen_time_slot') : '60'}
                        onChange={(value) => setValue('kitchen_time_slot', value ? value.toString() : '60')}
                        errors={errors}
                        control={control}
                    />
                </FormGroupDescription>
            </EuiForm>
            <FormGroupDescription>
                <EuiFlexGroup style={{ width: '95%' }}>
                    <div>
                        {watch('kitchen_time_slot') === '70' && (
                            <div style={{ marginTop: '30px', marginBottom: '30px' }}>
                                <KitchenTimeSlot
                                    control={control}
                                    setValue={setValue}
                                    name='kitchen_time'
                                    watch={watch}
                                    errors={errors}
                                />
                            </div>
                        )}
                        {watch('kitchen_time_slot') === '80' && (
                            <div style={{ marginTop: '30px', marginBottom: '30px', paddingLeft: '23px' }}>
                                <EuiFlexGroup
                                    style={{
                                        marginBottom: 30,
                                        paddingBottom: 40,
                                        borderBottom: '1px solid black',
                                        // width: '90%',
                                        // paddingLeft: '23px'
                                    }}
                                >
                                    <EuiFlexGroup
                                        style={{
                                            flexDirection: 'column',
                                            textAlign: 'center',
                                            paddingTop: '20px',
                                        }}
                                    >
                                        <EuiFlexItem
                                            style={{
                                                textAlign: 'left',
                                            }}
                                        >
                                            <strong> Sunday </strong>{' '}
                                        </EuiFlexItem>

                                        <KitchenTimeSlot
                                            control={control}
                                            setValue={setValue}
                                            name='kitchen_time_weak.sunday'
                                            watch={watch}
                                        />
                                    </EuiFlexGroup>
                                </EuiFlexGroup>

                                <EuiFlexGroup
                                    style={{
                                        marginBottom: 30,
                                        paddingBottom: 40,
                                        borderBottom: '1px solid black',
                                        // width: '90%',
                                    }}
                                >
                                    <EuiFlexGroup
                                        style={{
                                            flexDirection: 'column',
                                            textAlign: 'center',
                                        }}
                                    >
                                        <EuiFlexItem
                                            style={{
                                                textAlign: 'left',
                                                marginLeft: '3%',
                                            }}
                                        >
                                            <strong> Monday </strong>
                                        </EuiFlexItem>
                                        <KitchenTimeSlot
                                            control={control}
                                            setValue={setValue}
                                            name='kitchen_time_weak.monday'
                                            watch={watch}
                                        />
                                    </EuiFlexGroup>
                                </EuiFlexGroup>

                                <EuiFlexGroup
                                    style={{
                                        marginBottom: 30,
                                        paddingBottom: 40,
                                        borderBottom: '1px solid black',
                                        // width: '90%',
                                    }}
                                >
                                    <EuiFlexGroup
                                        style={{
                                            flexDirection: 'column',
                                            textAlign: 'center',
                                        }}
                                    >
                                        <EuiFlexItem
                                            style={{
                                                textAlign: 'left',
                                                marginLeft: '3%',
                                            }}
                                        >
                                            {' '}
                                            <strong> Tuesday </strong>{' '}
                                        </EuiFlexItem>

                                        <KitchenTimeSlot
                                            control={control}
                                            setValue={setValue}
                                            name='kitchen_time_weak.tuesday'
                                            watch={watch}
                                        />
                                    </EuiFlexGroup>
                                </EuiFlexGroup>

                                <EuiFlexGroup
                                    style={{
                                        marginBottom: 30,
                                        paddingBottom: 40,
                                        borderBottom: '1px solid black',
                                        // width: '90%',
                                    }}
                                >
                                    <EuiFlexGroup
                                        style={{
                                            flexDirection: 'column',
                                            textAlign: 'center',
                                        }}
                                    >
                                        <EuiFlexItem
                                            style={{
                                                textAlign: 'left',
                                                marginLeft: '3%',
                                            }}
                                        >
                                            {' '}
                                            <strong> Wednesday </strong>{' '}
                                        </EuiFlexItem>

                                        <KitchenTimeSlot
                                            control={control}
                                            setValue={setValue}
                                            name='kitchen_time_weak.wednesday'
                                            watch={watch}
                                        />
                                    </EuiFlexGroup>
                                </EuiFlexGroup>

                                <EuiFlexGroup
                                    style={{
                                        marginBottom: 30,
                                        paddingBottom: 40,
                                        borderBottom: '1px solid black',
                                        justifyContent: 'center',
                                        // width: '90%',
                                    }}
                                >
                                    <EuiFlexGroup
                                        style={{
                                            flexDirection: 'column',
                                            textAlign: 'center',
                                        }}
                                    >
                                        <EuiFlexItem
                                            style={{
                                                textAlign: 'left',
                                                marginLeft: '3%',
                                            }}
                                        >
                                            <strong> Thursday </strong>
                                        </EuiFlexItem>
                                        <KitchenTimeSlot
                                            control={control}
                                            setValue={setValue}
                                            name='kitchen_time_weak.thursday'
                                            watch={watch}
                                        />
                                    </EuiFlexGroup>
                                </EuiFlexGroup>

                                <EuiFlexGroup
                                    style={{
                                        marginBottom: 30,
                                        paddingBottom: 40,
                                        borderBottom: '1px solid black',
                                        // width: '90%',
                                    }}
                                >
                                    <EuiFlexGroup
                                        style={{
                                            flexDirection: 'column',
                                            textAlign: 'center',
                                        }}
                                    >
                                        <EuiFlexItem
                                            style={{
                                                textAlign: 'left',
                                                marginLeft: '3%',
                                            }}
                                        >
                                            <strong> Friday </strong>
                                        </EuiFlexItem>
                                        <KitchenTimeSlot
                                            control={control}
                                            setValue={setValue}
                                            name='kitchen_time_weak.friday'
                                            watch={watch}
                                        />
                                    </EuiFlexGroup>
                                </EuiFlexGroup>

                                <EuiFlexGroup
                                    style={{
                                        marginBottom: 30,
                                        paddingBottom: 40,
                                        borderBottom: '1px solid black',
                                        justifyContent: 'center',
                                        // width: '90%',
                                    }}
                                >
                                    <EuiFlexGroup
                                        style={{
                                            flexDirection: 'column',
                                            textAlign: 'center',
                                        }}
                                    >
                                        <EuiFlexItem
                                            style={{
                                                textAlign: 'left',
                                                marginLeft: '3%',
                                            }}
                                        >
                                            <strong> Saturday </strong>
                                        </EuiFlexItem>
                                        <KitchenTimeSlot
                                            control={control}
                                            setValue={setValue}
                                            name='kitchen_time_weak.saturday'
                                            watch={watch}
                                        />
                                    </EuiFlexGroup>
                                </EuiFlexGroup>
                            </div>
                        )}
                    </div>
                </EuiFlexGroup>
            </FormGroupDescription>
            {/* <FormGroupDescription>
                {
                    isDirty && userHasPermission(permissions, '/outlet-settings', 'write') ? (
                        <EuiFormRow>
                            <EuiFlexGroup alignItems="center">
                                <LoaderButton isTime={true} callbackMethod={handleSubmit(onSubmit)} isLoading={loader}/>
                                <EuiFlexItem>
                                    <EuiButtonEmpty onClick={resetChanges}>Discard changes</EuiButtonEmpty>
                                </EuiFlexItem>
                            </EuiFlexGroup>
                        </EuiFormRow>
                    ): ''
                }
            </FormGroupDescription> */}
        </>
    );
};

export default PreparationTime;
