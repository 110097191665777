import { EuiFlexGroup, EuiFlexItem, EuiFormRow, EuiRadioGroup } from '@elastic/eui';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import NumberField from '../../Form/NumberField';
import FormGroupDescription from '../../formGroupDescription';
import './store-setting.css';

const ManualInterventionTime = () => {
    const manual_intervention = [
        { id: 'no', label: 'No' },
        { id: 'yes', label: 'Yes' },
    ];

    const {
        watch,
        formState: { errors },
        setValue,
        control,
    } = useFormContext();

    return (
        <section style={ManualInterventionTimeStyles}>
            <EuiFlexGroup direction='column'>
                <EuiFlexItem style={{ fontWeight: '700', fontSize: '20px' }}>
                    <p>Manual Intervention</p>
                </EuiFlexItem>
                <EuiFlexItem>
                    <p style={{ color: '#6a717d' }}>
                        This is the time where the system will wait for someone to take some decision before the system
                        automatically assigns the order to a rider / 3rd party rider agency.
                    </p>
                </EuiFlexItem>
            </EuiFlexGroup>
            <EuiFormRow
                isInvalid={errors.manual_intervention}
                error={errors.manual_intervention ? errors['manual_intervention'].message : ''}
                style={{ marginTop: '12px' }}
            >
                <Controller
                    render={({ field }) => (
                        <EuiRadioGroup
                            options={manual_intervention}
                            idSelected={watch('manual_intervention') ? watch('manual_intervention') : 'no'}
                            onChange={(id) => {
                                setValue('manual_intervention', id === 'no' ? 'no' : id === 'yes' ? 'yes' : 'no', {
                                    shouldDirty: true,
                                });
                            }}
                            legend={{
                                children: <span>Would you like to add intervention time? </span>,
                            }}
                        />
                    )}
                    name='manual_intervention'
                    control={control}
                    fullWidth={true}
                    rules={{ required: 'Please Select priority or prefrence' }}
                />
            </EuiFormRow>

            {watch('manual_intervention') === 'yes' && (
                <>
                    <EuiFlexGroup
                        style={{ marginTop: '12px', maxWidth: '30vw' }}
                        direction='row'
                        justifyContent='spaceBetween'
                        alignItems='center'
                    >
                        <EuiFlexItem>
                            <NumberField
                                label={''}
                                errors={errors}
                                name='intervention_time_min'
                                rules={{
                                    required: 'Please Enter Intervention Time',
                                }}
                                control={control}
                                className='input-remove-hyphen'
                                prepend='Min'
                                min={0}
                            />
                        </EuiFlexItem>
                        <EuiFlexItem>
                            <NumberField
                                label={''}
                                errors={errors}
                                name='intervention_time_sec'
                                rules={{
                                    required: 'Please Enter Intervention Time',
                                }}
                                control={control}
                                className='input-remove-hyphen'
                                prepend='Seconds'
                                min={0}
                            />
                        </EuiFlexItem>
                    </EuiFlexGroup>
                </>
            )}
        </section>
    );
};

export default ManualInterventionTime;

const ManualInterventionTimeStyles = {
    transition: 'all',
    transitionDuration: '300ms',
    maxWidth: '40vw',
    marginTop: '35px',
};
