import React from 'react';
import { EuiFormRow, EuiSuperSelect } from '@elastic/eui';

const SelectOutlet = ({ outletList, outlet, onChangeOutlet }) => {
    return (
        <div>
            <EuiFormRow label=''>
                <EuiSuperSelect
                    options={outletList}
                    valueOfSelected={outlet}
                    onChange={(value) => onChangeOutlet(value)}
                    itemLayoutAlign='top'
                    hasDividers
                />
            </EuiFormRow>
        </div>
    );
};

export default SelectOutlet;
