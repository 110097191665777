import React, { useEffect, useState } from 'react';
import { Calendar, momentLocalizer, Views } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { EuiFlexItem } from '@elastic/eui';
import { getTableBookingList } from '../../api/tableBooking/getTableBookingList';
import { useDispatch, useSelector } from 'react-redux';
import * as types from '../../reduxStore/types/tableBooking';
import { isEmpty } from 'lodash';
import './style.css';
import TableAssignModal from './tableAssignModal';
// import Agenda from "./Agenda"
import EventComponent from './customeEvent';
import EarlyTableOccupyWarningModal from './earlyTableOccupyWaningModal';
import { occupyTable } from '../../api/tableBooking/occupyTable';

const TableBookingCalander = (props) => {
    const { outletId } = props;
    const [selectedEvent, setSelectedEvent] = useState({});
    const [earlyOccupyWarningModal, setEarlyOccupyWarningModal] = useState({
        event: undefined,
        modalIsOpen: false,
    });
    const localizer = momentLocalizer(moment);
    const dispatch = useDispatch();
    const tableBookingApprovedList = useSelector((state) => state.tableBooking.tableBookingApprovedList);
    const approvedListUpdate = useSelector((state) => state.tableBooking.approvedListUpdate);
    const [dateRange, setDateRange] = useState({
        start: moment().startOf('month').subtract('7', 'days'),
        end: moment().endOf('month').add('7', 'days'),
    });

    let myEventsList = [
        {
            id: 0,
            title: 'First event',
            allDay: true,
            // 2021, 3, 12, 10, 30, 0, 0
            start: new Date('2021-03-27 08:03'),
            // 2021, 3, 12, 12, 30, 0, 0
            end: new Date('2021-03-27 10:03'),
            allDay: false,
        },
        {
            id: 2,
            title: 'Second event',
            start: new Date('2021-03-27 08:03'),
            end: new Date('2021-03-27 10:03'),
            desc: 'second event remarks',
            allDay: false,
        },
    ];

    const onDateClick = (event) => {
        if (!event.booked_tables.length) {
            setSelectedEvent(event);
        }
    };

    const occupyTableApiCall = (event) => {
        dispatch(
            occupyTable({
                outletId: event.booked_tables[0].outlet_id,
                tableId: event.booked_tables[0].id,
                customerId: event.customer_id,
                cover_count: event.booked_tables[0].capacity,
            })
        ).then((res) => {
            if (res.success) {
                alert('successfully occupies!!!!');
            } else {
                if (res.message) {
                    alert(res.message);
                }
            }
        });
    };

    const checkTime = (data) => {
        let timeDifference = parseInt(new Date(data.start) - new Date());
        if (timeDifference && parseInt(new Date(data.start) - new Date()) / 3600000 > 1) {
            setEarlyOccupyWarningModal({
                event: data,
                modalIsOpen: true,
            });
        } else {
            occupyTableApiCall(data);
        }
    };

    const fetchList = () => {
        dispatch(
            getTableBookingList({ outletId, status: 'approved', startDate: dateRange.start, endDate: dateRange.end })
        ).then((response) => {
            if (response.success) {
                let bookingList = [];
                response.outlet_table_requests.map((booking) => {
                    bookingList.push({
                        id: booking.id,
                        title: booking.customer_name,
                        // title: `${booking.customer_name} - ${booking.number_of_persons} PAX ${booking.comment ? ' - ' + booking.comment : ''}`,
                        // customer_number: `${booking.country_code} ${booking.mobile_number}`,
                        // occupancy: booking.number_of_persons,
                        // date_time: moment(`${booking.date} ${booking.time}`),
                        start: new Date(`${booking.date} ${booking.time}`),
                        end: new Date(`${booking.date} ${booking.time}`),
                        des: booking.comment,
                        booked_tables: booking.booked_tables,
                        fetchList: fetchList,
                        checkTime: checkTime,
                        outletId: outletId,
                        customer_id: booking.customer_id,
                    });
                });

                // setBookingList(bookingList)
                dispatch({
                    type: types.SET_TABLE_BOOKING_APROVED_LIST,
                    payload: {
                        tableBookingApprovedList: bookingList,
                    },
                });
            } else {
                alert('Error in fetching list');
            }
        });
    };

    useEffect(() => {
        fetchList();
    }, [outletId, approvedListUpdate, dateRange]);

    const eventColor = (event) => {
        if (event.booked_tables.length)
            return {
                className: 'rcb-event1',
            };
        else return {};
    };

    return (
        <>
            {!isEmpty(selectedEvent) && (
                <TableAssignModal
                    fetchList={fetchList}
                    selectedEvent={selectedEvent}
                    setSelectedEvent={setSelectedEvent}
                    outletId={outletId}
                />
            )}
            {earlyOccupyWarningModal.modalIsOpen && (
                <EarlyTableOccupyWarningModal
                    earlyOccupyWarningModal={earlyOccupyWarningModal}
                    setEarlyOccupyWarningModal={setEarlyOccupyWarningModal}
                    occupyTableApiCall={occupyTableApiCall}
                />
            )}
            <EuiFlexItem style={{ marginTop: '15px' }}>
                <Calendar
                    onRangeChange={(e) => setDateRange(e)}
                    localizer={localizer}
                    events={tableBookingApprovedList}
                    startAccessor='start'
                    endAccessor='end'
                    length={1}
                    // views={{month: true, agenda: AgendaView}}
                    views={[Views.MONTH, Views.AGENDA]}
                    eventPropGetter={eventColor}
                    components={{
                        agenda: {
                            event: EventComponent,
                        },
                    }}
                    // components={compoenent}
                    // components={{
                    //     // event: this.formatEvent,
                    //     agenda: CustomAgenda
                    //   }}
                    // style={{ height: 500 }}
                    className='calanderView'
                    onSelectEvent={(e) => onDateClick(e)}
                    // timeslots={15}
                    popup
                    // drilldownView="agenda"
                    // showMultiDayTimes={true}
                />
            </EuiFlexItem>
        </>
    );
};

export default TableBookingCalander;
