import React, { useCallback, useEffect, useState } from 'react';
import { EuiDragDropContext, EuiDroppable, euiDragDropReorder, EuiFlexItem } from '@elastic/eui';
import { useDispatch, useSelector } from 'react-redux';
import LoadingOverlay from 'react-loading-overlay';
import CategoryRow from './categoryRow';
import './style.css';
import {
    fetchOutletCatalogue,
    fetchRestaurantCatalogue,
    updateCategorySequence,
    updateCategorySequenceForOutlet,
} from './Api/FetchCatalogueInfo';
import { SET_RESTAURANT_CATALOGUE } from '../Store/MenuTypes';

const CategoryList = ({ isOutletMenu }) => {
    const dispatch = useDispatch();
    const selectedOutletId = useSelector((state) => state.outlet.selectedOutletId);
    const languageId = useSelector((state) => state.language.languageId);
    const categories = useSelector((state) => state.menuReducer.restaurantCategories);
    const [loaderOverlay, setLoaderOverlay] = useState(false);

    const onCategoryDragEnd = ({ source, destination }, currentCategory = null) => {
        if (source && destination) {
            let reorderedCategories = euiDragDropReorder(
                categories.filter((category) => {
                    return category.parent_id === null;
                }),
                source.index,
                destination.index
            );

            if (!isOutletMenu) {
                setLoaderOverlay(true);
                updateCategorySequence(reorderedCategories).then((res) => {
                    if (res.success) {
                        fetchRestaurantCatalogue(languageId).then((response) => {
                            dispatch({
                                type: SET_RESTAURANT_CATALOGUE,
                                payload: response.categories,
                            });
                        });

                        setLoaderOverlay(false);
                    } else {
                        setLoaderOverlay(false);
                        alert('Error in sequence change');
                    }
                });
            } else {
                // outletCategorySequenceChange
                setLoaderOverlay(true);
                updateCategorySequenceForOutlet(selectedOutletId, reorderedCategories).then((res) => {
                    if (res.success) {
                        fetchOutletCatalogue(selectedOutletId, languageId).then((response) => {
                            dispatch({
                                type: SET_RESTAURANT_CATALOGUE,
                                payload: response.categories,
                            });
                        });
                        setLoaderOverlay(false);
                    } else {
                        setLoaderOverlay(false);
                        alert('Error in sequence change');
                    }
                });
            }
        }
    };

    return (
        <EuiFlexItem>
            <EuiDragDropContext onDragEnd={(dragProp) => onCategoryDragEnd(dragProp)}>
                <LoadingOverlay active={loaderOverlay} spinner text='Re-arranging sequence'>
                    <EuiDroppable className='menuHeightClass scrollbar-sm' droppableId='DROPPABLE_AREA_CATEGORY_LIST'>
                        {categories
                            .filter((category) => {
                                return category.parent_id === null;
                            })
                            .map((category, index) => {
                                return (
                                    <CategoryRow
                                        key={category.category_id}
                                        isOutletMenu={isOutletMenu}
                                        index={index}
                                        category={category}
                                    />
                                );
                            })}
                    </EuiDroppable>
                </LoadingOverlay>
            </EuiDragDropContext>
        </EuiFlexItem>
    );
};

export default CategoryList;
