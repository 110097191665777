import {
    EuiButton,
    EuiCard,
    EuiFlexGroup,
    EuiFlexItem,
    EuiFlyout,
    EuiFlyoutBody,
    EuiFlyoutFooter,
    EuiFlyoutHeader,
    EuiLoadingContent,
    EuiSpacer,
    EuiText,
    EuiTitle,
    EuiGlobalToastList,
} from '@elastic/eui';
import { isEmpty } from 'lodash';
import moment from 'moment';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ReactToPrint from 'react-to-print';
import { userHasPermission } from '../../helper/auth';
import { fetchOutletSections } from './Api/fetchOutletSections';
import { sessionSettle } from './Api/sessionSettle';
import TableOrders from './TableOrders';
import TableOrdersBillPrint from './TableOrdersBillPrint';

export const NoRunningOrders = () => {
    return (
        <EuiCard
            title={
                <EuiTitle size='s'>
                    <h2>No Running Orders</h2>
                </EuiTitle>
            }
        />
    );
};

const OrdersFlyout = ({ selectedTable, closeFlyout }) => {
    const history = useHistory();
    const permissions = useSelector((state) => state.permission.permission);
    const tableDetails = useSelector((state) => state.tableManagement.tableOrderDetails);
    const tableOrderDetails = useSelector((state) => state.tableManagement.tableOrderDetails?.orders);
    const [netTotal, setNetTotal] = useState(0);
    const [toasts, setToasts] = useState([]);
    const dispatch = useDispatch();
    const orderBillRef = useRef(null);
    const languageId = useSelector((state) => state.language.languageId);
    let restaurantList = useSelector((state) => state.auth.restaurantList);
    let selectedRestuarantId = useSelector((state) => state.auth.selectedRestaurantId);
    const [tableTime, setTableTime] = useState(
        moment(selectedTable?.current_session?.started_at)
            .fromNow()
            .replace('a few seconds ago', '0 Mins')
            .replace('a minute ago', '0 Mins')
            .replace('minutes', 'Mins')
            .replace('ago', '')
            .replace('hours', 'hrs')
    );

    useEffect(() => {
        if (selectedTable?.current_session?.started_at) {
            var interval = setInterval(() => {
                setTableTime(
                    moment(selectedTable?.current_session?.started_at)
                        .fromNow()
                        .replace('a few seconds ago', '0 Mins')
                        .replace('a minute ago', '0 Mins')
                        .replace('minutes', 'Mins')
                        .replace('ago', '')
                        .replace('hours', 'hrs')
                );
            }, 600);
        }
        return () => {
            clearInterval(interval);
        };
    }, [selectedTable?.current_session?.started_at]);

    useEffect(() => {
        if (selectedTable?.current_session?.orders?.length) {
            let net_total = 0;
            selectedTable.current_session.orders.filter(
                (order) => (net_total = parseInt(order.net_total) + parseInt(net_total))
            );
            setNetTotal(net_total);
        }
    }, [selectedTable]);

    const handleSessionSettlement = useCallback(async () => {
        await sessionSettle(selectedTable.current_session.id).then((response) => {
            closeFlyout();
            if (!response.isError) {
                setToasts([
                    ...toasts,
                    {
                        title: response.message,
                    },
                ]);
                dispatch(fetchOutletSections());
            } else {
                setToasts([
                    ...toasts,
                    {
                        title: response.message,
                    },
                ]);
            }
        });
    }, [dispatch, selectedTable, toasts, closeFlyout]);

    const removeToast = useCallback(
        (removedToast) => {
            setToasts(toasts.filter((toast) => toast.id !== removedToast.id));
        },
        [toasts]
    );

    return (
        <>
            {tableOrderDetails?.length ? (
                <div style={{ display: 'none' }}>
                    <TableOrdersBillPrint
                        details={tableOrderDetails}
                        languageId={languageId}
                        ref={(el) => (orderBillRef.current = el)}
                        restaurantList={restaurantList}
                        selectedRestaurantId={selectedRestuarantId}
                    />
                </div>
            ) : null}
            <EuiGlobalToastList toasts={toasts} dismissToast={removeToast} toastLifeTimeMs={6000} />
            <EuiFlyout size='m' onClose={closeFlyout}>
                <EuiFlyoutHeader hasBorder>
                    <EuiTitle>
                        <h2>{selectedTable.internal_name}</h2>
                    </EuiTitle>
                </EuiFlyoutHeader>
                <EuiFlyoutBody>
                    {isEmpty(tableDetails) ? (
                        <EuiLoadingContent lines={3} />
                    ) : (
                        <>
                            {selectedTable?.current_session ? (
                                <>
                                    <EuiCard
                                        textAlign='left'
                                        title={
                                            <EuiTitle size='s'>
                                                <h2>{selectedTable.internal_name}</h2>
                                            </EuiTitle>
                                        }
                                        description={
                                            <>
                                                <EuiText>
                                                    {!selectedTable?.current_session
                                                        ? 'Ideal'
                                                        : selectedTable?.current_session?.kot_running_order_counts > 0
                                                        ? 'KOT Running'
                                                        : selectedTable?.current_session?.running_order_counts > 0 &&
                                                          selectedTable?.current_session?.kot_running_order_counts === 0
                                                        ? 'Running'
                                                        : null}
                                                </EuiText>
                                                <EuiText>{netTotal ? '₹' + netTotal : 0}</EuiText>
                                                <EuiText>{tableTime}</EuiText>
                                            </>
                                        }
                                    />
                                    <EuiSpacer />
                                    {tableOrderDetails?.length ? (
                                        tableOrderDetails.map((order) => {
                                            return (
                                                <React.Fragment key={order.order_id.toString()}>
                                                    <TableOrders selectedTable={selectedTable} order={order} />{' '}
                                                    <EuiSpacer />
                                                </React.Fragment>
                                            );
                                        })
                                    ) : (
                                        <NoRunningOrders />
                                    )}
                                </>
                            ) : (
                                <NoRunningOrders />
                            )}
                        </>
                    )}
                </EuiFlyoutBody>
                <EuiFlyoutFooter>
                    <EuiFlexGroup wrap responsive direction='row' justifyContent='spaceBetween' alignItems='center'>
                        <EuiFlexItem>
                            <ReactToPrint
                                copyStyles={false}
                                trigger={() => <EuiButton fill>Print Bill</EuiButton>}
                                content={() => orderBillRef.current}
                            />
                        </EuiFlexItem>
                        <EuiFlexItem>
                            <EuiButton
                                fill
                                disabled={!userHasPermission(permissions, history.location.pathname, 'write')}
                                onClick={handleSessionSettlement}
                            >
                                Settle Table
                            </EuiButton>
                        </EuiFlexItem>
                    </EuiFlexGroup>
                </EuiFlyoutFooter>
            </EuiFlyout>
        </>
    );
};

export default OrdersFlyout;
