import API from '../axios/API';

export const fetchCreditTransactions =
    (pagination, typeOfTransaction, startDate, endDate) => async (dispatch, getState) => {
        let res;
        const outlet_id = getState().outlet.selectedOutletId;

        if (typeOfTransaction === 'infrastructure') {
            try {
                res = await API.get(
                    `restaurants/:restaurantId/infrastructure/credit-transactions?page=${
                        pagination.pageIndex + 1
                    }&per_page=${
                        pagination.pageSize
                    }&filters[sorting][column]=created_at&filters[sorting][direction]=desc&filters[date_range_start]=${startDate?.format(
                        'YYYY-MM-DD'
                    )}&filters[date_range_end]=${endDate?.format('YYYY-MM-DD')}`
                );
            } catch (error) {
                console.error('infrastructure api error', error);
            }
        }

        if (typeOfTransaction === 'services') {
            try {
                res = await API.get(
                    `restaurants/:restaurantId/outlets/${outlet_id}/services/credit-transactions?page=${
                        pagination.pageIndex + 1
                    }&per_page=${
                        pagination.pageSize
                    }&filters[sorting][column]=created_at&&filters[sorting][column]=created_at&filters[sorting][direction]=desc&filters[date_range_start]=${startDate?.format(
                        'YYYY-MM-DD'
                    )}&filters[date_range_end]=${endDate?.format('YYYY-MM-DD')}`
                );
            } catch (error) {
                console.error('services api error', error);
            }
        }

        return res;
    };
