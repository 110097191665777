import { getItem, getTaxAmount } from './utils';

export const itemLinkingResetValue = (
    defaultValue,
    category,
    totalCategoryItems,
    linkedItems,
    petPoojaItems,
    petPoojaTaxes
) => {
    let filterSelectedItem = [];
    let linkedItemList = [];
    defaultValue[`item_${category.category_id}`] = [];

    if (totalCategoryItems?.length) {
        for (const categoryKey of totalCategoryItems) {
            if (categoryKey?.[category.category_id]?.length) {
                for (const [fieldIndex, item] of categoryKey?.[category.category_id].entries()) {
                    defaultValue[`item_${category.category_id}`].push(item);
                    filterSelectedItem = linkedItems.filter((itemData) => {
                        return itemData.module_id == item.item_id && itemData.module_name === 'item';
                    });

                    for (const itemData of linkedItems) {
                        if (itemData.module_id == item.item_id && itemData.module_name === 'item') {
                            linkedItemList.push(itemData);
                        }
                    }
                    for (const [index, orderingMode] of item?.ordering_modes?.entries()) {
                        defaultValue[`item_${category.category_id}`][fieldIndex].ordering_modes[index].selected_item =
                            [];
                        for (const linkedItem of linkedItemList) {
                            if (orderingMode.restaurant_ordering_mode_id === linkedItem.restaurant_ordering_mode_id) {
                                let itemResponse = getItem(linkedItem.third_party_module_id, petPoojaItems);
                                let { taxArrayObj } = getTaxAmount(itemResponse, petPoojaTaxes, item.price);
                                if (
                                    item.item_id == linkedItem.module_id &&
                                    linkedItem.restaurant_ordering_mode_id === orderingMode.restaurant_ordering_mode_id
                                ) {
                                    defaultValue[`item_${category.category_id}`][fieldIndex].ordering_modes[
                                        index
                                    ].selected_item = [
                                        {
                                            id: linkedItem?.id,
                                            module_id: linkedItem?.module_id,
                                            itemid: linkedItem.third_party_module_id,
                                            module_name: linkedItem?.module_name,
                                            label: linkedItem.title,
                                            third_party_service_provider: 'pet-pooja',
                                            third_party_module_id: linkedItem.third_party_module_id,
                                            outlet_id: linkedItem?.outlet_id,
                                            title: linkedItem?.title,
                                            uuid: linkedItem?.third_party_module_id?.toString(),
                                            restaurant_ordering_mode_id: orderingMode?.restaurant_ordering_mode_id,
                                            linkedItem: true,
                                            tax: taxArrayObj,
                                            ...linkedItem,
                                        },
                                    ];
                                }
                            }
                            // else {
                            //     defaultValue[`item_${category.category_id}`][fieldIndex].ordering_modes[
                            //         index
                            //     ].selected_item = [];
                            // }
                        }
                    }

                    // RESETTING VARIANTS
                    let filterVariants = linkedItems?.filter((variant) => variant?.module_name === 'variant_item');

                    let filterItemVariation = petPoojaItems.filter(
                        (item) => item?.itemid === filterSelectedItem?.[0]?.third_party_module_id
                    );

                    if (item?.variants?.length) {
                        for (const [optionIndex, option] of item.variants[0]?.options.entries()) {
                            for (const [modeIndex, orderingMode] of option?.ordering_modes.entries()) {
                                defaultValue[`item_${category.category_id}`][fieldIndex].variants[0].options[
                                    optionIndex
                                ].ordering_modes[modeIndex].selected_item = [];
                                for (const linkedVariant of filterVariants) {
                                    if (
                                        orderingMode.restaurant_ordering_mode_id ===
                                            linkedVariant.restaurant_ordering_mode_id &&
                                        linkedVariant.module_id === option.id
                                    ) {
                                        filterItemVariation[0]?.variation?.forEach((petPoojaVariant) => {
                                            if (petPoojaVariant.id === linkedVariant.third_party_module_id) {
                                                defaultValue[`item_${category.category_id}`][
                                                    fieldIndex
                                                ].variants[0].options[optionIndex].ordering_modes[
                                                    modeIndex
                                                ].selected_item = [
                                                    {
                                                        variant_item_id: petPoojaVariant.id,
                                                        id: petPoojaVariant.variationid,
                                                        label: petPoojaVariant.name,
                                                        module_id: linkedVariant.module_id,
                                                        module_name: linkedVariant.module_name,
                                                        outlet_id: linkedVariant?.outlet_id,
                                                        title: linkedVariant?.title,
                                                        uuid: linkedVariant?.third_party_module_id?.toString(),
                                                        linkedItem: true,
                                                        restaurant_ordering_mode_id:
                                                            orderingMode?.restaurant_ordering_mode_id,
                                                        ...petPoojaVariant,
                                                    },
                                                ];
                                            }
                                        });
                                    }
                                    // else {
                                    //     defaultValue[`item_${category.category_id}`][fieldIndex].variants[0].options[
                                    //         optionIndex
                                    //     ].ordering_modes[modeIndex].selected_item = [];
                                    // }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    return defaultValue;
};
