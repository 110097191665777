import React from 'react';
import { EuiDroppable, htmlIdGenerator, EuiFormRow, EuiFieldText, EuiButtonIcon, EuiFieldNumber } from '@elastic/eui';
import { Controller } from 'react-hook-form';
import OrderItemSplitDraggable from './orderSplitItemDraggable';

const OrderItemSplitDroppable = (props) => {
    const { watch, field, index, errors, remove, control, list, setList } = props;

    const removePerson = () => {
        let listArray = [...list];

        field.data.map((data) => {
            listArray.push({
                content: data.item.title,
                id: htmlIdGenerator()(),
                item: { ...data.item },
            });
        });

        remove(index);
        setList(listArray);
    };

    const itemsTotal = () => {
        let total = 0;

        field.data.map((field) => {
            total += parseFloat(field.item.price);

            field.item.customizations.map((addon) => {
                total += parseFloat(addon.price);
            });
        });

        return total;
    };

    return (
        <div style={{ width: '25%', height: '100%', marginRight: '15px' }} key={field.id}>
            <EuiFormRow
                fullWidth={true}
                error={
                    errors.choicesItem && errors.choicesItem.hasOwnProperty(index) && errors.choicesItem[index]['name']
                        ? errors.choicesItem[index]['name'].message
                        : ''
                }
                isInvalid={
                    errors.choicesItem && errors.choicesItem.hasOwnProperty(index) && errors.choicesItem[index]['name']
                        ? errors.choicesItem[index]['name'].message
                        : ''
                }
                style={{ width: '150px' }}
            >
                <Controller
                    render={({ field }) => <EuiFieldText value={field.value} onChange={field.onChange} />}
                    name={`choicesItem[${index}].name`}
                    placeholder='Customer name'
                    control={control}
                    fullWidth={true}
                    isInvalid={
                        errors.choicesItem &&
                        errors.choicesItem.hasOwnProperty(index) &&
                        errors.choicesItem[index]['name']
                            ? errors.choicesItem[index]['name'].message
                            : ''
                    }
                    rules={{
                        required: 'Enter name',
                    }}
                    defaultValue={field.name}
                />
            </EuiFormRow>

            <EuiFormRow
                fullWidth={true}
                error={
                    errors.choicesItem &&
                    errors.choicesItem.hasOwnProperty(index) &&
                    errors.choicesItem[index]['mobile_number']
                        ? errors.choicesItem[index]['mobile_number'].message
                        : ''
                }
                isInvalid={
                    errors.choicesItem &&
                    errors.choicesItem.hasOwnProperty(index) &&
                    errors.choicesItem[index]['mobile_number']
                        ? errors.choicesItem[index]['mobile_number'].message
                        : ''
                }
                style={{ width: '150px' }}
            >
                <Controller
                    render={({ field }) => <EuiFieldNumber value={field.value} onChange={field.onChange} />}
                    name={`choicesItem[${index}].mobile_number`}
                    placeholder='mobile number'
                    control={control}
                    fullWidth={true}
                    isInvalid={
                        errors.choicesItem &&
                        errors.choicesItem.hasOwnProperty(index) &&
                        errors.choicesItem[index]['mobile_number']
                            ? errors.choicesItem[index]['mobile_number'].message
                            : ''
                    }
                    rules={{
                        required: 'Enter number',
                        // validate: value =>
                        //         watch('choicesItem')[index].mobile_number.length !== 10 || "should be 10",
                    }}
                    defaultValue={field.mobile_number}
                />
            </EuiFormRow>
            <div
                style={{
                    height: '30%',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    marginBottom: '10px',
                    alignItems: 'center',
                }}
            >
                <h4>Total : {itemsTotal()}</h4>
                <EuiButtonIcon
                    // onClick={() => {onRemovePerson(`DROPPABLE_AREA_TYPE_${index + 2}`); }}
                    onClick={() => removePerson()}
                    // remove(index)
                    style={{ height: '25px' }}
                    iconType='minusInCircle'
                />
            </div>
            <div style={{ height: '60%' }}>
                <EuiDroppable
                    droppableId={`DROPPABLE_AREA_TYPE_${index + 2}`}
                    type='TYPE_ONE'
                    spacing='m'
                    withPanel
                    grow={false}
                >
                    {field.data &&
                        field.data.map((item, idx) => (
                            <OrderItemSplitDraggable
                                type={`DROPPABLE_AREA_TYPE_${index + 2}`}
                                item={item}
                                idx={idx}
                                field={field}
                            />
                        ))}
                </EuiDroppable>
            </div>
        </div>
    );
};

export default OrderItemSplitDroppable;
