import React from 'react';
import { MainLayout } from '../../components/layout/mainLayout/mainLayout';
import TableBookingComponent from '../../components/tableBooking/tableBookingComponent';

const TableBooking = (props) => {
    return (
        <MainLayout title='Table Booking' showOutletSelection={true}>
            <TableBookingComponent />
        </MainLayout>
    );
};

export default TableBooking;
