import { EuiFlexGroup, EuiFlexItem, EuiFormRow, EuiSelect, EuiSuperSelect } from '@elastic/eui';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useWatch } from 'react-hook-form';
import { useSelector } from 'react-redux';
import FieldArrayNumberField from '../../Form/FieldArrayNumberField';
import TextField from '../../Form/TextField';

const VariantPricing = ({ control, errors, index, fieldVariant, watch, setValue }) => {
    const orderingModes = useSelector((state) => state.menuReducer.restaurantOrderingModes);
    const [activeOrderingModes, setActiveOrderingModes] = useState([]);
    const gstOptions = useMemo(() => {
        return [
            { value: '0', text: '0%' },
            { value: '5', text: '5%' },
            { value: '12', text: '12%' },
            { value: '18', text: '18%' },
            { value: '28', text: '28%' },
        ];
    }, []);

    const itemOrderingModes = useWatch({
        control,
        name: `variantGroup[${index}].ordering_modes`,
    });

    const onChangeGST = useCallback(
        (value, name) => {
            setValue(name, value);
        },
        [setValue]
    );

    const quantityUnits = useSelector((state) => state.menuReducer.quantityUnits);
    const [quantityIdOptions, setQuantityIdOptions] = useState([]);

    useEffect(() => {
        if (orderingModes?.length) {
            const activeOrderingModes = orderingModes.filter((orderingMode) =>
                itemOrderingModes.some(
                    (itemMode) => itemMode.restaurant_ordering_mode_id === orderingMode.id && orderingMode.status
                )
            );

            setActiveOrderingModes(activeOrderingModes);
        }
    }, [itemOrderingModes, orderingModes]);

    useEffect(() => {
        if (quantityUnits?.length) {
            let units = [];
            for (const element of quantityUnits) {
                units.push({
                    value: element.id,
                    inputDisplay: element.unit,
                });
            }
            setQuantityIdOptions(units);
        }
    }, [quantityUnits]);

    return (
        <EuiFlexItem>
            {watch(`variantGroup[${index}].is_enabled_same_price_details_for_all_ordering_modes`) ? (
                <>
                    {activeOrderingModes?.length
                        ? activeOrderingModes.map((orderingMode, idx) => {
                              const priceInputName = `variantGroup[${index}].ordering_modes[${idx}].price`;
                              const comparePriceInputName = `variantGroup[${index}].ordering_modes[${idx}].compare_price`;
                              const packagingPriceInputName = `variantGroup[${index}].ordering_modes[${idx}].packaging_charge`;
                              const gstInputName = `variantGroup[${index}].ordering_modes[${idx}].gst_slab`;
                              const quantity = `variantGroup[${index}].ordering_modes[${idx}].quantity`;
                              const quantity_unit_id = `variantGroup[${index}].ordering_modes[${idx}].quantity_unit_id`;
                              const serves = `variantGroup[${index}].ordering_modes[${idx}].serves`;
                              const serves_unit = `variantGroup[${index}].ordering_modes[${idx}].serves_unit`;

                              return (
                                  <EuiFlexGroup
                                      direction='column'
                                      wrap
                                      responsive
                                      gutterSize='s'
                                      style={{
                                          marginLeft: '10px',
                                          marginTop: idx === 0 ? 0 : 20,
                                      }}
                                  >
                                      <EuiFlexItem>
                                          <FieldArrayNumberField
                                              error={errors[priceInputName] ? errors[priceInputName].message : ''}
                                              isInvalid={errors[priceInputName]}
                                              label={`Price ${orderingMode.display_name}`}
                                              name={priceInputName}
                                              placeholder='price'
                                              rules={{
                                                  required: 'Please enter price',
                                              }}
                                              control={control}
                                              watch={watch}
                                              defaultValue={orderingMode[priceInputName]}
                                          />
                                      </EuiFlexItem>

                                      <EuiFlexItem>
                                          <FieldArrayNumberField
                                              error={
                                                  errors?.[comparePriceInputName]
                                                      ? errors[comparePriceInputName].message
                                                      : ''
                                              }
                                              isInvalid={
                                                  errors?.[comparePriceInputName] ? errors[comparePriceInputName] : ''
                                              }
                                              label={`Compare Price ${orderingMode.display_name}`}
                                              placeholder='Compare price'
                                              name={comparePriceInputName}
                                              rules={{
                                                  required: 'Please enter compare price',
                                              }}
                                              control={control}
                                              watch={watch}
                                              defaultValue={orderingMode[comparePriceInputName]}
                                          />
                                      </EuiFlexItem>

                                      <EuiFlexItem>
                                          <FieldArrayNumberField
                                              error={
                                                  errors?.[packagingPriceInputName]
                                                      ? errors[packagingPriceInputName].message
                                                      : ''
                                              }
                                              isInvalid={
                                                  errors?.[packagingPriceInputName]
                                                      ? errors[packagingPriceInputName]
                                                      : ''
                                              }
                                              label={`Packaging ${orderingMode.display_name}`}
                                              name={packagingPriceInputName}
                                              placeholder='Compare price'
                                              rules={{
                                                  required: 'Please enter packaging price',
                                              }}
                                              control={control}
                                              watch={watch}
                                              defaultValue={orderingMode[packagingPriceInputName]}
                                          />
                                      </EuiFlexItem>

                                      <EuiFlexItem>
                                          <EuiFormRow
                                              label={`GST ${orderingMode.display_name}`}
                                              style={{ maxWidth: '150px' }}
                                          >
                                              <Controller
                                                  render={() => (
                                                      <EuiSelect
                                                          options={gstOptions}
                                                          value={watch(gstInputName)}
                                                          onChange={(e) => {
                                                              onChangeGST(e.target.value, gstInputName);
                                                          }}
                                                      />
                                                  )}
                                                  defaultValue={Number(orderingMode[gstInputName])}
                                                  name={gstInputName}
                                                  control={control}
                                              />
                                          </EuiFormRow>
                                      </EuiFlexItem>
                                      <EuiFlexItem>
                                          <TextField
                                              label={`Quantity ${orderingMode.display_name}`}
                                              name={quantity}
                                              control={control}
                                              defaultValue={orderingMode[quantity]}
                                              errors={errors}
                                              placeholder='Item Quantity'
                                              style={{
                                                  minWidth: 120,
                                              }}
                                          />
                                      </EuiFlexItem>
                                      <EuiFlexItem>
                                          <EuiFormRow
                                              label={`Quantity Unit Id ${orderingMode.display_name}`}
                                              style={{
                                                  minWidth: 120,
                                              }}
                                          >
                                              <Controller
                                                  render={({ field }) => {
                                                      return (
                                                          <EuiSuperSelect
                                                              options={quantityIdOptions}
                                                              valueOfSelected={field.value}
                                                              onChange={field.onChange}
                                                          />
                                                      );
                                                  }}
                                                  name={quantity_unit_id}
                                                  min={0}
                                                  step={'any'}
                                                  control={control}
                                                  defaultValue={orderingMode[quantity_unit_id]}
                                              />
                                          </EuiFormRow>
                                      </EuiFlexItem>
                                      <EuiFlexItem>
                                          <TextField
                                              label={`Serves ${orderingMode.display_name}`}
                                              name={serves}
                                              control={control}
                                              defaultValue={orderingMode[serves]}
                                              errors={errors}
                                              placeholder='Item Serves'
                                              style={{
                                                  minWidth: 120,
                                              }}
                                          />
                                      </EuiFlexItem>
                                      <EuiFlexItem>
                                          <TextField
                                              label={`Serves Unit ${orderingMode.display_name}`}
                                              name={serves_unit}
                                              control={control}
                                              defaultValue={orderingMode[serves_unit]}
                                              errors={errors}
                                              placeholder='Item Serves Unit'
                                              style={{
                                                  minWidth: 120,
                                              }}
                                          />
                                      </EuiFlexItem>
                                  </EuiFlexGroup>
                              );
                          })
                        : null}
                </>
            ) : (
                <EuiFlexGroup
                    direction='column'
                    wrap
                    responsive
                    gutterSize='s'
                    style={{
                        marginLeft: '10px',
                    }}
                >
                    <EuiFlexItem>
                        <FieldArrayNumberField
                            error={
                                errors.variantGroup &&
                                errors.variantGroup.hasOwnProperty(index) &&
                                errors.variantGroup[index][`price`]
                                    ? errors.variantGroup[index][`price`].message
                                    : ''
                            }
                            isInvalid={
                                errors.variantGroup &&
                                errors.variantGroup.hasOwnProperty(index) &&
                                errors.variantGroup[index][`price`]
                                    ? errors.variantGroup[index][`price`]
                                    : ''
                            }
                            label={'Price'}
                            name={`variantGroup[${index}].price`}
                            placeholder='price'
                            rules={{
                                required: 'Please enter price',
                            }}
                            control={control}
                            watch={watch}
                            defaultValue={fieldVariant[`variantGroup[${index}].price`]}
                        />
                    </EuiFlexItem>

                    <EuiFlexItem>
                        <FieldArrayNumberField
                            error={
                                errors.variantGroup &&
                                errors.variantGroup.hasOwnProperty(index) &&
                                errors.variantGroup[index][`compare_price`]
                                    ? errors.variantGroup[index][`compare_price`].message
                                    : ''
                            }
                            isInvalid={
                                errors.variantGroup &&
                                errors.variantGroup.hasOwnProperty(index) &&
                                errors.variantGroup[index][`compare_price`]
                                    ? errors.variantGroup[index][`compare_price`]
                                    : ''
                            }
                            label={'Compare price'}
                            name={`variantGroup[${index}].compare_price`}
                            placeholder='Compare price'
                            rules={{
                                required: 'Please enter compare price',
                            }}
                            control={control}
                            watch={watch}
                            defaultValue={fieldVariant[`variantGroup[${index}].compare_price`]}
                        />
                    </EuiFlexItem>

                    <EuiFlexItem>
                        <FieldArrayNumberField
                            error={
                                errors?.variantGroup?.hasOwnProperty(index) &&
                                errors.variantGroup[index][`packaging_charge`]
                                    ? errors.variantGroup[index][`packaging_charge`].message
                                    : ''
                            }
                            isInvalid={
                                errors?.variantGroup?.hasOwnProperty(index) &&
                                errors.variantGroup[index][`packaging_charge`]
                                    ? errors.variantGroup[index][`packaging_charge`]
                                    : ''
                            }
                            label={'Packaging price'}
                            name={`variantGroup[${index}].packaging_charge`}
                            placeholder='Compare price'
                            rules={{
                                required: 'Please enter packaging price',
                            }}
                            control={control}
                            watch={watch}
                            defaultValue={fieldVariant[`variantGroup[${index}].packaging_charge`]}
                        />
                    </EuiFlexItem>

                    <EuiFlexItem>
                        <EuiFormRow label='GST' style={{ maxWidth: '150px' }}>
                            <Controller
                                render={() => (
                                    <EuiSelect
                                        options={gstOptions}
                                        value={watch(`variantGroup[${index}].gst_slab`)}
                                        onChange={(e) => onChangeGST(e.target.value, `variantGroup[${index}].gst_slab`)}
                                    />
                                )}
                                name={`variantGroup[${index}].gst_slab`}
                                control={control}
                                defaultValue={
                                    fieldVariant[`variantGroup[${index}].gst_slab`]
                                        ? fieldVariant[`variantGroup[${index}].gst_slab`]
                                        : '0'
                                }
                            />
                        </EuiFormRow>
                    </EuiFlexItem>
                    <EuiFlexItem>
                        <TextField
                            label={`Quantity`}
                            name={`variantGroup[${index}].quantity`}
                            control={control}
                            defaultValue={watch('quantity')}
                            errors={errors}
                            placeholder='Item Quantity'
                            style={{
                                minWidth: 120,
                            }}
                        />
                    </EuiFlexItem>
                    <EuiFlexItem>
                        <EuiFormRow
                            label={`Quantity Unit Id`}
                            style={{
                                minWidth: 120,
                            }}
                        >
                            <Controller
                                render={({ field }) => {
                                    return (
                                        <EuiSuperSelect
                                            options={quantityIdOptions}
                                            valueOfSelected={field.value}
                                            onChange={field.onChange}
                                        />
                                    );
                                }}
                                name={`variantGroup[${index}].quantity_unit_id`}
                                min={0}
                                step={'any'}
                                control={control}
                                defaultValue={watch('quantity_unit_id')}
                            />
                        </EuiFormRow>
                    </EuiFlexItem>
                    <EuiFlexItem>
                        <TextField
                            label={`Serves`}
                            name={`variantGroup[${index}].serves`}
                            control={control}
                            defaultValue={watch('serves')}
                            errors={errors}
                            placeholder='Item Serves'
                            style={{
                                minWidth: 120,
                            }}
                        />
                    </EuiFlexItem>
                    <EuiFlexItem>
                        <TextField
                            label={`Serves Unit`}
                            name={`variantGroup[${index}].serves_unit`}
                            control={control}
                            defaultValue={watch('serves_unit')}
                            errors={errors}
                            placeholder='Item Serves Unit'
                            style={{
                                minWidth: 120,
                            }}
                        />
                    </EuiFlexItem>
                </EuiFlexGroup>
            )}
        </EuiFlexItem>
    );
};

export default VariantPricing;
