import React, { useState, useEffect } from 'react';
import { Controller, useFieldArray } from 'react-hook-form';
import TimePicker from 'react-time-picker';
import {
    EuiFlexGroup,
    EuiFlexItem,
    EuiFormRow,
    EuiButtonIcon,
    EuiButton,
    EuiBasicTable,
    EuiButtonEmpty,
    EuiSelect,
    EuiFieldText,
} from '@elastic/eui';
import TimingPopover from './timingPopover';
import './style.css';
import moment from 'moment';

const TimeSlot = ({ setValue, control, name, watch, reset, setTimeSlotSave }) => {
    const { fields, append, remove } = useFieldArray({
        control,
        name: name,
    });

    const [isPopoverOpen, setIsPopoverOpen] = useState(false);

    let columnData = [];
    fields.length &&
        fields.map((field, index) => {
            columnData.push({
                id: field.id,
                start_time: field.start_time,
                end_time: field.end_time,
                index: index,
            });
        });

    const columns = [
        {
            field: 'start_time',
            name: 'Start time',
            width: '45%',
            render: (start_time, index) => (
                <>
                    <span>{start_time ? moment(start_time, ['hh:mm']).format('hh:mm a') : ''}</span>
                    <EuiFlexItem style={{ display: 'flex', flexDirection: 'row', display: 'none' }}>
                        <EuiFormRow key={index.id}>
                            <Controller
                                render={({ field }) => (
                                    <TimePicker
                                        value={field.value}
                                        onChange={(time) => {
                                            setValue(`${name}[${index.index}].start_time`, time, { shouldDirty: true });
                                        }}
                                        format='hh:mm a'
                                        amPmAriaLabel='Select AM/PM'
                                        closeClock={true}
                                        disableClock={true}
                                        required={true}
                                    />
                                )}
                                defaultValue={start_time ? start_time : null}
                                name={`${name}[${index.index}].start_time`}
                                control={control}
                                fullWidth={true}
                            />
                        </EuiFormRow>
                    </EuiFlexItem>
                </>
            ),
        },
        {
            field: 'end_time',
            name: 'End time',
            width: '45%',
            render: (end_time, index) => (
                <>
                    <span>{end_time ? moment(end_time, ['hh:mm']).format('hh:mm a') : ''}</span>
                    <EuiFlexItem style={{ display: 'flex', flexDirection: 'row', display: 'none' }}>
                        <EuiFormRow key={index.id}>
                            <Controller
                                render={({ field }) => (
                                    <TimePicker
                                        value={field.value}
                                        onChange={(time) => {
                                            setValue(`${name}[${index.index}].end_time`, time, { shouldDirty: true });
                                        }}
                                        format='hh:mm a'
                                        amPmAriaLabel='Select AM/PM'
                                        closeClock={true}
                                        disableClock={true}
                                        required={true}
                                    />
                                )}
                                defaultValue={end_time ? end_time : null}
                                name={`${name}[${index.index}].end_time`}
                                control={control}
                                fullWidth={true}
                            />
                        </EuiFormRow>
                    </EuiFlexItem>
                </>
            ),
        },
        {
            field: 'remove',
            name: '',
            width: '12%',
            render: (item, index) => (
                <div key={index.id}>
                    <EuiButtonIcon
                        aria-label='button'
                        iconType='minusInCircle'
                        onClick={() => {
                            remove(index.index);
                        }}
                    />
                </div>
            ),
        },
    ];

    const getRowProps = (item) => {
        const { id } = item;
        return {
            'data-test-subj': `row-${id}`,
            className: 'customRowClass',
            onClick: () => {},
            key: item.id,
        };
    };

    const getCellProps = (item, column) => {
        const { id } = item;
        const { field } = column;
        return {
            className: 'customCellClass',
            'data-test-subj': `cell-${id}-${field}`,
            textOnly: true,
        };
    };

    return (
        <>
            <EuiFlexItem>
                <EuiBasicTable
                    items={columnData}
                    rowHeader='firstName'
                    columns={columns}
                    rowProps={getRowProps}
                    cellProps={getCellProps}
                    style={{ minWidth: '220px' }}
                />
            </EuiFlexItem>

            <TimingPopover
                button={
                    fields.length < 6 && (
                        <EuiFlexItem
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'flex-start',
                            }}
                        >
                            <EuiButtonEmpty
                                fill
                                iconType='plusInCircleFilled'
                                onClick={() => {
                                    setIsPopoverOpen(true);
                                }}
                            >
                                Add
                            </EuiButtonEmpty>
                        </EuiFlexItem>
                    )
                }
                fields={fields}
                name={name}
                setValueParent={setValue}
                append={append}
                setIsPopoverOpen={setIsPopoverOpen}
                isPopoverOpen={isPopoverOpen}
                setTimeSlotSave={setTimeSlotSave}
            />
        </>
    );
};

export default TimeSlot;
