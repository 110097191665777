import {
    EuiButtonIcon,
    EuiCheckbox,
    EuiFlexItem,
    EuiText,
    htmlIdGenerator,
    EuiButtonEmpty,
    EuiPanel,
    EuiFormRow,
    EuiFieldText,
    EuiToolTip,
    EuiHorizontalRule,
    EuiTableHeader,
    EuiTableHeaderCell,
    EuiTable,
    EuiTableBody,
    EuiTableRow,
    EuiTableRowCell,
    EuiNotificationBadge,
    EuiConfirmModal,
    EuiOverlayMask,
} from '@elastic/eui';
import { isElement, isEmpty } from 'lodash';
import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as types from '../../reduxStore/types/billing';
import noOrderSvg from '../../assets/img/soup.svg';
import API from '../../api/axios/API';
import RunningOrderComponent from './runningOrdersComponent';
import ItemCommentModal from './itemCommentModal';
import { useForm, Controller } from 'react-hook-form';
import { agmarkSign } from '../../helper/agmarkSign';
import _ from 'lodash';
// import DeleteItemModal from "./deleteItemModal"

const BillingSectionItem = (props) => {
    const { outletId } = props;

    const {
        register,
        handleSubmit,
        control,
        watch,
        reset,
        setValue,

        formState: { errors },
    } = useForm({
        defaultValues: {},
    });

    const [commentsId, setCommentsId] = useState([]);

    const previousOrders = useSelector((state) => state.billing.previousOrders);
    const billingItem = useSelector((state) => state.billing.billingItem);
    const billingItemCartStructure = useSelector((state) => state.billing.billingItemCartStructure);
    const mode = useSelector((state) => state.billing.mode);
    const languageId = useSelector((state) => state.language.languageId);
    const discount = useSelector((state) => state.billing.discount);
    const itemFreeList = useSelector((state) => state.billing.itemFreeList);
    const finishCustomerInfo = useSelector((state) => state.billing.finishCustomerInfo);
    const customerInformation = useSelector((state) => state.billing.customerInformation);

    const dispatch = useDispatch();

    const [commentModal, setCommentModal] = useState(false);
    const [currentItemComment, setCurrentItemComment] = useState({});
    const [removeItem, setRemoveItem] = useState({});
    const [itemCountState, setItemCountState] = useState(0);

    const cartId = useSelector((state) => state.billing.cartId);
    const tableSelectedList = useSelector((state) => state.billing.tableSelectedList);

    const [itemFree, setItemFree] = useState([]);

    useEffect(() => {
        let scroller = document.getElementById(`billingSectionItemId`);
        if (scroller) {
            scroller.scrollIntoView();
        }
    }, [billingItem]);

    const onMinusItem = (item, count, billingItem) => {
        let billingItemObj = JSON.parse(JSON.stringify(billingItem));
        let id = `${item.id}i`;

        billingItemObj[id].quantity -= count;

        dispatch({
            type: types.SET_BILLING_ITEM,
            payload: {
                billingItem: billingItemObj,
            },
        });

        addItemToCart(id, billingItemObj);
    };

    const onPlusItem = (item, count, billingItem) => {
        let billingItemObj = JSON.parse(JSON.stringify(billingItem));
        let id = `${item.id}i`;

        billingItemObj[id].quantity += count;

        dispatch({
            type: types.SET_BILLING_ITEM,
            payload: {
                billingItem: billingItemObj,
            },
        });

        addItemToCart(id, billingItemObj);
    };

    const delayedQueryForPlus = useCallback(
        _.debounce((item, itemCountState, billingItem) => {
            onPlusItem(item, itemCountState, billingItem);
            setItemCountState(0);
        }, 500),
        []
    );

    const delayedQueryForMinus = useCallback(
        _.debounce((item, itemCountState, billingItem) => {
            onMinusItem(item, itemCountState, billingItem);
            setItemCountState(0);
        }, 500),
        []
    );

    const onPlusClick = (item) => {
        setItemCountState((prevState) => ++prevState);
        delayedQueryForPlus(item, itemCountState + 1, billingItem);
    };

    const onMinusClick = (item) => {
        // if(!itemCountState){
        //     let id = `${item.id}i`
        //     setItemCountState(parseInt(billingItem[id].quantity)-1)
        //     delayedQueryForMinus(item, parseInt(billingItem[id].quantity)-1, billingItem)
        //     return;
        // }

        setItemCountState((prevState) => --prevState);
        delayedQueryForPlus(item, itemCountState - 1, billingItem);
    };

    const orderBillingStructureNew = (itemInnerObj) => {
        let itemObj = {};
        Object.entries(itemInnerObj).forEach(([key, value], index) => {
            if (key !== 'cart_id' && key !== 'customer_details') {
                let innerItemObj = {};
                if (!itemObj[value.id]) {
                    innerItemObj['quantity'] = value.quantity;

                    if (value.customizations && !isEmpty(value.customizations)) {
                        innerItemObj['customizations'] = value.customizations;
                    }
                } else {
                    innerItemObj['quantity'] = parseInt(itemObj[value.id]['quantity']) + parseInt(value.quantity);

                    if (value.customizations && !isEmpty(value.customizations)) {
                        innerItemObj['customizations'] = value.customizations;
                    }
                }

                itemObj[value.id] = innerItemObj;
            }
        });

        console.log('order billing structure new ', itemObj);

        return itemObj;
    };

    const addItemToCart = async (key, itemObj, tableSelectedList1 = [...tableSelectedList]) => {
        let response;
        let url = '';

        if (!cartId) {
            url = `/en/pos/restaurants/:restaurantId/outlets/${outletId}/carts`;
        } else {
            url = `/en/pos/restaurants/:restaurantId/outlets/${outletId}/carts/${cartId}`;
        }

        let data = orderBillingStructureNew(itemObj);

        try {
            if (!cartId) {
                url = `/en/pos/restaurants/:restaurantId/outlets/${outletId}/carts`;
                response = await API.post(url, {
                    items: { ...data },
                    ordering_mode: mode,
                    table_ids: tableSelectedList1,
                });
            } else {
                url = `/en/pos/restaurants/:restaurantId/outlets/${outletId}/carts/${cartId}`;
                response = await API.put(url, {
                    items: { ...data },
                    ordering_mode: mode,
                    table_ids: tableSelectedList1,
                });
            }
        } catch (error) {
            response = error;
        }

        if (response.success) {
            isEmpty(billingItem) &&
                !cartId &&
                dispatch({
                    type: types.SET_CART_ID,
                    payload: {
                        cartId: response.cart.cart_id,
                    },
                });
            dispatch({
                type: types.SET_BILLING_ITEM_CART_STRUCTURE,
                payload: {
                    billingItemCartStructure: response,
                },
            });
            //add cart_item_id
            cartItemIdAdd(response, itemObj);
        } else {
            let billingItemObj = JSON.parse(JSON.stringify(billingItem));
            delete billingItemObj[key];
            dispatch({
                type: types.SET_BILLING_ITEM,
                payload: {
                    billingItem: billingItemObj,
                },
            });
            alert('Failed to add cart');
        }
    };

    const cartItemIdAdd = (response, itemObj) => {
        let innerObj = { ...itemObj };

        if (response.cart.cart_items.length > 0) {
            response.cart.cart_items.map((item) => {
                innerObj[`${item.item_id}i`].cart_item_id = item.cart_item_id;
            });
        }

        dispatch({
            type: types.SET_BILLING_ITEM,
            payload: {
                billingItem: innerObj,
            },
        });
    };

    const onRemoveItem = (item) => {
        let billingItemObj = JSON.parse(JSON.stringify(billingItem));
        let id = `${item.id}i`;

        if (item.customizations && item.customizations.length > 0) {
            billingItemObj[id].quantity = 0;
        } else {
            delete billingItemObj[id];
        }

        dispatch({
            type: types.SET_BILLING_ITEM,
            payload: {
                billingItem: billingItemObj,
            },
        });

        addItemToCart(id, billingItemObj);
    };

    const addonList = (addon) => {
        let addonList = [];
        if (!isEmpty(addon)) {
            Object.entries(addon).forEach(([key, value]) => {
                if (key !== 'addon_information' && key !== 'variant_information') {
                    value.map((itemAddon, index) => {
                        // index === 0 && addonList.push(<p style={{ fontSize: '12px' }}>&nbsp; {itemAddon.group_name}</p>);
                        addonList.push(
                            <p style={{ fontSize: '10px' }}>
                                - {itemAddon.name} [₹ {itemAddon.choicePrice}]
                            </p>
                        );
                    });
                }
            });
        }

        return addonList;
    };

    const onChange = (e, item) => {
        let innerArray = [...itemFreeList];

        if (e.target.checked) {
            innerArray.push(item);
        } else {
            innerArray.splice(
                innerArray.findIndex((item) => item.cart_item_id === item.cart_item_id),
                1
            );
        }

        dispatch({
            type: types.SET_ITEM_FREE_LIST,
            payload: {
                itemFreeList: innerArray,
            },
        });
    };

    const itemTotal = (item, addon) => {
        let total = item.price;

        if (addon.variant_information) {
            total = addon.variant_information.price;
        }

        Object.entries(addon).forEach(([key, value]) => {
            if (key !== 'addon_information' && key !== 'variant_information') {
                value.map((itemAddon) => {
                    total += itemAddon.choicePrice
                        ? parseFloat(itemAddon.choicePrice)
                        : parseFloat(itemAddon.choicePrice);
                });
            }
        });

        return total;
    };

    const filterItemFree = (itemFree, item) => {
        return itemFree.cart_item_id === item.cart_item_id;
    };

    const commentToText = (id, text) => {
        if (!commentsId.includes(id)) {
            let innerCommentsId = [...commentsId];
            innerCommentsId.push(id);
            setCommentsId(innerCommentsId);
        }

        setValue(`instruction-${id}`, text);
    };

    const removeTextBoxController = () => {
        setCommentsId([]);
    };

    const itemTotalWithoutAddon = (item) => {
        //    let price = item.variantPrice ? item.variantPrice : item.price
        return item.quantity ? item.price * item.quantity : item.price * item.promotional_quantity;
    };

    const itemPushMethod = (items, item) => {
        items.push(
            <EuiTableRow>
                <EuiTableRowCell>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        {discount === 'get_an_item_free' && (
                            <div style={{ paddingRight: '5px' }}>
                                <EuiCheckbox
                                    id={htmlIdGenerator()()}
                                    // checked={itemFree.includes(key)}
                                    checked={
                                        itemFreeList.filter((itemFree) => filterItemFree(itemFree, item)).length > 0
                                    }
                                    onChange={(e) => onChange(e, item)}
                                />
                            </div>
                        )}

                        <div style={{ padding: '0px 8px 5px 0px' }}>{agmarkSign(item.food_type)}</div>

                        <div>
                            <div style={{ paddingTop: '2px', paddingRight: '2px' }}>
                                <span>{item.title}</span>
                            </div>

                            {commentsId.filter((id) => id === `${item.id}`).length ? (
                                <EuiFormRow
                                    style={{ paddingTop: '5px' }}
                                    onBlur={(e) => {
                                        removeTextBoxController();
                                    }}
                                >
                                    <Controller
                                        autoFocus
                                        render={({ field }) => (
                                            <EuiFieldText value={field.value} onChange={field.onChange} />
                                        )}
                                        name={`instruction-${item.id}`}
                                        placeholder='cooking instructions..'
                                        control={control}
                                        // onChange={(e) => commentText(e, item, false, 0)}
                                        defaultValue={item.special_instruction}
                                        onKeyPress={(e) => keyPressEvent(e, item, false, 0)}
                                        append={
                                            <EuiToolTip content='save instructions'>
                                                <EuiButtonIcon
                                                    iconType='arrowRight'
                                                    aria-label='Gear this'
                                                    onClick={() => commentText(item, false, 0)}
                                                />
                                            </EuiToolTip>
                                        }
                                    />
                                </EuiFormRow>
                            ) : (
                                <>
                                    {!item.special_instruction ? (
                                        <div onClick={() => commentMethod(item, false, 0)}>
                                            <span style={{ fontSize: '10px' }} className='dotted'>
                                                Cooking instructions
                                            </span>
                                        </div>
                                    ) : (
                                        <div onClick={() => commentToText(`${item.id}`, item.special_instruction)}>
                                            {item.special_instruction && (
                                                <p
                                                    style={{ fontSize: '10px', padding: '5px 5px 5px 0px' }}
                                                    className='dotted'
                                                >
                                                    - {item.special_instruction}
                                                </p>
                                            )}
                                        </div>
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                </EuiTableRowCell>
                <EuiTableRowCell align='center'>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            border: '1px solid #d3dae6',
                            borderRadius: '3px',
                        }}
                    >
                        <div
                            onClick={() => {
                                item.quantity >= 2 && onMinusClick(item);
                            }}
                            style={{
                                width: '50px',
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'center',
                                cursor: 'pointer',
                                borderRight: '1px solid #d3dae6',
                            }}
                        >
                            <span>-</span>
                        </div>

                        <div style={{ padding: '0 5px 2px' }}>
                            <EuiNotificationBadge>
                                {item.quantity ? item.quantity : item.promotional_quantity}
                            </EuiNotificationBadge>
                        </div>

                        <div
                            onClick={() => onPlusClick(item)}
                            style={{
                                width: '50px',
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'center',
                                cursor: 'pointer',
                                borderLeft: '1px solid #d3dae6',
                            }}
                        >
                            <span>+</span>
                        </div>
                    </div>
                </EuiTableRowCell>

                <EuiTableRowCell align='right'>
                    <span>₹ {itemTotalWithoutAddon(item)}</span>
                </EuiTableRowCell>

                <EuiTableRowCell alignItems='left'>
                    <EuiButtonIcon
                        // onClick={() => onRemoveItem(item)}
                        onClick={() => setRemoveItem({ item })}
                        iconType={'cross'}
                    />
                </EuiTableRowCell>
            </EuiTableRow>
        );
    };

    const onMinusForAddons = (item, index) => {
        let billingItemObj = JSON.parse(JSON.stringify(billingItem));
        let id = `${item.id}i`;

        billingItemObj[id].customizations[index].addon_information.quantity -= 1;

        dispatch({
            type: types.SET_BILLING_ITEM,
            payload: {
                billingItem: billingItemObj,
            },
        });

        addItemToCart(id, billingItemObj);
    };

    const onPlusForAddons = (item, index) => {
        let billingItemObj = JSON.parse(JSON.stringify(billingItem));
        let id = `${item.id}i`;

        billingItemObj[id].customizations[index].addon_information.quantity += 1;

        dispatch({
            type: types.SET_BILLING_ITEM,
            payload: {
                billingItem: billingItemObj,
            },
        });

        addItemToCart(id, billingItemObj);
    };

    const onRemoveForAddons = (item, index) => {
        let billingItemObj = JSON.parse(JSON.stringify(billingItem));
        let id = `${item.id}i`;
        let innerArray = billingItemObj[id].customizations;

        innerArray.splice(index, 1);

        billingItemObj[id].customizations = innerArray;

        dispatch({
            type: types.SET_BILLING_ITEM,
            payload: {
                billingItem: billingItemObj,
            },
        });

        addItemToCart(id, billingItemObj);
    };

    const onChangeAPICall = async () => {
        let response;
        try {
            response = await API.post(``);
        } catch (error) {
            response = error;
        }

        if (response.success) {
        } else {
            alert('Error in selection');
        }
    };

    const commentMethod = (item, addons, index) => {
        let id;
        if (addons) {
            id = `${item.id}-${index}`;
        } else {
            id = `${item.id}`;
        }

        if (!commentsId.includes(id)) {
            if (commentsId.length > 0) {
                let innerArray = [...commentsId];
                innerArray.push(id);
                setCommentsId(innerArray);
            } else {
                setCommentsId([id]);
            }
        }
    };

    const filterCommentsId = (id, currentId) => {
        return id === currentId;
    };

    const keyPressEvent = (e, item, boolValue, index) => {
        if (e.key === 'Enter') {
            commentText(item, boolValue, index);
        }
    };

    const commentText = (item, addons, index) => {
        let id = `${item.id}i`;
        if (addons) {
            let billingItemObj = JSON.parse(JSON.stringify(billingItem));

            billingItemObj[id]['customizations'][index]['addon_information']['special_instruction'] = watch(
                `instruction-${item.id}-${index}`
            );

            dispatch({
                type: types.SET_BILLING_ITEM,
                payload: {
                    billingItem: billingItemObj,
                },
            });

            let innerArray = [...commentsId];
            let indexNumber = innerArray.findIndex((id) => filterCommentsId(id, `${item.id}-${index}`));
            if (indexNumber >= 0) {
                innerArray.splice(indexNumber, 1);
                setCommentsId(innerArray);
            }
        } else {
            let billingItemObj = JSON.parse(JSON.stringify(billingItem));

            billingItemObj[id]['special_instruction'] = watch(`instruction-${item.id}`);

            dispatch({
                type: types.SET_BILLING_ITEM,
                payload: {
                    billingItem: billingItemObj,
                },
            });

            let innerArray = [...commentsId];
            let indexNumber = innerArray.findIndex((id) => filterCommentsId(id, `${item.id}`));
            if (indexNumber >= 0) {
                innerArray.splice(indexNumber, 1);
                setCommentsId(innerArray);
            }
        }
    };

    const itemPushMethodForAddons = (items, item, addon, index) => {
        items.push(
            <EuiTableRow>
                <EuiTableRowCell>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        {discount === 'get_an_item_free' && (
                            <div style={{ paddingRight: '5px' }}>
                                <EuiCheckbox
                                    id={htmlIdGenerator()()}
                                    onChange={onChangeAPICall}
                                    // checked={itemFree.includes(key)}
                                    // onChange={(e) => onChange(e, item)}
                                    checked={
                                        itemFreeList.filter((itemFree) => filterItemFree(itemFree, item)).length > 0
                                    }
                                    onChange={(e) => onChange(e, item)}
                                />
                            </div>
                        )}

                        <div style={{ padding: '0px 8px 5px 0px' }}>{agmarkSign(item.food_type)}</div>

                        <div>
                            <div>
                                <span>{item.title}</span>
                            </div>
                            {addonList(addon)}

                            {commentsId.filter((id) => id === `${item.id}-${index}`).length ? (
                                <EuiFormRow
                                    style={{ paddingTop: '5px' }}
                                    onBlur={(e) => {
                                        removeTextBoxController();
                                    }}
                                >
                                    <Controller
                                        autoFocus
                                        render={({ field }) => (
                                            <EuiFieldText value={field.value} onChange={field.onChange} />
                                        )}
                                        name={`instruction-${item.id}-${index}`}
                                        placeholder='cooking instructions..'
                                        control={control}
                                        defaultValue={
                                            !isEmpty(addon.addon_information) &&
                                            addon.addon_information.special_instruction
                                                ? addon.addon_information.special_instruction
                                                : ''
                                        }
                                        onKeyPress={(e) => keyPressEvent(e, item, true, index)}
                                        append={
                                            <EuiToolTip content='save instructions'>
                                                <EuiButtonIcon
                                                    iconType='arrowRight'
                                                    aria-label='Gear this'
                                                    onClick={() => commentText(item, true, index)}
                                                />
                                            </EuiToolTip>
                                        }
                                    />
                                </EuiFormRow>
                            ) : (
                                <>
                                    {!addon.addon_information.special_instruction ? (
                                        <div onClick={() => commentMethod(item, true, index)}>
                                            <span style={{ fontSize: '10px' }} className='dotted'>
                                                Cooking instructions
                                            </span>
                                        </div>
                                    ) : (
                                        <div
                                            onClick={() =>
                                                commentToText(
                                                    `${item.id}-${index}`,
                                                    addon.addon_information.special_instruction
                                                )
                                            }
                                        >
                                            {addon.addon_information.special_instruction && (
                                                <p
                                                    style={{ fontSize: '10px', padding: '5px 5px 5px 0px' }}
                                                    className='dotted'
                                                >
                                                    - {addon.addon_information.special_instruction}
                                                </p>
                                            )}
                                        </div>
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                </EuiTableRowCell>
                <EuiTableRowCell align='center'>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            border: '1px solid #d3dae6',
                            borderRadius: '3px',
                        }}
                    >
                        <div
                            onClick={() => {
                                addon.addon_information.quantity >= 2 && onMinusForAddons(item, index);
                            }}
                            style={{
                                width: '50px',
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'center',
                                cursor: 'pointer',
                                borderRight: '1px solid #d3dae6',
                            }}
                        >
                            <span>-</span>
                        </div>

                        <div style={{ padding: '0 5px 2px' }}>
                            <EuiNotificationBadge>{addon.addon_information.quantity}</EuiNotificationBadge>
                        </div>

                        <div
                            onClick={() => onPlusForAddons(item, index)}
                            style={{
                                width: '50px',
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'center',
                                cursor: 'pointer',
                                borderLeft: '1px solid #d3dae6',
                            }}
                        >
                            <span>+</span>
                        </div>

                        {/* <EuiButtonEmpty
                            style={{width: '12px', height: '12px', color: 'black'}}
                            onClick={() => onMinusForAddons(item, index)}
                            disabled={addon.addon_information.quantity === 1 ? true : false}
                            iconType={'minusInCircle'}
                        /> */}

                        {/* <span style={{padding: "0px 10px"}}>{addon.addon_information.quantity}</span>

                        <EuiButtonIcon
                            style={{color: 'black'}}
                            onClick={() => onPlusForAddons(item, index)}
                            // disabled={item.quantity === 0}
                            iconType={'plusInCircle'} 
                        /> */}
                    </div>
                </EuiTableRowCell>

                <EuiTableRowCell align='right'>
                    <span>₹ {itemTotal(item, addon) * addon.addon_information.quantity}</span>
                </EuiTableRowCell>

                <EuiTableRowCell alignItems='left'>
                    <EuiButtonIcon
                        // onClick={() => onRemoveForAddons(item, index)}
                        onClick={() => setRemoveItem({ item, index })}
                        iconType={'cross'}
                    />
                </EuiTableRowCell>
            </EuiTableRow>
        );
    };

    const tableHeader = () => {
        return (
            <EuiTableHeader style={{ backgroundColor: '#f7f7f7' }}>
                <EuiTableHeaderCell key={10} width='100%'>
                    <div>
                        <span>New Order</span>
                    </div>
                </EuiTableHeaderCell>
            </EuiTableHeader>
        );
    };

    const renderHeaderCell = () => {
        let headers = [];

        headers.push(
            <EuiTableHeaderCell key={1} width='62%'>
                Item
            </EuiTableHeaderCell>
        );

        headers.push(
            <EuiTableHeaderCell key={2} width='20%' align='center'>
                Qty.
            </EuiTableHeaderCell>
        );

        headers.push(
            <EuiTableHeaderCell key={3} width='20%' align='right'>
                Amount
            </EuiTableHeaderCell>
        );
        headers.push(<EuiTableHeaderCell key={3} width='10%' align='left'></EuiTableHeaderCell>);

        return headers;
    };

    const itemRecord = () => {
        let items = [];

        if (!isEmpty(billingItem)) {
            Object.entries(billingItem).forEach(([key, value]) => {
                if (!value.customizations) {
                    itemPushMethod(items, value);
                } else {
                    if (value.quantity > 0 || value.promotional_quantity > 0) {
                        itemPushMethod(items, value);
                    }

                    if (value.customizations && value.customizations.length > 0) {
                        value.customizations.map((addon, index) => {
                            itemPushMethodForAddons(items, value, addon, index);
                        });
                    }
                }
            });

            let tableView = (
                <EuiFlexItem style={{ display: 'flex', flexDirection: 'column' }}>
                    {tableHeader()}
                    <EuiTable id='billingSectionItemId'>
                        <EuiTableHeader>{renderHeaderCell()}</EuiTableHeader>
                        <EuiTableBody>{items}</EuiTableBody>
                    </EuiTable>
                </EuiFlexItem>
            );
            items = tableView;
        } else {
            if (isEmpty(billingItem) && isEmpty(previousOrders)) {
                items = (
                    <div
                        style={{
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <img src={noOrderSvg} style={{ width: '25px', height: '25px' }} alt='' />
                            <br />
                            <EuiText>
                                <b style={{ textAlign: 'center' }}>No Items selected!</b>
                            </EuiText>
                        </div>
                    </div>
                );
            }
        }

        return items;
    };

    const classApply = () => {
        if (!isEmpty(billingItem) || !isEmpty(previousOrders)) {
            return mode !== 'dine_in' ? 'POSBillingSectionItem' : 'POSBillingSectionItemForDineIn';
        } else {
            if (isEmpty(customerInformation)) {
                return mode !== 'dine_in' ? 'POSBillingSectionWithoutItem' : 'POSBillingSectionWithoutItemForDineIn';
            } else {
                return mode !== 'dine_in'
                    ? 'POSBillingSectionWithoutItem'
                    : 'POSBillingSectionWithoutItemForDineInWithCustomerInfo';
            }
        }
    };

    return (
        <>
            {commentModal && (
                <ItemCommentModal setCommentModal={setCommentModal} currentItemComment={currentItemComment} />
            )}
            {!isEmpty(removeItem) && (
                <DeleteItemModal
                    onRemoveItem={onRemoveItem}
                    onRemoveForAddons={onRemoveForAddons}
                    removeItem={removeItem}
                    setRemoveItem={setRemoveItem}
                />
            )}

            {/* id="style-3" */}
            <EuiFlexItem
                style={{
                    display: 'flex',
                    overflow: 'auto',
                    padding: '0px',
                    flexDirection: 'column',
                    backgroundColor: 'transparent',
                }}
                className={classApply()}
            >
                <RunningOrderComponent />
                {itemRecord()}
            </EuiFlexItem>

            <EuiHorizontalRule style={{ margin: '0 0 5px 0' }} />
        </>
    );
};

const DeleteItemModal = (props) => {
    const { setRemoveItem, removeItem, onRemoveItem, onRemoveForAddons } = props;

    return (
        <EuiOverlayMask>
            <EuiConfirmModal
                title='Delete Item'
                onCancel={() => setRemoveItem({})}
                onConfirm={() => {
                    if (removeItem['index'] != undefined) {
                        onRemoveForAddons(removeItem.item, removeItem.index);
                    } else {
                        console.log('inside item');
                        onRemoveItem(removeItem.item);
                    }

                    setRemoveItem({});
                }}
                cancelButtonText='Cancel'
                confirmButtonText='Delete'
                defaultFocusedButton='confirm'
            >
                You are about to delete an item.
            </EuiConfirmModal>
        </EuiOverlayMask>
    );
};

export default BillingSectionItem;
