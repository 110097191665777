import { EuiButton, EuiFlexGroup, EuiFlexItem, EuiSpacer, EuiSuperSelect } from '@elastic/eui';
import React, { useRef } from 'react';
import { useDispatch } from 'react-redux';
import ReactToPrint from 'react-to-print';
import { downloadReportsData } from '../../api/reports/downloadReportsData';
import CustomDatePicker from '../Credits/CustomDatePicker';
import ReportPrint from './ReportPrint';

const ReportFilters = ({
    options,
    selectedFilter,
    setSelectedFilter,
    endDate,
    startDate,
    setEndDate,
    setStartDate,
    outletString,
    reportsData,
    column,
}) => {
    const dispatch = useDispatch();
    const printerRef = useRef(null);
    //FILTER OPTION FOR REPORTS

    const onChange = (selctedId) => {
        setSelectedFilter(selctedId);
    };

    //DOWNLOAD REPORTS CSV DATA
    const downloadCSV = () => {
        dispatch(downloadReportsData(outletString, startDate, endDate, 'date', 'asc', selectedFilter));
    };

    return (
        <>
            <div style={{ display: 'none' }}>
                <ReportPrint ref={printerRef} reportsData={reportsData} column={column} />
            </div>
            <EuiSpacer size='m' />
            <EuiFlexGroup direction='row' justifyContent='spaceBetween' alignItems='center'>
                <EuiFlexItem grow={2}>
                    <EuiSuperSelect
                        options={options}
                        valueOfSelected={selectedFilter}
                        onChange={(value) => onChange(value)}
                        hasDividers
                    />
                </EuiFlexItem>
                <EuiFlexItem>
                    <EuiFlexGroup alignItems='center'>
                        {selectedFilter === 'executive_sales_summary' ? (
                            <EuiFlexItem>
                                <ReactToPrint
                                    copyStyles={false}
                                    trigger={() => (
                                        <EuiButton disabled={!reportsData?.length} fill>
                                            PRINT REPORT
                                        </EuiButton>
                                    )}
                                    content={() => printerRef.current}
                                />
                            </EuiFlexItem>
                        ) : null}
                        <EuiFlexItem>
                            <EuiButton fill onClick={downloadCSV}>
                                Download CSV{' '}
                            </EuiButton>
                        </EuiFlexItem>
                        <EuiFlexItem grow={2} style={{ minWidth: '280px' }}>
                            <CustomDatePicker
                                isInvalid
                                startDate={startDate}
                                setStartDate={setStartDate}
                                endDate={endDate}
                                setEndDate={setEndDate}
                            />
                        </EuiFlexItem>
                    </EuiFlexGroup>
                </EuiFlexItem>
            </EuiFlexGroup>
            <EuiSpacer size='l' />
        </>
    );
};

export default React.memo(ReportFilters);
