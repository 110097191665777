import { EuiAccordion, EuiCheckbox, EuiFlexGroup, EuiFlexItem, EuiText, htmlIdGenerator } from '@elastic/eui';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { agmarkSign } from '../../../helper/agmarkSign';
import AddonVariantLink from './AddonVariantLink';

const AddonLinkItemList = ({
    item,
    handleLinkChange,
    selectedAddon,
    handleVariantLinkChange,
    addonVariantLinkStatus,
}) => {
    const isOutletMenu = useSelector((state) => state.menuReducer.isOutletMenu);
    const languageId = useSelector((state) => state.language.languageId);
    const [itemSelected, setItemSelected] = useState(false);
    const [indeterminate, setIndeterminate] = useState(false);
    // const [variantItemSelected, setVariantItemSelected] = useState(false);

    useEffect(() => {
        if (selectedAddon.linked_item?.length) {
            for (const linked_item of selectedAddon.linked_item) {
                if (!item?.variants?.length) {
                    if (linked_item === item?.item_id) {
                        setItemSelected(true);
                    }
                }
            }
        }
    }, [item?.item_id, item?.variants?.length, selectedAddon.linked_item]);

    // check for variant checkbox status init
    useEffect(() => {
        if (item?.variants?.[0]?.options?.length) {
            const isItemIndeterminate = item.variants[0].options.filter((itemOption) =>
                selectedAddon.linked_variants.some((selectedOptionId) => itemOption.id === selectedOptionId)
            );

            if (isItemIndeterminate?.length === item.variants[0].options?.length) {
                setIndeterminate(false);
            } else if (
                isItemIndeterminate?.length > 0 &&
                isItemIndeterminate?.length < item.variants[0].options?.length
            ) {
                setIndeterminate(true);
            } else if (!isItemIndeterminate?.length) {
                setIndeterminate(false);
            }
        }
    }, [item?.variants, selectedAddon?.linked_variants]);

    // after changing state of variants status

    useEffect(() => {
        if (item?.variants?.[0]?.options?.length) {
            const isItemIndeterminate = [
                ...item?.variants?.[0]?.options?.filter((itemOption) =>
                    addonVariantLinkStatus.link.some((selectedOptionId) => itemOption.id === selectedOptionId)
                ),
                ...item?.variants?.[0]?.options?.filter((itemOption) =>
                    addonVariantLinkStatus.unlink.some((selectedOptionId) => itemOption.id === selectedOptionId)
                ),
            ];

            if (isItemIndeterminate?.length === item.variants[0].options?.length) {
                setItemSelected(true);
                setIndeterminate(false);
            } else if (
                isItemIndeterminate?.length > 0 &&
                isItemIndeterminate?.length < item.variants[0].options?.length
            ) {
                setIndeterminate(true);
            } else if (!isItemIndeterminate?.length) {
                setIndeterminate(false);
                setItemSelected(false);
            }
        }
    }, [addonVariantLinkStatus.link, item.variants, addonVariantLinkStatus.unlink]);

    // check for variants ITEM status

    useEffect(() => {
        if (item?.variants?.[0]?.options?.length) {
            const isStatusUnLinked = item?.variants?.[0]?.options?.filter((itemOption) =>
                addonVariantLinkStatus.unlink.some((selectedOptionId) => itemOption.id === selectedOptionId)
            )?.length;

            if (isStatusUnLinked) {
                setItemSelected(false);
            } else {
                if (selectedAddon.linked_item?.length) {
                    for (const linked_item of selectedAddon.linked_item) {
                        if (linked_item === item?.item_id) {
                            setItemSelected(true);
                        }
                    }
                }
            }
        }
    }, [addonVariantLinkStatus.unlink, item?.item_id, item?.variants, selectedAddon.linked_item]);

    const onChange = useCallback(
        (event) => {
            const isChecked = event.target.checked;
            setItemSelected(isChecked);
            if (!item?.variants?.length) {
                handleLinkChange(isChecked, item);
            }
            //  else {
            //     console.log('item.variants[0]?.options', item.variants[0]?.options);
            //     for (const option of item.variants[0]?.options) {
            //         console.log('call twice', isChecked, 'plus option is', option);
            //         handleVariantLinkChange(isChecked, option);
            //     }
            // }
        },
        [handleLinkChange, item]
    );

    return (
        <>
            <EuiFlexGroup
                direction='row'
                justifyContent='spaceBetween'
                alignItems='flexStart'
                style={{ borderTop: '1px solid #D3DAE6' }}
            >
                <EuiFlexItem grow={10}>
                    <EuiFlexGroup gutterSize='s' alignItems='center'>
                        {!item?.variants?.length ? (
                            <>
                                <EuiFlexItem grow={false}>
                                    {item.food_type ? agmarkSign(item.food_type) : ''}
                                </EuiFlexItem>
                                <EuiFlexItem grow={10}>
                                    <EuiText>
                                        {isOutletMenu ? item.translations.title[languageId] : item.internal_name}
                                    </EuiText>
                                </EuiFlexItem>
                            </>
                        ) : (
                            <EuiAccordion
                                id={htmlIdGenerator()()}
                                style={{ width: '100%' }}
                                buttonContent={isOutletMenu ? item.translations.title[languageId] : item.internal_name}
                            >
                                <EuiFlexGroup>
                                    <EuiFlexItem>
                                        {item?.variants?.[0]?.options?.map((option) => {
                                            return (
                                                <AddonVariantLink
                                                    key={option.id.toString()}
                                                    option={option}
                                                    parentItem={item}
                                                    selectedAddon={selectedAddon}
                                                    setItemSelected={setItemSelected}
                                                    handleVariantLinkChange={handleVariantLinkChange}
                                                />
                                            );
                                        })}
                                    </EuiFlexItem>
                                </EuiFlexGroup>
                            </EuiAccordion>
                        )}
                    </EuiFlexGroup>
                </EuiFlexItem>

                <EuiFlexItem grow={false} style={{ marginLeft: '10px', marginTop: '22px' }}>
                    <EuiCheckbox
                        id={htmlIdGenerator()()}
                        label=''
                        indeterminate={indeterminate}
                        disabled={item?.variants?.length}
                        checked={itemSelected}
                        onChange={onChange}
                    />
                </EuiFlexItem>
            </EuiFlexGroup>
        </>
    );
};

export default React.memo(AddonLinkItemList);
