import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { EuiText, EuiBasicTable, EuiLink } from '@elastic/eui';
import ItemLinkFlyout from '../ItemLinkFlyout/ItemLinkFlyout';
import { linkUnlinkOffers } from './OfferLink/linkUnLinkOffers';
import { toastsErrorMessage, toastSuccessMessage } from '../../utils/toasts';
import OfferActions from '../../modules/Offers/Components/OfferActions';

const OfferList = ({ setRefetchForOffer, allOffers, fetchOffers }) => {
    const dispatch = useDispatch();
    const outletList = useSelector((state) => state.outlet.outletList);
    const currentLanguage = useSelector((state) => state.language.languageId);
    const [offerLinkFlyout, setOfferLinkFlyout] = useState({
        data: [],
        display: false,
        selectedItem: null,
    });
    const [offerLinkStatus, setOfferLinkStatus] = useState({
        link: [],
        unlink: [],
    });

    useEffect(() => {
        if (outletList?.length) {
            setOfferLinkFlyout((prevState) => ({
                ...prevState,
                data: outletList,
            }));
        }
    }, [outletList]);

    const offerName = useMemo(() => {
        return [
            {
                value: 'flat_discount',
                displayName: 'Flat Discount',
            },
            {
                value: 'percentage_discount',
                displayName: 'Percentage Discount',
            },
            {
                value: 'free_delivery',
                displayName: 'Free Delivery',
            },
            {
                value: 'free_items',
                displayName: 'Get an item',
            },
            {
                value: 'buy_x_get_y',
                displayName: 'Buy X Get Y free',
            },
            {
                value: 'menu_discount',
                displayName: 'Menu Discount',
            },
            {
                value: 'buy_one_get_one_free',
                displayName: 'BOGO',
            },
            {
                value: 'coupled_offer',
                displayName: 'Coupled Offer',
            },
            {
                value: 'combo_item_price_deal',
                displayName: 'Value Deal',
            },
        ];
    }, []);

    const toggleFlyout = useCallback((offer) => {
        setOfferLinkFlyout((prevState) => ({
            ...prevState,
            display: !prevState.display,
            selectedItem: offer,
        }));
    }, []);

    let columns = useMemo(() => {
        return [
            {
                field: 'coupon_code',
                name: 'Code',
                render: (code) => (
                    <EuiText size='s' href='#' target='_blank'>
                        {code}
                    </EuiText>
                ),
            },
            {
                field: 'offer_type',
                name: 'Type',
                align: 'center',
                render: (type) => {
                    return <EuiText size='s'>{type}</EuiText>;
                },
            },
            {
                field: 'title',
                name: 'Title',
                align: 'center',
                render: (title) => {
                    return <EuiText size='s'>{title}</EuiText>;
                },
            },
            {
                field: 'description',
                name: 'Description',
                align: 'center',
                width: '25%',
            },
            {
                field: 'link_offer',
                name: 'Link Offer',
                align: 'center',
                render: (offer) => {
                    return (
                        <EuiLink size='s' onClick={() => toggleFlyout(offer)}>
                            Link
                        </EuiLink>
                    );
                },
            },
            {
                field: 'offer_actions',
                name: 'Actions',
                align: 'right',
                render: (actions) => {
                    return (
                        <OfferActions actions={actions} setRefetchForOffer={setRefetchForOffer} allOffers={allOffers} />
                    );
                },
            },
        ];
    }, [toggleFlyout, setRefetchForOffer, allOffers]);

    const items = useMemo(() => {
        return allOffers?.offers?.data?.length
            ? allOffers?.offers?.data.map((item) => {
                  return {
                      offer_type: offerName.map((offer) => {
                          return offer.value === item.offer_type ? offer.displayName : null;
                      }),
                      title: item?.translations?.title?.[currentLanguage],
                      description: item?.translations?.description?.[currentLanguage],
                      coupon_code: item.coupon_code,
                      offer_actions: item,
                      link_offer: item,
                  };
              })
            : [];
    }, [currentLanguage, offerName, allOffers?.offers?.data]);

    const handleOnChange = useCallback(
        (event, selectedDataInfo, setIsSelected, selectedItem) => {
            const isChecked = event.target.checked;
            setIsSelected(isChecked);

            if (isChecked) {
                const doesOutletExist = selectedItem?.linked_outlets?.filter(
                    (outlet_id) => outlet_id === selectedDataInfo?.outlet_data.outlet_id
                );

                if (!doesOutletExist?.length) {
                    setOfferLinkStatus((prevState) => ({
                        ...prevState,
                        link: [...prevState?.link, selectedDataInfo?.outlet_data.outlet_id],
                    }));
                }
            } else {
                const checkedOutletLink = offerLinkStatus.link.filter(
                    (outletId) => outletId !== selectedDataInfo?.outlet_data.outlet_id
                );
                setOfferLinkStatus((prevState) => ({
                    ...prevState,
                    link: [...checkedOutletLink],
                }));
            }

            // Prepare the array for deletion
            if (isChecked) {
                const matchedModes = offerLinkStatus.unlink?.filter((outletId) => {
                    return outletId !== selectedDataInfo?.outlet_data.outlet_id;
                });
                setOfferLinkStatus((prevState) => ({
                    ...prevState,
                    unlink: [...matchedModes],
                }));
            } else {
                const matchedModes = selectedItem?.linked_outlets?.filter((outlet_id) => {
                    return outlet_id === selectedDataInfo?.outlet_data.outlet_id;
                })?.[0];

                if (matchedModes) {
                    setOfferLinkStatus((prevState) => ({
                        ...prevState,
                        unlink: [...prevState.unlink, matchedModes],
                    }));
                } else {
                    setOfferLinkStatus((prevState) => ({
                        ...prevState,
                        unlink: [...prevState.unlink],
                    }));
                }
            }
        },
        [offerLinkStatus.link, offerLinkStatus.unlink]
    );

    const handleOfferUnLink = useCallback(
        async (unlinkIds, selectedOffer) => {
            await linkUnlinkOffers(selectedOffer.id, { outlet_ids: [...unlinkIds] }, 'un-link')
                .then((response) => {
                    setIsLoading(false);
                    if (response.success) {
                        setOfferLinkStatus({
                            link: [],
                            unlink: [],
                        });
                        fetchOffers();
                        toastSuccessMessage('Offer Status Changed Successfully', dispatch);
                    }
                })
                .catch((error) => {
                    setIsLoading(false);
                    toastsErrorMessage('Oops, there was an error', dispatch);
                });
        },
        [dispatch, fetchOffers]
    );

    const handleOfferLink = useCallback(
        async (linkIds, selectedOffer) => {
            await linkUnlinkOffers(selectedOffer.id, { outlet_ids: [...linkIds] }, 'link')
                .then((response) => {
                    setIsLoading(false);
                    if (response.success) {
                        setOfferLinkStatus({
                            link: [],
                            unlink: [],
                        });
                        fetchOffers();
                        toastSuccessMessage('Offer Status Changed Successfully', dispatch);
                    }
                })
                .catch((error) => {
                    setIsLoading(false);
                    toastsErrorMessage('Oops, there was an error', dispatch);
                });
        },
        [dispatch, fetchOffers]
    );

    const [isLoading, setIsLoading] = useState(false);

    const handleOfferLinkChanges = useCallback(
        async (selectedItem) => {
            setIsLoading(true);
            if (offerLinkStatus.link?.length) {
                handleOfferLink(offerLinkStatus.link, selectedItem);
            } else if (offerLinkStatus.unlink?.length) {
                handleOfferUnLink(offerLinkStatus.unlink, selectedItem);
            }
        },
        [handleOfferLink, handleOfferUnLink, offerLinkStatus.link, offerLinkStatus.unlink]
    );

    const initSelectedItems = useCallback((outlet, setIsSelected, selectedOffer) => {
        if (selectedOffer?.linked_outlets?.length) {
            for (const outletId of selectedOffer.linked_outlets) {
                if (outletId === outlet.outlet_data.outlet_id) {
                    setIsSelected(true);
                }
            }
        }
    }, []);

    return (
        <>
            <ItemLinkFlyout
                isFlyoutOpen={offerLinkFlyout.display}
                data={offerLinkFlyout.data}
                toggleFlyout={toggleFlyout}
                selectedItem={offerLinkFlyout.selectedItem}
                labelStringLvl1={'inputDisplay'}
                appModule='/offers'
                accessLvl='write'
                isLoading={isLoading}
                initSelectedItems={initSelectedItems}
                setOfferLinkStatus={setOfferLinkStatus}
                handleFlyoutSubmitButton={handleOfferLinkChanges}
                handleOnChange={handleOnChange}
                offerLinkStatus={offerLinkStatus}
                isSubmitButtonDisable={!offerLinkStatus.link.length && !offerLinkStatus.unlink.length}
                flyoutHeader={`Link ${offerLinkFlyout?.selectedItem?.translations?.title?.[currentLanguage]}`}
            />
            <EuiBasicTable
                tableCaption='Offers Entries'
                items={items}
                rowHeader='firstName'
                columns={columns}
                responsive
            />
        </>
    );
};

export default React.memo(OfferList);
