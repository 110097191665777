import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';

import OrderComponent from './orderComponent';

const RunningOrdersComponent = (props) => {
    const previousOrders = useSelector((state) => state.billing.previousOrders);

    const [scrollerState, setScrollerCheck] = useState(false);

    useEffect(() => {
        if (!scrollerState) {
            let scroller = document.getElementById(`previousOrders-${previousOrders.length - 1}`);
            if (scroller) {
                scroller.scrollIntoView();
                setScrollerCheck(true);
            }
        }

        if (isEmpty(previousOrders)) {
            setScrollerCheck(false);
        }
    }, [previousOrders]);

    const previousOrderList = () => {
        let itemsList = [];

        if (!isEmpty(previousOrders)) {
            previousOrders.map((order, superIndex) => {
                itemsList.push(<OrderComponent superIndex={superIndex} order={order} />);
            });
        }

        return itemsList;
    };

    return <>{previousOrderList()}</>;
};

export default RunningOrdersComponent;
