import React, { useCallback, useEffect, useState } from 'react';
import { userHasPermission } from '../../../helper/auth';
import { useSelector, useDispatch } from 'react-redux';
import {
    EuiDragDropContext,
    EuiDraggable,
    EuiDroppable,
    EuiFlexGroup,
    EuiFlexItem,
    EuiIcon,
    EuiPanel,
    EuiText,
    euiDragDropReorder,
    euiDragDropMove,
    EuiEmptyPrompt,
    EuiSpacer,
    EuiLoadingContent,
} from '@elastic/eui';
import LoadingOverlay from 'react-loading-overlay';
import { outletCategorySequenceChange } from '../../../api/menu/outletCategorySequenceChange';
import { outletItemListSequenceChange } from '../../../api/menu/outletItemListSequenceChange';
import './style.css';
import { isEmpty } from 'lodash';
import RestaurantCategoryComponent from './restaurantCategoryComponent';
import OutletCategoryComponent from './outletCategoryComponent';

const OutletCategoryList = (props) => {
    const { outletBannerList, setOutletBannerList, handleUnlink, onBannerLink } = props;
    const permissions = useSelector((state) => state.permission.permission);
    const outletBannerLoader = useSelector((state) => state.banner.outletBannerLoader);
    const [loaderOverlay, setLoaderOverlay] = useState(false);
    const banners = useSelector((state) => state.banner.banners);
    const menuList = useSelector((state) => state.menu.menuList);
    const [restaurantBannerList, setRestaurantBannerList] = useState([]);
    const [selectedCategoryItems, setSelectedCategoriesItems] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState({});
    const dispatch = useDispatch();
    const outletId = useSelector((state) => state.outlet.selectedOutletId);

    useEffect(() => {
        if (outletBannerList && outletBannerList.length) {
            let restaurantBannerSubtractFromOutletBanner = menuList.filter(
                (restaurantCategory) =>
                    !outletBannerList.filter(
                        (outletBanner) => outletBanner.category_id === restaurantCategory.category_id
                    ).length
            );
            setRestaurantBannerList([...restaurantBannerSubtractFromOutletBanner]);
        } else {
            setRestaurantBannerList(!isEmpty(menuList) ? [...menuList] : []);
        }
    }, [outletBannerList, menuList]);

    // banner sequence change api call
    const onDragEnd = async ({ source, destination }) => {
        setLoaderOverlay(true);
        const lists = {
            CUSTOM_HANDLE_DROPPABLE_AREA1: restaurantBannerList,
            CUSTOM_HANDLE_DROPPABLE_AREA2: outletBannerList,
        };
        const actions = {
            CUSTOM_HANDLE_DROPPABLE_AREA1: setRestaurantBannerList,
            CUSTOM_HANDLE_DROPPABLE_AREA2: setOutletBannerList,
        };
        if (source && destination) {
            if (source.droppableId === destination.droppableId) {
                if (source.droppableId !== 'CUSTOM_HANDLE_DROPPABLE_AREA1') {
                    const items = euiDragDropReorder(lists[destination.droppableId], source.index, destination.index);
                    actions[destination.droppableId](items);
                    items.map((item) => {
                        item.id = item.category_id;
                        if (item?.sub_categories?.length) {
                            item.sub_categories.map((sub_category) => {
                                sub_category.id = sub_category.category_id;
                            });
                        }
                    });

                    await dispatch(outletCategorySequenceChange(outletId, items))
                        .then((response) => {
                            setLoaderOverlay(false);
                            if (response.success) {
                                setOutletBannerList(items);
                            } else {
                                alert(response.message);
                            }
                        })
                        .catch((err) => {
                            setLoaderOverlay(false);
                        });
                }
            } else {
                const sourceId = source.droppableId;
                const destinationId = destination.droppableId;
                const result = euiDragDropMove(lists[sourceId], lists[destinationId], source, destination);
                actions[sourceId](result[sourceId]);
                actions[destinationId](result[destinationId]);

                if (destinationId === 'CUSTOM_HANDLE_DROPPABLE_AREA2') {
                    const updatedOutletSequence = result['CUSTOM_HANDLE_DROPPABLE_AREA2'];
                    await onBannerLink(lists[sourceId][source.index].category_id)
                        .then(async (response) => {
                            setLoaderOverlay(false);
                            await dispatch(outletCategorySequenceChange(outletId, updatedOutletSequence))
                                .then((response) => {
                                    setLoaderOverlay(false);
                                    if (response.success) {
                                        setOutletBannerList(updatedOutletSequence);
                                    } else {
                                        alert(response.message);
                                    }
                                })
                                .catch((err) => {
                                    setLoaderOverlay(false);
                                });
                        })
                        .catch((error) => {
                            setLoaderOverlay(false);
                        });
                }

                if (destinationId === 'CUSTOM_HANDLE_DROPPABLE_AREA1') {
                    await handleUnlink(lists[sourceId][source.index].category_id)
                        .then((response) => {
                            setLoaderOverlay(false);
                        })
                        .catch((error) => {
                            setLoaderOverlay(false);
                        });
                }
            }
        }
    };

    const onDragEndItems = useCallback(
        ({ source, destination }) => {
            if (source && destination) {
                setLoaderOverlay(true);
                const items = euiDragDropReorder(selectedCategoryItems, source.index, destination.index);

                dispatch(outletItemListSequenceChange(outletId, items))
                    .then((res) => {
                        if (res.success) {
                            setLoaderOverlay(false);
                            setSelectedCategoriesItems(items);
                        } else {
                            setLoaderOverlay(false);
                            alert('Error in sequence change');
                        }
                    })
                    .catch((err) => setLoaderOverlay(false));
            }
        },
        [dispatch, outletId, selectedCategoryItems]
    );

    return (
        <div
            style={{
                overflowY: 'hidden',
            }}
        >
            <EuiFlexGroup style={{ minHeight: '200px', width: '100%' }} direction='row'>
                <EuiDragDropContext onDragEnd={onDragEnd}>
                    {outletBannerLoader.loader ? (
                        <EuiFlexItem grow={3}>
                            <EuiLoadingContent lines={10} />
                        </EuiFlexItem>
                    ) : (
                        <EuiFlexItem grow={3} style={{ maxHeight: '80vh' }}>
                            <EuiText>
                                <b>Restaurant catalogue list</b>
                            </EuiText>
                            <EuiSpacer size='s' />
                            <EuiDroppable
                                className='outleLinkStyle scrollbar-sm'
                                droppableId='CUSTOM_HANDLE_DROPPABLE_AREA1'
                                spacing='m'
                                withPanel
                            >
                                {/* spinner text='Re-arranging banners' */}
                                <LoadingOverlay active={loaderOverlay}>
                                    {restaurantBannerList?.length ? (
                                        restaurantBannerList.map((item, idx) => (
                                            <RestaurantCategoryComponent item={item} index={idx} />
                                        ))
                                    ) : (
                                        <EuiFlexGroup wrap style={{ marginTop: 10 }}>
                                            <EuiFlexItem>
                                                <EuiEmptyPrompt
                                                    iconType='editorStrike'
                                                    title={<h2>No Catalogue to Link</h2>}
                                                    body={<p>You have linked all catalogue to this outlet.</p>}
                                                />
                                            </EuiFlexItem>
                                        </EuiFlexGroup>
                                    )}
                                </LoadingOverlay>
                            </EuiDroppable>
                        </EuiFlexItem>
                    )}

                    <div className='arrowStyle'>
                        <EuiIcon type='sortRight' />
                        <EuiIcon type='sortLeft' />
                    </div>

                    {outletBannerLoader.loader ? (
                        <EuiFlexItem grow={4}>
                            <EuiLoadingContent lines={10} />
                        </EuiFlexItem>
                    ) : (
                        <EuiFlexItem grow={4} style={{ maxHeight: '80vh' }}>
                            <EuiText>
                                <b>Outlet linked catalogue list</b>
                            </EuiText>
                            <EuiSpacer size='s' />
                            <EuiDroppable
                                className='outleLinkStyle scrollbar-sm'
                                droppableId='CUSTOM_HANDLE_DROPPABLE_AREA2'
                                spacing='m'
                                withPanel
                            >
                                <LoadingOverlay active={loaderOverlay} spinner text='Re-arranging banners'>
                                    {outletBannerList?.length ? (
                                        outletBannerList.map((item, idx) => (
                                            <OutletCategoryComponent
                                                item={item}
                                                key={idx}
                                                selectedCategory={selectedCategory}
                                                setSelectedCategory={setSelectedCategory}
                                                setSelectedCategoriesItems={setSelectedCategoriesItems}
                                                handleUnlink={handleUnlink}
                                                outletBannerList={outletBannerList}
                                                setOutletBannerList={setOutletBannerList}
                                                index={idx}
                                            />
                                        ))
                                    ) : (
                                        <EuiFlexGroup wrap style={{ marginTop: 10 }}>
                                            <EuiFlexItem>
                                                <EuiEmptyPrompt
                                                    iconType='editorStrike'
                                                    title={<h2>No Catalogue Linked</h2>}
                                                    body={<p>You haven't linked any catalogue to this outlet.</p>}
                                                />
                                            </EuiFlexItem>
                                        </EuiFlexGroup>
                                    )}
                                </LoadingOverlay>
                            </EuiDroppable>
                        </EuiFlexItem>
                    )}
                </EuiDragDropContext>
                {outletBannerLoader.loader ? (
                    <EuiFlexItem grow={4}>
                        <EuiLoadingContent lines={10} />
                    </EuiFlexItem>
                ) : (
                    <EuiFlexItem grow={4} style={{ maxHeight: '80vh' }}>
                        <EuiText>
                            <b>{`${
                                !isEmpty(selectedCategory) ? selectedCategory.internal_name : ''
                            } catalogue items`}</b>
                        </EuiText>
                        <EuiDragDropContext onDragEnd={onDragEndItems}>
                            <EuiSpacer size='s' />
                            <EuiDroppable
                                className='outleLinkStyle scrollbar-sm'
                                droppableId='CUSTOM_HANDLE_DROPPABLE_AREA2'
                                spacing='m'
                                withPanel
                            >
                                <LoadingOverlay active={loaderOverlay}>
                                    {selectedCategoryItems?.length ? (
                                        selectedCategoryItems.map((item, idx) => (
                                            <EuiDraggable
                                                spacing='m'
                                                key={item?.item_id?.toString()}
                                                index={idx}
                                                draggableId={item?.item_id?.toString()}
                                                customDragHandle={true}
                                            >
                                                {(provided) => (
                                                    <EuiPanel className='custom' paddingSize='m'>
                                                        <EuiFlexGroup>
                                                            <EuiFlexItem
                                                                grow={false}
                                                                style={{
                                                                    justifyContent: 'center',
                                                                }}
                                                            >
                                                                {userHasPermission(permissions, '/menu', 'write') && (
                                                                    <div {...provided.dragHandleProps}>
                                                                        <EuiIcon type='grab' />
                                                                    </div>
                                                                )}
                                                            </EuiFlexItem>
                                                            <EuiFlexItem
                                                                style={{
                                                                    flexDirection: 'row',
                                                                    alignItems: 'center',
                                                                }}
                                                            >
                                                                <EuiText> {item.internal_name} </EuiText>
                                                            </EuiFlexItem>
                                                        </EuiFlexGroup>
                                                    </EuiPanel>
                                                )}
                                            </EuiDraggable>
                                        ))
                                    ) : (
                                        <>
                                            <EuiEmptyPrompt
                                                iconType='editorStrike'
                                                title={<h2>No Records</h2>}
                                                body={<p>You don't have any records in selected category.</p>}
                                            />
                                        </>
                                    )}
                                </LoadingOverlay>
                            </EuiDroppable>
                        </EuiDragDropContext>
                    </EuiFlexItem>
                )}
            </EuiFlexGroup>
        </div>
    );
};

export default React.memo(OutletCategoryList);
