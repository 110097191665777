import React, { useEffect, useState } from 'react';
import { EuiText, EuiSwitch, EuiFlexItem, EuiFlexGroup } from '@elastic/eui';
import { useDispatch, useSelector } from 'react-redux';
import OutletAvailabiliyPopup from '../settings/outlet-mode-setting/outletAvailabiliyPopup';
import { toggleOutletAvailability } from '../../api/outlet/toggleOutletAvailability';
import { getOutletList } from '../../api/outlet/getOutletList';
import { userHasPermission } from '../../helper/auth';

export const OutletSwitch = (props) => {
    const { outlet } = props;
    const [outletModeSwitchState, setOutletModeSwitchState] = useState(false);
    const permissions = useSelector((state) => state.permission.permission);
    const [isModalVisible, setIsModalVisible] = useState(false);

    const dispatch = useDispatch();

    useEffect(() => {
        setOutletModeSwitchState(outlet.settings.is_open);
    }, [outlet]);

    const onChangeSwitchState = () => {
        dispatch(toggleOutletAvailability(outlet.outlet_id, 1)).then((res) => {
            if (res.success) {
                setOutletModeSwitchState(true);
                dispatch(getOutletList());
                return;
            }

            if (res.message) {
                alert(res.message);
            }

            alert('Error in update status');
        });
    };

    return (
        <>
            <EuiFlexGroup alignItems='center'>
                <EuiFlexItem grow={8}>
                    <EuiText size='s'>Store On/Off</EuiText>
                </EuiFlexItem>

                <EuiFlexItem grow={2}>
                    <EuiSwitch
                        disabled={!userHasPermission(permissions, '/outlet-mode', 'write')}
                        label={outletModeSwitchState ? 'on' : 'off'}
                        checked={outletModeSwitchState ? true : false}
                        onChange={(e) => (outletModeSwitchState ? setIsModalVisible(true) : onChangeSwitchState())}
                    />
                </EuiFlexItem>
            </EuiFlexGroup>

            {isModalVisible && (
                <OutletAvailabiliyPopup
                    closeModal={() => setIsModalVisible(false)}
                    mode={'outlet'}
                    outletId={outlet.outlet_id}
                    setStateUpdate={setOutletModeSwitchState}
                />
            )}
        </>
    );
};
