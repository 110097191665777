import React, { useEffect, useState } from 'react';
import {
    EuiButton,
    EuiConfirmModal,
    EuiFlexGroup,
    EuiFlexItem,
    EuiOverlayMask,
    EuiModalFooter,
    EuiModal,
    EuiModalHeader,
    EuiModalHeaderTitle,
    EuiModalBody,
    EuiText,
    EuiHealth,
    EuiHighlight,
    EuiComboBox,
} from '@elastic/eui';
import { ReactComponent as ShiftArrow } from '../../assets/img/shiftArrow.svg';
import './styleTextBox.css';
import { useSelector, useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';
import './style.css';
import { shiftTable } from '../../api/billing/shiftTable';
import * as types from '../../reduxStore/types/billing';

const CopyTableModal = (props) => {
    const { setCopyTableModalState } = props;

    const tables = useSelector((state) => state.billing.tables);
    const tableData = useSelector((state) => state.billing.tableData);
    const mode = useSelector((state) => state.billing.mode);
    const [tableList, setTableList] = useState([]);
    const [selectedTable, setSelectedTable] = useState([]);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!isEmpty(tables)) {
            let innerArray = [];
            tables.map((table) => {
                // if(table.table_mode === mode){
                innerArray.push({
                    id: table.outlet_table_id,
                    label: table.name,
                    color: table.status === 'Idle' ? 'green' : '#bb2205',
                    status: table.status,
                    disabled: table.status === 'Idle' ? false : true,
                });
                // }
            });

            if (innerArray.length) {
                let idleTables = innerArray.filter((table) => table.status === 'Idle');
                let runningTables = innerArray.filter((table) => table.status !== 'Idle');
                innerArray = idleTables.concat(runningTables);
                setTableList(innerArray);
            }
        }
    }, [tables]);

    const closeModal = () => {
        setCopyTableModalState(false);
    };

    const onChange = (selectedTable) => {
        setSelectedTable(selectedTable);
    };

    const renderOption = (option, searchValue, contentClassName) => {
        const { color, label, value } = option;
        return (
            <EuiHealth color={color}>
                <span className={contentClassName}>
                    <EuiHighlight search={searchValue}>{label}</EuiHighlight>
                </span>
            </EuiHealth>
        );
    };

    const shiftTableCall = () => {
        if (selectedTable.length) {
            dispatch(
                shiftTable({ old_table_id: tableData.outlet_table_id, shifted_table_id: selectedTable[0].id })
            ).then((res) => {
                if (res.success) {
                    dispatch({
                        type: types.SET_RESET_STATE,
                    });
                    closeModal();
                }
            });
        }
    };

    return (
        <>
            <EuiOverlayMask>
                <EuiModal onClose={closeModal} className='copyModal'>
                    <EuiModalHeader>
                        <EuiModalHeaderTitle>
                            <h1></h1>
                        </EuiModalHeaderTitle>
                    </EuiModalHeader>
                    <EuiModalBody className='copyModalBody'>
                        <EuiFlexItem style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <EuiText style={{ fontSize: '18px' }}>Shift</EuiText>
                            <EuiText style={{ fontSize: '28px' }}>{!isEmpty(tableData) ? tableData.name : ''}</EuiText>
                        </EuiFlexItem>

                        <EuiFlexItem
                            style={{
                                padding: '25px 0px',
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <ShiftArrow />
                        </EuiFlexItem>

                        <EuiFlexItem
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <EuiText style={{ fontSize: '18px' }}>To</EuiText>
                            <EuiComboBox
                                placeholder='SELECT A TABLE'
                                singleSelection={{
                                    asPlainText: true,
                                }}
                                options={tableList}
                                selectedOptions={selectedTable}
                                onChange={onChange}
                                isClearable={false}
                                renderOption={renderOption}
                            />
                        </EuiFlexItem>
                        {/* </div> */}
                    </EuiModalBody>
                    <EuiModalFooter className='copyModalFooter'>
                        <EuiButton
                            style={{ fontSize: '14px', backgroundColor: '#275af6', border: 'transparent' }}
                            fill
                            onClick={shiftTableCall}
                        >
                            OKAY
                        </EuiButton>
                        <EuiButton
                            style={{
                                marginLeft: '15px',
                                fontSize: '14px',
                                backgroundColor: '#f627c2',
                                border: 'transparent',
                            }}
                            fill
                            onClick={closeModal}
                        >
                            CANCEL
                        </EuiButton>
                    </EuiModalFooter>
                </EuiModal>
            </EuiOverlayMask>
        </>
    );
};

export default CopyTableModal;
