import React from 'react';
import { MainLayout } from '../../components/layout/mainLayout/mainLayout';
import { EuiFlexGroup, EuiFlexItem } from '@elastic/eui';
import { OrderRatingComponent } from '../../components/orderRating/orderRating';
import { useSelector } from 'react-redux';
import { NoOutletMessage } from '../../utils/noOutletMessage';

const OrderRatingPage = (props) => {
    const selectedOutlet = useSelector((state) => state.outlet.selectedOutletId);

    return (
        <>
            <MainLayout title='Order rating' outlet={true}>
                {selectedOutlet ? (
                    <EuiFlexGroup direction='column'>
                        <EuiFlexItem>
                            <OrderRatingComponent />
                        </EuiFlexItem>
                    </EuiFlexGroup>
                ) : (
                    <NoOutletMessage />
                )}
            </MainLayout>
        </>
    );
};

export default OrderRatingPage;
