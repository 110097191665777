import {
    EuiButtonEmpty,
    EuiContextMenuItem,
    EuiContextMenuPanel,
    EuiDataGrid,
    EuiFlexItem,
    EuiPopover,
} from '@elastic/eui';
import React, { useMemo, useState } from 'react';
import Pagination from '../Form/Pagination';

const ReportsDataGrid = (props) => {
    const { onSort, setPagination, pagination, appState } = props;

    const handlePageChange = (page) => {
        setPagination({
            ...pagination,
            pageIndex: page,
        });
    };

    // Column visibility
    const [visibleColumns, setVisibleColumns] = useState(() => props.column.map(({ id }) => id)); // initialize to the full set of columns
    const renderCellValue = useMemo(() => {
        return ({ rowIndex, columnId, setCellProps }) => {
            return props.reportsData?.hasOwnProperty(rowIndex) ? props.reportsData[rowIndex][columnId] : null;
        };
    }, [props.reportsData]);

    const getIconType = (size) => {
        return size === pagination.pageSize ? 'check' : 'empty';
    };

    const [isPopoverOpen, setIsPopoverOpen] = useState(false);
    const onButtonClick = () => setIsPopoverOpen((isPopoverOpen) => !isPopoverOpen);
    const closePopover = () => setIsPopoverOpen(false);

    const button = (
        <EuiButtonEmpty size='s' color='text' iconType='arrowDown' iconSide='right' onClick={onButtonClick}>
            Rows per page: {pagination.pageSize}
        </EuiButtonEmpty>
    );

    const items = [
        <EuiContextMenuItem
            key='10 rows'
            icon={getIconType(10)}
            onClick={() => {
                closePopover();
                props.onChangeItemsPerPage(10);
            }}
        >
            10 rows
        </EuiContextMenuItem>,
        <EuiContextMenuItem
            key='20 rows'
            icon={getIconType(20)}
            onClick={() => {
                closePopover();
                props.onChangeItemsPerPage(20);
            }}
        >
            20 rows
        </EuiContextMenuItem>,
        <EuiContextMenuItem
            key='50 rows'
            icon={getIconType(50)}
            onClick={() => {
                closePopover();
                props.onChangeItemsPerPage(50);
            }}
        >
            50 rows
        </EuiContextMenuItem>,
        <EuiContextMenuItem
            key='100 rows'
            icon={getIconType(100)}
            onClick={() => {
                closePopover();
                props.onChangeItemsPerPage(100);
            }}
        >
            100 rows
        </EuiContextMenuItem>,
    ];

    return (
        <>
            <EuiDataGrid
                aria-label='Data grid table for reports summary'
                columns={props.column}
                columnVisibility={{ visibleColumns, setVisibleColumns }}
                rowCount={props.reportsData.length}
                renderCellValue={renderCellValue}
                inMemory={{ level: 'sorting' }}
                sorting={{ columns: props.sortingColumns, onSort }}
                height={props.reportsData.length >= 1 && '60vh'}
            />
            {appState.resportsData.summary.total > appState.resportsData.summary.per_page && (
                <Pagination
                    totalRecord={appState.resportsData.summary.total}
                    pageSize={appState.resportsData.summary.per_page}
                    activePage={appState.resportsData.summary.current_page - 1}
                    handlePageChange={handlePageChange}
                />
            )}
            <EuiFlexItem grow={false}>
                <EuiPopover button={button} isOpen={isPopoverOpen} closePopover={closePopover} panelPaddingSize='none'>
                    <EuiContextMenuPanel items={items} />
                </EuiPopover>
            </EuiFlexItem>
        </>
    );
};

export default ReportsDataGrid;
