import API from '../axios/API';

export const shiftTable = (data) => {
    return async (dispatch, getState) => {
        let response;
        let { outlet } = getState();

        try {
            response = await API.post(
                `restaurants/:restaurantID/outlets/${outlet.selectedOutletId}/customers/shift-table`,
                data
            );
        } catch (error) {
            response = error;
        }

        return response;
    };
};
