import API from '../axios/API';

export const outletSettingsAPI = (section, data) => {
    return async (dispatch, getState) => {
        let response;
        let { outlet } = getState();

        try {
            response = await API.put(
                `${process.env.REACT_APP_BASE_URL_V3}/restaurants/:restaurantId/outlets/${outlet.selectedOutletId}?section=${section}`,
                data
            );
        } catch (error) {
            response = error;
        }

        return response;
    };
};

export const outletSettingsV4 = (section, data) => {
    return async (dispatch, getState) => {
        let response;
        let { outlet } = getState();

        try {
            response = await API.put(
                `${process.env.REACT_APP_BASE_URL_V4}/restaurants/:restaurantId/outlets/${outlet.selectedOutletId}?section=${section}`,
                data
            );
        } catch (error) {
            response = error;
        }

        return response;
    };
};
