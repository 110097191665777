import React, { useEffect } from 'react';
import FormGroupDescription from '../../../components/formGroupDescription';
import { useSelector } from 'react-redux';
import TextField from '../../../components/Form/TextField';
import { useForm } from 'react-hook-form';
import { EuiSpacer, EuiButtonEmpty, EuiFormRow, EuiFlexItem, EuiFlexGroup, EuiForm } from '@elastic/eui';
import NumberField from '../../../components/Form/NumberField';
import LoaderButton from '../../../components/Form/loaderButton';
import { useDispatch } from 'react-redux';
import { outletSettingsAPI } from '../../../api/outlet/outletSettingsAPI';
import * as types from '../../../reduxStore/types/outlet';
import MobileNumber from '../../../components/Form/MobileNumber';
import { isEmpty } from 'lodash';
import { userHasPermission } from '../../../helper/auth';

const ContactDetails = (props) => {
    const { getOutletData, outletId, fetchOutletById } = props;

    const restaurantDescriptions = useSelector((state) => state.auth.restaurantDescriptions);
    const permissions = useSelector((state) => state.permission.permission);
    const languages = useSelector((state) => state.language.availableLanguages);
    const {
        handleSubmit,
        control,
        watch,
        reset,
        setValue,

        formState: { isDirty, errors },
    } = useForm();
    const dispatch = useDispatch();

    useEffect(() => {
        if (!userHasPermission(permissions, '/outlet-settings', 'write')) return;
        if (isDirty) {
            dispatch({
                type: 'set-portal-active',
                payload: {
                    portalActive: {
                        resetChanges: resetChanges,
                        submit: handleSubmit(onSubmit),
                    },
                },
            });
        } else {
            dispatch({
                type: 'set-portal-active',
                payload: {
                    portalActive: {},
                },
            });
        }
    }, [isDirty]);

    useEffect(() => {
        if (getOutletData && getOutletData.outlet) {
            resetChanges();
        }
    }, [getOutletData]);

    const resetChanges = () => {
        let defaultValue = {};
        let translationObject = {
            name: {},
        };

        for (const languageId in getOutletData.outlet.translations.name) {
            translationObject.name[languageId] = getOutletData.outlet.translations.name[languageId];
        }

        defaultValue.translations = translationObject;

        if (getOutletData.outlet.outlet_address.contact_number) {
            let country_code = getOutletData.outlet.outlet_address.country_code.slice(
                1,
                getOutletData.outlet.outlet_address.country_code.length
            );
            defaultValue.contact_number = `${country_code} ${getOutletData.outlet.outlet_address.contact_number}`;
            defaultValue.country_code = getOutletData.outlet.outlet_address.country_code;
        }

        reset({
            ...defaultValue,
        });
    };

    const onSubmit = (data) => {
        const name = {};

        for (const property in data.translations.name) {
            name[parseInt(property)] = data.translations.name[property];
        }

        data.translations.name = name;
        data.country_code = !isEmpty(data.selectedCountryNumber)
            ? `+${data.selectedCountryNumber.dialCode}`
            : watch('country_code');
        data.contact_number = !isEmpty(data.selectedCountryNumber)
            ? data.selectedCountryNumber.number
            : watch('contact_number');

        dispatch(outletSettingsAPI('contact', data)).then((res) => {
            if (res.success) {
                dispatch({
                    type: types.SET_PORTAL_ACTIVE,
                    payload: {
                        portalActive: {},
                    },
                });
                dispatch({
                    type: types.SET_PORTAL_ACTIVE_TAB_SWITCHED,
                    payload: {
                        tabSwitched: false,
                    },
                });
                fetchOutletById();
            } else {
                alert(res.message);
            }
        });
    };

    return (
        <>
            <EuiForm component='form' onSubmit={handleSubmit(onSubmit)}>
                <FormGroupDescription
                    title={restaurantDescriptions?.outlet_name?.display_name}
                    description={restaurantDescriptions?.outlet_name?.description}
                >
                    {languages &&
                        languages.map((item, index) => (
                            <>
                                <TextField
                                    key={index}
                                    errors={errors}
                                    isInvalid={
                                        errors.translations &&
                                        errors.translations.name &&
                                        errors.translations.name.hasOwnProperty(item.language_id)
                                    }
                                    error={
                                        errors.translations &&
                                        errors.translations.name &&
                                        errors.translations.name.hasOwnProperty(item.language_id) &&
                                        errors.translations.name[item.language_id]
                                            ? errors.translations.name[item.language_id].message
                                            : ''
                                    }
                                    label={index === 0 ? <strong>Name</strong> : ''}
                                    name={`translations.name.${item.language_id}`}
                                    placeholder={`Enter the name of the outlet in ${item.language_label}`}
                                    rules={{ required: 'Please enter name' }}
                                    control={control}
                                />
                                <EuiSpacer />
                            </>
                        ))}
                </FormGroupDescription>

                {/* <NumberField
                        label={<b>Contact number</b>}
                        errors={errors}
                        name='contact_number'
                        placeholder='Enter the contact number of the outlet'
                        rules={{
                            required: 'Please Enter your contact number',
                            minLength: {
                                value: 10,
                                message: ' length should be 10',
                            },
                            maxLength: {
                                value: 10,
                                message: ' length should be 10',
                            },
                            validate: (value) => value >= 0 || 'Please enter positive value',
                        }}
                        min={0}
                        minLength={10}
                        maxLength={10}
                        control={control}
                    /> */}
                <FormGroupDescription
                    title={restaurantDescriptions?.contact_number_of_your_outlet?.display_name}
                    description={restaurantDescriptions?.contact_number_of_your_outlet?.description}
                >
                    <MobileNumber
                        watch={watch}
                        setValue={setValue}
                        label={'Contact number'}
                        isInvalid={errors['contact_number']}
                        errors={errors}
                        placeholder='Enter Customer number'
                        name={'contact_number'}
                        control={control}
                        rules={true}
                    />
                </FormGroupDescription>
                <EuiSpacer />

                {/* {
                        isDirty ? (
                            <EuiFormRow>
                                <EuiFlexGroup alignItems="center">
                                    <LoaderButton isLoading={true}/>
                                    <EuiFlexItem>
                                        <EuiButtonEmpty onClick={resetChanges}>Discard changes</EuiButtonEmpty>
                                    </EuiFlexItem>
                                </EuiFlexGroup>
                            </EuiFormRow>
                        ): ''
                    } */}
            </EuiForm>
        </>
    );
};

export default ContactDetails;
