import React, { useCallback, useMemo, useState } from 'react';
import { MainLayout } from '../../components/layout/mainLayout/mainLayout';
import { useDispatch, useSelector } from 'react-redux';
import { EuiFlexGroup, EuiFlexItem, EuiPanel, EuiSideNav } from '@elastic/eui';
import Catalogue from './Tabs/Catalogue';
import { userHasPermission } from '../../helper/auth';
import { useHistory } from 'react-router-dom';
import { OutletMenuWrapper } from '../../components/menuComponentsRefactorCopy/outletMenu';
import OutletLinkage from './Tabs/outletLinkage';
import { NoOutletMessage } from '../../utils/noOutletMessage';
import { isEmpty } from 'lodash';
import PetPoojaItemLink from './Tabs/PetPoojaItemLink';
import PetPoojaAddonLink from './Tabs/PetPoojaAddonLink';

const MenuPage = () => {
    const [showOutlet, setShowOutlet] = useState(false);
    const [selectedTab, setSelectedTab] = useState('catalogue');
    const outletId = useSelector((state) => state.outlet.selectedOutletId);
    const portalActive = useSelector((state) => state.outlet.portalActive);
    const permissions = useSelector((state) => state.permission.permission);
    const dispatch = useDispatch();
    const history = useHistory();

    const onChangeTab = useCallback(
        (id) => {
            if (!isEmpty(portalActive)) {
                dispatch({
                    type: 'set-portal-active-tab-switched',
                    payload: {
                        tabSwitched: true,
                    },
                });
            } else {
                setSelectedTab(id);

                if (id === 'item_link' || id === 'addon_link' || id === 'link_catalogue' || id === 'outlet_menu') {
                    setShowOutlet(true);
                } else {
                    setSelectedTab(id);
                    setShowOutlet(false);
                }
            }
        },
        [dispatch, portalActive]
    );

    const sideNav = useMemo(() => {
        return [
            {
                name: '',
                id: 0,
                items: [
                    {
                        id: 'catalogue',
                        name: 'Catalogue',
                        onClick: () => onChangeTab('catalogue'),
                        isSelected: selectedTab === 'catalogue',
                    },
                    {
                        id: 'link_catalogue',
                        name: 'Link Catalogue',
                        onClick: () => onChangeTab('link_catalogue'),
                        isSelected: selectedTab === 'link_catalogue',
                    },
                    {
                        id: 'outlet_menu',
                        name: 'Outlet Menu',
                        onClick: () => onChangeTab('outlet_menu'),
                        isSelected: selectedTab === 'outlet_menu',
                    },
                    {
                        name: 'Pet Pooja Link',
                        id: 1,
                        forceOpen: true,
                        items: [
                            {
                                id: 'item_link',
                                name: 'Item Link',
                                onClick: () => onChangeTab('item_link'),
                                isSelected: selectedTab === 'item_link',
                            },
                            {
                                id: 'addon_link',
                                name: 'Addon Link',
                                onClick: () => onChangeTab('addon_link'),
                                isSelected: selectedTab === 'addon_link',
                            },
                        ],
                    },
                ],
            },
        ];
    }, [onChangeTab, selectedTab]);

    const selectedComponent = () => {
        switch (selectedTab) {
            case 'catalogue':
                return (
                    <EuiPanel>
                        <Catalogue />
                    </EuiPanel>
                );
            case 'link_catalogue':
                return (
                    <EuiPanel style={{ position: 'relative' }}>
                        <OutletLinkage />
                    </EuiPanel>
                );
            case 'outlet_menu':
                return (
                    <EuiPanel style={{ position: 'relative' }}>
                        <OutletMenuWrapper />
                    </EuiPanel>
                );
            case 'item_link':
                return (
                    <>
                        {outletId ? (
                            <EuiPanel style={{ position: 'relative' }}>
                                <PetPoojaItemLink />
                            </EuiPanel>
                        ) : (
                            <EuiPanel style={{ position: 'relative' }}>
                                <NoOutletMessage />
                            </EuiPanel>
                        )}
                    </>
                );
            case 'addon_link':
                return (
                    <>
                        {outletId ? (
                            <EuiPanel style={{ position: 'relative' }}>
                                <PetPoojaAddonLink />
                            </EuiPanel>
                        ) : (
                            <EuiPanel style={{ position: 'relative' }}>
                                <NoOutletMessage />
                            </EuiPanel>
                        )}
                    </>
                );
            default:
                return null;
        }
    };

    return (
        <MainLayout title='Menu' outlet={showOutlet} isSingleScreen={true} backgroundColorChange>
            {!userHasPermission(permissions, history.location.pathname, 'write') &&
            !userHasPermission(permissions, history.location.pathname, 'delete') ? (
                <OutletMenuWrapper />
            ) : (
                <EuiFlexGroup
                    wrap
                    responsive
                    direction='row'
                    justifyContent='spaceBetween'
                    alignItems='flexStart'
                    gutterSize='l'
                >
                    <EuiFlexItem grow={2} style={{ width: '20%', position: 'sticky', top: '50px' }}>
                        <EuiPanel>
                            <EuiSideNav
                                aria-label='Basic example'
                                mobileTitle='Navigate within $APP_NAME'
                                style={{
                                    // backgroundColor: themeMode === 'light' ? '#fafbfd' : '#1a1b20',
                                    padding: '8px',
                                }}
                                items={sideNav}
                            />
                        </EuiPanel>
                    </EuiFlexItem>

                    {/* <div style={{ backgroundColor: '#D3DAE6', width: '1.1px', margin: '0px 10px' }}></div> */}

                    <EuiFlexItem grow={8} style={{ width: '80%' }} className='menuHeightClass remove-scrollbar'>
                        {selectedComponent()}
                    </EuiFlexItem>
                </EuiFlexGroup>
            )}
        </MainLayout>
    );
};
export default MenuPage;
