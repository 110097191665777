import React from 'react';
import moment from 'moment';
import { EuiText, EuiHorizontalRule, EuiFlexGroup, EuiFlexItem, htmlIdGenerator } from '@elastic/eui';
import API from '../../api/axios/API';
import { isEmpty } from 'lodash';

export default class OrderSplitInvoice extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            order: {},
        };
    }

    async fetchOutletInformation() {
        let outletResponse;
        try {
            outletResponse = await API.get(`restaurants/:restaurantId/outlets/${this.props.outletId}`);
        } catch (error) {
            outletResponse = error;
        }

        this.setState({
            order: outletResponse,
        });
    }

    componentDidMount() {
        this.fetchOutletInformation();
    }

    itemsRecords(itemFields) {
        // for(let key in this.props.billingItem){
        let itemsArray = [];
        itemFields.map((itemField) => {
            let customizationTotal = 0;
            let item = JSON.parse(JSON.stringify(itemField.item));
            let adonObj = [];

            // for(let addon_key in item.addons){
            //     adonObj.push(item.addons[addon_key])
            // }
            // item.adonObj = adonObj

            itemsArray.push(
                <React.Fragment>
                    <EuiFlexItem key={htmlIdGenerator()()}>
                        {/* todo: remove this comment and solve translation issue */}
                        <EuiText key={htmlIdGenerator()()} size='m'>
                            {item.title}
                        </EuiText>

                        {!isEmpty(item.adonObj) &&
                            item.adonObj[0].map((addons) => {
                                customizationTotal = customizationTotal + parseFloat(addons.choicePrice);
                                return (
                                    <>
                                        <EuiText key={htmlIdGenerator()()} style={{ fontSize: '12px' }}>
                                            - {addons.name} [&#8377;{addons.choicePrice.toFixed(2)}]
                                        </EuiText>
                                    </>
                                );
                            })}
                    </EuiFlexItem>

                    <EuiFlexItem
                        key={htmlIdGenerator()()}
                        style={{
                            justifyContent: 'space-between',
                            flexDirection: 'row',
                            display: 'flex',
                            marginTop: '5px',
                        }}
                    >
                        <EuiText key={htmlIdGenerator()()}>
                            {item.quantity} x &#8377;
                            {parseFloat(item.price.toFixed(2)) + parseFloat(customizationTotal.toFixed(2))}
                        </EuiText>
                        <EuiText>
                            &#8377;
                            {(parseFloat(item.price.toFixed(2)) + parseFloat(customizationTotal.toFixed(2))) *
                                parseInt(item.quantity)}
                        </EuiText>
                    </EuiFlexItem>
                    <br />
                </React.Fragment>
            );
        });

        // }

        return itemsArray;
    }

    subTotal(itemFields) {
        let subTotal = 0;

        itemFields.map((itemField) => {
            subTotal += itemField.item.price * itemField.item.quantity;

            if (itemField.item.addons) {
                for (let addon_key in itemField.item.addons) {
                    itemField.item.addons[addon_key].map((choice) => {
                        subTotal += parseFloat(choice.choicePrice) * itemField.item.quantity;
                    });
                }
            }
        });

        return subTotal;
    }

    packagingCharges(itemFields) {
        let packagingCharge = 0;

        itemFields.map((itemField) => {
            packagingCharge += itemField.item.packaging_charge * itemField.item.count;
        });
        // if(!isEmpty(billingItem)){
        //     for(var key in billingItem){
        //         if(key !== "customer_details"){
        //             packagingCharge += billingItem[key].packaging_charge * billingItem[key].count
        //         }
        //     }
        // }

        return packagingCharge;
    }

    gstTotal(itemFields) {
        let gstTotal = 0;
        // let billingItem = this.props.billingItem
        itemFields.map((itemField) => {
            if (itemField.item.gst_slab !== 0) {
                gstTotal += parseFloat(
                    (
                        (parseFloat(itemField.item.price) + parseFloat(itemField.item.packaging_charge)) *
                        (itemField.item.gst_slab / 100)
                    ).toFixed(2) * itemField.item.quantity
                );
            }

            for (let addon_key in itemField.item.addons) {
                itemField.item.addons[addon_key].map((choice) => {
                    gstTotal +=
                        parseInt(choice.gst_slab) !== 0
                            ? parseFloat(choice.choicePrice) *
                              (parseInt(choice.gst_slab) / 100) *
                              itemField.item.quantity
                            : 0;
                });
            }
        });
        // if(!isEmpty(billingItem)){
        //     for(var key in billingItem){
        //         if(key !== "customer_details"){
        //             console.log("gst slab ", billingItem[key].gst_slab)
        //             if(billingItem[key].gst_slab !== 0){
        //                 gstTotal += parseFloat(((parseFloat(billingItem[key].price) + parseFloat(billingItem[key].packaging_charge)) * (billingItem[key].gst_slab / 100)).toFixed(2) * billingItem[key].count)
        //             }

        //             for(let addon_key in billingItem[key].addons){
        //                 billingItem[key].addons[addon_key].map(choice => {
        //                     gstTotal += parseInt(choice.gst_slab) !== 0 ? (parseFloat(choice.choicePrice) * (parseInt(choice.gst_slab) / 100)) * billingItem[key].count : 0
        //                 })
        //             }
        //         }

        //     }
        // }

        return gstTotal;
    }

    totalAmount() {
        let totalAmount = 0;
        let billingItem = this.props.billingItem;
        if (!isEmpty(billingItem)) {
            for (var key in billingItem) {
                if (key !== 'customer_details') {
                    if (billingItem[key].gst_slab !== 1) {
                        totalAmount += parseFloat(
                            (
                                (parseFloat(billingItem[key].price) + parseFloat(billingItem[key].packaging_charge)) *
                                (1 + billingItem[key].gst_slab / 100)
                            ).toFixed(2) * billingItem[key].count
                        );
                    } else {
                        totalAmount += parseFloat(
                            (
                                parseFloat(billingItem[key].price) + parseFloat(billingItem[key].packaging_charge)
                            ).toFixed(2) * billingItem[key].count
                        );
                    }

                    for (let addon_key in billingItem[key].addons) {
                        billingItem[key].addons[addon_key].map((choice) => {
                            totalAmount +=
                                (parseFloat(choice.choicePrice) + parseInt(choice.gst_slab)) * billingItem[key].count;
                        });
                    }
                }
            }
        }

        return totalAmount;
    }

    modeSelecteion() {
        if (this.props.mode === 'dine_in') {
            return 'Dine In';
        } else if (this.props.mode === 'takeaway') {
            return 'Takeaway';
        }

        return 'Delivery';
    }

    fieldList() {
        let restaurantName = this.props.restaurantList.restaurants.filter(
            (restaurant) => restaurant.id === parseInt(this.props.selectedRestuarantId)
        );

        let customizationTotal = 0;
        let spaceBetweenAlign = { justifyContent: 'space-between', flexDirection: 'row', display: 'flex' };
        let centerAlign = { justifyContent: 'center', alignItems: 'center', display: 'flex', textAlign: 'center' };

        let innerArray = [];

        this.props.fields.map((field) => {
            console.log('inside field loop', field);
            innerArray.push(
                <>
                    <EuiFlexItem
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            width: '219px',
                            marginLeft: '15px',
                            fontFamily: 'sans-serif',
                            fontSize: '12px',
                        }}
                    >
                        <EuiFlexGroup style={{ width: '219px' }}>
                            <EuiFlexItem style={centerAlign}>
                                <span style={{ fontSize: '16px' }}>
                                    <b>{restaurantName[0].name}</b>
                                </span>
                            </EuiFlexItem>
                            <EuiFlexItem style={centerAlign}>
                                <span>{this.state.order.outlet.name}</span>
                            </EuiFlexItem>
                            <EuiFlexItem style={centerAlign}>
                                <span>{this.state.order.outlet.outlet_address.address_line_1}</span>
                            </EuiFlexItem>
                            {this.state.order.outlet.settings && this.state.order.outlet.settings.gst_number && (
                                <EuiFlexItem style={centerAlign}>
                                    <span>GSTIN: {this.state.order.outlet.settings.gst_number}</span>
                                </EuiFlexItem>
                            )}
                            <EuiHorizontalRule />

                            <EuiFlexItem style={centerAlign}>{moment().format('DD MMM yyy hh:mm A')}</EuiFlexItem>
                            <EuiHorizontalRule />

                            <EuiFlexItem style={centerAlign}>Cash on Delivery</EuiFlexItem>
                            <EuiFlexItem style={centerAlign}>{this.modeSelecteion()}</EuiFlexItem>
                            <EuiHorizontalRule />

                            <EuiFlexItem>
                                <b>Name: </b> {field.name}
                            </EuiFlexItem>
                            {/* <EuiFlexItem>{this.customerAddress()}</EuiFlexItem> */}
                            <EuiText>{/* <b>Address: </b> {this.props.customer_address}, */}</EuiText>
                            <EuiHorizontalRule />

                            <EuiFlexItem style={centerAlign}>
                                <b>Item(s)</b>
                            </EuiFlexItem>
                            <EuiHorizontalRule />

                            {this.itemsRecords(field.data)}

                            <EuiHorizontalRule />
                            <EuiFlexItem style={spaceBetweenAlign}>
                                <span>Gross total</span>
                                <span>&#8377;{this.subTotal(field.data)}</span>
                            </EuiFlexItem>

                            {this.packagingCharges(field.data) > 0 && (
                                <EuiFlexItem style={spaceBetweenAlign}>
                                    <span>Packaging Charge</span>
                                    <span>&#8377;{this.packagingCharges(field.data)}</span>
                                </EuiFlexItem>
                            )}

                            {this.gstTotal(field.data) > 0 && (
                                <EuiFlexItem style={spaceBetweenAlign}>
                                    <span>GST</span>
                                    <span>&#8377;{this.gstTotal(field.data)} </span>
                                </EuiFlexItem>
                            )}

                            <EuiHorizontalRule />
                            <EuiFlexItem style={spaceBetweenAlign}>
                                <b>Total</b>
                                {/* <b>&#8377;{this.totalAmount()}</b> */}
                            </EuiFlexItem>

                            {/* {
                        parseInt(this.props.order.discount_amount) > 0 && (
                            <EuiFlexItem style={spaceBetweenAlign}>
                                <span>Discount</span>
                                <span>&#8377; {this.props.order.discount_amount && this.props.order.discount_amount.toFixed(2)}</span>
                            </EuiFlexItem>
                        )
                    }
                    
                        
                    {
                        parseInt(this.props.order.cart.convenience_charge) > 0 && (
                            <EuiFlexItem style={spaceBetweenAlign}>
                                <span>Convenience Charge</span>
                                <span>&#8377;{this.props.order.cart.convenience_charge}</span>
                            </EuiFlexItem>
                        )
                    }
                    
                    {
                        parseInt(this.props.order.cart.delivery_charge) > 0 && (
                        <EuiFlexItem style={spaceBetweenAlign}>
                            <span>Delivery Charge</span>
                            <span>&#8377;{this.props.order.cart.delivery_charge}</span>
                        </EuiFlexItem>)
                    }
                    
                    */}
                            <EuiHorizontalRule />

                            <EuiFlexItem style={{ justifyContent: 'center', flexDirection: 'row', display: 'flex' }}>
                                Thank you.
                            </EuiFlexItem>
                            <EuiFlexItem style={centerAlign}>
                                <b>Powered by Fab Systems</b>
                            </EuiFlexItem>
                        </EuiFlexGroup>
                    </EuiFlexItem>
                    <div style={{ pageBreakAfter: 'always', clear: 'both' }}>&nbsp;</div>
                </>
            );
        });

        return <div>{innerArray}</div>;
    }

    render() {
        if (isEmpty(this.state.order)) {
            return null;
        }

        return <>{this.fieldList()}</>;
    }
}
