import { EuiFlexGroup, EuiFlexItem, EuiHorizontalRule, EuiText } from '@elastic/eui';
import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { agmarkSign } from '../../../helper/agmarkSign';
import { fetchItemAvailabilityItems } from '../../menuComponentsRefactorCopy/catalogue/Api/ItemAvailability/FetchItemAvailabilityData';
import AvailabilitySwitch from './Components/AvailabilitySwitch';
import VariantFlyout from './VariantFlyout';

const CategoryItemRow = ({ item, category, setListDetails, handleAvailablityModal }) => {
    const [variantFlyout, setVariantFlyout] = useState(false);
    const outletId = useSelector((state) => state.outlet.selectedOutletId);
    const defaultButtonColor =
        item.in_stock_ordering_mode > 0 &&
        item.total_ordering_modes > 0 &&
        item.in_stock_ordering_mode !== item.total_ordering_modes
            ? 'warning'
            : item.in_stock_ordering_mode > 0 &&
              item.total_ordering_modes > 0 &&
              item.in_stock_ordering_mode === item.total_ordering_modes
            ? 'success'
            : item.in_stock_ordering_mode === 0 && item.total_ordering_modes === 0
            ? 'danger'
            : 'danger';

    const buttonPosition =
        item.in_stock_ordering_mode > 0 &&
        item.total_ordering_modes > 0 &&
        item.in_stock_ordering_mode !== item.total_ordering_modes
            ? 'partial_state'
            : item.in_stock_ordering_mode > 0 &&
              item.total_ordering_modes > 0 &&
              item.in_stock_ordering_mode === item.total_ordering_modes
            ? 'turned_on'
            : item.in_stock_ordering_mode === 0 && item.total_ordering_modes === 0
            ? 'turned_off'
            : 'turned_off';

    const [toggleCompressedIdSelected, setToggleCompressedIdSelected] = useState(buttonPosition);
    const [buttonColor, setButtonColor] = useState(defaultButtonColor);

    const handleAvailabilitySwitchChange = useCallback(
        (optionId, isCategory, selectedElement) => {
            let callbackData = {
                showModal: true,
            };
            if (!optionId === 'partial_state') setToggleCompressedIdSelected(optionId);
            if (optionId === 'turned_off') {
                setButtonColor('danger');
            } else if (optionId === 'turned_on') {
                setButtonColor('success');
            }

            if (isCategory) {
                callbackData.isCategory = true;
            } else {
                callbackData.isCategory = false;
            }
            if (optionId === 'turned_off' || optionId === 'turned_on') {
                callbackData.modalResponse = {
                    data: selectedElement,
                    isActive: optionId === 'turned_off' ? 0 : 1,
                    item_id: item.id,
                };
            }

            handleAvailablityModal({
                showModal: callbackData.showModal,
                modalResponse: callbackData.modalResponse,
                isCategory: isCategory ? isCategory : false,
                clearSwitchStateOnClose: () => {
                    setToggleCompressedIdSelected(buttonPosition);
                    setButtonColor(buttonColor);
                },
                fetchUpdatedItems: () => {
                    fetchItemAvailabilityItems(outletId, category.id).then((response) => {
                        setListDetails((prevState) => ({ ...prevState, isLoading: true }));
                        if (response.success) {
                            setListDetails((prevState) => ({
                                ...prevState,
                                items: [...response.items],
                                isLoading: false,
                            }));
                        }
                    });
                },
            });
        },
        [buttonColor, buttonPosition, category.id, handleAvailablityModal, item.id, outletId, setListDetails]
    );

    const handleVariantFlyout = useCallback(() => {
        setVariantFlyout((prevState) => !prevState);
    }, []);

    return (
        <>
            {variantFlyout ? (
                <VariantFlyout
                    handleAvailablityModal={handleAvailablityModal}
                    closeFlyout={handleVariantFlyout}
                    item={item}
                />
            ) : null}
            <React.Fragment>
                <EuiHorizontalRule margin='xs' />
                <EuiFlexGroup gutterSize='xs' justifyContent='spaceBetween' alignItems='center'>
                    <EuiFlexItem>
                        <EuiFlexGroup gutterSize='xs' justifyContent='flexStart' alignItems='center'>
                            <EuiFlexItem grow={false} style={{ marginRight: '10px' }}>
                                {item.food_type ? agmarkSign(item.food_type) : ''}
                            </EuiFlexItem>
                            <EuiFlexItem grow={false}>
                                <EuiText>{item.internal_name}</EuiText>
                            </EuiFlexItem>
                            {item.variants_count > 0 && (
                                <EuiFlexItem
                                    onClick={() => {
                                        handleVariantFlyout();
                                    }}
                                    grow={false}
                                >
                                    <div
                                        style={{
                                            cursor: 'pointer',
                                            height: '12px',
                                            width: '12px',
                                            borderRadius: '100%',
                                            background: '#275AF6',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            color: '#FFF',
                                            marginTop: '6px',
                                        }}
                                    >
                                        <EuiText size='xs'>v</EuiText>
                                    </div>
                                </EuiFlexItem>
                            )}
                        </EuiFlexGroup>
                    </EuiFlexItem>
                    <EuiFlexItem grow={false}>
                        <AvailabilitySwitch
                            buttonColor={buttonColor}
                            handleAvailabilitySwitchChange={handleAvailabilitySwitchChange}
                            toggleCompressedIdSelected={toggleCompressedIdSelected}
                            buttonSize={'compressed'}
                            isCategory={false}
                            selectedElement={item}
                            name={item.id.toString()}
                        />
                    </EuiFlexItem>
                </EuiFlexGroup>
            </React.Fragment>
        </>
    );
};

export default React.memo(CategoryItemRow);
