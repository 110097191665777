import { EuiFlexItem, EuiPanel, EuiSpacer, EuiText } from '@elastic/eui';
import React from 'react';
import { useSelector } from 'react-redux';
import VariantLinkOrderingModes from './VariantLinkOrderingModes';

const ItemVariantsList = ({ option, selectedParentOption, variantOptions, optionIndex, category_id, fieldIndex }) => {
    const languageId = useSelector((state) => state.language.languageId);
    const outletOrderingModesEnabled = useSelector((state) => state.outlet.orderingModes)?.filter(
        (orderingMode) => orderingMode?.is_enabled
    );

    const uniqueVariantOptions = variantOptions?.reduce(
        (acc, curr) => (acc.find((v) => v.variationid === curr.variationid) ? acc : [...acc, curr]),
        []
    );

    return (
        <div style={{ marginLeft: '20px' }} key={option.id}>
            <EuiPanel style={{ width: '69%' }} hasShadow={true}>
                <EuiFlexItem
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        width: '100%',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    <EuiFlexItem>
                        <EuiText>{option.translations.title[languageId]}</EuiText>
                    </EuiFlexItem>
                    <EuiFlexItem>
                        {option?.ordering_modes?.map((orderingMode, modeIndex) => {
                            return outletOrderingModesEnabled?.map((enabledMode) => {
                                return (
                                    <React.Fragment key={enabledMode.restaurant_ordering_mode_id.toString()}>
                                        {enabledMode.restaurant_ordering_mode_id ===
                                        orderingMode.restaurant_ordering_mode_id ? (
                                            <VariantLinkOrderingModes
                                                key={orderingMode.restaurant_ordering_mode_id.toString()}
                                                optionOrderingMode={orderingMode}
                                                category_id={category_id}
                                                fieldIndex={fieldIndex}
                                                modeIndex={modeIndex}
                                                uniqueVariantOptions={uniqueVariantOptions}
                                                selectedParentOption={selectedParentOption}
                                                option={option}
                                                optionIndex={optionIndex}
                                                petPoojaItemList={uniqueVariantOptions}
                                            />
                                        ) : null}
                                    </React.Fragment>
                                );
                            });
                        })}
                    </EuiFlexItem>
                </EuiFlexItem>
            </EuiPanel>
            <EuiSpacer siz='s' />
        </div>
    );
};

export default React.memo(ItemVariantsList);
