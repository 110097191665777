import {
    EuiBasicTable,
    EuiFlexItem,
    EuiNotificationBadge,
    EuiFlexGroup,
    EuiFieldSearch,
    EuiSpacer,
    EuiLoadingContent,
    EuiTableBody,
    EuiTable,
    EuiTableHeader,
    EuiTableHeaderCell,
} from '@elastic/eui';
import React, { useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import BillingItem from './billingItem';
import AddonModal from './addonModal';
import { useDispatch } from 'react-redux';
import { getBillingItems } from '../../api/getBillingItems';
import * as types from '../../reduxStore/types/billing';
import { capitalize } from '../../helper/capitalize';
import API from '../../api/axios/API';
import { isEmpty } from 'lodash';
import _ from 'lodash';
import { itemSearch } from '../../api/billing/itemSearch';
import { agmarkSign } from '../../helper/agmarkSign';
import ItemListTableRow from './itemListTableComponent';

const ItemList = (props) => {
    const { outletId } = props;

    const dispatch = useDispatch();
    const activeCategoryId = useSelector((state) => state.billing.activeCategoryId);
    const itemList = useSelector((state) => state.billing.itemList);
    const mode = useSelector((state) => state.billing.mode);
    const language = useSelector((state) => state.language.language);
    const selectedOutletId = useSelector((state) => state.outlet.selectedOutletId);

    const cartId = useSelector((state) => state.billing.cartId);
    const tableSelectedList = useSelector((state) => state.billing.tableSelectedList);
    const customerInformation = useSelector((state) => state.billing.customerInformation);
    const sequence = useSelector((state) => state.billing.sequence);
    const billingItem = useSelector((state) => state.billing.billingItem);
    const languageCode = useSelector((state) => state.language.language);

    const [items, setItems] = useState([]);

    const addItemWithoutAddon = (item, count, billingItem) => {
        let billingItemObj = JSON.parse(JSON.stringify(billingItem));
        let innerObj = {};

        let id = `${item.id}i`;
        if (billingItemObj[id]) {
            innerObj = billingItemObj[id];
            innerObj.quantity += count;
        } else {
            innerObj = { ...item, title: item.title, description: item.description };
            innerObj.quantity = count;
        }

        billingItemObj[id] = { ...innerObj, sequence: sequence + 1 };

        addItemToCart(id, billingItemObj);

        dispatch({
            type: types.SET_BILLING_ITEM,
            payload: {
                billingItem: billingItemObj,
            },
        });
    };

    const addItemToCart = async (key, itemObj) => {
        let response;
        let url = '';
        let finalObj = {};

        let data = orderBillingStructureNew(itemObj);

        if (mode === 'delivery') {
            if (!customerInformation.id) {
                finalObj['customer_address'] = {
                    latitude: customerInformation.latitude,
                    longitude: customerInformation.longitude,
                    location_name: customerInformation.location_name,
                    block_number: customerInformation.block_number,
                    city: customerInformation.city,
                    state: customerInformation.state,
                    region: customerInformation.region,
                    land_mark: customerInformation.land_mark,
                    tag: 'home',
                };
            } else {
                finalObj['customer_address'] = { id: customerInformation.id };
            }
        }

        finalObj = {
            ...finalObj,
            items: { ...data },
            ordering_mode: mode == 'qsr' ? 'takeaway' : mode,
            table_ids: tableSelectedList,
        };

        try {
            if (!cartId) {
                url = `/${languageCode}/pos/restaurants/:restaurantId/outlets/${outletId}/carts`;
                response = await API.post(url, finalObj);
            } else {
                url = `/${languageCode}/pos/restaurants/:restaurantId/outlets/${outletId}/carts/${cartId}`;
                response = await API.put(url, finalObj);
            }
        } catch (error) {
            response = error;
        }

        if (response.success) {
            isEmpty(billingItem) &&
                !cartId &&
                dispatch({
                    type: types.SET_CART_ID,
                    payload: {
                        cartId: response.cart.cart_id,
                    },
                });
            dispatch({
                type: types.SET_BILLING_ITEM_CART_STRUCTURE,
                payload: {
                    billingItemCartStructure: response,
                },
            });

            dispatch({
                type: types.SET_SEQUENCE_COUNT_ADD,
            });
            cartItemIdAdd(response, itemObj);
        } else {
            let billingItemObj = JSON.parse(JSON.stringify(billingItem));
            delete billingItemObj[key];
            dispatch({
                type: types.SET_BILLING_ITEM,
                payload: {
                    billingItem: billingItemObj,
                },
            });
            errorCheck(response);
        }
    };

    const orderBillingStructureNew = (itemInnerObj) => {
        let itemObj = {};
        Object.entries(itemInnerObj).forEach(([key, value], index) => {
            if (key !== 'cart_id' && key !== 'customer_details') {
                let innerItemObj = {};
                if (!itemObj[value.id]) {
                    innerItemObj['quantity'] = value.quantity;

                    if (value.customizations && !isEmpty(value.customizations)) {
                        innerItemObj['customizations'] = value.customizations;
                    }
                } else {
                    innerItemObj['quantity'] = parseInt(itemObj[value.id]['quantity']) + parseInt(value.quantity);

                    if (value.customizations && !isEmpty(value.customizations)) {
                        innerItemObj['customizations'] = value.customizations;
                    }
                }

                itemObj[value.id] = innerItemObj;
            }
        });

        return itemObj;
    };

    const cartItemIdAdd = (response, itemObj) => {
        let innerObj = { ...itemObj };

        if (response.cart.cart_items.length > 0) {
            response.cart.cart_items.map((item) => {
                innerObj[`${item.item_id}i`].cart_item_id = item.cart_item_id;
            });
        }

        dispatch({
            type: types.SET_BILLING_ITEM,
            payload: {
                billingItem: innerObj,
            },
        });
    };

    const errorCheck = (orderResponse) => {
        let table_error = false;
        let customer_error = false;
        if (orderResponse.errors) {
            Object.entries(orderResponse.errors).forEach(([key, value], index) => {
                if (key.startsWith('table_ids')) {
                    table_error = true;
                }

                if (key.startsWith('customer_address')) {
                    customer_error = true;
                }
            });

            if (table_error) {
                alert('Table already assigned');
                dispatch({
                    type: types.SET_TABLE_SELECTED_LIST,
                    payload: {
                        tableSelectedList: [],
                    },
                });

                dispatch({
                    type: types.SET_CUSTOMER_INFORMATION_FINISH,
                    payload: {
                        finishCustomerInfo: false,
                    },
                });
            } else if (customer_error) {
                alert('Provide proper customer information');
                dispatch({
                    type: types.SET_CUSTOMER_INFORMATION_FINISH,
                    payload: {
                        finishCustomerInfo: false,
                    },
                });
            } else {
                alert('error in order place');
            }
        } else {
            if (orderResponse.message) {
                alert(orderResponse.message);
            } else {
                alert('error in order place');
            }
        }
    };

    const [noItemList, setNoItemList] = useState(false);

    useEffect(() => {
        if (itemList.outlet_categories && itemList.outlet_categories.items.length) {
            setItems(itemList.outlet_categories.items);
            setNoItemList(true);
        }
    }, [itemList]);

    const itemCount = (id) => {
        let count = 0;

        if (billingItem[id]) {
            count += billingItem[id].quantity;

            if (billingItem[id].customizations && billingItem[id].customizations.length > 0) {
                billingItem[id].customizations.map((addon) => {
                    count += addon.addon_information.quantity;
                });
            }
        }

        return count;
    };

    const columns = [
        {
            field: 'title',
            name: <b>Item</b>,
            width: '90%',
            render: (title, item) => (
                <EuiFlexItem style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    {itemCount(`${item.id}i`) ? (
                        <EuiNotificationBadge>{itemCount(`${item.id}i`)}</EuiNotificationBadge>
                    ) : (
                        ''
                    )}

                    <div style={{ paddingLeft: '10px' }}>{agmarkSign(item.food_type)}</div>
                    <EuiFlexItem style={{ paddingLeft: '10px' }}>
                        <h4>
                            <b>{capitalize(title)}</b>
                        </h4>
                        <span className='itemDescriptionForTable'>Description goes here..</span>
                    </EuiFlexItem>
                </EuiFlexItem>
            ),
        },
        {
            field: 'price',
            name: <b>Price</b>,
            width: '10%',
            render: (price) => <span>₹{price}</span>,
        },
    ];

    const getRowProps = (item) => {
        const { id } = item;

        return {
            'data-test-subj': `row-${id}`,
            className: 'customRowClass',
            onClick: () => {
                if ((item.addon_groups && item.addon_groups.length > 0) || (item.variants && item.variants.length)) {
                    dispatch({
                        type: types.SET_ADDON_MODAL_VISIBLE,
                        payload: {
                            addonModalVisible: true,
                        },
                    });
                } else {
                    addItemWithoutAddon(item);
                }
                dispatch({
                    type: types.SET_SELECTED_ITEM,
                    payload: {
                        selectedItem: item,
                    },
                });
            },
        };
    };

    const getCellProps = (item, column) => {
        const { id } = item;
        const { field } = column;
        return {
            className: '',
            'data-test-subj': `cell-${id}-${field}`,
            textOnly: true,
        };
    };

    useEffect(() => {
        if (selectedOutletId && activeCategoryId) {
            dispatch(getBillingItems(selectedOutletId));
            setNoItemList(false);
        }
    }, [activeCategoryId, mode, language]);

    const renderItemList = () => {
        if (itemList.outlet_categories && itemList.outlet_categories.items.length > 0) {
            return (
                <div className='POSItemList' style={{ padding: '0 10px' }}>
                    <EuiFlexGroup direction='column'>
                        <EuiSpacer size='s' />
                        <div className='tableList'>
                            <EuiTable id='item_table'>
                                <EuiTableHeader>
                                    <EuiTableHeaderCell key='item_title' width='90%'>
                                        Item
                                    </EuiTableHeaderCell>
                                    <EuiTableHeaderCell key='item_price' width='10%'>
                                        Price
                                    </EuiTableHeaderCell>
                                </EuiTableHeader>
                                <EuiTableBody>
                                    {items.map((item) => (
                                        <ItemListTableRow item={item} addItemWithoutAddon={addItemWithoutAddon} />
                                    ))}
                                </EuiTableBody>
                            </EuiTable>
                            {/* <EuiBasicTable
                                items={items}
                                rowHeader='tableName'
                                columns={columns}
                                rowProps={getRowProps}
                                cellProps={getCellProps}
                            /> */}
                        </div>
                    </EuiFlexGroup>
                </div>
            );
        } else {
            if (noItemList) {
                return <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}> No Item found</div>;
            } else {
                return <EuiLoadingContent lines={10} />;
            }
        }
    };

    const filterMatchingItems = (value) => {
        let innerArray = [];
        dispatch(itemSearch(value)).then((res) => {
            if (res.success) {
                res.outlet_categories.map((category) => {
                    if (category.items && category.items.length) {
                        category.items.map((item) => {
                            innerArray.push(item);
                        });
                    }

                    if (category.sub_categories && category.sub_categories.length) {
                        category.sub_categories.map((subCategory) => {
                            if (subCategory.items && subCategory.items.length) {
                                subCategory.items.map((item) => {
                                    innerArray.push(item);
                                });
                            }
                        });
                    }
                });
                setItems(innerArray);
            }
        });
    };

    const delayedQuery = useCallback(
        _.debounce((q) => filterMatchingItems(q), 500),
        []
    );

    const [activeCategoryIdState, setActiveCategoryIdState] = useState('');

    const searchItems = (e) => {
        if (e.target.value) {
            delayedQuery(e.target.value);

            if (!activeCategoryIdState) {
                setActiveCategoryIdState(activeCategoryId);
                dispatch({
                    type: types.SET_ACTIVE_CATEGORY_ID,
                    payload: {
                        activeCategoryId: '',
                    },
                });
            }
        } else {
            dispatch({
                type: types.SET_ACTIVE_CATEGORY_ID,
                payload: {
                    activeCategoryId: activeCategoryIdState,
                },
            });
            setActiveCategoryIdState('');
        }
    };

    return (
        <>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', padding: '7px' }}>
                <EuiFieldSearch
                    fullWidth
                    placeholder='Search item'
                    onChange={searchItems}
                    isClearable={true}
                    aria-label='search of item'
                    defaultChecked=''
                />
            </div>

            {renderItemList()}
        </>
    );
};

export default ItemList;
