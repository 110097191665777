import React, { useEffect, useState } from 'react';
import {
    EuiFlexItem,
    EuiButtonEmpty,
    EuiButtonIcon,
    EuiText,
    EuiBadge,
    EuiButton,
    EuiCheckbox,
    htmlIdGenerator,
    EuiFieldText,
    EuiFormRow,
    EuiToolTip,
    EuiTableHeaderCell,
    EuiTableRow,
    EuiTableRowCell,
    EuiTableHeader,
    EuiTableBody,
    EuiTable,
    EuiPopover,
    EuiNotificationBadge,
    EuiConfirmModal,
    EuiOverlayMask,
} from '@elastic/eui';
import moment from 'moment';
import { isEmpty } from 'lodash';
import RunningOrderSettingsModal from './runningOrderSettingsModal';
import ItemCommentModal from './itemCommentModal';
import { useSelector, useDispatch } from 'react-redux';
import * as types from '../../reduxStore/types/billing';
import API from '../../api/axios/API';
import TextField from '../Form/TextField';
import { useForm, Controller } from 'react-hook-form';
import './styleTextBox.css';
import { agmarkSign } from '../../helper/agmarkSign';
import { ReactComponent as Gear } from '../../assets/img/gear.svg';
import { ReactComponent as POS } from '../../assets/img/pos.svg';
import { ReactComponent as Phone } from '../../assets/img/phone.svg';
import { orderStatusChange } from '../../api/orders/orderStatusChange';
import { getTableDetails } from '../../api/billing/getTableDetails';
// import POSPreparationTimeModal from './posPreparationTimeModal';
import { PreparationTimeModal } from '../orderDetailsCard/preparationTimeModal';

const OrderComponent = (props) => {
    const { superIndex, order } = props;

    const {
        register,
        handleSubmit,
        control,
        watch,
        reset,
        setValue,

        formState: { errors },
    } = useForm({
        defaultValues: {},
    });

    const previousOrders = useSelector((state) => state.billing.previousOrders);
    const languageId = useSelector((state) => state.language.languageId);
    const languageCode = useSelector((state) => state.language.language);
    const discount = useSelector((state) => state.billing.discount);
    const tableSelectedList = useSelector((state) => state.billing.tableSelectedList);
    const itemFreeList = useSelector((state) => state.billing.itemFreeList);
    const billingItem = useSelector((state) => state.billing.billingItem);
    const mode = useSelector((state) => state.billing.mode);
    const customerInformation = useSelector((state) => state.billing.customerInformation);
    // tableData
    const tableData = useSelector((state) => state.billing.tableData);
    const dispatch = useDispatch();

    const [editOrder, setEditOrder] = useState(false);
    const [currentIndex, setCurrentIndex] = useState();
    const [currentSuperIndex, setCurrentSuperIndex] = useState();
    const [currentItemComment, setCurrentItemComment] = useState({});
    const [commentModal, setCommentModal] = useState(false);
    const [settingsModal, setSettingsModal] = useState(false);
    const [orderUserDetails, setOrderUserDetails] = useState(false);
    const [isPopoverOpen, setIsPopOverOpen] = useState(false);
    const [commentsId, setCommentsId] = useState([]);
    const [editPreviousOrder, setEditPreviousOrders] = useState([]);
    const [removeItem, setRemoveItem] = useState({});
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [preparationTimeModal, setPreparationTimeModal] = useState(false);

    const onPlusItem = (index, superIndex, withAddon) => {
        let innerArray = [...previousOrders];

        if (withAddon) {
            if (innerArray[superIndex].cart.cart_items[index].addonQuantity) {
                innerArray[superIndex].cart.cart_items[index].addonQuantity += 1;
            } else {
                innerArray[superIndex].cart.cart_items[index].addonQuantity =
                    innerArray[superIndex].cart.cart_items[index].quantity + 1;
            }
        } else {
            innerArray[superIndex].cart.cart_items[index].quantity += 1;
        }

        dispatch({
            type: types.SET_PREVIOUS_ORDERS,
            payload: {
                previousOrders: innerArray,
            },
        });
    };

    const onMinusItem = (index, superIndex, withAddon) => {
        let innerArray = [...previousOrders];

        if (withAddon) {
            if (innerArray[superIndex].cart.cart_items[index].addonQuantity) {
                innerArray[superIndex].cart.cart_items[index].addonQuantity -= 1;
            } else {
                innerArray[superIndex].cart.cart_items[index].addonQuantity =
                    innerArray[superIndex].cart.cart_items[index].quantity - 1;
            }
        } else {
            innerArray[superIndex].cart.cart_items[index].quantity -= 1;
        }

        dispatch({
            type: types.SET_PREVIOUS_ORDERS,
            payload: {
                previousOrders: innerArray,
            },
        });
    };

    const onRemoveItem = (index, superIndex) => {
        let innerArray = [...previousOrders];

        innerArray[superIndex].cart.cart_items.splice(index, 1);

        dispatch({
            type: types.SET_PREVIOUS_ORDERS,
            payload: {
                previousOrders: innerArray,
            },
        });
    };

    const convertAddonStructure = (value, from) => {
        let addonStructure = {};

        value.customizations.map((addon) => {
            let innerArray = addonStructure[addon.addon_group_id] ? addonStructure[addon.addon_group_id] : [];

            innerArray.push(addon);

            addonStructure[addon.addon_group_id] = innerArray;

            addonStructure['addon_information'] = {
                quantity: value.addonQuantity ? value.addonQuantity : value.quantity,
                special_instruction: value.special_instruction ? value.special_instruction : '',
            };
        });

        return addonStructure;
    };

    const addonList = (item) => {
        let addonList = [];
        if (!isEmpty(item.customizations)) {
            let addon = convertAddonStructure(item, 'addonList');

            Object.entries(addon).forEach(([key, value]) => {
                if (key !== 'addon_information' && key !== 'variant_information') {
                    value.map((itemAddon, index) => {
                        // index === 0 && addonList.push(<p style={{ fontSize: '12px' }}>&nbsp; {itemAddon.group_name}</p>);
                        addonList.push(
                            <p style={{ fontSize: '10px' }}>
                                - {itemAddon.name} [₹ {itemAddon.price}]
                            </p>
                        );
                    });
                }
            });
        }

        return addonList;
    };

    const itemTotal = (item) => {
        let total = item.price;

        let addon = convertAddonStructure(item, 'itemTotal');

        Object.entries(addon).forEach(([key, value]) => {
            if (key !== 'addon_information' && key !== 'variant_information') {
                value.map((itemAddon) => {
                    total += itemAddon.price ? parseFloat(itemAddon.price) : parseFloat(itemAddon.price);
                });
            }
        });

        return total;
    };

    const onChange = async (e, item) => {
        let innerArray = [...itemFreeList];

        if (e.target.checked) {
            innerArray.push(item);
        } else {
            innerArray.splice(
                innerArray.findIndex((i) => i.cart_item_id === item.cart_item_id),
                1
            );
        }

        dispatch({
            type: types.SET_ITEM_FREE_LIST,
            payload: {
                itemFreeList: innerArray,
            },
        });
    };

    const filterItemFree = (itemFree, item) => {
        return itemFree.cart_item_id === item.cart_item_id;
    };

    const minusButtonDisable = (item) => {
        // item.quantity === 1 || item.quantity === 0) || !editOrder
        if (item.addonQuantity) {
            if (item.addonQuantity === 1 || !editOrder) {
                return true;
            }
        } else {
            if (item.quantity === 1 || !editOrder) {
                return true;
            }
        }

        return false;
    };

    const itemPushMethodForAddonsPreviousOrders = (items, item, index, superIndex) => {
        items.push(
            <EuiTableRow>
                <EuiTableRowCell>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        {discount === 'get_an_item_free' && (
                            <div style={{ paddingRight: '5px' }}>
                                <EuiCheckbox
                                    id={htmlIdGenerator()()}
                                    checked={
                                        itemFreeList.filter((itemFree) => filterItemFree(itemFree, item)).length > 0
                                    }
                                    onChange={(e) => onChange(e, item)}
                                />
                            </div>
                        )}

                        <div style={{ padding: '0px 8px 5px 0px' }}>{agmarkSign(item.food_type)}</div>

                        <div>
                            <div>
                                <span>{item.title}</span>
                            </div>

                            {addonList(item)}

                            {commentsId.filter((id) => id === `${index}-${superIndex}`).length ? (
                                <>
                                    <EuiFormRow
                                        style={{ paddingTop: '5px' }}
                                        onBlur={(e) => {
                                            removeTextBoxController(index, superIndex);
                                        }}
                                    >
                                        <Controller
                                            compressed
                                            autoFocus
                                            render={({ field }) => (
                                                <EuiFieldText value={field.value} onChange={field.onChange} />
                                            )}
                                            name={`instruction-${index}-${superIndex}`}
                                            placeholder='cooking instructions..'
                                            control={control}
                                            defaultValue={item.special_instruction ? item.special_instruction : ''}
                                            onKeyPress={(e) => {
                                                if (e.key === 'Enter') {
                                                    commentText(item, index, superIndex, true);
                                                }
                                            }}
                                            append={
                                                <EuiToolTip content='save instructions'>
                                                    <EuiButtonIcon
                                                        id={`instruction-${index}-${superIndex}`}
                                                        iconType='arrowRight'
                                                        aria-label='Gear this'
                                                        onClick={() => commentText(item, index, superIndex, true)}
                                                    />
                                                </EuiToolTip>
                                            }
                                        />
                                    </EuiFormRow>
                                </>
                            ) : (
                                <>{cookingInstructionCondition(item, index, superIndex, true)}</>
                            )}
                        </div>
                    </div>
                </EuiTableRowCell>
                <EuiTableRowCell align='center'>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            border: '1px solid #d3dae6',
                            borderRadius: '3px',
                        }}
                    >
                        {editOrder && (
                            <div
                                onClick={() => {
                                    !minusButtonDisable(item) && onMinusItem(index, superIndex, true);
                                }}
                                style={{
                                    width: '50px',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    cursor: 'pointer',
                                    borderRight: '1px solid #d3dae6',
                                }}
                            >
                                <span>-</span>
                            </div>
                        )}

                        <div style={{ padding: '0 5px 2px' }}>
                            <EuiNotificationBadge>
                                {item.addonQuantity ? item.addonQuantity : item.quantity}
                            </EuiNotificationBadge>
                        </div>

                        {editOrder && (
                            <div
                                onClick={() => {
                                    editOrder && onPlusItem(index, superIndex, true);
                                }}
                                style={{
                                    width: '50px',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    cursor: 'pointer',
                                    borderLeft: '1px solid #d3dae6',
                                }}
                            >
                                <span>+</span>
                            </div>
                        )}
                    </div>
                </EuiTableRowCell>

                <EuiTableRowCell align='right'>
                    <span>₹ {itemTotal(item) * item.quantity}</span>
                </EuiTableRowCell>

                <EuiTableRowCell align='left'>
                    <EuiButtonIcon
                        // onClick={() => onRemoveItem(index, superIndex)}
                        onClick={() => setRemoveItem({ index, superIndex })}
                        iconType={'cross'}
                        disabled={!editOrder ? true : false}
                    />
                </EuiTableRowCell>
            </EuiTableRow>
        );
    };

    const commentMethod = (item, index, superIndex) => {
        let id = `${index}-${superIndex}`;
        if (!commentsId.includes(id)) {
            setCommentsId([...commentsId, id]);
        }

        if (item.special_instruction) {
            setValue(id, item.special_instruction);
        }

        if (item.customizations && item.customizations.length && item.customizations[0].special_instruction) {
            setValue(id, item.customizations[0].special_instruction);
        }
    };

    const filterCommentsId = (id, currentId) => {
        return id === currentId;
    };

    const removeTextBoxController = (index, superIndex) => {
        // let commentsArray = [...commentsId]
        // let indexNumber = commentsArray.findIndex((id) => filterCommentsId(id, `${index}-${superIndex}`))
        // if(indexNumber >= 0){
        //     commentsArray.splice(indexNumber, 1)
        //     setCommentsId(commentsArray)
        // }

        setCommentsId([]);
    };

    const commentText = (item, index, superIndex, addons) => {
        let innerArray = [...previousOrders];

        if (addons) {
            innerArray[superIndex].cart.cart_items[index].special_instruction = watch(
                `instruction-${index}-${superIndex}`
            );
        } else {
            innerArray[superIndex].cart.cart_items[index].special_instruction = watch(
                `instruction-${index}-${superIndex}`
            );
        }

        dispatch({
            type: types.SET_PREVIOUS_ORDERS,
            payload: {
                previousOrders: innerArray,
            },
        });

        let commentsArray = [...commentsId];
        let indexNumber = commentsArray.findIndex((id) => filterCommentsId(id, `${index}-${superIndex}`));
        if (indexNumber >= 0) {
            commentsArray.splice(indexNumber, 1);
            setCommentsId(commentsArray);
        }
    };

    const commentToText = (index, superIndex, item) => {
        let id = `${index}-${superIndex}`;

        setValue(`instruction-${id}`, item.special_instruction);
    };

    const cookingInstructionCondition = (item, index, superIndex, isCustomization) => {
        if (item.special_instruction) {
            return (
                <div onClick={() => editOrder && commentMethod(item, index, superIndex)}>
                    <p style={{ fontSize: '10px', padding: '5px' }} className='dotted'>
                        - {item.special_instruction ? item.special_instruction : ''}
                    </p>
                </div>
            );
        } else if (editOrder) {
            return (
                <div onClick={() => commentMethod(item, index, superIndex)}>
                    <span style={{ fontSize: '10px' }} className='dotted'>
                        Cooking instructions
                    </span>
                </div>
            );
        }
    };

    const itemPushMethodPreviousOrders = (items, item, index, superIndex) => {
        items.push(
            <EuiTableRow key={1}>
                <EuiTableRowCell>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        {discount === 'get_an_item_free' && (
                            <EuiFlexItem style={{ paddingRight: '5px' }}>
                                <EuiCheckbox
                                    id={htmlIdGenerator()()}
                                    checked={
                                        itemFreeList.filter((itemFree) => filterItemFree(itemFree, item)).length > 0
                                    }
                                    // onChange={(e) => onChange(e, key)}
                                    onChange={(e) => onChange(e, item)}
                                />
                            </EuiFlexItem>
                        )}

                        <div style={{ padding: '0px 8px 5px 0px' }}>{agmarkSign(item.food_type)}</div>

                        <div>
                            <div>
                                <span>{item.title}</span>
                            </div>

                            {commentsId.filter((id) => id === `${index}-${superIndex}`).length ? (
                                <EuiFormRow
                                    style={{ paddingTop: '5px' }}
                                    onBlur={(e) => {
                                        removeTextBoxController(index, superIndex);
                                    }}
                                >
                                    <Controller
                                        compressed
                                        autoFocus
                                        render={({ field }) => (
                                            <EuiFieldText value={field.value} onChange={field.onChange} />
                                        )}
                                        name={`instruction-${index}-${superIndex}`}
                                        placeholder='cooking instructions..'
                                        control={control}
                                        defaultValue={item.special_instruction}
                                        onKeyPress={(e) => {
                                            if (e.key === 'Enter') {
                                                commentText(item, index, superIndex, false);
                                            }
                                        }}
                                        append={
                                            <EuiToolTip content='save instructions'>
                                                <EuiButtonIcon
                                                    id={`instruction-${index}-${superIndex}`}
                                                    iconType='arrowRight'
                                                    aria-label='Gear this'
                                                    onClick={() => commentText(item, index, superIndex, false)}
                                                />
                                            </EuiToolTip>
                                        }
                                    />
                                </EuiFormRow>
                            ) : (
                                <>{cookingInstructionCondition(item, index, superIndex, false)}</>
                            )}
                        </div>
                    </div>
                </EuiTableRowCell>

                <EuiTableRowCell align='center'>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            alignItems: 'center',
                            border: '1px solid #d3dae6',
                            borderRadius: '3px',
                        }}
                    >
                        {editOrder && (
                            <div
                                onClick={() => {
                                    item.quantity > 1 && editOrder && onMinusItem(index, superIndex, false);
                                }}
                                style={{
                                    width: '50px',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    cursor: 'pointer',
                                    borderRight: '1px solid #d3dae6',
                                }}
                            >
                                <span>-</span>
                            </div>
                        )}

                        <div style={{ padding: '0 5px 2px' }}>
                            <EuiNotificationBadge>
                                {item.quantity ? item.quantity : item.promotional_quantity}
                            </EuiNotificationBadge>
                        </div>

                        {editOrder && (
                            <div
                                onClick={() => {
                                    editOrder && onPlusItem(index, superIndex, false);
                                }}
                                style={{
                                    width: '50px',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    cursor: 'pointer',
                                    borderLeft: '1px solid #d3dae6',
                                }}
                            >
                                <span>+</span>
                            </div>
                        )}
                    </div>
                </EuiTableRowCell>

                <EuiTableRowCell align='right'>
                    <span>
                        {' '}
                        ₹ {item.quantity ? item.price * item.quantity : item.price * item.promotional_quantity}
                    </span>
                </EuiTableRowCell>

                <EuiTableRowCell align='right'>
                    <EuiButtonIcon
                        // onClick={() => onRemoveItem(index, superIndex)}
                        onClick={() => setRemoveItem({ index, superIndex })}
                        iconType={'cross'}
                        disabled={!editOrder ? true : false}
                    />
                </EuiTableRowCell>
            </EuiTableRow>
        );
    };

    const renderHeaderCell = () => {
        let headers = [];

        headers.push(
            <EuiTableHeaderCell key={1} width='62%'>
                Item
            </EuiTableHeaderCell>
        );

        headers.push(
            <EuiTableHeaderCell key={2} width='20%' align='center'>
                Qty.
            </EuiTableHeaderCell>
        );

        headers.push(
            <EuiTableHeaderCell key={3} width='20%' align='right'>
                Amount
            </EuiTableHeaderCell>
        );
        headers.push(<EuiTableHeaderCell key={3} width='10%' align='center'></EuiTableHeaderCell>);

        return headers;
    };

    const orderStatusChangeAPICall = (status, time = 0) => {
        dispatch(orderStatusChange(order.order_id, { status, kitchen_preparation_time: time })).then((res) => {
            if (res.success) {
                setIsPopOverOpen(false);
                dispatch(getTableDetails(tableData.outlet_table_id)).then((res) => {
                    if (res.success) {
                        dispatch({
                            type: types.SET_PREVIOUS_ORDERS,
                            payload: {
                                previousOrders: res.orders.orders,
                            },
                        });

                        dispatch({
                            type: types.SET_TABLE_DATA,
                            payload: {
                                tableData: res.orders,
                            },
                        });
                    }
                });
            } else {
                alert(res.message);
            }
        });
    };

    const orderStatus = () => {
        switch (order.status.delivery_status) {
            case 'placed':
                return 'PLACED';
            case 'restaurant_accepted':
                return 'PREPARING';
            case 'restaurant_ready':
                return 'READY';
            case 'delivered':
                return 'DELIVERED';
            case 'payment_acknowledged':
                return 'PAID';
        }
    };

    const orderStatusButtonInPopover = () => {
        switch (order.status.delivery_status) {
            case 'placed':
                return (
                    <EuiButton onClick={() => setPreparationTimeModal(true)} size='s' fill>
                        {' '}
                        ACCEPT ORDER
                    </EuiButton>
                );
            case 'restaurant_accepted':
                return (
                    <EuiButton onClick={() => orderStatusChangeAPICall('restaurant_ready')} size='s' fill>
                        {' '}
                        MOVE TO READY
                    </EuiButton>
                );
            case 'restaurant_ready':
                return (
                    <EuiButton onClick={() => orderStatusChangeAPICall('delivered')} size='s' fill>
                        {' '}
                        FOOD ARRIVED
                    </EuiButton>
                );
        }
    };

    const tableHeader = () => {
        return (
            <EuiTable>
                <EuiTableHeader style={{ backgroundColor: '#f7f7f7' }}>
                    <EuiTableHeaderCell key={1} width='75%'>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <div
                                style={{
                                    marginRight: '10px',
                                    cursor: 'pointer',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                }}
                            >
                                <Gear height={12} width={12} onClick={() => setSettingsModal(true)} />
                            </div>

                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                {/* order.status && (order.status.delivered_at || order.status.customer_picked_up_at) ? "Delivered" : */}
                                <span style={{ paddingRight: '15px' }}>KOT #{order.order_id}</span>
                            </div>

                            <EuiPopover
                                button={
                                    <>
                                        <div
                                            onClick={() =>
                                                setIsPopOverOpen((prevState) => setIsPopOverOpen(!prevState))
                                            }
                                            className='custom-badge'
                                            style={{
                                                backgroundColor: '#ffa500',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                marginLeft: '15px',
                                                cursor: 'pointer',
                                            }}
                                        >
                                            <span size='s' style={{ color: '#ffffff', paddingRight: '10px' }}>
                                                {orderStatus()}
                                            </span>
                                        </div>
                                    </>
                                }
                                isOpen={isPopoverOpen}
                                closePopover={() => setIsPopOverOpen(false)}
                            >
                                {orderStatusButtonInPopover()}
                            </EuiPopover>
                        </div>
                    </EuiTableHeaderCell>

                    <EuiTableHeaderCell key={2} width='25%'>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <span style={{ paddingRight: '15px' }}>
                                {order.status &&
                                    order.status.placed_at &&
                                    moment(order.status.placed_at).format('hh:mm A')}
                            </span>
                            {order.order_platform === 'point-of-service' ? (
                                <div onClick={() => setOrderUserDetails(true)}>
                                    <POS height={16} width={16} />
                                </div>
                            ) : (
                                <div onClick={() => setOrderUserDetails(true)}>
                                    <Phone height={16} width={16} />
                                </div>
                            )}
                        </div>
                    </EuiTableHeaderCell>
                </EuiTableHeader>
            </EuiTable>
        );
    };

    const previousOrderList = () => {
        let items = [];
        if (order.cart && order.cart.cart_items && order.cart.cart_items.length > 0) {
            order.cart.cart_items.map((item, index) => {
                if (!item.customizations && item.customizations.length < 1) {
                    itemPushMethodPreviousOrders(items, item, index, superIndex);
                } else {
                    if ((item.quantity > 0 || item.promotional_quantity > 0) && item.customizations.length < 1) {
                        itemPushMethodPreviousOrders(items, item, index, superIndex);
                    }

                    if (item.customizations && item.customizations.length > 0) {
                        itemPushMethodForAddonsPreviousOrders(items, item, index, superIndex);
                    }
                }
            });
        }

        let tableView = (
            <EuiFlexItem style={{ display: 'flex', flexDirection: 'column' }}>
                {mode === 'dine_in' ? tableHeader() : ''}

                <EuiTable id={`previousOrders-${superIndex}`}>
                    <EuiTableHeader>{renderHeaderCell()}</EuiTableHeader>
                    <EuiTableBody>{items}</EuiTableBody>
                </EuiTable>
            </EuiFlexItem>
        );

        return tableView;
    };

    const orderBillingStructureNew = () => {
        let itemObj = {};

        previousOrders[superIndex].cart.cart_items.map((value) => {
            let innerItemObj = {};

            if (!itemObj[value.item_id]) {
                if (value.customizations && value.customizations.length > 0) {
                    innerItemObj['customizations'] = [{ ...convertAddonStructure(value) }];
                } else {
                    innerItemObj['quantity'] = value.quantity;
                    // itemObj[value.item_id]['quantity'] = value.quantity
                }

                if (value.special_instruction) {
                    innerItemObj['special_instruction'] = value.special_instruction;
                }
            } else {
                if (value.customizations && value.customizations.length > 0) {
                    if (
                        itemObj[value.item_id] &&
                        itemObj[value.item_id].customizations &&
                        itemObj[value.item_id].customizations.length > 0
                    ) {
                        innerItemObj['customizations'] = [...itemObj[value.item_id].customizations];
                    } else {
                        innerItemObj['customizations'] = [];
                    }

                    innerItemObj['customizations'].push({
                        ...convertAddonStructure(value, 'orderBillingStructureNew'),
                    });
                } else {
                    innerItemObj['quantity'] = parseInt(itemObj[value.item_id]['quantity']) + parseInt(value.quantity);
                    // itemObj[value.item_id]['quantity'] = value.quantity
                }

                if (value.special_instruction) {
                    innerItemObj['special_instruction'] = value.special_instruction;
                }
            }

            //this is used to avoid issue of override of itemObj
            if (innerItemObj.customizations) {
                if (itemObj[value.item_id]) {
                    itemObj[value.item_id].customizations = innerItemObj.customizations;
                    if (itemObj[value.item_id].quantity) {
                        itemObj[value.item_id].quantity = itemObj[value.item_id].quantity;
                    }
                } else {
                    itemObj[value.item_id] = innerItemObj;
                    if (itemObj[value.item_id].quantity) {
                        itemObj[value.item_id].quantity = itemObj[value.item_id].quantity;
                    } else {
                        itemObj[value.item_id].quantity = 0;
                    }
                }
            } else {
                itemObj[value.item_id] = {
                    quantity: innerItemObj.quantity,
                    special_instruction: innerItemObj.special_instruction ? innerItemObj.special_instruction : '',
                };
            }
        });
        console.log('item obj in itemObj', itemObj);
        return itemObj;
    };

    const saveOrder = async () => {
        let itemStructure = orderBillingStructureNew();
        let innerObj = {};

        innerObj['items'] = itemStructure;

        if (!innerObj['items'] && isEmpty(innerObj['items'])) {
            innerObj['void'] = 1;
        }

        innerObj.customer = {
            ...customerInformation,
            mobile_number: customerInformation.mobile_number,
            date_of_birth: customerInformation.date_of_birth
                ? moment(customerInformation.date_of_birth).format('YYYY-MM-DD')
                : '',
            anniversary_date: customerInformation.anniversary_date
                ? moment(customerInformation.anniversary_date).format('YYYY-MM-DD')
                : '',
        };

        console.log('edit order json string ', JSON.stringify(innerObj));
        let response;

        try {
            response = await API.put(
                `${languageCode}/pos/restaurants/:restaurantId/outlets/${order.outlet_id}/orders/${order.order_id}`,
                innerObj
            );
        } catch (error) {
            response = error;
        }

        if (response.success) {
            fetchPreviousOrders();
        } else {
            alert('Error in updating order');
        }

        setEditOrder(false);
        setCommentsId([]);
    };

    const fetchPreviousOrders = async () => {
        let response;

        try {
            response = await API.get(
                `restaurants/:restaurantId/outlets/${order.outlet_id}/tables/${tableSelectedList[0]}`
            );
        } catch (error) {
            response = error;
        }

        if (response.success) {
            dispatch({
                type: types.SET_PREVIOUS_ORDERS,
                payload: {
                    previousOrders: response.orders.orders,
                },
            });
        } else {
            alert('Error in fetching table orders');
        }
    };

    const cancelEditOrder = () => {
        dispatch({
            type: types.SET_PREVIOUS_ORDERS,
            payload: {
                previousOrders: editPreviousOrder,
            },
        });

        setEditOrder(false);
        setCommentsId([]);
    };

    console.log('preparationTimeModal ', preparationTimeModal);

    return (
        <>
            {commentModal && (
                <ItemCommentModal
                    setCommentModal={setCommentModal}
                    previousOrders={true}
                    currentItemComment={currentItemComment}
                    currentIndex={currentIndex}
                    currentSuperIndex={currentSuperIndex}
                />
            )}
            {settingsModal && (
                <RunningOrderSettingsModal
                    setEditPreviousOrders={setEditPreviousOrders}
                    setEditOrder={setEditOrder}
                    setSettingsModal={setSettingsModal}
                    order={order}
                    superIndex={superIndex}
                />
            )}
            {!isEmpty(removeItem) && (
                <ConfirmRemove onRemoveItem={onRemoveItem} removeItem={removeItem} setRemoveItem={setRemoveItem} />
            )}
            {orderUserDetails && (
                <OrderUserDetails customer={order.customer} setOrderUserDetails={setOrderUserDetails} />
            )}
            {preparationTimeModal && (
                <PreparationTimeModal
                    pos
                    order={order}
                    preparationTimeModal={preparationTimeModal}
                    setPreparationTimeModal={setPreparationTimeModal}
                    updateOrderStatusCall={orderStatusChangeAPICall}
                />
            )}

            {previousOrderList()}

            {editOrder && (
                <EuiFlexItem
                    style={{
                        padding: '10px',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-around',
                    }}
                >
                    <EuiButton style={{ height: '30px' }} color='danger' onClick={cancelEditOrder}>
                        Cancel
                    </EuiButton>
                    <EuiButton style={{ height: '30px' }} onClick={saveOrder} fill>
                        Save
                    </EuiButton>
                </EuiFlexItem>
            )}
        </>
    );
};

const OrderUserDetails = (props) => {
    const { customer, setOrderUserDetails } = props;
    return (
        <EuiOverlayMask>
            <EuiConfirmModal
                title='Order user details'
                // onCancel={() => setRemoveItem({})}
                onConfirm={() => {
                    setOrderUserDetails(false);
                }}
                // cancelButtonText="Close"
                confirmButtonText='Close'
                defaultFocusedButton='confirm'
            >
                <EuiFlexItem>
                    <EuiText>Name : {customer.name}</EuiText>
                    <EuiText>Number : {customer.mobile_number}</EuiText>
                </EuiFlexItem>
            </EuiConfirmModal>
        </EuiOverlayMask>
    );
};

const ConfirmRemove = (props) => {
    const { setRemoveItem, removeItem, onRemoveItem } = props;
    return (
        <EuiOverlayMask>
            <EuiConfirmModal
                title='Delete Item'
                onCancel={() => setRemoveItem({})}
                onConfirm={() => {
                    onRemoveItem(removeItem.index, removeItem.superIndex);
                    setRemoveItem({});
                }}
                cancelButtonText='Cancel'
                confirmButtonText='Delete'
                defaultFocusedButton='confirm'
            >
                You are about to delete an item.
            </EuiConfirmModal>
        </EuiOverlayMask>
    );
};

export default OrderComponent;
