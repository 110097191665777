import {
    EuiButton,
    EuiFlexGroup,
    EuiFlexItem,
    EuiTitle,
    EuiText,
    EuiDataGrid,
    EuiPopover,
    EuiIcon,
    EuiButtonEmpty,
    EuiContextMenuPanel,
    EuiPagination,
    EuiLoadingContent,
} from '@elastic/eui';
import moment from 'moment';
import React, { createContext, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCreditTransactions } from '../../../api/topup/TransactionHistory';
import CreditsAlertModal from '../../../components/Credits/CreditsAlertModal';
import CustomDatePicker from '../../../components/Credits/CustomDatePicker';
import PaymentModal from '../../../components/Credits/PaymentModal';
import PopOverItems from '../../../components/Credits/PopOverItems';
import { authModule } from '../../../helper/authModulePermission';
import { isEmpty } from 'lodash';
const DataContext = createContext();

const InfraCredits = (props) => {
    const [showPaymentModal, setShowPaymentModal] = useState(false);
    const [showAlertModal, setShowAlertModal] = useState(false);
    const dispatch = useDispatch();
    const typeOfTransaction = 'infrastructure';
    const [transactionData, setTransactionData] = useState([]);
    const [startDate, setStartDate] = useState(moment());
    const [endDate, setEndDate] = useState(moment());
    const [availableCredits, setAvailableCredits] = useState(0);
    const [loader, setLoader] = useState(true);
    const [alertAmount, setAlertAmount] = useState(50);
    const [updateTopupData, setUpdateTopupData] = useState(false);
    const lastUpdated = useSelector((state) => state.creditAlerts.last_update);
    const permissions = useSelector((state) => state.permission.permission);
    const updatedOrderList = useSelector((state) => state.creditAlerts.transaction_list);

    const closePaymentModal = () => {
        setShowPaymentModal(false);
        setShowAlertModal(false);
    };

    useEffect(() => {
        if (!isEmpty(updatedOrderList)) {
            setTransactionData(updatedOrderList.transactions.data);
            setTotalRedcords(updatedOrderList?.transactions?.total);
            setAvailableCredits(updatedOrderList?.total_service_credit);
            setAlertAmount(updatedOrderList?.low_infrastructure_credit_alert_amount);
            setLoader(false);
        }
    }, [updatedOrderList]);

    // ** Pagination config
    const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 });
    const onChangePage = useCallback(
        (pageIndex) => setPagination((pagination) => ({ ...pagination, pageIndex })),
        [setPagination]
    );

    // ** Sorting config
    const [sortingColumns, setSortingColumns] = useState([]);
    const onSort = useCallback(
        (sortingColumns) => {
            setSortingColumns(sortingColumns);
        },
        [setSortingColumns]
    );

    const [totalRecords, setTotalRedcords] = useState(0);

    // fetch transactions list;
    useEffect(() => {
        setLoader(true);
        dispatch(fetchCreditTransactions(pagination, typeOfTransaction, startDate, endDate)).then((res) => {
            setTransactionData(res?.transactions?.data);
            setTotalRedcords(res?.transactions?.total);
            setAvailableCredits(res?.total_service_credit);
            setAlertAmount(res?.low_infrastructure_credit_alert_amount);
            setLoader(false);
        });
    }, [startDate, endDate, pagination, lastUpdated]);

    useEffect(() => {
        if (updateTopupData) {
            setLoader(true);
            dispatch(fetchCreditTransactions(pagination, typeOfTransaction, startDate, endDate)).then((res) => {
                setTransactionData(res?.transactions?.data);
                setTotalRedcords(res?.transactions?.total);
                setAvailableCredits(res?.total_service_credit);
                setAlertAmount(res?.low_infrastructure_credit_alert_amount);
                setLoader(false);
            });
        }
    }, [updateTopupData]);

    let raw_data = [];

    if (transactionData?.length > 0) {
        transactionData.forEach((data) =>
            raw_data.push({
                reference_id: <>{<EuiText>{data.reference_id}</EuiText>}</>,
                type: <>{<EuiText>{data.type}</EuiText>}</>,
                service: (
                    <>
                        {
                            <EuiText>
                                {data.third_party_api_name
                                    ? data.third_party_api_name
                                    : 'sms credit and google geocode api'}
                            </EuiText>
                        }
                    </>
                ),
                status: <>{<EuiText>{data.status}</EuiText>}</>,
                amount: <>{<EuiText>{data.amount}</EuiText>}</>,
                date: <>{<EuiText> {data.date}</EuiText>}</>,
            })
        );
    }

    const PAGE_COUNT = transactionData?.length > 0 && Math.ceil(totalRecords / pagination.pageSize);
    const [isPopoverOpen, setIsPopoverOpen] = useState(false);
    const onButtonClick = () => setIsPopoverOpen((isPopoverOpen) => !isPopoverOpen);
    const closePopover = () => setIsPopoverOpen(false);

    const onChangeRowSize = (row_size) => {
        setPagination({
            ...pagination,
            pageSize: row_size,
        });
        onChangePage();
    };

    const button = (
        <EuiButtonEmpty size='s' color='text' iconType='arrowDown' iconSide='right' onClick={onButtonClick}>
            Rows per page: {pagination.pageSize}
        </EuiButtonEmpty>
    );

    const items = [<PopOverItems closePopover={closePopover} onChangeRowSize={onChangeRowSize} />];

    const columns = [
        {
            id: 'reference_id',
            displayAsText: 'Reference ID',
            defaultSortDirection: 'asc',
            // isSortable: false,
        },
        {
            id: 'type',
            defaultSortDirection: 'asc',
            initialWidth: 125,
        },

        {
            id: 'service',
            isSortable: false,
        },
        {
            id: 'status',
            defaultSortDirection: 'asc',
            isResizable: false,
            initialWidth: 125,
        },
        {
            id: 'amount',
            initialWidth: 125,
        },
        {
            id: 'date',
            actions: {
                additional: [
                    {
                        label: 'Today',
                        onClick: () => {
                            setStartDate(moment());
                            setEndDate(moment());
                            setPagination({ pageIndex: 0, pageSize: 10 });
                            dispatch(fetchCreditTransactions(pagination, typeOfTransaction, startDate, endDate)).then(
                                (res) => {
                                    setTransactionData(res.transactions.data);
                                }
                            );
                        },
                        iconType: 'calendar',
                        size: 'xs',
                        color: 'text',
                    },
                    {
                        label: 'Yesterday',
                        onClick: () => {
                            setStartDate(moment().subtract(1, 'day'));
                            setEndDate(moment().subtract(1, 'day'));
                            setPagination({ pageIndex: 0, pageSize: 10 });
                            dispatch(fetchCreditTransactions(pagination, typeOfTransaction, startDate, endDate)).then(
                                (res) => {
                                    setTransactionData(res.transactions.data);
                                }
                            );
                        },
                        iconType: 'calendar',
                        size: 'xs',
                        color: 'text',
                    },
                    {
                        label: 'Last 7 days',
                        onClick: () => {
                            setStartDate(moment().subtract(7, 'day'));
                            setEndDate(moment());
                            setPagination({ pageIndex: 0, pageSize: 10 });
                            dispatch(fetchCreditTransactions(pagination, typeOfTransaction, startDate, endDate)).then(
                                (res) => {
                                    setTransactionData(res.transactions.data);
                                }
                            );
                        },
                        iconType: 'calendar',
                        size: 'xs',
                        color: 'text',
                    },
                    {
                        label: 'Last 30 days',
                        onClick: () => {
                            setStartDate(moment().subtract(1, 'month'));
                            setEndDate(moment());
                            setPagination({ pageIndex: 0, pageSize: 10 });
                            dispatch(fetchCreditTransactions(pagination, typeOfTransaction, startDate, endDate)).then(
                                (res) => {
                                    setTransactionData(res.transactions.data);
                                }
                            );
                        },
                        iconType: 'calendar',
                        size: 'xs',
                        color: 'text',
                    },
                    {
                        label: 'This month',
                        onClick: () => {
                            setStartDate(moment().clone().startOf('month'));
                            setEndDate(moment());
                            setPagination({ pageIndex: 0, pageSize: 10 });
                            dispatch(fetchCreditTransactions(pagination, typeOfTransaction, startDate, endDate)).then(
                                (res) => {
                                    setTransactionData(res.transactions.data);
                                }
                            );
                        },
                        iconType: 'calendar',
                        size: 'xs',
                        color: 'text',
                    },
                    {
                        label: 'Last month',
                        onClick: () => {
                            setStartDate(moment().subtract(1, 'month').startOf('month'));
                            setEndDate(moment().subtract(1, 'month').endOf('month'));
                            setPagination({ pageIndex: 0, pageSize: 10 });
                            dispatch(fetchCreditTransactions(pagination, typeOfTransaction, startDate, endDate)).then(
                                (res) => {
                                    setTransactionData(res.transactions.data);
                                }
                            );
                        },
                        iconType: 'calendar',
                        size: 'xs',
                        color: 'text',
                    },
                ],
            },
        },
    ];

    const [visibleColumns, setVisibleColumns] = useState(() => columns.map(({ id }) => id)); // initialize to the full set of columns

    const renderCellValue = useMemo(() => {
        return ({ rowIndex, columnId, setCellProps }) => {
            return raw_data.hasOwnProperty(rowIndex) ? raw_data[rowIndex][columnId] : null;
        };
    }, [raw_data]);

    return (
        <>
            {showPaymentModal && (
                <PaymentModal
                    closeModal={closePaymentModal}
                    setShowPaymentModal={setShowPaymentModal}
                    creditType='infrastructure'
                    setUpdateTopupData={setUpdateTopupData}
                    setPaymentLoader={props.setPaymentLoader}
                    setLoader={setLoader}
                />
            )}
            {showAlertModal && (
                <CreditsAlertModal
                    alertAmount={alertAmount}
                    alertType='infrastructure'
                    closeModal={closePaymentModal}
                    setUpdateTopupData={setUpdateTopupData}
                />
            )}
            <EuiFlexGroup justifyContent='spaceBetween' style={{ width: '100%' }} alignItems='center'>
                <EuiFlexItem>
                    <EuiText
                        size='s'
                        style={{ marginBottom: '26px' }}
                        children={
                            <h2>
                                <b> {props.title}</b>
                            </h2>
                        }
                    />
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                    <div>
                        <EuiButton
                            onClick={() => setShowPaymentModal(true)}
                            fill
                            disabled={!authModule(permissions, 'write', 'infrastructure_credit')}
                        >
                            Add Credits
                        </EuiButton>
                        <EuiIcon
                            onClick={() => setShowAlertModal(true)}
                            type='gear'
                            style={{ marginLeft: '18px', cursor: 'pointer' }}
                            size='l'
                        />
                    </div>
                </EuiFlexItem>
            </EuiFlexGroup>
            <div
                style={{
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'space-between',
                    alignItems: 'end',
                    marginBottom: '16px',
                }}
            >
                <div
                    justifyContent='spaceBetween'
                    grow={false}
                    style={{
                        width: '28%',
                        padding: '32px 26px',
                        boxShadow:
                            '0 0 12px -1px rgb(65 78 101 / 20%), 0 0 4px -1px rgb(65 78 101 / 20%), 0 0 2px 0 rgb(65 78 101 / 20%)',
                    }}
                    gutterSize='xs'
                    direction='row'
                    alignItems='center'
                >
                    <EuiFlexItem>
                        {loader ? (
                            <EuiLoadingContent lines={2} />
                        ) : (
                            <EuiFlexGroup direction='column'>
                                <EuiText size='s' children={<p>Available Credits</p>} />
                                <EuiTitle size='l' children={<h1>{availableCredits}</h1>} />
                                <EuiText size='xs' children={<span>These Credits are not real time.</span>} />
                            </EuiFlexGroup>
                        )}
                    </EuiFlexItem>
                </div>
                <CustomDatePicker
                    startDate={startDate}
                    setStartDate={setStartDate}
                    endDate={endDate}
                    setEndDate={setEndDate}
                />
            </div>
            <EuiFlexItem>
                <DataContext.Provider value={raw_data}>
                    <EuiDataGrid
                        aria-label='Customer List Datagrid'
                        columns={columns}
                        columnVisibility={{ visibleColumns, setVisibleColumns }}
                        rowCount={raw_data.length}
                        inMemory={{ level: 'sorting' }}
                        renderCellValue={renderCellValue}
                        sorting={{ columns: sortingColumns, onSort }}
                        height={raw_data?.length > 0 && raw_data?.length < 5 ? '40vh' : 'auto'}
                        width='100%'
                    />
                    <EuiFlexGroup justifyContent='spaceBetween' alignItems='center'>
                        <EuiFlexItem grow={false}>
                            <EuiPopover
                                button={button}
                                isOpen={isPopoverOpen}
                                closePopover={closePopover}
                                panelPaddingSize='none'
                            >
                                <EuiContextMenuPanel items={items} />
                            </EuiPopover>
                        </EuiFlexItem>

                        <EuiFlexItem grow={false}>
                            <EuiPagination
                                aria-label='Custom pagination example'
                                pageCount={PAGE_COUNT}
                                activePage={pagination.pageIndex}
                                onPageClick={onChangePage}
                            />
                        </EuiFlexItem>
                    </EuiFlexGroup>
                </DataContext.Provider>
            </EuiFlexItem>
        </>
    );
};

export default InfraCredits;
