import React, { useEffect, useState } from 'react';
import {
    EuiButton,
    EuiOverlayMask,
    EuiFlexGroup,
    EuiFlexItem,
    EuiTabs,
    EuiTab,
    EuiFlyoutFooter,
    EuiTitle,
    EuiFlyout,
    EuiFlyoutHeader,
    EuiFlyoutBody,
    EuiModal,
    EuiModalHeader,
    EuiModalBody,
    EuiModalFooter,
    EuiButtonGroup,
} from '@elastic/eui';
import './style.css';
import { ETLDeliveryManagement } from '../orderDetailsCard/eltRiderManagemnet';
import { FabDeliveryManagment } from '../orderDetailsCard/fabDeliveryManagement';
import { DunzoRiderTab } from '../orderDetailsCard/dunzoRider';
import { WeFastRiderTab } from '../orderDetailsCard/weFastRider';
import { ScoutRiderTab } from '../orderDetailsCard/scoutRider';
import { AhoyRiderTab } from '../orderDetailsCard/ahoyRider';
import { ShadowFaxRiderTab } from '../orderDetailsCard/shadowFaxRider';

export const RiderManageModal = (props) => {
    const [tabState, setTabState] = useState('bolt-rider');
    const [deliveryServices, setDeliveryServices] = useState([]);
    // [{id: 'bolt', label: 'Bolt Riders'}, {id: 'elt-riders', label: 'ELT Riders'}, {id: 'dunzo-riders', label: 'Dunzo Riders'}, {id: 'we-fast-riders', label: 'Wefast Riders'}, {id: 'scout-riders', label: 'Scout Riders'}, {id: 'ahoy-riders', label: 'Ahoy Riders'}]

    const closeModal = () => {
        props.closeRiderModal();
    };

    useEffect(() => {
        if (
            props.order.outlet &&
            props.order.outlet.settings &&
            !props.order.outlet.settings.enable_own_rider_service
        ) {
            if (props.order.outlet && props.order.outlet.third_party_rider_setting) {
                riderEnableCheck();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.order.outlet]);

    useEffect(() => {
        if (props.order.outlet && props.order.outlet.settings) {
            let innerArray = [];
            if (props.order.outlet.settings.enable_own_rider_service) {
                innerArray.push({ id: 'bolt-rider', label: 'Bolt Riders' });
            }

            if (props.order.outlet?.third_party_rider_setting?.elt_integration_available) {
                innerArray.push({ id: 'elt-rider', label: 'ELT Riders' });
            }

            if (props.order.outlet?.third_party_rider_setting?.dunzo_integration_available) {
                innerArray.push({ id: 'dunzo-rider', label: 'Dunzo Riders' });
            }

            if (props.order.outlet?.third_party_rider_setting?.wefast_integration_available) {
                innerArray.push({ id: 'we-fast-rider', label: 'Wefast Riders' });
            }

            if (props.order.outlet?.third_party_rider_setting?.scout_runner_integration_available) {
                innerArray.push({ id: 'scout-rider', label: 'Scout Riders' });
            }

            if (props.order.outlet?.third_party_rider_setting?.ahoy_integration_available) {
                innerArray.push({ id: 'ahoy-rider', label: 'Ahoy Riders' });
            }

            if (props.order.outlet?.third_party_rider_setting?.is_shadowfax_integration_enabled) {
                innerArray.push({ id: 'shadow-fax-rider', label: 'Shadow fax Riders' });
            }

            setDeliveryServices(innerArray);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.order.outlet]);

    const riderEnableCheck = () => {
        switch (1) {
            case props.order.outlet.third_party_rider_setting.elt_integration_available:
                setTabState('elt-rider');
                return;
            case props.order.outlet.third_party_rider_setting.dunzo_integration_available:
                setTabState('dunzo-rider');
                return;
            case props.order.outlet.third_party_rider_setting.wefast_integration_available:
                setTabState('we-fast-rider');
                return;
            case props.order.outlet.third_party_rider_setting.scout_runner_integration_available:
                setTabState('scout-rider');
                return;
            case props.order.outlet.third_party_rider_setting.ahoy_integration_available:
                setTabState('ahoy-rider');
                return;
            case parseInt(props.order.outlet.third_party_rider_setting.is_shadowfax_integration_enabled):
                setTabState('shadow-fax-rider');
                return;
            default:
                return;
        }
    };

    const serviceCheck = () => {
        let msg = riderServiceCheckSwitch();

        return (
            <EuiFlexItem style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                <b>{msg}</b>
            </EuiFlexItem>
        );
    };

    const riderServiceCheckSwitch = () => {
        switch (1) {
            case props.order.outlet.settings.enable_own_rider_service:
            case props.order.outlet.third_party_rider_setting.elt_integration_available:
            case props.order.outlet.third_party_rider_setting.dunzo_integration_available:
            case props.order.outlet.third_party_rider_setting.wefast_integration_available:
            case props.order.outlet.third_party_rider_setting.scout_runner_integration_available:
            case props.order.outlet.third_party_rider_setting.ahoy_integration_available:
            case parseInt(props.order.outlet.third_party_rider_setting.is_shadowfax_integration_enabled):
                return '';
            default:
                return 'Please select atleast one Rider service';
        }
    };

    const onChangeDeliveryService = (value) => {
        setTabState(value);
    };
    return (
        <>
            {props.riderModalOpen && (
                <EuiOverlayMask>
                    <EuiModal
                        onClose={() => {
                            closeModal();
                        }}
                        style={{ zIndex: 6000 }}
                    >
                        <EuiModalHeader>
                            <EuiTitle size='m'>
                                <h2>Rider Manage</h2>
                            </EuiTitle>
                        </EuiModalHeader>

                        <EuiModalBody>
                            <EuiFlexGroup alignItems='center' justifyContent='spaceBetween'>
                                <EuiFlexItem style={{ overflow: 'scroll' }}>
                                    <EuiButtonGroup
                                        options={deliveryServices}
                                        idSelected={tabState}
                                        onChange={onChangeDeliveryService}
                                        color='primary'
                                    />
                                </EuiFlexItem>
                            </EuiFlexGroup>

                            {tabState === 'elt-rider' &&
                            props.order.outlet &&
                            props.order.outlet.third_party_rider_setting &&
                            props.order.outlet.third_party_rider_setting.elt_integration_available ? (
                                <ETLDeliveryManagement {...props} />
                            ) : (
                                ''
                            )}
                            {tabState === 'bolt-rider' &&
                            props.order.outlet &&
                            props.order.outlet.settings &&
                            props.order.outlet.settings.enable_own_rider_service ? (
                                <FabDeliveryManagment {...props} />
                            ) : (
                                ''
                            )}
                            {tabState === 'dunzo-rider' &&
                            props.order.outlet &&
                            props.order.outlet.third_party_rider_setting &&
                            props.order.outlet.third_party_rider_setting.dunzo_integration_available ? (
                                <DunzoRiderTab {...props} />
                            ) : (
                                ''
                            )}

                            {tabState === 'we-fast-rider' &&
                            props.order.outlet &&
                            props.order.outlet.third_party_rider_setting &&
                            props.order.outlet.third_party_rider_setting.wefast_integration_available ? (
                                <WeFastRiderTab {...props} />
                            ) : (
                                ''
                            )}

                            {tabState === 'scout-rider' &&
                            props.order.outlet &&
                            props.order.outlet.third_party_rider_setting &&
                            props.order.outlet.third_party_rider_setting.scout_runner_integration_available ? (
                                <ScoutRiderTab {...props} />
                            ) : (
                                ''
                            )}

                            {tabState === 'ahoy-rider' &&
                            props.order.outlet &&
                            props.order.outlet.third_party_rider_setting &&
                            props.order.outlet.third_party_rider_setting.ahoy_integration_available ? (
                                <AhoyRiderTab {...props} />
                            ) : (
                                ''
                            )}

                            {tabState === 'shadow-fax-rider' &&
                            parseInt(
                                props?.order?.outlet?.third_party_rider_setting?.is_shadowfax_integration_enabled
                            ) ? (
                                <ShadowFaxRiderTab {...props} />
                            ) : (
                                ''
                            )}

                            {serviceCheck()}
                        </EuiModalBody>

                        {/* <EuiModalFooter>
                            <EuiButton onClick={() => closeModal()} fill>
                                Close
                            </EuiButton>
                        </EuiModalFooter> */}
                    </EuiModal>
                </EuiOverlayMask>
            )}
        </>
    );
};
