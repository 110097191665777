import React, { memo } from 'react';
import {
    EuiCheckableCard,
    EuiFlexGroup,
    EuiFlexItem,
    EuiButtonIcon,
    EuiSpacer,
    EuiText,
    EuiLoadingContent,
    EuiPanel,
    EuiCard,
} from '@elastic/eui';
import { useSelector } from 'react-redux';
import { userHasPermission } from '../../../helper/auth';
import { isEmpty } from 'lodash';

const AddOnGroupList = (props) => {
    const selectedLanguage = useSelector((state) => state.language.languageId);
    const permissions = useSelector((state) => state.permission.permission);

    if (!props.addOnList) {
        return null;
    }

    if (isEmpty(props.addOnList))
        return (
            <EuiPanel hasBorder>
                <EuiLoadingContent lines={4} />
            </EuiPanel>
        );

    return props.addOnList?.restaurant_addon_groups?.length ? (
        props.addOnList.restaurant_addon_groups.map((restaurant_addon_group, index) => {
            return (
                <div key={index}>
                    <EuiCheckableCard
                        id={restaurant_addon_group.addon_group_id.toString()}
                        label={
                            <EuiFlexGroup responsive={false} justifyContent='spaceBetween'>
                                <EuiFlexItem grow={false}>
                                    <EuiText>
                                        <p>
                                            {restaurant_addon_group.translations.group_name[`${selectedLanguage}`]} |{' '}
                                            {restaurant_addon_group.internal_name}{' '}
                                        </p>
                                    </EuiText>
                                </EuiFlexItem>
                                <EuiFlexItem grow={false}>
                                    <EuiFlexGroup responsive={false} gutterSize='none'>
                                        <EuiFlexItem grow={2}>
                                            {userHasPermission(permissions, '/menu', 'write') && (
                                                <EuiButtonIcon
                                                    color='primary'
                                                    onClick={() => {
                                                        props.toggleFlyout(restaurant_addon_group);
                                                    }}
                                                    iconType='link'
                                                    aria-label='Next'
                                                />
                                            )}
                                        </EuiFlexItem>
                                        <EuiFlexItem grow={false} className='mr-left-10'>
                                            {userHasPermission(permissions, '/menu', 'write') && (
                                                <EuiButtonIcon
                                                    color='primary'
                                                    onClick={() =>
                                                        props.editGroup(restaurant_addon_group.addon_group_id)
                                                    }
                                                    iconType='pencil'
                                                    aria-label='Next'
                                                />
                                            )}
                                        </EuiFlexItem>
                                        <EuiFlexItem grow={false} className='mr-left-10'>
                                            {userHasPermission(permissions, '/menu', 'delete') && (
                                                <EuiButtonIcon
                                                    color='danger'
                                                    onClick={() =>
                                                        props.deleteGroup(restaurant_addon_group.addon_group_id)
                                                    }
                                                    iconType='trash'
                                                    aria-label='Next'
                                                />
                                            )}
                                        </EuiFlexItem>
                                    </EuiFlexGroup>
                                </EuiFlexItem>
                            </EuiFlexGroup>
                        }
                        name='add-on-groups'
                        checked={restaurant_addon_group.addon_group_id === props.selectedGroup}
                        onChange={() => props.onChangeSelectedGroup(restaurant_addon_group.addon_group_id)}
                    />
                    <EuiSpacer size={'s'} />
                </div>
            );
        })
    ) : (
        <EuiCard title='Addon Groups Not Found.' />
    );
};
export default memo(AddOnGroupList);
