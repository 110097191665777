import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import NumberField from '../../../components/Form/NumberField';
import FormGroupDescription from '../../../components/formGroupDescription';
import { EuiSpacer, EuiForm } from '@elastic/eui';
import { useDispatch, useSelector } from 'react-redux';
import { outletSettingsAPI } from '../../../api/outlet/outletSettingsAPI';
import * as types from '../../../reduxStore/types/outlet';
import { userHasPermission } from '../../../helper/auth';

const OtherCharges = (props) => {
    const { getOutletData, fetchOutletById } = props;
    const {
        handleSubmit,
        control,
        reset,

        formState: { isDirty, errors },
    } = useForm();
    const dispatch = useDispatch();
    const permissions = useSelector((state) => state.permission.permission);
    const restaurantDescriptions = useSelector((state) => state.auth.restaurantDescriptions);

    useEffect(() => {
        if (!userHasPermission(permissions, '/outlet-settings', 'write')) return;

        if (isDirty) {
            dispatch({
                type: 'set-portal-active',
                payload: {
                    portalActive: {
                        resetChanges: resetChanges,
                        submit: handleSubmit(onSubmit),
                    },
                },
            });
        } else {
            dispatch({
                type: 'set-portal-active',
                payload: {
                    portalActive: {},
                },
            });
        }
    }, [isDirty]);

    const resetChanges = () => {
        let defaultValue = {};
        defaultValue.packaging_charge = getOutletData.outlet.settings.packaging_charge;
        defaultValue.convenience_charge = getOutletData.outlet.settings.convenience_charge;
        reset({
            ...defaultValue,
        });
    };

    useEffect(() => {
        if (getOutletData && getOutletData.outlet) {
            resetChanges();
        }
    }, [getOutletData]);

    const onSubmit = (data) => {
        dispatch(outletSettingsAPI('other_charges', data)).then((res) => {
            if (res.success) {
                dispatch({
                    type: types.SET_PORTAL_ACTIVE,
                    payload: {
                        portalActive: {},
                    },
                });
                dispatch({
                    type: types.SET_PORTAL_ACTIVE_TAB_SWITCHED,
                    payload: {
                        tabSwitched: false,
                    },
                });
                fetchOutletById();
            } else {
                alert(res.message);
            }
        });
    };

    return (
        <>
            <EuiForm component='form' onSubmit={handleSubmit(onSubmit)}>
                <FormGroupDescription
                    title={restaurantDescriptions?.other_charges?.display_name}
                    description={restaurantDescriptions?.other_charges?.description}
                >
                    <NumberField
                        label='Packaging charge'
                        errors={errors}
                        name='packaging_charge'
                        placeholder='Please enter packaging charge'
                        rules={null}
                        step='any'
                        control={control}
                        helpText='This will be charged per order.'
                    />
                    <NumberField
                        label='Convinience charge'
                        errors={errors}
                        name='convenience_charge'
                        placeholder='Please enter delivery charge thresold'
                        rules={null}
                        step='any'
                        control={control}
                    />
                    <EuiSpacer />

                    {/* {
                        isDirty ? (
                            <EuiFormRow>
                                <EuiFlexGroup alignItems="center">
                                    <LoaderButton isLoading={false}/>
                                    <EuiFlexItem>
                                        <EuiButtonEmpty onClick={resetChanges}>Discard changes</EuiButtonEmpty>
                                    </EuiFlexItem>
                                </EuiFlexGroup>
                            </EuiFormRow>
                        ): ''
                    } */}
                </FormGroupDescription>
            </EuiForm>
        </>
    );
};

export default OtherCharges;
