import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import TextField from '../../../components/Form/TextField';
import TextAreaField from '../../../components/Form/TextAreaField';
import { EuiFlexItem, EuiFlexGroup, EuiSpacer } from '@elastic/eui';
import { useFormContext } from 'react-hook-form';
import CheckBoxField from '../../../components/Form/CheckBoxField';

const BasicDetails = ({ selectedOfferType }) => {
    const languages = useSelector((state) => state.language.availableLanguages);
    const {
        formState: { errors },
        control,
        setValue,
    } = useFormContext();

    return (
        <EuiFlexGroup>
            <EuiFlexItem>
                {languages?.map((item) => (
                    <Fragment key={item.language_id}>
                        <TextField
                            errors={errors}
                            isInvalid={
                                errors.translations &&
                                errors.translations.title &&
                                errors.translations.title.hasOwnProperty(item.language_id)
                            }
                            error={
                                errors.translations &&
                                errors.translations.title &&
                                errors.translations.title.hasOwnProperty(item.language_id) &&
                                errors.translations.title[item.language_id]
                                    ? errors.translations.title[item.language_id].message
                                    : ''
                            }
                            label={`${item.language_label} : Title`}
                            name={`translations.title.${item.language_id}`}
                            placeholder='Please Enter title'
                            rules={{
                                required: 'Please enter Title',
                            }}
                            control={control}
                        />
                        {/* <input
                                placeholder='Please Enter title'
                                {...register(`translations.title.${item.language_id}`)}
                                // onChange={field.onChange}
                            /> */}
                        <TextAreaField
                            errors={errors}
                            isInvalid={
                                errors.translations &&
                                errors.translations.description &&
                                errors.translations.description.hasOwnProperty(item.language_id)
                            }
                            compressed={true}
                            error={
                                errors.translations &&
                                errors.translations.description &&
                                errors.translations.description.hasOwnProperty(item.language_id) &&
                                errors.translations.description[item.language_id]
                                    ? errors.translations.description[item.language_id].message
                                    : ''
                            }
                            label={`${item.language_label}  : Description`}
                            name={`translations.description.${item.language_id}`}
                            placeholder='Please Enter description'
                            rules={{
                                required: 'Please enter description',
                            }}
                            control={control}
                        />
                    </Fragment>
                ))}

                {selectedOfferType !== 'menu_discount' && (
                    <TextField
                        errors={errors}
                        label='Coupen code'
                        name='coupon_code'
                        placeholder='Please Enter coupen code'
                        rules={{ required: 'Please enter coupen code' }}
                        control={control}
                    />
                )}
                <EuiSpacer />
                <CheckBoxField
                    label='Hide On Offer Exhaustion'
                    setValue={setValue}
                    control={control}
                    name='hide_on_exhaustion'
                />
            </EuiFlexItem>
        </EuiFlexGroup>
    );
};
export default React.memo(BasicDetails);
