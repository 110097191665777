import { EuiDatePicker, EuiDatePickerRange } from '@elastic/eui';
import React from 'react';

export default function CustomDatePicker(props) {
    const { startDate, setStartDate, endDate, setEndDate, displayFormat, startDatePlaceholder, endDatePlaceholder } =
        props;

    return (
        <EuiDatePickerRange
            startDateControl={
                <EuiDatePicker
                    selected={startDate}
                    onChange={setStartDate}
                    startDate={startDate}
                    endDate={endDate}
                    isInvalid={props.isInvalid && startDate > endDate}
                    aria-label='Start date'
                    dateFormat={displayFormat}
                    placeholder={startDatePlaceholder}
                />
            }
            endDateControl={
                <EuiDatePicker
                    selected={endDate}
                    onChange={setEndDate}
                    startDate={startDate}
                    endDate={endDate}
                    isInvalid={props.isInvalid && startDate > endDate}
                    aria-label='End date'
                    dateFormat={displayFormat}
                    placeholder={endDatePlaceholder}
                />
            }
        />
    );
}
