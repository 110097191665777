import React, { memo } from 'react';
import { Chart, Axis, Settings, LineSeries } from '@elastic/charts';
import { EUI_CHARTS_THEME_DARK, EUI_CHARTS_THEME_LIGHT } from '@elastic/eui/dist/eui_charts_theme';
import { EuiText, EuiFlexItem, EuiSpacer, EuiFlexGroup } from '@elastic/eui';
import { orderBy } from 'lodash';
import '@elastic/charts/dist/theme_light.css';
import { useSelector } from 'react-redux';
import moment from 'moment';

const RevenueChart = (props) => {
    const themeMode = useSelector((state) => state.theme.theme);
    const euiChartTheme = themeMode === 'light' ? EUI_CHARTS_THEME_LIGHT : EUI_CHARTS_THEME_DARK;
    const languageId = useSelector((state) => state.language.languageId);

    const revenueChartFunction = (name) => {
        let revenueArray = [];
        if (props.outlet && props.outlet.revenue_sales_by_date) {
            props.outlet.revenue_sales_by_date.map((revenue) =>
                revenueArray.push({
                    x: moment(revenue.order_date).format('DD-MM-YYYY'),
                    y:
                        name === 'Sales'
                            ? revenue.sales
                                ? revenue.sales
                                : 0
                            : parseFloat(revenue.revenue ? revenue.revenue : 0),
                    g: name,
                })
            );
        }
        return revenueArray;
    };

    return (
        <EuiFlexGroup direction='column'>
            <EuiFlexItem>
                <EuiText>
                    <h3>Revenue Chart</h3>
                </EuiText>
            </EuiFlexItem>
            <EuiFlexItem>
                <Chart size={{ height: 150 }}>
                    <Settings
                        theme={euiChartTheme}
                        showLegend={true}
                        legendPosition='right'
                        showLegendDisplayValue={false}
                    />

                    <LineSeries
                        id='bars'
                        name='0'
                        data={revenueChartFunction('Revenue')}
                        xAccessor={'x'}
                        yAccessors={['y']}
                        splitSeriesAccessors={['g']}
                    />
                    <Axis id='bottom-axis' position='bottom' showGridLines />
                    <Axis id='left-axis' position='left' showGridLines />
                </Chart>
            </EuiFlexItem>
            <EuiSpacer size='s' />
            <EuiFlexItem>
                <Chart size={{ height: 150 }}>
                    <Settings
                        theme={euiChartTheme}
                        showLegend={true}
                        legendPosition='right'
                        showLegendDisplayValue={false}
                    />
                    <LineSeries
                        id='bars'
                        name='0'
                        data={revenueChartFunction('Sales')}
                        xAccessor={'x'}
                        yAccessors={['y']}
                        splitSeriesAccessors={['g']}
                    />
                    <Axis id='bottom-axis' position='bottom' showGridLines />
                    <Axis id='left-axis' position='left' showGridLines />
                </Chart>
            </EuiFlexItem>
        </EuiFlexGroup>
    );
};

export default memo(RevenueChart);
