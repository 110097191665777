/* global localStorage */
import React, { useEffect, useState } from 'react';
import './loginCardStyle.scss';
import {
    EuiButton,
    EuiCallOut,
    EuiFieldPassword,
    EuiFieldText,
    EuiForm,
    EuiFormRow,
    EuiImage,
    EuiLink,
    EuiPageContent,
    EuiPageContentBody,
    EuiSpacer,
    EuiText,
    EuiPanel,
    EuiFlexGroup,
    EuiFlexItem,
    // EuiTitle
} from '@elastic/eui';
import FabSystemsLogo from '../../../assets/img/FabSystemsLogo.png';
import PILOTLOGO from '../../../assets/img/PILOTLOGO.png';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import API from '../../../api/axios/API.js';
import { isEmpty } from 'lodash';

export const LoginCard = () => {
    const [userName, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [isUserError, setIsUserError] = useState(false);
    const [isPasswordError, setIsPasswordError] = useState(false);
    const [validCredentials, setValidCredentials] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [loginLoading, setLoginLoading] = useState(false);

    const firebaseToken = useSelector((state) => state.auth.firebaseToken);
    const dispatch = useDispatch();
    let history = useHistory();

    useEffect(() => {
        const listener = (event) => {
            if (event.code === 'Enter' || event.code === 'NumpadEnter') {
                console.log('Enter key was pressed. Run your function.');
            }
        };
        document.addEventListener('keydown', listener);

        return () => {
            document.removeEventListener('keydown', listener);
        };
    }, []);

    const onKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            event.stopPropagation();
            LoginSubmit();
        }
    };

    const LoginSubmit = async () => {
        if (userName.length === 0) {
            setIsUserError(true);
        } else {
            setIsUserError(false);
        }
        if (password.length === 0) {
            setIsPasswordError(true);
        } else {
            setIsPasswordError(false);
        }

        if (userName.length !== 0 && password.length !== 0) {
            setLoginLoading(true);
            setIsUserError(false);
            setIsPasswordError(false);
            try {
                // const loginResponse = await mutate({
                //     username: userName,
                //     password: password,
                //     device_id: localStorage.getItem('deviceId'),
                // });

                const loginResponse = await API.post('login', {
                    email: userName,
                    password,
                    device_id: localStorage.getItem('deviceId'),
                    platform: 'web_app',
                });

                dispatch({
                    type: 'set-auth',
                    payload: {
                        ...loginResponse.user,
                        accepting_orders: loginResponse.device.accepting_orders,
                    },
                });

                let loginResponseObj = loginResponse.user;
                loginResponseObj['accepting_orders'] = loginResponse.device.accepting_orders;

                localStorage.setItem('authToken', loginResponse.token);
                localStorage.setItem('user', JSON.stringify(loginResponseObj));

                try {
                    await API.put('/devices', {
                        device_id: localStorage.getItem('deviceId'),
                        platform: 'web_app',
                        token: firebaseToken,
                    });
                } catch (error) {
                    console.log('device not register error');
                }

                // Login is successful, now let's move to restaurant selection page.
                history.push('/select-restaurant');
            } catch (error) {
                if (!error.success) {
                    setValidCredentials(true);
                    setErrorMessage(error);
                }
            }
        }
        setLoginLoading(false);
    };

    // display error from server side
    const displayErrorServer = () => {
        let errorsList = [];

        if (!isEmpty(errorMessage) && errorMessage.errors) {
            for (var key in errorMessage.errors) {
                errorsList.push(
                    <>
                        <EuiCallOut title={`${errorMessage.errors[key]}`} color='danger' iconType='alert' />
                        <EuiSpacer />
                    </>
                );
            }
        } else {
            errorsList.push(
                <>
                    <EuiCallOut title={`${errorMessage.message}`} color='danger' iconType='alert' />
                    <EuiSpacer />
                </>
            );
        }

        return errorsList;
    };

    return (
        <>
            <EuiFlexGroup
                direction='row'
                justifyContent='center'
                gutterSize='none'
                style={{ maxHeight: '120px' }}
                alignItems='center'
            >
                <EuiFlexItem grow={false}>
                    <EuiImage size='m' alt='img' url={PILOTLOGO} />
                </EuiFlexItem>
            </EuiFlexGroup>
            <EuiSpacer />
            {validCredentials && displayErrorServer()}

            <EuiForm>
                <div className='login-container'>
                    <EuiPanel className='login-panel'>
                        <EuiFlexGroup direction='column' style={{ padding: '30px' }}>
                            <EuiFlexItem
                                style={{
                                    fontSize: '24px',
                                    fontWeight: '600',
                                    textAlign: 'center',
                                    whiteSpace: 'nowrap',
                                    paddingBottom: '18px',
                                }}
                            >
                                {/* <EuiTitle>Login</EuiTitle> */}
                                <b>Login</b>
                            </EuiFlexItem>

                            <EuiSpacer />

                            <EuiFormRow label='' isInvalid={isUserError} error={['Username is required']}>
                                <EuiFieldText
                                    style={{ borderRadius: '5px' }}
                                    name='first'
                                    icon='user'
                                    placeholder='Username'
                                    value={userName}
                                    onChange={(e) => setUserName(e.target.value)}
                                    isInvalid={isUserError}
                                />
                            </EuiFormRow>

                            <EuiSpacer />

                            <EuiFormRow label='' isInvalid={isPasswordError} error={['Password is required']}>
                                <EuiFieldPassword
                                    style={{ borderRadius: '5px' }}
                                    name='first'
                                    icon='lock'
                                    placeholder='Password'
                                    value={password}
                                    isInvalid={isPasswordError}
                                    onKeyDown={onKeyDown}
                                    onChange={(e) => setPassword(e.target.value)}
                                    // type={'dual'}
                                />
                            </EuiFormRow>

                            <EuiSpacer />
                            <EuiSpacer />

                            <EuiFormRow>
                                <EuiButton isLoading={loginLoading} fullWidth fill onClick={() => LoginSubmit()}>
                                    Login
                                </EuiButton>
                            </EuiFormRow>

                            {/* <EuiSpacer /> */}

                            <EuiFlexItem>
                                <EuiFormRow>
                                    <div style={{ textAlign: 'center' }}>
                                        <EuiLink>
                                            <EuiText>Forgot Password</EuiText>
                                        </EuiLink>
                                    </div>
                                </EuiFormRow>
                            </EuiFlexItem>
                        </EuiFlexGroup>
                        {/* isInvalid={false} error={['enter valid email']} */}
                    </EuiPanel>
                </div>
            </EuiForm>
        </>
    );
};
