import { EuiFlexItem } from '@elastic/eui';
import React, { Fragment } from 'react';
import TextAreaField from '../Form/TextAreaField';
import { useSelector } from 'react-redux';
import { useFormContext } from 'react-hook-form';

const TermsAndConditions = () => {
    const languages = useSelector((state) => state.language.availableLanguages);
    const {
        formState: { errors },
        control,
    } = useFormContext();

    return (
        <>
            <EuiFlexItem>
                {languages?.map((language, index) => (
                    <Fragment key={language.language_id}>
                        <TextAreaField
                            errors={errors}
                            isInvalid={
                                errors.translations &&
                                errors.translations.terms_and_conditions &&
                                errors.translations.terms_and_conditions.hasOwnProperty(language.language_id)
                            }
                            error={
                                errors.translations &&
                                errors.translations.terms_and_conditions &&
                                errors.translations.terms_and_conditions.hasOwnProperty(language.language_id) &&
                                errors.translations.terms_and_conditions[language.language_id]
                                    ? errors.translations.terms_and_conditions[language.language_id].message
                                    : ''
                            }
                            label={!index && `Terms and conditions`}
                            name={`translations.terms_and_conditions.${language.language_id}`}
                            placeholder={`Please Enter terms and conditions: ${language.language_label}`}
                            control={control}
                        />
                    </Fragment>
                ))}
            </EuiFlexItem>
        </>
    );
};

export default React.memo(TermsAndConditions);
