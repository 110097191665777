import React, { useEffect } from 'react';
import { EuiForm } from '@elastic/eui';
import { useForm } from 'react-hook-form';
import FormGroupDescription from '../../../components/formGroupDescription';
import TextField from '../../../components/Form/TextField';
import ButtonGroup from '../../../components/Form/ButtonGroup';
import { useDispatch, useSelector } from 'react-redux';
import { outletSettingsAPI } from '../../../api/outlet/outletSettingsAPI';
import * as types from '../../../reduxStore/types/outlet';
import { userHasPermission } from '../../../helper/auth';

const ThirdPartyPOS = (props) => {
    const { getOutletData, fetchOutletById } = props;
    const {
        handleSubmit,
        control,
        watch,
        reset,
        setValue,

        formState: { isDirty, errors },
    } = useForm();
    const dispatch = useDispatch();
    const permissions = useSelector((state) => state.permission.permission);

    useEffect(() => {
        if (!userHasPermission(permissions, '/outlet-settings', 'write')) return;

        if (isDirty) {
            dispatch({
                type: 'set-portal-active',
                payload: {
                    portalActive: {
                        resetChanges: resetChanges,
                        submit: handleSubmit(onSubmit),
                    },
                },
            });
        } else {
            dispatch({
                type: 'set-portal-active',
                payload: {
                    portalActive: {},
                },
            });
        }
    }, [isDirty]);

    useEffect(() => {
        if (getOutletData && getOutletData?.outlet?.outlet_third_party_menu_setting) {
            resetChanges();
        }
    }, [getOutletData]);

    const resetChanges = () => {
        let defaultValue = {};

        defaultValue.is_pet_pooja_order_assignment_enabled = getOutletData?.outlet?.outlet_third_party_menu_setting
            ?.is_pet_pooja_order_assignment_enabled
            ? 1
            : 0;
        defaultValue.is_pet_pooja_integration_enabled = getOutletData?.outlet?.outlet_third_party_menu_setting
            ?.is_pet_pooja_integration_enabled
            ? 1
            : 0;
        defaultValue.app_key = getOutletData.outlet.outlet_third_party_menu_setting.app_key;
        defaultValue.app_secret = getOutletData.outlet.outlet_third_party_menu_setting.app_secret;
        defaultValue.access_token = getOutletData.outlet.outlet_third_party_menu_setting.access_token;
        defaultValue.restaurant_id = getOutletData.outlet.outlet_third_party_menu_setting.restaurant_id;

        reset({
            ...defaultValue,
        });
    };

    const onSubmit = (data) => {
        // :todo
        dispatch(outletSettingsAPI('menu_settings', data)).then((res) => {
            if (res.success) {
                dispatch({
                    type: types.SET_PORTAL_ACTIVE,
                    payload: {
                        portalActive: {},
                    },
                });
                dispatch({
                    type: types.SET_PORTAL_ACTIVE_TAB_SWITCHED,
                    payload: {
                        tabSwitched: false,
                    },
                });
                fetchOutletById();
            } else {
                alert(res.message);
            }
        });
    };

    return (
        <>
            <EuiForm component='form' onSubmit={handleSubmit(onSubmit)}>
                <FormGroupDescription
                    title={'Enable Pet Pooja integration'}
                    description={'Enable to start integrating pej pooja menu'}
                >
                    <ButtonGroup
                        idSelected={watch('is_pet_pooja_integration_enabled') ? '1' : '0'}
                        color='primary'
                        control={control}
                        setValue={setValue}
                        name='is_pet_pooja_integration_enabled'
                        watch={watch}
                        label={'Third party pos enable'}
                    />
                </FormGroupDescription>

                {watch('is_pet_pooja_integration_enabled') ? (
                    <>
                        <FormGroupDescription title={'App key'} description={''}>
                            <TextField
                                errors={errors}
                                label='App Key'
                                name='app_key'
                                placeholder='Please enter app key'
                                rules={{ required: 'Please enter app key' }}
                                control={control}
                            />
                        </FormGroupDescription>

                        <FormGroupDescription title={'App secret'} description={''}>
                            <TextField
                                errors={errors}
                                label='App secret'
                                name='app_secret'
                                placeholder='Please enter app secret'
                                rules={{ required: 'Please enter app secret' }}
                                control={control}
                            />
                        </FormGroupDescription>

                        <FormGroupDescription title={'Access token'} description={''}>
                            <TextField
                                errors={errors}
                                label='Access token'
                                name='access_token'
                                placeholder='Please enter access token'
                                rules={{ required: 'Please enter access token' }}
                                control={control}
                            />
                        </FormGroupDescription>

                        <FormGroupDescription title={'Restaurant ID'} description={''}>
                            <TextField
                                errors={errors}
                                label='Restaurant ID'
                                name='restaurant_id'
                                placeholder='Please enter restaurant id'
                                rules={{ required: 'Please enter restaurant id' }}
                                control={control}
                            />
                        </FormGroupDescription>
                    </>
                ) : (
                    ''
                )}

                <FormGroupDescription
                    title={'Enable Pet Pooja orders'}
                    description={'Enable to start getting orders to pet pooja'}
                >
                    <ButtonGroup
                        idSelected={watch('is_pet_pooja_order_assignment_enabled') ? '1' : '0'}
                        color='primary'
                        control={control}
                        setValue={setValue}
                        name='is_pet_pooja_order_assignment_enabled'
                        watch={watch}
                        label={'Third party pos orders enable'}
                    />
                </FormGroupDescription>
            </EuiForm>
        </>
    );
};

export default ThirdPartyPOS;
