import React, { useCallback, useMemo, useState, memo } from 'react';
import {
    EuiText,
    EuiDataGrid,
    EuiLink,
    EuiContextMenuItem,
    EuiPopover,
    EuiPagination,
    EuiContextMenuPanel,
    EuiFlexItem,
    EuiFlexGroup,
    EuiButtonEmpty,
} from '@elastic/eui';
import moment from 'moment';

const OrderRatingTable = (props) => {
    const { orderList, activePage, rowSize } = props;

    const columns = [
        {
            id: 'name',
            display: 'Name',
            displayAsText: 'Name',
            isSortable: true,
        },
        {
            id: 'date',
            display: 'Date',
            displayAsText: 'Date',
            isSortable: true,
        },
        {
            id: 'order_id',
            display: 'Order ID',
            displayAsText: 'Order ID',
            isSortable: false,
        },
        {
            id: 'order_value',
            display: 'Order Value',
            displayAsText: 'Order Value',
        },
        {
            id: 'order_rating',
            display: 'Order Rating',
            displayAsText: 'Order Rating',
        },
        {
            id: 'rider_rating',
            display: 'Rider Rating',
            displayAsText: 'Rider Rating',
        },
    ];

    // const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 });

    // const onChangeItemsPerPage = useCallback(
    //     (pageSize) => {
    //         props.paginationCallback((pagination) => ({
    //             ...pagination,
    //             pageSize,
    //             pageIndex: 0,
    //         }));
    //     },
    //     [props.paginationCallback]
    // );

    // const onChangePage = useCallback(
    //     (pageIndex) => {
    //         props.paginationCallback((pagination) => ({ ...pagination, pageIndex }));
    //     },
    //     [setPagination]
    // );

    const [sortingColumns, setSortingColumns] = useState([]);

    const orderFlyoutOpen = (order) => {
        // setFlyoutOpen(true);
        props.flyoutCallback(true);
        // setSelectedOrder(order);
        props.selectedOrderCallback(order);
    };

    const onSort = useCallback(
        (sortingColumns) => {
            setSortingColumns(sortingColumns);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [setSortingColumns]
    );

    const [visibleColumns, setVisibleColumns] = useState(() => columns.map(({ id }) => id));

    let mappedItems =
        orderList &&
        orderList.order_collection &&
        orderList.order_collection.orders &&
        orderList.order_collection.orders.data.length > 0
            ? orderList.order_collection.orders.data.map((order) => {
                  return {
                      name: (
                          <>
                              {
                                  <EuiText key={order.order_id + 'name'} onClick={() => orderFlyoutOpen(order)}>
                                      {' '}
                                      <EuiLink> {order.customer.name} </EuiLink>{' '}
                                  </EuiText>
                              }
                          </>
                      ),
                      date: (
                          <>
                              {
                                  <EuiText key={order.outlet_id + 'date'}>
                                      {/* onClick={() => orderFlyoutOpen(order)} */}
                                      {order.status.placed_at &&
                                          moment(order.status.placed_at).format('DD-MM-YYYY hh:mm:ss A')}{' '}
                                  </EuiText>
                              }
                          </>
                      ),
                      order_id: <>{<EuiText key={order.order_id + 'order_id'}> {order.order_label}</EuiText>}</>,
                      order_value: (
                          <>{<EuiText key={order.order_id + 'order_value'}> {order.net_total.toFixed()}</EuiText>}</>
                      ),
                      order_rating: (
                          <>
                              {
                                  <EuiText key={order.order_id + 'order_rating'}>
                                      {' '}
                                      {order.feedback ? order.feedback.star.toFixed(2) : 'Not applicable'}
                                  </EuiText>
                              }
                          </>
                      ),
                      rider_rating: (
                          <>
                              {
                                  <EuiText key={order.order_id + 'rider_rating'}>
                                      {' '}
                                      {order.feedback ? order.feedback.delivery_star.toFixed(2) : 'Not applicable'}
                                  </EuiText>
                              }
                          </>
                      ),
                  };
              })
            : [];

    const renderCellValue = useMemo(() => {
        return ({ rowIndex, columnId, setCellProps }) => {
            return mappedItems.hasOwnProperty(rowIndex) ? mappedItems[rowIndex][columnId] : null;
        };
    }, [mappedItems]);

    const [isPopoverOpen, setIsPopoverOpen] = useState(false);
    const PAGE_COUNT = Math.ceil(orderList.order_collection.orders.total / rowSize);

    const onChangeRowSize = (row_size) => {
        // setActivePage(0);
        props.activePageCallback(0);
        // setRowSize(row_size);
        props.rowSizeCallback(row_size);
    };

    const onButtonClick = () => setIsPopoverOpen((isPopoverOpen) => !isPopoverOpen);
    const closePopover = () => setIsPopoverOpen(false);

    // const goToPage = (pageNumber) => setActivePage(pageNumber);
    const goToPage = (pageNumber) => props.activePageCallback(pageNumber);

    const getIconType = (size) => {
        return size === rowSize ? 'check' : 'empty';
    };

    const button = (
        <EuiButtonEmpty size='s' color='text' iconType='arrowDown' iconSide='right' onClick={onButtonClick}>
            Rows per page: {rowSize}
        </EuiButtonEmpty>
    );

    const items = [
        <EuiContextMenuItem
            key='10 rows'
            icon={getIconType(10)}
            onClick={() => {
                closePopover();
                onChangeRowSize(10);
            }}
        >
            10 rows
        </EuiContextMenuItem>,
        <EuiContextMenuItem
            key='20 rows'
            icon={getIconType(20)}
            onClick={() => {
                closePopover();
                onChangeRowSize(20);
            }}
        >
            20 rows
        </EuiContextMenuItem>,
        <EuiContextMenuItem
            key='50 rows'
            icon={getIconType(50)}
            onClick={() => {
                closePopover();
                onChangeRowSize(50);
            }}
        >
            50 rows
        </EuiContextMenuItem>,
        <EuiContextMenuItem
            key='100 rows'
            icon={getIconType(100)}
            onClick={() => {
                closePopover();
                onChangeRowSize(100);
            }}
        >
            100 rows
        </EuiContextMenuItem>,
    ];
    return (
        <>
            <EuiDataGrid
                aria-label='Order List Table'
                columns={columns}
                columnVisibility={{ visibleColumns, setVisibleColumns }}
                rowCount={mappedItems.length}
                // orderList && orderList.order_collection && orderList.order_collection.orders.data.length
                // orderList.customers.total
                // mappedItems.length
                inMemory={{ level: 'sorting' }}
                renderCellValue={renderCellValue}
                sorting={{ columns: sortingColumns, onSort }}
                // pagination={{
                //     ...pagination,
                //     pageSizeOptions: [10, 50, 100],
                //     onChangeItemsPerPage: onChangeItemsPerPage,
                //     onChangePage: onChangePage,
                // }}
            />
            <EuiFlexGroup justifyContent='spaceBetween' alignItems='center'>
                <EuiFlexItem grow={false}>
                    <EuiPopover
                        button={button}
                        isOpen={isPopoverOpen}
                        closePopover={closePopover}
                        panelPaddingSize='none'
                    >
                        <EuiContextMenuPanel items={items} />
                    </EuiPopover>
                </EuiFlexItem>

                <EuiFlexItem grow={false}>
                    <EuiPagination
                        aria-label='Custom pagination example'
                        pageCount={PAGE_COUNT}
                        // PAGE_COUNT
                        activePage={activePage}
                        onPageClick={goToPage}
                    />
                </EuiFlexItem>
            </EuiFlexGroup>
        </>
    );
};

export default memo(OrderRatingTable);
