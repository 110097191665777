import React from 'react';
import { EuiOverlayMask, EuiConfirmModal } from '@elastic/eui';

const RejectTableBookingModal = (props) => {
    const { bookingId, setRejectBooking, bookingStatusChange } = props;

    const cancelBooking = () => {
        setRejectBooking(false);
        bookingStatusChange(bookingId, 'rejected');
    };

    return (
        <EuiOverlayMask>
            <EuiConfirmModal
                title='Are you sure you want to cancel this booking?'
                onCancel={() => setRejectBooking(false)}
                onConfirm={cancelBooking}
                cancelButtonText="No, don't do it"
                confirmButtonText='Yes, do it'
                buttonColor='danger'
                defaultFocusedButton='cancel'
            >
                <p>You&rsquo;re about to reject this Booking.</p>
                <p>Are you sure you want to do this?</p>
            </EuiConfirmModal>
        </EuiOverlayMask>
    );
};

export default RejectTableBookingModal;
