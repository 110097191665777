import React, { Fragment, useEffect, useState, memo, useCallback } from 'react';
import OfferList from '../../../components/offer/offerList';
import Pagination from '../../../components/Form/Pagination';
import API from '../../../api/axios/API';
import { EuiLoadingContent, EuiSpacer } from '@elastic/eui';

const OfferTable = () => {
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 10,
    });

    const [refetchForOffer, setRefetchForOffer] = useState(false);
    const [appState, setAppState] = useState({
        loading: true,
        offersData: null,
        errorState: false,
        refetch: false,
    });

    const fetchOffers = useCallback(async () => {
        try {
            const offers = await API.get(
                `restaurants/:restaurantId/offers?page=${pagination.pageIndex + 1}&per_page=${pagination.pageSize}`
            );
            setAppState((preState) => ({
                ...preState,
                offersData: offers,
                loading: false,
            }));
            setAppState((prevState) => ({
                ...prevState,
                loadingForOffer: false,
                errorStateForOffer: false,
            }));
        } catch (e) {
            setAppState((preState) => ({
                ...preState,
                errorState: true,
                loading: false,
            }));
            setAppState((prevState) => ({
                ...prevState,
                loadingForOffer: true,
                errorStateForOffer: true,
            }));
        }
    }, [pagination.pageIndex, pagination.pageSize]);

    const handlePageChange = useCallback(
        (page) => {
            setPagination({
                ...pagination,
                pageIndex: page,
            });
        },
        [pagination]
    );

    useEffect(() => {
        fetchOffers();
    }, [refetchForOffer, pagination.pageIndex, fetchOffers]);

    if (appState.loading) return <EuiLoadingContent lines={4} />;
    if (appState.errorState) return 'Error';

    return (
        <Fragment>
            <OfferList
                allOffers={appState.offersData}
                setRefetchForOffer={setRefetchForOffer}
                fetchOffers={fetchOffers}
            />
            {appState.offersData.offers.total > appState.offersData.offers.per_page && (
                <Pagination
                    totalRecord={appState.offersData.offers.total}
                    pageSize={appState.offersData.offers.per_page}
                    activePage={appState.offersData.offers.current_page - 1}
                    handlePageChange={handlePageChange}
                />
            )}
        </Fragment>
    );
};

export default memo(OfferTable);
