import { EuiCheckbox, EuiFlexGroup, EuiFlexItem, EuiPanel, EuiSpacer, htmlIdGenerator } from '@elastic/eui';
import React, { useEffect, useState } from 'react';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { userHasPermission } from '../../../helper/auth';

const BannerLinkOutletList = ({ outlet, banner, loadingState, setBannerLinkStatus, bannerLinkStatus }) => {
    const [isOutletSelected, setIsOutletSelected] = useState(false);
    const permissions = useSelector((state) => state.permission.permission);
    const [selectedOutlet, setSelectedOutlet] = useState(null);

    useEffect(() => {
        if (banner.outlet?.length) {
            let linkedOutletList = [];
            for (const bannerOutlet of banner.outlet) {
                if (bannerOutlet.outlet_id === outlet.outlet_data.outlet_id) {
                    setIsOutletSelected(true);
                    linkedOutletList.push(bannerOutlet);
                }
            }
            setSelectedOutlet(linkedOutletList);
        }
    }, [banner, outlet.outlet_data.outlet_id]);

    const handleLinkChange = useCallback(
        (event, banner) => {
            const isChecked = event.target.checked;
            setIsOutletSelected(isChecked);

            if (isChecked) {
                const doesOutletExist = banner?.outlet?.filter(
                    (outletBanner) => outletBanner?.outlet_id === outlet?.outlet_data.outlet_id
                );

                if (!doesOutletExist?.length) {
                    setBannerLinkStatus((prevState) => ({
                        ...prevState,
                        link: [...prevState?.link, outlet?.outlet_data.outlet_id],
                    }));
                }
            } else {
                const checkedOutletLink = bannerLinkStatus.link.filter(
                    (outletId) => outletId !== outlet?.outlet_data.outlet_id
                );
                setBannerLinkStatus((prevState) => ({
                    ...prevState,
                    link: [...checkedOutletLink],
                }));
            }

            // Prepare the array for deletion
            if (isChecked) {
                const matchedModes = bannerLinkStatus.unlink?.filter((outletId) => {
                    return outletId !== outlet?.outlet_data.outlet_id;
                });
                setBannerLinkStatus((prevState) => ({
                    ...prevState,
                    unlink: [...matchedModes],
                }));
            } else {
                const matchedModes = banner?.outlet?.filter((bannerOutlet) => {
                    return bannerOutlet?.outlet_id === outlet?.outlet_data.outlet_id;
                })?.[0];

                if (matchedModes?.outlet_id) {
                    setBannerLinkStatus((prevState) => ({
                        ...prevState,
                        unlink: [...prevState.unlink, matchedModes.outlet_id],
                    }));
                } else {
                    setBannerLinkStatus((prevState) => ({
                        ...prevState,
                        unlink: [...prevState.unlink],
                    }));
                }
            }
        },
        [bannerLinkStatus.link, bannerLinkStatus.unlink, outlet, setBannerLinkStatus]
    );

    return (
        <>
            <EuiPanel className='custom' paddingSize='m'>
                <EuiFlexGroup>
                    <EuiFlexItem grow={false}>
                        {userHasPermission(permissions, '/banners', 'delete') && (
                            <EuiCheckbox
                                id={htmlIdGenerator()()}
                                label={outlet.inputDisplay}
                                checked={isOutletSelected}
                                // onChange={(e) => handleLinkChange(e, banner)}
                                onChange={(event) => handleLinkChange(event, banner)}
                                disabled={loadingState}
                            />
                        )}
                    </EuiFlexItem>
                </EuiFlexGroup>
            </EuiPanel>
            <EuiSpacer size='s' />
        </>
    );
};

export default React.memo(BannerLinkOutletList);
