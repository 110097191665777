import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { MainLayout } from '../../components/layout/mainLayout/mainLayout';
import useQuery from '../../utils/useQuery';
import OfferCreateEditForm from './Components/OfferCreateEditForm';

const OfferCreateEditPage = () => {
    let { offerId } = useParams();
    offerId = offerId ? Number(offerId) : null;

    const query = useQuery();
    const offerType = query.get('offerType');

    const offer = useSelector((state) => state.offersReducer.offers.find((item) => item.id === offerId));
    const [selectedOffer] = useState(offer);

    const history = useHistory();

    if (!offerType) {
        history.push('/offers');
    }

    return (
        <MainLayout
            backgroundColorChange={true}
            title={!selectedOffer ? 'Create an offer' : 'Modify an offer'}
            historyBack={true}
        >
            <OfferCreateEditForm offer={selectedOffer} selectedOfferType={offerType} />
        </MainLayout>
    );
};

export default OfferCreateEditPage;
