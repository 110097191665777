import { EuiFlexGroup, EuiFlexItem, EuiForm, EuiButtonGroup, EuiHorizontalRule } from '@elastic/eui';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import StepWizard from 'react-step-wizard';
import CustomerBasicInfo from './customerBasicInfo';
import CustomerAdditionalInfo from './customerAdditionalInfo';
import TakeawayOrderList from './takeawayOrderList';
import DeliveryOrderList from './deliveryOrderList';
import MapComponent from '../mapComponent';
import { useSelector, useDispatch } from 'react-redux';
import * as types from '../../reduxStore/types/billing';
import TableAssign from './tableAssign';
import { isEmpty } from 'lodash';
import OrderList from './orderList';
import { occupyTable } from '../../api/billing/occupyTable';
import { createCustomer } from '../../api/customer/createCustomer';
import { editCustomer } from '../../api/customer/editCustomer';

const ValueSet = (setValue, name, value) => {
    setValue(name, value);
};

const CustomerInformation = (props) => {
    const { outletId } = props;
    let defaultLatLong = { lat: 22.303894, lng: 70.802162 };
    const {
        register,
        handleSubmit,
        control,
        watch,
        reset,
        setValue,

        formState: { errors },
    } = useForm({
        defaultValues: { ...defaultLatLong },
    });

    const mode = useSelector((state) => state.billing.mode);
    const customerInformation = useSelector((state) => state.billing.customerInformation);
    const tableSelectedList = useSelector((state) => state.billing.tableSelectedList);
    const billingItem = useSelector((state) => state.billing.billingItem);
    const [buttonClicked, setButtonClicked] = useState('');
    const previousOrders = useSelector((state) => state.billing.previousOrders);
    const addNew = useSelector((state) => state.billing.addNew);
    const outletList = useSelector((state) => state.outlet.outletList);
    const selectedOutletId = useSelector((state) => state.outlet.selectedOutletId);
    const dispatch = useDispatch();
    const [toggleButtons, setToogleButtons] = useState([
        { id: 'takeaway', label: 'Takeaway' },
        { id: 'delivery', label: 'Delivery' },
    ]);

    const [toggleIdSelected, setToggleIdSelected] = useState(toggleButtons[0].id);

    useEffect(() => {
        if (outletList.length) {
            outletList.map((outlet) => {
                if (outlet.value == selectedOutletId) {
                    let innerArray = [];

                    // if(outlet.outlet_data.settings.is_fine_dine_enabled){
                    innerArray.push({ id: 'dine_in', label: 'Fine Dine' });
                    // }

                    // if(outlet.outlet_data.settings.is_qsr_enabled){
                    innerArray.push({ id: 'qsr', label: 'QSR' });
                    // }

                    if (innerArray.length) {
                        setToogleButtons([...innerArray, ...toggleButtons]);
                        setToggleIdSelected(innerArray[0].id);
                    }
                }
            });
        }
    }, [selectedOutletId]);

    useEffect(() => {
        if (!isEmpty(customerInformation)) {
            reset({
                ...customerInformation,
                mobile_number: `${customerInformation.country_code}${customerInformation.mobile_number}`,
            });
        }
    }, [mode]);

    const onChangeSetValue = useCallback((name, value) => {
        ValueSet(setValue, name, value);
    }, []);

    const onSubmit = (data) => {
        console.log('inside on submit ', mode);
        if (buttonClicked === 'continue') return;

        if (mode === 'dine_in' && tableSelectedList.length < 1) {
            // alert("Please select atleat one table")
        } else {
            console.log('inside on submit else ', mode);
            if (mode === 'dine_in') {
                customerInformationApi(data);
            } else {
                customerInformationApiForTakeaway(data);
            }
        }
    };

    const customerInformationApiForTakeaway = (data) => {
        console.log('inside takeaway ', addNew, 'data', data);
        if (addNew) {
            if (!data.id) {
                dispatch(
                    createCustomer({
                        ...data,
                        country_code: data.selectedCountryNumber.dialCode,
                        mobile_number: data.selectedCountryNumber.number,
                    })
                ).then((res) => {
                    if (res.success) {
                        dispatch({
                            type: types.SET_CUSTOMER_INFORMATION_FINISH,
                            payload: {
                                finishCustomerInfo: true,
                            },
                        });

                        dispatch({
                            type: types.SET_CUSTOMER_INFORMATION,
                            payload: {
                                customerInformation: {
                                    ...data,
                                    id: res.customer.id,
                                    mobile_number: data.selectedCountryNumber
                                        ? data.selectedCountryNumber.number
                                        : data.mobile_number,
                                    country_code: data.selectedCountryNumber
                                        ? data.selectedCountryNumber.dialCode
                                        : '91',
                                },
                            },
                        });
                    } else {
                        alert(res.message);
                    }
                });
            } else {
                dispatch({
                    type: types.SET_CUSTOMER_INFORMATION_FINISH,
                    payload: {
                        finishCustomerInfo: true,
                    },
                });

                dispatch({
                    type: types.SET_CUSTOMER_INFORMATION,
                    payload: {
                        customerInformation: {
                            ...data,
                            mobile_number: data.selectedCountryNumber
                                ? data.selectedCountryNumber.number
                                : data.mobile_number,
                            country_code: data.selectedCountryNumber ? data.selectedCountryNumber.dialCode : '91',
                        },
                    },
                });
            }
        } else if (!addNew && customerInformation.id) {
            dispatch(editCustomer(customerInformation.id, { ...data })).then((res) => {
                if (res.success) {
                    dispatch({
                        type: types.SET_CUSTOMER_INFORMATION_FINISH,
                        payload: {
                            finishCustomerInfo: true,
                        },
                    });

                    dispatch({
                        type: types.SET_CUSTOMER_INFORMATION,
                        payload: {
                            customerInformation: {
                                ...data,
                                id: res.customer.id,
                                mobile_number: customerInformation.mobile_number,
                                country_code: customerInformation.country_code,
                            },
                        },
                    });
                } else {
                    alert(res.message);
                }
            });
        }
    };

    const customerInformationApi = (data) => {
        dispatch(
            occupyTable(outletId, tableSelectedList[0], data, isEmpty(customerInformation) ? 'create' : 'update')
        ).then((res) => {
            if (res.success) {
                dispatch({
                    type: types.SET_CUSTOMER_INFORMATION_FINISH,
                    payload: {
                        finishCustomerInfo: true,
                    },
                });

                dispatch({
                    type: types.SET_CUSTOMER_INFORMATION,
                    payload: {
                        customerInformation: {
                            ...data,
                            mobile_number: data.selectedCountryNumber
                                ? data.selectedCountryNumber.number
                                : data.mobile_number,
                            country_code: data.selectedCountryNumber ? data.selectedCountryNumber.dialCode : '91',
                        },
                    },
                });
            } else {
                alert(res.message);
            }
        });
    };

    const onChangeMode = (id) => {
        setToggleIdSelected(id);
        dispatch({
            type: types.SET_MODE,
            payload: {
                mode: id,
            },
        });
    };

    return (
        <EuiForm component='form' onSubmit={handleSubmit(onSubmit)}>
            <EuiFlexGroup>
                <EuiFlexItem>
                    <EuiButtonGroup
                        options={toggleButtons}
                        idSelected={toggleIdSelected}
                        onChange={(id) => onChangeMode(id)}
                        isFullWidth
                        color='primary'
                    />
                </EuiFlexItem>
            </EuiFlexGroup>
            <EuiHorizontalRule margin='l' />
            <EuiFlexGroup direction='column' className='customerInformation'>
                <EuiFlexItem>
                    <StepWizard
                        transitions={{
                            enterRight: '',
                            enterLeft: '',
                            exitRight: '',
                            exitLeft: '',
                        }}
                        initialStep={1}
                    >
                        {mode === 'dine_in' && (
                            <TableAssign
                                reset={reset}
                                errors={errors}
                                control={control}
                                watch={watch}
                                outletId={outletId}
                                setButtonClicked={setButtonClicked}
                            />
                        )}

                        {mode === 'qsr' && (
                            <OrderList
                                ordersType={'qsr'}
                                reset={reset}
                                errors={errors}
                                control={control}
                                watch={watch}
                                outletId={outletId}
                                setButtonClicked={setButtonClicked}
                            />
                        )}

                        {mode === 'takeaway' && (
                            // <TakeawayOrderList errors={errors} control={control} watch={watch} outletId={outletId} setButtonClicked={setButtonClicked}/>
                            <OrderList
                                ordersType={'takeaway'}
                                reset={reset}
                                errors={errors}
                                control={control}
                                watch={watch}
                                outletId={outletId}
                                setButtonClicked={setButtonClicked}
                            />
                        )}

                        {mode === 'delivery' && (
                            // <DeliveryOrderList reset={reset} errors={errors} control={control} watch={watch} outletId={outletId} setButtonClicked={setButtonClicked}/>
                            <OrderList
                                ordersType={'delivery'}
                                reset={reset}
                                errors={errors}
                                control={control}
                                watch={watch}
                                outletId={outletId}
                                setButtonClicked={setButtonClicked}
                            />
                        )}

                        <CustomerBasicInfo
                            errors={errors}
                            control={control}
                            watch={watch}
                            reset={reset}
                            setValue={setValue}
                            setButtonClicked={setButtonClicked}
                        />
                        {/* <CustomerAdditionalInfo
                            errors={errors}
                            control={control}
                            watch={watch}
                            reset={reset}
                            setValue={setValue}
                            setButtonClicked={setButtonClicked}
                        /> */}
                        {mode === 'delivery' && (
                            <MapComponent
                                defaultLatLong={defaultLatLong}
                                setValue={onChangeSetValue}
                                errors={errors}
                                control={control}
                                watch={watch}
                                setButtonClicked={setButtonClicked}
                            />
                        )}
                    </StepWizard>
                </EuiFlexItem>
            </EuiFlexGroup>
        </EuiForm>
    );
};

export default CustomerInformation;
