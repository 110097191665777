import React, { useRef, useEffect, useState } from 'react';
import {
    EuiFocusTrap,
    EuiNavDrawer,
    // EuiSideNav,
    EuiPage,
    EuiPageBody,
    EuiPageContent,
    EuiPageContentBody,
    EuiText,
    EuiFlexItem,
    EuiHorizontalRule,
    EuiSelect,
    EuiButton,
    EuiImage,
    EuiSuperSelect,
    EuiPopover,
} from '@elastic/eui';
import { Header } from '../header';
import { LeftSideBar } from '../leftSideBar';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import * as types from '../../../reduxStore/types/outlet';
import * as typesBilling from '../../../reduxStore/types/billing';
import { isEmpty } from 'lodash';
import { ReactComponent as BackArrow } from '../../../assets/img/backArrow.svg';
import PILOTLOGO from '../../../assets/img/PILOTLOGO.png';
import API from '../../../api/axios/API';
import '../../../styles/style.css';

export const MainLayout = (props) => {
    const navDrawerRef = useRef(null);
    const history = useHistory();
    const dispatch = useDispatch();
    const languageId = useSelector((state) => state.language.languageId);
    const outletList = useSelector((state) => state.outlet.outletList);
    const selectedOutletId = useSelector((state) => state.outlet.selectedOutletId);
    const portalActive = useSelector((state) => state.outlet.portalActive);
    const themeMode = useSelector((state) => state.theme.theme);
    const creditAlerts = useSelector((state) => state.creditAlerts.alerts);

    useEffect(() => {
        if (
            (history.location.pathname === '/billing' || history.location.pathname === '/billing_copy') &&
            navDrawerRef &&
            navDrawerRef.current
        ) {
            navDrawerRef.current.collapseDrawer();
        }
    }, [history]);

    useEffect(() => {
        if (navDrawerRef && navDrawerRef.current) {
            navDrawerRef.current.collapseDrawer();
        }
    }, []);

    // expandDrawer
    const onMouseInside = () => {
        if (navDrawerRef && navDrawerRef.current && !navDrawerRef.current.state.isLocked) {
            navDrawerRef.current.expandDrawer();
        }
    };

    const onMouseLeave = () => {
        if (navDrawerRef && navDrawerRef.current && !navDrawerRef.current.state.isLocked) {
            navDrawerRef.current.collapseDrawer();
        }
    };

    useEffect(() => {
        let outletArray = [];
        outletList.map((outlet) => {
            outletArray.push({
                value: outlet.value,
                inputDisplay: outlet.outlet_data.translations.name[languageId],
                outlet_data: outlet.outlet_data,
            });
        });

        dispatch({
            type: types.SET_OUTLET_LIST,
            payload: {
                outletList: outletArray,
            },
        });
    }, [languageId]);

    const onChangeOutlet = (value) => {
        let outlet = outletList.find((outlet) => outlet.value == value);

        if (!isEmpty(portalActive)) {
            dispatch({
                type: 'set-portal-active-tab-switched',
                payload: {
                    tabSwitched: true,
                },
            });
        } else {
            dispatch({
                type: types.SET_SELECTED_OUTLET_ID,
                payload: {
                    selectedOutletId: value,
                },
            });

            dispatch({
                type: types.SET_SELECTED_OUTLET,
                payload: {
                    selectedOutlet: outlet,
                },
            });
        }
    };

    const onCustomOutletChange = (value) => {
        if (!isEmpty(portalActive)) {
            dispatch({
                type: 'set-portal-active-tab-switched',
                payload: {
                    tabSwitched: true,
                },
            });
        } else {
            let outlet = outletList.find((outlet) => outlet.value == value);
            if (outlet === undefined && value === 'all_outlet') {
                outlet = {
                    value: 'all_outlet',
                    inputDisplay: 'All Outlets',
                    outlet_data: null,
                };
            }

            dispatch({
                type: types.SET_SELECTED_OUTLET_ID,
                payload: {
                    selectedOutletId: value,
                },
            });

            dispatch({
                type: types.SET_SELECTED_OUTLET,
                payload: {
                    selectedOutlet: outlet,
                },
            });
        }
    };

    const onClear = () => {
        dispatch({
            type: typesBilling.SET_RESET_STATE,
        });
    };

    const historyBack = () => {
        if (!isEmpty(portalActive)) {
            dispatch({
                type: 'set-portal-active-tab-switched',
                payload: {
                    tabSwitched: true,
                },
            });
        } else {
            history.goBack();
        }
    };

    const onHoldModal = () => {
        dispatch({
            type: typesBilling.SET_HOLD_ORDER_MODAL,
            payload: {
                holdOrderModal: true,
            },
        });
    };

    return (
        <>
            <EuiFocusTrap>
                <Header navDrawerRef={navDrawerRef} />
                <EuiPage
                    style={{
                        backgroundColor:
                            props.backgroundColorChange && themeMode === 'light'
                                ? 'rgba(244, 244, 244, 1)'
                                : themeMode === 'light'
                                ? '#ffffff'
                                : '#1a1b20',
                        width: '100%',
                        overflowX: 'hidden',
                        height: '100vh',
                        maxHeight: props.isSingleScreen ? '100vh' : 'auto',
                        overflowY: props.isSingleScreen ? 'hidden' : 'visible',
                    }}
                    className='scrollbar'
                >
                    <EuiPageBody
                        style={{
                            paddingTop:
                                creditAlerts.low_credit_outlets_message && creditAlerts.low_credit_restaurants_message
                                    ? '150px'
                                    : creditAlerts.low_credit_outlets_message
                                    ? '100px'
                                    : creditAlerts.low_credit_restaurants_message
                                    ? '100px'
                                    : '50px',
                        }}
                    >
                        {/* Page title */}
                        {props.title && (
                            <EuiFlexItem style={{ padding: '0 20px' }}>
                                <EuiFlexItem
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                    }}
                                >
                                    <EuiFlexItem
                                        style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
                                    >
                                        {/* Pos back button */}
                                        {props.backButton && (
                                            <EuiFlexItem
                                                style={{ marginRight: '10px', cursor: 'pointer' }}
                                                onClick={onClear}
                                            >
                                                <BackArrow />
                                            </EuiFlexItem>
                                        )}

                                        {props.historyBack && (
                                            <EuiFlexItem
                                                style={{ marginRight: '10px', cursor: 'pointer' }}
                                                onClick={historyBack}
                                            >
                                                <BackArrow />
                                            </EuiFlexItem>
                                        )}

                                        <EuiText>
                                            <h2>
                                                <b>{props.title}</b>
                                            </h2>
                                        </EuiText>
                                    </EuiFlexItem>

                                    {/* Oultet dropdown */}

                                    {props?.allowAllOutletsSelection && outletList?.length >= 2 ? (
                                        <EuiFlexItem style={{ display: 'flex', flexDirection: 'row' }}>
                                            {props.holdButton ? (
                                                <EuiFlexItem style={{ marginRight: '15px' }}>
                                                    <EuiButton onClick={onHoldModal} fill size='s'>
                                                        On Hold
                                                    </EuiButton>
                                                </EuiFlexItem>
                                            ) : (
                                                ''
                                            )}
                                            <EuiPopover
                                                button={
                                                    <EuiSuperSelect
                                                        style={{ width: '250px' }}
                                                        options={[
                                                            {
                                                                inputDisplay: 'All Outlets',
                                                                value: 'all_outlet',
                                                            },
                                                            ...outletList,
                                                        ]}
                                                        valueOfSelected={selectedOutletId ? selectedOutletId : ''}
                                                        onChange={onCustomOutletChange}
                                                    />
                                                }
                                                isOpen={!selectedOutletId}
                                                closePopover={() => {}}
                                                // focusTrapProps="clickOutsideDisables"
                                            >
                                                <EuiText>
                                                    <p>select store from here</p>
                                                </EuiText>
                                            </EuiPopover>
                                        </EuiFlexItem>
                                    ) : (
                                        props.outlet && (
                                            <EuiFlexItem style={{ display: 'flex', flexDirection: 'row' }}>
                                                {props.holdButton ? (
                                                    <EuiFlexItem style={{ marginRight: '15px' }}>
                                                        <EuiButton onClick={onHoldModal} fill size='s'>
                                                            On Hold
                                                        </EuiButton>
                                                    </EuiFlexItem>
                                                ) : (
                                                    ''
                                                )}
                                                <EuiPopover
                                                    button={
                                                        <EuiSuperSelect
                                                            style={{ width: '250px' }}
                                                            options={outletList}
                                                            valueOfSelected={selectedOutletId ? selectedOutletId : ''}
                                                            onChange={onChangeOutlet}
                                                        />
                                                    }
                                                    isOpen={!selectedOutletId}
                                                    closePopover={() => {}}
                                                    // focusTrapProps="clickOutsideDisables"
                                                >
                                                    <EuiText>
                                                        <p>select store from here</p>
                                                    </EuiText>
                                                </EuiPopover>
                                            </EuiFlexItem>
                                        )
                                    )}
                                </EuiFlexItem>
                                <EuiHorizontalRule margin='xs' />
                            </EuiFlexItem>
                        )}

                        <EuiPageContentBody style={{ padding: '12px 1.5%' }}>
                            <>{props.children}</>
                        </EuiPageContentBody>
                    </EuiPageBody>
                </EuiPage>
            </EuiFocusTrap>
        </>
    );
};
