import React, { useEffect, memo, useCallback, useState } from 'react';
import { EuiFlexGroup, EuiFlexItem, EuiCard, EuiButton } from '@elastic/eui';
import { AddonGroupMainFlyout } from '../addOnGroup/AddonGroupMainFlyout';
import MenuList from '../catalogue/menuList';
import ItemDetailsEditor from '../../../modules/Menu/Components/Catalogue/ItemDetails/ItemDetailsEditor';
import { useSelector, useDispatch, batch } from 'react-redux';
import * as type from '../../../reduxStore/types/menu';
import './style.css';
import MenuSearch from './menuSearch';
import { SET_ADDON_MAIN_FLYOUT } from '../../../reduxStore/types/addon';

const MenuEditorTab = () => {
    const dispatch = useDispatch();
    const activeItemId = useSelector((state) => state.menu.activeItemId);
    const activeCategoryId = useSelector((state) => state.menu.activeCategoryId);
    const itemDetails = useSelector((state) => state.menuReducer.activeItemDetails.details);
    const categories = useSelector((state) => state.menuReducer.restaurantCategories);
    const isOutletMenu = useSelector((state) => state.menuReducer.isOutletMenu);
    const [state, setState] = useState({
        isOpen: false,
    });

    let hasOneCategoryAndOneItem = useCallback(() => {
        return categories?.length && categories[0].items?.length;
    }, [categories]);

    let hasOneSubcategoryAndOneItem = useCallback(() => {
        return (
            categories?.length && categories[0].sub_categories?.length && categories[0].sub_categories[0].items?.length
        );
    }, [categories]);

    useEffect(() => {
        let selectedActiveCategoryId = null;
        let selectedActiveItemId = null;

        if (hasOneCategoryAndOneItem()) {
            if (!activeCategoryId) selectedActiveCategoryId = categories.categories[0].category_id;
            if (!activeItemId) selectedActiveItemId = categories.categories[0].items[0].item_id;
        } else if (hasOneSubcategoryAndOneItem()) {
            if (!activeCategoryId) selectedActiveCategoryId = categories?.sub_categories[0].sub_category_id;
            if (!activeItemId) selectedActiveItemId = categories?.sub_categories[0].items[0].item_id;
        }

        batch(() => {
            dispatch({
                type: type.SET_MENU_LIST,
                payload: {
                    menuList: categories,
                },
            });
            dispatch({
                type: type.SET_ACTIVE_CATEGORY_ID,
                payload: {
                    activeCategoryId: selectedActiveCategoryId,
                },
            });

            dispatch({
                type: type.SET_ACTIVE_ITEM_ID,
                payload: {
                    activeItemId: selectedActiveItemId,
                },
            });
        });
    }, [categories]);

    const closeFlyout = useCallback(() => {
        setState({
            ...state,
            isOpen: false,
            selectedGroup: 0,
        });
        dispatch({
            type: SET_ADDON_MAIN_FLYOUT,
            payload: {
                addOnMainflyout: false,
            },
        });
    }, [dispatch, state]);

    const handleAddonFlyout = useCallback(() => {
        setState({ ...state, isOpen: !state.isOpen });
    }, [setState, state]);

    return (
        <>
            {state.isOpen ? <AddonGroupMainFlyout closeFlyout={closeFlyout} state={state} /> : null}
            {/* grow={2} style={{ width: '95%', overflow: 'hidden' }} */}
            {/* Above styles were the issue behind overflowed scroll view */}
            <EuiFlexItem grow={2} style={{ width: '95%', overflow: 'hidden' }}>
                {/* Top Header on catelog page that has search component and addon group button with flyout. */}
                {!isOutletMenu ? (
                    <>
                        <EuiFlexGroup>
                            <EuiFlexItem style={{ minWidth: '350px' }}>
                                <MenuSearch />
                            </EuiFlexItem>
                            <EuiFlexItem grow={false}>
                                <EuiButton onClick={handleAddonFlyout} iconType='indexEdit' aria-label='any'>
                                    Addon Groups
                                </EuiButton>
                            </EuiFlexItem>
                        </EuiFlexGroup>
                    </>
                ) : null}

                <EuiFlexItem style={!isOutletMenu ? { paddingTop: '20px' } : {}}>
                    <EuiFlexGroup className='display_style'>
                        <EuiFlexItem
                            style={{ height: '75%', overflow: 'auto', paddingBottom: '6%' }}
                            className='remove-scrollbar'
                        >
                            <MenuList />
                        </EuiFlexItem>

                        <EuiFlexItem className='item_details' style={{ height: '75%' }}>
                            <EuiFlexItem className='menuStyle remove-scrollbar'>
                                {itemDetails ? <ItemDetailsEditor /> : <EuiCard hasBorder title='Select an Item' />}
                            </EuiFlexItem>
                        </EuiFlexItem>
                    </EuiFlexGroup>
                </EuiFlexItem>
            </EuiFlexItem>
        </>
    );
};
export default memo(MenuEditorTab);
