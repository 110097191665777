import React, { useMemo, useState, memo } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
    EuiText,
    EuiDataGrid,
    EuiLink,
    EuiContextMenuItem,
    EuiPopover,
    EuiPagination,
    EuiContextMenuPanel,
    EuiFlexItem,
    EuiFlexGroup,
    EuiButtonEmpty,
} from '@elastic/eui';
import * as types from '../../reduxStore/types/customer';
import moment from 'moment';
import RupeeSymbol from '../rupeeComponent.js';
import { numberFormat } from '../../utils/numberFormat';

const CustomerDataGrid = (props) => {
    // const { getAllCustomer, pagination, setPagination } = props;
    const { getAllCustomer, activePage, rowSize } = props;

    console.log('getAllCustomer', getAllCustomer);

    const history = useHistory();
    const dispatch = useDispatch();

    const columns = [
        {
            id: 'name',
            display: 'Name',
            displayAsText: 'Name',
            isSortable: true,
        },
        {
            id: 'contact_number',
            display: 'Contact Number',
            displayAsText: 'Contact Number',
            isSortable: false,
        },
        {
            id: 'email',
            display: 'Email',
            displayAsText: 'Email',
            isSortable: false,
        },
        {
            id: 'total_orders',
            display: 'Total Orders',
            displayAsText: 'Total Orders',
        },
        {
            id: 'first_order_date',
            display: 'First Order Date',
            displayAsText: 'First Order Date',
        },
        {
            id: 'last_order_date',
            display: 'Last Order Date',
            displayAsText: 'Last Order Date',
        },
        {
            id: 'join_date',
            display: 'Join Date',
            displayAsText: 'Join Date',
        },
        {
            id: 'life_time_value',
            display: 'Life Time value',
            displayAsText: 'Life Time value',
        },
        {
            id: 'order_ratings',
            display: 'Order Ratings',
            displayAsText: 'Order Ratings',
        },
        {
            id: 'delivery_ratings',
            display: 'Delivery Ratings',
            displayAsText: 'Delivery Ratings',
        },
    ];

    // const [sortingColumns, setSortingColumns] = useState([]);

    const customeOrderRedirect = (customer) => {
        dispatch({
            type: types.SET_CUSTOMER_DETAILS,
            payload: {
                customerDetails: customer,
            },
        });
        history.push('/customer-orders');
    };

    // const onSort = useCallback(
    //     (sortingColumns) => {
    //         setSortingColumns(sortingColumns);
    //     },
    //     [setSortingColumns]
    // );

    const [visibleColumns, setVisibleColumns] = useState(() => columns.map(({ id }) => id));

    let mappedItems =
        getAllCustomer && getAllCustomer.customers && getAllCustomer.customers.data.length > 0
            ? getAllCustomer.customers.data.map((item) => {
                  return {
                      name: (
                          <>
                              {
                                  <EuiText key={item.id + 'name'} onClick={() => customeOrderRedirect(item)}>
                                      <EuiLink> {item.name} </EuiLink>{' '}
                                  </EuiText>
                              }
                          </>
                      ),
                      contact_number: (
                          <>
                              {
                                  <EuiText key={item.id + 'contact_number'} onClick={() => customeOrderRedirect(item)}>
                                      {' '}
                                      <EuiLink> {item.mobile_number} </EuiLink>{' '}
                                  </EuiText>
                              }
                          </>
                      ),
                      email: (
                          <>
                              {item.email ? (
                                  <EuiText key={item.id + 'email'} onClick={() => customeOrderRedirect(item)}>
                                      {' '}
                                      <EuiLink> {item.email} </EuiLink>{' '}
                                  </EuiText>
                              ) : (
                                  '--'
                              )}
                          </>
                      ),
                      total_orders: <>{<EuiText key={item.id + 'total_orders'}> {item.order_count}</EuiText>}</>,
                      first_order_date: (
                          <>
                              {
                                  <EuiText key={item.id + 'first_order_date'}>
                                      {' '}
                                      {item.first_order
                                          ? moment(item.first_order).format('DD-MM-YYYY hh:mm:ss A')
                                          : '--'}
                                  </EuiText>
                              }
                          </>
                      ),
                      last_order_date: (
                          <>
                              {
                                  <EuiText key={item.id + 'last_order_date'}>
                                      {' '}
                                      {item.last_order ? moment(item.last_order).format('DD-MM-YYYY hh:mm:ss A') : '--'}
                                  </EuiText>
                              }
                          </>
                      ),
                      join_date: (
                          <>
                              {
                                  <EuiText key={item.id + 'join_date'}>
                                      {' '}
                                      {item.join_date ? moment(item.join_date).format('DD-MM-YYYY hh:mm:ss A') : '--'}
                                  </EuiText>
                              }
                          </>
                      ),

                      life_time_value: (
                          <>
                              {
                                  <EuiText key={item.id + 'life_time_value'}>
                                      {item.life_time_value ? (
                                          <>
                                              <RupeeSymbol size='16' />
                                              {numberFormat(item.life_time_value)}
                                          </>
                                      ) : (
                                          <>
                                              <RupeeSymbol size='16' />
                                              <span>0.00</span>
                                          </>
                                      )}
                                  </EuiText>
                              }
                          </>
                      ),
                      order_ratings: (
                          <>
                              {
                                  <EuiText key={item.id + 'order_ratings'}>
                                      {' '}
                                      {item.order_ratings ? parseInt(item.order_ratings).toFixed(2) : '--'}
                                  </EuiText>
                              }
                          </>
                      ),
                      delivery_ratings: (
                          <>
                              {
                                  <EuiText key={item.id + 'delivery_ratings'}>
                                      {' '}
                                      {item.delivery_ratings ? parseInt(item.delivery_ratings).toFixed(2) : '--'}
                                  </EuiText>
                              }
                          </>
                      ),
                  };
              })
            : [];
    const renderCellValue = useMemo(() => {
        return ({ rowIndex, columnId, setCellProps }) => {
            return mappedItems.hasOwnProperty(rowIndex) ? mappedItems[rowIndex][columnId] : null;
        };
    }, [mappedItems]);

    console.log('length mappedItems', mappedItems);

    const [isPopoverOpen, setIsPopoverOpen] = useState(false);
    // const [activePage, setActivePage] = useState(0);
    // const [rowSize, setRowSize] = useState(10);

    const PAGE_COUNT = getAllCustomer && Math.ceil(getAllCustomer.customers.total / rowSize);

    const onChangeRowSize = (row_size) => {
        // setActivePage(0);
        props.activePageCallback(0);
        // setRowSize(row_size);
        props.rowSizeCallback(row_size);
    };

    const onButtonClick = () => setIsPopoverOpen((isPopoverOpen) => !isPopoverOpen);
    const closePopover = () => setIsPopoverOpen(false);

    // const goToPage = (pageNumber) => setActivePage(pageNumber);
    const goToPage = (pageNumber) => props.activePageCallback(pageNumber);

    const getIconType = (size) => {
        return size === rowSize ? 'check' : 'empty';
    };

    const button = (
        <EuiButtonEmpty size='s' color='text' iconType='arrowDown' iconSide='right' onClick={onButtonClick}>
            Rows per page: {rowSize}
        </EuiButtonEmpty>
    );

    const items = [
        <EuiContextMenuItem
            key='10 rows'
            icon={getIconType(10)}
            onClick={() => {
                closePopover();
                onChangeRowSize(10);
            }}
        >
            10 rows
        </EuiContextMenuItem>,
        <EuiContextMenuItem
            key='20 rows'
            icon={getIconType(20)}
            onClick={() => {
                closePopover();
                onChangeRowSize(20);
            }}
        >
            20 rows
        </EuiContextMenuItem>,
        <EuiContextMenuItem
            key='50 rows'
            icon={getIconType(50)}
            onClick={() => {
                closePopover();
                onChangeRowSize(50);
            }}
        >
            50 rows
        </EuiContextMenuItem>,
        <EuiContextMenuItem
            key='100 rows'
            icon={getIconType(100)}
            onClick={() => {
                closePopover();
                onChangeRowSize(100);
            }}
        >
            100 rows
        </EuiContextMenuItem>,
    ];

    return (
        <>
            <EuiDataGrid
                aria-label='Customer List Datagrid'
                columns={columns}
                columnVisibility={{ visibleColumns, setVisibleColumns }}
                rowCount={mappedItems.length}
                // inMemory={{ level: 'sorting' }}
                renderCellValue={renderCellValue}
                // sorting={{ columns: sortingColumns, onSort }}
            />
            <EuiFlexGroup justifyContent='spaceBetween' alignItems='center'>
                <EuiFlexItem grow={false}>
                    <EuiPopover
                        button={button}
                        isOpen={isPopoverOpen}
                        closePopover={closePopover}
                        panelPaddingSize='none'
                    >
                        <EuiContextMenuPanel items={items} />
                    </EuiPopover>
                </EuiFlexItem>

                <EuiFlexItem grow={false}>
                    <EuiPagination
                        aria-label='Custom pagination example'
                        pageCount={PAGE_COUNT}
                        // PAGE_COUNT
                        activePage={activePage}
                        onPageClick={goToPage}
                    />
                </EuiFlexItem>
            </EuiFlexGroup>
        </>
    );
};
export default memo(CustomerDataGrid);
