import { EuiFlexGroup, EuiFlexItem, EuiSpacer } from '@elastic/eui';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { fetchCustomerPayments } from '../../api/BillPayments/fetchCustomerPayments';
import BillPaymentTable from '../../components/BillPayments/BillPaymentTable';
import CustomDatePicker from '../../components/Credits/CustomDatePicker';
import { MainLayout } from '../../components/layout/mainLayout';
import { NoOutletMessage } from '../../utils/noOutletMessage';

const BillPaymentPage = () => {
    const dispatch = useDispatch();
    const [records, setRecords] = useState({});
    const [startDate, setStartDate] = useState(moment());
    const [endDate, setEndDate] = useState(moment());
    const selectedOutletId = useSelector((state) => state.outlet.selectedOutletId);

    const fetchPayments = useCallback(
        async (startDate, endDate, currentPage) => {
            await dispatch(fetchCustomerPayments(startDate, endDate, currentPage, selectedOutletId)).then(
                (response) => {
                    if (response.success) {
                        setRecords(response.customer_bill_payments);
                    }
                }
            );
        },
        [dispatch, selectedOutletId]
    );

    useEffect(() => {
        if (selectedOutletId) {
            fetchPayments(startDate, endDate, 1);
        }
    }, [endDate, fetchPayments, startDate, selectedOutletId]);

    return (
        <MainLayout title='Bill Payments' outlet={true} isSingleScreen={true} backgroundColorChange={true}>
            {!selectedOutletId ? (
                <NoOutletMessage />
            ) : (
                <>
                    <EuiFlexGroup justifyContent='spaceBetween' alignItems='center'>
                        <EuiFlexItem grow={false}>
                            <CustomDatePicker
                                startDate={startDate}
                                setStartDate={setStartDate}
                                endDate={endDate}
                                setEndDate={setEndDate}
                            />
                        </EuiFlexItem>
                    </EuiFlexGroup>

                    <EuiSpacer />
                    <BillPaymentTable
                        records={records}
                        fetchPayments={fetchPayments}
                        startDate={startDate}
                        endDate={endDate}
                    />
                </>
            )}
        </MainLayout>
    );
};

export default BillPaymentPage;
