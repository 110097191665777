import React from 'react';
import { EuiFlexItem, EuiText, EuiButtonEmpty, EuiLoadingContent } from '@elastic/eui';
import { useSelector } from 'react-redux';
import { userHasPermission } from '../../../helper/auth';
import CategoryList from './CategoryList';
import { useHistory } from 'react-router';
import './style.css';

const MenuList = () => {
    const permissions = useSelector((state) => state.permission.permission);
    const themeMode = useSelector((state) => state.theme.theme);
    const history = useHistory();
    const isOutletMenu = useSelector((state) => state.menuReducer.isOutletMenu);

    return (
        <EuiFlexItem>
            <EuiFlexItem
                grow={false}
                style={{
                    backgroundColor: themeMode === 'light' ? '#f4f6f9' : 'black',
                    justifyContent: 'space-between',
                    padding: '0 12px',
                    alignItems: 'center',
                    alignContent: 'center',
                    flexDirection: 'row',
                    marginBottom: '5px',
                    height: '40px',
                }}
            >
                <EuiText>
                    <span>Menu </span>
                </EuiText>

                <EuiText size='s'>
                    {userHasPermission(permissions, '/menu', 'write') && !isOutletMenu && (
                        <EuiButtonEmpty
                            size='s'
                            style={{ background: 'transparent' }}
                            onClick={() => history.push('/category?category=add')}
                        >
                            ADD NEW
                        </EuiButtonEmpty>
                    )}
                </EuiText>
            </EuiFlexItem>

            <CategoryList isOutletMenu={isOutletMenu} />
        </EuiFlexItem>
    );
};

export default MenuList;
