import { EuiText, EuiIcon, EuiCard, EuiFlexItem, EuiSpacer, EuiButton } from '@elastic/eui';
import React, { useState } from 'react';
import { userHasPermission } from '../../helper/auth';
import { useSelector, useDispatch } from 'react-redux';
import { getPastOrders } from '../../api/pastOrder/getPastOrders';
import { refundAmount } from '../../api/pastOrder/refundAmount';

const RefundStatusCard = (props) => {
    const { selectedOrder } = props;
    const [refundLoader, setRefundLoader] = useState(false);
    const permissions = useSelector((state) => state.permission.permission);
    const currentOrderingMode = useSelector((state) => state.orders.currentOrderingMode);
    const dispatch = useDispatch();

    const refundAmountAPI = () => {
        setRefundLoader(true);
        dispatch(refundAmount(selectedOrder.order_id)).then((response) => {
            if (response.success) {
                dispatch(getPastOrders(props.startDateTime, props.endDateTime, props.state, currentOrderingMode));
                setRefundLoader(false);
                return;
            }
            setRefundLoader(false);
            alert(response.message);
        });
    };

    const refundCard = () => {
        const isRefundFailed = selectedOrder?.refunds?.filter((refund) => refund.failed_at);
        const isInitiated = selectedOrder?.refunds?.filter((refund) => refund.initiated_at);
        const isRefundSucceeded = selectedOrder?.refunds?.filter((refund) => refund.refunded_at);

        if (isRefundSucceeded?.length) {
            return (
                <>
                    <EuiCard
                        display='plain'
                        layout='horizontal'
                        paddingSize='s'
                        style={{ backgroundColor: '#74decef2' }}
                        title={
                            <EuiFlexItem style={{ alignItems: 'center', flexDirection: 'row' }}>
                                <EuiIcon color='#347e73' type='alert' />
                                <div style={{ marginLeft: '10px', color: '#347e73' }}>
                                    <span>Refund Succeeded</span>
                                </div>
                            </EuiFlexItem>
                        }
                        description='Refund process completed successfully.'
                    />
                    <EuiSpacer />
                </>
            );
        } else if (isInitiated?.length && !isRefundFailed?.length && !isRefundSucceeded?.length) {
            return (
                <>
                    <EuiCard
                        display='plain'
                        layout='horizontal'
                        paddingSize='s'
                        style={{ backgroundColor: '#F5A700' }}
                        title={
                            <EuiFlexItem style={{ alignItems: 'center', flexDirection: 'row' }}>
                                <EuiIcon color='#FFFFF0' type='alert' />
                                <div style={{ marginLeft: '10px', color: '#FFFFF0' }}>
                                    <span>Refund Inititated</span>
                                </div>
                            </EuiFlexItem>
                        }
                        description='Refund process inititated successfully.'
                    />
                    <EuiSpacer />
                </>
            );
        } else if (isRefundFailed?.length) {
            return (
                <>
                    {' '}
                    <EuiCard
                        display='plain'
                        layout='horizontal'
                        paddingSize='s'
                        style={{ backgroundColor: '#ff00002e' }}
                        title={
                            <EuiFlexItem style={{ alignItems: 'center', flexDirection: 'row' }}>
                                <EuiIcon color='rgb(189, 39, 30)' type='alert' />
                                <div style={{ marginLeft: '10px', color: 'rgb(189, 39, 30)' }}>
                                    <span>Refund Failed</span>
                                </div>
                            </EuiFlexItem>
                        }
                        description={
                            <>
                                <EuiText size='s'>
                                    Refund process couldn't able to complete. Please retry refund.
                                </EuiText>
                                <EuiSpacer size='s' />
                                {userHasPermission(permissions, '/past-orders', 'delete') && props.isPast && (
                                    <EuiFlexItem>
                                        <EuiButton fill size='s' onClick={refundAmountAPI} isLoading={refundLoader}>
                                            Retry
                                        </EuiButton>
                                    </EuiFlexItem>
                                )}
                            </>
                        }
                    />
                </>
            );
        }
    };

    return <>{selectedOrder?.refunds?.length ? refundCard() : ''}</>;
};

export default RefundStatusCard;
