import {
    EuiButton,
    EuiDragDropContext,
    euiDragDropReorder,
    EuiDroppable,
    EuiFlexGroup,
    EuiFlexItem,
    EuiFormRow,
    EuiSpacer,
    EuiText,
} from '@elastic/eui';
import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import UpSellingFlyout from '../../../components/menuComponentsRefactorCopy/catalogue/UpSellingFlyout';
import UpSellingItemCard from '../../../components/menuComponentsRefactorCopy/menuEditorTab/UpSellingItemCard';
import {
    REMOVE_UPSELLING_ITEM_EDIT_SELECTED_LIST,
    SET_UPSELLING_ITEM_EDIT_SELECTED_LIST,
} from '../../../reduxStore/types/menu';
import { ReactComponent as UpSellingItemIcon } from '../../../assets/img/svg/UpSellingItemIcon.svg';
import ItemDetailsCollaspibleNav from './Catalogue/ItemDetails/ItemDetailsCollaspibleNav';

const ItemUpSellingLinks = () => {
    const dispatch = useDispatch();
    const [isNavOpen, setIsNavOpen] = useState(false);
    const isOutletMenu = useSelector((state) => state.menuReducer.isOutletMenu);
    const [flyoutState, setFlyoutState] = useState({ isOpen: false, action: null });
    const selectedItemEditUpsellingList = useSelector((state) => state.menu.selectedItemEditUpsellingList);
    const removedItemEditUpsellingList = useSelector((state) => state.menu.removedItemEditUpsellingList);

    const handleUpSellingDragEnd = useCallback(
        ({ source, destination }) => {
            if (source && destination) {
                const items = euiDragDropReorder(selectedItemEditUpsellingList, source.index, destination.index);
                dispatch({
                    type: SET_UPSELLING_ITEM_EDIT_SELECTED_LIST,
                    payload: {
                        selectedItemEditUpsellingList: items,
                    },
                });
            }
        },
        [dispatch, selectedItemEditUpsellingList]
    );

    const handleUpSellingItemDeletionAction = useCallback(
        (index, item) => {
            let upSellingItemArray = [...selectedItemEditUpsellingList];
            upSellingItemArray.splice(index, 1);

            dispatch({
                type: REMOVE_UPSELLING_ITEM_EDIT_SELECTED_LIST,
                payload: {
                    removedItemEditUpsellingList: [
                        ...removedItemEditUpsellingList,
                        item?.item_id ? item?.item_id : item?.id ? item.id : item.id,
                    ],
                },
            });

            dispatch({
                type: SET_UPSELLING_ITEM_EDIT_SELECTED_LIST,
                payload: {
                    selectedItemEditUpsellingList: upSellingItemArray,
                },
            });
        },
        [dispatch, removedItemEditUpsellingList, selectedItemEditUpsellingList]
    );

    return (
        <>
            <ItemDetailsCollaspibleNav
                cardTitle='Up-Selling Items'
                cardDescription='Add an upselling item to the selected item'
                isNavOpen={isNavOpen}
                setIsNavOpen={setIsNavOpen}
                toggleText='Edit Item Pricing'
                icon={<UpSellingItemIcon />}
            >
                <EuiFlexGroup gutterSize='none' direction='column'>
                    <EuiFlexItem>
                        <UpSellingFlyout flyoutState={flyoutState} setFlyoutState={setFlyoutState} />
                        <EuiFormRow label='Add Up-Selling Items' fullWidth={true}>
                            <EuiButton
                                iconType={'popout'}
                                size='s'
                                iconSize='s'
                                onClick={() => setFlyoutState({ isOpen: true, action: 'upselling' })}
                            >
                                Select Up-Selling Items
                            </EuiButton>
                        </EuiFormRow>
                    </EuiFlexItem>

                    <EuiSpacer size='s' />

                    <EuiFlexItem
                        style={{
                            flexDirection: 'row',
                            overflowY: 'auto',
                            width: '100%',
                        }}
                        className='scrollbar-sm'
                    >
                        <EuiDragDropContext onDragEnd={handleUpSellingDragEnd}>
                            <EuiDroppable
                                droppableId='CUSTOM_HANDLE_DROPPABLE_AREA'
                                spacing='m'
                                withPanel
                                direction='horizontal'
                                style={{
                                    display: 'flex',
                                    width: '35vw',
                                    heigth: '200px',
                                    overflow: 'auto',
                                }}
                            >
                                {selectedItemEditUpsellingList?.length ? (
                                    <UpSellingItemCard
                                        itemList={selectedItemEditUpsellingList}
                                        onItemDelete={handleUpSellingItemDeletionAction}
                                        disabled={isOutletMenu}
                                    />
                                ) : (
                                    <EuiText> No Up-Selling Item Found</EuiText>
                                )}
                            </EuiDroppable>
                        </EuiDragDropContext>
                    </EuiFlexItem>
                </EuiFlexGroup>
            </ItemDetailsCollaspibleNav>
        </>
    );
};

export default React.memo(ItemUpSellingLinks);
