import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
    EuiButton,
    EuiFlyout,
    EuiFlyoutHeader,
    EuiTitle,
    EuiFlyoutBody,
    EuiFlyoutFooter,
    EuiFlexGroup,
    EuiFlexItem,
    EuiButtonEmpty,
    EuiPanel,
    EuiText,
    EuiSpacer,
    EuiDragDropContext,
    EuiDroppable,
    euiDragDropReorder,
    EuiConfirmModal,
} from '@elastic/eui';
import AddOnGroupList from './addOnGroupList';
import AddOnChoiceList from './addOnChoiceList';
import { useSelector, useDispatch } from 'react-redux';
import { AddOnAddGroupFlyout } from './addOnAddGroupFlyout';
import * as types from '../../../reduxStore/types/addon';
import * as typesMenu from '../../../reduxStore/types/menu';
import LoadingOverlay from 'react-loading-overlay';
import API from '../../../api/axios/API';
import { userHasPermission } from '../../../helper/auth';
import AddonLinkFlyout from './AddonLinkFlyout';

const ShowAddNewAddonFlyoutFunction = (value, setShowAddNewAddonFlyout) => {
    setShowAddNewAddonFlyout(value);
};

export const AddonGroupMainFlyout = ({ state, closeFlyout }) => {
    const [apiFetchState, setApiFetchState] = useState(true);
    const [addOnList, setAddOnList] = useState({});
    const [addonLinkFlyout, setAddonLinkFlyout] = useState({
        addon: null,
        display: false,
    });

    const addonState = useSelector((state) => state.menu.addonState);
    const dispatch = useDispatch();

    const fetchAddonRecords = useCallback(async () => {
        let response = '';
        try {
            response = await API.get(`/restaurants/:restaurantId/restaurant-addon-groups`);
        } catch (error) {
            response = error;
        }

        if (response.success) {
            setAddOnList(response);
        } else {
            alert('Error in Addon fetch');
        }
    }, []);

    useEffect(() => {
        fetchAddonRecords();
    }, [fetchAddonRecords, apiFetchState]);

    useEffect(() => {
        return function cleanup() {
            dispatch({
                type: typesMenu.SET_ADDON_STATE,
                payload: {
                    addonState: !addonState,
                },
            });
        };
    }, [dispatch, addonState]);

    const [choicesList, setChoicesList] = useState([]);

    const [addOnGroupSelected, setAddOnGroupSelected] = useState(false);

    const [editAddonObject, setEditAddonObject] = useState({});

    const permissions = useSelector((state) => state.permission.permission);
    const addOnMainflyout = useSelector((state) => state.addon.addOnMainflyout);
    const [showDeleteConfirmationModal, setShowDeleteConformationModal] = useState(false);

    const [showAddNewAddonFlyout, setShowAddNewAddonFlyout] = useState(false);

    const showAddNewAddonFlyoutCallback = useCallback((value) => {
        ShowAddNewAddonFlyoutFunction(value, setShowAddNewAddonFlyout);
    }, []);

    const [selectedGroupIdForDeletion, setgroupIdForDeletion] = useState();

    const [loaderOverlay, setLoaderOverlay] = useState(false);

    const onDragEnd = useCallback(
        async ({ source, destination }) => {
            if (source && destination) {
                setLoaderOverlay(true);
                const items = euiDragDropReorder(choicesList, source.index, destination.index);

                const response = await API.patch(
                    `/restaurants/:restaurantId/restaurant-addon-groups/${addOnGroupSelected}/change-sequence`,
                    {
                        choices: items,
                    }
                );

                if (response.success) {
                    setChoicesList([...items]);
                    setApiFetchState((prevState) => !prevState);
                    fetchAddonRecords();
                }
                setLoaderOverlay(false);
            }
        },
        [addOnGroupSelected, choicesList, fetchAddonRecords]
    );

    const onChangeSelectedGroup = useCallback(
        (id) => {
            setAddOnGroupSelected(id);

            addOnList.restaurant_addon_groups.map((addOn) => {
                if (addOn.addon_group_id === id && addOn.choices.length !== 0) {
                    setChoicesList(addOn.choices);
                }

                return null;
            });
        },
        [addOnList.restaurant_addon_groups]
    );

    const onDeleteGroup = useCallback((id) => {
        setShowDeleteConformationModal(true);
        setgroupIdForDeletion(id);
    }, []);

    const AddNewAddOn = useCallback(() => {
        setShowAddNewAddonFlyout(true);
    }, []);

    const onConfirmDeleteAddonGroup = useCallback(
        async (id) => {
            const response = await API.delete(
                `/restaurants/:restaurantId/restaurant-addon-groups/${selectedGroupIdForDeletion}`
            );
            setApiFetchState((prevState) => !prevState);

            if (response.success && addOnGroupSelected === id) {
                setAddOnGroupSelected(false);
                setChoicesList([]);
            }
            setShowDeleteConformationModal(false);
        },
        [addOnGroupSelected, selectedGroupIdForDeletion]
    );

    // Fly out contains info of selected addon group
    let flyout = useMemo(() => {
        return (
            <AddOnAddGroupFlyout
                isOpen={showAddNewAddonFlyout}
                setIsOpen={setShowAddNewAddonFlyout}
                apiFetch={fetchAddonRecords}
                // choicesCount={choicesCount}
                addOnList={addOnList}
                defaultValue={editAddonObject}
                setEditAddonObject={setEditAddonObject}
                setApiFetchState={setApiFetchState}
            />
        );
    }, [addOnList, editAddonObject, showAddNewAddonFlyout, fetchAddonRecords]);

    const availableLanguages = useSelector((state) => state.language.availableLanguages);
    const onEditGroup = (id) => {
        let defaultValue = {};
        addOnList.restaurant_addon_groups.map((addon) => {
            if (id === addon.addon_group_id) {
                availableLanguages.map((language, index) => {
                    defaultValue[`title${language.language_id}`] = addon.translations.group_name[language.language_id];
                    defaultValue[`description${language.language_id}`] =
                        addon.translations.description[language.language_id];
                });

                defaultValue['maximum'] = addon.max_addons;
                defaultValue['minimum'] = addon.min_addons;
                defaultValue['internal_name'] = addon.internal_name;
                defaultValue['show_agmark'] = addon.show_agmark;

                if (addon.choices !== undefined && addon.choices.length > 0) {
                    defaultValue['choices'] = [];
                    addon.choices.map((choice) => {
                        let value = {};
                        availableLanguages.map(
                            (language, i) =>
                                (value[`choice_title${language.language_id}`] =
                                    choice.translations.name[language.language_id])
                        );
                        value[`choice_id`] = choice.choice_id;
                        value[`price`] = choice.ordering_modes[0].price;
                        value[`compare_price`] = choice.ordering_modes[0].compare_price;
                        value[`gst_slab`] = choice.ordering_modes[0].gst_slab;
                        value[`food_type`] = choice.food_type;
                        value[`show_agmark`] = choice.show_agmark;
                        value[`image`] = choice.image_url;

                        // Handle Pirce and Expose Changes
                        if (choice?.ordering_modes?.length) {
                            const same_price = choice.ordering_modes[0].price;
                            const same_compare_price = choice.ordering_modes[0].compare_price;
                            const same_gst_slab = choice.ordering_modes[0].gst_slab;
                            for (const orderingMode of choice.ordering_modes) {
                                let isEnabled = [];
                                let isDisabled;
                                // Handle Prices

                                value[`price_${orderingMode.restaurant_ordering_mode_id}`] = orderingMode.price;
                                value[`gst_slab_${orderingMode.restaurant_ordering_mode_id}`] = orderingMode.gst_slab
                                    ? orderingMode.gst_slab
                                    : 0;

                                value[`compare_price_${orderingMode.restaurant_ordering_mode_id}`] =
                                    orderingMode.compare_price ? orderingMode.compare_price : 0;

                                // handle checked marked on Expose
                                value[`${orderingMode.restaurant_ordering_mode_id}`] = true;

                                // Handle "Select Different Price" checkbox state
                                if (
                                    same_price == orderingMode.price &&
                                    same_gst_slab == orderingMode.gst_slab &&
                                    same_compare_price == orderingMode.compare_price
                                ) {
                                    isEnabled.push('enabled');
                                } else {
                                    isEnabled.push('disabled');
                                }
                                isDisabled = isEnabled?.find((enabled) => enabled === 'disabled') === 'disabled';
                                value['is_enabled_same_price_details_for_all_ordering_modes'] = isDisabled
                                    ? true
                                    : false;
                            }
                        }

                        value['ordering_modes'] = choice.ordering_modes;
                        defaultValue['choices'].push(value);

                        return null;
                    });
                }
            }

            return null;
        });
        setEditAddonObject(defaultValue);

        dispatch({
            type: types.SET_ADDON_EDIT_ID,
            payload: {
                addonEditId: id,
            },
        });
        setShowAddNewAddonFlyout(true);
    };

    const [choiceDeleteConfirmationModal, setChoiceDeleteConfirmationModal] = useState(false);
    const [choiceDeleteId, setChoiceDeleteId] = useState('');

    const choiceDeleteIdCallback = useCallback((value) => {
        setChoiceDeleteId(value);
    }, []);

    const choiceDeleteConfirmModalCallback = useCallback((value) => {
        setChoiceDeleteConfirmationModal(value);
    }, []);

    const onChoiceConfirmationDeleteModal = async () => {
        if (choiceDeleteId) {
            await API.delete(`/restaurants/:restaurantId/restaurant-addon-group-choice/${choiceDeleteId}`);

            setApiFetchState((prevState) => !prevState);

            setChoiceDeleteConfirmationModal(false);
        }
    };

    const toggleFlyout = useCallback((addon) => {
        setAddonLinkFlyout((prevState) => ({
            display: !prevState.display,
            addon: addon ? addon : null,
        }));
    }, []);

    return (
        <>
            {addonLinkFlyout.display ? (
                <AddonLinkFlyout
                    addonLinkFlyout={addonLinkFlyout}
                    toggleFlyout={toggleFlyout}
                    fetchAddonRecords={fetchAddonRecords}
                />
            ) : null}
            {showDeleteConfirmationModal && (
                <EuiConfirmModal
                    style={{
                        position: 'fixed',
                        top: '35%',
                        left: '35%',
                        marginTop: '-50px',
                        marginLeft: '-50px',
                        zIndex: 10000,
                    }}
                    title='Are you sure you want to delete this group?'
                    onCancel={() => setShowDeleteConformationModal(false)}
                    onConfirm={onConfirmDeleteAddonGroup}
                    cancelButtonText="No, don't do it"
                    confirmButtonText='Yes, do it'
                    buttonColor='danger'
                    defaultFocusedButton='cancel'
                >
                    <p>You&rsquo;re about to delete this group</p>
                    <p>Are you sure you want to do this?</p>
                </EuiConfirmModal>
            )}

            {choiceDeleteConfirmationModal && (
                <EuiConfirmModal
                    style={{
                        position: 'fixed',
                        top: '35%',
                        left: '35%',
                        marginTop: '-50px',
                        marginLeft: '-50px',
                        zIndex: 10000,
                    }}
                    title='Are you sure you want to delete this choice?'
                    onCancel={() => setChoiceDeleteConfirmationModal(false)}
                    onConfirm={onChoiceConfirmationDeleteModal}
                    cancelButtonText="No, don't do it"
                    confirmButtonText='Yes, do it'
                    buttonColor='danger'
                    defaultFocusedButton='cancel'
                >
                    <p>You&rsquo;re about to delete this choice</p>
                    <p>Are you sure you want to do this?</p>
                </EuiConfirmModal>
            )}

            {showAddNewAddonFlyout && flyout}

            {(state.isOpen || addOnMainflyout) && (
                <EuiFlyout
                    onClose={closeFlyout}
                    hideCloseButton
                    aria-labelledby='flyoutComplicatedTitle'
                    className='full-screen-flyout'
                    style={{ zIndex: 6500 }}
                    headerzindexlocation='below'
                >
                    <EuiFlyoutHeader hasBorder>
                        <EuiTitle size='m'>
                            <h2 id='flyoutComplicatedTitle'>Manage Add on Group</h2>
                        </EuiTitle>
                    </EuiFlyoutHeader>
                    <EuiFlyoutBody style={{ overflowY: 'initial' }}>
                        <EuiFlexGroup>
                            <EuiFlexItem>
                                <div>
                                    <EuiPanel paddingSize='s'>
                                        <EuiFlexGroup responsive={false} justifyContent='spaceBetween'>
                                            <EuiFlexItem grow={false}>
                                                <EuiText>
                                                    <h3>Addon groups</h3>
                                                </EuiText>
                                            </EuiFlexItem>
                                            <EuiFlexItem grow={false}>
                                                {userHasPermission(permissions, '/menu', 'write') && (
                                                    <EuiButton
                                                        onClick={AddNewAddOn}
                                                        iconType='plusInCircleFilled'
                                                        aria-label='any'
                                                        size='s'
                                                    >
                                                        Add New
                                                    </EuiButton>
                                                )}
                                            </EuiFlexItem>
                                        </EuiFlexGroup>
                                    </EuiPanel>
                                    <EuiSpacer size='s' />
                                    <EuiPanel
                                        className='scrollbar'
                                        paddingSize='s'
                                        style={{ maxHeight: '63vh', overflow: 'auto' }}
                                    >
                                        <EuiFlexItem style={{ overflow: 'auto' }}>
                                            <AddOnGroupList
                                                // isLoading={isLoading}
                                                // error={error}
                                                toggleFlyout={toggleFlyout}
                                                addOnList={addOnList}
                                                selectedGroup={addOnGroupSelected}
                                                onChangeSelectedGroup={onChangeSelectedGroup}
                                                deleteGroup={onDeleteGroup}
                                                isOpen={showAddNewAddonFlyout}
                                                setIsOpen={showAddNewAddonFlyoutCallback}
                                                editGroup={onEditGroup}
                                                // defaultValue={editAddonObject}
                                            />
                                        </EuiFlexItem>
                                    </EuiPanel>
                                </div>
                            </EuiFlexItem>
                            <EuiFlexItem>
                                <div>
                                    <EuiPanel paddingSize='s'>
                                        <EuiFlexGroup responsive={false} justifyContent='spaceBetween'>
                                            <EuiFlexItem grow={false}>
                                                <EuiText>
                                                    <h3>Addon Choices</h3>
                                                </EuiText>
                                            </EuiFlexItem>
                                            <EuiFlexItem grow={false}>
                                                {userHasPermission(permissions, '/menu', 'write') && (
                                                    <EuiButton
                                                        onClick={AddNewAddOn}
                                                        iconType='plusInCircleFilled'
                                                        aria-label='any'
                                                        size='s'
                                                    >
                                                        Add New
                                                    </EuiButton>
                                                )}
                                            </EuiFlexItem>
                                        </EuiFlexGroup>
                                    </EuiPanel>

                                    <EuiSpacer size='s' />
                                    <EuiDragDropContext onDragEnd={onDragEnd}>
                                        <EuiDroppable droppableId='CUSTOM_HANDLE_DROPPABLE_AREA' spacing='m' withPanel>
                                            <LoadingOverlay active={loaderOverlay} spinner text='Re-arranging sequence'>
                                                <AddOnChoiceList
                                                    setChoiceDeleteId={choiceDeleteIdCallback}
                                                    setChoiceDeleteConfirmationModal={choiceDeleteConfirmModalCallback}
                                                    choicesList={choicesList}
                                                    selectedGroup={addOnGroupSelected}
                                                    addOnList={addOnList}
                                                />
                                            </LoadingOverlay>
                                        </EuiDroppable>
                                    </EuiDragDropContext>
                                </div>
                            </EuiFlexItem>
                        </EuiFlexGroup>
                    </EuiFlyoutBody>
                    <EuiFlyoutFooter>
                        <EuiFlexGroup justifyContent='spaceBetween'>
                            <EuiFlexItem grow={false}>
                                <EuiButtonEmpty iconType='cross' onClick={closeFlyout} flush='left'>
                                    Close
                                </EuiButtonEmpty>
                            </EuiFlexItem>
                            <EuiFlexItem grow={false} />
                        </EuiFlexGroup>
                    </EuiFlyoutFooter>
                </EuiFlyout>
            )}
        </>
    );
};
