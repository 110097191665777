import {
    EuiButton,
    EuiFlexItem,
    EuiFieldText,
    EuiPanel,
    EuiFormRow,
    EuiFieldNumber,
    EuiForm,
    EuiButtonEmpty,
    EuiOverlayMask,
    EuiConfirmModal,
    EuiModal,
    EuiModalHeader,
    EuiModalHeaderTitle,
    EuiModalBody,
    EuiModalFooter,
    EuiButtonIcon,
    EuiSpacer,
    EuiCheckbox,
    htmlIdGenerator,
    EuiHorizontalRule,
    EuiBadge,
} from '@elastic/eui';
import React, { useState, useEffect, useRef, useCallback, memo } from 'react';
import BillingSectionItem from './billingSectionItem';
import { useSelector, useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';
import * as types from '../../reduxStore/types/billing';
import { useForm, Controller } from 'react-hook-form';
import OrderSplitModal from './orderSplitModal';
import API from '../../api/axios/API';
import MapModal from './mapModal';
import DiscountFlyout from './discountFlyout';
import BillingSectionButtonList from './billingSectionButtonList';
import PaymentSection from './paymentSection';
import { ReactComponent as TableSVG } from '../../assets/img/tableSVG.svg';
import { ReactComponent as Users } from '../../assets/img/users.svg';
import { ReactComponent as Mobile } from '../../assets/img/mobile.svg';
import { ReactComponent as PickUp } from '../../assets/img/pickUp.svg';
import { ReactComponent as Moped } from '../../assets/img/moped.svg';
import CopyTableModal from './copyTableModal';
import { generateOTP } from '../../api/generateOTP';
import { getErroMessage } from '../../helper/getErrorMessage';
import { capitalize } from '../../helper/capitalize';
import moment from 'moment';

const ValueSet = (setValue, name, value) => {
    setValue(name, value);
};

const BillingSection = (props) => {
    const { outletId } = props;

    let defaultLatLong = { lat: 22.303894, lng: 70.802162 };

    const {
        register,
        handleSubmit,
        control,
        watch,
        reset,
        setValue,

        formState: { errors },
    } = useForm({
        defaultValues: { latitude: defaultLatLong.lat, longitude: defaultLatLong.lng },
    });

    const [paymentOption, setPaymentOption] = useState([]);
    const [paymentOptionSelected, setPaymentOptionSelected] = useState('');

    const billingItem = useSelector((state) => state.billing.billingItem);
    const billingItemCartStructure = useSelector((state) => state.billing.billingItemCartStructure);
    const mode = useSelector((state) => state.billing.mode);
    const holdOrder = useSelector((state) => state.billing.holdOrder);
    const splitOrderModal = useSelector((state) => state.billing.splitOrderModal);
    const discount = useSelector((state) => state.billing.discount);
    const itemFreeList = useSelector((state) => state.billing.itemFreeList);
    const customerInformation = useSelector((state) => state.billing.customerInformation);
    const finishCustomerInfo = useSelector((state) => state.billing.finishCustomerInfo);
    const tableData = useSelector((state) => state.billing.tableData);
    const previousOrders = useSelector((state) => state.billing.previousOrders);
    const tables = useSelector((state) => state.billing.tables);
    const tableSelectedList = useSelector((state) => state.billing.tableSelectedList);
    const languageCode = useSelector((state) => state.language.language);
    const dineInStepsStatus = useSelector((state) => state.billing.dineInStepsStatus);
    const isDirty = useSelector((state) => state.billing.isDirty);

    const cartId = useSelector((state) => state.billing.cartId);

    const dispatch = useDispatch();

    const [buttonClicked, setButtonClicked] = useState('');
    const [mapModal, setMapModal] = useState(false);
    const [discountFlyout, setDiscountFlyout] = useState(false);
    const [modeChangeOrderConfirmationModal, setModeChangeOrderConfirmationModal] = useState(false);
    const [changedMode, setChangedMode] = useState('');
    const [unavailableItemsArray, setUnavailableItemsArray] = useState([]);

    const [discountData, setDiscountData] = useState({});
    const [redemePoint, setRedemePoint] = useState(false);
    const [redemePointConfirmationModal, setRedemePointConfirmationModal] = useState(false);
    const [copyTableModalState, setCopyTableModalState] = useState(false);

    const [couponCodeData, setCouponCodeData] = useState({});

    const [status, setStatus] = useState({
        kot: false,
        bill: false,
        payment: false,
        close: false,
    });

    useEffect(() => {
        if (!finishCustomerInfo) {
            setStatus({
                kot: false,
                bill: false,
                payment: false,
                close: false,
            });
        }
    }, [finishCustomerInfo]);

    const BillingSectionStyle = {
        display: 'flex',
        flexDirection: 'column',
        border: '1px solid #D3DAE6',
        borderRadius: '4px',
        padding: '16px',
        // boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px',
    };

    const ModeStyle = {
        display: 'flex',
        flexDirection: 'row',
        border: '1px solid #d8dadc',
        justifyContent: 'space-between',
        height: '60px',
        padding: '8px',
        backgroundColor: '#f5f5f5',
    };

    useEffect(() => {
        if (finishCustomerInfo) {
            setStatus({
                kot: true,
                bill: false,
                payment: false,
                close: false,
            });
            // resetBillingUser()
        }

        if (
            mode === 'dine_in' &&
            tableSelectedList.length &&
            !isEmpty(tables) &&
            !isEmpty(customerInformation) &&
            isEmpty(previousOrders)
        ) {
            fillPreviousOrderForSelectedCustomer();
        }
    }, [mode]);

    useEffect(() => {
        if (!isEmpty(billingItem) && billingItem.customer_details) {
            reset({ ...billingItem.customer_details });
        }
    }, [billingItem]);

    const options = [
        { value: 'select_discount', text: 'Discount' },
        { value: 'percentage_discount', text: 'Percentage Discount' },
        { value: 'flat_discount', text: 'Amount Discount' },
        { value: 'get_an_item_free', text: 'Item Free' },
    ];

    const [value, setOptionsValue] = useState(options[0].value);

    const fillPreviousOrderForSelectedCustomer = () => {
        let tableId = tableSelectedList[0];

        tables.map((table) => {
            if (tableId == table.outlet_table_id) {
                dispatch({
                    type: types.SET_PREVIOUS_ORDERS,
                    payload: {
                        previousOrders: table.orders,
                    },
                });

                dispatch({
                    type: types.SET_TABLE_DATA,
                    payload: {
                        tableData: table,
                    },
                });
            }
        });
    };

    const itemCount = () => {
        let count = 0;

        if (!isEmpty(billingItemCartStructure)) {
            count = billingItemCartStructure.cart.cart_items.length;
        }

        return count;
    };

    const cartItemIdAdd = (response, itemObj) => {
        let innerObj = { ...itemObj };

        if (response.cart.cart_items.length > 0) {
            response.cart.cart_items.map((item) => {
                innerObj[`${item.item_id}i`].cart_item_id = item.cart_item_id;
            });
        }

        dispatch({
            type: types.SET_BILLING_ITEM,
            payload: {
                billingItem: innerObj,
            },
        });
    };

    const orderBillingStructureNew = (billingItem) => {
        let itemObj = {};

        Object.entries(billingItem).forEach(([key, value], index) => {
            if (key !== 'cart_id' && key !== 'customer_details') {
                let innerItemObj = {};
                if (!itemObj[value.id]) {
                    innerItemObj['quantity'] = value.quantity;

                    if (value.customizations && !isEmpty(value.customizations)) {
                        innerItemObj['customizations'] = value.customizations;
                    }

                    if (value.special_instruction) {
                        innerItemObj['special_instruction'] = value.special_instruction;
                    }
                } else {
                    innerItemObj['quantity'] = parseInt(itemObj[value.id]['quantity']) + parseInt(value.quantity);

                    if (value.customizations && !isEmpty(value.customizations)) {
                        innerItemObj['customizations'] = value.customizations;
                    }

                    if (value.special_instruction) {
                        innerItemObj['special_instruction'] = value.special_instruction;
                    }
                }

                itemObj[value.id] = innerItemObj;
            }
        });

        console.log('order billing structure new ', itemObj);

        return itemObj;
    };

    const addItemToCart = async (order, cartId) => {
        let response;
        let url = '';

        if (!cartId) {
            url = `/en/pos/restaurants/:restaurantId/outlets/${outletId}/carts`;
        } else {
            url = `/en/pos/restaurants/:restaurantId/outlets/${outletId}/carts/${cartId}`;
        }

        let data = orderBillingStructureNew(order);

        if (mode === 'delivery') {
            if (customerInformation.id) {
                data['customer_address'] = { id: customerInformation.id };
            } else {
                data['customer_address'] = {
                    latitude: customerInformation.latitude,
                    longitude: customerInformation.longitude,
                    location_name: customerInformation.location_name,
                    block_number: customerInformation.block_number,
                    city: customerInformation.city,
                    state: customerInformation.state,
                    region: customerInformation.region,
                    land_mark: customerInformation.land_mark,
                    tag: 'home',
                };
            }
        }

        try {
            if (!cartId) {
                url = `/en/pos/restaurants/:restaurantId/outlets/${outletId}/carts`;
                response = await API.post(url, {
                    items: { ...data },
                    ordering_mode: mode,
                    table_ids: tableSelectedList,
                });
            } else {
                url = `/en/pos/restaurants/:restaurantId/outlets/${outletId}/carts/${cartId}`;
                response = await API.put(url, {
                    items: { ...data },
                    ordering_mode: mode,
                    table_ids: tableSelectedList,
                });
            }
        } catch (error) {
            response = error;
        }

        if (response.success) {
            isEmpty(billingItem) &&
                !cartId &&
                dispatch({
                    type: types.SET_CART_ID,
                    payload: {
                        cartId: response.cart.cart_id,
                    },
                });
            dispatch({
                type: types.SET_BILLING_ITEM_CART_STRUCTURE,
                payload: {
                    billingItemCartStructure: response,
                },
            });

            onModeChangeEmptyOrders();

            //add cart_item_id
            cartItemIdAdd(response, order);
        } else {
            // let billingItemObj = JSON.parse(JSON.stringify(billingItem));
            // delete billingItemObj[key]
            // dispatch({
            //     type: types.SET_BILLING_ITEM,
            //     payload: {
            //         billingItem: billingItemObj,
            //     },
            // });
            // alert('Failed to add cart')
        }
    };

    const onConfirmReset = () => {
        let itemObj = JSON.parse(JSON.stringify(billingItem));
        let itemFreeArray = [...itemFreeList];

        unavailableItemsArray.map((itemKey) => {
            delete itemObj[`${itemKey.id}i`];

            let index = itemFreeArray.findIndex((key) => {
                return key === itemKey;
            });
            if (index >= 0) {
                itemFreeArray.splice(index, 1);
            }
        });

        dispatch({
            type: types.SET_BILLING_ITEM,
            payload: {
                billingItem: itemObj,
            },
        });

        dispatch({
            type: types.SET_MODE,
            payload: {
                mode: changedMode,
            },
        });

        dispatch({
            type: types.SET_CUSTOMER_INFORMATION_FINISH,
            payload: {
                finishCustomerInfo: false,
            },
        });

        dispatch({
            type: types.SET_ITEM_FREE_LIST,
            payload: {
                itemFreeList: itemFreeArray,
            },
        });

        if (isEmpty(itemObj)) {
            // dispatch({
            //     type: types.SET_BILLING_ITEM_CART_STRUCTURE,
            //     payload:{
            //         billingItemCartStructure: {}
            //     }
            // })

            dispatch({
                type: types.SET_CUSTOMER_INFORMATION,
                payload: {
                    customerInformation: {},
                },
            });
        }

        dispatch({
            type: types.SET_PREVIOUS_ORDERS,
            payload: {
                previousOrders: [],
            },
        });

        setModeChangeOrderConfirmationModal(false);

        addItemToCart(itemObj, cartId);
    };

    const modeChangeCondition = () => {
        setStatus({
            kot: true,
            bill: false,
            payment: false,
            close: false,
        });
        setModeChangeOrderConfirmationModal(false);
    };

    let modeChangeItemValidationModal = (
        <EuiOverlayMask>
            <EuiConfirmModal
                title='Are you sure you want to change Mode? There are few items not available in another Mode'
                onCancel={() => modeChangeCondition()}
                onConfirm={onConfirmReset}
                cancelButtonText="No, don't do it"
                confirmButtonText='Yes, do it'
                buttonColor='danger'
                defaultFocusedButton='cancel'
            >
                <p>You&rsquo;re about to remove unavailable items</p>
                <p>Are you sure you want to do this?</p>
            </EuiConfirmModal>
        </EuiOverlayMask>
    );

    const checkModeChangeAvailabilty = async (currentMode) => {
        let checkAvailability = true;
        let unavailableItemsArray = [];
        let categoryItemsList = {};
        if (!isEmpty(billingItem) && finishCustomerInfo) {
            // Object.entries(billingItem).forEach(([key, value], index) => {

            //     // let itemAdded = false
            //     if(!value[currentMode]){
            //         checkAvailability = false
            //         unavailableItemsArray.push(key)
            //     }

            //     // if(!itemAdded && value.addons && value.addons.length > 0){
            //     //     value.addons.map(addon => {
            //     //         if(!addon[currentMode]){
            //     //             checkAvailability = false
            //     //             !unavailableItemsArray.includes(key) && unavailableItemsArray.push(key)
            //     //         }
            //     //     })

            //     // }
            // })

            // if(!checkAvailability){
            //     setChangedMode(currentMode)
            //     setUnavailableItemsArray(unavailableItemsArray)
            //     setModeChangeOrderConfirmationModal(true)
            // }else{
            //     dispatch({
            //         type: types.SET_MODE,
            //         payload: {
            //             mode: currentMode,
            //         },
            //     });
            // }

            for (const key in billingItem) {
                if (!categoryItemsList[billingItem[key].category.category_id]) {
                    let response;
                    try {
                        response = await API.get(
                            `${languageCode}/restaurants/:restaurantId/outlets/${outletId}/categories/${billingItem[key].category.category_id}/items?ordering_mode=${currentMode}`
                        );
                    } catch (error) {
                        response = error;
                    }

                    if (response.success) {
                        let filteredItems = response.outlet_categories.items.filter(
                            (item) => item.id == billingItem[key].id
                        );

                        if (filteredItems.length < 1) {
                            unavailableItemsArray.push(billingItem[key]);
                        }

                        categoryItemsList[billingItem[key].category.category_id] = response.outlet_categories.items;
                    } else {
                        alert('Error in checking items');
                    }
                } else {
                    let filteredItems = categoryItemsList[billingItem[key].category.category_id].filter(
                        (item) => item.id == billingItem[key].id
                    );

                    if (filteredItems.length < 1) {
                        unavailableItemsArray.push(billingItem[key]);
                    }
                }
            }

            if (unavailableItemsArray.length > 0) {
                setChangedMode(currentMode);
                setUnavailableItemsArray(unavailableItemsArray);
                setModeChangeOrderConfirmationModal(true);
            } else {
                onModeChangeEmptyOrders();

                dispatch({
                    type: types.SET_MODE,
                    payload: {
                        mode: currentMode,
                    },
                });

                if (currentMode == 'delivery') {
                    dispatch({
                        type: types.SET_CUSTOMER_INFORMATION_FINISH,
                        payload: {
                            finishCustomerInfo: false,
                        },
                    });
                }
            }
        } else {
            onModeChangeEmptyOrders();

            dispatch({
                type: types.SET_MODE,
                payload: {
                    mode: currentMode,
                },
            });

            if (currentMode == 'delivery') {
                dispatch({
                    type: types.SET_CUSTOMER_INFORMATION_FINISH,
                    payload: {
                        finishCustomerInfo: false,
                    },
                });
            }
        }
    };

    const onModeChange = (currentMode) => {
        if (finishCustomerInfo && mode !== currentMode) {
            checkModeChangeAvailabilty(currentMode);
        } else {
            onModeChangeEmptyOrders();

            dispatch({
                type: types.SET_MODE,
                payload: {
                    mode: currentMode,
                },
            });
        }
    };

    const onModeChangeEmptyOrders = () => {
        dispatch({
            type: types.SET_TABLE_DATA,
            payload: {
                tableData: {},
            },
        });

        dispatch({
            type: types.SET_PREVIOUS_ORDERS,
            payload: {
                previousOrders: [],
            },
        });

        // addItemToCart(billingItem, cartId)
    };

    const onHold = (data) => {
        let innerArray = [...holdOrder];

        let billingItemObj = JSON.parse(JSON.stringify(billingItem));

        billingItemObj['customer_details'] = { ...customerInformation, table_list: tableSelectedList, mode: mode };

        billingItemObj['cart_id'] = cartId;

        innerArray.push(billingItemObj);

        dispatch({
            type: types.SET_HOLD_ORDER,
            payload: {
                holdOrder: innerArray,
            },
        });

        dispatch({
            type: types.SET_CART_ID,
            payload: {
                cartId: null,
            },
        });

        localStorage.setItem('holdOrder', JSON.stringify(innerArray));

        dispatch({
            type: types.SET_BILLING_ITEM,
            payload: {
                billingItem: {},
            },
        });

        dispatch({
            type: types.SET_BILLING_ITEM_CART_STRUCTURE,
            payload: {
                billingItemCartStructure: {},
            },
        });

        dispatch({
            type: types.SET_CUSTOMER_INFORMATION_FINISH,
            payload: {
                finishCustomerInfo: false,
            },
        });
    };

    const onSubmit = (data) => {
        if (buttonClicked === 'hold') {
            onHold(data);
            reset({ name: '', address: '', mobile_number: '', discount: '', cover_count: '' });
            resetBillingUser();
        }
    };

    const orderBillingStructure = () => {
        let innerArray = [];
        for (let key in billingItem) {
            let innerObj = JSON.parse(JSON.stringify(billingItem[key]));

            if (itemFreeList.includes(key)) {
                innerObj.promotional_quantity = 1;
            }

            innerArray.push(innerObj);
        }

        return innerArray;
    };

    const onChangeSetValue = useCallback((name, value) => {
        // setValue(name, value)
        ValueSet(setValue, name, value);
    }, []);

    const grandTotal = () => {
        let total = 0;
        if (!isEmpty(billingItemCartStructure)) {
            total = billingItemCartStructure.cart.net_total;
        }

        if (!isEmpty(tableData)) {
            total += tableData.net_total;
        }

        return parseFloat(total).toFixed(2);
    };

    const discountTotal = () => {
        let total = 0;
        if (!isEmpty(billingItemCartStructure)) {
            total = billingItemCartStructure.cart.discount_amount;
        }

        console.log('inside billing section table data check ', tableData);
        if (!isEmpty(tableData)) {
            total += tableData.discount_amount;
        }

        return parseFloat(total).toFixed(2);
    };

    const cashRef = useRef(null);
    const cardRef = useRef(null);
    const netBankingRef = useRef(null);
    const walletRef = useRef(null);
    const upiRef = useRef(null);
    const discountValueRef = useRef(null);

    const onWheel = () => {
        cashRef && cashRef.current && cashRef.current.blur();
        cardRef && cardRef.current && cardRef.current.blur();
        netBankingRef && netBankingRef.current && netBankingRef.current.blur();
        walletRef && walletRef.current && walletRef.current.blur();
        upiRef && upiRef.current && upiRef.current.blur();
        discountValueRef && discountValueRef.current && discountValueRef.current.blur();
    };

    const subTotal = () => {
        let subTotal = 0;

        if (!isEmpty(billingItemCartStructure)) {
            subTotal = billingItemCartStructure.cart.gross_total;
        }

        if (!isEmpty(tableData)) {
            subTotal += tableData.gross_total;
        }

        return subTotal;
    };

    const errorMessageForDiscount = () => {
        if (value === options[1].value) {
            return 'Please enter value between 0 to 100';
        } else if (value === options[2].value) {
            return 'Please enter value between 0 - ' + subTotal();
        }
    };

    const resetBillingUser = () => {
        setOptionsValue(options[0].value);
        dispatch({
            type: types.SET_RESET_STATE,
        });

        setCouponCodeData({});

        mode === 'dine_in' && setPaymentOptionSelected('');
        setDiscountData({});
        setRedemePoint(false);

        reset({ name: '', address: '', mobile_number: '', discount: '', cover_count: '' });
        // reset({});
    };

    useEffect(() => {
        setValue('discount', discountData.discount);
        setValue('discount_value', discountData.discount_value);
    }, [value]);

    // useEffect(() => {
    //     !isEmpty(couponCodeData) && setValue('coupon_amount', couponCodeData.cart.offers[0].offer_discount_amount);
    // }, [couponCodeData]);

    const generateOTPForRedemePoints = async () => {
        dispatch(generateOTP(customerInformation.country_code, customerInformation.mobile_number)).then((res) => {
            if (res.success) {
                setRedemePoint(true);
            } else {
                // alert("Error in generating OTP")
                alert(getErroMessage(res));
            }
        });
    };

    const confirmRedemePoints = () => {
        generateOTPForRedemePoints();

        setRedemePointConfirmationModal(false);
    };

    const redemePointsConfirmationModal = (
        <EuiOverlayMask>
            <EuiModal onClose={() => setRedemePointConfirmationModal(false)} initialFocus='[name=popswitch]'>
                <EuiModalHeader>
                    <EuiModalHeaderTitle>Loyalty Points Validation OTP</EuiModalHeaderTitle>
                </EuiModalHeader>

                <EuiModalBody>
                    Confirming this it will send otp to {customerInformation.country_code}{' '}
                    {customerInformation.mobile_number}
                </EuiModalBody>

                <EuiModalFooter>
                    <EuiButtonEmpty onClick={() => setRedemePointConfirmationModal(false)}>Cancel</EuiButtonEmpty>

                    <EuiButton onClick={confirmRedemePoints} fill>
                        Confirm
                    </EuiButton>
                </EuiModalFooter>
            </EuiModal>
        </EuiOverlayMask>
    );

    const addonGroupCreate = (addons) => {
        let customizationObj = {};

        addons.map((addon) => {
            if (customizationObj[addon.addon_group_id]) {
                let innerArray = [...customizationObj[addon.addon_group_id]];

                innerArray.push(addon);

                customizationObj[addon.addon_group_id] = innerArray;
            } else {
                customizationObj[addon.addon_group_id] = [{ ...addon }];
            }
        });

        return customizationObj;
    };

    const updateBillingItem = (offerResponse) => {
        let billingItemObj1 = {};

        let quantityObj = {};

        offerResponse.cart.cart_items.map((item) => {
            if (item.customizations && item.customizations.length > 0) {
                let innerObj = addonGroupCreate(item.customizations);

                if (billingItemObj1[item.item_id]) {
                    let innerCustomizationArray = billingItemObj1[item.item_id].customizations;

                    innerCustomizationArray.push({ ...innerObj, addon_information: { quantity: item.quantity } });

                    billingItemObj1[item.item_id].customizations = innerCustomizationArray;

                    billingItemObj1[item.item_id].quantity = 0;
                } else {
                    item.customizations = [{ ...innerObj, addon_information: { quantity: item.quantity } }];
                    billingItemObj1[item.item_id] = item;
                    billingItemObj1[item.item_id].quantity = 0;
                }
            } else {
                billingItemObj1[item.item_id] = item;
                quantityObj[item.item_id] = item.quantity;
            }
        });

        Object.entries(quantityObj).forEach(([key, value]) => {
            if (billingItemObj1[key]) {
                billingItemObj1[key].quantity = value;
            }
        });

        dispatch({
            type: types.SET_BILLING_ITEM,
            payload: {
                billingItem: billingItemObj1,
            },
        });
    };

    const removeOffer = async (offerId) => {
        let response;
        try {
            response = await API.put(
                `en/pos/restaurants/:restaurantId/outlets/${outletId}/carts/${cartId}/remove-offer`,
                {
                    offer_id: offerId,
                }
            );
        } catch (error) {
            response = error;
        }

        if (response.success) {
            updateBillingItem(response);
            if (response.cart.offers.length < 1) {
                setCouponCodeData({});
            } else {
                setCouponCodeData(response);
            }
            dispatch({
                type: types.SET_BILLING_ITEM_CART_STRUCTURE,
                payload: {
                    billingItemCartStructure: response,
                },
            });
            setValue('coupon_amount', undefined);
        } else {
            alert('Failed to remove offer');
        }
    };

    useEffect(() => {
        if (!isEmpty(couponCodeData)) {
            let billingItemObj = JSON.parse(JSON.stringify(billingItem));
            couponCodeData.cart.cart_items.map((item) => {
                billingItemObj[value.item_id] = value;
            });
        }
    }, [couponCodeData]);

    const modeCheck = () => {
        if (mode === 'dine_in') {
            return <TableSVG height={12} style={{ color: 'white' }} />;
        } else if (mode === 'takeaway') {
            return <PickUp height={12} fill={'#ffffff'} />;
        }

        return <Moped height={12} fill={'#ffffff'} />;
    };

    const changeTable = () => {
        setCopyTableModalState(true);
    };

    const tableNameCondition = () => {
        if (mode !== 'dine_in') {
            return mode.toUpperCase();
        }

        let value = 'DINE IN';
        if (tableSelectedList.length) {
            tables.map((table) => {
                if (table.outlet_table_id == tableSelectedList[0]) {
                    value = table.name;
                }
            });
        }

        return value;
    };

    const outletList = useSelector((state) => state.outlet.outletList);
    const getOutletName = () => {
        let outletName = '';
        outletList.map((outlet) => {
            if (outlet.value == outletId) {
                outletName = outlet.text;
            }
        });

        return outletName;
    };

    const onChangeTable = () => {
        if (mode === 'dine_in' && !isEmpty(customerInformation)) {
            setCopyTableModalState(true);
        }
    };

    const orderBillingStructureForPlaceOrder = () => {
        let itemObj = {};

        Object.entries(billingItem).forEach(([key, value], index) => {
            if (key !== 'cart_id' && key !== 'customer_details') {
                let innerItemObj = {};
                if (!itemObj[value.id]) {
                    innerItemObj['quantity'] = value.quantity;

                    if (value.customizations && !isEmpty(value.customizations)) {
                        innerItemObj['customizations'] = value.customizations;
                    }

                    if (value.special_instruction) {
                        innerItemObj['special_instruction'] = value.special_instruction;
                    }
                } else {
                    innerItemObj['quantity'] = parseInt(itemObj[value.id]['quantity']) + parseInt(value.quantity);

                    if (value.customizations && !isEmpty(value.customizations)) {
                        innerItemObj['customizations'] = value.customizations;
                    }

                    if (value.special_instruction) {
                        innerItemObj['special_instruction'] = value.special_instruction;
                    }
                }

                itemObj[value.id] = innerItemObj;
            }
        });

        return itemObj;
    };

    const statusUpdate = (orderId) => {
        let dineInStepStatusObj = JSON.parse(JSON.stringify(dineInStepsStatus));
        dineInStepStatusObj[orderId] = { ...status, kot: true };

        dispatch({
            type: types.SET_DINE_IN_STEPS_STATUS,
            payload: {
                dineInStepsStatus: dineInStepStatusObj,
            },
        });

        setStatus({ kot: false, bill: false, payment: false, close: false });

        // horizontalStepsUpdate({ kot: false,
        //     bill: false,
        //     payment: false,
        //     close: false
        // })
    };

    const errorCheck = (orderResponse) => {
        let table_error = false;
        let customer_error = false;
        if (orderResponse.errors) {
            Object.entries(orderResponse.errors).forEach(([key, value], index) => {
                if (key.startsWith('table_ids')) {
                    table_error = true;
                }

                if (key.startsWith('customer_address')) {
                    customer_error = true;
                }
            });

            if (table_error) {
                alert('Table already assigned');
                dispatch({
                    type: types.SET_TABLE_SELECTED_LIST,
                    payload: {
                        tableSelectedList: [],
                    },
                });

                dispatch({
                    type: types.SET_CUSTOMER_INFORMATION_FINISH,
                    payload: {
                        finishCustomerInfo: false,
                    },
                });
            } else if (customer_error) {
                alert('Provide proper customer information');
                dispatch({
                    type: types.SET_CUSTOMER_INFORMATION_FINISH,
                    payload: {
                        finishCustomerInfo: false,
                    },
                });
            } else {
                alert(getErroMessage(orderResponse));
            }
        } else {
            if (orderResponse.message) {
                alert(orderResponse.message);
            } else {
                alert('error in order place');
            }
        }
    };

    const placeOrderCall = async (print = false) => {
        let itemStructure = orderBillingStructureForPlaceOrder();
        let innerObj = {};
        innerObj['items'] = itemStructure;

        innerObj.customer = {
            ...customerInformation,
            mobile_number: customerInformation.mobile_number,
            date_of_birth: customerInformation.date_of_birth
                ? moment(customerInformation.date_of_birth).format('YYYY-MM-DD')
                : '',
            anniversary_date: customerInformation.anniversary_date
                ? moment(customerInformation.anniversary_date).format('YYYY-MM-DD')
                : '',
            block_number: customerInformation.block_number,
            land_mark: customerInformation.land_mark,
        };

        if (customerInformation.id) {
            innerObj['id'] = customerInformation.id;
            innerObj['is_dirty'] = isDirty;
        }

        if (mode === 'delivery' && customerInformation.id) {
            innerObj['customer_address'] = { id: customerInformation.id };
        }

        innerObj['customer_address'] =
            mode === 'delivery'
                ? {
                      latitude: customerInformation.latitude,
                      longitude: customerInformation.longitude,
                      location_name: customerInformation.location_name,
                      block_number: customerInformation.block_number,
                      city: customerInformation.city,
                      state: customerInformation.state,
                      region: customerInformation.region,
                      land_mark: customerInformation.land_mark,
                      tag: 'home',
                  }
                : null;

        let item_free = [];
        if (value === 'get_an_item_free') {
            itemFreeList.map((item) => {
                item_free.push({
                    cart_item_id: item.cart_item_id,
                    quantity: 1,
                });
            });
        }

        innerObj['discount_information'] =
            value !== 'select_discount' && isEmpty(previousOrders)
                ? {
                      type: value === 'get_an_item_free' ? 'free_item' : value,
                      value: value !== 'get_an_item_free' ? discountData.discount_value : item_free,
                      discount_reason: discountData.discount,
                  }
                : null;

        innerObj['ordering_mode'] = mode;
        // innerObj['payment_method'] = paymentOptionSelected
        innerObj['payment_method_id'] = paymentOptionSelected;
        innerObj['cart_id'] = cartId;
        // innerObj['table_ids'] = tableSelectedList

        //redeme points
        if (redemePoint) {
            innerObj['use_reward_points'] = redemePoint;
            // innerObj['redeme_points'] = watch('redeme_points')
            innerObj['otp'] = watch('otp');
        }

        let orderResponse;

        try {
            orderResponse = await API.post(
                `${languageCode}/pos/restaurants/:restaurantId/outlets/${outletId}/orders`,
                innerObj
            );
        } catch (error) {
            orderResponse = error;
        }

        if (orderResponse.success) {
            dispatch({
                type: types.SET_BILLING_ITEM,
                payload: {
                    billingItem: {},
                },
            });

            statusUpdate(orderResponse.order.order_id);

            if (print) {
                document.getElementById('printButton').click();
            }

            resetBillingUser();
        } else {
            errorCheck(orderResponse);
        }
    };

    return (
        <>
            {modeChangeOrderConfirmationModal && modeChangeItemValidationModal}
            {splitOrderModal && <OrderSplitModal total={grandTotal()} outletId={outletId} />}
            {/* {redemePointConfirmationModal && redemePointsConfirmationModal} */}
            {copyTableModalState && <CopyTableModal setCopyTableModalState={setCopyTableModalState} />}

            {mapModal && (
                <MapModal
                    setMapModal={setMapModal}
                    watch={watch}
                    setValue={setValue}
                    errors={errors}
                    control={control}
                    reset={reset}
                />
            )}
            {discountFlyout && (
                <DiscountFlyout
                    setDiscountData={setDiscountData}
                    setDiscountFlyout={setDiscountFlyout}
                    subTotal={subTotal}
                    setValueBllingSection={setValue}
                    setOptionsValueBillingSection={setOptionsValue}
                    outletId={outletId}
                    setCouponCodeData={setCouponCodeData}
                />
            )}

            <div style={BillingSectionStyle} className={status.payment ? 'paymentSection' : 'POSBillingSection'}>
                <EuiForm component='form' onSubmit={handleSubmit(onSubmit)}>
                    {!isEmpty(customerInformation) ? (
                        <EuiFlexItem>
                            <EuiFlexItem style={{ display: 'flex', flexDirection: 'row' }}>
                                <EuiFlexItem
                                    grow={7}
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'flex-start',
                                    }}
                                >
                                    <span onClick={onChangeTable} className='orderFirstTitle dotted'>
                                        {tableNameCondition()}
                                    </span>
                                    <span className='orderSecondTitle'>{capitalize(customerInformation.name)}</span>
                                    <span
                                        onClick={() => {
                                            dispatch({
                                                type: types.SET_CUSTOMER_INFORMATION_FINISH,
                                                payload: {
                                                    finishCustomerInfo: false,
                                                },
                                            });
                                        }}
                                        className='orderDetailOutletName dotted'
                                    >
                                        {customerInformation.country_code ? customerInformation.country_code : ''}{' '}
                                        {customerInformation.mobile_number}
                                    </span>
                                </EuiFlexItem>

                                <EuiFlexItem
                                    grow={3}
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'flex-end',
                                    }}
                                >
                                    <span className='orderDetailTime'>Customer's 1st order</span>
                                    <span style={{ paddingTop: '5px' }} className='orderDetailOutletName'>
                                        {getOutletName()}
                                    </span>
                                </EuiFlexItem>
                            </EuiFlexItem>

                            <EuiHorizontalRule style={{ marginBottom: '0px' }} />
                        </EuiFlexItem>
                    ) : (
                        ''
                    )}

                    <div>
                        {status.payment ? (
                            <PaymentSection
                                placeOrderCall={placeOrderCall}
                                status={status}
                                setStatus={setStatus}
                                setValue={setValue}
                                errors={errors}
                                watch={watch}
                                control={control}
                                outletId={outletId}
                            />
                        ) : (
                            <BillingSectionItem outletId={outletId} />
                        )}

                        {!isEmpty(couponCodeData) && (
                            <div style={{ paddingLeft: '20px' }}>
                                <EuiPanel
                                    paddingSize='s'
                                    style={{
                                        backgroundColor: '#86e686',
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    <EuiFlexItem style={{ flexDirection: 'column', width: '100%' }}>
                                        <p>Discount Applied</p>
                                        {couponCodeData.cart.offers.map((offer) => {
                                            return (
                                                <EuiFlexItem
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        justifyContent: 'space-between',
                                                        alignItems: 'center',
                                                        width: '100%',
                                                    }}
                                                >
                                                    <p style={{ fontSize: '14px' }}> {offer.coupon_code} </p>
                                                    <EuiButtonIcon
                                                        iconType={'cross'}
                                                        onClick={() => removeOffer(offer.offer_id)}
                                                    />
                                                </EuiFlexItem>
                                            );
                                        })}
                                    </EuiFlexItem>
                                </EuiPanel>
                                <EuiSpacer />
                            </div>
                        )}

                        {redemePoint && (
                            <div
                                style={{
                                    paddingLeft: '20px',
                                    paddingBottom: '10px',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    width: '100%',
                                    justifyContent: 'space-between',
                                    display: 'none',
                                }}
                            >
                                <EuiFormRow
                                    style={{ width: '48%' }}
                                    label={'Redeme points'}
                                    fullWidth={true}
                                    error={errors['redeme_points'] ? errors['redeme_points'].message : ''}
                                    isInvalid={errors['redeme_points'] && errors['redeme_points'].message}
                                >
                                    <Controller
                                        render={({ field }) => (
                                            <EuiFieldNumber value={field.value} onChange={field.onChange} />
                                        )}
                                        name={'redeme_points'}
                                        placeholder='Redeme points'
                                        control={control}
                                        fullWidth={true}
                                        rules={{
                                            required: 'Please enter redeme points',
                                        }}
                                        isInvalid={errors['redeme_points'] && errors['redeme_points'].message}
                                    />
                                </EuiFormRow>
                                <EuiFormRow
                                    style={{ marginTop: '0px', width: '48%' }}
                                    label={'OTP'}
                                    fullWidth={true}
                                    error={errors['otp'] ? errors['otp'].message : ''}
                                    isInvalid={errors['otp'] && errors['otp'].message}
                                >
                                    <Controller
                                        render={({ field }) => (
                                            <EuiFieldNumber value={field.value} onChange={field.onChange} />
                                        )}
                                        name={'otp'}
                                        placeholder='OTP'
                                        control={control}
                                        fullWidth={true}
                                        rules={{
                                            required: 'Please enter discount reason',
                                        }}
                                        isInvalid={errors['otp'] && errors['otp'].message}
                                    />
                                </EuiFormRow>
                            </div>
                        )}

                        <BillingSectionButtonList
                            setPaymentOption={setPaymentOption}
                            paymentOption={paymentOption}
                            paymentOptionSelected={paymentOptionSelected}
                            setPaymentOptionSelected={setPaymentOptionSelected}
                            errors={errors}
                            setValue={setValue}
                            control={control}
                            paymentOptionSelected={paymentOptionSelected}
                            discountData={discountData}
                            value={value}
                            outletId={outletId}
                            setButtonClicked={setButtonClicked}
                            resetBillingUser={resetBillingUser}
                            customer_name={!isEmpty(customerInformation) ? customerInformation.name : ''}
                            // watch('name')
                            customer_address={watch('address')}
                            status={status}
                            setStatus={setStatus}
                            redemePoint={redemePoint}
                            watch={watch}
                            setDiscountFlyout={setDiscountFlyout}
                            setRedemePointConfirmationModal={setRedemePointConfirmationModal}
                            setRedemePoint={setRedemePoint}
                            generateOTPForRedemePoints={generateOTPForRedemePoints}
                            placeOrderCall={placeOrderCall}
                        />
                    </div>
                </EuiForm>
            </div>
        </>
    );
};

export default memo(BillingSection);
