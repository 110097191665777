import * as types from '../types/offers';

const initialState = {
    offers: [],
    selectedOffer: '',
};

function offersReducer(state = initialState, action) {
    switch (action.type) {
        case types.SET_OFFERS:
            return {
                ...state,
                offers: action.payload.offers,
            };

        default:
            return state;
    }
}
export default offersReducer;
