import API from '../axios/API';

export const downloadPastOrderCsvFile = (startDate, endDate, languageId) => {
    return async () => {
        const min = 10000;
        const max = 99999;
        const uniqueNumber = Math.floor(Math.random() * (max - min + 1)) + min;

        const formattedStartDate = startDate.format('YYYY-MM-DD');
        const formattedEndDate = endDate.format('YYYY-MM-DD');
        let response;
        try {
            const link = document.createElement('a');
            link.target = '_blank';
            link.download = `${formattedStartDate} ${formattedEndDate} Past Order ${uniqueNumber}`;
            response = await API.get(
                `${process.env.REACT_APP_BASE_URL_V3}/restaurants/:restaurantId/download/past-orders?start_date=${formattedStartDate}&end_date=${formattedEndDate}&language_id=${languageId}`,
                { responseType: 'blob' }
            ).then((res) => {
                link.href = URL.createObjectURL(
                    new Blob([res], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
                );
                link.click();
            });
        } catch (error) {
            window.alert('Error in downloading the File');
            console.log('error', error);
        }

        return response;
    };
};
