import {
    EuiButton,
    EuiCard,
    EuiFlexGroup,
    EuiFlexItem,
    EuiFlyout,
    EuiFlyoutBody,
    EuiFlyoutFooter,
    EuiFlyoutHeader,
    EuiLoadingContent,
    EuiTitle,
} from '@elastic/eui';
import { capitalize } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { userHasPermission } from '../../../helper/auth';
import { fetchImageList } from '../../../modules/MediaLibrary/Api/fetchImageList';
import UploadImageDropZone from '../../../modules/MediaLibrary/Components/UploadImageDropZone';
import MediaSelectionImage from './MediaSelectionImage';
import UploadButton from './UploadButton';

const ImageSelectionContainer = ({
    imageType,
    selectedImages,
    handleImageSelection,
    toggleModal,
    handleOnSave,
    aspectRatio,
}) => {
    const [state, setState] = useState({
        images: [],
        isLoading: false,
        showUploadImageModal: false,
    });
    const permissions = useSelector((state) => state.permission.permission);

    const history = useHistory();

    const fetchImages = useCallback(async (imageType) => {
        await fetchImageList(imageType, setState);
    }, []);

    const handleUploadImageModalToggle = useCallback(() => {
        setState((prevState) => ({
            ...prevState,
            showUploadImageModal: !prevState.showUploadImageModal,
        }));
    }, []);

    useEffect(() => {
        fetchImages(imageType);
    }, [fetchImages, imageType]);

    return (
        <>
            {state.showUploadImageModal ? (
                <UploadImageDropZone
                    imageType={imageType}
                    handleUploadImageModalToggle={handleUploadImageModalToggle}
                    fetchImages={fetchImages}
                    aspectRatio={aspectRatio}
                />
            ) : null}
            <EuiFlyout size={'100%'} maxWidth onClose={toggleModal} style={{ zIndex: 1000 }}>
                <EuiFlyoutHeader>
                    <EuiFlexGroup justifyContent='spaceBetween'>
                        <EuiFlexItem>
                            <EuiTitle size='m'>
                                <h2> Image Selection for {capitalize(imageType.replaceAll('_', ' '))}</h2>
                            </EuiTitle>
                        </EuiFlexItem>
                        <EuiFlexItem
                            onClick={handleUploadImageModalToggle}
                            style={{ paddingRight: '20px' }}
                            grow={false}
                        >
                            <UploadButton />
                        </EuiFlexItem>
                    </EuiFlexGroup>
                </EuiFlyoutHeader>
                <EuiFlyoutBody>
                    <EuiFlexGroup
                        wrap
                        responsive
                        direction='row'
                        justifyContent='spaceBetween'
                        alignItems='flexStart'
                        gutterSize='l'
                    >
                        {state.isLoading ? (
                            <>
                                <EuiFlexItem>
                                    <EuiLoadingContent lines={4} />
                                </EuiFlexItem>
                            </>
                        ) : (
                            <>
                                {state.images?.length ? (
                                    <EuiFlexItem>
                                        <EuiFlexGroup
                                            wrap
                                            responsive
                                            direction='row'
                                            // justifyContent='spaceBetween'
                                            alignItems='stretch'
                                            gutterSize='l'
                                        >
                                            {state.images.map((image) => {
                                                return (
                                                    <MediaSelectionImage
                                                        handleImageSelection={handleImageSelection}
                                                        key={image.id.toString()}
                                                        selectedImages={selectedImages}
                                                        image={image}
                                                    />
                                                );
                                            })}
                                        </EuiFlexGroup>
                                    </EuiFlexItem>
                                ) : (
                                    <EuiFlexItem>
                                        <EuiCard title='No Images Found' />
                                    </EuiFlexItem>
                                )}
                            </>
                        )}
                    </EuiFlexGroup>
                </EuiFlyoutBody>
                <EuiFlyoutFooter>
                    <EuiButton onClick={handleOnSave}>Add Selected Images</EuiButton>
                </EuiFlyoutFooter>
            </EuiFlyout>
        </>
    );
};

export default React.memo(ImageSelectionContainer);
