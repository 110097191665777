import React from 'react';
import moment from 'moment';
import { EuiText, EuiHorizontalRule, EuiFlexGroup, EuiFlexItem, htmlIdGenerator } from '@elastic/eui';
import API from '../../api/axios/API';
import { isEmpty } from 'lodash';
export default class BillingInvoicePrint extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            order: {},
        };
    }

    async fetchOutletInformation() {
        let outletResponse;
        try {
            outletResponse = await API.get(`restaurants/:restaurantId/outlets/${this.props.outletId}`);
        } catch (error) {
            outletResponse = error;
        }

        this.setState({
            order: outletResponse,
        });
    }

    componentDidMount() {
        this.fetchOutletInformation();
    }

    itemsRecords() {
        // let itemsArray = []
        for (let key in this.props.billingItem) {
            let customizationTotal = 0;
            let item = JSON.parse(JSON.stringify(this.props.billingItem[key]));
            let adonObj = [];

            for (let addon_key in item.addons) {
                adonObj.push(item.addons[addon_key]);
            }
            item.adonObj = adonObj;

            return (
                <React.Fragment>
                    <EuiFlexItem key={htmlIdGenerator()()}>
                        {/* todo: remove this comment and solve translation issue */}
                        <EuiText key={htmlIdGenerator()()} size='m'>
                            {item.title}
                        </EuiText>

                        {!isEmpty(item.adonObj) &&
                            item.adonObj[0].map((addons) => {
                                customizationTotal = customizationTotal + parseFloat(addons.choicePrice);
                                return (
                                    <>
                                        <EuiText key={htmlIdGenerator()()} style={{ fontSize: '12px' }}>
                                            - {addons.name} [&#8377;{addons.choicePrice.toFixed(2)}]
                                        </EuiText>
                                    </>
                                );
                            })}
                    </EuiFlexItem>

                    <EuiFlexItem
                        key={htmlIdGenerator()()}
                        style={{
                            justifyContent: 'space-between',
                            flexDirection: 'row',
                            display: 'flex',
                            marginTop: '5px',
                        }}
                    >
                        <EuiText key={htmlIdGenerator()()}>
                            {item.quantity} x &#8377;
                            {parseFloat(item.price.toFixed(2)) + parseFloat(customizationTotal.toFixed(2))}
                        </EuiText>
                        <EuiText>
                            &#8377;
                            {(parseFloat(item.price.toFixed(2)) + parseFloat(customizationTotal.toFixed(2))) *
                                parseInt(item.quantity)}
                        </EuiText>
                    </EuiFlexItem>
                    <br />
                </React.Fragment>
            );
        }
    }

    previousRecords() {
        let itemsArray = [];
        if (!isEmpty(this.props.previousOrders)) {
            this.props.previousOrders.map((order) => {
                order.cart.cart_items.map((items) => {
                    let customizationTotal = 0;
                    let item = JSON.parse(JSON.stringify(items));
                    let adonObj = [];

                    if (item.customizations && item.customizations.length > 0) {
                        adonObj = item.customizations;
                    }

                    item.adonObj = adonObj;

                    itemsArray.push(
                        <React.Fragment>
                            <EuiFlexItem key={htmlIdGenerator()()}>
                                <EuiText key={htmlIdGenerator()()} size='m'>
                                    {item.title}
                                </EuiText>

                                {!isEmpty(item.adonObj) &&
                                    item.adonObj.map((addons) => {
                                        customizationTotal = customizationTotal + parseFloat(addons.price);
                                        return (
                                            <>
                                                <EuiText key={htmlIdGenerator()()} style={{ fontSize: '12px' }}>
                                                    - {addons.name} [&#8377;{addons.price.toFixed(2)}]
                                                </EuiText>
                                            </>
                                        );
                                    })}
                            </EuiFlexItem>

                            <EuiFlexItem
                                key={htmlIdGenerator()()}
                                style={{
                                    justifyContent: 'space-between',
                                    flexDirection: 'row',
                                    display: 'flex',
                                    marginTop: '5px',
                                }}
                            >
                                <EuiText key={htmlIdGenerator()()}>
                                    {item.quantity} x &#8377;
                                    {parseFloat(item.price.toFixed(2)) + parseFloat(customizationTotal.toFixed(2))}
                                </EuiText>
                                <EuiText>
                                    &#8377;
                                    {(parseFloat(item.price.toFixed(2)) + parseFloat(customizationTotal.toFixed(2))) *
                                        parseInt(item.quantity)}
                                </EuiText>
                            </EuiFlexItem>
                            <br />
                        </React.Fragment>
                    );
                });
            });
        }

        return itemsArray;
    }

    subTotal() {
        let subTotal = 0;

        if (!isEmpty(this.props.tableData)) {
            subTotal += this.props.tableData.gross_total;
        }

        // if(!isEmpty(this.props.billingItemCartStructure)){
        //     subTotal += this.props.billingItemCartStructure.cart.gross_total
        // }

        return subTotal;
    }

    packagingCharges() {
        let packagingCharge = 0;

        if (!isEmpty(this.props.tableData)) {
            packagingCharge += this.props.tableData.packaging_charge;
        }

        // if(!isEmpty(this.props.billingItemCartStructure)){
        //     packagingCharge += this.props.billingItemCartStructure.cart.packaging_charge
        // }

        return packagingCharge;
    }

    discountAmount() {
        let discountAmount = 0;

        if (!isEmpty(this.props.tableData)) {
            discountAmount += this.props.tableData.discount_amount;
        }

        // if(!isEmpty(this.props.billingItemCartStructure)){
        //     discountAmount += this.props.billingItemCartStructure.cart.discount_amount
        // }

        return discountAmount;
    }

    convenienceCharges() {
        let convenienceCharge = 0;

        if (!isEmpty(this.props.tableData)) {
            convenienceCharge += this.props.tableData.convenience_charge;
        }

        // if(!isEmpty(this.props.billingItemCartStructure)){
        //     convenienceCharge += this.props.billingItemCartStructure.cart.convenience_charge
        // }

        return convenienceCharge;
    }

    deliveryCharges() {
        let deliveryCharges = 0;

        if (!isEmpty(this.props.tableData)) {
            deliveryCharges += this.props.tableData.convenience_charge;
        }

        // if(!isEmpty(this.props.billingItemCartStructure)){
        //     deliveryCharges += this.props.billingItemCartStructure.cart.convenience_charge
        // }

        return deliveryCharges;
    }

    gstTotal() {
        let gstTotal = 0;

        if (!isEmpty(this.props.tableData)) {
            gstTotal += this.props.tableData.gst_amount;
        }

        // if(!isEmpty(this.props.billingItemCartStructure)){
        //     gstTotal += this.props.billingItemCartStructure.cart.gst_amount
        // }

        return gstTotal;
    }

    totalAmount() {
        let totalAmount = 0;

        if (!isEmpty(this.props.tableData)) {
            totalAmount += this.props.tableData.net_total;
        }

        if (totalAmount > 0 && this.props.redemePoints) {
            if (this.props.customerInformation.reward_points / this.props.redemePointValueOnPurchase > totalAmount) {
                totalAmount = 0;
            } else {
                totalAmount -= this.props.customerInformation.reward_points / this.props.redemePointValueOnPurchase;
            }
        }

        // if(!isEmpty(this.props.billingItemCartStructure)){
        //     totalAmount += this.props.billingItemCartStructure.cart.net_total
        // }

        return totalAmount;
    }

    modeSelecteion = () => {
        if (this.props.mode === 'dine_in') {
            return 'Dine In';
        } else if (this.props.mode === 'takeaway') {
            return 'Takeaway';
        }

        return 'Delivery';
    };

    render() {
        if (isEmpty(this.state.order)) {
            return null;
        }

        let restaurantName = this.props.restaurantList.restaurants.filter(
            (restaurant) => restaurant.id === parseInt(this.props.selectedRestuarantId)
        );

        let customizationTotal = 0;
        let spaceBetweenAlign = { justifyContent: 'space-between', flexDirection: 'row', display: 'flex' };
        let centerAlign = { justifyContent: 'center', alignItems: 'center', display: 'flex', textAlign: 'center' };
        return (
            <EuiFlexItem
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    width: '219px',
                    marginLeft: '15px',
                    fontFamily: 'sans-serif',
                    fontSize: '12px',
                }}
            >
                <EuiFlexGroup style={{ width: '219px' }}>
                    <EuiFlexItem style={centerAlign}>
                        <span style={{ fontSize: '16px' }}>
                            <b>{restaurantName[0].name}</b>
                        </span>
                    </EuiFlexItem>
                    <EuiFlexItem style={centerAlign}>
                        {/* todo: remove this comment and solve translation issue */}
                        <span>{this.state.order.outlet.name}</span>
                    </EuiFlexItem>
                    <EuiFlexItem style={centerAlign}>
                        {/* todo: remove this comment and solve translation issue */}
                        <span>{this.state.order.outlet.outlet_address.address_line_1}</span>
                    </EuiFlexItem>
                    {this.state.order.outlet.settings && this.state.order.outlet.settings.gst_number && (
                        <EuiFlexItem style={centerAlign}>
                            <span>GSTIN: {this.state.order.outlet.settings.gst_number}</span>
                        </EuiFlexItem>
                    )}
                    <EuiHorizontalRule />

                    <EuiFlexItem style={centerAlign}>{moment().format('DD MMM yyy hh:mm A')}</EuiFlexItem>
                    <EuiHorizontalRule />

                    <EuiFlexItem style={centerAlign}>Cash on Delivery</EuiFlexItem>

                    <EuiFlexItem style={centerAlign}>{this.modeSelecteion()}</EuiFlexItem>
                    <EuiHorizontalRule />

                    {console.log('bill print customer name ', this.props.customer_name)}
                    <EuiFlexItem>
                        <b>Name: </b> {this.props.customer_name}
                    </EuiFlexItem>
                    {/* <EuiFlexItem>{this.customerAddress()}</EuiFlexItem> */}
                    {console.log('bill print customer address ', this.props.customer_address)}
                    <EuiText>
                        <b>Address: </b> {this.props.customer_address},
                    </EuiText>
                    <EuiHorizontalRule />

                    <EuiFlexItem style={centerAlign}>
                        <b>Item(s)</b>
                    </EuiFlexItem>
                    <EuiHorizontalRule />

                    {this.previousRecords()}
                    {/* {this.itemsRecords()} */}

                    <EuiHorizontalRule />
                    <EuiFlexItem style={spaceBetweenAlign}>
                        <span>Gross total</span>
                        <span>&#8377;{this.subTotal()}</span>
                    </EuiFlexItem>

                    {this.packagingCharges() > 0 ? (
                        <EuiFlexItem style={spaceBetweenAlign}>
                            <span>Packaging Charge</span>
                            <span>&#8377;{this.packagingCharges()}</span>
                        </EuiFlexItem>
                    ) : (
                        ''
                    )}

                    {this.discountAmount() > 0 ? (
                        <EuiFlexItem style={spaceBetweenAlign}>
                            <span>Discount</span>
                            <span>&#8377; {this.discountAmount()}</span>
                        </EuiFlexItem>
                    ) : (
                        ''
                    )}

                    {this.props.redemePoints ? (
                        <EuiFlexItem style={spaceBetweenAlign}>
                            <span>Rewards</span>
                            <span>&#8377; {this.props.pointsValue()}</span>
                        </EuiFlexItem>
                    ) : (
                        ''
                    )}

                    {this.convenienceCharges() > 0 ? (
                        <EuiFlexItem style={spaceBetweenAlign}>
                            <span>Convenience charge</span>
                            <span>&#8377; {this.convenienceCharges()}</span>
                        </EuiFlexItem>
                    ) : (
                        ''
                    )}

                    {this.deliveryCharges() > 0 ? (
                        <EuiFlexItem style={spaceBetweenAlign}>
                            <span>Convenience charge</span>
                            <span>&#8377; {this.deliveryCharges()}</span>
                        </EuiFlexItem>
                    ) : (
                        ''
                    )}

                    {this.gstTotal() > 0 && (
                        <EuiFlexItem style={spaceBetweenAlign}>
                            <span>GST</span>
                            <span>&#8377;{this.gstTotal()} </span>
                        </EuiFlexItem>
                    )}

                    <EuiHorizontalRule />
                    <EuiFlexItem style={spaceBetweenAlign}>
                        <b>Total</b>
                        <b>&#8377;{this.totalAmount()}</b>
                    </EuiFlexItem>

                    {/* {
                        parseInt(this.props.order.discount_amount) > 0 && (
                            <EuiFlexItem style={spaceBetweenAlign}>
                                <span>Discount</span>
                                <span>&#8377; {this.props.order.discount_amount && this.props.order.discount_amount.toFixed(2)}</span>
                            </EuiFlexItem>
                        )
                    }
                    
                        
                    {
                        parseInt(this.props.order.cart.convenience_charge) > 0 && (
                            <EuiFlexItem style={spaceBetweenAlign}>
                                <span>Convenience Charge</span>
                                <span>&#8377;{this.props.order.cart.convenience_charge}</span>
                            </EuiFlexItem>
                        )
                    }
                    
                    {
                        parseInt(this.props.order.cart.delivery_charge) > 0 && (
                        <EuiFlexItem style={spaceBetweenAlign}>
                            <span>Delivery Charge</span>
                            <span>&#8377;{this.props.order.cart.delivery_charge}</span>
                        </EuiFlexItem>)
                    }
                    
                     */}
                    <EuiHorizontalRule />

                    <EuiFlexItem style={{ justifyContent: 'center', flexDirection: 'row', display: 'flex' }}>
                        Thank you.
                    </EuiFlexItem>
                    <EuiFlexItem style={centerAlign}>
                        <b>Powered by Fab Systems</b>
                    </EuiFlexItem>
                </EuiFlexGroup>
            </EuiFlexItem>
        );
    }
}
