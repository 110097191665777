import {
    EuiEmptyPrompt,
    EuiFlexGroup,
    EuiFlexItem,
    EuiGlobalToastList,
    EuiLoadingContent,
    EuiSpacer,
    htmlIdGenerator,
} from '@elastic/eui';
import { isEmpty } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import API from '../../../api/axios/API';
import { petPoojaSyncValidate } from '../../../api/menu/petPoojaSyncValidate';
import { fetchOrderingModes } from '../../../api/outlet/fetchOrderingModes';
import { userHasPermission } from '../../../helper/auth';
import {
    SET_PET_POOJA_ADDON_GROUPS,
    SET_PET_POOJA_LINKED_ADDONS,
    SET_SYSTEM_ADDON_GROUPS,
} from '../../../reduxStore/types/PetPoojaItemTypes';
import UnlinkedItems from '../../../components/PetPoojaMenuLink/UnlinkedItems';
import { linkItemToPetPooja } from '../../../components/PetPoojaLink/Api/linkSelectedToPetPooja';
import AddonGroupLink from '../../../components/PetPoojaLink/PetPoojaAddonLink/AddonGroupLink';
import PetPoojaSynceButton from '../../../components/PetPoojaLink/PetPoojaSynceButton';
import {
    getLinkedPetpoojaAddonChoice,
    getLinkedPetpoojaAddonGroup,
    getPetpoojaChoices,
} from '../../../components/PetPoojaLink/utils/utils';

const PetPoojaAddonLink = () => {
    const methods = useForm({
        mode: 'all',
    });
    const linkedItems = useSelector((state) => state.petPoojaReducer.petPoojaLinkedAddons);
    const dispatch = useDispatch();
    const [validationFailedItems, setValidationFailedItem] = useState({
        addon: {},
    });
    const [stateToSetDirtyFalse, setStateToSetDirtyFalse] = useState([]);
    // above state is only used to set isDirty to false
    // after appending the form useEffect will run it's sideEffect
    // by running the sideEffect form resets itself by call resetChanges()
    const [addonLoader, setAddonLoader] = useState({ loader: true, error: true });
    const [petPoojaSyncLoader, setPetPoojaSyncLoader] = useState(false);
    const [addonGroups, setAddonGroups] = useState([]);
    const permissions = useSelector((state) => state.permission.permission);
    const selectedOutletId = useSelector((state) => state.outlet.selectedOutletId);
    const [isPetpoojaLinkEnable, setPetpoojaLinkEnable] = useState(true);
    const outletList = useSelector((state) => state.outlet.outletList);
    const languageId = useSelector((state) => state.language.languageId);
    const [petpoojaAddonGroups, setPetpoojaAddonGroups] = useState([]);
    const [toasts, setToasts] = useState([]);

    const clearValidationFormError = useCallback(() => {
        setValidationFailedItem({ addon: {} });
    }, []);

    const fetchRecords = useCallback(() => {
        setAddonLoader((prevState) => ({ ...prevState, loader: true }));
        dispatch(fetchOrderingModes());
        Promise.all([
            API.get(`restaurants/:restaurantId/outlets/${selectedOutletId}/restaurant-addon-groups`),
            API.get(`restaurants/:restaurantId/outlets/${selectedOutletId}/pet-pooja/menu-fetch`),
            API.get(`restaurants/:restaurantId/outlets/${selectedOutletId}/pet-pooja/menu-links`),
        ]).then((response) => {
            if (!response[0].success || !response[1].success || !response[2].success) {
                setAddonLoader({ loader: false, error: true });
                return;
            }

            if (
                response[0].restaurant_addon_groups?.length &&
                response?.[2]?.order_response?.length &&
                response[1]?.pet_pooja_menu?.addongroups?.length
            ) {
                const linkedItems = response?.[2]?.order_response;
                const petpoojaMenu = response[1].pet_pooja_menu.addongroups;
                dispatch({
                    type: SET_SYSTEM_ADDON_GROUPS,
                    payload: response[0].restaurant_addon_groups,
                });

                let linkedAddons = [];
                response[0]?.restaurant_addon_groups?.forEach((addonGroup) => {
                    let addOnChoices = [];
                    if (addonGroup.choices.length) {
                        addonGroup.choices.forEach((addonChoiceItem) => {
                            addOnChoices.push({
                                choice_id: addonChoiceItem.choice_id,
                                name: addonChoiceItem.translations.name[languageId],
                                label: addonChoiceItem.translations.name[languageId],
                                price: addonChoiceItem.price,
                                orderingModes: addonChoiceItem.ordering_modes,
                                isDirty: false,
                                selectedPetpoojaAddonChoice: getLinkedPetpoojaAddonChoice(
                                    addonChoiceItem.choice_id,
                                    linkedItems,
                                    petpoojaMenu
                                ),
                            });
                        });
                    }

                    linkedAddons.push({
                        addonGroupId: addonGroup.addon_group_id,
                        internal_name: addonGroup.internal_name,
                        addOnChoices: addOnChoices,
                        label: addonGroup.internal_name,
                        isDirty: false,
                        petPoojaChoices: getPetpoojaChoices(addonGroup.addon_group_id, linkedItems, petpoojaMenu),
                        selectedPetpoojaAddonGroup: getLinkedPetpoojaAddonGroup(
                            addonGroup.addon_group_id,
                            linkedItems,
                            petpoojaMenu
                        ),
                    });
                });

                setAddonGroups(linkedAddons);
            }

            if (response[1].pet_pooja_menu) {
                dispatch({
                    type: SET_PET_POOJA_ADDON_GROUPS,
                    payload: response[1].pet_pooja_menu.addongroups,
                });

                let petpoojaAddonGroups = [];
                response[1].pet_pooja_menu.addongroups?.forEach((petpoojaAddonItem) => {
                    let addOnChoices = [];
                    if (petpoojaAddonItem.addongroupitems.length) {
                        petpoojaAddonItem.addongroupitems.forEach((addonChoiceItem) => {
                            addOnChoices.push({
                                // couldn't find the choice_id
                                // corrent payload would be
                                // choice_id: addonChoiceItem.choice_id,
                                // the corrent choice_id is inserted when its being selected
                                choice_id: addonChoiceItem.addonitemid, // is equals to third_party_module_id
                                name: addonChoiceItem.addonitem_name,
                                third_party_module_id: addonChoiceItem.addonitemid,
                                label: addonChoiceItem.addonitem_name,
                                price: addonChoiceItem.addonitem_price,
                            });
                        });
                    }
                    petpoojaAddonGroups.push({
                        petpoojaAddonGroupId: petpoojaAddonItem.addongroupid,
                        petpoojaAddonGroupName: petpoojaAddonItem.addongroup_name,
                        addOnChoices: addOnChoices,
                        label: petpoojaAddonItem.addongroup_name,
                        // module_id: petpoojaAddonItem.addongroupid,
                        third_party_module_id: petpoojaAddonItem.addongroupid,
                    });
                });

                setPetpoojaAddonGroups(petpoojaAddonGroups);
            }

            if (response[2].order_response) {
                dispatch({
                    type: SET_PET_POOJA_LINKED_ADDONS,
                    payload: response[2].order_response,
                });
            }
            setAddonLoader({ loader: false, error: false });
        });
    }, [dispatch, languageId, selectedOutletId]);

    const validateSync = useCallback(() => {
        setPetPoojaSyncLoader(true);
        dispatch(petPoojaSyncValidate()).then((response) => {
            if (response.success) {
                clearValidationFormError();
                setToasts([
                    ...toasts,
                    {
                        title: 'This outlet Fully Synced',
                        id: htmlIdGenerator()(),
                        color: 'success',
                    },
                ]);
            } else {
                const unlinkedAddonGroup = addonGroups?.filter(
                    (group) => group.addonGroupId === response?.unlinked_item_addon_groups?.restaurant_addon_group_id
                );

                setValidationFailedItem((prevState) => ({ ...prevState, addon: unlinkedAddonGroup[0] }));
                setToasts([
                    ...toasts,
                    {
                        title: 'This outlet is not Fully Synced',
                        id: htmlIdGenerator()(),
                        color: 'danger',
                    },
                ]);
            }
            setPetPoojaSyncLoader(false);
        });
    }, [addonGroups, dispatch, clearValidationFormError, toasts]);

    const resetChanges = useCallback(() => {
        let defaultValue = {};
        if (addonGroups?.length && linkedItems?.length) {
            for (const addonGroup of addonGroups) {
                defaultValue[`addon_${addonGroup.addonGroupId}`] = [];
                for (const linkedItem of linkedItems) {
                    if (
                        addonGroup.addonGroupId === linkedItem.module_id &&
                        linkedItem.module_name === 'restaurant_addon_group'
                    ) {
                        // resets group information
                        defaultValue[`addon_${addonGroup.addonGroupId}`][0] = {
                            ...addonGroup,
                            addOnChoices: addonGroup.addOnChoices,
                            selectedGroup: [
                                {
                                    addOnChoices: addonGroup.addOnChoices,
                                    label: linkedItem.title,
                                    petpoojaAddonGroupId: linkedItem.third_party_module_id,
                                    third_party_module_id: linkedItem.third_party_module_id,
                                    petpoojaAddonGroupName: linkedItem.title,
                                    module_id: addonGroup.addonGroupId,
                                    linkedItem: true,
                                },
                            ],
                        };
                    } else if (isEmpty(defaultValue[`addon_${addonGroup.addonGroupId}`][0])) {
                        defaultValue[`addon_${addonGroup.addonGroupId}`][0] = {
                            ...addonGroup,
                            addOnChoices: addonGroup.addOnChoices,
                        };
                    }

                    // resetting the addon choices
                    if (addonGroup?.addOnChoices?.length) {
                        for (const [choiceIndex, addOnChoice] of addonGroup.addOnChoices.entries()) {
                            if (addOnChoice?.orderingModes?.length) {
                                for (const [modeIndex, orderingMode] of addOnChoice?.orderingModes.entries()) {
                                    if (
                                        orderingMode.restaurant_ordering_mode_id ==
                                            linkedItem.restaurant_ordering_mode_id &&
                                        linkedItem.module_name == 'restaurant_addon_groups_choice' &&
                                        addOnChoice.choice_id == linkedItem.module_id
                                    ) {
                                        // addOnChoice.choice_id === linkedItem.third_party_module_id
                                        // fix this last condition with proper form of payload

                                        // defaultChoice.push({
                                        //     ...orderingMode,
                                        //     selectedChoice: [
                                        //         {
                                        //             active: addOnChoice.selectedPetpoojaAddonChoice.active,
                                        //             addonitem_name: addOnChoice.name,
                                        //             choice_id: addOnChoice.choice_id,
                                        //             addonitem_price:
                                        //                 addOnChoice.selectedPetpoojaAddonChoice.addonitem_price,
                                        //             price: addOnChoice.name,
                                        //             addonitemid: addOnChoice.selectedPetpoojaAddonChoice.addonitemid,
                                        //             attributes: addOnChoice.selectedPetpoojaAddonChoice.attributes,
                                        //             label: addOnChoice.name,
                                        //             restaurant_ordering_mode_id:
                                        //                 orderingMode.restaurant_ordering_mode_id,
                                        //         },
                                        //     ],
                                        // });
                                        defaultValue[`addon_${addonGroup.addonGroupId}`][0].addOnChoices[
                                            choiceIndex
                                        ].orderingModes[modeIndex] = {
                                            ...orderingMode,
                                            selectedChoice: [
                                                {
                                                    active: addOnChoice.selectedPetpoojaAddonChoice.active,
                                                    addonitem_name: addOnChoice.name,
                                                    choice_id: addOnChoice.choice_id,
                                                    module_id: linkedItem.module_id,
                                                    module_name: linkedItem.module_name,
                                                    third_party_module_id: linkedItem.third_party_module_id,
                                                    addonitem_price:
                                                        addOnChoice.selectedPetpoojaAddonChoice.addonitem_price,
                                                    price: addOnChoice.name,
                                                    addonitemid: addOnChoice.selectedPetpoojaAddonChoice.addonitemid,
                                                    attributes: addOnChoice.selectedPetpoojaAddonChoice.attributes,
                                                    label: linkedItem.title,
                                                    restaurant_ordering_mode_id: linkedItem.restaurant_ordering_mode_id,
                                                    third_party_service_provider:
                                                        linkedItem.third_party_service_provider,
                                                    title: linkedItem.title,
                                                    linkedItem: true,
                                                },
                                            ],
                                        };
                                    }
                                }
                            }
                        }
                    }
                }
            }
            if (defaultValue) {
                methods.reset({ ...defaultValue });
            }
        }
    }, [addonGroups, linkedItems, methods]);

    const onSubmit = useCallback(
        (data) => {
            if (addonGroups?.length && data) {
                const menu_links = [];
                for (const { addonGroupId } of addonGroups) {
                    if (data[`addon_${addonGroupId}`]?.length) {
                        const addonGroup = data[`addon_${addonGroupId}`][0];
                        const modeId = addonGroup?.addOnChoices?.[0]?.orderingModes?.[0]?.restaurant_ordering_mode_id
                            ? addonGroup?.addOnChoices?.[0]?.orderingModes?.[0]?.restaurant_ordering_mode_id
                            : 141;
                        // SELF ADDON GROUP
                        if (addonGroup?.selectedGroup?.length) {
                            menu_links.push({
                                module_id: addonGroup.addonGroupId,
                                module_name: 'restaurant_addon_group',
                                third_party_service_provider: 'pet-pooja',
                                third_party_module_id: addonGroup?.selectedGroup?.[0]?.petpoojaAddonGroupId,
                                outlet_id: selectedOutletId,
                                title: addonGroup?.selectedGroup?.[0]?.label,
                                linkedItem: addonGroup?.selectedGroup?.[0]?.linkedItem,
                                restaurant_ordering_mode_id: modeId,
                                id: modeId,
                            });
                        }

                        if (addonGroup?.addOnChoices?.length) {
                            // HANDLE CHOICE DATA
                            for (const addonChoice of addonGroup.addOnChoices) {
                                if (addonChoice?.orderingModes?.length) {
                                    for (const orderingMode of addonChoice.orderingModes) {
                                        if (orderingMode?.selectedChoice?.length) {
                                            const selectedChoice = orderingMode.selectedChoice?.[0];
                                            menu_links.push({
                                                module_id: selectedChoice.choice_id,
                                                module_name: 'restaurant_addon_groups_choice',
                                                third_party_service_provider: 'pet-pooja',
                                                linkedItem: selectedChoice?.linkedItem,
                                                third_party_module_id: selectedChoice?.third_party_module_id
                                                    ? selectedChoice.third_party_module_id
                                                    : selectedChoice?.addonitemid,
                                                outlet_id: selectedOutletId,
                                                title: selectedChoice?.label,
                                                price: selectedChoice?.price,
                                                restaurant_ordering_mode_id:
                                                    // selectedChoice?.restaurant_ordering_mode_id
                                                    //     ? selectedChoice?.restaurant_ordering_mode_id
                                                    //     :
                                                    orderingMode.restaurant_ordering_mode_id,
                                            });
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                const uniqueUnlinkedItems = data?.menu_unlinks?.reduce(
                    (acc, curr) =>
                        acc?.find(
                            (item) =>
                                item.third_party_module_id === curr.third_party_module_id &&
                                curr.module_id === item.module_id &&
                                curr.module_name === item.module_name &&
                                curr.restaurant_ordering_mode_id === item.restaurant_ordering_mode_id
                        )
                            ? acc
                            : [...acc, curr],
                    []
                );

                const removedAlreadyLinkedItems = menu_links?.filter((item) => !item?.linkedItem);

                if (menu_links?.length) {
                    data = {
                        ...data,
                        external_menu_links: removedAlreadyLinkedItems?.length ? removedAlreadyLinkedItems : [],
                        external_menu_unlink: uniqueUnlinkedItems?.length ? uniqueUnlinkedItems : [],
                    };

                    // console.log('ADDON PAYLOAD', data);

                    linkItemToPetPooja(data, selectedOutletId).then((response) => {
                        if (!response?.error) {
                            dispatch({
                                type: 'set-portal-active',
                                payload: {
                                    portalActive: {},
                                },
                            });
                            setToasts([
                                ...toasts,
                                {
                                    title: 'Item Saved Successfully',
                                    id: htmlIdGenerator()(),
                                    color: 'success',
                                },
                            ]);
                        }
                    });
                }
            }
        },
        [addonGroups, dispatch, selectedOutletId, toasts]
    );

    useEffect(() => {
        if (!userHasPermission(permissions, '/menu', 'write')) return;

        if (methods.formState.isDirty) {
            dispatch({
                type: 'set-portal-active',
                payload: {
                    portalActive: {
                        resetChanges: resetChanges,
                        submit: methods.handleSubmit(onSubmit),
                    },
                },
            });
        } else {
            dispatch({
                type: 'set-portal-active',
                payload: {
                    portalActive: {},
                },
            });
        }
    }, [methods.formState.isDirty]);

    const removeToast = useCallback(
        (removedToast) => {
            setToasts(toasts.filter((toast) => toast.id !== removedToast.id));
        },
        [toasts]
    );

    useEffect(() => {
        if (addonGroups?.length && linkedItems?.length && stateToSetDirtyFalse?.length) {
            resetChanges();
        }
    }, [addonGroups, resetChanges, linkedItems, stateToSetDirtyFalse]);

    useEffect(() => {
        if (selectedOutletId) {
            let outlet = outletList.find((outlet) => outlet.value == selectedOutletId);

            if (!outlet?.outlet_data?.outlet_third_party_menu_setting?.is_pet_pooja_integration_enabled) {
                setPetpoojaLinkEnable(false);
                return;
            }

            setPetpoojaLinkEnable(true);
            fetchRecords();
        }

        return function cleanup() {
            clearValidationFormError();
        };
    }, [selectedOutletId, clearValidationFormError]);

    if (!isPetpoojaLinkEnable) {
        return (
            <div style={{ position: 'absolute', top: '50%', left: '50%', transform: `translate(-50%, -50%)` }}>
                <EuiEmptyPrompt
                    iconType='editorStrike'
                    title={<h2>PetPooja Settings</h2>}
                    body={<p>Please enable this from outlet setings - Third party POS.</p>}
                />
            </div>
        );
    }

    if (addonLoader.loader) return <EuiLoadingContent lines={4} />;

    return (
        <FormProvider {...methods}>
            <EuiGlobalToastList toasts={toasts} dismissToast={removeToast} toastLifeTimeMs={6000} />
            <EuiFlexGroup alignItems='flexEnd' justifyContent='flexStart' direction='column'>
                <EuiFlexItem grow={false}>
                    <PetPoojaSynceButton isLoading={petPoojaSyncLoader} handleOnClick={validateSync} />
                </EuiFlexItem>

                {!isEmpty(validationFailedItems.addon) && (
                    <EuiFlexItem grow={10} style={{ width: '95%' }}>
                        <UnlinkedItems validationFailedItems={validationFailedItems} />
                    </EuiFlexItem>
                )}

                <EuiFlexItem grow={10} style={{ width: '97%' }}>
                    <EuiFlexGroup direction='column'>
                        <EuiFlexItem>
                            {addonGroups?.map((group) => {
                                return (
                                    <AddonGroupLink
                                        groupOptions={petpoojaAddonGroups}
                                        key={group?.addonGroupId?.toString()}
                                        group={group}
                                        setStateToSetDirtyFalse={setStateToSetDirtyFalse}
                                    />
                                );
                            })}
                        </EuiFlexItem>
                    </EuiFlexGroup>
                </EuiFlexItem>
            </EuiFlexGroup>
            <EuiSpacer />
        </FormProvider>
    );
};

export default React.memo(PetPoojaAddonLink);
