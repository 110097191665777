import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import CategoryRow from './CategoryRow';

const CategoryChildRow = ({ category, handleAvailablityModal, fetchItemAvailabilityData }) => {
    const categories = useSelector((state) => state.itemAvailabilityReducer.outlet_categories);
    const [childCategories] = useState(categories.filter((categoryItem) => categoryItem.parent_id === category.id));

    return (
        <>
            {childCategories?.map((subCategory) => (
                <CategoryRow
                    key={subCategory.id.toString()}
                    category={subCategory}
                    fetchItemAvailabilityData={fetchItemAvailabilityData}
                    handleAvailablityModal={handleAvailablityModal}
                />
            ))}
        </>
    );
};

export default React.memo(CategoryChildRow);
