import React, { useCallback, useMemo, useState } from 'react';

import {
    EuiFieldText,
    EuiFlexGroup,
    EuiFlexItem,
    EuiFormRow,
    EuiColorPicker,
    useColorPickerState,
    EuiButtonEmpty,
    EuiText,
    EuiConfirmModal,
    EuiOverlayMask,
    EuiButton,
} from '@elastic/eui';
import { Controller, useWatch } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { createNewBadge, deleteBadges, fetchRestaurantBadges, updateBadge } from '../catalogue/Api/FetchCatalogueInfo';
import { SET_RESTAURANT_BADGES } from '../Store/MenuTypes';

const Badge = ({ index, control, remove, setValue, watch, badge, isOutletMenu }) => {
    const editItem = useSelector((state) => state.menuReducer.activeItemDetails.details);
    const [color, setColor] = useColorPickerState(badge?.color);
    const [badgeErrors, setBadgeErrors] = useState({});
    const languageId = useSelector((state) => state.language.languageId);
    const [showModal, setShowModal] = useState(false);
    const restaurantBadges = useSelector((state) => state.menuReducer.restaurantBadges);
    const dispatch = useDispatch();
    const isBadgeExisted = editItem?.badges?.filter((existedBadge) => {
        return existedBadge.id === badge.id;
    });

    const handleColorChange = useCallback(
        (text, { hex, isValid }) => {
            setColor(text, { hex, isValid });
            setValue(`badges[${index}].color`, hex);
        },
        [index, setColor, setValue]
    );
    const display_name = useWatch({
        control,
        name: `badges[${index}].translations.display_name[${languageId}]`,
    });

    const colorPickedUp = useWatch({
        control,
        name: `badges[${index}].color`,
    });

    const handleRemoveBadge = useCallback(
        (badge) => {
            if (restaurantBadges?.length) {
                const isBadgeUploaded = restaurantBadges.filter((restaurantBadge) => restaurantBadge.id === badge.id);

                if (isBadgeUploaded?.length) {
                    setShowModal(true);
                } else {
                    remove(index);
                }
            }
        },
        [index, remove, restaurantBadges]
    );

    const deleteSelectedBadge = useCallback(
        async (badge) => {
            setShowModal(false);
            await deleteBadges(badge.id).then(async (response) => {
                if (response.success) {
                    await fetchRestaurantBadges().then((response) => {
                        dispatch({
                            type: SET_RESTAURANT_BADGES,
                            payload: response.badges,
                        });
                    });
                }
            });
            remove(index);
        },
        [dispatch, index, remove]
    );

    const saveCreatedBadge = useCallback(
        async (display_name) => {
            let translationObject = {
                display_name: {
                    [languageId]: display_name,
                },
            };

            const badgeObject = {
                color: colorPickedUp,
                internal_name: display_name,
                translations: translationObject,
            };

            if (!colorPickedUp) {
                setBadgeErrors((prevState) => ({
                    ...prevState,
                    color: 'Please select an appropriate color',
                }));
            } else {
                setBadgeErrors((prevState) => ({
                    ...prevState,
                    color: null,
                }));
            }
            if (!display_name) {
                setBadgeErrors((prevState) => ({
                    ...prevState,
                    display_name: 'Please enter display name',
                }));
            } else {
                setBadgeErrors((prevState) => ({
                    ...prevState,
                    display_name: null,
                }));
            }

            if (display_name && colorPickedUp) {
                await createNewBadge(badgeObject).then((response) => console.log('response', response));
                await fetchRestaurantBadges().then((response) => {
                    dispatch({
                        type: SET_RESTAURANT_BADGES,
                        payload: response.badges,
                    });
                });
            }
        },
        [colorPickedUp, dispatch, languageId]
    );

    const editSelectedBadge = useCallback(async (selectedBadge) => {
        if (selectedBadge.id) {
            await updateBadge(selectedBadge, selectedBadge.id).then((res) => {
                //todo add toasts for notifications
                // if (res.success) {
                //     setToast((prevState) => ({
                //         ...prevState,
                //         isOpen: true,
                //         color: 'success',
                //         title: 'Updated Successfully',
                //     }));
                // } else {
                //     setToast((prevState) => ({
                //         ...prevState,
                //         isOpen: true,
                //         color: 'danger',
                //         title: 'Error Occurred',
                //     }));
                // }
            });
        }
    }, []);

    const deleteConfirmationModal = useMemo(() => {
        return (
            <EuiOverlayMask>
                <EuiConfirmModal
                    title={<p>Are you sure you want to delete this badge? </p>}
                    onCancel={() => {
                        setShowModal(false);
                    }}
                    onConfirm={() => deleteSelectedBadge(badge)}
                    cancelButtonText="No, don't do it"
                    confirmButtonText='Yes, do it'
                    buttonColor='danger'
                    defaultFocusedButton='cancel'
                >
                    <p>You&rsquo;re about to delete this </p>
                    <p>Are you sure you want to do this?</p>
                </EuiConfirmModal>
            </EuiOverlayMask>
        );
    }, [badge, deleteSelectedBadge]);

    return (
        <>
            {showModal ? deleteConfirmationModal : null}
            <EuiFlexGroup gutterSize='s' direction='row' justifyContent='spaceBetween' alignItems='flexEnd' wrap>
                <EuiFlexItem grow={10} style={{ minWidth: '80px' }}>
                    <Controller
                        render={({ field }) => (
                            <EuiFormRow label={'Display Name'}>
                                <EuiFieldText
                                    placeholder={'Enter Badge Name'}
                                    value={field.value}
                                    disabled={isOutletMenu}
                                    onChange={(value) => {
                                        setValue(
                                            `badges[${index}].translations.display_name[${languageId}]`,
                                            value.target.value
                                        );
                                    }}
                                />
                            </EuiFormRow>
                        )}
                        name={`badges[${index}].translations.display_name[${languageId}]`}
                        control={control}
                        fullWidth={true}
                        defaultValue={display_name}
                    />
                </EuiFlexItem>
                <EuiFlexItem>
                    <EuiColorPicker disabled={isOutletMenu} onChange={handleColorChange} color={color} button={null} />
                </EuiFlexItem>
                <EuiFlexItem>
                    <EuiButtonEmpty
                        disabled={isOutletMenu}
                        iconType={'trash'}
                        color='danger'
                        onClick={() => handleRemoveBadge(badge)}
                    />
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                    <EuiButton
                        style={{ cursor: 'pointer' }}
                        size='m'
                        onClick={() => {
                            if (!isOutletMenu) {
                                if (isBadgeExisted?.length) {
                                    editSelectedBadge(badge);
                                } else {
                                    saveCreatedBadge(display_name);
                                }
                            }
                        }}
                    >
                        {isBadgeExisted?.length ? 'Save Edit' : 'Save'}
                    </EuiButton>
                </EuiFlexItem>
            </EuiFlexGroup>
            {!isEmpty(badgeErrors) ? (
                <EuiFlexGroup gutterSize='none' justifyContent='flexStart' alignItems='flexStart' direction='column'>
                    <EuiFlexItem>
                        <EuiText size='xs' color={'danger'}>
                            {badgeErrors.display_name}
                        </EuiText>
                    </EuiFlexItem>
                    <EuiFlexItem>
                        <EuiText size='xs' color={'danger'}>
                            {badgeErrors.color}
                        </EuiText>
                    </EuiFlexItem>
                </EuiFlexGroup>
            ) : null}
        </>
    );
};

export default React.memo(Badge);
