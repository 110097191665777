import React, { useEffect, useState } from 'react';
import ItemDetailsCollaspibleNav from './ItemDetailsCollaspibleNav';
import { ReactComponent as ItemTimingsIcon } from '../../../../../assets/img/svg/ItemTimings.svg';
import { useSelector } from 'react-redux';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import {
    EuiCollapsibleNavGroup,
    EuiFlexGroup,
    EuiFlexItem,
    EuiFormRow,
    EuiRadioGroup,
    EuiSpacer,
    EuiText,
    EuiTextColor,
} from '@elastic/eui';
import CheckBoxField from '../../../../../components/Form/CheckBoxField';
import MultipleOrderingTimings from '../../../../../components/menuComponentsRefactorCopy/menuEditorTab/MultipleOrderingTimings';
import TimeSlot from '../../../../../components/Form/TimeSlot';
import WeekDaysTimeSlot from '../../../../../components/menuComponentsRefactorCopy/menuEditorTab/WeekDaysTimeSlot';

const ItemTimings = ({ stateUpdate }) => {
    const [isNavOpen, setIsNavOpen] = useState(false);
    const isOutletMenu = useSelector((state) => state.menuReducer.isOutletMenu);
    const {
        control,
        setValue,
        formState: { errors },
        reset,
        watch,
    } = useFormContext({});

    const itemOrderingModes = useWatch({
        control,
        name: 'ordering_modes',
    });
    const restaurantOrderingModes = useSelector((state) => state.outlet.orderingModes);
    const [orderingModes, setOrderingModes] = useState([]);
    const isTimeDifferentForDifferentDays =
        useWatch({ control, name: 'timer_for_item' }) === 'different_time_for_different_days';
    const isTimeSameForAllDays = useWatch({ control, name: 'timer_for_item' }) === 'same_time_for_all_days';
    const is_enabled_same_time_slot_details_for_all_ordering_modes = useWatch({
        control,
        name: 'is_enabled_same_time_slot_details_for_all_ordering_modes',
    });

    useEffect(() => {
        if (restaurantOrderingModes?.length && itemOrderingModes?.length) {
            let orderingModes = [];
            for (const restaurantOrderingMode of restaurantOrderingModes) {
                for (const itemOrderingMode of itemOrderingModes) {
                    if (
                        restaurantOrderingMode.status &&
                        restaurantOrderingMode.id === itemOrderingMode.restaurant_ordering_mode_id
                    ) {
                        orderingModes.push({ ...itemOrderingMode });
                    }
                }
            }
            if (orderingModes?.length) {
                setOrderingModes(orderingModes);
            }
        }
    }, [restaurantOrderingModes, itemOrderingModes]);

    return (
        <>
            <ItemDetailsCollaspibleNav
                cardTitle='Item Timings'
                isNavOpen={isNavOpen}
                cardDescription='Specify the timings when this item will be available on fab delivery'
                setIsNavOpen={setIsNavOpen}
                toggleText='Edit Basic Details'
                icon={<ItemTimingsIcon />}
            >
                <EuiFlexItem>
                    <CheckBoxField
                        name='is_enabled_same_time_slot_details_for_all_ordering_modes'
                        label='Different Time Slots for all ordering mode?'
                        control={control}
                        setValue={setValue}
                        isDisabled={isOutletMenu}
                    />
                </EuiFlexItem>
                <EuiSpacer />
                {is_enabled_same_time_slot_details_for_all_ordering_modes ? (
                    <>
                        {orderingModes?.map((orderingMode) => {
                            return (
                                <MultipleOrderingTimings
                                    key={orderingMode?.restaurant_ordering_mode_id.toString()}
                                    orderingMode={orderingMode}
                                    errors={errors}
                                    stateUpdate={stateUpdate}
                                    weekDays={weekDays}
                                    reset={reset}
                                    control={control}
                                    setValue={setValue}
                                    watch={watch}
                                />
                            );
                        })}
                    </>
                ) : (
                    <EuiCollapsibleNavGroup
                        background='light'
                        title={
                            <EuiFlexItem style={{ padding: '0 .5rem' }}>
                                <EuiText>
                                    <h5>Timings for all ordering modes</h5>
                                </EuiText>
                            </EuiFlexItem>
                        }
                        isCollapsible={true}
                        initialIsOpen={true}
                    >
                        <EuiFlexItem style={{ padding: '0 1rem' }}>
                            <EuiFlexItem>
                                <EuiText size='s'>
                                    <span>
                                        Please Specify the timings when this item will be available on fab delivery
                                    </span>
                                </EuiText>
                                <EuiText size='s' color='warning'>
                                    Each day can have only 6 time slots
                                </EuiText>
                            </EuiFlexItem>
                            <EuiFlexGroup alignItems='center' style={{ marginTop: '10px' }}>
                                <EuiFlexItem grow={false}>
                                    <EuiFormRow isInvalid={errors?.timer_for_item} fullWidth={true}>
                                        <Controller
                                            render={({ field }) => (
                                                <EuiRadioGroup
                                                    options={itemTimeSlot}
                                                    idSelected={field.value}
                                                    onChange={field.onChange}
                                                    legend={{
                                                        children: <span> Decide this Item lifetime </span>,
                                                    }}
                                                />
                                            )}
                                            name='timer_for_item'
                                            defaultValue={true}
                                            control={control}
                                            fullWidth={true}
                                        />
                                    </EuiFormRow>
                                </EuiFlexItem>
                            </EuiFlexGroup>
                            <EuiFlexItem>
                                {isTimeSameForAllDays && (
                                    <>
                                        <EuiFlexItem style={{ padding: '10px' }}>
                                            <TimeSlot
                                                control={control}
                                                setValue={setValue}
                                                name='time'
                                                errors={errors}
                                                watch={watch}
                                            />
                                        </EuiFlexItem>
                                    </>
                                )}
                                {isTimeDifferentForDifferentDays && (
                                    <>
                                        {weekDays.map((day, index) => (
                                            <WeekDaysTimeSlot
                                                day={day}
                                                key={index + day}
                                                reset={reset}
                                                control={control}
                                                setValue={setValue}
                                                watch={watch}
                                                name={`week.${day}`}
                                                component={TimeSlot}
                                            />
                                        ))}
                                    </>
                                )}
                                {stateUpdate && <EuiTextColor color='danger'>please enter time slot</EuiTextColor>}
                            </EuiFlexItem>
                        </EuiFlexItem>
                    </EuiCollapsibleNavGroup>
                )}
            </ItemDetailsCollaspibleNav>
        </>
    );
};

const itemTimeSlot = [
    { id: 'same_day_of_the_week', label: 'Item is available at for all days of the week ' },
    { id: 'same_time_for_all_days', label: 'Item is available at same time for all days of the week' },
    {
        id: 'different_time_for_different_days',
        label: 'Item is available at specific time for specific days of the week',
    },
];

const weekDays = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];

export default React.memo(ItemTimings);
