import { EuiButton, EuiButtonEmpty, EuiFlexGroup, EuiFlexItem, EuiFormRow, EuiImage, EuiSpacer } from '@elastic/eui';
import { isEmpty } from 'lodash';
import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import ImageSelectionContainer from '../../../components/UIComponent/ImageSelection/ImageSelectionContainer';

const LanguageBannerImage = ({
    selectedImages,
    setSelectedImages,
    buttonLabel,
    imageType,
    setValue,
    name,
    selectedImgButtonLabel = 'Change Image',
    label,
    defaultImageValue,
    aspectRatio,
    withRemove,
}) => {
    const [isShowingImageSelectionModal, setIsShowingImageSelectionModal] = useState(false);
    const isOutletMenu = useSelector((state) => state.menuReducer.isOutletMenu);

    const toggleModal = useCallback(() => {
        setIsShowingImageSelectionModal((prevState) => !prevState);
        if (!isEmpty(defaultImageValue)) {
            setSelectedImages(defaultImageValue);
        }
    }, [defaultImageValue, setSelectedImages]);

    const handleOnSave = useCallback(() => {
        setIsShowingImageSelectionModal((prevState) => !prevState);
        if (!isEmpty(selectedImages)) {
            setValue(name, selectedImages, { shouldDirty: true });
        }
    }, [name, selectedImages, setValue]);

    const handleImageSelection = useCallback(
        (image) => {
            setSelectedImages(image);
        },
        [setSelectedImages]
    );

    const onDelete = useCallback(() => {
        setSelectedImages({});
        setValue(name, {}, { shouldDirty: true });
    }, [name, setSelectedImages, setValue]);

    return (
        <>
            {isShowingImageSelectionModal ? (
                <ImageSelectionContainer
                    imageType={imageType}
                    handleOnSave={handleOnSave}
                    toggleModal={toggleModal}
                    handleImageSelection={handleImageSelection}
                    selectedImages={selectedImages}
                    aspectRatio={aspectRatio.width / aspectRatio.height}
                />
            ) : null}
            {!isEmpty(selectedImages) ? (
                <>
                    <EuiFormRow label={label}>
                        <EuiImage
                            size='m'
                            hasShadow
                            alt={selectedImages.original_file_name}
                            src={selectedImages.image_url}
                        />
                    </EuiFormRow>
                    <EuiSpacer size='s' />
                </>
            ) : null}

            {!withRemove ? (
                <EuiFlexGroup justifyContent='flexStart'>
                    <EuiFlexItem grow={false}>
                        <EuiButtonEmpty disabled={isOutletMenu} onClick={toggleModal}>
                            {!selectedImages.image_url ? buttonLabel : selectedImgButtonLabel}
                        </EuiButtonEmpty>
                    </EuiFlexItem>
                </EuiFlexGroup>
            ) : (
                <EuiFlexGroup justifyContent='flexStart'>
                    <EuiFlexItem grow={false}>
                        <EuiButton fill disabled={isOutletMenu} onClick={toggleModal}>
                            {!selectedImages.image_url ? buttonLabel : selectedImgButtonLabel}
                        </EuiButton>
                    </EuiFlexItem>
                    {selectedImages.image_url ? (
                        <EuiFlexItem grow={false}>
                            <EuiButtonEmpty
                                fill
                                color='danger'
                                iconType='trash'
                                disabled={isOutletMenu}
                                onClick={() => {
                                    onDelete();
                                }}
                            >
                                Delete
                            </EuiButtonEmpty>
                        </EuiFlexItem>
                    ) : null}
                </EuiFlexGroup>
            )}
        </>
    );
};

export default React.memo(LanguageBannerImage);
