import React from 'react';
import {
    EuiOverlayMask,
    EuiModal,
    EuiModalHeader,
    EuiModalHeaderTitle,
    EuiModalBody,
    EuiFlexGroup,
    EuiFlexItem,
    EuiModalFooter,
    EuiButton,
    EuiTabbedContent,
    EuiForm,
    EuiFlyout,
    EuiFlyoutHeader,
    EuiTitle,
    EuiFlyoutBody,
    EuiFlyoutFooter,
    EuiButtonEmpty,
} from '@elastic/eui';
import { useDispatch, useSelector } from 'react-redux';
import * as types from '../../reduxStore/types/billing';
import ItemSplitTab from './orderItemSplitTab';
import PercentageSplitTab from './orderPercentageSplitTab';
import AmountSplitTab from './orderAmountSplitTab';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import { isEmpty } from 'lodash';
import API from '../../api/axios/API';

const OrderSplitModal = (props) => {
    const {
        register,
        handleSubmit,
        control,
        watch,
        reset,
        setValue,

        formState: { errors },
    } = useForm({
        defaultValues: {
            choices: [
                { name: 'Person 1', number: 50 },
                { name: 'Person 2', number: 50 },
            ],
            choicesAmount: [
                { name: 'Person 1', number: props.total / 2 },
                { name: 'Person 2', number: props.total / 2 },
            ],
        },
    });

    const { fields, append, remove } = useFieldArray({
        control,
        name: 'choicesItem',
    });

    let languageCode = useSelector((state) => state.language.language);
    const customerInformation = useSelector((state) => state.billing.customerInformation);
    const previousOrders = useSelector((state) => state.billing.previousOrders);

    const dispatch = useDispatch();

    const onCloseModal = () => {
        dispatch({
            type: types.SET_SPLIT_ORDER_MODAL,
            payload: {
                splitOrderModal: false,
            },
        });
    };

    const tabs = [
        {
            id: 'percentage',
            name: 'Percentage',
            content: (
                <PercentageSplitTab
                    outletId={props.outletId}
                    total={props.total}
                    watch={watch}
                    setValue={setValue}
                    errors={errors}
                    control={control}
                />
            ),
        },
        {
            id: 'amount',
            name: 'Amout',
            content: (
                <AmountSplitTab
                    outletId={props.outletId}
                    total={props.total}
                    watch={watch}
                    setValue={setValue}
                    errors={errors}
                    control={control}
                />
            ),
        },
        {
            id: 'Items',
            name: 'Items',
            content: (
                <ItemSplitTab
                    outletId={props.outletId}
                    watch={watch}
                    setValue={setValue}
                    errors={errors}
                    control={control}
                    fields={fields}
                    append={append}
                    remove={remove}
                />
            ),
        },
    ];

    const checkErrors = () => {
        let error = false;

        if (!isEmpty(errors)) {
            Object.entries(errors).forEach(([key, value], index) => {
                if (errors[key].message) {
                    error = true;
                }
            });
        }

        let total = 0;
        let record = watch();
        if (!isEmpty(record)) {
            Object.entries(record).forEach(([key, value], index) => {
                if (parseFloat(value) > 0) {
                    total += parseFloat(value);
                }

                if (parseFloat(value) < 0) {
                    error = true;
                }
            });

            if (total < 0 || total < 100 || total > 100 || !total) {
                error = true;
            }
        }

        return error;
    };

    const checkErrorsField = () => {
        let error = false;

        if (!isEmpty(errors)) {
            Object.entries(errors).forEach(([key, value], index) => {
                if (errors[key].message) {
                    error = true;
                }
            });
        }

        let total = 0;
        let record = watch();
        if (!isEmpty(record) && record.choices) {
            record.choices.map((choice) => {
                if (parseFloat(choice.number) > 0) {
                    total += parseFloat(choice.number);
                }

                if (parseFloat(choice.number) < 0) {
                    error = true;
                }
            });

            if (total < 0 || total < 100 || total > 100 || !total) {
                error = true;
            }
        } else if (!isEmpty(record) && record.choicesAmount) {
            record.choicesAmount.map((choiceAmount) => {
                if (parseFloat(choiceAmount.number) > 0) {
                    total += parseFloat(choiceAmount.number);
                }

                if (parseFloat(choiceAmount.number) < 0) {
                    error = true;
                }
            });

            if (total < 0 || total < props.total || total > props.total || !total) {
                error = true;
            }

            console.log('inside else total ', total);
        }

        return error;
    };

    const splitedItems = (data) => {
        let subItems = [];

        fields.map((field, index) => {
            let innerArray = [];

            field.data.map((item) => {
                innerArray.push({
                    cart_item_id: item.item.cart_item_id,
                    quantity: 1,
                });
            });

            let innerObj = {
                customer: {
                    name: data.choicesItem[index]['name'],
                    mobile_number: data.choicesItem[index]['mobile_number'],
                    country_code: customerInformation ? customerInformation.country_code : '+91',
                },
                items: innerArray,
            };

            subItems.push(innerObj);
        });

        return subItems;
    };

    const updatePrintStatus = async (data) => {
        let subItems = splitedItems(data);

        let splitedObj = {
            order_id: previousOrders[0].order_id,
            sub_bills: subItems,
            customer: { ...customerInformation },
        };

        let response;

        try {
            response = await API.put(
                `${languageCode}/pos/restaurants/:restaurantId/outlets/${props.outletId}/split-bill`,
                splitedObj
            );
        } catch (error) {
            response = error;
        }
    };

    const onSubmit = (data) => {
        console.log('data in item split', data);
        updatePrintStatus(data);
        document.getElementById('buttonClickForPrint').click();
    };

    return (
        <EuiForm component='form' onSubmit={handleSubmit(onSubmit)}>
            <EuiFlyout ownFocus onClose={onCloseModal} aria-labelledby='flyoutTitle'>
                <EuiFlyoutHeader hasBorder>
                    <EuiTitle size='m'>
                        <h2 id='flyoutTitle'>Split Orders</h2>
                    </EuiTitle>
                </EuiFlyoutHeader>
                <EuiFlyoutBody>
                    <EuiFlexGroup direction='column' style={{ padding: '10px' }}>
                        <EuiTabbedContent tabs={tabs} initialSelectedTab={tabs[0]} autoFocus='selected' />
                    </EuiFlexGroup>
                </EuiFlyoutBody>
                <EuiFlyoutFooter>
                    <EuiFlexGroup justifyContent='spaceBetween'>
                        <EuiFlexItem grow={false}>
                            <EuiButtonEmpty iconType='cross' onClick={onCloseModal} flush='left'>
                                Close
                            </EuiButtonEmpty>
                        </EuiFlexItem>
                        <EuiFlexItem grow={false}>
                            <EuiButton type={'submit'} disabled={checkErrorsField()} fill>
                                Save
                            </EuiButton>
                            {checkErrorsField() && (
                                <span style={{ color: 'red', fontSize: '12px' }}>Please check total</span>
                            )}
                        </EuiFlexItem>
                    </EuiFlexGroup>
                </EuiFlyoutFooter>
            </EuiFlyout>
        </EuiForm>
    );
};

export default OrderSplitModal;
