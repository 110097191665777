import React, { useEffect } from 'react';
import { EuiFlexGroup, EuiFlexItem } from '@elastic/eui';
import EltRider from '../../../../components/settings/outlet-form/elt_rider';
import DunzoRider from '../../../../components/settings/outlet-form/dunzo_rider';
import WeFastRider from '../../../../components/settings/outlet-form/wefast_rider';
import ShadowFaxRiders from '../../../../components/settings/outlet-form/shadow_fax_rider';
// import ScoutRider from './scout_rider';
import { useForm, FormProvider } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { outletSettingsAPI } from '../../../../api/outlet/outletSettingsAPI';
import * as types from '../../../../reduxStore/types/outlet';
import FormGroupDescription from '../../../../components/formGroupDescription';
import ButtonGroup from '../../../../components/Form/ButtonGroup';
import { userHasPermission } from '../../../../helper/auth';

const ThirdPartyRider = ({ outletId, getOutletData, fetchOutletById }) => {
    const methods = useForm();

    const dispatch = useDispatch();
    const permissions = useSelector((state) => state.permission.permission);
    const restaurantDescriptions = useSelector((state) => state.auth.restaurantDescriptions);

    useEffect(() => {
        if (!userHasPermission(permissions, '/outlet-settings', 'write')) return;
        if (methods.formState.isDirty) {
            dispatch({
                type: 'set-portal-active',
                payload: {
                    portalActive: {
                        resetChanges: resetChanges,
                        submit: methods.handleSubmit(onSubmit),
                    },
                },
            });
        } else {
            dispatch({
                type: 'set-portal-active',
                payload: {
                    portalActive: {},
                },
            });
        }
    }, [methods.formState.isDirty]);

    const resetChanges = () => {
        const outletData = getOutletData.outlet;
        let defaultValue = {};

        defaultValue.enable_own_rider_service = outletData.settings.enable_own_rider_service;

        //WeFast rider info
        defaultValue.wefast_auth_token = outletData.third_party_rider_setting
            ? outletData.third_party_rider_setting.wefast_auth_token
            : '';
        defaultValue.wefast_call_back_url = outletData.third_party_rider_setting
            ? outletData.third_party_rider_setting.wefast_call_back_url
            : '';
        defaultValue.wefast_integration_available = outletData.third_party_rider_setting
            ? outletData.third_party_rider_setting.wefast_integration_available
                ? 1
                : 0
            : 0;

        //Dunzo Rider info
        defaultValue.dunzo_client_secret = outletData.third_party_rider_setting
            ? outletData.third_party_rider_setting.dunzo_client_secret
            : '';
        defaultValue.dunzo_client_id = outletData.third_party_rider_setting
            ? outletData.third_party_rider_setting.dunzo_client_id
            : '';
        defaultValue.dunzo_call_back_url = outletData.third_party_rider_setting
            ? outletData.third_party_rider_setting.dunzo_call_back_url
            : '';
        defaultValue.dunzo_integration_available = outletData.third_party_rider_setting
            ? outletData.third_party_rider_setting.dunzo_integration_available
                ? 1
                : 0
            : 0;

        //ELT rider info
        defaultValue.elt_api_key = outletData.third_party_rider_setting
            ? outletData.third_party_rider_setting.elt_api_key
            : '';
        defaultValue.elt_primary_address_id = outletData.third_party_rider_setting
            ? outletData.third_party_rider_setting.elt_primary_address_id
            : '';
        defaultValue.elt_call_back_url = outletData.third_party_rider_setting
            ? outletData.third_party_rider_setting.elt_call_back_url
            : '';
        defaultValue.elt_integration_available = outletData.third_party_rider_setting
            ? outletData.third_party_rider_setting.elt_integration_available
                ? 1
                : 0
            : 0;

        // Shadow fax rider info
        defaultValue.shadowfax_token = outletData.third_party_rider_setting
            ? outletData.third_party_rider_setting.shadowfax_token
            : '';

        defaultValue.shadowfax_client_code = outletData.third_party_rider_setting
            ? outletData.third_party_rider_setting.shadowfax_client_code
            : '';

        defaultValue.is_shadowfax_integration_enabled = outletData.third_party_rider_setting
            ? parseInt(outletData.third_party_rider_setting.is_shadowfax_integration_enabled)
                ? 1
                : 0
            : 0;

        // set form default value
        methods.reset({
            ...defaultValue,
        });
    };

    useEffect(() => {
        if (getOutletData) {
            resetChanges();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getOutletData]);

    const onSubmit = async (data) => {
        dispatch(outletSettingsAPI('rider_settings', data)).then((res) => {
            if (res.success) {
                dispatch({
                    type: types.SET_PORTAL_ACTIVE,
                    payload: {
                        portalActive: {},
                    },
                });
                dispatch({
                    type: types.SET_PORTAL_ACTIVE_TAB_SWITCHED,
                    payload: {
                        tabSwitched: false,
                    },
                });
                fetchOutletById();
            } else {
                alert(res.message);
            }
        });
        // update WeFast information api call
        // try {
        //     const weFastRiderInfo = await API.patch(
        //         `restaurants/:restaurantId/outlets/${data.id}/third-party-rider-setting`,
        //         data
        //     );
        //     if (weFastRiderInfo.success) {
        //         alert('Edit Third party riders successfully..');
        //     }
        // } catch (e) {
        //     // error handle in api call
        //     if (e.message) {
        //         alert(e.message);
        //     } else {
        //         alert('Please try later');
        //     }
        // }
    };

    return (
        <FormProvider {...methods}>
            <EuiFlexGroup direction='column'>
                <EuiFlexItem>
                    <FormGroupDescription
                        title={restaurantDescriptions?.in_house_riders?.display_name}
                        description={restaurantDescriptions?.in_house_riders?.description}
                    >
                        <ButtonGroup
                            idSelected={methods.watch('enable_own_rider_service') ? '1' : '0'}
                            color='primary'
                            control={methods.control}
                            setValue={methods.setValue}
                            name='enable_own_rider_service'
                            watch={methods.watch}
                            label={'Enable own rider service'}
                        />
                    </FormGroupDescription>
                </EuiFlexItem>
                <EuiFlexItem>
                    <EltRider />
                </EuiFlexItem>
                <EuiFlexItem>
                    <DunzoRider fetchOutletById={fetchOutletById} getOutletData={getOutletData} />
                </EuiFlexItem>
                <EuiFlexItem>
                    <WeFastRider fetchOutletById={fetchOutletById} getOutletData={getOutletData} />
                </EuiFlexItem>
                <EuiFlexItem>
                    <ShadowFaxRiders fetchOutletById={fetchOutletById} getOutletData={getOutletData} />
                </EuiFlexItem>
                {/* <EuiFlexItem>
                <EuiText style={{paddingTop: '10px'}}>
                    <b>Scout Riders</b>
                </EuiText>
                <ScoutRider getOutletData={getOutletData} outletId={outletId}/>
            </EuiFlexItem> */}
            </EuiFlexGroup>
        </FormProvider>
    );
};

export default ThirdPartyRider;
