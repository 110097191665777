import { isEmpty } from 'lodash';

export const getErroMessage = (errors) => {
    let errorMessage = '';

    if (!isEmpty(errors.errors)) {
        for (var key in errors.errors) {
            errorMessage = errorMessage + errors.errors[key] + '\n';
        }
    }

    if (errors.message) {
        errorMessage += errors.message;
    }

    return errorMessage;
};
