import React, { memo, useEffect, useState } from 'react';
import API from '../../api/axios/API';
import { CSVDownload } from 'react-csv';

const OrderRatingCsvDownload = (props) => {
    const { selectedOutlet, order } = props;
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState({});
    const [error, setError] = useState(false);

    const getOrderRatings = async () => {
        await API.get(
            `/restaurants/:restaurantId/outlets/${selectedOutlet}/orders?page=1&per_page=${order.order_collection.orders.total}`
        )
            .then((res) => {
                setData(res);
                setIsLoading(false);
            })
            .catch(() => setError(true));
    };

    useEffect(() => {
        getOrderRatings();
    }, []);

    if (isLoading) return null;
    if (error) {
        alert('Error in downlaod..');
        // return
    }

    let orderArray = [];
    data &&
        data.order_collection &&
        data.order_collection.orders &&
        data.order_collection.orders.data &&
        data.order_collection.orders.data.map((order) =>
            orderArray.push({
                Name: order.customer.name,
                Date: order.status.placed_at,
                'Order id': order.order_label,
                'Order value': order.net_total.toFixed(),
                'Order Rating': order.feedback && order.feedback.star.toFixed(2),
                'Rider Rating': order.feedback && order.feedback.delivery_star.toFixed(2),
            })
        );

    return (
        <div>
            <CSVDownload data={orderArray} />;{props.csvStateCallback(false)}
        </div>
    );
};

export default memo(OrderRatingCsvDownload);
