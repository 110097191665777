import React, { useState, Fragment, useEffect, memo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SelectOutlet from '../../../components/banner/BannerLink/selectOutlet';
import SelectOffer from '../../../components/offer/OfferLink/selectOffer';
import OutletOfferList from '../../../components/offer/OfferLink/outletOfferList';
import API from '../../../api/axios/API';
import { EuiSpacer, EuiFlexGroup, EuiFlexItem, EuiLoadingContent, htmlIdGenerator } from '@elastic/eui';
import { SET_SELECTED_OUTLET_ID } from '../../../reduxStore/types/outlet';
import { SET_TOAST_STATE } from '../../../reduxStore/types/Global/Global';

const LinkOffer = () => {
    const languageID = useSelector((state) => state.language.languageId);
    const dispatch = useDispatch();

    // state for api maintaine
    const [offerLinkTabAPI, setOfferLinkTabAPI] = useState({
        loadingOutlet: true,
        loadingOffer: false,
        userOutlets: null,
        allOffers: null,
        errorForOutlet: false,
        errorForOffers: false,
    });

    // get all user's outlets of restaurant
    useEffect(() => {
        const allOutlets = async () => {
            try {
                const outlets = await API.get(`restaurants/:restaurantId/users/outlets`);
                if (outlets.success) {
                    setOfferLinkTabAPI((preState) => ({
                        ...preState,
                        loadingOutlet: false,
                        userOutlets: outlets,
                    }));
                }
            } catch (e) {
                setOfferLinkTabAPI((preState) => ({
                    ...preState,
                    loadingOutlet: false,
                    errorForOutlet: true,
                }));
            }
        };
        allOutlets();
    }, []);

    let outletList = [
        {
            value: '0',
            inputDisplay: 'Please select outlet',
        },
    ];

    offerLinkTabAPI.userOutlets &&
        offerLinkTabAPI.userOutlets.outlet &&
        offerLinkTabAPI.userOutlets.outlet.map((item, index) =>
            outletList.push({
                value: item.outlet_id,
                inputDisplay: `${item.translations.name[languageID]} `,
            })
        );
    const [outlet, setOutlet] = useState('0');
    const [offerList, setOfferList] = useState([]);
    const [outletOfferList, setOutletOfferList] = useState({
        totalPages: null,
        offers: null,
    });

    const updateOfferData = useCallback(
        async (outletID) => {
            try {
                const offerList = await API.get(`restaurants/:restaurantId/offers-titles`);
                let offerListForSelect = [];
                offerList.offers.map((item, index) =>
                    offerListForSelect.push({
                        value: item.offer_id,
                        inputDisplay: item.translations.title[languageID],
                    })
                );
                setOfferList(offerListForSelect);
            } catch (error) {}
            getOutletOfferList(outletID);
        },
        [languageID]
    );

    const getOutletOfferList = async (outletID) => {
        try {
            const offerList = await API.get(`restaurants/:restaurantId/outlets/${outletID}/offers`);
            setOutletOfferList((prevState) => ({
                ...prevState,
                offers: offerList.offers,
                totalPages: offerList.offers,
            }));
        } catch (e) {
            alert('error');
        }
    };

    const onChangeOutlet = async (value) => {
        if (value.toString() === '0' || outlet.toString() === value.toString()) return false;
        setOutlet(value);
        dispatch({
            type: SET_SELECTED_OUTLET_ID,
            payload: {
                selectedOutletId: value,
            },
        });
        await updateOfferData(value);
    };
    const onChangeOffer = async (value) => {
        try {
            await API.patch(`restaurants/:restaurantId/outlets/${outlet}/offers/${value}/link`);
            dispatch({
                type: SET_TOAST_STATE,
                payload: {
                    title: 'Offer Linked Successfully',
                    color: 'success',
                    id: htmlIdGenerator()(),
                },
            });
            await getOutletOfferList(outlet);
        } catch (error) {
            dispatch({
                type: SET_TOAST_STATE,
                payload: {
                    title: 'Oops, there was an error',
                    color: 'danger',
                    iconType: 'help',
                    id: htmlIdGenerator()(),
                },
            });
        }
    };
    const handleUnlink = useCallback(
        async (id) => {
            try {
                await API.patch(`restaurants/:restaurantId/outlets/${outlet}/offers/${id}/un-link`);
                dispatch({
                    type: SET_TOAST_STATE,
                    payload: {
                        title: 'Offer Unlinked Successfully',
                        color: 'success',
                        id: htmlIdGenerator()(),
                    },
                });
                await updateOfferData(outlet);
            } catch (e) {
                dispatch({
                    type: SET_TOAST_STATE,
                    payload: {
                        title: 'Please try later',
                        color: 'danger',
                        iconType: 'help',
                        id: htmlIdGenerator()(),
                    },
                });
            }
        },
        [dispatch, outlet, updateOfferData]
    );

    if (offerLinkTabAPI.loadingOutlet || offerLinkTabAPI.loadingOffer) return <EuiLoadingContent lines={4} />;

    // todo add appropriate error message
    if (offerLinkTabAPI.errorOutlet || offerLinkTabAPI.errorForOffers) return '';

    return (
        <Fragment>
            <EuiFlexGroup wrap style={{ marginTop: 10 }}>
                <EuiFlexItem>
                    <EuiFlexGroup alignItems='center'>
                        <EuiFlexItem grow={false}>Select Outlet</EuiFlexItem>
                        <EuiFlexItem>
                            <SelectOutlet outletList={outletList} outlet={outlet} onChangeOutlet={onChangeOutlet} />
                        </EuiFlexItem>
                    </EuiFlexGroup>
                </EuiFlexItem>
                <EuiFlexItem style={{ marginTop: 10 }}>
                    <EuiFlexGroup alignItems='center'>
                        <EuiFlexItem grow={false}>Select Offer</EuiFlexItem>
                        <EuiFlexItem>
                            <SelectOffer offerList={offerList} onChangeOffer={onChangeOffer} />
                        </EuiFlexItem>
                    </EuiFlexGroup>
                </EuiFlexItem>
            </EuiFlexGroup>
            <EuiSpacer />

            {/* display offer list */}
            {outletOfferList.offers?.length && (
                <OutletOfferList
                    outletOfferList={outletOfferList.offers}
                    setOutletOfferList={setOutletOfferList}
                    totalPageCount={outletOfferList.totalPages}
                    handleUnlink={handleUnlink}
                />
            )}
        </Fragment>
    );
};

export default memo(LinkOffer);
