import React, { useState, useEffect, useRef, useCallback } from 'react';
import {
    EuiFlexGroup,
    EuiFlexItem,
    EuiButton,
    EuiText,
    EuiTextColor,
    EuiForm,
    euiDragDropReorder,
    EuiButtonIcon,
    EuiOverlayMask,
    EuiConfirmModal,
    EuiLoadingContent,
    EuiCard,
    EuiSpacer,
    EuiHorizontalRule,
} from '@elastic/eui';
import { useForm, FormProvider, useWatch } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import * as types from '../../../../../reduxStore/types/menu';
import { isEmpty, isArray } from 'lodash';
import { userHasPermission } from '../../../../../helper/auth';
import API from '../../../../../api/axios/API';
import swal from 'sweetalert';
import VariantModal from '../../../../../components/menuComponentsRefactorCopy/menuEditorTab/variantModal';
import VariantAddonLinkModal from '../../../../../components/menuComponentsRefactorCopy/menuEditorTab/varaintAddonLinkModal';
import ItemPricing from './ItemPricing';
import ItemExpose from './ItemExpose';
import { multipleTimings } from '../../../../../components/menuComponentsRefactorCopy/menuEditorTab/utils';
import ItemBadges from '../../../../../components/menuComponentsRefactorCopy/menuEditorTab/ItemBadges';
import {
    SET_ACTIVE_ITEM_DETAILS,
    SET_BADGES_IDS,
    SET_SAVED_VARIANT_GROUP,
    UPDATE_ITEM_LIST,
} from '../../../../../components/menuComponentsRefactorCopy/Store/MenuTypes';
import MappedItemModal from '../../../../../components/menuComponentsRefactorCopy/menuEditorTab/MappedItemModal';
import ItemBasicDetails from './ItemBasicDetails';
import ItemAddonGroups from './ItemAddonGroups';
import ItemImage from './ItemImage';
import ItemTimings from './ItemTimings';
import ItemUpSellingLinks from '../../ItemUpSellingLinks';

const ItemDetailsEditor = () => {
    const badges_ids = useSelector((state) => state.menuReducer.badgesIds);
    const unlink_badges_ids = useSelector((state) => state.menuReducer.unLinkBadgesIds);
    const categories = useSelector((state) => state.menuReducer.restaurantCategories);
    const availableLanguages = useSelector((state) => state.language.availableLanguages);
    const editItem = useSelector((state) => state.menuReducer.activeItemDetails.details);
    const buttonDisable = useSelector((state) => state.menu.buttonDisable);
    const permissions = useSelector((state) => state.permission.permission);
    const themeMode = useSelector((state) => state.theme.theme);
    const activeItemId = useSelector((state) => state.menu.activeItemId);
    const addOnList = useSelector((state) => state.menuReducer.addons);
    const selectedItemEditAddOnList = useSelector((state) => state.menu.selectedItemEditAddOnList);
    const selectedItemEditUpsellingList = useSelector((state) => state.menu.selectedItemEditUpsellingList);
    const removedItemEditUpsellingList = useSelector((state) => state.menu.removedItemEditUpsellingList);
    const activeCategoryItemList = useSelector((state) => state.menu.activeCategoryItemList);
    const languageId = useSelector((state) => state.language.languageId);
    const variantGroupReducer = useSelector((state) => state.menu.variantGroup);
    const activeCategoryId = useSelector((state) => state.menu.activeCategoryId);
    // saved variantGroupReducer
    const variantModalSaved = useSelector((state) => state.menu.variantModalSaved);
    const variantAddonLink = useSelector((state) => state.menu.variantAddonLink);
    const variantGroupTranslations = useSelector((state) => state.menu.variantGroupTranslations);
    const newIdItemDelete = useSelector((state) => state.menu.newIdItemDelete);
    const mainSubmitClicked = useRef(false);
    const isDetailsLoading = useSelector((state) => state.menuReducer.activeItemDetails.isLoading);
    const [addonOptions, setAddonOptions] = useState([]);
    const [showError, setShowError] = useState(false);
    const [imageDeleteState, setImageDeleteState] = useState({ itemImage: {} });
    const [addonSelected, setAddonSelected] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [varaintModalState, setVaraintModalState] = useState(false);
    const [varaintAddonLinkModalState, setVaraintAddonLinkModalState] = useState(false);
    const [showItemModal, setShowItemModal] = useState(false);
    const orderingModes = useSelector((state) => state.menuReducer.restaurantOrderingModes);

    const isOutletMenu = useSelector((state) => state.menuReducer.isOutletMenu);
    const methods = useForm({});
    const {
        control,
        handleSubmit,
        formState: { errors },
        reset,
        watch,
    } = methods;

    const selectedOrderingModes = useWatch({
        control,
        name: 'ordering_modes',
    });

    useEffect(() => {
        if (addOnList?.length) {
            let addOnArray = [{ value: 0, text: 'Select addon', record: {} }];
            addOnList?.map((addon) =>
                addOnArray.push({
                    value: addon.addon_group_id,
                    text: `${addon.translations.group_name[languageId]} - ${addon.internal_name}`,
                    record: addon,
                })
            );

            if (addOnArray.length) {
                setAddonOptions(addOnArray);
            }
        }
    }, [addOnList, languageId]);

    useEffect(() => {
        if (categories && categories.categories && categories.categories.length > 0 && !activeItemId) {
            dispatch({
                type: types.EDIT_ITEM,
                payload: {
                    editItem:
                        categories.categories[0].items !== undefined && categories.categories[0].items.length > 0
                            ? categories.categories[0].items[0]
                            : {},
                    origin: 'itemDetails',
                },
            });

            if (
                categories.categories[0].items &&
                categories.categories[0].items.length > 0 &&
                categories.categories[0].items[0].addon_groups !== undefined &&
                categories.categories[0].items[0].addon_groups.length > 0
            ) {
                dispatch({
                    type: types.SET_ADDON_ITEM_EDIT_SELECTED_LIST,
                    payload: {
                        selectedItemEditAddOnList: categories.categories[0].items[0].addon_groups,
                    },
                });
            }

            // todo add default value for up selling item
        }
    }, [categories]);

    useEffect(() => {
        let id = '';
        let editItem = {};
        let editItemAddon = [];
        activeCategoryItemList.map((item) => {
            if (item.item_id === activeItemId) {
                id = item.item_id;
                editItem = item;
                editItemAddon = item.addon_groups && item.addon_groups.length > 0 ? item.addon_groups : [];
            }
        });

        if (!id && activeCategoryItemList && activeCategoryItemList.length > 0) {
            id = activeCategoryItemList[0].item_id;
            editItem = activeCategoryItemList[0];
            editItemAddon =
                activeCategoryItemList[0].addon_groups && activeCategoryItemList[0].addon_groups.length > 0
                    ? activeCategoryItemList[0].addon_groups
                    : [];
        }

        dispatch({
            type: types.EDIT_ITEM,
            payload: {
                editItem: editItem,
            },
        });

        dispatch({
            type: types.SET_ADDON_ITEM_EDIT_SELECTED_LIST,
            payload: {
                selectedItemEditAddOnList: editItemAddon,
            },
        });
    }, [activeCategoryItemList]);

    useEffect(() => {
        if (!isEmpty(editItem) && editItem.item_id !== 'newId' && activeItemId != 'newId') {
            setAddonSelected('');
            let editDefaultValue = { ...editItem };

            setImageDeleteState({
                itemImage: editDefaultValue.image,
            });

            availableLanguages.map((language) => {
                editDefaultValue[`title${language.language_id}`] =
                    editDefaultValue.translations?.title[language.language_id];
                editDefaultValue[`description${language.language_id}`] =
                    editDefaultValue.translations?.description[language.language_id];
            });

            let formatedSscheduleMap = {};
            if (editDefaultValue.time_slot_setting !== 'no_time_slots' && isArray(editDefaultValue.schedule)) {
                for (let scheduleElement of editDefaultValue.schedule) {
                    if (formatedSscheduleMap.hasOwnProperty(scheduleElement.time_slots.day_of_week)) {
                        formatedSscheduleMap[scheduleElement.time_slots.day_of_week].push({
                            start_time: scheduleElement.time_slots.start_time,
                            end_time: scheduleElement.time_slots.end_time,
                        });
                    } else {
                        formatedSscheduleMap[scheduleElement.time_slots.day_of_week] = [
                            {
                                start_time: scheduleElement.time_slots.start_time,
                                end_time: scheduleElement.time_slots.end_time,
                            },
                        ];
                    }
                }
            }

            // timeslots for multiple ordering modes
            // `timer_for_item_${orderingMode.restaurant_ordering_mode_id}_${orderingMode.restaurant_ordering_mode_name}`;
            if (editItem?.ordering_modes?.length) {
                for (const element of editItem.ordering_modes) {
                    let diffFormatedScheduleMap = {};
                    if (element.time_slot_setting !== 'no_time_slots' && isArray(element.schedules)) {
                        for (let scheduleElement of element.schedules) {
                            if (diffFormatedScheduleMap.hasOwnProperty(scheduleElement.day_of_week)) {
                                diffFormatedScheduleMap[scheduleElement.day_of_week].push({
                                    start_time: scheduleElement.start_time,
                                    end_time: scheduleElement.end_time,
                                });
                            } else {
                                diffFormatedScheduleMap[scheduleElement.day_of_week] = [
                                    {
                                        start_time: scheduleElement.start_time,
                                        end_time: scheduleElement.end_time,
                                    },
                                ];
                            }
                        }
                    }

                    editDefaultValue.week = {
                        ...editDefaultValue.week,
                        [`sunday_${element.restaurant_ordering_mode_id}_${element.restaurant_ordering_mode_name}`]: [],
                        [`monday_${element.restaurant_ordering_mode_id}_${element.restaurant_ordering_mode_name}`]: [],
                        [`tuesday_${element.restaurant_ordering_mode_id}_${element.restaurant_ordering_mode_name}`]: [],
                        [`wednesday_${element.restaurant_ordering_mode_id}_${element.restaurant_ordering_mode_name}`]:
                            [],
                        [`thursday_${element.restaurant_ordering_mode_id}_${element.restaurant_ordering_mode_name}`]:
                            [],
                        [`friday_${element.restaurant_ordering_mode_id}_${element.restaurant_ordering_mode_name}`]: [],
                        [`saturday_${element.restaurant_ordering_mode_id}_${element.restaurant_ordering_mode_name}`]:
                            [],
                    };

                    if (element.time_slot_setting === 'same_time_slot_for_all_days') {
                        editDefaultValue[
                            `time_${element.restaurant_ordering_mode_id}_${element.restaurant_ordering_mode_name}`
                        ] = diffFormatedScheduleMap['1'];
                        editDefaultValue[
                            `timer_for_item_${element.restaurant_ordering_mode_id}_${element.restaurant_ordering_mode_name}`
                        ] = `same_time_for_all_days_${element.restaurant_ordering_mode_id}`;
                    } else if (element.time_slot_setting === 'different_time_slots_for_different_days') {
                        editDefaultValue.week = {
                            ...editDefaultValue.week,
                            [`sunday_${element.restaurant_ordering_mode_id}_${element.restaurant_ordering_mode_name}`]:
                                [],
                            [`monday_${element.restaurant_ordering_mode_id}_${element.restaurant_ordering_mode_name}`]:
                                [],
                            [`tuesday_${element.restaurant_ordering_mode_id}_${element.restaurant_ordering_mode_name}`]:
                                [],
                            [`wednesday_${element.restaurant_ordering_mode_id}_${element.restaurant_ordering_mode_name}`]:
                                [],
                            [`thursday_${element.restaurant_ordering_mode_id}_${element.restaurant_ordering_mode_name}`]:
                                [],
                            [`friday_${element.restaurant_ordering_mode_id}_${element.restaurant_ordering_mode_name}`]:
                                [],
                            [`saturday_${element.restaurant_ordering_mode_id}_${element.restaurant_ordering_mode_name}`]:
                                [],
                        };

                        for (const key in diffFormatedScheduleMap) {
                            if (key == 7) {
                                editDefaultValue.week[
                                    `sunday_${element.restaurant_ordering_mode_id}_${element.restaurant_ordering_mode_name}`
                                ] = diffFormatedScheduleMap[key];
                            }
                            if (key == 1) {
                                editDefaultValue.week[
                                    `monday_${element.restaurant_ordering_mode_id}_${element.restaurant_ordering_mode_name}`
                                ] = diffFormatedScheduleMap[key];
                            }
                            if (key == 2) {
                                editDefaultValue.week[
                                    `tuesday_${element.restaurant_ordering_mode_id}_${element.restaurant_ordering_mode_name}`
                                ] = diffFormatedScheduleMap[key];
                            }
                            if (key == 3) {
                                editDefaultValue.week[
                                    `wednesday_${element.restaurant_ordering_mode_id}_${element.restaurant_ordering_mode_name}`
                                ] = diffFormatedScheduleMap[key];
                            }
                            if (key == 4) {
                                editDefaultValue.week[
                                    `thursday_${element.restaurant_ordering_mode_id}_${element.restaurant_ordering_mode_name}`
                                ] = diffFormatedScheduleMap[key];
                            }
                            if (key == 5) {
                                editDefaultValue.week[
                                    `friday_${element.restaurant_ordering_mode_id}_${element.restaurant_ordering_mode_name}`
                                ] = diffFormatedScheduleMap[key];
                            }
                            if (key == 6) {
                                editDefaultValue.week[
                                    `saturday_${element.restaurant_ordering_mode_id}_${element.restaurant_ordering_mode_name}`
                                ] = diffFormatedScheduleMap[key];
                            }
                        }
                        editDefaultValue[
                            `timer_for_item_${element.restaurant_ordering_mode_id}_${element.restaurant_ordering_mode_name}`
                        ] = `different_time_for_different_days_${element.restaurant_ordering_mode_id}`;

                        // console.log('set editDefaultValue', editDefaultValue);
                    } else {
                        editDefaultValue[
                            `timer_for_item_${element.restaurant_ordering_mode_id}_${element.restaurant_ordering_mode_name}`
                        ] = `same_day_of_the_week_${element.restaurant_ordering_mode_id}`;
                    }
                }
            }

            // console.log('editDefaultValue', editDefaultValue);

            let variantOptionArray = [];

            if (editItem.variants && editItem.variants.length) {
                // let innerArray = []

                editDefaultValue['variant_group_id'] = editItem.variants[0].id;
                editDefaultValue['variant_group_title'] = editItem.variants[0].translations.title[languageId];
                editDefaultValue['variant_group_description'] =
                    editItem.variants[0].translations.description[languageId];
                editDefaultValue['show_agmark'] = editItem.variants[0].show_agmark ? 1 : 0;

                for (let key in editItem.variants[0].translations.title) {
                    editDefaultValue[`variant_group_title-${key}`] = editItem.variants[0].translations.title[key];
                }

                for (let key in editItem.variants[0].translations.description) {
                    editDefaultValue[`variant_group_description-${key}`] =
                        editItem.variants[0].translations.description[key];
                }

                editItem.variants[0].options.map((option) => {
                    let innerObj = {
                        compare_price: option.compare_price,
                        price: option.price,
                        packaging_charge: option.packaging_charge,
                        gst_slab: option.gst_slab,
                        food_type: option.food_type,
                        image_url: option.image_url,
                        // id: option.id
                    };

                    for (let key in option.translations.title) {
                        innerObj[`variant_title-${key}`] = option.translations.title[key];
                    }

                    variantOptionArray.push(innerObj);
                });
            }
            let isEnabled = [];

            if (editItem.ordering_modes?.length) {
                editItem.ordering_modes.forEach((orderingMode) => {
                    orderingMode[`price_${orderingMode.restaurant_ordering_mode_id}`] = orderingMode.price;
                    orderingMode[`gst_slab_${orderingMode.restaurant_ordering_mode_id}`] = orderingMode.gst_slab
                        ? orderingMode.gst_slab
                        : 0;
                    orderingMode[`packaging_charge_${orderingMode.restaurant_ordering_mode_id}`] =
                        orderingMode.packaging_charge ? orderingMode.packaging_charge : 0;
                    orderingMode[`compare_price_${orderingMode.restaurant_ordering_mode_id}`] =
                        orderingMode.compare_price ? orderingMode.compare_price : 0;
                });

                const same_price = editItem.ordering_modes[0].price;
                const same_compare_price = editItem.ordering_modes[0].compare_price;
                const same_packaging = editItem.ordering_modes[0].packaging_charge;
                const same_gst_slab = editItem.ordering_modes[0].gst_slab;
                for (let index = 0; index < editItem.ordering_modes.length; index++) {
                    const element = editItem.ordering_modes[index];
                    if (
                        same_price == element.price &&
                        same_packaging == element.packaging_charge &&
                        same_gst_slab == element.gst_slab &&
                        same_compare_price == element.compare_price
                    ) {
                        isEnabled.push('enabled');
                    } else {
                        isEnabled.push('disabled');
                    }
                }
            }

            // handle time slots
            let isDifferentTimeSlotDifferent = [];
            const defaultSchedules = editItem?.ordering_modes[0]?.schedules;

            function arrayEquals(defaultArr, loopArr) {
                return (
                    Array.isArray(defaultArr) &&
                    Array.isArray(loopArr) &&
                    defaultArr.every((val, index) => {
                        return JSON.stringify(val) === JSON.stringify(loopArr[index]);
                    })
                );
            }

            for (let index = 0; index < editItem.ordering_modes.length; index++) {
                const element = editItem.ordering_modes[index];
                // const isEqual = arrayEquals(defaultSchedules, element?.schedules);
                const sameSchedules = defaultSchedules.filter(
                    (defaultSchedule) =>
                        !element?.schedules.some(
                            (modeSchedules) =>
                                modeSchedules.start_time === defaultSchedule.start_time &&
                                modeSchedules.end_time === defaultSchedule.end_time
                        )
                );
                // console.log('sameSchedules', sameSchedules);
                if (!sameSchedules?.length) {
                    isDifferentTimeSlotDifferent.push('disabled');
                } else {
                    isDifferentTimeSlotDifferent.push('enabled');
                }
            }

            const isDisabled = isEnabled?.find((enabled) => enabled === 'disabled') === 'disabled';
            editDefaultValue['itemImage'] = editItem.image;
            editDefaultValue['gst_slab'] = editItem.gst_slab;
            editDefaultValue['ordering_modes'] = editItem?.ordering_modes;
            for (const element of editItem?.ordering_modes) {
                editDefaultValue[
                    `${element.restaurant_ordering_mode_name}_${element.restaurant_ordering_mode_id}`
                ] = true;
                editDefaultValue[`quantity_${element.restaurant_ordering_mode_id}`] = element.quantity;
                editDefaultValue[`quantity_unit_id_${element.restaurant_ordering_mode_id}`] = element.quantity_unit_id;
                editDefaultValue[`serves_${element.restaurant_ordering_mode_id}`] = element.serves;
                editDefaultValue[`serves_unit_${element.restaurant_ordering_mode_id}`] = element.serves_unit;
            }
            editDefaultValue['quantity_unit_id'] = editItem?.ordering_modes?.[0]?.quantity_unit_id;
            editDefaultValue['price'] = editItem?.ordering_modes?.[0]?.price;
            editDefaultValue['packaging_charge'] = editItem?.ordering_modes?.[0]?.packaging_charge;
            editDefaultValue['gst_slab'] = editItem?.ordering_modes?.[0]?.gst_slab;
            editDefaultValue['compare_price'] = editItem?.ordering_modes?.[0]?.compare_price;
            editDefaultValue['quantity'] = editItem?.ordering_modes?.[0]?.quantity;
            editDefaultValue['serves'] = editItem?.ordering_modes?.[0]?.serves;
            editDefaultValue['serves_unit'] = editItem?.ordering_modes?.[0]?.serves_unit;
            // isDisabled

            if (isDifferentTimeSlotDifferent?.find((status) => status === 'enabled') !== 'enabled') {
                if (editItem.time_slot_setting === 'same_time_slot_for_all_days') {
                    editDefaultValue.time = formatedSscheduleMap['1'];
                    editDefaultValue['timer_for_item'] = 'same_time_for_all_days';
                } else if (editItem.time_slot_setting === 'different_time_slots_for_different_days') {
                    editDefaultValue.week = {
                        sunday: [],
                        monday: [],
                        tuesday: [],
                        wednesday: [],
                        thursday: [],
                        friday: [],
                        saturday: [],
                    };

                    for (const key in formatedSscheduleMap) {
                        if (key == 7) {
                            editDefaultValue.week.sunday = formatedSscheduleMap[key];
                        }
                        if (key == 1) {
                            editDefaultValue.week.monday = formatedSscheduleMap[key];
                        }
                        if (key == 2) {
                            editDefaultValue.week.tuesday = formatedSscheduleMap[key];
                        }
                        if (key == 3) {
                            editDefaultValue.week.wednesday = formatedSscheduleMap[key];
                        }
                        if (key == 4) {
                            editDefaultValue.week.thursday = formatedSscheduleMap[key];
                        }
                        if (key == 5) {
                            editDefaultValue.week.friday = formatedSscheduleMap[key];
                        }
                        if (key == 6) {
                            editDefaultValue.week.saturday = formatedSscheduleMap[key];
                        }
                    }
                    editDefaultValue['timer_for_item'] = 'different_time_for_different_days';
                } else {
                    editDefaultValue['timer_for_item'] = 'same_day_of_the_week';
                }
            }

            reset({
                ...editDefaultValue,
                is_enabled_same_price_details_for_all_ordering_modes: isDisabled ? true : false,
                is_enabled_same_time_slot_details_for_all_ordering_modes:
                    isDifferentTimeSlotDifferent?.find((status) => status === 'enabled') === 'enabled' ? true : false,
                badges: editItem?.badges,
                variantGroup: variantOptionArray.length
                    ? variantOptionArray
                    : [
                          {
                              title: '',
                              description: '',
                              price: editItem.price,
                              packaging_charge: editItem.packaging_charge,
                              compare_price: editItem.compare_price,
                              gst_slab: editItem.gst_slab,
                              food_type: editItem.food_type,
                          },
                          {
                              title: '',
                              description: '',
                              price: '',
                              packaging_charge: '',
                              compare_price: '',
                              gst_slab: '0',
                              food_type: 'vegetarian',
                          },
                      ],
                time: editDefaultValue.time ? editDefaultValue.time : [],
                week: editDefaultValue.week
                    ? editDefaultValue.week
                    : { sunday: [], monday: [], tuesday: [], wednesday: [], thursday: [], friday: [], saturday: [] },
            });
        } else {
            let editDefaultValue = {};
            availableLanguages.map((language) => {
                editDefaultValue[`title${language.language_id}`] = '';
                editDefaultValue[`description${language.language_id}`] = '';
            });
            editDefaultValue['internal_name'] = '';
            editDefaultValue['price'] = '';
            editDefaultValue['compare_price'] = '';
            editDefaultValue['packaging_charge'] = '';
            editDefaultValue['gst_slab'] = '0';
            editDefaultValue['itemImage'] = null;
            editDefaultValue['takeaway'] = '';
            editDefaultValue['dine_in'] = '';
            editDefaultValue['delivery'] = '';
            editDefaultValue['timer_for_item'] = 'same_day_of_the_week';
            editDefaultValue['food_type'] = options[1].value;
            editDefaultValue['upselling_item'] = false;
            editDefaultValue['ordering_modes'] = [];

            setImageDeleteState({
                itemImage: {},
            });

            dispatch({
                type: types.SET_ADDON_ITEM_EDIT_SELECTED_LIST,
                payload: {
                    selectedItemEditAddOnList: [],
                },
            });

            dispatch({
                type: types.SET_UPSELLING_ITEM_EDIT_SELECTED_LIST,
                payload: {
                    selectedItemEditUpsellingList: [],
                },
            });

            let defaultOrderingModes = [];

            if (orderingModes?.length) {
                defaultOrderingModes.push(orderingModes.filter((mode) => mode.status));
            }

            reset({
                ...editDefaultValue,
                is_enabled_same_price_details_for_all_ordering_modes: false,
                is_enabled_same_time_slot_details_for_all_ordering_modes: false,
                variantGroup: [
                    {
                        title: '',
                        description: '',
                        price: '',
                        packaging_charge: '',
                        compare_price: '',
                        gst_slab: '0',
                        food_type: 'vegetarian',
                    },
                    {
                        title: '',
                        description: '',
                        price: '',
                        packaging_charge: '',
                        compare_price: '',
                        gst_slab: '0',
                        food_type: 'vegetarian',
                    },
                ],
                time: [],
                week: { sunday: [], monday: [], tuesday: [], wednesday: [], thursday: [], friday: [], saturday: [] },
            });
        }
    }, [editItem, activeItemId, availableLanguages]);

    const dispatch = useDispatch();

    const [buttonLoader, setButtonLoader] = useState(false);

    const timeSlotData = (data, orderMode) => {
        let schedules;
        if (data.timer_for_item === 'same_day_of_the_week' || data.timer_for_item === true) {
            schedules = null;
            data.time_slot_setting = 'no_time_slots';
        } else if (data.timer_for_item === 'same_time_for_all_days') {
            data.time_slot_setting = 'same_time_slot_for_all_days';
            let time =
                data.time &&
                data.time.length > 0 &&
                data.time.filter((item, index) => {
                    if (item.start_time !== '' && item.end_time !== '') {
                        return item;
                    }
                });
            if (time) {
                schedules = [
                    {
                        days_of_week: [1, 2, 3, 4, 5, 6, 7],
                        time_slots: time,
                    },
                ];
            } else {
                schedules = [];
            }
        } else {
            schedules = [];
            data.time_slot_setting = 'different_time_slots_for_different_days';
            let sunday =
                data.week &&
                data.week.sunday &&
                data.week.sunday.filter((item, index) => {
                    if (item.start_time !== '' && item.end_time !== '') {
                        return item;
                    }
                });
            sunday &&
                sunday.length > 0 &&
                schedules.push({
                    days_of_week: [7],
                    time_slots: sunday,
                });
            let monday =
                data.week &&
                data.week.monday &&
                data.week.monday.filter((item, index) => {
                    if (item.start_time !== '' && item.end_time !== '') {
                        return item;
                    }
                });
            monday &&
                monday.length > 0 &&
                schedules.push({
                    days_of_week: [1],
                    time_slots: monday,
                });
            let tuesday =
                data.week &&
                data.week.tuesday &&
                data.week.tuesday.filter((item, index) => {
                    if (item.start_time !== '' && item.end_time !== '') {
                        return item;
                    }
                });
            tuesday &&
                tuesday.length > 0 &&
                schedules.push({
                    days_of_week: [2],
                    time_slots: tuesday,
                });
            let wednesday =
                data.week &&
                data.week.wednesday &&
                data.week.wednesday.filter((item, index) => {
                    if (item.start_time !== '' && item.end_time !== '') {
                        return item;
                    }
                });
            wednesday &&
                wednesday.length > 0 &&
                schedules.push({
                    days_of_week: [3],
                    time_slots: wednesday,
                });
            let thursday =
                data.week &&
                data.week.thursday &&
                data.week.thursday.filter((item, index) => {
                    if (item.start_time !== '' && item.end_time !== '') {
                        return item;
                    }
                });
            thursday &&
                thursday.length > 0 &&
                schedules.push({
                    days_of_week: [4],
                    time_slots: thursday,
                });
            let friday =
                data.week &&
                data.week.friday &&
                data.week.friday.filter((item, index) => {
                    if (item.start_time !== '' && item.end_time !== '') {
                        return item;
                    }
                });
            friday &&
                friday.length > 0 &&
                schedules.push({
                    days_of_week: [5],
                    time_slots: friday,
                });
            let saturday =
                data.week &&
                data.week.saturday &&
                data.week.saturday.filter((item, index) => {
                    if (item.start_time !== '' && item.end_time !== '') {
                        return item;
                    }
                });
            saturday &&
                saturday.length > 0 &&
                schedules.push({
                    days_of_week: [6],
                    time_slots: saturday,
                });
        }
        data.schedules = schedules;
        if (orderMode) {
            orderMode.schedules = schedules;
            orderMode.time_slot_setting = data.time_slot_setting;
        }
    };

    const [stateUpdate, setStateUpdate] = useState(false);

    const imageChecker = (data) => {
        if (data.itemImage) {
            if (imageDeleteState.itemImage && data.itemImage.id !== imageDeleteState.itemImage.id) {
                try {
                    API.delete(`/restaurants/:restaurantId/uploads/${imageDeleteState.itemImage.id}`);
                } catch (error) {
                    console.log('error in image delete ', error);
                }
            }
        } else {
            if (!isEmpty(imageDeleteState.itemImage)) {
                try {
                    API.delete(`/restaurants/:restaurantId/uploads/${imageDeleteState.itemImage.id}`);
                } catch (error) {
                    console.log('error in image delete ', error);
                }

                data.itemImage = imageDeleteState.itemImage;
            }
        }
    };

    useEffect(() => {
        dispatch({
            type: types.SET_VARIANT_GROUP,
            payload: {
                variantGroup: [],
            },
        });
        dispatch({
            type: types.SET_VARIANT_ADDON_LINK,
            payload: {
                variantAddonLink: {},
            },
        });
        dispatch({
            type: types.SET_VARIANT_GROUP_TRANSLATIONS,
            payload: {
                variantGroupTranslations: {},
            },
        });
        dispatch({
            type: types.SET_VARIANT_MODAL_SAVED,
            payload: {
                variantModalSaved: false,
            },
        });
        return function cleanup() {
            dispatch({
                type: SET_BADGES_IDS,
                payload: [],
            });
            dispatch({
                type: SET_SAVED_VARIANT_GROUP,
                payload: {},
            });
            setShowError(false);
            setStateUpdate(false);
        };
    }, [editItem?.category_id, activeItemId, dispatch]);

    const variantDataFormat = () => {
        let variants = [];
        if (variantModalSaved) {
            if (variantGroupReducer.length) {
                let optionArray = [];
                let translationObj = {};
                let description = {};
                availableLanguages.map((language) => {
                    translationObj[language.language_id] =
                        variantGroupTranslations[`variant_group_title-${language.language_id}`];
                    description[language.language_id] =
                        variantGroupTranslations[`variant_group_description-${language.language_id}`];
                });
                variants[0] = {
                    translations: { title: translationObj, description: description },
                    show_agmark: variantGroupTranslations.show_agmark ? variantGroupTranslations.show_agmark : 0,
                    variant_group_id: variantGroupTranslations.variant_group_id,
                };

                variantGroupReducer.map((variant, index) => {
                    let translationObject = {};

                    availableLanguages.map((language) => {
                        translationObject[language.language_id] = variant[`variant_title-${language.language_id}`];
                    });

                    if (variantAddonLink[variant.id]) {
                        let addon_group_ids = [];
                        variantAddonLink[variant.id].map((option) => {
                            addon_group_ids.push(option.addon_group_id);
                        });
                        variant.addon_group_ids = addon_group_ids;
                    } else if (
                        editItem.variants &&
                        editItem.variants.length &&
                        editItem.variants[0].options &&
                        editItem.variants[0].options.length &&
                        editItem?.variants[0]?.options[index]?.addons?.length
                    ) {
                        let addon_group_ids = [];

                        editItem.variants[0].options[index].addons.map((addon) => {
                            addon_group_ids.push(addon.addon_group_id);
                        });
                        variant.addon_group_ids = addon_group_ids;

                        // variant.addon_group_ids = editItem.variants[0].options
                    }

                    optionArray.push({
                        ...variant,
                        image_id: variant.image_url ? variant.image_url.id : null,
                        translations: { title: translationObject },
                    });
                });
                variants[0].options = optionArray;
            }
        } else if (editItem.variants && editItem.variants.length) {
            editItem.variants[0].variant_group_id = editItem.variants[0].id;
            if (!isEmpty(variantAddonLink)) {
                editItem.variants[0].options.map((option) => {
                    let addon_group_ids = [];
                    variantAddonLink[option.id].map((option) => {
                        addon_group_ids.push(option.addon_group_id);
                    });

                    option.addon_group_ids = addon_group_ids;
                });

                variants = [...editItem.variants];
            } else {
                editItem.variants[0].options.map((option) => {
                    let addon_group_ids = [];
                    option.addons.map((addon) => {
                        addon_group_ids.push(addon.addon_group_id);
                    });

                    option.addon_group_ids = addon_group_ids;
                });

                variants = [...editItem.variants];
            }
        }

        return variants;
    };

    const dataPriceFormat = (data, name) => {
        if (name === 'gst_slab') {
            if (variantGroupReducer.length) {
                return parseFloat(variantGroupReducer[0][name]);
            } else if (editItem?.variants?.length) {
                return editItem.variants[0].options[0][name];
            } else {
                return data[name];
            }
        }
        if (data[name] !== '') {
            return data[name];
        } else {
            if (variantGroupReducer.length) {
                return parseFloat(variantGroupReducer[0][name]);
            } else if (editItem?.variants?.length) {
                return editItem.variants[0].options[0][name];
            }
        }
    };

    const editItemId = async (
        data,
        addOnArray,
        translationObject,
        variantsStructuredData,
        upSellingItems,
        removedItemIds
    ) => {
        let res;
        try {
            res = await API.put(`/restaurants/:restaurantId/categories/${activeCategoryId}/items/${editItem.item_id}`, {
                schedules: data.time_slot_setting !== 'no_time_slots' ? data.schedules : null,
                time_slot_setting: data.time_slot_setting,
                internal_name: data.internal_name,
                hidden: 0,
                best_seller: data.best_seller ? 1 : 0,
                must_try: data.must_try ? 1 : 0,
                show_agmark: 1,
                delivery: data.delivery ? 1 : 0,
                dine_in: data.dine_in ? 1 : 0,
                takeaway: data.takeaway ? 1 : 0,
                upselling_item_ids: upSellingItems,
                upselling_item: data?.upselling_item ? data.upselling_item : false,
                image_id: data.itemImage ? data.itemImage.id : null,
                packaging_charge: dataPriceFormat(data, 'packaging_charge'),
                price: dataPriceFormat(data, 'price'),
                compare_price: dataPriceFormat(data, 'compare_price'),
                food_type: dataPriceFormat(data, 'food_type'),
                gst_slab: parseFloat(dataPriceFormat(data, 'gst_slab')),
                addon_groups: addOnArray,
                translations: translationObject,
                variants: variantsStructuredData,
                ordering_modes: data.ordering_modes,
                delete_ordering_modes: data?.delete_ordering_modes ? data.delete_ordering_modes : [],
                badges_ids: badges_ids?.length ? badges_ids : [],
                delete_upselling_item_ids: removedItemIds?.length ? removedItemIds : [],
                delete_badge_ids: unlink_badges_ids?.length ? unlink_badges_ids : [],
            });
        } catch (error) {
            setButtonLoader(false);
            res = error;
        }

        if (res.success) {
            dispatch({
                type: types.SET_CATEGORY_STATE,
                payload: {
                    categoryState: {},
                },
            });
            setErrorMessage('');
            swal('Item successfully edited...', '', 'success');
            setButtonLoader(false);
        } else {
            setErrorMessage(res.errors);
            swal('Fail edit item...', '', 'error');
            setButtonLoader(false);
        }
    };

    const addItemId = async (
        data,
        addOnArray,
        translationObject,
        variantsStructuredData,
        upSellingItems,
        removedItemIds
    ) => {
        let res;
        try {
            res = await API.post(`/restaurants/:restaurantId/categories/${activeCategoryId}/items`, {
                schedules: data.time_slot_setting !== 'no_time_slots' ? data.schedules : null,
                time_slot_setting: data.time_slot_setting,
                internal_name: data.internal_name,
                hidden: 0,
                best_seller: data.best_seller ? 1 : 0,
                must_try: data.must_try ? 1 : 0,
                show_agmark: 1,
                delivery: data.delivery ? 1 : 0,
                dine_in: data.dine_in ? 1 : 0,
                takeaway: data.takeaway ? 1 : 0,
                upselling_item: data.upselling_item,
                upselling_item_ids: upSellingItems,
                image_id: data.itemImage ? data.itemImage.id : null,
                packaging_charge: dataPriceFormat(data, 'packaging_charge'),
                price: dataPriceFormat(data, 'price'),
                compare_price: dataPriceFormat(data, 'compare_price'),
                food_type: dataPriceFormat(data, 'food_type'),
                gst_slab: parseFloat(dataPriceFormat(data, 'gst_slab')),
                addon_groups: addOnArray,
                translations: translationObject,
                variants: variantsStructuredData,
                ordering_modes: data.ordering_modes,
                delete_ordering_modes: data?.delete_ordering_modes ? data.delete_ordering_modes : [],
                badges_ids: badges_ids?.length ? badges_ids : [],
                delete_upselling_item_ids: removedItemIds?.length ? removedItemIds : [],
                delete_badge_ids: unlink_badges_ids?.length ? unlink_badges_ids : [],
            });
        } catch (error) {
            setButtonLoader(false);
            res = error;
        }

        if (res.success) {
            dispatch({
                type: types.SET_CATEGORY_STATE,
                payload: {
                    categoryState: {},
                },
            });
            setErrorMessage('');
            swal('Item successfully edited...', '', 'success');
            setButtonLoader(false);
        } else {
            setErrorMessage(res.errors);
            swal('Fail edit item...', '', 'error');
            setButtonLoader(false);
        }
    };

    const EditItem = async (data) => {
        if (!mainSubmitClicked.current) return;
        setButtonLoader(true);

        if (activeItemId === 'newId') {
            if (!data.is_enabled_same_price_details_for_all_ordering_modes) {
                // Same Price For Every Ordering Mode
                data?.ordering_modes.forEach((orderMode) => {
                    orderMode.price = data.price;
                    orderMode.compare_price = data.compare_price;
                    orderMode.gst_slab = parseFloat(data.gst_slab);
                    orderMode.packaging_charge = data.packaging_charge;
                    orderMode.quantity_unit_id = data[`quantity_unit_id`];
                    orderMode.quantity = data[`quantity`];
                    orderMode.serves = data[`serves`];
                    orderMode.serves_unit = data[`serves_unit`];
                    orderMode[`restaurant_ordering_mode_id`] = orderMode?.id
                        ? orderMode.id
                        : orderMode?.restaurant_ordering_mode_id
                        ? orderMode.restaurant_ordering_mode_id
                        : null;
                    timeSlotData(data, orderMode);
                });
            } else {
                // Handle Different Prices & Time Slots for Multiple Ordering Modes
                data?.ordering_modes.forEach((orderMode) => {
                    orderMode.price = data[`price_${orderMode.restaurant_ordering_mode_id}`];
                    orderMode.compare_price = data[`compare_price_${orderMode.restaurant_ordering_mode_id}`]
                        ? parseFloat(data[`compare_price_${orderMode.restaurant_ordering_mode_id}`])
                        : 0;
                    orderMode.gst_slab = data[`gst_slab_${orderMode.restaurant_ordering_mode_id}`]
                        ? data[`gst_slab_${orderMode.restaurant_ordering_mode_id}`]
                        : 0;
                    orderMode.packaging_charge = data[`packaging_charge_${orderMode.restaurant_ordering_mode_id}`]
                        ? data[`packaging_charge_${orderMode.restaurant_ordering_mode_id}`]
                        : 0;
                    orderMode.quantity_unit_id = data[`quantity_unit_id_${orderMode.restaurant_ordering_mode_id}`];
                    orderMode.quantity = data[`quantity_${orderMode.restaurant_ordering_mode_id}`];
                    orderMode.serves = data[`serves_${orderMode.restaurant_ordering_mode_id}`];
                    orderMode.serves_unit = data[`serves_unit_${orderMode.restaurant_ordering_mode_id}`];

                    // multipleTimings(orderMode, data);
                });
            }
            if (!data.is_enabled_same_time_slot_details_for_all_ordering_modes) {
                data?.ordering_modes.forEach((orderMode) => {
                    multipleTimings(orderMode, data);
                });
            } else {
                data?.ordering_modes.forEach((orderMode) => {
                    timeSlotData(data, orderMode);
                });
            }
        } else if (data?.variants?.[0]?.options?.length) {
            // Handle Variants Pricing
            const variant_option = data.variants[0].options[0];
            data?.ordering_modes.forEach((orderMode) => {
                orderMode.price = variant_option[`price`];
                orderMode.compare_price = variant_option[`compare_price`] ? variant_option[`compare_price`] : 0;
                orderMode.gst_slab = variant_option[`gst_slab`] ? parseFloat(variant_option[`gst_slab`]) : 0;
                orderMode.packaging_charge = variant_option[`packaging_charge`]
                    ? variant_option[`packaging_charge`]
                    : 0;
                // orderMode[`restaurant_ordering_mode_id`] = orderMode.restaurant_ordering_mode_id;
                // FOR VARIANTS
                orderMode.quantity_unit_id =
                    variant_option[`quantity_unit_id_${orderMode.restaurant_ordering_mode_id}`];
                orderMode.quantity = variant_option[`quantity_${orderMode.restaurant_ordering_mode_id}`];
                orderMode.serves = variant_option[`serves_${orderMode.restaurant_ordering_mode_id}`];
                orderMode.serves_unit = variant_option[`serves_unit_${orderMode.restaurant_ordering_mode_id}`];
                orderMode[`restaurant_ordering_mode_id`] = orderMode?.id
                    ? orderMode.id
                    : orderMode?.restaurant_ordering_mode_id
                    ? orderMode.restaurant_ordering_mode_id
                    : null;
                if (!data.is_enabled_same_time_slot_details_for_all_ordering_modes) {
                    timeSlotData(data, orderMode);
                } else {
                    multipleTimings(orderMode, data);
                }
            });
        } else if (
            !data.is_enabled_same_price_details_for_all_ordering_modes &&
            !data.is_enabled_same_time_slot_details_for_all_ordering_modes
        ) {
            // Same Price For Every Ordering Mode
            data?.ordering_modes.forEach((orderMode) => {
                orderMode.price = data.price;
                orderMode.compare_price = data.compare_price;
                orderMode.gst_slab = parseFloat(data.gst_slab);
                orderMode.packaging_charge = data.packaging_charge;
                orderMode.quantity_unit_id = data[`quantity_unit_id`];
                orderMode.quantity = data[`quantity`];
                orderMode.serves = data[`serves`];
                orderMode.serves_unit = data[`serves_unit`];
                orderMode[`restaurant_ordering_mode_id`] = orderMode?.id
                    ? orderMode.id
                    : orderMode?.restaurant_ordering_mode_id
                    ? orderMode.restaurant_ordering_mode_id
                    : null;
                timeSlotData(data, orderMode);
            });
        } else if (!data.is_enabled_same_time_slot_details_for_all_ordering_modes) {
            data?.ordering_modes.forEach((orderMode) => {
                orderMode[`restaurant_ordering_mode_id`] = orderMode?.id
                    ? orderMode.id
                    : orderMode?.restaurant_ordering_mode_id
                    ? orderMode.restaurant_ordering_mode_id
                    : null;
                timeSlotData(data, orderMode);
            });
        } else if (!data.is_enabled_same_price_details_for_all_ordering_modes) {
            // Same Price For Every Ordering Mode
            data?.ordering_modes.forEach((orderMode) => {
                orderMode.price = data.price;
                orderMode.compare_price = data.compare_price;
                orderMode.gst_slab = parseFloat(data.gst_slab);
                orderMode.packaging_charge = data.packaging_charge;
                orderMode.quantity_unit_id = data[`quantity_unit_id`];
                orderMode.quantity = data[`quantity`];
                orderMode.serves = data[`serves`];
                orderMode.serves_unit = data[`serves_unit`];
                orderMode[`restaurant_ordering_mode_id`] = orderMode?.id
                    ? orderMode.id
                    : orderMode?.restaurant_ordering_mode_id
                    ? orderMode.restaurant_ordering_mode_id
                    : null;
                timeSlotData(data, orderMode);
            });
        } else {
            // Handle Different Prices & Time Slots for Multiple Ordering Modes
            data?.ordering_modes.forEach((orderMode) => {
                orderMode.price = data[`price_${orderMode.restaurant_ordering_mode_id}`];
                orderMode.compare_price = data[`compare_price_${orderMode.restaurant_ordering_mode_id}`]
                    ? data[`compare_price_${orderMode.restaurant_ordering_mode_id}`]
                    : 0;
                orderMode.gst_slab = data[`gst_slab_${orderMode.restaurant_ordering_mode_id}`]
                    ? parseFloat(data[`gst_slab_${orderMode.restaurant_ordering_mode_id}`])
                    : 0;
                orderMode.packaging_charge = data[`packaging_charge_${orderMode.restaurant_ordering_mode_id}`]
                    ? data[`packaging_charge_${orderMode.restaurant_ordering_mode_id}`]
                    : 0;
                orderMode.quantity_unit_id = data[`quantity_unit_id_${orderMode.restaurant_ordering_mode_id}`];
                orderMode.quantity = data[`quantity_${orderMode.restaurant_ordering_mode_id}`];
                orderMode.serves = data[`serves_${orderMode.restaurant_ordering_mode_id}`];
                orderMode.serves_unit = data[`serves_unit_${orderMode.restaurant_ordering_mode_id}`];
                orderMode[`restaurant_ordering_mode_id`] = orderMode?.id
                    ? orderMode.id
                    : orderMode?.restaurant_ordering_mode_id
                    ? orderMode.restaurant_ordering_mode_id
                    : null;

                multipleTimings(orderMode, data);
            });
        }

        // different timeslots issue fix

        if (data.is_enabled_same_time_slot_details_for_all_ordering_modes) {
            data?.ordering_modes.forEach((orderMode) => {
                multipleTimings(orderMode, data);
            });
        }

        // imageChecker(data)

        await timeSlotData(data);
        let variantsStructuredData = variantDataFormat();
        // variantsStructuredData stores all the functions/proprties about variants

        // if (!data.is_enabled_same_price_details_for_all_ordering_modes) {
        //     if (data.time_slot_setting !== 'no_time_slots') {
        //         if (data.time_slot_setting === 'different_time_slots_for_different_days' && data.schedules.length < 1) {
        //             setStateUpdate(true);
        //             return;
        //         } else if (data.time_slot_setting === 'same_time_slot_for_all_days' && data.schedules.length < 1) {
        //             setStateUpdate(true);
        //             return;
        //         } else {
        //             setStateUpdate(false);
        //         }
        //     } else {
        //         setStateUpdate(false);
        //     }
        // } else {
        //     const isScheduleNotSelected = data?.ordering_modes?.filter((orderMode) => {
        //         if (orderMode.time_slot_setting !== 'no_time_slots') {
        //             if (
        //                 orderMode.time_slot_setting === 'different_time_slots_for_different_days' &&
        //                 orderMode.schedules.length < 1
        //             ) {
        //                 return orderMode;
        //             } else if (
        //                 orderMode.time_slot_setting === 'same_time_slot_for_all_days' &&
        //                 orderMode.schedules.length < 1
        //             ) {
        //                 return orderMode;
        //             }
        //         }
        //     });

        //     if (isScheduleNotSelected?.length) {
        //         setStateUpdate(true);
        //     } else {
        //         setStateUpdate(false);
        //     }
        // }

        let titles = {};
        let descriptions = {};

        availableLanguages.map((language) => {
            titles[language.language_id] = data[`title${language.language_id}`];
            descriptions[language.language_id] = data[`description${language.language_id}`];
        });

        let translationObject = {
            title: titles,
            description: descriptions,
        };
        let addOnArray = [];
        let upSellingItems = [];

        selectedItemEditAddOnList?.forEach((addon, index) => {
            addOnArray.push({
                addon_group_id: addon.addon_group_id,
                display_sequence: index + 1,
            });
        });
        selectedItemEditUpsellingList?.forEach((upSellingItem) => {
            upSellingItems.push(upSellingItem?.item_id ? upSellingItem.item_id : upSellingItem);
        });
        setStateUpdate(false);

        let removedItemIds = [];

        if (removedItemEditUpsellingList?.length && editItem?.upselling_items?.length) {
            for (const upselling_item of editItem.upselling_items) {
                for (const removedItemId of removedItemEditUpsellingList) {
                    if (upselling_item.item_id === removedItemId) {
                        removedItemIds.push(removedItemId);
                    }
                }
            }
        }

        if (activeItemId !== 'newId') {
            editItemId(data, addOnArray, translationObject, variantsStructuredData, upSellingItems, removedItemIds);
        } else {
            addItemId(data, addOnArray, translationObject, variantsStructuredData, upSellingItems, removedItemIds);
        }

        mainSubmitClicked.current = false;
    };

    const onChangeAddon = (value) => {
        let selectedList = [...selectedItemEditAddOnList];
        let objectExist = false;

        parseInt(value) !== 0 &&
            addonOptions?.map((addon) => {
                if (value === addon.value.toString()) {
                    selectedItemEditAddOnList.map((addon) => {
                        if (addon.addon_group_id.toString() === value) {
                            objectExist = true;
                        }
                    });
                    if (!objectExist) {
                        selectedList.push(addon.record);
                        dispatch({
                            type: types.SET_ADDON_ITEM_EDIT_SELECTED_LIST,
                            payload: {
                                selectedItemEditAddOnList: selectedList,
                            },
                        });
                    }
                }
            });

        setAddonSelected(value);
    };

    const onAddonDelete = (index) => {
        let addonArray = [...selectedItemEditAddOnList];
        addonArray.splice(index, 1);
        dispatch({
            type: types.SET_ADDON_ITEM_EDIT_SELECTED_LIST,
            payload: {
                selectedItemEditAddOnList: addonArray,
            },
        });
    };

    useEffect(() => {
        return function cleanup(params) {
            dispatch({
                type: types.REMOVE_UPSELLING_ITEM_EDIT_SELECTED_LIST,
                payload: {
                    removedItemEditUpsellingList: [],
                },
            });
        };
    }, [dispatch]);

    const onDragEnd = ({ source, destination }) => {
        if (source && destination) {
            const items = euiDragDropReorder(selectedItemEditAddOnList, source.index, destination.index);

            dispatch({
                type: types.SET_ADDON_ITEM_EDIT_SELECTED_LIST,
                payload: {
                    selectedItemEditAddOnList: items,
                },
            });
        }
    };

    const displayErrorServer = () => {
        let errorsList = [];
        if (!isEmpty(errorMessage)) {
            for (var key in errorMessage) {
                errorsList.push(<EuiTextColor color='danger'>{errorMessage[key]}</EuiTextColor>);
            }
        }
        return errorsList;
    };

    const displayError = (errors) => {
        let innerArray = [];
        if (!isEmpty(errors)) {
            for (var key in errors) {
                innerArray.push(
                    <EuiText>
                        <ul>
                            <li>{errors[key].message}</li>
                        </ul>
                    </EuiText>
                );
            }
        }
        return innerArray;
    };

    const priceRef = useRef(null);
    const comparePriceRef = useRef(null);
    const packagingPriceRef = useRef(null);

    const variantConditionForPrice = () => {
        if (variantModalSaved) {
            return !variantGroupReducer.length;
        } else {
            return !(editItem.variants && editItem.variants.length);
        }
    };
    const [mapItemModal, setIsMapItemModal] = useState(false);
    const handleMapItemModal = useCallback(() => {
        setIsMapItemModal((prevState) => !prevState);
    }, []);

    const onConfirmItemDelete = async () => {
        if (activeItemId != 'newId') {
            let deleteItem = await API.delete(
                `restaurants/:restaurantId/categories/${activeCategoryId}/items/${editItem.item_id}`
            );

            if (deleteItem.success) {
                dispatch({
                    type: types.SET_CATEGORY_STATE,
                    payload: {
                        categoryState: {},
                    },
                });
                dispatch({
                    type: UPDATE_ITEM_LIST,
                    payload: { update: true, details: editItem },
                });
                dispatch({
                    type: SET_ACTIVE_ITEM_DETAILS,
                    payload: { details: {}, isLoading: false },
                });
                if (editItem.item_id === activeItemId) {
                    dispatch({
                        type: types.EDIT_ITEM,
                        payload: {
                            editItem: {},
                        },
                    });
                }
            }

            setShowItemModal(false);
        } else {
            dispatch({
                type: types.SET_NEW_ITEM_ID_DELETE,
                payload: {
                    newIdItemDelete: !newIdItemDelete,
                },
            });
            setShowItemModal(false);
        }
    };

    let deleteModal;
    if (showItemModal) {
        deleteModal = (
            <EuiOverlayMask>
                <EuiConfirmModal
                    title={<p>Are you sure you want to delete this item?</p>}
                    onCancel={() => {
                        setShowItemModal(false);
                    }}
                    onConfirm={onConfirmItemDelete}
                    cancelButtonText="No, don't do it"
                    confirmButtonText='Yes, do it'
                    buttonColor='danger'
                    defaultFocusedButton='cancel'
                >
                    <p>You&rsquo;re about to delete this item</p>
                    <p>Are you sure you want to do this?</p>
                    {editItem?.is_mapped_item ? (
                        <p>(Note: Deleting this item will also remove this item linked in any other categories)</p>
                    ) : null}
                </EuiConfirmModal>
            </EuiOverlayMask>
        );
    }

    return (
        <>
            {deleteModal}
            {varaintModalState && (
                <VariantModal
                    isOutletMenu={isOutletMenu}
                    setVaraintModalState={setVaraintModalState}
                    editItem={editItem}
                />
            )}
            {varaintAddonLinkModalState && (
                <VariantAddonLinkModal
                    isOutletMenu={isOutletMenu}
                    control={control}
                    setVaraintAddonLinkModalState={setVaraintAddonLinkModalState}
                    editItem={editItem}
                />
            )}

            <MappedItemModal item={editItem} mapItemModal={mapItemModal} handleMapItemModal={handleMapItemModal} />
            <EuiFlexItem
                grow={false}
                style={{
                    backgroundColor: themeMode === 'light' ? '#f4f6f9' : 'black',
                    justifyContent: 'space-between',
                    padding: '8px',
                    alignItems: 'center',
                    alignContent: 'center',
                    flexDirection: 'row',
                    marginBottom: '5px',
                    height: '40px',
                }}
            >
                <EuiText>
                    <span>Item Details</span>
                </EuiText>
            </EuiFlexItem>
            {isDetailsLoading ? (
                <EuiLoadingContent lines={4} />
            ) : (
                <EuiFlexItem className='itemDetailStyle scrollbar-sm'>
                    {/* {editItem?.is_mapped_item ? (
                        <EuiFlexGroup
                            justifyContent='flexEnd'
                            gutterSize='s'
                            alignItems='center'
                            style={{ marginRight: '8px' }}
                        >
                            <EuiFlexItem grow={false}>
                                <EuiText size='s'>This Item is Mapped</EuiText>
                            </EuiFlexItem>
                            <EuiFlexItem grow={false} onClick={handleMapItemModal}>
                                <EuiLink>
                                    <EuiIcon type='link' />
                                </EuiLink>
                            </EuiFlexItem>
                        </EuiFlexGroup>
                    ) : null} */}
                    <EuiSpacer size='s' />
                    <FormProvider {...methods}>
                        <EuiForm component='form' onSubmit={handleSubmit(EditItem)}>
                            {!isEmpty(editItem) ? (
                                <EuiFlexGroup direction='column' style={{ paddingBottom: '6%' }}>
                                    <EuiFlexItem>
                                        <ItemBasicDetails variantConditionForPrice={variantConditionForPrice} />

                                        <EuiHorizontalRule />

                                        <ItemExpose />

                                        <EuiHorizontalRule />

                                        <ItemPricing
                                            setVaraintModalState={setVaraintModalState}
                                            priceRef={priceRef}
                                            comparePriceRef={comparePriceRef}
                                            packagingPriceRef={packagingPriceRef}
                                            variantConditionForPrice={variantConditionForPrice}
                                        />

                                        <EuiHorizontalRule />

                                        <ItemBadges />

                                        <EuiHorizontalRule />

                                        <ItemAddonGroups
                                            addonOptions={addonOptions}
                                            variantConditionForPrice={variantConditionForPrice}
                                            onChangeAddon={onChangeAddon}
                                            addonSelected={addonSelected}
                                            setVaraintAddonLinkModalState={setVaraintAddonLinkModalState}
                                            onDragEnd={onDragEnd}
                                            selectedItemEditAddOnList={selectedItemEditAddOnList}
                                            onAddonDelete={onAddonDelete}
                                        />

                                        <EuiHorizontalRule />

                                        <ItemImage />

                                        {!isOutletMenu ? (
                                            <>
                                                <EuiHorizontalRule />
                                                <ItemTimings stateUpdate={stateUpdate} />
                                            </>
                                        ) : null}
                                        <EuiHorizontalRule />

                                        <ItemUpSellingLinks />

                                        <EuiHorizontalRule />

                                        {!isEmpty(errorMessage) && showError && (
                                            <EuiFlexGroup
                                                direction='column'
                                                style={{ backgroundColor: '#f8e9e9', padding: '15px', margin: '10px' }}
                                            >
                                                <EuiFlexItem
                                                    style={{
                                                        flexDirection: 'row',
                                                        justifyContent: 'space-between',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <EuiTextColor color='danger' style={{ paddingBottom: '10px' }}>
                                                        <b>Please resolve below validations</b>
                                                    </EuiTextColor>
                                                    <EuiButtonIcon
                                                        color={'warning'}
                                                        onClick={() => setShowError(false)}
                                                        iconType='cross'
                                                        aria-label='cross'
                                                    />
                                                </EuiFlexItem>
                                                {displayErrorServer()}
                                            </EuiFlexGroup>
                                        )}

                                        {!selectedOrderingModes?.length ? (
                                            <EuiTextColor color='danger' style={{ paddingBottom: '10px' }}>
                                                <b>Please Select at least one ordering mode</b>
                                            </EuiTextColor>
                                        ) : null}

                                        {!isEmpty(errors) && showError && (
                                            <EuiFlexGroup
                                                direction='column'
                                                style={{ backgroundColor: '#f8e9e9', padding: '15px', margin: '10px' }}
                                            >
                                                <EuiFlexItem
                                                    style={{
                                                        flexDirection: 'row',
                                                        justifyContent: 'space-between',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <EuiTextColor color='danger' style={{ paddingBottom: '10px' }}>
                                                        <b>Please resolve below validations</b>
                                                    </EuiTextColor>
                                                    <EuiButtonIcon
                                                        color={'warning'}
                                                        onClick={() => setShowError(false)}
                                                        iconType='cross'
                                                        aria-label='cross'
                                                    />
                                                </EuiFlexItem>
                                                {displayError(errors)}
                                            </EuiFlexGroup>
                                        )}
                                        {!isOutletMenu ? (
                                            <>
                                                <EuiButton
                                                    onClick={() => {
                                                        mainSubmitClicked.current = true;
                                                        setShowError(true);
                                                        setErrorMessage('');
                                                    }}
                                                    type='submit'
                                                    fill
                                                    isLoading={buttonLoader || buttonDisable}
                                                    disabled={
                                                        !userHasPermission(permissions, '/menu', 'write') ||
                                                        buttonLoader ||
                                                        !selectedOrderingModes?.length
                                                    }
                                                >
                                                    save
                                                </EuiButton>
                                                <EuiSpacer size='s' />
                                                <EuiButton
                                                    color='danger'
                                                    onClick={() => setShowItemModal(true)}
                                                    type='submit'
                                                    disabled={
                                                        !userHasPermission(permissions, '/menu', 'write') ||
                                                        buttonLoader
                                                    }
                                                >
                                                    Delete
                                                </EuiButton>
                                            </>
                                        ) : null}
                                    </EuiFlexItem>
                                </EuiFlexGroup>
                            ) : (
                                <EuiCard title='No Record Found' />
                            )}
                        </EuiForm>
                    </FormProvider>
                </EuiFlexItem>
            )}
        </>
    );
};

export default React.memo(ItemDetailsEditor);

const options = [
    { value: 'non-vegetarian', text: 'Non Veg' },
    { value: 'vegetarian', text: 'Veg' },
    { value: 'egg', text: 'Eggs' },
];
