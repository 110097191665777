import { EuiSpacer } from '@elastic/eui';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import NumberField from '../../Form/NumberField';

const VariableDiscount = () => {
    const {
        control,
        formState: { errors },
    } = useFormContext();

    return (
        <>
            <NumberField
                errors={errors}
                label='Threshold Amount'
                control={control}
                name='threshold_amount'
                placeholder='Enter Threshold Amount'
                rules={{
                    required: 'Please Enter Threshold Amount',
                }}
            />
            <NumberField
                errors={errors}
                label='Above Threshold'
                control={control}
                name='discount_above_threshold'
                // name='above_threshold_discount'
                placeholder='Enter Above Threshold'
                rules={{
                    required: 'Please Enter Above Threshold',
                }}
            />
            <NumberField
                errors={errors}
                label='Below Threshold'
                control={control}
                // name='below_threshold_discount'
                name='discount_below_threshold'
                placeholder='Enter Below Threshold'
                rules={{
                    required: 'Please Enter Below Threshold',
                }}
            />
            <EuiSpacer />
        </>
    );
};

export default React.memo(VariableDiscount);
