import React, { useState, useEffect, useCallback } from 'react';
import MenuEditorTab from '../../../components/menuComponentsRefactorCopy/menuEditorTab/MenuEditorTab';
import { useSelector, useDispatch, batch } from 'react-redux';
import {
    SET_ADDONS,
    SET_RESTAURANT_BADGES,
    SET_RESTAURANT_CATALOGUE,
    SET_ORDERING_MODES,
    SET_ACTIVE_ITEM_DETAILS,
    SET_QUANTITY_UNITS,
} from '../../../components/menuComponentsRefactorCopy/Store/MenuTypes';
import {
    fetchAddons,
    fetchOutletCatalogue,
    fetchRestaurantCatalogue,
    fetchOrderingModes,
    fetchRestaurantBadges,
    fetchQuantityUnits,
} from '../../../components/menuComponentsRefactorCopy/catalogue/Api/FetchCatalogueInfo';
import { EuiLoadingContent, EuiPanel } from '@elastic/eui';

const Catalogue = () => {
    const selectedOutletId = useSelector((state) => state.outlet.selectedOutletId);
    const isOutletMenu = useSelector((state) => state.menuReducer.isOutletMenu);
    const languageId = useSelector((state) => state.language.languageId);
    const dispatch = useDispatch();
    const [uiState, setUiState] = useState({
        isLoading: true,
        error: null,
    });

    const getCatalogueInfo = useCallback(async () => {
        try {
            const responses = await Promise.all([
                isOutletMenu
                    ? fetchOutletCatalogue(selectedOutletId, languageId)
                    : fetchRestaurantCatalogue(languageId),
                fetchAddons(),
                fetchOrderingModes(),
                fetchRestaurantBadges(),
                fetchQuantityUnits(),
            ]);
            batch(() => {
                dispatch({
                    type: SET_RESTAURANT_CATALOGUE,
                    payload: responses[0].categories,
                });
                dispatch({
                    type: SET_ADDONS,
                    payload: responses[1].restaurant_addon_groups,
                });
                dispatch({
                    type: SET_ORDERING_MODES,
                    payload: responses[2].ordering_modes,
                });

                dispatch({
                    type: SET_RESTAURANT_BADGES,
                    payload: responses[3].badges,
                });
                dispatch({
                    type: SET_QUANTITY_UNITS,
                    payload: responses[4].quantity_units,
                });
            });

            setUiState((prevState) => ({
                ...prevState,
                isLoading: false,
                error: null,
            }));

            return responses;
        } catch (error) {
            setUiState((prevState) => ({
                ...prevState,
                isLoading: false,
                error: error,
            }));
        }
    }, [dispatch, isOutletMenu, languageId, selectedOutletId]);

    useEffect(() => {
        if (isOutletMenu) {
            setUiState({
                isLoading: true,
                error: null,
            });

            dispatch({
                type: 'set-active-item-id',
                payload: {
                    activeItemId: false,
                },
            });
            dispatch({
                type: 'edit-item',
                payload: {
                    editIem: {},
                },
            });
        }

        return function cleanup() {
            dispatch({
                type: SET_ACTIVE_ITEM_DETAILS,
                payload: {},
            });
        };
    }, [selectedOutletId, isOutletMenu, dispatch]);

    useEffect(() => {
        getCatalogueInfo();
    }, [isOutletMenu, selectedOutletId, getCatalogueInfo]);

    if (uiState.isLoading) {
        return <EuiLoadingContent lines={5} />;
    }

    return <MenuEditorTab />;
};

export default React.memo(Catalogue);
