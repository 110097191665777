import { getTimeAgo } from './getPrettyTime';

export const nextBooking = (timeSlots) => {
    let msg = '';
    let selectedTime = '';

    if (timeSlots.length) {
        timeSlots.map((time) => {
            if (selectedTime && selectedTime > `${time.date} ${time.time}`) {
                selectedTime = selectedTime = `${time.date} ${time.time}`;
            } else {
                selectedTime = `${time.date} ${time.time}`;
            }
        });
    }

    if (selectedTime) {
        msg = getTimeAgo(selectedTime);
    }
    return msg;
};
