import React, { useEffect, useState } from 'react';
import RiderInformation from './riderInformation';
import RiderOrderInformation from './riderOrderInformation';
import { EuiSpacer, EuiButton, EuiFlexGroup, EuiFlexItem, EuiIcon, EuiLoadingContent } from '@elastic/eui';
import API from '../../api/axios/API';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { OrderFlyout } from '../orderRating/orderFlyout';
import Pagination from '../Form/Pagination';
import RiderInfoCsv from './riderInfoCsv';
import { useHistory } from 'react-router-dom';
import CustomDatePicker from '../Credits/CustomDatePicker';

const RiderDetails = () => {
    const [isFlyoutVisible, setIsFlyoutVisible] = useState(false);
    const riderId = useSelector((state) => state.rider.specific_Rider_Data.rider_id);
    const [startDateTime, setStartDateTime] = useState(moment());
    const [endDateTime, setEndDateTime] = useState(moment());
    const [csv, setCSV] = useState(false);

    const history = useHistory();

    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 10,
    });

    const [riderDetails, setRiderDetails] = useState({
        isLoading: true,
        error: false,
        data: undefined,
    });

    const fetchRiderDetails = async () => {
        let response;

        try {
            response = await API.get(
                `restaurants/:restaurantId/riders/${riderId}/orders?start_date=${moment(startDateTime).format(
                    'YYYY-MM-DD'
                )}&end_date=${moment(endDateTime).format('YYYY-MM-DD')}&page=${pagination.pageIndex + 1}&par_page=${
                    pagination.pageSize
                }`
            );
        } catch (error) {
            response = error;
        }

        if (response.success) {
            setRiderDetails({
                isLoading: false,
                error: false,
                data: response,
            });
        } else {
            setRiderDetails({
                isLoading: false,
                error: true,
                data: undefined,
            });
            alert('Error in fetching rider details');
        }
    };

    useEffect(() => {
        endDateTime && fetchRiderDetails();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [endDateTime, startDateTime]);

    const [selectedOrder, setSelectedOrder] = useState({});
    let flyout;
    if (isFlyoutVisible) {
        flyout = (
            <OrderFlyout setFlyoutOpen={setIsFlyoutVisible} order={selectedOrder} setSelectedOrder={setSelectedOrder} />
        );
    }
    const handlePageChange = (page) => {
        setPagination({
            ...pagination,
            pageIndex: page,
        });
    };

    if (riderDetails.isLoading) return <EuiLoadingContent lines={3} />;
    if (riderDetails.error) return 'error..';

    return (
        <>
            <EuiIcon
                style={{
                    padding: 5,
                    border: '1px solid black',
                    borderRadius: '50%',
                    marginBottom: 10,
                    background: 'white',
                }}
                onClick={() => history.goBack()}
                type='arrowLeft'
                size='xl'
            />
            <EuiFlexGroup alignItems='center'>
                <EuiFlexItem>
                    <CustomDatePicker
                        startDate={startDateTime}
                        setStartDate={setStartDateTime}
                        endDate={endDateTime}
                        setEndDate={setEndDateTime}
                        displayFormat='DD-MM-YYYY'
                    />
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                    {csv && (
                        <RiderInfoCsv
                            setCSV={setCSV}
                            startDateTime={startDateTime}
                            riderId={riderId}
                            endDateTime={endDateTime}
                            totalRecord={riderDetails.data.rider_order_details.rider_orders.total}
                        />
                    )}
                    <EuiButton onClick={() => setCSV(true)}>Download CSV</EuiButton>
                </EuiFlexItem>
            </EuiFlexGroup>

            <EuiSpacer />
            <RiderInformation riderDetails={riderDetails.data} />
            <EuiSpacer />
            <RiderOrderInformation
                allOrder={riderDetails.data}
                setIsFlyoutVisible={setIsFlyoutVisible}
                setSelectedOrder={setSelectedOrder}
            />
            {riderDetails &&
                riderDetails.data.rider_order_details.rider_orders.total >
                    riderDetails.data.rider_order_details.rider_orders.per_page && (
                    <Pagination
                        totalRecord={riderDetails.data.rider_order_details.rider_orders.total}
                        pageSize={riderDetails.data.rider_order_details.rider_orders.per_page}
                        activePage={riderDetails.data.rider_order_details.rider_orders.current_page - 1}
                        handlePageChange={handlePageChange}
                    />
                )}
            {flyout}
        </>
    );
};

export default RiderDetails;
