import React, { useCallback, useEffect, useState } from 'react';
import { EuiCollapsibleNavGroup, EuiText, EuiFlexItem, EuiLoadingSpinner } from '@elastic/eui';
import { getOnlyItems as fetchSelectedCategoryItems } from '../../api/menu/getOnlyItems';
import { useDispatch } from 'react-redux';
import CategoryItems from './CategoryItems';
import { useFieldArray, useFormContext } from 'react-hook-form';

const Category = (props) => {
    const { category, setTotalCategoryItems } = props;
    const [itemList, setItemList] = useState([]);
    const [loader, setLoader] = useState({ loader: false, error: false });
    const dispatch = useDispatch();

    const { control } = useFormContext({
        mode: 'all',
    });

    const { fields, append } = useFieldArray({
        control,
        name: `item_${category.category_id}`,
    });

    const onToggle = useCallback(() => {
        if (!(!itemList.length && !category?.sub_categories?.length && !fields?.length)) {
            return false;
        }

        setLoader({ ...loader, loader: true });
        dispatch(fetchSelectedCategoryItems(category.category_id)).then((response) => {
            if (!response.success) {
                setLoader({ loader: false, error: true });
                return false;
            }

            setItemList(response.items);
            setTotalCategoryItems((prevItems) => [...prevItems, { [category.category_id]: response.items }]);

            setLoader({ ...loader, loader: false });
        });
    }, [
        category.category_id,
        category?.sub_categories?.length,
        dispatch,
        itemList.length,
        loader,
        setTotalCategoryItems,
        fields,
    ]);

    useEffect(() => {
        if (itemList?.length) {
            for (const item of itemList) {
                append({ ...item });
            }
        }
    }, [append, itemList]);

    return (
        <EuiCollapsibleNavGroup
            key={category.category_id.toString()}
            background='light'
            title={
                <EuiFlexItem style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <EuiText>
                        <h3>{category.internal_name}</h3>
                    </EuiText>
                    {loader.loader ? (
                        <EuiFlexItem style={{ paddingLeft: '20px' }}>
                            <EuiLoadingSpinner size='m' />
                        </EuiFlexItem>
                    ) : null}
                </EuiFlexItem>
            }
            isCollapsible={true}
            initialIsOpen={false}
            onToggle={onToggle}
            arrowDisplay={'left'}
        >
            {category?.sub_categories?.length ? (
                <EuiFlexItem style={{ marginLeft: '20px' }}>
                    {category.sub_categories.map((subCategory) => {
                        return (
                            <Category
                                category={subCategory}
                                key={subCategory.category_id.toString()}
                                setTotalCategoryItems={setTotalCategoryItems}
                            />
                        );
                    })}
                </EuiFlexItem>
            ) : null}

            {fields.map((item, index) => {
                return (
                    <CategoryItems
                        category_id={category.category_id}
                        item={item}
                        fieldIndex={index}
                        key={item.item_id.toString() + index.toString()}
                    />
                );
            })}
        </EuiCollapsibleNavGroup>
    );
};

export default React.memo(Category);
