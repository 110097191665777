export const getSectionTableStructure = (tablesList) => {
    let innerObj = {};

    if (tablesList.length) {
        tablesList.map((table) => {
            if (innerObj[table.section.name]) {
                let innerArray = [...innerObj[table.section.name].tables];
                innerArray.push(table);
                innerObj[table.section.name].tables = innerArray;
            } else {
                innerObj[table.section.name] = {
                    id: table.section.id,
                    name: table.section.name,
                    tables: [table],
                };
            }
        });
    }

    return innerObj;
};
