import {
    EuiFlexGroup,
    EuiFlexItem,
    EuiPanel,
    EuiHealth,
    EuiLoadingContent,
    EuiBasicTable,
    EuiComboBox,
    EuiBeacon,
    EuiFieldSearch,
} from '@elastic/eui';
import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import API from '../../api/axios/API';
import FormStepsFooter from './formStepsFooter';
import Orders from './orders';
import { capitalize } from '../../helper/capitalize';
import { getTimeAgo } from '../../helper/getPrettyTime';
import { isEmpty } from 'lodash';
import * as types from '../../reduxStore/types/billing';
import _ from 'lodash';
import { ReactComponent as Clock } from '../../assets/img/clock.svg';

const OrderList = (props) => {
    const { errors, control, watch, reset, setValue, setButtonClicked, outletId, ordersType } = props;
    const languageCode = useSelector((state) => state.language.language);
    const [items, setItems] = useState([]);
    const [orderSearchState, setOrderSearchState] = useState([]);

    const [takeawayList, setTakeawayList] = useState({
        loading: false,
        error: false,
        data: undefined,
    });
    const dispatch = useDispatch();

    const getOrdersTableList = (orders) => {
        let innerArray = [];
        innerArray.push({ id: '00', customer: '+  Add New' });

        if (orders.length) {
            orders.map((order) => {
                innerArray.push({
                    customer: capitalize(order.customer.name),
                    number: `${order.customer.mobile_number}`,
                    net_total: order.net_total,
                    count: order.order_count,
                    items_count: order.cart.cart_items.length,
                    time: !isEmpty(order.status) ? getTimeAgo(order.status.placed_at) : '',
                    order: order,
                });
            });
        }

        setItems(innerArray);
        setOrderSearchState(innerArray);
    };

    const fetchOutletList = async () => {
        let response;
        setTakeawayList({ ...takeawayList, loading: true });
        try {
            response = await API.get(
                `${languageCode}/restaurants/:restaurantId/outlets/${outletId}/orders?ordering_mode=${ordersType}`
            );
        } catch (error) {
            response = error;
        }

        if (response.success) {
            setTakeawayList({ loading: false, error: false, data: response.orders });
            getOrdersTableList(response.orders);
        } else {
            setTakeawayList({ loading: false, error: true, data: undefined });
            alert('Error in fetching Takeaway orders');
        }
    };

    useEffect(() => {
        fetchOutletList();
    }, [outletId, ordersType]);

    const orderList = () => {
        let orderList = [];
        if (takeawayList.loading) {
            return (
                <EuiFlexItem style={{ width: '100%' }}>
                    <EuiLoadingContent lines={5} />
                </EuiFlexItem>
            );
        }

        if (takeawayList.data && takeawayList.data.length > 0) {
            takeawayList.data.map((order) => {
                orderList.push(<Orders order={order} />);
            });
        } else {
            return (
                <EuiFlexItem style={{ alignItems: 'center', justifyContent: 'center', height: '90%' }}>
                    No Orders Found
                </EuiFlexItem>
            );
        }

        return orderList;
    };

    const onOrderSelect = (order) => {
        dispatch({
            type: types.SET_PREVIOUS_ORDERS,
            payload: {
                previousOrders: [order],
            },
        });

        dispatch({
            type: types.SET_ORDER_ID,
            payload: {
                orderId: order.order_id,
            },
        });

        dispatch({
            type: types.SET_CUSTOMER_INFORMATION_FINISH,
            payload: {
                finishCustomerInfo: true,
            },
        });

        dispatch({
            type: types.SET_CUSTOMER_INFORMATION,
            payload: {
                customerInformation: order.customer,
            },
        });
    };

    const columns = [
        {
            field: 'customer',
            name: <b>Customer</b>,
            sortable: true,
            render: (customer, items) => {
                return <span>{customer}</span>;
            },
        },
        {
            field: 'number',
            name: <b>Number</b>,
            sortable: true,
            render: (number, items) => {
                return <span>{number}</span>;
            },
        },
        {
            field: 'net_total',
            name: <b>Net total</b>,
            sortable: true,
            render: (net_total, items) => {
                return <span>{net_total}</span>;
            },
        },
        {
            field: 'count',
            name: <b>Customer' order count</b>,
            sortable: true,
            render: (count, items) => {
                return <span>{count}</span>;
            },
        },
        {
            field: 'items_count',
            name: <b>Items count</b>,
            sortable: true,
            render: (items_count, items) => {
                return <span>{items_count}</span>;
            },
        },
        {
            field: 'time',
            name: <b>Time</b>,
            sortable: true,
            render: (time, items) => {
                if (time) {
                    return (
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end' }}>
                            <div style={{ paddingBottom: '1px' }}>
                                <Clock />
                            </div>
                            <div style={{ padding: '0px 0px 2px 5px' }}>
                                <span style={{ fontSize: '14px' }}>{time}</span>
                            </div>
                        </div>
                    );
                }
            },
        },
    ];

    const getRowProps = (item) => {
        const { id, order } = item;
        return {
            'data-test-subj': `row-${id}`,
            className: item.customer === '+  Add New' ? 'customRowClassForSection' : 'customRowClass',
            onClick: () => {
                if (item.customer !== '+  Add New') {
                    onOrderSelect(order);
                } else {
                    dispatch({
                        type: types.SET_ADD_NEW,
                        payload: {
                            addNew: true,
                        },
                    });
                }
                item.customer !== '+  Add New' && onOrderSelect(order);
            },
        };
    };

    const getCellProps = (item, column) => {
        const { id } = item;
        const { field } = column;
        console.log('items cell', item);
        return {
            className: item.customer === '+  Add New' ? 'customerClassForRowHeight' : 'customCellClass',
            'data-test-subj': `cell-${id}-${field}`,
            textOnly: true,
        };
    };

    const fetchmatchingOrders = (value, orderSearchState) => {
        let innerArray = [{ id: '00', customer: '+  Add New' }];

        orderSearchState.map((table) => {
            if (table.customer.toLowerCase().includes(value.toLowerCase()) && table.id !== '00') {
                innerArray.push(table);
            }
        });

        setItems(innerArray);
    };

    const delayedQuery = useCallback(
        _.debounce((q, orderSearchState) => fetchmatchingOrders(q, orderSearchState), 500),
        []
    );

    const searchOrders = (e) => {
        if (e.target.value) {
            delayedQuery(e.target.value, orderSearchState);
        } else {
            setItems([...orderSearchState]);
        }
    };

    return (
        <EuiFlexGroup direction='column'>
            {/* <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', marginTop: '10px' }}>
                <div style={{ width: '22%' }}>
                    <b style={{fontSize: '34px', fontFamily: 'Metropolis-Bold'}}>{capitalize(ordersType)}</b>
                </div>
                <div
                    style={{ display: 'flex', flexDirection: 'row', width: '76%', fontSize: '14px', paddingRight: '10px',justifyContent: 'flex-end' }}
                >

                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                        <div className="tableStatusRectangle" style={{backgroundColor: '#eeedeb'}}/>
                        <span>
                            LINED UP
                        </span>
                    </div>

                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: '10px'}}>
                        <div className="tableStatusRectangle" style={{backgroundColor: '#cdffd1'}}/>
                    
                        <span>
                            PREPARING
                        </span>
                    </div>

                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: '10px'}}>
                        <div className="tableStatusRectangle" style={{backgroundColor: '#ffcdcd'}}/>
                    
                        <span>
                            READY
                        </span>
                    </div>

                    {
                        ordersType === "delivery" ? (
                            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: '10px'}}>
                                <div className="tableStatusRectangle" style={{backgroundColor: '#285af6'}}/>
                            
                                <span>
                                    Dispatch
                                </span>
                            </div>
                        ): ''
                    }
                    
                </div>
            </div> */}

            {/* <div className="takeawayList" style={{paddingLeft: '20px', paddingTop: '15px'}}>
                {orderList()}
            </div> */}

            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                {/* <EuiComboBox
                    options={[]}
                    singleSelection={{ asPlainText: true }}
                    selectedOptions={[]}
                    onChange={() => {}}
                    placeholder='Search order'
                    fullWidth
                /> */}
                <EuiFieldSearch
                    fullWidth
                    placeholder='Search table'
                    onChange={searchOrders}
                    isClearable={true}
                    aria-label='search of table'
                    defaultChecked=''
                />
            </div>
            <div className='tableList' style={{ padding: '15px 0' }}>
                <EuiBasicTable
                    items={items}
                    rowHeader='customer'
                    columns={columns}
                    rowProps={getRowProps}
                    cellProps={getCellProps}
                />
            </div>

            {/* <EuiFlexItem>
                <FormStepsFooter reset={reset} errors={errors} watch={watch} step={1} {...props} setButtonClicked={setButtonClicked}/>
            </EuiFlexItem> */}
        </EuiFlexGroup>
    );
};

export default OrderList;
