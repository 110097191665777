import React from 'react';
import Map from './map';

const MapComponent = (props) => {
    const { errors, control, watch, reset, setValue, Controller, defaultLatLong } = props;
    return (
        <>
            <div style={{ padding: '10px' }}>
                <Map
                    // google={this.props.google}
                    watch={watch}
                    setValue={setValue}
                    errors={errors}
                    control={control}
                    reset={reset}
                    Controller={Controller}
                    center={defaultLatLong}
                    height='100px'
                    zoom={15}
                />
            </div>
        </>
    );
};

export default MapComponent;
