import React from 'react';
import { useSelector } from 'react-redux';
import { MainLayout } from '../../components/layout/mainLayout/mainLayout';
import TableManagementScreen from '../../components/TableManagement/TableManagementScreen';
import { NoOutletMessage } from '../../utils/noOutletMessage';

const TableManagementPage = (props) => {
    const outletId = useSelector((state) => state.outlet.selectedOutletId);
    return (
        <MainLayout title='Table Management' outlet backgroundColorChange={true}>
            {outletId ? <TableManagementScreen /> : <NoOutletMessage />}
        </MainLayout>
    );
};

export default TableManagementPage;
