import React from 'react';
import { EuiFlexGroup, EuiFlexItem, EuiPanel, EuiSpacer, htmlIdGenerator } from '@elastic/eui';
import LiveOrderCustomerInformation from '../../components/liveOrders/liveOrderCustomerInformation';
import LiveOrderDetailsTable from '../liveOrders/liveOrderDetailsTable';
import OrderTotalBill from '../liveOrders/orderTotalBill';
import LiveOrderDetailsAction from '../liveOrders/liveOrderDetailsActions';

const TableOrders = ({ order, selectedTable }) => {
    return (
        <>
            <React.Fragment key={htmlIdGenerator()()}>
                <EuiPanel>
                    <LiveOrderCustomerInformation isFromTableManagement={true} selectedOrder={order} />
                    <EuiSpacer />

                    <LiveOrderDetailsTable selectedOrder={order} />
                    <EuiSpacer />
                    <OrderTotalBill selectedOrder={order} isFromTableManagement={true} />
                    <EuiSpacer />
                    <EuiFlexGroup>
                        <EuiFlexItem>
                            <LiveOrderDetailsAction
                                isFromTableManagement={true}
                                selectedOrder={order}
                                tableId={selectedTable.id}
                            />
                        </EuiFlexItem>
                    </EuiFlexGroup>
                </EuiPanel>
            </React.Fragment>
        </>
    );
};

export default React.memo(TableOrders);
