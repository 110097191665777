import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchOutletOrderingModes } from '../../../../api/outlet/fetchOutletOrderingModes';
import { updateOutletOrderingModes } from '../../../../components/settings/outlet-form/utils';
import * as types from '../../../../reduxStore/types/outlet';
import {
    EuiCard,
    EuiDragDropContext,
    euiDragDropReorder,
    EuiDraggable,
    EuiDroppable,
    EuiFlexGroup,
    EuiFlexItem,
    EuiIcon,
    EuiPanel,
    EuiSpacer,
    EuiText,
} from '@elastic/eui';
import FormGroupDescription from '../../../../components/formGroupDescription';
import { useForm } from 'react-hook-form';
import { userHasPermission } from '../../../../helper/auth';

function findUnique(arr, predicate) {
    let found = {};
    arr.forEach((d) => {
        if (d.is_enabled) {
            found[predicate(d)] = d;
        }
    });
    return Object.keys(found).map((key) => found[key]);
}

const DisplayRankModes = () => {
    const dispatch = useDispatch();
    const outletId = useSelector((state) => state.outlet.selectedOutletId);
    const outletOrderingModes = useSelector((state) => state?.outlet?.outletOrderingModes?.ordering_modes);
    const restaurantDescriptions = useSelector((state) => state.auth.restaurantDescriptions);
    const permissions = useSelector((state) => state.permission.permission);
    const [list, setList] = useState([]);
    const {
        setValue,
        handleSubmit,
        reset,
        formState: { isDirty },
    } = useForm();

    const resetChanges = useCallback(() => {
        if (outletOrderingModes?.length) {
            const uniqueOrderingModes = findUnique(outletOrderingModes, (d) => d.tab);

            setList(uniqueOrderingModes);

            reset({
                ordering_modes: uniqueOrderingModes,
            });
        }
    }, [outletOrderingModes, reset]);

    useEffect(() => {
        resetChanges();
    }, [outletOrderingModes, resetChanges]);

    const getOrderingModes = useCallback(async () => {
        dispatch(fetchOutletOrderingModes(outletId));
    }, [dispatch, outletId]);

    useEffect(() => {
        getOrderingModes();
    }, [getOrderingModes]);

    useEffect(() => {
        if (!userHasPermission(permissions, '/outlet-settings', 'write')) return;

        if (isDirty) {
            dispatch({
                type: 'set-portal-active',
                payload: {
                    portalActive: {
                        resetChanges: resetChanges,
                        submit: handleSubmit(onSubmit),
                    },
                },
            });
        } else {
            dispatch({
                type: 'set-portal-active',
                payload: {
                    portalActive: {},
                },
            });
            dispatch({
                type: 'set-portal-active-tab-switched',
                payload: {
                    tabSwitched: false,
                },
            });
        }
    }, [isDirty]);

    const onSubmit = useCallback(
        (data) => {
            let structuredModes = [];

            data.ordering_modes.forEach((orderingMode, index) => {
                let obj = {
                    restaurant_ordering_mode_id: orderingMode.restaurant_ordering_mode_id,
                    display_rank: index + 1,
                    is_enabled: orderingMode.is_enabled,
                    translations: orderingMode.translations,
                    show_on_home_screen: true,
                };

                structuredModes.push(obj);
            });
            updateOutletOrderingModes(outletId, { ordering_modes: structuredModes }, 'ordering_mode').then(
                (response) => {
                    if (response.success) {
                        dispatch({
                            type: types.SET_PORTAL_ACTIVE,
                            payload: {
                                portalActive: {},
                            },
                        });
                        dispatch({
                            type: types.SET_PORTAL_ACTIVE_TAB_SWITCHED,
                            payload: {
                                tabSwitched: false,
                            },
                        });
                        getOrderingModes();
                    }
                }
            );
        },
        [dispatch, getOrderingModes, outletId]
    );

    const onDragEnd = useCallback(
        ({ source, destination }) => {
            if (source && destination) {
                const items = euiDragDropReorder(list, source.index, destination.index);

                setValue('ordering_modes', items, { shouldDirty: true });

                setList(items);
            }
        },
        [list, setValue]
    );

    return (
        <FormGroupDescription
            title={restaurantDescriptions?.ordering_mode?.display_name}
            description={restaurantDescriptions?.ordering_mode?.description}
        >
            <EuiDragDropContext onDragEnd={onDragEnd}>
                <EuiDroppable droppableId='CUSTOM_HANDLE_DROPPABLE_AREA' spacing='m' withPanel>
                    {list?.length ? (
                        list.map((mode, idx) => {
                            return (
                                <EuiDraggable
                                    spacing='m'
                                    key={mode.id.toString()}
                                    index={idx}
                                    draggableId={mode.id.toString()}
                                    customDragHandle={true}
                                    hasInteractiveChildren={true}
                                >
                                    {(provided) => (
                                        <>
                                            <EuiSpacer size={`${idx !== 0 ? 's' : null}`} />
                                            <EuiPanel paddingSize='s'>
                                                <EuiFlexGroup
                                                    justifyContent='spaceBetween'
                                                    alignItems='center'
                                                    gutterSize='s'
                                                >
                                                    <EuiFlexItem>
                                                        <EuiFlexGroup
                                                            justifyContent='spaceBetween'
                                                            alignItems='center'
                                                            gutterSize='s'
                                                        >
                                                            {' '}
                                                            <EuiFlexItem grow={false}>
                                                                <EuiFlexItem
                                                                    color='transparent'
                                                                    paddingSize='s'
                                                                    {...provided.dragHandleProps}
                                                                    aria-label='Drag Handle'
                                                                >
                                                                    <EuiIcon type='grab' />
                                                                </EuiFlexItem>
                                                            </EuiFlexItem>
                                                            <EuiFlexItem>
                                                                <EuiText style={{ textTransform: 'capitalize' }}>
                                                                    {mode.tab?.includes('_')
                                                                        ? mode.tab.replace('_', ' ')
                                                                        : mode.tab}
                                                                </EuiText>
                                                            </EuiFlexItem>
                                                        </EuiFlexGroup>
                                                    </EuiFlexItem>
                                                </EuiFlexGroup>
                                            </EuiPanel>
                                        </>
                                    )}
                                </EuiDraggable>
                            );
                        })
                    ) : (
                        <EuiCard title='Ordering modes not found for this outlet' />
                    )}
                </EuiDroppable>
            </EuiDragDropContext>
        </FormGroupDescription>
    );
};

export default React.memo(DisplayRankModes);
