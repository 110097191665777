import {
    EuiFlexGroup,
    EuiFormRow,
    EuiFlexItem,
    EuiFieldText,
    EuiButton,
    EuiHorizontalRule,
    EuiForm,
    EuiLoadingContent,
} from '@elastic/eui';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import API from '../../api/axios/API';
import CouponOffer from './couponOffer';
import * as types from '../../reduxStore/types/billing';

const CouponCodeTab = (props) => {
    const { outletId, setCouponCodeData } = props;

    const {
        register,
        handleSubmit,
        control,
        watch,
        reset,
        setValue,

        formState: { errors },
    } = useForm({
        defaultValues: {},
    });

    const dispatch = useDispatch();
    const mode = useSelector((state) => state.billing.mode);

    const [offerList, setOfferList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const cartId = useSelector((state) => state.billing.cartId);

    const fetchCouponCodeList = async () => {
        let response;

        console.log('outlet id ', outletId);
        setIsLoading(true);
        try {
            response = await API.get(
                `/en/pos/restaurants/:restaurantId/outlets/${outletId}/offers?ordering_mode=${mode}&display_settings=display_in_offers_tab`
            );
        } catch (error) {
            response = error;
        }

        console.log('response offer ', response);

        if (response.success) {
            setOfferList(response.offers);
        }

        setIsLoading(false);
    };

    useEffect(() => {
        outletId && fetchCouponCodeList();
    }, []);

    const applyOffer = async (code) => {
        let response;

        try {
            response = await API.put(
                `en/pos/restaurants/:restaurantId/outlets/${outletId}/carts/${cartId}/apply-offer`,
                {
                    coupon_code: code,
                }
            );
        } catch (error) {
            response = error;
        }

        if (response.success) {
            setCouponCodeData(response);
            dispatch({
                type: types.SET_BILLING_ITEM_CART_STRUCTURE,
                payload: {
                    billingItemCartStructure: response,
                },
            });
            alert('Coupon applied successfully!!');
        } else {
            if (response.message) {
                alert(response.message);
            } else {
                alert('Error in coupon apply');
            }
        }
    };

    const onSubmit = (data) => {
        applyOffer(data.coupon_code);
    };

    return (
        <EuiFlexGroup direction='column'>
            <EuiForm component='form' onSubmit={handleSubmit(onSubmit)}>
                <EuiFlexItem style={{ marginTop: '20px' }}>
                    <EuiFormRow
                        label={'Coupopn code'}
                        fullWidth={true}
                        error={errors['coupon_code'] ? errors['coupon_code'].message : ''}
                        isInvalid={errors['coupon_code']}
                    >
                        <Controller
                            render={({ field }) => <EuiFieldText value={field.value} onChange={field.onChange} />}
                            name={'coupon_code'}
                            placeholder='Coupon Code '
                            control={control}
                            fullWidth={true}
                            rules={{
                                required: 'Please enter Coupon code',
                            }}
                            isInvalid={errors['coupon_code']}
                        />
                    </EuiFormRow>
                    <EuiButton fill type='submit'>
                        APPLY
                    </EuiButton>
                </EuiFlexItem>
            </EuiForm>
            <EuiHorizontalRule />

            <EuiFlexGroup direction='column'>
                {!isLoading ? (
                    offerList.map((offer) => {
                        return <CouponOffer offer={offer} outletId={outletId} setCouponCodeData={setCouponCodeData} />;
                    })
                ) : (
                    <EuiFlexItem>
                        <EuiLoadingContent lines={5} />
                    </EuiFlexItem>
                )}
            </EuiFlexGroup>
        </EuiFlexGroup>
    );
};

export default CouponCodeTab;
