import React, { useEffect, useState } from 'react';
import {
    EuiButton,
    EuiListGroup,
    EuiListGroupItem,
    EuiPopover,
    EuiIcon,
    EuiSelect,
    EuiFlexItem,
    EuiComboBox,
} from '@elastic/eui';
import { useDispatch, useSelector } from 'react-redux';
import { SET_LANGUAGE, SET_LANGUAGE_ID } from '../../reduxStore/types/language';
import languageIcon from '../../assets/img/languageIcon.svg';

export const LanguageDropDown = () => {
    const [isPopoverOpen, setPopover] = useState(false);
    const closePopover = () => {
        setPopover(false);
    };

    const availableLanguages = useSelector((state) => state.language.availableLanguages);
    const currentLanguage = useSelector((state) => state.language.language);
    const languageId = useSelector((state) => state.language.languageId);
    const dispatch = useDispatch();
    const [languageOptions, setLanguageOptions] = useState([]);

    useEffect(() => {
        let innerArray = [];
        if (availableLanguages && availableLanguages.length) {
            availableLanguages.map((language) => {
                innerArray.push({
                    value: language.language_id,
                    text: language.language_label,
                    language_data: language,
                });
            });
        }
        if (innerArray.length) {
            setLanguageOptions(innerArray);
        }
    }, []);

    const onButtonClick = () => {
        setPopover(!isPopoverOpen);
    };

    const onLanguageSelect = (e) => {
        let language = availableLanguages.filter((language) => language.language_id == e.target.value);
        if (language.length) {
            language = language[0];
        }
        dispatch({ type: SET_LANGUAGE, payload: language.type });
        dispatch({
            type: SET_LANGUAGE_ID,
            payload: { languageId: language.language_id },
        });
        dispatch({
            type: SET_LANGUAGE,
            payload: language.language,
        });
        closePopover();
    };

    return (
        <EuiFlexItem style={{ padding: '3px' }}>
            <EuiSelect
                options={languageOptions}
                value={languageId}
                onChange={(e) => onLanguageSelect(e)}
                aria-label='language list'
            />
        </EuiFlexItem>
    );
};
