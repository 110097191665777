import React, { useState, useRef, useCallback, useEffect } from 'react';
import {
    EuiFlexGroup,
    EuiFlexItem,
    EuiText,
    EuiFormRow,
    EuiFieldNumber,
    EuiComboBox,
    EuiSelect,
    EuiStat,
    EuiPanel,
    EuiIcon,
    EuiButton,
} from '@elastic/eui';
import { Controller } from 'react-hook-form';
import _, { isEmpty } from 'lodash';
import API from '../../api/axios/API';
import { useDispatch, useSelector } from 'react-redux';
import * as types from '../../reduxStore/types/billing';
import NumberField from '../Form/NumberField';
import KeyboardComponent from './keyboardComponent';
import { ReactComponent as BackArrow } from '../../assets/img/backArrow.svg';

const PaymentSection = (props) => {
    const { control, errors, watch, setValue, outletId, status, setStatus, placeOrderCall } = props;

    const [selectedPaymentOptions, setSelectedPaymentOptions] = useState([]);
    const [selectedWallet, setSelectedWallet] = useState('');
    const [walletOptions, setWalletOptions] = useState([]);
    const [paymentOptions, setPaymentOptions] = useState({});

    const [fieldFocus, setFieldFocus] = useState('');

    const tableData = useSelector((state) => state.billing.tableData);
    const mode = useSelector((state) => state.billing.mode);
    const customerInformation = useSelector((state) => state.billing.customerInformation);
    const languageCode = useSelector((state) => state.language.language);

    const dispatch = useDispatch();

    const cashRef = useRef(null);
    const cardRef = useRef(null);
    const netBankingRef = useRef(null);
    const walletRef = useRef(null);
    const upiRef = useRef(null);
    const discountValueRef = useRef(null);
    const billToCompanyRef = useRef(null);
    const noChargeBillRef = useRef(null);

    const onWheel = () => {
        cashRef && cashRef.current && cashRef.current.blur();
        cardRef && cardRef.current && cardRef.current.blur();
        netBankingRef && netBankingRef.current && netBankingRef.current.blur();
        walletRef && walletRef.current && walletRef.current.blur();
        upiRef && upiRef.current && upiRef.current.blur();
        billToCompanyRef && billToCompanyRef.current && billToCompanyRef.current.blur();
        noChargeBillRef && noChargeBillRef.current && noChargeBillRef.current.blur();
        discountValueRef && discountValueRef.current && discountValueRef.current.blur();
    };

    useEffect(() => {
        apiCall();
    }, []);

    const apiCall = async () => {
        let response;

        try {
            response = await API.get(
                `${languageCode}/pos/restaurants/:restaurantId/outlets/${outletId}/outlet-payment-configurations?ordering_mode=point_of_service`
            );
        } catch (error) {
            response = error;
        }

        if (response.success) {
            if (
                response.available_payments &&
                response.available_payments.wallet &&
                response.available_payments.wallet.length > 0
            ) {
                let innerArray = [];
                response.available_payments.wallet.map((wallet) => {
                    innerArray.push({ value: wallet.payment_method_id, label: wallet.payment_method });
                });

                if (innerArray.length > 0) {
                    setSelectedWallet(innerArray[0].value);
                    setWalletOptions(innerArray);
                }
            }

            if (response.available_payments) {
                setPaymentOptions(response.available_payments);
            }
        } else {
            alert('Error in fetching payments method');
        }
    };

    const amountValidate = () => {
        if (watch('cash') || watch('upi') || watch('cc_dc') || watch('wallet') || watch('net_banking')) {
            let amount = 0;
            if (parseFloat(watch('cash'))) {
                amount += parseFloat(watch('cash'));
            }
            if (parseFloat(watch('upi'))) {
                amount += parseFloat(watch('upi'));
            }
            if (parseFloat(watch('cc_dc'))) {
                amount += parseFloat(watch('cc_dc'));
            }
            if (parseFloat(watch('wallet'))) {
                amount += parseFloat(watch('wallet'));
            }
            if (parseFloat(watch('net_banking'))) {
                amount += parseFloat(watch('net_banking'));
            }

            if (parseFloat(amount) === parseFloat(tableData.net_total)) {
                return <p style={{ color: 'green', fontSize: '12px' }}>amount matched</p>;
            } else {
                return <p style={{ color: 'red', fontSize: '12px' }}>please match to be paid amount</p>;
            }
        } else {
            return <p style={{ color: 'red', fontSize: '12px' }}>please match to be paid amount</p>;
        }
    };

    const transactionArrayCreate = (amount, id, paymentOptions, selectedWallet) => {
        let transactionArray = [];
        console.log('payment options ', paymentOptions);
        if (watch('cash') && paymentOptions.cash) {
            if (id == paymentOptions.cash[0].payment_method_id) {
                transactionArray.push({
                    payment_method_id: id,
                    amount: amount,
                });
            } else {
                transactionArray.push({
                    payment_method_id: paymentOptions.cash[0].payment_method_id,
                    amount: watch('cash'),
                });
            }
        }

        if (watch('cc_dc') && paymentOptions.card) {
            if (id == paymentOptions.card[0].payment_method_id) {
                transactionArray.push({
                    payment_method_id: id,
                    amount: amount,
                });
            } else {
                transactionArray.push({
                    payment_method_id: paymentOptions.card[0].payment_method_id,
                    amount: watch('cc_dc'),
                });
            }
        }

        if (watch('net_banking') && paymentOptions.net_banking) {
            if (id == paymentOptions.net_banking[0].payment_method_id) {
                transactionArray.push({
                    payment_method_id: id,
                    amount: amount,
                });
            } else {
                transactionArray.push({
                    payment_method_id: paymentOptions.net_banking[0].payment_method_id,
                    amount: watch('net_banking'),
                });
            }
        }

        if (watch('wallet') && selectedWallet) {
            if (id == selectedWallet) {
                transactionArray.push({
                    payment_method_id: id,
                    amount: amount,
                });
            } else {
                transactionArray.push({
                    payment_method_id: selectedWallet,
                    amount: watch('wallet'),
                });
            }
        }

        if (watch('upi') && paymentOptions.upi) {
            if (id == paymentOptions.upi[0].payment_method_id) {
                transactionArray.push({
                    payment_method_id: id,
                    amount: amount,
                });
            } else {
                transactionArray.push({
                    payment_method_id: paymentOptions.upi[0].payment_method_id,
                    amount: watch('upi'),
                });
            }
        }

        if (watch('bill_to_company') && paymentOptions.bill_to_company) {
            if (id == paymentOptions.bill_to_company[0].payment_method_id) {
                transactionArray.push({
                    payment_method_id: id,
                    amount: amount,
                });
            } else {
                transactionArray.push({
                    payment_method_id: paymentOptions.bill_to_company[0].payment_method_id,
                    amount: watch('bill_to_company'),
                });
            }
        }

        if (watch('no_charge_bill') && paymentOptions.no_charge_bill) {
            if (id == paymentOptions.no_charge_bill[0].payment_method_id) {
                transactionArray.push({
                    payment_method_id: id,
                    amount: amount,
                });
            } else {
                transactionArray.push({
                    payment_method_id: paymentOptions.no_charge_bill[0].payment_method_id,
                    amount: watch('no_charge_bill'),
                });
            }
        }

        return transactionArray;
    };

    const transactionArrayCreateForButton = () => {
        let transactionArray = [];
        console.log('paymentOptions ', paymentOptions);
        if (watch('cash') && paymentOptions.cash) {
            transactionArray.push({
                payment_method_id: paymentOptions.cash[0].payment_method_id,
                amount: watch('cash'),
            });
        }

        if (watch('cc_dc') && paymentOptions.card) {
            transactionArray.push({
                payment_method_id: paymentOptions.card[0].payment_method_id,
                amount: watch('cc_dc'),
            });
        }

        if (watch('net_banking') && paymentOptions.net_banking) {
            transactionArray.push({
                payment_method_id: paymentOptions.net_banking[0].payment_method_id,
                amount: watch('net_banking'),
            });
        }

        if (watch('wallet')) {
            transactionArray.push({
                payment_method_id: selectedWallet,
                amount: watch('wallet'),
            });
        }

        if (watch('upi') && paymentOptions.upi) {
            transactionArray.push({
                payment_method_id: paymentOptions.upi[0].payment_method_id,
                amount: watch('upi'),
            });
        }

        if (watch('bill_to_company') && paymentOptions.bill_to_company) {
            transactionArray.push({
                payment_method_id: paymentOptions.bill_to_company[0].payment_method_id,
                amount: watch('bill_to_company'),
            });
        }

        if (watch('no_charge_bill') && paymentOptions.no_charge_bill) {
            transactionArray.push({
                payment_method_id: paymentOptions.no_charge_bill[0].payment_method_id,
                amount: watch('no_charge_bill'),
            });
        }

        return transactionArray;
    };

    const amountSubmit = async () => {
        let transactionArray = transactionArrayCreateForButton();

        if (!transactionArray.length) {
            alert('Amounts required');
        }

        let obj = amountApiObj(transactionArray);

        let response;

        try {
            response = await API.put(
                `${languageCode}/pos/restaurants/:restaurantId/outlets/${outletId}/make-payment`,
                obj
            );
        } catch (error) {
            response = error;
        }

        if (response.success) {
            if (mode === 'dine_in') {
                onUpdateAmount();
            } else {
                onUpdateAmountOrderFetch();
            }
        } else {
            alert('Error in payment update');
        }
    };

    const onUpdateAmount = async () => {
        let response;

        try {
            response = await API.get(
                `/restaurants/:restaurantId/outlets/${outletId}/tables/${tableData.outlet_table_id}`
            );
        } catch (error) {
            response = error;
        }

        if (response.success) {
            dispatch({
                type: types.SET_TABLE_DATA,
                payload: {
                    tableData: response.orders,
                },
            });
        } else {
            alert('Error in amount fetch');
        }
    };

    const onUpdateAmountOrderFetch = async () => {
        let response;

        try {
            response = await API.get(`${languageCode}/pos/restaurants/:restaurantId/orders/${tableData.order_id}`);
        } catch (error) {
            response = error;
        }

        if (response.success) {
            dispatch({
                type: types.SET_TABLE_DATA,
                payload: {
                    tableData: response.order,
                },
            });
        } else {
            alert('Error in amount fetch');
        }
    };

    const amountApiObj = (transactionArray) => {
        if (mode === 'dine_in') {
            return {
                order_id: tableData.orders.length > 0 ? tableData.orders[0].order_id : 0,
                transactions: transactionArray,
                customer: { ...customerInformation },
            };
        } else {
            return {
                order_id: !isEmpty(tableData) ? tableData.order_id : 0,
                transactions: transactionArray,
                customer: { ...customerInformation },
            };
        }
    };

    const amountChangeApi = async (amount, id, paymentOptions, selectedWallet) => {
        let transactionArray = transactionArrayCreate(amount, id, paymentOptions, selectedWallet);

        let obj = amountApiObj(transactionArray);

        let response;

        try {
            response = await API.put(
                `${languageCode}/pos/restaurants/:restaurantId/outlets/${outletId}/make-payment`,
                obj
            );
        } catch (error) {
            response = error;
        }

        if (response.success) {
            if (mode === 'dine_in') {
                onUpdateAmount();
            } else {
                onUpdateAmountOrderFetch();
            }
        } else {
            alert('Error in payment update');
        }
    };

    const delayedQuery = useCallback(
        _.debounce(
            (amount, id, paymentOptions, selectedWallet) => amountChangeApi(amount, id, paymentOptions, selectedWallet),
            500
        ),
        []
    );

    const onAmountChange = (e, id) => {
        delayedQuery(e.target.value, id, paymentOptions, selectedWallet);
    };

    const onBackButton = () => {
        if (mode === 'dine_in') {
            setStatus({ kot: false, bill: true, payment: false, close: false });
        } else {
            setStatus({ kot: true, bill: false, payment: false, close: false });
        }
    };

    const receivedAmount = () => {
        let received = tableData.prepaid_amount ? parseFloat(tableData.prepaid_amount) : 0;

        if (watch('bill_to_company')) {
            received += parseFloat(watch('bill_to_company'));
        }

        if (watch('wallet')) {
            received += parseFloat(watch('wallet'));
        }

        if (watch('upi')) {
            received += parseFloat(watch('upi'));
        }

        if (watch('cash')) {
            received += parseFloat(watch('cash'));
        }

        if (watch('cc_dc')) {
            received += parseFloat(watch('cc_dc'));
        }

        if (watch('no_charge_bill')) {
            received += parseFloat(watch('no_charge_bill'));
        }

        return received;
    };

    const returnAmount = () => {
        let returnAmount = receivedAmount() - tableData.net_total;

        return returnAmount > 0 ? returnAmount : 0;
    };

    return (
        <EuiFlexGroup direction='column' className='paymentSectionStyle'>
            <EuiFlexItem style={{ display: 'flex', flexDirection: 'row', height: '75px' }}>
                <EuiPanel style={{ backgroundColor: '#ffdada', padding: '5px', width: '30%' }}>
                    <EuiStat
                        // tableData.amount_to_pay
                        title={tableData.net_total}
                        description='TOTAL'
                        textAlign='left'
                    >
                        <EuiIcon type='empty' />
                    </EuiStat>
                </EuiPanel>

                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', margin: '0 15px' }}>-</div>

                <EuiPanel style={{ backgroundColor: '#cfffd1', padding: '5px', width: '30%' }}>
                    <EuiStat
                        // tableData.net_total
                        title={receivedAmount()}
                        description='RECEIVED'
                        textAlign='left'
                    >
                        <EuiIcon type='empty' />
                    </EuiStat>
                </EuiPanel>

                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', margin: '0 15px' }}>=</div>

                <EuiPanel style={{ backgroundColor: '#ffffff', padding: '5px', width: '30%' }}>
                    <EuiStat
                        // tableData.net_total
                        title={returnAmount()}
                        description='RETURN'
                        textAlign='left'
                    >
                        <EuiIcon type='empty' />
                    </EuiStat>
                </EuiPanel>
            </EuiFlexItem>

            <EuiFlexItem grow={8} className='paymentSectionInputAreas'>
                <EuiFormRow
                    // label={'CASH'}
                    style={{ width: '98%' }}
                >
                    <Controller
                        onClick={(e) => setFieldFocus('cash')}
                        render={({ field }) => <EuiFieldNumber value={field.value} onChange={field.onChange} />}
                        name={'cash'}
                        prepend='CASH'
                        control={control}
                        fullWidth={true}
                        placeholder={'cash'}
                        inputRef={cashRef}
                        onWheel={onWheel}
                        // onKeyUp={(e) => onAmountChange(e, paymentOptions.cash[0].payment_method_id)}
                        steps={'any'}
                    />
                </EuiFormRow>

                {!isEmpty(paymentOptions) && paymentOptions.card && (
                    <EuiFormRow
                        // label={'CREDIT/DEBIT'}
                        style={{ width: '98%' }}
                    >
                        <Controller
                            render={({ field }) => <EuiFieldNumber value={field.value} onChange={field.onChange} />}
                            onClick={(e) => setFieldFocus('cc_dc')}
                            name={'cc_dc'}
                            prepend='CC/DC'
                            control={control}
                            fullWidth={true}
                            placeholder='credit card/debit card'
                            inputRef={cardRef}
                            onWheel={onWheel}
                            // onKeyUp={(e) => onAmountChange(e, paymentOptions.cash[0].payment_method_id)}
                            steps={'any'}
                        />
                    </EuiFormRow>
                )}

                {!isEmpty(paymentOptions) && paymentOptions.upi && (
                    <EuiFormRow
                        // label={'UPI'}
                        style={{ width: '98%' }}
                    >
                        <Controller
                            render={({ field }) => <EuiFieldNumber value={field.value} onChange={field.onChange} />}
                            onClick={(e) => setFieldFocus('upi')}
                            control={control}
                            name={'upi'}
                            prepend='UPI'
                            placeholder='upi'
                            inputRef={upiRef}
                            onWheel={onWheel}
                            // onKeyUp={(e) => onAmountChange(e, paymentOptions.upi[0].payment_method_id)}
                            steps={'any'}
                        />
                    </EuiFormRow>
                )}

                {!isEmpty(paymentOptions) && paymentOptions.wallet && (
                    <EuiFormRow label={''} fullWidth={true}>
                        <Controller
                            render={({ field }) => <EuiFieldNumber value={field.value} onChange={field.onChange} />}
                            onClick={(e) => setFieldFocus('wallet')}
                            name={'wallet'}
                            control={control}
                            placeholder='wallet'
                            inputRef={walletRef}
                            onWheel={onWheel}
                            // onKeyUp={(e) => onAmountChange(e, selectedWallet)}
                            steps={'any'}
                            prepend={
                                <EuiFormRow label={''} style={{ width: '140px' }}>
                                    <EuiSelect
                                        options={walletOptions}
                                        value={selectedWallet}
                                        onChange={(e) => setSelectedWallet(e.target.value)}
                                    />
                                </EuiFormRow>
                            }
                        />
                    </EuiFormRow>
                )}

                {!isEmpty(paymentOptions) && paymentOptions.bill_to_company && (
                    <EuiFormRow
                        // label={'BTC'}
                        style={{ width: '98%' }}
                    >
                        <Controller
                            render={({ field }) => <EuiFieldNumber value={field.value} onChange={field.onChange} />}
                            onClick={(e) => setFieldFocus('bill_to_company')}
                            name={'bill_to_company'}
                            prepend='BTC'
                            control={control}
                            fullWidth={true}
                            placeholder='Bill to company'
                            inputRef={billToCompanyRef}
                            onWheel={onWheel}
                            // onKeyUp={(e) => onAmountChange(e, paymentOptions.bill_to_company[0].payment_method_id)}
                            steps={'any'}
                        />
                    </EuiFormRow>
                )}

                {!isEmpty(paymentOptions) && paymentOptions.no_charge_bill && (
                    <EuiFormRow
                        // label={'NO CHARGE BILL'}
                        style={{ width: '98%' }}
                    >
                        <Controller
                            render={({ field }) => <EuiFieldNumber value={field.value} onChange={field.onChange} />}
                            onClick={(e) => setFieldFocus('no_charge_bill')}
                            name={'no_charge_bill'}
                            prepend='NCB'
                            control={control}
                            fullWidth={true}
                            placeholder='No charge bill'
                            inputRef={noChargeBillRef}
                            onWheel={onWheel}
                            // onKeyUp={(e) => onAmountChange(e, paymentOptions.no_charge_bill[0].payment_method_id)}
                            steps={'any'}
                        />
                    </EuiFormRow>
                )}
            </EuiFlexItem>

            {/* <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', width: '94%'}}>
                    {
                        // (!isEmpty(paymentOptions) && paymentOptions.cash) && (
                            <EuiFlexItem style={{width: '48%', margin: '15px 5px'}}>
                                <EuiFormRow
                                    // label={'CASH'}
                                    style={{width: '98%'}}
                                >
                                    <Controller
                                        onClick={(e) => setFieldFocus('cash')}
                                        render={({field}) => <EuiFieldNumbervalue={field.value} onChange={field.onChange} />}
                                        name={'cash'}
                                        prepend="CASH"
                                        control={control}
                                        fullWidth={true}
                                        placeholder={'cash'}
                                        inputRef={cashRef}
                                        onWheel={onWheel}    
                                        // onKeyUp={(e) => onAmountChange(e, paymentOptions.cash[0].payment_method_id)}
                                        steps={'any'}
                                    />
                                </EuiFormRow>
                            </EuiFlexItem>
                        // )
                    }

                    {
                        (!isEmpty(paymentOptions) && paymentOptions.card) && (
                            <EuiFlexItem style={{width: '48%', margin: '15px 5px'}}>
                                <EuiFormRow
                                    // label={'CREDIT/DEBIT'}
                                    style={{width: '98%'}}
                                >
                                    <Controller
                                        render={({field}) => <EuiFieldNumbervalue={field.value} onChange={field.onChange} />}
                                        onClick={(e) => setFieldFocus('cc_dc')}
                                        name={'cc_dc'}
                                        prepend="CC/DC"
                                        control={control}
                                        fullWidth={true}
                                        placeholder='credit card/debit card'
                                        inputRef={cardRef}
                                        onWheel={onWheel}    
                                        // onKeyUp={(e) => onAmountChange(e, paymentOptions.cash[0].payment_method_id)}
                                        steps={'any'}
                                    />
                                </EuiFormRow>
                            </EuiFlexItem>
                        )
                    }
                </div> */}

            {/* <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '94%'}}> */}
            {/* {
                        (!isEmpty(paymentOptions) && paymentOptions.net_banking) && (
                            <div style={{width: '48%', margin: '15px 5px'}}>
                                <EuiFormRow
                                    label={'NET BANKING'}
                                    style={{width: '98%'}}
                                >
                                    <Controller
                                        render={({field}) => <EuiFieldNumber value={field.value} onChange={field.onChange} />}
                                        onClick={(e) => setFieldFocus('net_banking')}
                                        control={control}
                                        fullWidth={true}
                                        name={'net_banking'}
                                        prepend="NET"
                                        placeholder='Net Banking'
                                        inputRef={netBankingRef}
                                        onWheel={onWheel}    
                                        // onKeyUp={(e) => onAmountChange(e, paymentOptions.cash[0].payment_method_id)}
                                        steps={'any'}
                                    />
                                </EuiFormRow>
                            </div>
                        )
                    } */}

            {/* {
                        (!isEmpty(paymentOptions) && paymentOptions.upi) && (
                            <div style={{width: '48%', margin: '15px 5px'}}>
                                <EuiFormRow
                                    // label={'UPI'}
                                    style={{width: '98%'}}
                                >
                                    <Controller
                                        render={({field}) => <EuiFieldNumber value={field.value} onChange={field.onChange} />}
                                        onClick={(e) => setFieldFocus('upi')}
                                        control={control}
                                        fullWidth={true}
                                        name={'upi'}
                                        prepend="UPI"
                                        placeholder='upi'
                                        inputRef={upiRef}
                                        onWheel={onWheel}    
                                        // onKeyUp={(e) => onAmountChange(e, paymentOptions.upi[0].payment_method_id)}
                                        steps={'any'}
                                    />
                                </EuiFormRow>
                            </div>
                        )
                    } */}
            {/* </div> */}

            {/* <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '94%'}}>
                    {
                        (!isEmpty(paymentOptions) && paymentOptions.wallet) && (
                            <div style={{display: 'flex', flexDirection: 'column', margin: '15px 5px'}}>
                                <div style={{display: 'flex', flexDirection: 'row'}}> */}
            {/* <div>
                                        <EuiFormRow
                                            label={''}
                                            style={{width: '120px'}}
                                        >
                                            <EuiSelect
                                                options={walletOptions}
                                                value={selectedWallet}
                                                onChange={(e) => setSelectedWallet(e.target.value)}
                                            /> 
                                        </EuiFormRow>
                                    </div> */}
            {/* <div>
                                        <EuiFormRow
                                            label={''}
                                            fullWidth={true}
                                        >
                                            <Controller
                                                render={({field}) => <EuiFieldNumber value={field} onChange={field.onChange} />}
                                                onClick={(e) => setFieldFocus('wallet')}
                                                name={'wallet'}
                                                control={control}
                                                fullWidth={true}
                                                placeholder='wallet'
                                                inputRef={walletRef}
                                                onWheel={onWheel}    
                                                // onKeyUp={(e) => onAmountChange(e, selectedWallet)}
                                                steps={'any'}
                                                prepend={
                                                    <EuiFormRow
                                                        label={''}
                                                        style={{width: '140px'}}
                                                    >
                                                        <EuiSelect
                                                            options={walletOptions}
                                                            value={selectedWallet}
                                                            onChange={(e) => setSelectedWallet(e.target.value)}
                                                        /> 
                                                    </EuiFormRow>
                                                }
                                            />
                                        </EuiFormRow>
                                    </div> */}
            {/* </div>
                            </div>
                        )
                    }
                </div> */}

            {/* <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '94%'}}>
                    {
                        (!isEmpty(paymentOptions) && paymentOptions.bill_to_company) && (
                            <EuiFlexItem style={{width: '48%', margin: '15px 5px'}}>
                                <EuiFormRow
                                    // label={'BTC'}
                                    style={{width: '98%'}}
                                >
                                    <Controller
                                        render={({field}) => <EuiFieldNumber value={field} onChange={field.onChange} />}
                                        onClick={(e) => setFieldFocus('bill_to_company')}
                                        name={'bill_to_company'}
                                        prepend="BTC"
                                        control={control}
                                        fullWidth={true}
                                        placeholder='Bill to company'
                                        inputRef={billToCompanyRef}
                                        onWheel={onWheel}    
                                        // onKeyUp={(e) => onAmountChange(e, paymentOptions.bill_to_company[0].payment_method_id)}
                                        steps={'any'}
                                    />
                                </EuiFormRow>
                            </EuiFlexItem>
                        )
                    }

                    {
                        (!isEmpty(paymentOptions) && paymentOptions.no_charge_bill) && (
                            <EuiFlexItem style={{width: '48%', margin: '15px 5px'}}>
                                <EuiFormRow
                                    // label={'NO CHARGE BILL'}
                                    style={{width: '98%'}}
                                >
                                    <Controller
                                        render={({field}) => <EuiFieldNumber value={field} onChange={field.onChange} />}
                                        onClick={(e) => setFieldFocus('no_charge_bill')}
                                        name={'no_charge_bill'}
                                        prepend="NCB"
                                        control={control}
                                        fullWidth={true}
                                        placeholder='No charge bill'
                                        inputRef={noChargeBillRef}
                                        onWheel={onWheel}    
                                        // onKeyUp={(e) => onAmountChange(e, paymentOptions.no_charge_bill[0].payment_method_id)}
                                        steps={'any'}
                                    />
                                </EuiFormRow>
                            </EuiFlexItem>
                        )
                    }
                </div>
            </EuiFlexItem> */}

            {/* <EuiFlexItem style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}>
                {amountValidate()}
            </EuiFlexItem> */}

            {/* <KeyboardComponent fieldFocus={fieldFocus} watch={watch} setValue={setValue}/> */}

            {/* <EuiFlexItem style={{display: 'flex', flexDirection: 'row', alignItems: 'flex-end', marginBottom: '0px'}}>
                <EuiButton style={{ backgroundColor: '#275af6'}} fill fullWidth={true} onClick={amountSubmit}>
                    SETTLE PAYMENT
                </EuiButton>
            </EuiFlexItem> */}

            <EuiFlexItem
                grow={2}
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'flex-end',
                    justifyContent: 'space-between',
                }}
            >
                <div style={{ marginRight: '10px', cursor: 'pointer', fontSize: '14px' }} onClick={onBackButton}>
                    <BackArrow />
                </div>

                {mode === 'dine_in' ? (
                    <EuiButton
                        size='s'
                        style={{ backgroundColor: '#275af6' }}
                        fill
                        fullWidth={true}
                        onClick={amountSubmit}
                    >
                        SETTLE PAYMENT
                    </EuiButton>
                ) : (
                    <>
                        <EuiButton
                            size='s'
                            style={{ backgroundColor: '#275af6', width: '30%', fontSize: '14px' }}
                            fill
                            fullWidth
                        >
                            SPLIT BILL
                        </EuiButton>

                        <EuiButton
                            onClick={() => placeOrderCall(true)}
                            size='s'
                            style={{ backgroundColor: '#275af6', width: '30%', fontSize: '14px' }}
                            fill
                            fullWidth
                        >
                            PRINT & PLACE
                        </EuiButton>

                        <EuiButton
                            onClick={() => placeOrderCall()}
                            size='s'
                            style={{ backgroundColor: '#275af6', width: '30%', fontSize: '14px' }}
                            fill
                            fullWidth
                        >
                            SAVE & PLACE
                        </EuiButton>
                    </>
                )}
            </EuiFlexItem>
        </EuiFlexGroup>
    );
};

export default PaymentSection;
