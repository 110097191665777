export const SET_BILLING_CATEGORIES = 'set-billing-categories';
export const SET_ACTIVE_CATEGORY_ID = 'set-billing-active-category-id';
export const SET_ACTIVE_ITEM_ID = 'set-billing-active-item-id';
export const SET_ITEM_LIST = 'set-billing-item-list';
export const SET_BILLING_ITEM = 'set-billing-item';
export const SET_ADDON_MODAL_VISIBLE = 'set-addon-modal-visible';
export const SET_SELECTED_ITEM_ADDON_LIST = 'set-selected-item-addonlist';
export const SET_SELECTED_ITEM = 'set-selected-item';
export const SET_MODE = 'set-mode';
export const SET_HOLD_ORDER = 'set-hold-order';
export const SET_HOLD_ORDER_MODAL = 'set-hold-order-modal';
export const SET_SPLIT_ORDER_MODAL = 'set-split-order-modal';
export const SET_DISCOUNT = 'set-discount';
export const SET_ITEM_FREE_LIST = 'set-item-free-list';
export const SET_CUSTOMER_INFORMATION = 'set-customer-information';
export const SET_CUSTOMER_INFORMATION_FINISH = 'set-customer-information-finish';
export const SET_CUSTOMER_FIRST_ORDER = 'set-customer-first-order';
export const SET_IS_DIRTY = 'set-is-dirty';
export const SET_TABLE_SELECTED_LIST = 'set-table-selected-list';
export const SET_CART_ID = 'set-cart-id';
export const SET_DINE_IN_STEPS_STATUS = 'set-dine-in-steps-status';
export const SET_ORDER_ID = 'set-order-id';
export const SET_BILLING_ITEM_CART_STRUCTURE = 'set-billing-item-cart-structure';
export const SET_PREVIOUS_ORDERS = 'set-previous-orders';
export const SET_ITEM_COMMENT_MODAL = 'set-item-comment-modal';
export const SET_SELECTED_DRAGGABLE_ITEMS = 'set-selected-draggable-items';
export const SET_TABLE_DATA = 'set-table-data';
export const SET_TABLES = 'set-tables';
export const SET_SEQUENCE_COUNT_ADD = 'set-sequence-count-add';
export const SET_SEQUENCE_COUNT_MINUS = 'set-sequence-count-minus';
export const SET_RESET_STATE = 'set-reset-state';
export const SET_ADD_NEW = 'set-add-new';
