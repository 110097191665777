import React, { useState, useRef, useEffect } from 'react';
import {
    EuiFlyout,
    EuiFlyoutHeader,
    EuiTitle,
    EuiFlyoutBody,
    EuiFlexGroup,
    EuiFlyoutFooter,
    EuiFlexItem,
    EuiButtonEmpty,
    EuiFormRow,
    EuiFieldPassword,
    EuiButton,
    EuiSelect,
    EuiFieldNumber,
    EuiFieldText,
    EuiTextColor,
    EuiButtonIcon,
    EuiForm,
} from '@elastic/eui';
import API from '../../api/axios/API';
import { useDispatch, useSelector } from 'react-redux';
import * as types from '../../reduxStore/types/billing';
import { Controller, useForm } from 'react-hook-form';
import { isEmpty } from 'lodash';
import { applyDiscount } from '../../api/applyDiscount';

const OtherOfferTab = (props) => {
    const {
        setDiscountFlyout,
        subTotal,
        setValueBllingSection,
        setOptionsValueBillingSection,
        setDiscountData,
        outletId,
    } = props;
    const discountValueRef = useRef(null);

    const {
        register,
        handleSubmit,
        control,
        watch,
        reset,
        setValue,

        formState: { errors },
    } = useForm({
        defaultValues: {},
    });

    const [password, setPassword] = useState('');
    const [isPasswordError, setIsPasswordError] = useState(false);
    const [passwordMatched, setPasswordMatched] = useState(false);
    const [loginLoader, setLoginLoader] = useState(false);
    const [errorMessage, setErrorMessage] = useState({});
    const [showError, setShowError] = useState(false);

    const languageCode = useSelector((state) => state.language.language);
    const customerInformation = useSelector((state) => state.billing.customerInformation);
    const previousOrders = useSelector((state) => state.billing.previousOrders);
    const mode = useSelector((state) => state.billing.mode);
    const tableData = useSelector((state) => state.billing.tableData);
    const billingItem = useSelector((state) => state.billing.billingItem);

    const options = [
        { value: 'select_discount', text: 'Discount' },
        { value: 'percentage_discount', text: 'Percentage Discount' },
        { value: 'flat_discount', text: 'Amount Discount' },
        { value: 'get_an_item_free', text: 'Item Free' },
    ];

    const [value, setOptionsValue] = useState(options[0].value);

    const dispatch = useDispatch();

    const onChange = (e) => {
        setOptionsValue(e.target.value);

        dispatch({
            type: types.SET_DISCOUNT,
            payload: {
                discount: e.target.value,
            },
        });
    };

    const onKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            event.stopPropagation();
            console.log('Password submitted');
            passwordCheck();
        }
    };

    const passwordCheck = async () => {
        if (password.length === 0) {
            setIsPasswordError(true);
            return;
        }

        setLoginLoader(true);
        let response;

        try {
            response = await API.post(`/restaurants/:restaurantID/users/validate-password`, {
                password: password,
            });
        } catch (error) {
            response = error;
        }

        if (response.success) {
            setPasswordMatched(true);
        } else {
            setPasswordMatched(false);
            setErrorMessage(response.errors);
            setShowError(true);
        }

        setLoginLoader(false);
    };

    const onCloseModal = () => {
        setDiscountFlyout(false);
    };

    const errorMessageForDiscount = () => {
        if (value === options[1].value) {
            return 'Please enter value between 0 to 100';
        } else if (value === options[2].value) {
            return 'Please enter value between 0 - ' + subTotal();
        }
    };

    const onWheel = () => {
        discountValueRef && discountValueRef.current && discountValueRef.current.blur();
    };

    const displayErrorServer = () => {
        let errorsList = [];
        if (!isEmpty(errorMessage)) {
            for (var key in errorMessage) {
                errorsList.push(<EuiTextColor color='danger'>{errorMessage[key]}</EuiTextColor>);
            }
        }
        return errorsList;
    };

    const onUpdateDiscount = async () => {
        let response;

        try {
            response = await API.get(
                `/restaurants/:restaurantId/outlets/${outletId}/tables/${tableData.outlet_table_id}`
            );
        } catch (error) {
            response = error;
        }

        if (response.success) {
            dispatch({
                type: types.SET_TABLE_DATA,
                payload: {
                    tableData: response.orders,
                },
            });
        } else {
            alert('Error in amount fetch');
        }
    };

    const onUpdateDiscountOrderFetch = async () => {
        let response;

        try {
            response = await API.get(`${languageCode}/pos/restaurants/:restaurantId/orders/${tableData.order_id}`);
        } catch (error) {
            response = error;
        }

        if (response.success) {
            dispatch({
                type: types.SET_TABLE_DATA,
                payload: {
                    tableData: response.order,
                },
            });
        } else {
            alert('Error in amount fetch');
        }
    };

    const discountApply = async (data) => {
        dispatch(applyDiscount({ outletId, data, value })).then((response) => {
            if (response.success) {
                if (mode === 'dine_in') {
                    onUpdateDiscount();
                } else {
                    onUpdateDiscountOrderFetch();
                }
                alert('Success, Applied discount');
            } else {
                alert('Error in applying discount');
            }
        });
    };

    const onSubmit = (data) => {
        // && mode === "dine_in"
        if (value !== 'select_discount' && value !== 'get_an_item_free' && !isEmpty(previousOrders)) {
            discountApply(data);
        }

        setDiscountData({
            discount_value: data.discount_value,
            discount: data.discount,
        });

        onCloseModal();
        setOptionsValueBillingSection(value);
    };

    return (
        <>
            <EuiForm component='form' onSubmit={handleSubmit(onSubmit)}>
                <EuiFlexGroup direction='column' style={{ padding: '10px' }}>
                    <EuiFlexItem>
                        {!passwordMatched ? (
                            <EuiFlexItem>
                                <EuiFormRow
                                    label='Password'
                                    isInvalid={isPasswordError}
                                    error={['Password is required']}
                                >
                                    <EuiFieldPassword
                                        type={'dual'}
                                        name='first'
                                        icon='lock'
                                        placeholder='Password'
                                        value={password}
                                        isInvalid={isPasswordError}
                                        onKeyDown={onKeyDown}
                                        onChange={(e) => setPassword(e.target.value)}
                                    />
                                </EuiFormRow>
                                <EuiButton
                                    isLoading={loginLoader}
                                    onClick={() => passwordCheck()}
                                    style={{ width: '100px' }}
                                >
                                    Login
                                </EuiButton>
                            </EuiFlexItem>
                        ) : (
                            <>
                                <EuiFlexItem style={{ width: '50%' }}>
                                    <EuiSelect
                                        id='discountSelect'
                                        options={options}
                                        value={value}
                                        onChange={(e) => onChange(e)}
                                        aria-label='Discount'
                                    />
                                </EuiFlexItem>
                                <EuiFlexItem style={{ paddingBottom: '10px', flexDirection: 'row' }}>
                                    {(value === 'percentage_discount' || value === 'flat_discount') && (
                                        <EuiFlexItem style={{ paddingRight: '20px' }}>
                                            <EuiFormRow
                                                label={'discount value'}
                                                fullWidth={true}
                                                error={errors['discount_value'] ? errors['discount_value'].message : ''}
                                                isInvalid={errors['discount_value'] && errors['discount_value'].message}
                                            >
                                                <Controller
                                                    render={({ field }) => (
                                                        <EuiFieldNumber value={field.value} onChange={field.onChange} />
                                                    )}
                                                    name={'discount_value'}
                                                    placeholder='Customer discount value'
                                                    control={control}
                                                    fullWidth={true}
                                                    // step={'any'}
                                                    rules={{
                                                        required: 'Please enter value',
                                                        validate: (value1) =>
                                                            0 > value1 ||
                                                            value1 > (value === 'flat_discount' ? subTotal() : 100)
                                                                ? errorMessageForDiscount()
                                                                : null,
                                                    }}
                                                    isInvalid={
                                                        errors['discount_value'] && errors['discount_value'].message
                                                    }
                                                    inputRef={discountValueRef}
                                                    onWheel={onWheel}
                                                    defaultValue={1}
                                                />
                                            </EuiFormRow>
                                        </EuiFlexItem>
                                    )}
                                    {value !== 'select_discount' && (
                                        <EuiFlexItem>
                                            <EuiFormRow
                                                label={'Discount reason'}
                                                fullWidth={true}
                                                error={errors['discount'] ? errors['discount'].message : ''}
                                                isInvalid={errors['discount']}
                                            >
                                                <Controller
                                                    render={({ field }) => (
                                                        <EuiFieldText value={field.value} onChange={field.onChange} />
                                                    )}
                                                    name={'discount'}
                                                    placeholder='Discount reason'
                                                    control={control}
                                                    fullWidth={true}
                                                    rules={{
                                                        required: 'Please enter discount reason',
                                                    }}
                                                    isInvalid={errors['discount']}
                                                />
                                            </EuiFormRow>
                                        </EuiFlexItem>
                                    )}
                                </EuiFlexItem>
                            </>
                        )}
                    </EuiFlexItem>
                </EuiFlexGroup>
                {!isEmpty(errorMessage) && showError && (
                    <EuiFlexGroup
                        direction='column'
                        style={{
                            backgroundColor: '#f8e9e9',
                            margin: '10px',
                            padding: '15px',
                            borderRadius: '5px',
                        }}
                    >
                        <EuiFlexItem
                            style={{
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                            }}
                        >
                            <EuiTextColor color='danger' style={{ paddingBottom: '10px' }}>
                                <b>Please resolve below validations</b>
                            </EuiTextColor>
                            <EuiButtonIcon
                                color={'warning'}
                                onClick={() => setShowError(false)}
                                iconType='cross'
                                aria-label='cross'
                            />
                        </EuiFlexItem>
                        {displayErrorServer()}
                    </EuiFlexGroup>
                )}
                <EuiFlexGroup justifyContent='flexEnd' margin='10px'>
                    {passwordMatched && (
                        <EuiFlexItem grow={false}>
                            <EuiButton fill type='submit'>
                                save
                            </EuiButton>
                        </EuiFlexItem>
                    )}
                </EuiFlexGroup>
            </EuiForm>
        </>
    );
};

export default OtherOfferTab;
