import API from '../axios/API';
import { createCustomer } from '../customer/createCustomer';
import { editCustomer } from '../customer/editCustomer';

export const occupyTable = (outletId, tableId, data, query) => {
    return async (dispatch, getState) => {
        let response;
        let customerId = data.id;

        // let formateddata = {...data, "customer_id": data.id, "country_code": data.selectedCountryNumber.dialCode, "mobile_number": data.selectedCountryNumber.number}

        if (!customerId && query !== 'update') {
            await dispatch(
                createCustomer({
                    ...data,
                    country_code: data.selectedCountryNumber.dialCode,
                    mobile_number: data.selectedCountryNumber.number,
                })
            ).then((res) => {
                if (res.success) {
                    customerId = res.customer.id;
                }
            });
        }

        if (query === 'update') {
            await dispatch(editCustomer(customerId, { ...data })).then((res) => {
                if (res.success) {
                    customerId = res.customer.id;
                }
            });
        }

        try {
            if (query === 'update') {
                response = await API.put(`restaurants/:restaurantId/outlets/${outletId}/table/occupy-update`, {
                    cover_count: data.cover_count,
                    customer_id: customerId,
                    outlet_table_id: tableId,
                });
            } else {
                response = await API.post(`restaurants/:restaurantID/outlets/${outletId}/table/occupy`, {
                    cover_count: data.cover_count,
                    customer_id: customerId,
                    outlet_table_id: tableId,
                });
            }
        } catch (error) {
            response = error;
        }

        return response;
    };
};
