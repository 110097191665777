import React, { useCallback, useState } from 'react';
import { EuiPopover, EuiButton, EuiSpacer, EuiCard, EuiNotificationBadge, EuiHorizontalRule } from '@elastic/eui';
import { useSelector } from 'react-redux';
import { PaymentMode } from './paymentMode';
import { userHasPermission } from '../../helper/auth';
import { ReactComponent as DropDownIcon } from '../../assets/img/svg/arrow_drop_down_black_24dp.svg';

export const CashToggleDropDown = () => {
    const [isPopoverOpen, setIsPopoverOpen] = useState(false);
    const outletList = useSelector((state) => state.outlet.outletList);
    const languageId = useSelector((state) => state.language.languageId);
    const permissions = useSelector((state) => state.permission.permission);
    const themeMode = useSelector((state) => state.theme.theme);

    const getOutletCashMessage = useCallback(() => {
        let outletMessage = '';
        let color;
        if (outletList.length === 1) {
            let activePaymentMethodCount = [];

            const activeOrderingModes = outletList[0]?.outlet_data?.ordering_modes.filter(
                (orderingMode) => orderingMode.is_enabled
            );
            outletList[0].outlet_data.payment_methods.forEach((paymentMethod) => {
                outletList[0].outlet_data.ordering_modes.forEach((mode) => {
                    if (
                        paymentMethod.payment_method === 'CASH_ON_DELIVERY' &&
                        paymentMethod.active &&
                        mode.is_enabled &&
                        paymentMethod.outlet_ordering_mode_id === mode.id
                    ) {
                        activePaymentMethodCount.push({
                            ...paymentMethod,
                        });
                    }
                });
            });

            if (activePaymentMethodCount.length === activeOrderingModes?.length) {
                color = '#7de2b5';
                outletMessage = 'Accepting COD';
            } else if (activePaymentMethodCount.length < activeOrderingModes?.length) {
                color = '#f5a140';
                outletMessage = 'Accepting COD On Some Orders';
            } else {
                color = '#ef6464';
                outletMessage = 'Not accepting COD';
            }

            if (activePaymentMethodCount?.length === 0 && activeOrderingModes?.length > 0) {
                color = '#ef6464';
                outletMessage = 'Not accepting COD';
            }
            return (
                <div
                    style={{
                        padding: '5px 0',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        overflow: 'hidden',
                    }}
                >
                    <EuiNotificationBadge
                        style={{
                            background: color,
                            clipPath: 'circle(40% at 50% 50%)',
                            height: '12px',
                            width: '12px',
                        }}
                        size='s'
                    />
                    <EuiButton
                        size='s'
                        onClick={() => setIsPopoverOpen(!isPopoverOpen)}
                        style={{
                            border: 'none',
                            boxShadow: 'none',
                            background: 'none',
                            textDecoration: 'none',
                            overflow: 'hidden',
                            transform: 'translateY(0)',
                        }}
                    >
                        {outletMessage}
                    </EuiButton>
                    <DropDownIcon
                        fill={themeMode === 'dark' && '#fff'}
                        style={{
                            transition: 'all',
                            transitionDuration: '300ms',
                            transform: isPopoverOpen ? 'rotate(180deg)' : 'rotate(0)',
                            overflow: 'hidden',
                        }}
                    />
                </div>
            );
        }
        let activePaymentMethodCount = [];
        let activeOrderingModes = [];
        for (const outlet of outletList) {
            for (const orderingMode of outlet?.outlet_data?.ordering_modes) {
                if (orderingMode.is_enabled) {
                    activeOrderingModes.push(orderingMode);
                }
            }
        }
        outletList?.forEach((outlet) => {
            outlet?.outlet_data?.payment_methods?.forEach((paymentMethod) => {
                outlet?.outlet_data?.ordering_modes?.forEach((mode) => {
                    if (
                        paymentMethod.payment_method === 'CASH_ON_DELIVERY' &&
                        paymentMethod.active &&
                        mode.is_enabled &&
                        paymentMethod.outlet_ordering_mode_id === mode.id
                    ) {
                        activePaymentMethodCount.push(paymentMethod);
                    }
                });
            });
        });

        if (activePaymentMethodCount.length === activeOrderingModes?.length) {
            color = '#7de2b5';
            outletMessage = 'Accepting COD';
        } else if (activePaymentMethodCount.length < activeOrderingModes?.length) {
            color = '#f5a140';
            outletMessage = 'Accepting COD On Some Orders';
        } else {
            color = '#ef6464';
            outletMessage = 'Not accepting COD';
        }

        if (activePaymentMethodCount?.length === 0 && activeOrderingModes?.length > 0) {
            color = '#ef6464';
            outletMessage = 'Not accepting COD';
        }

        return (
            <div
                style={{
                    padding: '5px 0',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                }}
            >
                <EuiNotificationBadge
                    style={{
                        background: color,
                        clipPath: 'circle(40% at 50% 50%)',
                        height: '12px',
                        width: '12px',
                    }}
                    size='s'
                />
                <div
                    style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', overflow: 'hidden' }}
                    onClick={() => setIsPopoverOpen(!isPopoverOpen)}
                >
                    <EuiButton
                        size='s'
                        style={{
                            border: 'none',
                            boxShadow: 'none',
                            background: 'none',
                            textDecoration: 'none',
                            overflow: 'hidden',
                            transform: 'translateY(0)',
                        }}
                    >
                        {outletMessage}
                    </EuiButton>
                    <DropDownIcon
                        fill={themeMode === 'dark' && '#fff'}
                        style={{
                            transition: 'all',
                            transitionDuration: '300ms',
                            transform: isPopoverOpen ? 'rotate(180deg)' : 'rotate(0)',
                        }}
                    />
                </div>
            </div>
        );
    }, [isPopoverOpen, outletList, themeMode]);

    const getOutletCashList = useCallback(
        (id) => {
            let cashList = [];
            outletList.forEach((outlet) => {
                if (outlet.value !== id) return;
                let paymentArr = [];
                outlet.outlet_data.ordering_modes.forEach((mode) => {
                    outlet.outlet_data.payment_methods.forEach((paymentMethod) => {
                        if (
                            paymentMethod.payment_method === 'CASH_ON_DELIVERY' &&
                            mode.is_enabled &&
                            paymentMethod.outlet_ordering_mode_id === mode.id
                        ) {
                            paymentArr.push({
                                id: paymentMethod.id,
                                active: paymentMethod.active,
                                type: mode.type,
                                restaurant_ordering_mode_id: paymentMethod.restaurant_ordering_mode_id,
                                display_name: mode.display_name,
                            });
                        }
                    });
                });

                paymentArr.forEach((payment, idx) => {
                    cashList.push(
                        <PaymentMode display_name={payment.display_name} key={idx} payment={payment} outletId={id} />
                    );
                });
            });

            return cashList;
        },
        [outletList]
    );

    return (
        <>
            {userHasPermission(permissions, '/outlet-mode', 'read') ? (
                <EuiPopover
                    panelPaddingSize='none'
                    style={{ maxHeight: '90%', overflow: 'auto' }}
                    button={getOutletCashMessage()}
                    isOpen={isPopoverOpen}
                    closePopover={() => setIsPopoverOpen(!isPopoverOpen)}
                    anchorPosition='downCenter'
                >
                    {outletList?.map((outlet, index) => (
                        <React.Fragment key={index.toString()}>
                            <EuiCard
                                key={index}
                                style={{ margin: '8px 16px' }}
                                titleSize='xs'
                                layout='horizontal'
                                title={outlet.outlet_data.translations.name[languageId]}
                                description={<>{getOutletCashList(outlet.value)}</>}
                            />
                            {index < outletList.length - 1 ? (
                                <>
                                    <EuiSpacer size='xs' />
                                    <EuiHorizontalRule margin='none' />
                                    <EuiSpacer size='xs' />
                                </>
                            ) : (
                                ''
                            )}
                        </React.Fragment>
                    ))}
                </EuiPopover>
            ) : (
                ''
            )}
        </>
    );
};
