import React from 'react';
import { EuiSuperSelect, EuiHealth } from '@elastic/eui';
const SelectOffer = ({ offerList, onChangeOffer }) => {
    const options = [
        {
            value: '1',
            inputDisplay: (
                <EuiHealth color='subdued' style={{ lineHeight: 'inherit' }}>
                    Select offer
                </EuiHealth>
            ),
        },
    ];
    return (
        <EuiSuperSelect
            options={offerList}
            valueOfSelected={options[0].value}
            onChange={(value) => onChangeOffer(value)}
            placeholder='Please select banner'
        />
    );
};

export default SelectOffer;
