import API from '../axios/API';
import moment from 'moment';

export const getTableBookingList = ({ outletId, status, activePage, rowSize, startDate, endDate }) => {
    return async (dispatch, getState) => {
        let response;

        let { language } = getState();

        try {
            let url = '';
            if (status == 'approved') {
                url = `${
                    language.language
                }/pos/restaurants/:restaurantId/outlets/${outletId}/table-booking-requests?status=${status}&filters[date_range][from_date]=${moment(
                    startDate
                ).format('YYYY-MM-DD')}&filters[date_range][to_date]=${moment(endDate).format('YYYY-MM-DD')}`;
            } else {
                url = `${language.language}/pos/restaurants/:restaurantId/outlets/${outletId}/table-booking-requests?status=${status}&page=${activePage}&per_page=${rowSize}`;
            }
            response = await API.get(url);
        } catch (error) {
            response = error;
        }

        return response;
    };
};
