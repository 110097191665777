import React from 'react';
import { MainLayout } from '../../components/layout/mainLayout/mainLayout';
import PrinterComponent from '../../components/Printers/printerComponent';
import { useSelector } from 'react-redux';
import { NoOutletMessage } from '../../utils/noOutletMessage';

const PrintersPage = () => {
    const selectedOutletId = useSelector((state) => state.outlet.selectedOutletId);
    return (
        <MainLayout title='Printer Settings' showOutletSelection={true}>
            {selectedOutletId ? <PrinterComponent outletId={selectedOutletId} /> : <NoOutletMessage />}
        </MainLayout>
    );
};

export default PrintersPage;
