import React, { useState, useEffect, memo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import swal from 'sweetalert';
import { NoOutletMessage } from '../../../utils/noOutletMessage';
import * as types from '../../../reduxStore/types/banner';
import OutletCategoryList from '../../../components/menuComponentsRefactorCopy/outletLinkage/outletCategoryList';
import { linkCategoryToOutlet } from '../../../api/menu/linkCategoryToOutlet';
import { unLinkCategoryFromOutlet } from '../../../api/menu/unLinkCategoryFromOutlet';
import {
    fetchOutletCatalogue,
    fetchRestaurantCatalogue,
} from '../../../components/menuComponentsRefactorCopy/catalogue/Api/FetchCatalogueInfo';
import { getFlatToTreeStructureNew } from '../../../helper/getFlatToTreeStructureNew';
import { SET_MENU_LIST } from '../../../reduxStore/types/menu';

const OutletLinkage = () => {
    const [outletBannerList, setOutletBannerList] = useState(null);
    const selectedOutletId = useSelector((state) => state.outlet.selectedOutletId);
    const [outletCategoryLoaded, setOutletCategoryLoaded] = useState({ restaurant: false, outlet: false });
    const languageId = useSelector((state) => state.language.languageId);
    const dispatch = useDispatch();

    useEffect(() => {
        if (selectedOutletId) {
            fetchRestaurantCatalogue(languageId).then((response) => {
                if (response.success) {
                    setOutletCategoryLoaded({ ...outletCategoryLoaded, restaurant: true });
                    dispatch({
                        type: SET_MENU_LIST,
                        payload: {
                            menuList: getFlatToTreeStructureNew(response.categories),
                        },
                    });
                }
            });
        }
    }, [dispatch, selectedOutletId, languageId]);

    // method for update catagory
    const updateBannerData = useCallback(
        async (outletID) => {
            dispatch({
                type: types.SET_OUTLET_BANNER_LOADER,
                payload: {
                    outletBannerLoader: { loader: true, error: false },
                },
            });
            fetchOutletCatalogue(outletID, languageId).then((response) => {
                if (response.success) {
                    setOutletBannerList([...getFlatToTreeStructureNew(response.categories)]);
                    setOutletCategoryLoaded({ ...outletCategoryLoaded, outlet: true });
                    dispatch({
                        type: types.SET_OUTLET_BANNER_LOADER,
                        payload: {
                            outletBannerLoader: { loader: false, error: false },
                        },
                    });
                }
            });
        },
        [dispatch, languageId, outletCategoryLoaded]
    );

    useEffect(() => {
        if (selectedOutletId) {
            updateBannerData(selectedOutletId);
        }
    }, [selectedOutletId]);

    // method handle's for catagory link
    const onChangeBanner = useCallback(
        async (value) => {
            dispatch(linkCategoryToOutlet(selectedOutletId, value)).then((res) => {
                if (!res.success) {
                    updateBannerData(value);
                    swal('Please try later', '', 'error');
                }
            });
        },
        [dispatch, selectedOutletId, updateBannerData]
    );

    // method handle's for catagory un-link
    const handleUnlink = useCallback(
        async (id) => {
            dispatch(unLinkCategoryFromOutlet(selectedOutletId, id)).then((res) => {
                if (res.success) {
                    let innerArray = [...outletBannerList];
                    let index = innerArray.findIndex((category) => category.category_id == id);
                    innerArray.splice(index, 1);
                    setOutletBannerList(innerArray);

                    fetchRestaurantCatalogue(languageId).then((response) => {
                        if (response.success) {
                            setOutletCategoryLoaded({ ...outletCategoryLoaded, restaurant: true });
                            dispatch({
                                type: SET_MENU_LIST,
                                payload: {
                                    menuList: getFlatToTreeStructureNew(response.categories),
                                },
                            });
                        }
                    });
                } else {
                    updateBannerData(id);
                    swal('Please try later', '', 'error');
                }
            });
        },
        [dispatch, languageId, outletBannerList, outletCategoryLoaded, selectedOutletId, updateBannerData]
    );

    return (
        <>
            {!selectedOutletId ? (
                <NoOutletMessage />
            ) : (
                <OutletCategoryList
                    outletId={selectedOutletId}
                    setOutletBannerList={setOutletBannerList}
                    outletBannerList={outletBannerList}
                    handleUnlink={handleUnlink}
                    onBannerLink={onChangeBanner}
                    outletCategoryLoaded={outletCategoryLoaded}
                />
            )}
        </>
    );
};

export default memo(OutletLinkage);
