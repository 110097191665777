import React, { useEffect, useState } from 'react';
import {
    EuiFieldNumber,
    EuiFlexGroup,
    EuiFlexItem,
    EuiFormRow,
    EuiSelect,
    EuiSpacer,
    EuiSuperSelect,
    EuiText,
} from '@elastic/eui';
import { Controller } from 'react-hook-form';
import TextField from '../../Form/TextField';
import { useSelector } from 'react-redux';

const OrderingModePrices = ({
    index,
    orderingMode,
    errors,
    control,
    priceRef,
    onWheel,
    isOutletMenu,
    comparePriceRef,
    packagingPriceRef,
    gstOptions,
}) => {
    // const priceInputName = `price_${orderingMode.restaurant_ordering_mode_id}`;
    // const comparePriceInputName = `compare_price_${orderingMode.restaurant_ordering_mode_id}`;
    // const packagingPriceInputName = `packaging_charge_${orderingMode.restaurant_ordering_mode_id}`;
    // const gstInputName = `gst_slab_${orderingMode.restaurant_ordering_mode_id}`;
    // const quantity = `quantity_${orderingMode.restaurant_ordering_mode_id}`;
    // const quantity_unit_id = `quantity_unit_id_${orderingMode.restaurant_ordering_mode_id}`;
    // const serves = `serves_${orderingMode.restaurant_ordering_mode_id}`;
    // const serves_unit = `serves_unit_${orderingMode.restaurant_ordering_mode_id}`;
    const priceInputName = `ordering_modes[${index}].price`;
    const comparePriceInputName = `ordering_modes[${index}].compare_price`;
    const packagingPriceInputName = `ordering_modes[${index}].packaging_charge`;

    const gstInputName = `ordering_modes[${index}].gst_slab`;
    const quantity = `ordering_modes[${index}].quantity`;
    const quantity_unit_id = `ordering_modes[${index}].quantity_unit_id`;
    const serves = `ordering_modes[${index}].serves`;
    const serves_unit = `ordering_modes[${index}].serves_unit`;
    const quantityUnits = useSelector((state) => state.menuReducer.quantityUnits);

    const [quantityIdOptions, setQuantityIdOptions] = useState([]);

    useEffect(() => {
        if (quantityUnits?.length) {
            let units = [];
            for (const element of quantityUnits) {
                units.push({
                    value: element.id,
                    inputDisplay: element.unit,
                });
            }
            setQuantityIdOptions(units);
        }
    }, [quantityUnits]);

    return (
        <>
            <EuiSpacer />
            <EuiFlexGroup
                gutterSize='s'
                direction='row'
                justifyContent='flexStart'
                alignItems='flexStart'
                wrap
                responsive
                // style={{ margin: '12px 8px' }}
            >
                <EuiFlexItem
                    style={{
                        minWidth: 120,
                    }}
                >
                    <EuiFormRow
                        style={{
                            maxWidth: '150px',
                        }}
                        label={`Price ${orderingMode.restaurant_ordering_mode_name}`}
                        error={errors[priceInputName] ? errors[priceInputName].message : ''}
                        isInvalid={errors[priceInputName]}
                    >
                        <Controller
                            render={({ field }) => {
                                return (
                                    <EuiFieldNumber
                                        value={field.value}
                                        onChange={field.onChange}
                                        placeholder='Item price'
                                    />
                                );
                            }}
                            name={priceInputName}
                            min={0}
                            step={'any'}
                            control={control}
                            inputRef={priceRef}
                            onWheel={onWheel}
                            rules={{
                                required: 'Please enter item price',
                            }}
                            defaultValue={orderingMode[priceInputName]}
                            isInvalid={errors[priceInputName]}
                            disabled={isOutletMenu}
                        />
                    </EuiFormRow>
                    <EuiText
                        size='s'
                        style={{
                            color: '#b8b9bd',
                        }}
                    >
                        <span>Excluding All taxes</span>
                    </EuiText>
                </EuiFlexItem>
                <EuiFlexItem>
                    <EuiFormRow
                        style={{
                            minWidth: 120,
                        }}
                        label={`Compare Price ${orderingMode.restaurant_ordering_mode_name}`}
                    >
                        <Controller
                            render={({ field }) => (
                                <EuiFieldNumber
                                    value={field.value}
                                    onChange={field.onChange}
                                    placeholder='Item compare price'
                                />
                            )}
                            name={comparePriceInputName}
                            min={0.0}
                            step={'any'}
                            control={control}
                            defaultValue={orderingMode[comparePriceInputName]}
                            inputRef={comparePriceRef}
                            onWheel={onWheel}
                            disabled={isOutletMenu}
                        />
                    </EuiFormRow>
                </EuiFlexItem>
                <EuiFlexItem>
                    <EuiFormRow
                        style={{
                            minWidth: 120,
                        }}
                        label={`Packaging ${orderingMode.restaurant_ordering_mode_name}`}
                    >
                        <Controller
                            render={({ field }) => (
                                <EuiFieldNumber
                                    value={field.value}
                                    onChange={field.onChange}
                                    placeholder='Item packaging'
                                />
                            )}
                            name={packagingPriceInputName}
                            min={0.0}
                            step={'any'}
                            control={control}
                            inputRef={packagingPriceRef}
                            defaultValue={
                                orderingMode[packagingPriceInputName] ? orderingMode[packagingPriceInputName] : 0
                            }
                            onWheel={onWheel}
                            disabled={isOutletMenu}
                        />
                    </EuiFormRow>
                    <EuiText
                        size='s'
                        style={{
                            color: '#b8b9bd',
                        }}
                    >
                        <span>Including All taxes</span>
                    </EuiText>
                </EuiFlexItem>
                <EuiFlexItem>
                    <EuiFormRow
                        label={`GST ${orderingMode.restaurant_ordering_mode_name}`}
                        style={{
                            minWidth: 120,
                        }}
                    >
                        <Controller
                            render={({ field }) => {
                                return (
                                    <EuiSelect
                                        options={gstOptions}
                                        value={field.value}
                                        onChange={field.onChange}
                                        disabled={isOutletMenu}
                                    />
                                );
                            }}
                            defaultValue={Number(orderingMode[gstInputName])}
                            name={gstInputName}
                            control={control}
                        />
                    </EuiFormRow>
                </EuiFlexItem>
                <EuiFlexItem>
                    <TextField
                        label={`Quantity ${orderingMode.restaurant_ordering_mode_name}`}
                        name={quantity}
                        control={control}
                        inputRef={priceRef}
                        onWheel={onWheel}
                        defaultValue={orderingMode[quantity]}
                        disabled={isOutletMenu}
                        errors={errors}
                        placeholder='Item Quantity'
                        style={{
                            minWidth: 120,
                        }}
                    />
                </EuiFlexItem>
                <EuiFlexItem>
                    <EuiFormRow
                        label={`Quantity Unit Id ${orderingMode.restaurant_ordering_mode_name}`}
                        style={{
                            minWidth: 120,
                        }}
                    >
                        <Controller
                            render={({ field }) => {
                                return (
                                    <EuiSuperSelect
                                        options={quantityIdOptions}
                                        valueOfSelected={field.value}
                                        onChange={field.onChange}
                                    />
                                );
                            }}
                            name={quantity_unit_id}
                            min={0}
                            step={'any'}
                            control={control}
                            inputRef={priceRef}
                            onWheel={onWheel}
                            defaultValue={orderingMode[quantity_unit_id]}
                            disabled={isOutletMenu}
                        />
                    </EuiFormRow>
                </EuiFlexItem>
                <EuiFlexItem>
                    <TextField
                        label={`Serves ${orderingMode.restaurant_ordering_mode_name}`}
                        name={serves}
                        control={control}
                        inputRef={priceRef}
                        onWheel={onWheel}
                        defaultValue={orderingMode[serves]}
                        disabled={isOutletMenu}
                        errors={errors}
                        placeholder='Item Serves'
                        style={{
                            minWidth: 120,
                        }}
                    />
                </EuiFlexItem>
                <EuiFlexItem>
                    <TextField
                        label={`Serves Unit ${orderingMode.restaurant_ordering_mode_name}`}
                        name={serves_unit}
                        control={control}
                        inputRef={priceRef}
                        onWheel={onWheel}
                        defaultValue={orderingMode[serves_unit]}
                        disabled={isOutletMenu}
                        errors={errors}
                        placeholder='Item Serves Unit'
                        style={{
                            minWidth: 120,
                        }}
                    />
                </EuiFlexItem>
            </EuiFlexGroup>
        </>
    );
};

export default React.memo(OrderingModePrices);
