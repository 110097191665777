import React, { useEffect, useState } from 'react';
import {
    EuiRadioGroup,
    EuiFlexGroup,
    EuiFlexItem,
    EuiFormRow,
    EuiForm,
    EuiButton,
    EuiSpacer,
    EuiLink,
    EuiButtonEmpty,
    EuiProgress,
} from '@elastic/eui';
import { Controller, useForm } from 'react-hook-form';
import TextField from '../../../components/Form/TextField';
import FormGroupDescription from '../../../components/formGroupDescription';
import { useDispatch, useSelector } from 'react-redux';
import { outletSettingsAPI } from '../../../api/outlet/outletSettingsAPI';
import * as types from '../../../reduxStore/types/outlet';
import { userHasPermission } from '../../../helper/auth';

const TaxSettigns = (props) => {
    const { getOutletData, outletId, fetchOutletById } = props;
    const {
        handleSubmit,
        control,
        watch,
        reset,
        setValue,

        formState: { isDirty, errors },
    } = useForm();
    const gst_applicable = [
        { id: 'get_no', label: 'No' },
        { id: 'get_yes', label: 'Yes' },
    ];
    const dispatch = useDispatch();
    const permissions = useSelector((state) => state.permission.permission);
    const restaurantDescriptions = useSelector((state) => state.auth.restaurantDescriptions);

    useEffect(() => {
        if (!userHasPermission(permissions, '/outlet-settings', 'write')) return;
        if (isDirty) {
            dispatch({
                type: 'set-portal-active',
                payload: {
                    portalActive: {
                        resetChanges: resetChanges,
                        submit: handleSubmit(onSubmit),
                    },
                },
            });
        } else {
            dispatch({
                type: 'set-portal-active',
                payload: {
                    portalActive: {},
                },
            });
        }
    }, [isDirty]);

    const onSubmit = (data) => {
        data.gst_applicable = data.gst_applicable === 'get_yes' ? true : false;
        dispatch(outletSettingsAPI('taxes', data)).then((res) => {
            if (res.success) {
                dispatch({
                    type: types.SET_PORTAL_ACTIVE,
                    payload: {
                        portalActive: {},
                    },
                });
                dispatch({
                    type: types.SET_PORTAL_ACTIVE_TAB_SWITCHED,
                    payload: {
                        tabSwitched: false,
                    },
                });
                fetchOutletById();
            } else {
                alert(res.message);
            }
        });
    };

    const resetChanges = () => {
        reset({
            gst_applicable: getOutletData.outlet.settings.gst_applicable ? 'get_yes' : 'get_no',
            gst_number: getOutletData.outlet.settings.gst_number,
        });
    };

    useEffect(() => {
        if (getOutletData.outlet) {
            resetChanges();
        }
    }, [getOutletData]);

    return (
        <>
            <EuiForm component='form' onSubmit={handleSubmit(onSubmit)}>
                <FormGroupDescription
                    title={restaurantDescriptions?.taxes?.display_name}
                    description={restaurantDescriptions?.taxes?.description}
                >
                    <EuiFormRow isInvalid={errors.gst_applicable} fullWidth={true}>
                        <Controller
                            render={({}) => (
                                <EuiRadioGroup
                                    options={gst_applicable}
                                    idSelected={watch('gst_applicable') ? watch('gst_applicable') : 'get_no'}
                                    onChange={(id) =>
                                        setValue(
                                            'gst_applicable',
                                            id === 'get_no' ? 'get_no' : id === 'get_yes' ? 'get_yes' : 'get_no',
                                            {
                                                shouldDirty: true,
                                            }
                                        )
                                    }
                                    legend={{
                                        children: <span> Do you charge goods and service tax ? </span>,
                                    }}
                                />
                            )}
                            name='gst_applicable'
                            defaultValue={watch('gst_applicable') ? watch('gst_applicable') : 'get_no'}
                            control={control}
                            fullWidth={true}
                        />
                    </EuiFormRow>

                    {watch('gst_applicable') === 'get_yes' && (
                        <>
                            <TextField
                                errors={errors}
                                label='Goods and service tax number'
                                name='gst_number'
                                placeholder='Please enter goods and service tax number'
                                rules={{ required: 'Please enter goods and service tax number' }}
                                control={control}
                            />
                            <EuiSpacer />
                        </>
                    )}
                </FormGroupDescription>
            </EuiForm>
        </>
    );
};

export default TaxSettigns;
