import React, { useState, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import ComboBoxField from '../../../components/Form/ComboBoxField';
import TextField from '../../../components/Form/TextField';
import SelectField from '../../../components/Form/SelectField';
import CheckBoxField from '../../../components/Form/CheckBoxField';
import { currencyData } from '../../../components/settings/countrySymbolAndInforamtion';
import API from '../../../api/axios/API';
import {
    EuiFlexGroup,
    EuiFlexItem,
    EuiFormRow,
    EuiRadioGroup,
    EuiCard,
    EuiText,
    EuiForm,
    EuiButtonEmpty,
    EuiSpacer,
} from '@elastic/eui';
import FormGroupDescription from '../../../components/formGroupDescription';
import LoaderButton from '../../../components/Form/loaderButton';
import { useDispatch, useSelector } from 'react-redux';
import { outletSettingsAPI } from '../../../api/outlet/outletSettingsAPI';
import * as types from '../../../reduxStore/types/outlet';
import { userHasPermission } from '../../../helper/auth';

const PaymentSetting = ({ getOutletData, fetchOutletById }) => {
    const {
        handleSubmit,
        control,
        watch,
        reset,
        setValue,

        formState: { isDirty, errors },
    } = useForm();
    let defaultValue = {};
    let paymentProvider = [];
    let cardProvider = [];

    const [loader, setLoader] = useState(false);
    const dispatch = useDispatch();
    const permissions = useSelector((state) => state.permission.permission);
    const restaurantDescriptions = useSelector((state) => state.auth.restaurantDescriptions);

    useEffect(() => {
        if (!userHasPermission(permissions, '/outlet-settings', 'write')) return;
        if (isDirty) {
            dispatch({
                type: 'set-portal-active',
                payload: {
                    portalActive: {
                        resetChanges: resetChanges,
                        submit: handleSubmit(onSubmit),
                    },
                },
            });
        } else {
            dispatch({
                type: 'set-portal-active',
                payload: {
                    portalActive: {},
                },
            });
        }
    }, [isDirty]);

    const slub = [
        {
            value: '0',
            inputDisplay: '0%',
        },
        {
            value: '5',
            inputDisplay: '5%',
        },
        {
            value: '12',
            inputDisplay: '12%',
        },
        {
            value: '18',
            inputDisplay: '18%',
        },
        {
            value: '28',
            inputDisplay: '28%',
        },
    ];
    const gst_applicable = [
        {
            id: 'get_no',
            label: 'No',
        },
        {
            id: 'get_yes',
            label: 'Yes',
        },
    ];
    const payment_provider_options = [
        {
            label: 'paytm',
        },
        {
            label: 'stripe',
        },
    ];
    const optionsPaytm = [
        {
            value: 'paytm',
            inputDisplay: 'Paytm',
        },
    ];

    const resetChanges = () => {
        const outletData = getOutletData.outlet;
        defaultValue.gst_applicable = outletData.settings.gst_applicable ? 'get_yes' : 'get_no';
        defaultValue.delivery_charges_gst_slab = outletData.settings.delivery_charges_gst_slab.toString();
        defaultValue.gst_number = outletData.settings.gst_number;

        //set payment provider
        if (outletData.payment_settings.paytm) {
            paymentProvider.push({
                label: 'paytm',
            });
        }

        if (outletData.payment_settings.stripe) {
            paymentProvider.push({
                label: 'stripe',
            });
        }

        defaultValue.payment_provider = paymentProvider;

        if (outletData.payment_settings) {
            if (outletData.payment_settings.paytm) {
                for (const [key, value] of Object.entries(outletData.payment_settings.paytm)) {
                    if (key === 'PAYTM_MERCHANT_KEY') {
                        defaultValue.paytm_merchent_key = value;
                    }
                    if (key === 'PAYTM_MERCHANT_ID') {
                        defaultValue.paytm_merchent_id = value;
                    }
                    if (key === 'PAYTM_CHILD_MERCHANT_ID') {
                        defaultValue.paytm_child_merchent_id = value;
                    }
                    if (key === 'PAYTM_INDUSTRY_TYPE_ID') {
                        defaultValue.paytm_industry_type_id = value;
                    }
                    if (key === 'PAYTM_WEBSITE') {
                        defaultValue.paytm_website = value;
                    }
                    if (key === 'PAYTM_BASE_API_URL') {
                        defaultValue.paytm_base_api_url = value;
                    }
                }
            }
            if (!outletData.payment_settings?.paytm?.hasOwnProperty('PAYTM_CHILD_MERCHANT_ID')) {
                defaultValue.paytm_child_merchent_id = '';
            }
            if (outletData.payment_settings.stripe) {
                for (const [key, value] of Object.entries(outletData.payment_settings.stripe)) {
                    if (key === 'STRIPE_KEY') {
                        defaultValue.stripe_key = value;
                    }
                    if (key === 'STRIPE_SECRET') {
                        defaultValue.stripe_secret = value;
                    }
                    if (key === 'STRIPE_WEBHOOK') {
                        defaultValue.stripe_webhook = value;
                    }
                    if (key === 'STRIPE_WEBHOOK_SECRET') {
                        defaultValue.stripe_webhook_secret = value;
                    }
                }
            }
        }

        if (paymentProvider.length > 0) {
            // eslint-disable-next-line
            paymentProvider.map((item) => {
                if (item.label === 'paytm') {
                    cardProvider.push({
                        value: 'paytm',
                        inputDisplay: 'Paytm',
                    });
                } else {
                    cardProvider.push({
                        value: 'stripe',
                        inputDisplay: 'Stripe',
                    });
                }
                return null;
            });
        }
        defaultValue.cardProvider = cardProvider;
        defaultValue.credit_card = cardProvider.length === 1 ? cardProvider[0].value : null;

        reset({
            ...defaultValue,
        });
    };
    useEffect(() => {
        if (getOutletData && getOutletData.outlet) {
            resetChanges();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getOutletData]);
    let currencyDataSymbol = [];
    let currencyDataCode = [];
    for (const key in currencyData) {
        let value1 = currencyData[key];
        currencyDataSymbol.push({
            value: value1.symbol_native,
            label: `${value1.name} - ${value1.symbol} - ${value1.symbol_native} `,
        });
    }

    for (const key in currencyData) {
        let value1 = currencyData[key];
        currencyDataCode.push({
            value: value1.code,
            label: value1.code,
        });
    }
    const [credit, setCredit] = useState(null);
    const onChangePaymentProvider = (selectedProvider) => {
        setValue('payment_provider', selectedProvider);
        let paymentOptions = [];

        selectedProvider.map((item) => {
            if (item.label === 'paytm') {
                paymentOptions.push({
                    value: 'paytm',
                    inputDisplay: 'Paytm',
                });
            }
            if (item.label === 'stripe') {
                paymentOptions.push({
                    value: 'stripe',
                    inputDisplay: 'Stripe',
                });
            }
            return null;
        });

        const data = paymentOptions.length > 0 ? paymentOptions[0].value : 'paytm';

        setValue('credit_card', data);
        setCredit(paymentOptions);
        watch('cardProvider', paymentOptions);
    };

    const onSubmit = async (data) => {
        const paytm = data.payment_provider.find((item) => {
            if (item.label === 'paytm') {
                return 'paytm';
            }
            return null;
        });
        const stripe = data.payment_provider.find((item) => {
            if (item.label === 'stripe') {
                return 'stripe';
            }
            return null;
        });
        let payment_settings = {};
        if (paytm) {
            if (
                watch()?.paytm_child_merchent_id !== '' &&
                watch()?.paytm_merchent_key !== '' &&
                watch()?.paytm_merchent_id !== '' &&
                watch()?.paytm_industry_type_id !== '' &&
                watch()?.paytm_website !== '' &&
                watch()?.paytm_base_api_url !== '' &&
                paytm.label === 'paytm'
            ) {
                payment_settings.paytm = {
                    PAYTM_MERCHANT_KEY: data.paytm_merchent_key,
                    PAYTM_MERCHANT_ID: data.paytm_merchent_id,
                    PAYTM_INDUSTRY_TYPE_ID: data.paytm_industry_type_id,
                    PAYTM_WEBSITE: data.paytm_website,
                    PAYTM_BASE_API_URL: data.paytm_base_api_url,
                    PAYTM_CHILD_MERCHANT_ID: data.paytm_child_merchent_id,
                };
            } else if (
                watch()?.paytm_merchent_key === '' &&
                watch()?.paytm_merchent_id === '' &&
                watch()?.paytm_industry_type_id === '' &&
                watch()?.paytm_website === '' &&
                watch()?.paytm_base_api_url === '' &&
                watch()?.paytm_child_merchent_id !== ''
            ) {
                payment_settings.paytm = {
                    PAYTM_CHILD_MERCHANT_ID: data.paytm_child_merchent_id,
                };
            } else {
                payment_settings.paytm = {
                    PAYTM_MERCHANT_KEY: data.paytm_merchent_key,
                    PAYTM_MERCHANT_ID: data.paytm_merchent_id,
                    PAYTM_INDUSTRY_TYPE_ID: data.paytm_industry_type_id,
                    PAYTM_WEBSITE: data.paytm_website,
                    PAYTM_BASE_API_URL: data.paytm_base_api_url,
                };
            }
        }
        if (stripe) {
            if (stripe.label === 'stripe') {
                payment_settings.stripe = {
                    STRIPE_KEY: data.stripe_key,
                    STRIPE_SECRET: data.stripe_secret,
                    STRIPE_WEBHOOK: data.stripe_webhook,
                    STRIPE_WEBHOOK_SECRET: data.stripe_webhook_secret,
                };
            }
        }

        data.payment_settings = payment_settings;

        Object.entries(data).forEach(([key, value]) => {
            if (key !== 'payment_settings') {
                delete data[key];
            }
        });

        setLoader(true);
        dispatch(outletSettingsAPI('payment_settings', data)).then((res) => {
            if (res.success) {
                dispatch({
                    type: types.SET_PORTAL_ACTIVE,
                    payload: {
                        portalActive: {},
                    },
                });
                dispatch({
                    type: types.SET_PORTAL_ACTIVE_TAB_SWITCHED,
                    payload: {
                        tabSwitched: false,
                    },
                });
                fetchOutletById();
            } else {
                alert(res.message);
            }
        });

        setLoader(false);
    };

    return (
        <EuiForm component='form' onSubmit={handleSubmit(onSubmit)}>
            <FormGroupDescription
                title={restaurantDescriptions?.select_a_payment_gateway?.display_name}
                description={restaurantDescriptions?.select_a_payment_gateway?.description}
            >
                <ComboBoxField
                    options={payment_provider_options}
                    selectedOptions={watch('payment_provider') ? watch('payment_provider') : payment_provider_options}
                    onChange={onChangePaymentProvider}
                    errors={errors}
                    label='Select payment provider'
                    name='payment_provider'
                    placeholder='Select one or more payment provider'
                    rules={{
                        validate: (value) =>
                            (watch('payment_provider') && watch('payment_provider').length > 0) ||
                            'Please select atleast one payment provider',
                    }}
                    control={control}
                />
            </FormGroupDescription>

            <EuiFormRow fullWidth>
                <EuiFlexGroup direction='column'>
                    {watch('payment_provider') &&
                        watch('payment_provider').map((item, index) => {
                            if (item.label === 'paytm') {
                                return (
                                    <EuiFlexItem
                                        key={index}
                                        style={{
                                            width: '100%',
                                            minWidth: '40%',
                                            flex: '0 1 40%',
                                        }}
                                    >
                                        <FormGroupDescription
                                            title={restaurantDescriptions?.paytm?.display_name}
                                            description={restaurantDescriptions?.paytm?.description}
                                        >
                                            <TextField
                                                errors={errors}
                                                label='Paytm merchent key'
                                                name='paytm_merchent_key'
                                                placeholder='Please enter paytm merchent key'
                                                rules={{
                                                    required: !watch('paytm_child_merchent_id')
                                                        ? 'Please enter paytm merchent key'
                                                        : false,
                                                }}
                                                control={control}
                                            />
                                            <TextField
                                                errors={errors}
                                                label='Paytm merchent id'
                                                name='paytm_merchent_id'
                                                placeholder='Please enter paytm merchent id'
                                                rules={{
                                                    required: !watch('paytm_child_merchent_id')
                                                        ? 'Please enter paytm merchent key'
                                                        : false,
                                                }}
                                                control={control}
                                            />
                                            <TextField
                                                errors={errors}
                                                label='Paytm child merchent id'
                                                name='paytm_child_merchent_id'
                                                placeholder='Please enter paytm child merchent id'
                                                rules={{
                                                    required:
                                                        watch('paytm_merchent_key') &&
                                                        watch('paytm_merchent_id') &&
                                                        watch('paytm_industry_type_id') &&
                                                        watch('paytm_website') &&
                                                        watch('paytm_base_api_url')
                                                            ? false
                                                            : 'Please Enter Paytm child id',
                                                }}
                                                control={control}
                                            />
                                            <TextField
                                                errors={errors}
                                                label='Paytm industry type id'
                                                name='paytm_industry_type_id'
                                                placeholder='Please enter paytm industry type id'
                                                rules={{
                                                    required: !watch('paytm_child_merchent_id')
                                                        ? 'Please enter paytm merchent key'
                                                        : false,
                                                }}
                                                control={control}
                                            />
                                            <TextField
                                                errors={errors}
                                                label='Paytm website'
                                                name='paytm_website'
                                                placeholder='Please enter paytm website'
                                                rules={{
                                                    required: !watch('paytm_child_merchent_id')
                                                        ? 'Please enter paytm merchent key'
                                                        : false,
                                                }}
                                                control={control}
                                            />
                                            <TextField
                                                errors={errors}
                                                label='Paytm base api url'
                                                name='paytm_base_api_url'
                                                placeholder='Please enter paytm base api url'
                                                rules={{
                                                    required: !watch('paytm_child_merchent_id')
                                                        ? 'Please enter paytm merchent key'
                                                        : false,
                                                }}
                                                control={control}
                                            />
                                        </FormGroupDescription>
                                    </EuiFlexItem>
                                );
                            }
                            if (item.label === 'stripe') {
                                return (
                                    <EuiFlexItem
                                        key={`${index}9876`}
                                        style={{
                                            width: '100%',
                                            minWidth: '40%',
                                            flex: '0 1 40%',
                                        }}
                                    >
                                        <FormGroupDescription
                                            title={restaurantDescriptions?.stripe?.display_name}
                                            description={restaurantDescriptions?.stripe?.description}
                                        >
                                            <TextField
                                                errors={errors}
                                                label='Stripe key'
                                                name='stripe_key'
                                                placeholder='Please enter stripe key'
                                                rules={{ required: 'Please enter stripe key' }}
                                                control={control}
                                            />
                                            <TextField
                                                errors={errors}
                                                label='Stripe secret'
                                                name='stripe_secret'
                                                placeholder='Please enter stripe secret'
                                                rules={{ required: 'Please enter stripe secret' }}
                                                control={control}
                                            />
                                            <TextField
                                                errors={errors}
                                                label='Stripe webhook'
                                                name='stripe_webhook'
                                                placeholder='Please enter stripe webhook'
                                                rules={{ required: 'Please enter stripe webhook' }}
                                                control={control}
                                            />
                                            <TextField
                                                errors={errors}
                                                label='Stripe webhook secret'
                                                name='stripe_webhook_secret'
                                                placeholder='Please enter webhook secret'
                                                rules={{ required: 'Please enter webhook secret' }}
                                                control={control}
                                            />
                                        </FormGroupDescription>
                                    </EuiFlexItem>
                                );
                            }
                            return null;
                        })}
                </EuiFlexGroup>
            </EuiFormRow>

            {/* <FormGroupDescription>
                {
                    isDirty ? (
                        <EuiFormRow>
                            <EuiFlexGroup alignItems="center">
                                <LoaderButton isLoading={loader}/>
                                <EuiFlexItem>
                                    <EuiButtonEmpty onClick={resetChanges}>Discard changes</EuiButtonEmpty>
                                </EuiFlexItem>
                            </EuiFlexGroup>
                        </EuiFormRow>
                    ): ''
                }
            </FormGroupDescription> */}
        </EuiForm>
    );
};

export default PaymentSetting;
