import { EuiButton, EuiFlexGroup, EuiFlexItem } from '@elastic/eui';
import React, { useCallback } from 'react';
import { QRCode } from 'react-qrcode-logo';

const TableQrCode = ({ selectedTable }) => {
    const downloadQR = useCallback(async () => {
        try {
            const canvas = document.getElementById('react-qrcode-logo');
            const pngUrl = canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream');
            let downloadLink = document.createElement('a');
            downloadLink.href = pngUrl;
            downloadLink.download = `${selectedTable.code}.png`;
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
        } catch (error) {
            alert('Error in downloading QR Code');
        }
    }, [selectedTable]);

    return (
        <>
            <EuiFlexGroup direction='column'>
                <EuiFlexItem>
                    <QRCode
                        ecLevel={'H'}
                        qrStyle={'dots'}
                        value={selectedTable.code.toString()}
                        // logoImage={FabSystemsLogo}
                        logoWidth={60}
                        logoHeight={60}
                    />
                </EuiFlexItem>
                <EuiFlexItem>
                    <EuiButton onClick={downloadQR}> Download QR Code </EuiButton>
                </EuiFlexItem>
            </EuiFlexGroup>
        </>
    );
};

export default React.memo(TableQrCode);
