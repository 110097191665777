import { EuiButtonIcon, EuiFlexItem, EuiLoadingContent, EuiSelect, EuiComboBox, EuiButton } from '@elastic/eui';
import React, { useEffect, useState } from 'react';
import CategorySection from './categorySection';
import BillingSection from './billingSection';
import HoldOrderModal from './holdOrderModal';
import API from '../../api/axios/API';
import { useSelector, useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';
import * as types from '../../reduxStore/types/billing';
import CustomerInformation from './customerInformation';
import { useHistory } from 'react-router-dom';
import ViewOrderFlyout from './viewOrderFlyout';
import AddonModal from './addonModal';

const BillingComponent = (props) => {
    const dispatch = useDispatch();

    const languageId = useSelector((state) => state.language.languageId);
    const categories = useSelector((state) => state.billing.categories);
    const itemList = useSelector((state) => state.billing.itemList);
    const finishCustomerInfo = useSelector((state) => state.billing.finishCustomerInfo);
    const addonModalVisible = useSelector((state) => state.billing.addonModalVisible);
    const selectedOutletId = useSelector((state) => state.outlet.selectedOutletId);
    const holdOrderModal = useSelector((state) => state.billing.holdOrderModal);

    const [suggestionOptions, setSuggestionOptions] = useState([]);
    const [selectedSuggestionOption, setSelectedSuggestionOption] = useState([]);

    const [scrollerName, setScrollerName] = useState('');
    // const [holdOrderModal, setHolOrderModal] = useState(false);

    const [selectedOutlet, setSelectedOutlet] = useState({
        isLoading: true,
        error: false,
        data: undefined,
    });

    const [outletOptions, setOutletOptions] = useState([]);
    const [changeOutlet, setChangeOutlet] = useState(false);

    let history = useHistory();

    const loadingContent = () => {
        if (!selectedOutlet.isLoading && selectedOutlet.error) {
            return 'Error in fetching outlet';
        } else if (!selectedOutlet.isLoading && !selectedOutlet.error) {
            return 'No outlet found';
        }

        return <EuiLoadingContent lines={10} />;
    };

    const onChange = (selectedOptions) => {
        if (selectedOptions.length > 0) {
            dispatch({
                type: types.SET_ACTIVE_CATEGORY_ID,
                payload: {
                    activeCategoryId: selectedOptions[0].id,
                },
            });

            selectedOptions[0].item_id &&
                dispatch({
                    type: types.SET_ACTIVE_ITEM_ID,
                    payload: {
                        activeItemId: selectedOptions[0].item_id,
                    },
                });

            if (selectedOptions[0].category_internal_name) {
                let scroller_category = document.getElementById(selectedOptions[0].category_internal_name);
                setScrollerName(selectedOptions[0].category_internal_name);
                if (scroller_category) {
                    scroller_category.scrollIntoView();
                }
            }

            let scroller = document.getElementById(selectedOptions[0].internal_name);
            setScrollerName(selectedOptions[0].internal_name);
            if (scroller) {
                scroller.scrollIntoView();
            }
        } else {
            setScrollerName('');
            dispatch({
                type: types.SET_ACTIVE_ITEM_ID,
                payload: {
                    activeItemId: '',
                },
            });
        }

        setSelectedSuggestionOption(selectedOptions);
    };

    useEffect(() => {
        if (scrollerName) {
            let scroller = document.getElementById(scrollerName);
            scroller && scroller.scrollIntoView();
        }
    }, [scrollerName, itemList]);

    const onChangeOutlet = (e) => {
        setSelectedOutlet({ ...selectedOutlet, data: e.target.value });
        setChangeOutlet(true);

        dispatch({
            type: types.SET_BILLING_ITEM,
            payload: {
                billingItem: {},
            },
        });

        dispatch({
            type: types.SET_BILLING_CATEGORIES,
            payload: {
                categories: {},
            },
        });

        dispatch({
            type: types.SET_ITEM_LIST,
            payload: {
                itemList: [],
            },
        });

        dispatch({
            type: types.SET_TABLE_SELECTED_LIST,
            payload: {
                tableSelectedList: [],
            },
        });
    };

    useEffect(() => {
        return () => {
            dispatch({
                type: types.SET_RESET_STATE,
            });
        };
    }, []);

    useEffect(() => {
        dispatch({
            type: types.SET_BILLING_ITEM,
            payload: {
                billingItem: {},
            },
        });

        dispatch({
            type: types.SET_BILLING_CATEGORIES,
            payload: {
                categories: {},
            },
        });

        dispatch({
            type: types.SET_ACTIVE_CATEGORY_ID,
            payload: {
                activeCategoryId: '',
            },
        });
    }, []);

    const addNewTable = () => {
        history.push('/table-management?from=billing');
    };

    const selectedOutletFilter = () => {
        if (selectedOutlet.data) {
            let data = outletOptions.filter((outlet) => outlet.value == selectedOutlet.data);
            if (data.length > 0) {
                return data[0].text;
            }
        }

        return '';
    };

    return (
        <>
            {holdOrderModal && <HoldOrderModal outletId={selectedOutletId} />}

            <div style={{ width: '100%', display: 'flex', flexDirection: 'row', overflow: 'hidden' }}>
                <div style={{ display: 'flex', flexDirection: 'column', width: '59%' }}>
                    {/* {
                        finishCustomerInfo && (
                            <EuiFlexItem style={{ width: '400px', paddingRight: '14px', marginBottom: '19px', marginTop: '5px' }}>
                                <EuiComboBox
                                    options={suggestionOptions}
                                    singleSelection={{ asPlainText: true }}
                                    selectedOptions={selectedSuggestionOption}
                                    onChange={onChange}
                                    placeholder='search by category or item'
                                />
                            </EuiFlexItem>
                        )
                    } */}

                    {selectedOutletId ? (
                        <div className='POSLeftSide'>
                            {finishCustomerInfo ? (
                                <CategorySection outletId={selectedOutletId} />
                            ) : (
                                <CustomerInformation outletId={selectedOutletId} />
                            )}
                        </div>
                    ) : (
                        loadingContent()
                    )}
                </div>

                <div style={{ width: '40%', margin: '5px 0' }}>
                    {selectedOutletId ? (
                        <>
                            {addonModalVisible ? (
                                <AddonModal outletId={selectedOutletId} />
                            ) : (
                                <BillingSection outletId={selectedOutletId} />
                            )}
                        </>
                    ) : (
                        <div style={{ paddingTop: '40px', paddingLeft: '15px' }}>{loadingContent()}</div>
                    )}
                </div>
            </div>
        </>
    );
};

export default BillingComponent;
