import React, { useEffect, useState } from 'react';
import TextField from '../../Form/TextField';
import {
    EuiButton,
    EuiCopy,
    EuiFlexGroup,
    EuiFlexItem,
    EuiFlyout,
    EuiFlyoutBody,
    EuiFlyoutHeader,
    EuiSpacer,
    EuiText,
    EuiTitle,
} from '@elastic/eui';
import FormGroupDescription from '../../formGroupDescription';
import ButtonGroup from '../../Form/ButtonGroup';
import { useDispatch, useSelector } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import { requestForRiderService } from '../../../api/requestThirdPartyRiderService';

const WeFastRider = (props) => {
    const {
        formState: { errors },
        control,
        watch,
        setValue,
    } = useFormContext();
    const [isFlyoutVisible, setIsFlyoutVisible] = useState(false);
    const restaurantDescriptions = useSelector((state) => state.auth.restaurantDescriptions);
    const approvalDate = props.getOutletData?.outlet?.third_party_rider_setting?.wefast_request?.approval_date;
    const serviceStatus = props.getOutletData?.outlet?.third_party_rider_setting?.wefast_request?.status;
    const dispatch = useDispatch();
    const startService = () => {
        dispatch(requestForRiderService('wefast', 'rider service request for Wefast')).then(() => {
            props.fetchOutletById();
        });
    };
    let flyout;

    if (isFlyoutVisible) {
        flyout = (
            <EuiFlyout
                ownFocus
                onClose={() => {
                    setIsFlyoutVisible(!isFlyoutVisible);
                }}
                aria-labelledby={'simpleFlyoutTitle'}
            >
                <EuiFlyoutHeader hasBorder>
                    <EuiTitle size='m'>
                        <h2>Wefast riders</h2>
                    </EuiTitle>
                </EuiFlyoutHeader>
                <EuiFlyoutBody>
                    <ButtonGroup
                        idSelected={watch('wefast_integration_available') ? '1' : '0'}
                        color='primary'
                        control={control}
                        setValue={setValue}
                        name='wefast_integration_available'
                        watch={watch}
                        label={'WeFast integration'}
                    />

                    {watch('wefast_integration_available') ? (
                        <>
                            <TextField
                                errors={errors}
                                label='WeFast auth token'
                                name='wefast_auth_token'
                                placeholder='Enter WeFast auth token'
                                rules={
                                    watch('wefast_integration_available')
                                        ? { required: 'Please Enter WeFast auth token' }
                                        : {}
                                }
                                control={control}
                            />
                            <TextField
                                errors={errors}
                                label='Url'
                                name='wefast_call_back_url'
                                placeholder='Enter url'
                                control={control}
                                disabled={true}
                                defaultValue={watch('wefast_call_back_url')}
                                append={
                                    <EuiCopy
                                        textToCopy={watch('wefast_call_back_url') ? watch('wefast_call_back_url') : ''}
                                    >
                                        {(copy) => <EuiText onClick={copy}>Copy</EuiText>}
                                    </EuiCopy>
                                }
                            />
                        </>
                    ) : (
                        ''
                    )}
                </EuiFlyoutBody>
            </EuiFlyout>
        );
    }

    return (
        <div style={{ maxWidth: '650px' }}>
            <FormGroupDescription
                title={restaurantDescriptions?.wefast_riders?.display_name}
                description={restaurantDescriptions?.wefast_riders?.description}
            >
                <EuiFlexItem>
                    <EuiButton
                        style={{
                            backgroundColor: serviceStatus === 'approved' && '#228B22',
                            color: serviceStatus === 'approved' && 'white',
                        }}
                        fill
                        onClick={startService}
                        disabled={serviceStatus ? true : false}
                    >
                        {serviceStatus ? serviceStatus : 'START SERVICE'}
                    </EuiButton>
                </EuiFlexItem>
                <EuiSpacer style={{ marginTop: '6px' }}>
                    <EuiText textAlign='center' size='xs'>
                        ------ OR ------
                    </EuiText>
                </EuiSpacer>
                <EuiFlexItem grow={false}>
                    <EuiButton onClick={() => setIsFlyoutVisible(!isFlyoutVisible)}>CONNECT ACCOUNT</EuiButton>
                </EuiFlexItem>
                {flyout}
            </FormGroupDescription>
            {serviceStatus === 'pending' && (
                <EuiText
                    style={{
                        padding: '8px 12px',
                        background: '#BBB',
                        color: 'white',
                        marginBottom: '12px',
                        borderRadius: '4px',
                        // width: '65%',
                    }}
                >
                    <p> Your reqeust has been received and it shall be activated by {approvalDate} </p>
                </EuiText>
            )}
        </div>
    );
};

export default WeFastRider;
